import { UUID } from '../../api/types';

export enum APIFileValidationStatus {
  pending = 'pending',
  succeeded = 'succeeded',
  failed = 'failed',
  partially_succeeded = 'partially_succeeded',
}

export enum APIFileType {
  csv = 'csv',
  parquet = 'parquet',
}

export enum APIFilePurpose {
  portfolio_upload = 'portfolio_upload',
  portfolio_download = 'portfolio_download',
  result_set_export = 'result_set_export',
  portfolio_export = 'portfolio_export',
  usage_tracking_export = 'usage_tracking_export',
  geocode_log = 'geocode_log',
  geocode_error_log = 'geocode_error_log',
}

export interface APIFile {
  id: UUID;
  filename: string;
  purpose: APIFilePurpose;
  type: APIFileType;
  size: number;
  created_at: number;
  updated_at: number;
  created_by: UUID;
  updated_by: UUID;
}

export interface APIFileInput {
  file: File;
  purpose: APIFilePurpose;
}

export interface APIFileValidation {
  id: UUID;
  file: UUID;
  status: APIFileValidationStatus;
  failed_count: number;
  succeeded_count: number;
  total_count: number;
  errors:
    | [
        {
          code: string;
          description: string;
          row_number: number;
        },
      ]
    | null;
  created_at: number;
  updated_at: number;
  created_by: UUID;
  updated_by: UUID;
}

interface FileValidationLocn {
  customer_location_id: number;
  geometry: {
    latitude: number;
    longitude: number;
  };
}

export interface APIFileValidationLocn {
  id: UUID;
  locations: FileValidationLocn[];
}

export interface APIFileValidationInput {
  file: UUID;
}

export interface APICreateFileDownloadLinkInput {
  expires_in: number;
}

export interface APIFileDownloadLink {
  file_id: UUID;
  url: string;
}
