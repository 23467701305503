import { FC } from 'react';
import { DATA_DOG_RUM_CONFIG } from '../../datadog';

const AWS_CONFIG = {
  userPoolId: window._env_.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: window._env_.REACT_APP_USER_POOL_WEB_CLIENT_ID,
};

interface TagProps {
  children: string;
}

const Tag: FC<TagProps> = ({ children, ...rest }) => (
  <span style={{ fontWeight: 'bold', padding: 5, color: '#fff', background: '#51B8F2' }} {...rest}>
    {children}
  </span>
);

const HealthCheckPage: FC = () => {
  return (
    <div
      style={{
        color: '#000',
        background: '#fff',
        padding: '50px',
      }}
      data-testid="health-check-page"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        {/* <div style={{ minHeight: '50px' }}>
        GQL Server Connected: <Tag>{client ? 'YES' : 'NO'}</Tag>
      </div> */}
        <div style={{ minHeight: '50px' }}>
          Build Number: <Tag data-testid="build-number">{window._env_.REACT_APP_BUILD_NUMBER}</Tag>
        </div>

        <div style={{ minHeight: '50px' }}>
          API Base URL: <Tag data-testid="base-url">{window._env_.REACT_APP_GQL_ENDPOINT}</Tag>
        </div>

        <div style={{ minHeight: '50px' }}>
          Dundas BI Hostname:{' '}
          <Tag data-testid="dundas-hostname">{window._env_.REACT_APP_DUNDAS_BI_ENDPOINT}</Tag>
        </div>

        <div style={{ minHeight: '50px' }}>
          AWS Config:
          <ul>
            <li style={{ minHeight: '50px' }}>
              UserPoolId: <Tag data-testid="cognito-user-pool-id">{AWS_CONFIG.userPoolId}</Tag>
            </li>
            <li style={{ minHeight: '50px' }}>
              ClientId: <Tag data-testid="cognito-client-id">{AWS_CONFIG.userPoolWebClientId}</Tag>
            </li>
          </ul>
        </div>

        <div style={{ minHeight: '50px' }}>
          Data Dog RUM Config:
          <ul>
            <li style={{ minHeight: '50px' }}>
              env: <Tag data-testid="datadog-env">{DATA_DOG_RUM_CONFIG.env}</Tag>
            </li>
            <li style={{ minHeight: '50px' }}>
              applicationId:{' '}
              <Tag data-testid="datadog-applicationId">{DATA_DOG_RUM_CONFIG.applicationId}</Tag>
            </li>
            <li style={{ minHeight: '50px' }}>
              clientToken:{' '}
              <Tag data-testid="datadog-clientToken">{DATA_DOG_RUM_CONFIG.clientToken}</Tag>
            </li>
            <li style={{ minHeight: '50px' }}>
              site: <Tag data-testid="datadog-site">{DATA_DOG_RUM_CONFIG.site}</Tag>
            </li>
            <li style={{ minHeight: '50px' }}>
              service: <Tag data-testid="datadog-service">{DATA_DOG_RUM_CONFIG.service}</Tag>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HealthCheckPage;
