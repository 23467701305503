/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResultSetStatus } from '../../api/resultSets/types';
import { AlertObject, AlertObjectInput, AlertSeverity } from '../../components/alerts/types';
import { dateFormat } from '../../utils/datetime';
import { reactKey } from '../../utils/reactUtils';
import { generateDocument360URL } from '../../utils/settingsUtils';
import { logRejected } from './utils';
import { getDoc360TokenAdapter } from '../../rest/token/adapter';

export enum Document360LoadingStatus {
  IDLE = 'idle',
  SUCCEEDED = 'succeeded',
  LOADING = 'loading',
  FAILED = 'failed',
}

interface Document360TokenProps {
  redirectUrl?: string;
}

export const getDocument360Token = createAsyncThunk(
  'ui/get-doc-360-token',
  async ({ redirectUrl }: Document360TokenProps, { rejectWithValue, dispatch, getState }) => {
    const state: any = getState();
    const userId = state?.user?.userId ?? '';

    const queryResponse = await getDoc360TokenAdapter(userId);

    const { data: getDoc360Token, error } = queryResponse;

    if (getDoc360Token?.token) {
      return getDoc360Token.token;
    }

    dispatch(
      addAlert({
        severity: AlertSeverity.Warning,
        open: true,
        message: 'Unable to generate token. Please try again.',
      }),
    );
    return rejectWithValue('Unable to generate token. Please try again.');
  },
);

export enum AppSection {
  Explorer = 'Explorer',
  Visualizations = 'Visualizations',
}

export enum NavOption {
  Main = 'Main',
  Portfolios = 'Portfolios',
  ResultSets = 'Result Sets',
}

export interface SetDownloadDrawerProps {
  open?: boolean;
  portfolioId?: string;
  portfolioName?: string;
  resultSetId?: string;
  resultSetName?: string;
  resultSetStatus?: ResultSetStatus;
  error?: string;
}

export interface DownloadDrawerProps {
  open: boolean;
  portfolioId: string;
  portfolioName: string;
  resultSetId: string;
  resultSetName: string;
  resultSetStatus: ResultSetStatus;
}

export interface Document360Props {
  token: string;
  status: Document360LoadingStatus;
}

export interface UIState {
  downloadDrawer: DownloadDrawerProps;
  uploadDrawerOpen: boolean;
  appSection: AppSection;
  queryBuilderDrawerOpen: boolean;
  slrDrawerOpen: boolean;
  alerts: AlertObject[];
  settingsDrawerOpen: boolean;
  vizNavMenuOpen: boolean;
  document360: Document360Props;
  showAIAssistant: boolean;
}

const initialState: UIState = {
  downloadDrawer: {
    open: false,
    portfolioId: '',
    portfolioName: '',
    resultSetId: '',
    resultSetName: '',
    resultSetStatus: ResultSetStatus.COMPLETED,
  },
  uploadDrawerOpen: false,
  appSection: AppSection.Explorer,
  queryBuilderDrawerOpen: false,
  slrDrawerOpen: false,
  alerts: [],
  settingsDrawerOpen: false,
  vizNavMenuOpen: false,
  document360: {
    token: '',
    status: Document360LoadingStatus.IDLE,
  },
  showAIAssistant: window._env_.REACT_APP_ENVIRONMENT_TYPE === 'development',
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  // the `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetDownloadDrawer: (state: UIState) => {
      state.downloadDrawer = {
        open: false,
        portfolioId: '',
        portfolioName: '',
        resultSetId: '',
        resultSetName: '',
        resultSetStatus: ResultSetStatus.COMPLETED,
      };
    },
    setDownloadDrawer: (state: UIState, action: PayloadAction<SetDownloadDrawerProps>) => {
      const { open, portfolioId, portfolioName, resultSetId, resultSetName, resultSetStatus } =
        action.payload;

      if (open !== undefined) {
        state.downloadDrawer.open = open;
      }
      if (portfolioId !== undefined) {
        state.downloadDrawer.portfolioId = portfolioId;
      }
      if (portfolioName !== undefined) {
        state.downloadDrawer.portfolioName = portfolioName;
      }
      if (resultSetId !== undefined) {
        state.downloadDrawer.resultSetId = resultSetId;
      }
      if (resultSetName !== undefined) {
        state.downloadDrawer.resultSetName = resultSetName;
      }
      if (resultSetStatus !== undefined) {
        state.downloadDrawer.resultSetStatus = resultSetStatus;
      }
    },
    addAlert: (state: UIState, action: PayloadAction<AlertObjectInput>) => {
      const newAlert = {
        id: reactKey('app-alert-'),
        timestamp: dateFormat(undefined, 'MMMM D, YYYY h:mm A'),
        ...action.payload,
      };

      state.alerts.push(newAlert);
    },
    removeAlert: (state: UIState, action: PayloadAction<{ id: string }>) => {
      state.alerts = state.alerts.filter((alert) => alert.id !== action.payload.id);
    },
    closeAlert: (state: UIState, action: PayloadAction<{ id: string }>) => {
      state.alerts.forEach((alert) => {
        if (alert.id === action.payload.id) {
          alert.open = false;
        }
      });
    },
    clearAlerts: (state: UIState) => {
      state.alerts = [];
    },
    setAppSection: (state: UIState, action: PayloadAction<AppSection>) => {
      state.appSection = action.payload;
    },
    setUploadDrawer: (state: UIState, action: PayloadAction<boolean>) => {
      state.uploadDrawerOpen = action.payload;
    },
    setQueryBuilderDrawer: (state: UIState, action: PayloadAction<boolean>) => {
      state.queryBuilderDrawerOpen = action.payload;
    },
    setSLRDrawer: (state: UIState, action: PayloadAction<boolean>) => {
      state.slrDrawerOpen = action.payload;
    },
    setSettingsDrawer: (state: UIState, action: PayloadAction<boolean>) => {
      state.settingsDrawerOpen = action.payload;
    },
    setVizNavMenu: (state: UIState, action: PayloadAction<boolean>) => {
      state.vizNavMenuOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDocument360Token.pending, (state) => {
        state.document360.status = Document360LoadingStatus.LOADING;
      })
      .addCase(getDocument360Token.fulfilled, (state, action) => {
        state.document360.status = Document360LoadingStatus.SUCCEEDED;
        state.document360.token = action.payload;
        const redirectUrl = action?.meta?.arg?.redirectUrl ?? null;
        const href = generateDocument360URL(action.payload, redirectUrl);

        // Open a new window
        window.open(href, '_blank');
      })
      .addCase(getDocument360Token.rejected, (state, action) => {
        logRejected(action, 'getDoc360Token failed');
        state.document360.status = Document360LoadingStatus.FAILED;
      });
  },
});

export const {
  resetDownloadDrawer,
  setDownloadDrawer,
  setUploadDrawer,
  setAppSection,
  setQueryBuilderDrawer,
  setSLRDrawer,
  addAlert,
  removeAlert,
  closeAlert,
  clearAlerts,
  setSettingsDrawer,
  setVizNavMenu,
} = uiSlice.actions;

export default uiSlice.reducer;
