import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  AuthStatus,
  createClient,
  deleteClient,
  getClients,
} from '../../../redux/slices/userSlice';
import {
  selectClientCreds,
  selectClientCredsStatus,
  selectUserId,
} from '../../../redux/selectors/userSelectors';
import { ColumnStyle, CenteredRowStyle, MUTED_TEXT_COLOR } from '../../../utils/styleUtils';
import ImmutableTextInput from '../../../components/text-input/ImmutableTextInput';
import SectionedIconButton from '../../../components/buttons/SectionedIconButton';
import TextUnderlineButton from '../../../components/TextUnderlineButton';
import InfoIconTooltip from '../../../components/InfoIconTooltip';
import { Title } from '../../../components/InfoText';
import ConditionalRendering from '../../../components/ConditionalRendering';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import { copyTextToClipboard } from '../../../utils/browserUtils';
import { PADDING_SX } from './constants';

const ClientCredsSettings: FC = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const userId = useAppSelector(selectUserId);
  const creds = useAppSelector(selectClientCreds);
  const credStatus = useAppSelector(selectClientCredsStatus);

  const [isClientCreating, setIsClientCreating] = useState<boolean>(false);
  const [listRecentlyCopied, setListRecentlyCopied] = useState<string[]>([]);
  const [clientIdToDelete, setClientIdToDelete] = useState<string>('');
  const [deleteClientInProgress, setDeleteClientInProgress] = useState<boolean>(false);

  const isDeleteClientModalOpen = useMemo(() => Boolean(clientIdToDelete), [clientIdToDelete]);
  const isClientsLoading = useMemo(() => credStatus === AuthStatus.LOADING, [credStatus]);
  const createClientDisabled = useMemo(
    () => isClientsLoading || isClientCreating || creds.length >= 2,
    [creds, isClientCreating, isClientsLoading],
  );

  const createClientCred = async (): Promise<void> => {
    setIsClientCreating(true);
    await dispatch(createClient());
    setIsClientCreating(false);
  };

  const onConfirmDeleteClient = async (): Promise<void> => {
    try {
      setDeleteClientInProgress(true);
      await dispatch(
        deleteClient({
          clientId: clientIdToDelete,
        }),
      );
      setClientIdToDelete('');
    } catch {
      // In case of any exception, delete modal remains open
    }

    setDeleteClientInProgress(false);
  };

  const onCancelDeleteClient = (): void => {
    setClientIdToDelete('');
  };

  const onDeleteClient = (clientId: string): void => {
    setClientIdToDelete(clientId);
  };

  const fetchClientCreds = useCallback(async (): Promise<void> => {
    await dispatch(getClients());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userId]);

  const copyData = async (text: string): Promise<void> => {
    await copyTextToClipboard(text);
    setListRecentlyCopied((pre) => [...pre, text]);
    // timeout to show successfully copied indecator as micro-intercation
    setTimeout(() => {
      setListRecentlyCopied((pre) => pre.filter((item) => item !== text));
    }, 500);
  };

  useEffect(() => {
    void fetchClientCreds();
  }, [fetchClientCreds]);

  return (
    <>
      <ConditionalRendering
        condition={isClientsLoading}
        render={
          <Box
            sx={{
              width: '100%',
              ...CenteredRowStyle,
              marginBottom: theme.spacing(1.75),
              justifyContent: 'center',
            }}
          >
            <CircularProgress
              size={theme.spacing(1.75)}
              id="usage-stats-loading-indicator"
              color="inherit"
              sx={{
                fontSize: theme.spacing(1.75),
              }}
            />
          </Box>
        }
        elseRender={
          <Box data-testid="client-credentials-container">
            {creds.length > 0 && (
              <Box sx={{ ...ColumnStyle, ...PADDING_SX, maxWidth: theme.spacing(80) }} gap={1.5}>
                {creds.map(({ clientId, clientSecret }) => (
                  <Box
                    key={clientId}
                    sx={{
                      ...CenteredRowStyle,
                      alignItems: 'center',
                      width: '100%',
                    }}
                    gap={1.5}
                    data-testid={clientId}
                  >
                    <Box sx={{ ...ColumnStyle, width: '100%' }}>
                      <Title variant="caption">Client ID</Title>
                      <Box sx={{ ...CenteredRowStyle }}>
                        <ImmutableTextInput
                          textInput={clientId}
                          placeholder="Client ID..."
                          sx={{
                            borderTopLeftRadius: theme.spacing(0.5),
                            borderBottomLeftRadius: theme.spacing(0.5),
                          }}
                        />
                        <SectionedIconButton
                          buttons={[
                            {
                              icon: listRecentlyCopied.includes(clientId) ? (
                                <CheckCircleIcon sx={{ fontSize: theme.spacing(2) }} />
                              ) : (
                                <ContentCopyIcon sx={{ fontSize: theme.spacing(2) }} />
                              ),
                              onClick: () => {
                                void copyData(clientId);
                              },
                              id: 'copy-client-id',
                              buttonSx: {
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                height: '100%',
                              },
                            },
                          ]}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ ...ColumnStyle, width: '100%' }}>
                      <Title variant="caption">Secret</Title>
                      <Box sx={{ ...CenteredRowStyle }}>
                        <ImmutableTextInput
                          textInput={clientSecret}
                          placeholder="Client Secret..."
                          type="password"
                          sx={{
                            borderTopLeftRadius: theme.spacing(0.5),
                            borderBottomLeftRadius: theme.spacing(0.5),
                          }}
                        />
                        <SectionedIconButton
                          buttons={[
                            {
                              icon: listRecentlyCopied.includes(clientSecret) ? (
                                <CheckCircleIcon sx={{ fontSize: theme.spacing(2) }} />
                              ) : (
                                <ContentCopyIcon sx={{ fontSize: theme.spacing(2) }} />
                              ),
                              onClick: () => {
                                void copyData(clientSecret);
                              },
                              id: 'copy-secret',
                              buttonSx: {
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                height: '100%',
                              },
                            },
                          ]}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ ...ColumnStyle, paddingTop: theme.spacing(3.25) }}>
                      <SectionedIconButton
                        sx={{ height: theme.spacing(4) }}
                        buttons={[
                          {
                            icon:
                              clientId === clientIdToDelete ? (
                                <CircularProgress
                                  size={theme.spacing(1.75)}
                                  id="delete-client-indicator"
                                  color="inherit"
                                  sx={{
                                    fontSize: theme.spacing(1.75),
                                  }}
                                />
                              ) : (
                                <DeleteIcon sx={{ fontSize: theme.spacing(2) }} />
                              ),
                            onClick: () => {
                              onDeleteClient(clientId);
                            },
                            disabled: isDeleteClientModalOpen,
                            id: 'delete-client',
                            buttonSx: {
                              height: '100%',
                            },
                          },
                        ]}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
            <Box sx={{ ...ColumnStyle, ...PADDING_SX, maxWidth: theme.spacing(60) }} gap={1.5}>
              <Box
                sx={{
                  ...CenteredRowStyle,
                  alignItems: 'center',
                  width: '100%',
                }}
                data-testid="create-client"
              >
                {!creds.length && (
                  <Title variant="caption">To get client-id and secret&nbsp;</Title>
                )}
                <TextUnderlineButton
                  disabled={createClientDisabled}
                  TypographyProps={{ variant: 'h6' }}
                  sx={{ minWidth: 'auto', marginLeft: theme.spacing(0.25) }}
                  onClick={() => {
                    void createClientCred();
                  }}
                >
                  Create New Client
                </TextUnderlineButton>
                {creds.length >= 2 && (
                  <InfoIconTooltip
                    tooltip="You can only create maximum of 2 clients, to create new client please delete an existing one."
                    sx={{ marginLeft: theme.spacing(0.5), opacity: 0.5 }}
                    placement="right"
                  />
                )}
                {isClientCreating && (
                  <Typography
                    variant="body1"
                    sx={{ color: MUTED_TEXT_COLOR, paddingLeft: theme.spacing(0.5), lineHeight: 1 }}
                  >
                    <CircularProgress
                      size={theme.spacing(1.5)}
                      id="create-client-indicator"
                      color="inherit"
                      sx={{
                        fontSize: theme.spacing(1.5),
                      }}
                    />
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        }
      />
      <ConfirmationModal
        open={isDeleteClientModalOpen}
        title="Delete Client"
        body="You are requesting deletion of this ClientID and Secret. Once deleted, it will no longer be available
            in your account. Are you sure you want to continue?"
        onCancel={onCancelDeleteClient}
        onConfirm={() => {
          void onConfirmDeleteClient();
        }}
        confirmButtonText="Yes"
        cancelButtonText="No"
        isConfirmButtonLoading={deleteClientInProgress}
      />
    </>
  );
};

export default ClientCredsSettings;
