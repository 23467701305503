import { AlertSeverity } from '../../../components/alerts/types';

export const toIntOr = (value: any, alt: any): any => {
  try {
    const int = parseInt(value, 10);
    return Number.isNaN(int) ? alt : int;
  } catch (error) {}

  return alt;
};

export const toFloatOr = (value: any, alt: any): any => {
  try {
    const float = parseFloat(value);
    return Number.isNaN(float) ? alt : float;
  } catch (error) {}

  return alt;
};

export const humanReadableNumber = (num: number | string, thousandsSeparator = ','): string => {
  // separate the integer part of the number from the decimal part
  const [intPart, decimalPart] = num.toString().split('.');

  // convert the integer part into and array of its digits
  // ['123'] --> ['1', '2', '3']
  // '123' --> ['1', '2', '3']
  const intArr = Array.isArray(intPart) ? intPart.join('').split('') : intPart.split('');

  // `[...intArr]` is to make it sonarlint compliant
  const reversed = [...intArr].reverse();

  const intStringWithCommas = reversed
    .reduce((updatedArray: any[], digit: any, idx: number) => {
      const digitIndex = idx + 1;
      if (
        // not first
        digitIndex !== 1 &&
        // not last
        digitIndex !== intArr.length &&
        // third from last
        digitIndex % 3 === 0
      ) {
        return updatedArray.concat(digit).concat(thousandsSeparator);
      }

      return updatedArray.concat(digit);
    }, [])
    .reverse();

  // check undefined so 0 doesn't evaluate to falsy
  if (decimalPart !== undefined) {
    // choose a decimal separator (the inverse of the thousands separator)
    const decimalSeparator = thousandsSeparator === '.' ? ',' : '.';

    // Add the decimal part back
    return intStringWithCommas.concat(decimalSeparator).concat(decimalPart).join('');
  }

  return intStringWithCommas.join('');

  /*

        const statements = [
          ['1', '1'],
          ['12', '12'],
          ['123', '123'],
          ['1234', '1,234'],
          ['12345', '12,345'],
          ['123456', '123,456'],
          ['1234567', '1,234,567'],
          ['12345678', '12,345,678'],
          ['123456789', '123,456,789'],
          ['123456789.1', '123,456,789.1'],
          ['123456789.12', '123,456,789.12'],
          ['123456789.123', '123,456,789.123'],
        ]

        statements.forEach((item) => {
          const [input, expected] = item
          const actual = humanReadableNumber(input)
          console.assert(actual === expected, `Expected ${actual} === ${expected}`)
        })

    */
};

interface INFO_CODE_DEFINITION {
  code: string;
  description: string;
}

export const INFO_CODES_DICT: {
  [code: string]: INFO_CODE_DEFINITION;
} = {
  I101: {
    code: 'I101',
    description:
      'Please select a portfolio with 50 or fewer locations to run a Flood Mesh analysis.',
  },
  I103: {
    code: 'I103',
    description:
      'This option creates a large mesh around the center point. If you are interested in learning more about this feature, please speak to your CSA about upgrading.',
  },
};

export const getAlertSeverity = (errorCode: string): AlertSeverity => {
  const firstLetter = errorCode.slice(0, 1).toUpperCase();

  switch (firstLetter) {
    case 'E':
      return AlertSeverity.Error;
    case 'W':
      return AlertSeverity.Warning;
    case 'I':
      return AlertSeverity.Info;
    default:
      return AlertSeverity.Info;
  }
};
