import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectAppAlerts } from '../../redux/selectors/uiSelectors';
import { clearAlerts, closeAlert } from '../../redux/slices/uiSlice';
import TextUnderlineButton from '../TextUnderlineButton';
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useAlerts = () => {
  const dispatch = useAppDispatch();
  const alerts = useAppSelector(selectAppAlerts);
  const openAlerts = useMemo(() => {
    return alerts.filter((alert) => alert.open);
  }, [alerts]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // console.log('alerts (total)', alerts.length);
  // console.log('open alerts', openAlerts.length);

  useEffect(() => {
    openAlerts.forEach((alert) => {
      const { id, message, severity, options = {} } = alert;
      // display snackbar using notistack
      const {
        closeable = false,
        closeButtonText = 'Dismiss.',
        onCloseClickCallback = () => {},
        ...otherOptions
      } = options;

      enqueueSnackbar(message, {
        key: id,
        variant: severity,
        preventDuplicate: true,
        action: (snackbarId) => {
          if (!closeable) return null;

          return (
            <TextUnderlineButton
              onClick={() => {
                onCloseClickCallback();
                closeSnackbar(snackbarId);
                dispatch(closeAlert(alert));
              }}
            >
              {closeButtonText}
            </TextUnderlineButton>
          );
        },
        onClose: (event, reason, myKey) => {
          // console.log('onClose event--', { event, reason, myKey });
          dispatch(closeAlert(alert));
        },
        onExited: (event, myKey) => {
          // console.log('onExited event--', { event, myKey });

          // remove this snackbar from redux state
          dispatch(clearAlerts());
        },
        ...otherOptions,
      });
    });
  }, [openAlerts, closeSnackbar, enqueueSnackbar, dispatch]);
};

export default useAlerts;
