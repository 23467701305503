import { styled } from '@mui/material/styles';
import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
  tooltipClasses,
} from '@mui/material';
import { TILE_BORDER } from '../../utils/styleUtils';

interface TooltipProps extends MuiTooltipProps {
  mode?: 'light' | 'dark';
}

const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}));

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
}));

const Tooltip = styled(({ mode, ...props }: TooltipProps) => {
  return mode === 'dark' ? <DarkTooltip {...props} /> : <LightTooltip {...props} />;
})(({ theme }) => ({
  [`& .${tooltipClasses.arrow}:before`]: {
    border: TILE_BORDER,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 0,
    padding: theme.spacing(1),
    border: TILE_BORDER,
    boxShadow: '0px 2px 4px 0px #00000040',
  },
}));

export default Tooltip;
