import { Box, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import SwitchButton, { IndividualButtonProps } from '../../../components/buttons/SwitchButton';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectCoordinatesList,
  selectUploadCategory,
} from '../../../redux/selectors/uploadSelectors';
import {
  addCoordinates,
  editCoordinates,
  removeCoordinates,
  resetUploadState,
  setValidationActive,
  submitUploadRequest,
} from '../../../redux/slices/uploadSlice';
import { JSON2CSV } from '../../../utils/csvUtils';
import { timeNowUtc } from '../../../utils/datetime';
import {
  ColumnStyle,
  DRAWER_COLUMN_CONTAINER,
  MUI_SPACER,
  LITE_TEXT_COLOR,
  SIDE_PANEL_SEPARATOR,
  SIDE_PANEL_TITLE_COLOR,
  ACTIVE_BUTTON_STYLE,
  px,
} from '../../../utils/styleUtils';
import { TOP_BAR_HEIGHT } from '../../main/TopBar/util';
import CategorySelector from '../CategorySelector';
import MapPreview from '../MapPreview';
import { LocationData } from '../types';
import CoordinateItem from './CoordinateItem';
import CoordinatesPicker from './CoordinatesPicker';
import { CoordinateCompositionProps } from './types';
import { GUIDELINES_FOR_COORDINATE_COMPOSITION, deriveCoordsFromInputString } from './util';
import { StyledMediumLabel, StyledThinLabel } from '../../../components/labels/StyledLabel';

const CoordinateComposition: FC<CoordinateCompositionProps> = ({ closeDrawer }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const coordinatesList = useAppSelector(selectCoordinatesList);
  const [loading, setLoading] = useState<boolean>(false);
  const selectedCategory = useAppSelector(selectUploadCategory);

  const handleUploadSubmit = async (jsonData: any): Promise<void> => {
    const csvString = JSON2CSV(jsonData);
    const useIsoFormat = true;
    const name = `${timeNowUtc(useIsoFormat)}`;
    const csvFile = new File([csvString], name, { type: 'text/csv' });

    setLoading(true);
    await dispatch(submitUploadRequest({ csvString, name, csv: csvFile }));
    dispatch(setValidationActive(true)); // IDEA: could be run by submitUploadRequest, if successful
    setLoading(false);
  };

  const onContinue = useCallback(() => {
    void handleUploadSubmit(coordinatesList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinatesList]);

  const onCancel = (): void => {
    dispatch(resetUploadState());
    closeDrawer();
  };

  const handleDeleteCoordinateItem = (coordinatesItem: LocationData): void => {
    dispatch(removeCoordinates([coordinatesItem]));
  };

  const handleEditCoordinateItem = (coordinatesItem: LocationData): void => {
    dispatch(editCoordinates([coordinatesItem]));
  };

  const handleAddCoordinate = (coordsStr: string): void => {
    const { lat, lng } = deriveCoordsFromInputString(coordsStr);
    const nextCoordinateObj = {
      latitude: lat,
      longitude: lng,
    };
    dispatch(addCoordinates([nextCoordinateObj]));
  };

  const actionButtons: IndividualButtonProps[] = [
    {
      title: loading ? 'Processing upload...' : 'Continue',
      onClick: () => {
        void onContinue();
      },
      disabled: coordinatesList.length === 0 || !selectedCategory || loading,
      id: 'coordinate-upload-action-button-1',
      buttonSx: ACTIVE_BUTTON_STYLE,
    },
    {
      title: 'Cancel',
      onClick: onCancel,
      id: 'coordinate-upload-action-button-2',
    },
  ];

  const coordinatesListWithLabels = useMemo(() => {
    return coordinatesList.map((item, idx) => {
      const ordinalLabel = `${idx + 1}`;
      return {
        ...item,
        label: ordinalLabel,
      };
    });
  }, [coordinatesList]);

  return (
    <Box
      data-testid="coordinate-composition-component"
      sx={{
        ...ColumnStyle,
        color: LITE_TEXT_COLOR,
        height: '100%',
        width: '100%',
      }}
    >
      <Box
        sx={{
          ...DRAWER_COLUMN_CONTAINER,
          width: '100%',
          paddingBottom: theme.spacing(1),
        }}
      >
        <StyledMediumLabel variant="h5" sx={{ color: SIDE_PANEL_TITLE_COLOR }}>
          NEW PORTFOLIO
        </StyledMediumLabel>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
          },
          height: {
            xs: 'unset',
            sm: 'unset',
            md: '100%',
          },
          maxHeight: {
            xs: `calc(100% - ${theme.spacing(18)})`,
            sm: `calc(100% - ${theme.spacing(18)})`,
            md: `calc(100% - ${theme.spacing(7.375)})`,
          },
          width: '100%',
          overflowY: {
            xs: 'auto',
            sm: 'auto',
            md: 'unset',
          },
        }}
      >
        {/* COLUMN 1 */}
        <Box
          data-testid="coordinate-input-section"
          sx={{
            ...DRAWER_COLUMN_CONTAINER,
            borderRight: {
              xs: 'none',
              sm: 'none',
              md: SIDE_PANEL_SEPARATOR,
            },
            minWidth: {
              xs: '100%',
              sm: '100%',
              md: theme.spacing(43.75), // NOTE:
              lg: theme.spacing(43.75),
            },
            maxWidth: {
              xs: '100%',
              sm: '100%',
              md: theme.spacing(43.75), // NOTE:
              lg: '30vw',
            },
            height: {
              xs: 'unset',
              sm: 'unset',
              md: '100%',
            },
          }}
        >
          <Box
            sx={{
              ...ColumnStyle,
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Box>
              <CoordinatesPicker onSubmit={handleAddCoordinate} />
            </Box>
            <Box
              data-testid="coordinate-controls"
              sx={{
                ...ColumnStyle,
                marginBottom: theme.spacing(2),
                display: {
                  xs: 'none',
                  sm: 'none',
                  md: 'flex',
                },
              }}
            >
              <CategorySelector sx={{ marginBottom: theme.spacing(MUI_SPACER) }} />
              <SwitchButton type="normal" buttons={actionButtons} />
            </Box>
          </Box>
        </Box>
        {/* COLUMN 2 */}
        <Box
          data-testid="coordinate-location-section"
          sx={{
            ...DRAWER_COLUMN_CONTAINER,
            height: '100%',
            borderRight: {
              xs: 'none',
              sm: 'none',
              md: SIDE_PANEL_SEPARATOR,
            },
            minWidth: {
              xs: '100%',
              sm: '100%',
              md: theme.spacing(37.5), // NOTE:
              lg: theme.spacing(37.5), // NOTE:
            },
            maxWidth: {
              xs: '100%',
              sm: '100%',
              md: theme.spacing(37.5), // NOTE:
              lg: '30vw',
            },
          }}
        >
          <StyledThinLabel variant="h6">Added Locations:</StyledThinLabel>

          <Box
            sx={{
              height: '100%',
              width: '100%',
              maxHeight: {
                xs: '20vh',
                sm: '20vh',
                md: `calc(100vh - calc(${px(TOP_BAR_HEIGHT)} + ${theme.spacing(MUI_SPACER * 6)}))`,
              },
              overflowY: 'auto',
              // TODO: Return to this - START
              // flexDirection: {
              //   xs: 'row',
              //   sm: 'row',
              //   md: 'column',
              // },
              // flexWrap: {
              //   xs: 'wrap',
              //   sm: 'wrap',
              //   md: 'no-wrap',
              // },
              // TODO: Return to this - END
            }}
          >
            {coordinatesListWithLabels.map((coordinateData) => {
              const { locationId, label } = coordinateData;
              return (
                <CoordinateItem
                  key={`coordinate-item-${locationId}`}
                  ordinalLabel={label}
                  editEnabled={false}
                  coordinateData={coordinateData}
                  onDelete={handleDeleteCoordinateItem}
                  onEdit={handleEditCoordinateItem}
                />
              );
            })}
          </Box>
        </Box>
        {/* COLUMN 3 */}
        <Box
          data-testid="coordinate-guideline-section"
          sx={{
            ...ColumnStyle,
            minWidth: {
              xs: '100%',
              sm: '100%',
              md: theme.spacing(46.25), // NOTE:
              lg: theme.spacing(46.25), // NOTE:
            },
            maxWidth: {
              xs: '100%',
              sm: '100%',
              md: theme.spacing(46.25), // NOTE:
              lg: '30vw',
            },
            height: {
              xs: 'unset',
              sm: 'unset',
              md: '100%',
            },
          }}
        >
          <Box
            sx={{
              ...DRAWER_COLUMN_CONTAINER,
              height: {
                xs: 'unset',
                sm: 'unset',
                md: '100%',
              },
              width: '100%',
              maxHeight: {
                xs: 'unset',
                sm: 'unset',
                md: `calc(100vh - calc(${px(TOP_BAR_HEIGHT)} + ${theme.spacing(
                  MUI_SPACER * 3.5,
                )}))`,
              },
              overflowY: 'auto',
            }}
          >
            <Box
              data-testid="mapbox-container"
              sx={{ height: theme.spacing(37.5), marginTop: theme.spacing(MUI_SPACER * 2) }}
            >
              <MapPreview locationData={coordinatesListWithLabels} />
            </Box>
            <Box data-testid="guidelines" sx={{ marginTop: theme.spacing(MUI_SPACER) }}>
              {GUIDELINES_FOR_COORDINATE_COMPOSITION.map(({ key, text }, index) =>
                index ? (
                  <Typography key={key} variant="h6" sx={{ paddingBottom: theme.spacing(1.25) }}>
                    {index}. {text}
                  </Typography>
                ) : (
                  <StyledThinLabel
                    key={key}
                    variant="h6"
                    sx={{ paddingBottom: theme.spacing(1.25) }}
                  >
                    {text}
                  </StyledThinLabel>
                ),
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        data-testid="coordinate-controls-sm"
        sx={{
          ...ColumnStyle,
          display: {
            xs: 'flex',
            sm: 'flex',
            md: 'none',
          },
          paddingTop: theme.spacing(MUI_SPACER / 2),
          paddingBottom: theme.spacing(MUI_SPACER),
          paddingLeft: theme.spacing(MUI_SPACER),
          paddingRight: theme.spacing(MUI_SPACER),
        }}
      >
        <CategorySelector sx={{ marginBottom: theme.spacing(MUI_SPACER / 2) }} />
        <SwitchButton type="normal" buttons={actionButtons} />
      </Box>
    </Box>
  );
};

export default CoordinateComposition;
