import { FC, useMemo } from 'react';
import { Box, BoxProps, Typography, styled, useTheme } from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material';
import { StyleProps, TILE_BORDER } from '../../utils/styleUtils';
import Tooltip from '../tooltip/Tooltip';

interface TileRadioContent {
  id: string;
  title: string;
  subtitle?: string;
  selected?: boolean;
  disabled?: boolean;
}

type TileRadioProps = {
  sxSupplement?: StyleProps;
  tileTooltip?: string;
} & Omit<TileRadioContent, 'id'> &
  BoxProps;

const StyledTileRadioBox: FC<BoxProps> = styled((props: BoxProps) => {
  return <Box {...props} />;
})(({ theme }) => ({
  padding: theme.spacing(1.5),
  border: TILE_BORDER,
  borderRadius: theme.spacing(0.5),
  textAlign: 'center',
  cursor: 'pointer',
  display: 'flex',
}));

const TileRadio: FC<TileRadioProps> = ({
  sx = {},
  sxSupplement = {},
  title,
  subtitle = '',
  selected = false,
  disabled = false,
  tileTooltip = '',
  ...tileBoxProps
}) => {
  const theme = useTheme();
  const tileBox = useMemo(
    () => (
      <Box>
        <StyledTileRadioBox
          sx={{
            ...sx,
            ...sxSupplement,
            ...(disabled ? { opacity: 0.6, pointerEvents: 'none' } : null),
          }}
          {...tileBoxProps}
        >
          <Box>
            <Typography variant="h6" sx={{ lineHeight: theme.spacing(0.1) }}>
              {selected ? (
                <CheckCircleIcon
                  sx={{
                    width: theme.spacing(2.5),
                    height: theme.spacing(2.5),
                    margin: `0 ${theme.spacing(0.5)}`,
                  }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  sx={{
                    width: theme.spacing(2.5),
                    height: theme.spacing(2.5),
                    margin: `0 ${theme.spacing(0.5)}`,
                  }}
                />
              )}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">{title}</Typography>
            {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
          </Box>
        </StyledTileRadioBox>
      </Box>
    ),
    [sx, sxSupplement, disabled, tileBoxProps, theme, selected, title, subtitle],
  );
  return tileTooltip ? <Tooltip title={tileTooltip}>{tileBox}</Tooltip> : tileBox;
};

export default TileRadio;
