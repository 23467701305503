import { JwtPayload, jwtDecode } from 'jwt-decode';
import { get, isNil } from 'lodash';
import { Auth0User } from './types';

const TOKEN_EXPIRY_THRESHOLD_MILLIS = 5 * 60 * 1000;

export const getUserIdToken = (): string => {
  return localStorage.getItem('auth0IdToken') ?? '';
};

export const setUserIdToken = (token = ''): void => {
  localStorage.setItem('auth0IdToken', token);
};

export const getUserAccessToken = (): string => {
  return localStorage.getItem('auth0AccessToken') ?? '';
};

export const setUserAccessToken = (token = ''): void => {
  localStorage.setItem('auth0AccessToken', token);
};

export const getAuth0AppConfigs = (): {
  domain: string;
  clientId: string;
  redirectUri: string;
  audience: string;
} => {
  const domain = window._env_.REACT_APP_AUTH0_DOMAIN ?? '';
  const clientId = window._env_.REACT_APP_AUTH0_CLIENT_ID ?? '';
  const redirectUri =
    window._env_.REACT_APP_AUTH0_CALLBACK_URL ?? `${window.location.origin}/callback`;
  const audience = window._env_.REACT_APP_AUTH0_API_AUDIENCE ?? '';

  return {
    domain,
    clientId,
    redirectUri,
    audience,
  };
};

export const extractDetailsFromToken = (
  token: string,
): {
  exp: number | null | undefined;
  isExpired: boolean;
  timeoutExpiry: number;
} => {
  if (token) {
    try {
      const { exp } = jwtDecode<JwtPayload>(token);
      const dateNow = Date.now();
      if (isNil(exp)) {
        return {
          exp,
          isExpired: false,
          timeoutExpiry: 0,
        };
      }

      const diff = exp && exp * 1000 - dateNow;
      const timeoutExpiry =
        diff && diff > TOKEN_EXPIRY_THRESHOLD_MILLIS ? diff - TOKEN_EXPIRY_THRESHOLD_MILLIS : 0;

      return {
        exp,
        isExpired: Boolean(exp && dateNow >= exp * 1000),
        timeoutExpiry,
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error parsing id token.', error);
    }
  }

  return {
    exp: 0,
    isExpired: true,
    timeoutExpiry: 0,
  };
};

export const extractUserRole = (userAttributes: Auth0User): string =>
  get(userAttributes, 'custom:jupiter-role', '');

export const extractTenantName = (userAttributes: Auth0User): string =>
  get(userAttributes, 'custom:jupiter-tenant-name', '');

export const extractTenantDisplayName = (userAttributes: Auth0User): string =>
  get(userAttributes, 'custom:jupiter-tenant-display-name', '');

export const extractUsername = (userAttributes: Auth0User): string =>
  get(userAttributes, 'given_name', '');

export const extractEmail = (userAttributes: Auth0User): string => get(userAttributes, 'email', '');

export const extractJupiterUserId = (userAttributes: Auth0User): string =>
  get(userAttributes, 'custom:jupiter-user-id', '');

export const extractJupiterTenantId = (userAttributes: Auth0User): string =>
  get(userAttributes, 'custom:jupiter-tenant-id', '');

export const extractCustomRoles = (userAttributes: Auth0User): string[] =>
  get(userAttributes, 'custom:jupiter-roles', []);
