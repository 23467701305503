import {
  CARD_DELIMITER,
  CARD_BORDER_RADIUS,
  StyleProps,
  CARD_TITLE_COLOR,
} from '../../../utils/styleUtils';

export const WRAPPER_0_SX = {
  padding: '1px',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  color: '#000',
  backgroundColor: '#CECECE',
  boxShadow: '2px 2px 4px 0 rgba(0,0,0,0.25)',
  borderRadius: CARD_BORDER_RADIUS,
  cursor: 'pointer',
};

export const WRAPPER_0_SX_SELECTED = {
  p: '2px',
  backgroundImage: 'linear-gradient(70deg, #BBA974 0%, #5482F2 52%, #4AA79D 100%)',
  boxShadow: '0 2px 4px 2px #C5F2F7',
  zIndex: 1,
};

export const WRAPPER_1_SX = {
  padding: '1px',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  color: CARD_TITLE_COLOR,
  borderRadius: CARD_BORDER_RADIUS,
  cursor: 'pointer',
};

export const WRAPPER_1_SX_SELECTED = {
  padding: 0,
};

export const WRAPPER_2_SX = {
  m: 0,
  p: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
};

export const WRAPPER_3_SX = {
  paddingTop: 1.25,
  paddingBottom: 1.25,
  paddingLeft: 2,
  paddingRight: 2,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
};

export const WRAPPER_4_SX = {
  paddingTop: 1.25,
  paddingBottom: 1.25,
  paddingLeft: 2,
  paddingRight: 2,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
};

export const WRAPPER_5_SX = {
  paddingTop: 2,
  paddingBottom: 4.5,
};

const strTopologyImages = ['TopologyActive_x2', 'TopologyInactive_x2'];

export const RS_BG_IMG_SX: StyleProps = {
  width: '29.6%',
  position: 'absolute',
  bottom: 0,
  right: 0,
};

export const getResultSetImgSrc = (isActive: boolean): string => {
  const bgImage = strTopologyImages[isActive ? 0 : 1];
  return `${window._env_.REACT_APP_APP_ROOT}/img/${bgImage}.png`;
};
