import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

const Pill: FC<MuiChipProps> = styled(({ label, ...pillProps }: MuiChipProps) => {
  return <MuiChip label={label} {...pillProps} />;
})(({ theme }) => {
  return {
    letterSpacing: '0.25px',
    fontWeight: '400',
    borderRadius: '25px',
    height: '16px',
    padding: theme.spacing(0.25),
    '& .MuiChip-label': {
      fontSize: '0.75rem',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      color: '#fff',
      paddingTop: theme.spacing(0.125),
    },
    '& .MuiSvgIcon-root': {
      fontSize: theme.spacing(2),
      color: '#fff',
      margin: theme.spacing(0.125),
    },
  };
});

export default Pill;
