import { TypographyProps as MuiTypographyProps } from '@mui/material';
import { FC } from 'react';
import { exportFileAsync } from '../utils/csvUtils';
import TextUnderlineButton from './TextUnderlineButton';

export interface DownloadConfig {
  filename: string;
  url: string;
  id: string;
}

interface DownloadFilesProps {
  title: string;
  downloadConfigs: DownloadConfig[];
  uppercase?: boolean;
  TypographyProps?: MuiTypographyProps;
}

const DownloadFilesTrigger: FC<DownloadFilesProps> = ({
  title,
  downloadConfigs,
  uppercase = false,
  TypographyProps,
}) => {
  const handleDownloadFiles = async (): Promise<void> => {
    try {
      await Promise.all(
        downloadConfigs.map(async (config): Promise<void> => {
          await exportFileAsync(config);
        }),
      ).catch((err) => {
        console.error('[handleDownloadFiles] Promise.all ERROR -', err);
      });
    } catch (error) {
      console.error('[handleDownloadFiles] ERROR -', error);
    }
  };

  // IDEA: Allow user to set the <Component/> they want to use here (set to TextUnderlineButton by default)
  return (
    <TextUnderlineButton
      onClick={() => {
        void handleDownloadFiles();
      }}
      data-testid="download-template-files"
      uppercase={uppercase}
      TypographyProps={TypographyProps}
    >
      {title}
    </TextUnderlineButton>
  );
};

export default DownloadFilesTrigger;
