import { getUserAccessToken } from '../auth0/utils';
import { apiErrorAccumulator } from './utils';

export type APIResponse<T> =
  | {
      data: T;
      error: null;
    }
  | {
      data: null;
      error: Error;
    };

export async function GET(
  path: string,
  parameters: Record<string, any> = {},
): Promise<APIResponse<any>> {
  const accessToken = getUserAccessToken();

  if (accessToken.length === 0) {
    return { data: null, error: new Error('Access token not found') };
  }

  const url = new URL(
    path.startsWith('https://') ? path : `https://${window._env_.REACT_APP_API_ENDPOINT}${path}`,
  );

  const request = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  Object.keys(parameters).forEach((key) => url.searchParams.append(key, parameters[`${key}`]));

  const res = await fetch(url, request);
  const data = await res.json();

  if (res.ok) {
    return {
      data,
      error: null,
    };
  }
  const errorText = apiErrorAccumulator(data) || res.statusText;
  return {
    data,
    error: new Error(errorText),
  };
}

export async function POST(path: string, body?: object): Promise<APIResponse<any>> {
  const accessToken = getUserAccessToken();

  if (accessToken.length === 0) {
    return { data: null, error: new Error('Access token not found') };
  }

  const url = new URL(
    path.startsWith('https://') ? path : `https://${window._env_.REACT_APP_API_ENDPOINT}${path}`,
  );

  const request = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    ...(body ? { body: JSON.stringify(body) } : {}),
  };

  const res = await fetch(url, request);
  const data = await res.json();

  if (res.ok) {
    return {
      data,
      error: null,
    };
  }
  const errorText = apiErrorAccumulator(data) || res.statusText;
  return {
    data,
    error: new Error(errorText),
  };
}

export async function POST_FORM(path: string, body: object): Promise<APIResponse<any>> {
  const accessToken = getUserAccessToken();

  if (accessToken.length === 0) {
    return { data: null, error: new Error('Access token not found') };
  }

  const url = new URL(
    path.startsWith('https://') ? path : `https://${window._env_.REACT_APP_API_ENDPOINT}${path}`,
  );

  const form = new FormData();
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(body)) {
    form.append(key, value);
  }

  const request = {
    method: 'POST',
    headers: {
      // 'content-type': (browser automatically picks multipart/form-data with suitable boundary)
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: form,
  };

  const res = await fetch(url, request);
  const data = await res.json();

  if (res.ok) {
    return {
      data,
      error: null,
    };
  }
  const errorText = apiErrorAccumulator(data) || res.statusText;
  return {
    data,
    error: new Error(errorText),
  };
}

export async function PUT(path: string, body: object): Promise<APIResponse<any>> {
  const accessToken = getUserAccessToken();

  if (accessToken.length === 0) {
    return { data: null, error: new Error('Access token not found') };
  }

  const url = new URL(
    path.startsWith('https://') ? path : `https://${window._env_.REACT_APP_API_ENDPOINT}${path}`,
  );

  const request = {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  };

  const res = await fetch(url, request);
  const data = await res.json();

  if (res.ok) {
    return {
      data,
      error: null,
    };
  }
  const errorText = apiErrorAccumulator(data) || res.statusText;
  return {
    data,
    error: new Error(errorText),
  };
}

export async function DELETE(path: string): Promise<APIResponse<any>> {
  const accessToken = getUserAccessToken();

  if (accessToken.length === 0) {
    return { data: null, error: new Error('Access token not found') };
  }

  const url = new URL(
    path.startsWith('https://') ? path : `https://${window._env_.REACT_APP_API_ENDPOINT}${path}`,
  );

  const request = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const res = await fetch(url, request);

  if (res.ok) {
    return {
      data: null,
      error: null,
    };
  }

  const data = await res.json();
  const errorText = apiErrorAccumulator(data) || res.statusText;
  return {
    data,
    error: new Error(errorText),
  };
}
