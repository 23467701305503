import { Box, useTheme } from '@mui/material';
import { FC } from 'react';
import Drawer from '../../components/Drawer';
import withErrorBoundary from '../../components/ErrorBoundary/withErrorBoundary';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectDownloadDrawerOpen } from '../../redux/selectors/uiSelectors';
import { setDownloadDrawer } from '../../redux/slices/uiSlice';
import {
  DRAWER_COLUMN_CONTAINER,
  LITE_TEXT_COLOR,
  SIDE_PANEL_TITLE_COLOR,
} from '../../utils/styleUtils';
import LinksPresentation from './LinksPresentation';
import { StyledMediumLabel } from '../../components/labels/StyledLabel';

const DownloadDrawer: FC = () => {
  const dispatch = useAppDispatch();
  const drawerOpen = useAppSelector(selectDownloadDrawerOpen);
  const setNewDrawerState = (newState: boolean): void => {
    dispatch(setDownloadDrawer({ open: newState }));
  };
  const theme = useTheme();

  return (
    <Drawer drawerOpen={drawerOpen} setDrawer={setNewDrawerState}>
      <Box sx={{ ...DRAWER_COLUMN_CONTAINER, color: LITE_TEXT_COLOR }}>
        <StyledMediumLabel
          sx={{
            color: SIDE_PANEL_TITLE_COLOR,
            marginBottom: theme.spacing(1),
          }}
          variant="h5"
        >
          DOWNLOAD OPTIONS
        </StyledMediumLabel>
        <LinksPresentation />
        {/* <CheckboxesPresentation /> */}
      </Box>
    </Drawer>
  );
};

export default withErrorBoundary(DownloadDrawer);
