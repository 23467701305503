import { Box, Grid } from '@mui/material';
import { FC } from 'react';
import ContextMenu from '../../../components/ContextMenu';
import { ValueBold as InfoTextValue } from '../../../components/InfoText';
import MidDot from '../../../components/MidDot';
import MockDataTag, { MOCK_DATA_TAG_WIDTH } from '../../../components/MockDataTag';
import RenameField from '../../../components/RenameField';
import CardInfo from '../../../components/card/CardInfo';
import { DataStatus } from '../../../redux/slices/csgDataSlice';
import { CenteredColumnStyle, ColumnStyle, px } from '../../../utils/styleUtils';
import Title from './Title';
import { ResultSetCardProps } from './types';
import {
  RS_BG_IMG_SX,
  WRAPPER_0_SX,
  WRAPPER_0_SX_SELECTED,
  WRAPPER_1_SX,
  WRAPPER_1_SX_SELECTED,
  WRAPPER_2_SX,
  WRAPPER_3_SX,
  WRAPPER_4_SX,
  WRAPPER_5_SX,
  getResultSetImgSrc,
} from './util';

const ResultSetCard: FC<ResultSetCardProps> = ({
  sx = {},
  sxSupplement = {},
  title,
  type = 'UNKNOWN',
  selected,
  statusIcon = null,
  cardContent,
  handleInfoClick,
  handleSelect,
  isMockData = false,
  // Edit props
  editNameState,
  onRenameConfirmed,
  setEditState,
  // Context menu
  contextMenuOptions,
}) => {
  return (
    <Box
      data-testid="resultSet-card"
      onClick={handleSelect}
      sx={{
        ...sx,
        ...sxSupplement,
        ...WRAPPER_0_SX,
        ...(selected ? WRAPPER_0_SX_SELECTED : {}),
        position: 'relative',
      }}
    >
      <Box
        sx={{
          ...WRAPPER_1_SX,
          ...(selected ? WRAPPER_1_SX_SELECTED : {}),
          background: '#fff',
          position: 'relative',
        }}
      >
        <img alt="rs-cover" style={RS_BG_IMG_SX} src={getResultSetImgSrc(selected)} />
        {/** ************** TITLE - Start ****************/}
        <Box
          sx={{
            ...WRAPPER_4_SX,
            ...(editNameState.open
              ? {
                  paddingLeft: 0,
                  paddingRight: 0,
                }
              : {}),
          }}
        >
          {editNameState.open ? (
            <RenameField
              loading={editNameState.status === DataStatus.LOADING}
              error={editNameState.error}
              currentName={title}
              submitChanges={onRenameConfirmed}
              inputLabel=""
              cancelChanges={() => {
                setEditState({
                  open: false,
                  name: '',
                  status: DataStatus.IDLE,
                  error: null,
                });
              }}
            />
          ) : (
            <Grid container>
              <Grid
                item
                sx={{
                  width: 'calc(100% - 1rem)',
                  ...ColumnStyle,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Title
                  statusIcon={statusIcon}
                  title={title}
                  sx={{ maxWidth: `calc(100% - ${px(MOCK_DATA_TAG_WIDTH)})` }}
                />
                {isMockData && <MockDataTag />}
              </Grid>
              <Grid
                item
                sx={{
                  width: '1rem',
                  ...CenteredColumnStyle,
                }}
              >
                <ContextMenu
                  menuOptions={contextMenuOptions}
                  menuId="individual-rs-context-menu"
                  iconSx={{ fontSize: '1.75rem' }}
                  origin="result-set"
                />
              </Grid>
            </Grid>
          )}
        </Box>
        {/** ************** TITLE - End ****************/}
        <Box sx={WRAPPER_2_SX}>
          {/** ************** BODY - Start ****************/}
          <Box sx={WRAPPER_3_SX}>
            {/* <InfoTextBlock infoList={cardContent} justifyOn={InfoTextSection.VALUE} /> */}
            <Box sx={{ ...WRAPPER_5_SX, width: '100%' }}>
              <CardInfo infoItems={cardContent} selected={selected} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: selected ? '#023D5E' : '#C9C9C9',
              }}
            >
              <MidDot />
              <InfoTextValue noWrap>{type}</InfoTextValue>
            </Box>
          </Box>

          {/* NOTE: Hiding the Info Icon for release 1 - Start */}
          {/* <Box>
            <InfoIcon
              onClick={handleInfoClick}
              sx={{ cursor: 'pointer', height: '1rem', width: '1rem' }}
              />
          </Box> */}
          {/* NOTE: Hiding the Info Icon for release 1 - End */}
        </Box>
        {/** ************** BODY - End ****************/}
      </Box>
    </Box>
  );
};

export default ResultSetCard;
