import { UUID } from '../../api/types';
import { del, fetch, generate } from '../rest';
import { APIResponse } from '../http';
import { APIClients, APICreateClient } from './types';

export async function listClients(userId: UUID): Promise<APIResponse<APIClients>> {
  return await fetch(`https://${window._env_.REACT_APP_API_IO_ENDPOINT}/users/${userId}/clients`);
}

export async function generateClient(userId: UUID): Promise<APIResponse<APICreateClient>> {
  return await generate(
    `https://${window._env_.REACT_APP_API_IO_ENDPOINT}/users/${userId}/clients`,
  );
}

export async function deleteClient(userId: UUID, clientId: string): Promise<APIResponse<null>> {
  return await del(
    `https://${window._env_.REACT_APP_API_IO_ENDPOINT}/users/${userId}/clients`,
    clientId,
  );
}
