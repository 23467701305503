import { Box, BoxProps, Toolbar } from '@mui/material';
import { FC, ReactElement } from 'react';
import UserAlerts from '../../../../components/alerts/UserAlerts';
import { StripePlaceholder } from '../../../../components/Iterator';
import { px } from '../../../../utils/styleUtils';
import { TOP_BAR_HEIGHT } from '../../TopBar/util';
import BannerSection from '../sections/BannerSection';
import LoadingIndicator from './LoadingIndicator';

export interface AppContentBoxProps extends BoxProps {
  isLoggedIn?: boolean;
  bannerContent?: BoxProps['children'];
  scrim?: boolean;
  showSkeletal?: boolean;
}

const ScrimContent: FC = () => {
  return <StripePlaceholder sx={{ height: '100%' }} />;
};

const AppContentBox: FC<AppContentBoxProps> = ({
  children = null,
  isLoggedIn = false,
  bannerContent = null,
  scrim = false,
  showSkeletal = false,
  ...boxProps
}: AppContentBoxProps) => {
  const renderAppContentBox = (): ReactElement => {
    if (showSkeletal) {
      return <LoadingIndicator />;
    }
    if (scrim) {
      return <ScrimContent />;
    }
    return (
      <>
        <UserAlerts />
        {!isLoggedIn ? <BannerSection>{bannerContent}</BannerSection> : children}
      </>
    );
  };
  return (
    <main data-testid="app-content-component" style={{ height: '100%' }}>
      {/* NOTE: The Toolbar component below is a spacer element, to prevent ResetPasswordPage from slipping under TopBar */}
      <Toolbar
        sx={{
          height: px(TOP_BAR_HEIGHT),
        }}
      />
      <Box
        {...boxProps}
        sx={{
          height: `calc(100% - ${px(TOP_BAR_HEIGHT)})`,
          overflow: 'auto',
          background: `url(${window._env_.REACT_APP_APP_ROOT}/img/bg-login.png) no-repeat center/cover fixed`,
          boxShadow: isLoggedIn || showSkeletal ? 'none' : '1px 1px 100px 1px #000 inset',
          ...boxProps.sx,
        }}
      >
        {renderAppContentBox()}
      </Box>
    </main>
  );
};

export default AppContentBox;
