import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled, useTheme } from '@mui/material/styles';
import { MuiAccordionSummaryProps } from './types';
import { LITE_TEXT_COLOR } from '../../utils/styleUtils';

const AccordionSummary = styled((props: MuiAccordionSummaryProps) => {
  const theme = useTheme();
  return (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: theme.spacing(1.8) }} />}
      {...props}
    />
  );
})(({ theme }) => ({
  backgroundColor: 'transparent',
  flexDirection: 'row-reverse',
  padding: 0,
  minHeight: 'auto',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    margin: theme.spacing(1),
    marginRight: 0,
    fontSize: theme.spacing(1.75), // 14px
    color: LITE_TEXT_COLOR,
    width: '100%',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    '& .MuiSvgIcon-root': {
      fontSize: theme.spacing(1.5), // 12px
      color: LITE_TEXT_COLOR,
    },
  },
}));
export default AccordionSummary;
