export enum APIStatus {
  pending = 'pending',
  completed = 'completed',
  failed = 'failed',
}

export interface ErrorItem {
  loc: string[];
  msg: string;
  type: string;
}

export interface RestAPIError {
  detail?: ErrorItem[];
}

export interface DownloadResponse {
  download: {
    urls: string[];
  };
}
