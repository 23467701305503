import { APICreatePortfolioInput, APIUpdatePortfolioInput } from '../rest/portfolio/types';
import { CreatePortfolioInput, FileContentInput } from '../api/upload/types';
import { UpdatePortfolioInput } from '../api/portfolios/types';
import { APIFileInput, APIFilePurpose } from '../rest/file/types';

export function convertPortfolioInput(portfolio: CreatePortfolioInput): APICreatePortfolioInput {
  const { name, fileUploadId, categoryId, description } = portfolio;
  return {
    name,
    description,
    file_validation: fileUploadId,
    category: categoryId,
  };
}

export function convertPortfolioUpdateInput(
  portfolio: Omit<UpdatePortfolioInput, 'id'>,
): APIUpdatePortfolioInput {
  const { name, categoryId, description } = portfolio;
  return {
    name,
    description,
    category: categoryId,
  };
}

export function convertPortfolioFileInput(fileInput: FileContentInput, file: File): APIFileInput {
  return {
    file,
    purpose: APIFilePurpose.portfolio_upload,
  };
}
