import { FC } from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from '../../../redux/hooks';
import { selectIsLoggedIn } from '../../../redux/selectors/userSelectors';
import NavigationDropdown from '../../navigation/NavigationDropdown';
import PortfolioNavHeader from './PortfolioNavHeader';
import { selectAIAssistantVisible } from '../../../redux/selectors/uiSelectors';

const NavHeader: FC = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const shouldShowAIAssistantLink = useAppSelector(selectAIAssistantVisible);
  const appendAIAssistantLinkWidth = shouldShowAIAssistantLink ? '120px' : '0px';

  return (
    <>
      {/* COLUMN 1 */}
      <Box
        sx={{
          alignItems: 'flex-start',
          justifyContent: !isLoggedIn ? 'center' : 'space-between',
          maxWidth: {
            xs: `calc(44vw + ${appendAIAssistantLinkWidth})`,
            sm: `calc(44vw + ${appendAIAssistantLinkWidth})`,
            md: `calc(35vw + ${appendAIAssistantLinkWidth})`,
          },
        }}
      >
        <PortfolioNavHeader />
      </Box>

      {/* COLUMN 2 */}
      <Box
        sx={{
          maxWidth: {
            xs: '55vw',
            sm: '55vw',
            md: '64vw',
          },
        }}
      >
        <NavigationDropdown />
      </Box>
    </>
  );
};

export default NavHeader;
