import { Box, Grid, Link, Toolbar, Typography } from '@mui/material';
import { FC } from 'react';
import AppWrapper from '../components/AppWrapper';
import TextUnderlineButton from '../components/TextUnderlineButton';
import { px } from '../utils/styleUtils';
import { TOP_BAR_HEIGHT } from './main/TopBar/util';

const NotFoundPage: FC = () => (
  <AppWrapper scrim>
    <Toolbar sx={{ height: px(TOP_BAR_HEIGHT) }} />
    <Box
      sx={{
        height: `calc(100% - ${px(TOP_BAR_HEIGHT)})`,
        width: '100%',
        overflow: 'auto',
        color: '#000',
        background: '#fff',
      }}
    >
      <Grid
        container
        alignContent="center"
        alignItems="center"
        data-testid="not-found-page"
        flexDirection="column"
        spacing={2}
        sx={{ width: '100%', margin: 'auto' }}
      >
        <Grid item xs>
          <Typography variant="h2">Page Not Found</Typography>
        </Grid>
        <Grid item xs>
          <Link href="/" sx={{ width: '100%', textAlign: 'center' }}>
            <TextUnderlineButton
              TypographyProps={{
                sx: {
                  fontSize: '0.875rem',
                  color: '#000',
                },
                variant: 'h6',
              }}
            >
              Return to Homepage
            </TextUnderlineButton>
          </Link>
        </Grid>
      </Grid>
    </Box>
  </AppWrapper>
);

export default NotFoundPage;
