/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  Action,
  AnyAction,
  combineReducers,
  configureStore,
  PreloadedState,
  ThunkAction,
} from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import csgDataReducer from './slices/csgDataSlice';
import queryBuilderReducer from './slices/queryBuilderSlice';
import uiReducer from './slices/uiSlice';
import uploadReducer from './slices/uploadSlice';
import userReducer from './slices/userSlice';
import visualizationsReducer from './slices/visualizationsSlice';
import slrReducer from './slices/slrSlice';

/* IDEA: Customize persistence

const queryBuilderPersistConfig = {
  key: 'queryBuilder',
  storage,
  blacklist: ['queryBuilderOptions', 'perils', 'damageAndLoss', 'floodMesh', 'scores'],
  whitelist: ['status', 'reportName', 'portfolioId'],
};

queryBuilder: persistReducer(queryBuilderPersistConfig, queryBuilderReducer),

*/

// Create the root reducer independently to obtain the RootState type
const combinedReducer = combineReducers({
  user: userReducer,
  ui: uiReducer,
  upload: uploadReducer,
  csgData: csgDataReducer,
  viz: visualizationsReducer,
  queryBuilder: queryBuilderReducer,
  slr: slrReducer,
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === 'user/logout/pending') {
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  // stateReconciler: hardSet, // NOTE: `hardSet` does not appear to work properly; autoMergeLevel2 is the best alternative
  stateReconciler: autoMergeLevel2,
};

// https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
export type RootReducer = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer<RootReducer, AnyAction>(persistConfig, rootReducer);

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
    devTools: true,
    preloadedState,
  });

  return store;
};

export type AppDispatch = ReturnType<typeof setupStore>['dispatch'];
export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// export const store = setupStore();
export const store = setupStore({}); // no pre-loaded state

export const persistor = persistStore(store);

export default store;
