export const isSafari = (): boolean => window?.safari !== undefined;

const fallbackCopyTextToClipboard = (text: string): void => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';

    // eslint-disable-next-line no-console
    console.log(`Fallback: Copying text command was ${msg}`);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
};

export const copyTextToClipboard = async (text: string): Promise<void> => {
  try {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // eslint-disable-next-line no-console
        console.log('[copyTextToClipboard]: Copying to clipboard was successful!');
      })
      .catch((asyncError) => {
        console.error('[copyTextToClipboard]: Could not copy text: ', asyncError);
        // eslint-disable-next-line no-console
        console.log('[copyTextToClipboard]: Logging out input text\n', text);
      });
  } catch (fnError) {
    console.error('[copyTextToClipboard]: Something went wrong: ', fnError);
    // eslint-disable-next-line no-console
    console.log('[copyTextToClipboard]: Logging out input text\n', text);
  }
};

export const clearLocalStorage = ({ persist = [] }: { persist?: string[] }): void => {
  try {
    // persist
    const persistedList = persist.map((key) => {
      return {
        key,
        value: localStorage.getItem(key) ?? '',
      };
    });

    // clean
    window.localStorage.clear();

    // restore
    persistedList.forEach(({ key, value }) => {
      localStorage.setItem(key, value);
    });
  } catch (err) {
    console.error('clearLocalStorage ERROR', err);
  }
};
