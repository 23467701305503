import { APIResponse } from '../http';
import { getUsageStats } from './usage';
import { convertUsageStats } from '../../restUtils/restToGqlUsageStats';
import { UsageStatsType } from '../../pages/main/SettingsDrawer/types';
import { APIStatus, DownloadResponse } from '../types';
import { createExport, getExport } from '../export/export';
import { APIExportFileType } from '../export/types';
import { createFileDownloadLink } from '../file/file';
import { APIFileDownloadLink } from '../file/types';

export async function getUsageStatsAdapter(): Promise<APIResponse<UsageStatsType>> {
  const { data, error } = await getUsageStats();
  if (error) {
    return { data: null, error };
  }
  return { data: convertUsageStats(data), error: null };
}

export async function getUsageTrackingDownloadUrlsAdapter(): Promise<
  APIResponse<DownloadResponse>
> {
  const { data, error } = await createExport({
    file_type: APIExportFileType.csv,
    options: {
      object_type: 'usage_tracking',
    },
  });
  if (error) {
    return { data: null, error };
  }
  let apiExport = data;
  /* eslint-disable no-await-in-loop */
  while (apiExport.status === APIStatus.pending) {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 5000));
    const apiResponse = await getExport(apiExport.id);
    if (apiResponse.error) {
      return { data: null, error: apiResponse.error };
    }
    apiExport = apiResponse.data;
  }

  if (apiExport.status === APIStatus.failed) {
    return { data: null, error: new Error('Export failed.') };
  }

  const { files } = apiExport;

  const responses = await Promise.all(
    files.map(async (file) => await createFileDownloadLink(file.id, { expires_in: 3600 })),
  );
  const errResponse = responses.find((response) => response.error);
  // If there is at least one error
  if (errResponse) {
    return { data: null, error: errResponse.error as Error };
  }
  // There are zero errors
  return {
    data: {
      download: {
        urls: responses.map((link) => (link.data as APIFileDownloadLink).url),
      },
    },
    error: null,
  };
}
