import { Circle as CircleIcon } from '@mui/icons-material';
import { List, ListItem, Typography, popoverClasses } from '@mui/material';
import { FC } from 'react';
import Menu from '../../components/menu/Menu';
import { MenuPositionX } from '../../components/menu/types';
import SearchInput from '../../components/text-input/SearchInput';
import { dotIconSx, emptyMenuItemSx, listItemSx, listSx, searchInputSx } from './styleUtils';

// IDEA: Use Iterator as the container here, similar to PortfolioStripe

export type ButtonRefEl = HTMLButtonElement | null;
export type ClickAnchorHandler = (element: ButtonRefEl) => void;

export type ElementId = string | undefined;
export type ClickButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => void;

interface BreadcrumbListItem {
  id: string;
  selected: boolean;
  title: string;
  onClick: (item: BreadcrumbListItem) => void;
}

interface BreadcrumbDropdownMenuProps {
  elementId: ElementId;
  isMenuOpen: boolean;
  anchorEl: ButtonRefEl;
  onCloseMenu: ClickButtonHandler;
  listItems: BreadcrumbListItem[];
  menuDataTestId: string;
  listDataTestId: string;
  emptyMenuItemText: string;
  // optional search
  withSearch?: boolean;
  searchString?: string;
  setSearchString?: (args: any) => void;
  searchInputDataTestId?: string;
}

const BreadcrumbDropdownMenu: FC<BreadcrumbDropdownMenuProps> = ({
  elementId,
  isMenuOpen,
  anchorEl,
  onCloseMenu,
  menuDataTestId,
  listItems,
  listDataTestId,
  emptyMenuItemText,
  // optional search
  withSearch = false,
  searchString = '',
  setSearchString = () => {
    // eslint-disable-next-line no-console
    console.warn('setSearchString callback not defined');
  },
  searchInputDataTestId = 'searchInputDataTestId-not-defined',
}) => {
  return (
    <Menu
      data-testid={menuDataTestId}
      aria-labelledby={menuDataTestId}
      id={elementId}
      open={isMenuOpen}
      anchorEl={anchorEl}
      onClose={onCloseMenu}
      sx={{
        [`& .${popoverClasses.paper}`]:
          listItems.length > 0
            ? {
                paddingTop: 0,
              }
            : {},
      }}
      horzOrientation={MenuPositionX.LEFT}
    >
      {listItems.length > 0 || searchString.length > 0 ? (
        <>
          {withSearch && (
            <SearchInput
              data-testid={searchInputDataTestId}
              searchInput={searchString}
              onSearchInputChange={setSearchString}
              sx={searchInputSx}
            />
          )}
          <List data-testid={listDataTestId} sx={listSx}>
            {listItems.map((item) => {
              const { id, selected, title, onClick } = item;

              return (
                <ListItem
                  sx={listItemSx}
                  onClick={() => {
                    onClick(item);
                  }}
                  key={id}
                >
                  <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }} noWrap>
                    <CircleIcon sx={dotIconSx(selected)} />
                    {title}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
        </>
      ) : (
        <Typography variant="h6" sx={emptyMenuItemSx} noWrap>
          {emptyMenuItemText}
        </Typography>
      )}
    </Menu>
  );
};
export default BreadcrumbDropdownMenu;
