import { FC, useCallback, useEffect, useState } from 'react';
import { Category, Portfolio } from '../../../api/portfolios/types';
import { AlertSeverity } from '../../../components/alerts/types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectCategories } from '../../../redux/selectors/csgDataSelectors';
import { refreshCategoriesList, setPortfolio } from '../../../redux/slices/csgDataSlice';
import { addAlert } from '../../../redux/slices/uiSlice';
import CategorySelector from '../../upload/CategorySelector/CategorySelector';
import {
  CategoryOptionNullishType,
  CategorySelectorContainerProps,
  IdleStateVariant,
} from '../../upload/CategorySelector/types';
import { RemovePortfolioCategoryInput } from './types';
import { createCategoryAdapter } from '../../../rest/category/adapter';
import { updatePortfolioAdapter } from '../../../rest/portfolio/adapter';

interface Props extends CategorySelectorContainerProps {
  portfolio: Portfolio;
  isEditingCategory: boolean;
  onCancel: () => void;
  onCategoryRemoved: (shouldCloseMenu: boolean) => void;
  onCategoryApplied: (shouldCloseMenu: boolean) => void;
}

const CategorySelectorContainer: FC<Props> = ({
  isEditingCategory,
  onCancel,
  onCategoryRemoved,
  onCategoryApplied,
  portfolio,
  ...boxProps
}) => {
  const dispatch = useAppDispatch();
  const portfolioCategories = useAppSelector(selectCategories);
  const [categoryValue, setCategoryValue] = useState<CategoryOptionNullishType>(
    portfolio?.category ?? null,
  );

  useEffect(() => {
    setCategoryValue(portfolio?.category ?? null);
  }, [portfolio]);

  const removeCategory = async ({
    portfolioId,
    portfolioName,
  }: RemovePortfolioCategoryInput): Promise<void> => {
    const mutationResponse = await updatePortfolioAdapter(portfolioId, {
      name: portfolioName,
      categoryId: null, // This, sent to the API, will remove the category
    });

    const { data, error } = mutationResponse;

    if (data) {
      const { id } = data;

      if (id !== portfolioId) {
        dispatch(
          addAlert({
            open: true,
            message: 'Category removal could not be confirmed.',
            severity: AlertSeverity.Error,
          }),
        );

        onCategoryRemoved(false);
        return;
      }

      dispatch(
        setPortfolio({
          ...portfolio,
          category: null, // <-- This does: Locally, set portfolio category to null, which means no-category
        }),
      );

      dispatch(
        addAlert({
          open: true,
          message: 'Category removed successfully.',
          severity: AlertSeverity.Success,
        }),
      );

      onCategoryRemoved(true);
      return;
    }

    if (error) {
      dispatch(
        addAlert({
          open: true,
          message: error.message,
          severity: AlertSeverity.Error,
        }),
      );
    }

    onCategoryRemoved(true);
  };

  const disassociateCategoryFromPortfolio = useCallback(
    async () => {
      // returns a curried function
      await removeCategory({
        portfolioId: portfolio.id,
        portfolioName: portfolio.name,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [portfolio],
  );

  const applyCategory = async ({
    categoryToBeApplied,
    portfolioId,
    portfolioName,
  }: {
    categoryToBeApplied: Category;
    portfolioId: string;
    portfolioName: string;
  }): Promise<boolean> => {
    setCategoryValue(categoryToBeApplied);

    const mutationResponse = await updatePortfolioAdapter(portfolioId, {
      name: portfolioName,
      categoryId: categoryToBeApplied.id,
    });

    const { data, error } = mutationResponse;

    if (data) {
      const { id } = data;

      if (id !== portfolioId) {
        dispatch(
          addAlert({
            open: true,
            message: 'Category assignment could not be confirmed.',
            severity: AlertSeverity.Error,
          }),
        );

        onCategoryApplied(false);
        return false;
      }

      dispatch(
        setPortfolio({
          ...portfolio,
          category: categoryToBeApplied,
        }),
      );

      dispatch(
        addAlert({
          open: true,
          message: 'Portfolio updated.',
          severity: AlertSeverity.Success,
        }),
      );

      onCategoryApplied(true);
      return true;
    }

    if (error) {
      dispatch(
        addAlert({
          open: true,
          message: error.message,
          severity: AlertSeverity.Error,
        }),
      );
    }

    onCategoryApplied(true);
    return true;
  };

  const assignCategoryToPortfolio = useCallback(
    async ({ categoryToBeApplied }: { categoryToBeApplied: Category }) => {
      // returns a curried function
      return await applyCategory({
        categoryToBeApplied,
        portfolioId: portfolio.id,
        portfolioName: portfolio.name,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [portfolio],
  );

  const createAndAssignNewCategory = async ({
    categoryName,
  }: {
    categoryName: string;
  }): Promise<{ id: string; name: string } | null> => {
    const mutationResponse = await createCategoryAdapter({ name: categoryName });

    const { data, error } = mutationResponse;

    if (error) {
      dispatch(
        addAlert({
          severity: AlertSeverity.Error,
          open: true,
          message: error.message,
        }),
      );
      onCategoryApplied(false);
      return null;
    }

    if (data) {
      const { id, name } = data;

      if (!id) {
        dispatch(
          addAlert({
            open: true,
            severity: AlertSeverity.Error,
            message: 'Unhandled error in adding category (id not found)',
          }),
        );
        onCategoryApplied(false);
        return null;
      }

      dispatch(
        addAlert({
          severity: AlertSeverity.Success,
          open: true,
          message: `Category "${categoryName}" has been created`,
        }),
      );

      // assign selectedCategory
      const newCategoryValue = { id, name };

      const successful = await assignCategoryToPortfolio({ categoryToBeApplied: newCategoryValue });

      if (!successful) {
        console.error('assignCategoryToPortfolio was unsuccessful!!');
        onCategoryApplied(false);
        return null;
      }

      await dispatch(refreshCategoriesList());
      onCategoryApplied(true);
      return newCategoryValue;
    }

    onCategoryApplied(false);
    console.error(
      '[createAndAssignNewCategory] Unhandled: neither `data` nor `error` is available.',
    );
    return data;
  };

  const assignCategory = async (newCategoryValue: any): Promise<boolean> => {
    if (newCategoryValue?.name) {
      const successful = await assignCategoryToPortfolio({ categoryToBeApplied: newCategoryValue });

      if (!successful) {
        console.error('assignCategory was unsuccessful!!');
        return false;
      }

      return true;
    }
    console.error('Name not found for category:', newCategoryValue);
    return false;
  };

  return (
    <CategorySelector
      {...boxProps}
      onCancel={onCancel}
      isEditingCategory={isEditingCategory}
      categories={portfolioCategories}
      selectedCategory={categoryValue}
      onCreateAndAssignNewCategory={createAndAssignNewCategory}
      onAssignCategory={assignCategory}
      onDisassociateCategory={disassociateCategoryFromPortfolio}
      idleStateVariant={IdleStateVariant.INFO_TEXT}
      externalControls
      AutocompleteProps={{
        sx: {
          display: 'inline-flex',
          width: '100%',
          height: '100%',
        },
      }}
      TypographyProps={{
        sx: {
          textTransform: 'uppercase',
        },
      }}
    />
  );
};

export default CategorySelectorContainer;
