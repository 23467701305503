import { FC, Children, ReactElement } from 'react';
import { Box, BoxProps, Grow } from '@mui/material';

interface GrowListProps extends BoxProps {
  interval?: number;
  children: ReactElement | ReactElement[];
}

const GrowList: FC<GrowListProps> = ({ interval = 200, children, ...rest }) => {
  const elements = Array.isArray(children) ? children.filter(Boolean) : [children].filter(Boolean);

  return (
    <Box {...rest}>
      {Children.map(elements, (child: ReactElement, index) => (
        <Grow in timeout={(index + 1) * interval}>
          {/* NOTE: Box has to be here so that Grow has something to pass a ref to */}
          <Box>{child}</Box>
        </Grow>
      ))}
    </Box>
  );
};

export default GrowList;
