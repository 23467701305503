import { UUID } from '../../api/types';
import { create, del, list, update } from '../rest';
import { APIResponse } from '../http';
import { APICreateCategoryInput, APICategory } from './types';
import { MAX_LIST_API_LIMIT } from '../constants';

export async function listCategories(): Promise<APIResponse<APICategory[]>> {
  return await list('/categories', { limit: MAX_LIST_API_LIMIT });
}

export async function createCategory(
  input: APICreateCategoryInput,
): Promise<APIResponse<APICategory>> {
  return await create('/categories', input);
}

export async function updateCategory(id: UUID, name: string): Promise<APIResponse<APICategory>> {
  return await update('/categories', id, { name });
}

export async function deleteCategory(id: UUID): Promise<APIResponse<null>> {
  return await del('/categories', id);
}
