import { createTheme } from '@mui/material/styles';

// [TODO]: Temp font style fix, remove this style fix once font vertical alignment issue is resolved
export const FONT_ALIGNED_TEXT_STYLE = {
  paddingTop: '3px',
};

export const LOG_COLOR = {
  info: '#1F61A0',
  success: '#0F9D58',
  error: '#DB4437',
  warning: '#F4B400',
};

const theme = createTheme({
  // https://mui.com/material-ui/customization/palette/
  palette: {
    primary: {
      main: '#079AED',
    },
    secondary: {
      main: '#ED5A07',
    },
  },
  typography: {
    fontFamily: 'NeueHelveticaPro55Roman, Noto Sans Display, sans-serif',
    // fontFamily:
    //   'HelveticaNeue-Light, HelveticaNeue-Bold, HelveticaNeue, Noto Sans Display, sans-serif',
    // NOTE: For `rem` reference, see App.css
    h1: {
      fontSize: '4.5rem', // 4.5rem :: 72px
      fontWeight: '400', // fontWeight 400 :: "-Regular"
      ...FONT_ALIGNED_TEXT_STYLE,
    },
    h2: {
      fontSize: '3.75rem', // 3.75rem :: 60px
      ...FONT_ALIGNED_TEXT_STYLE,
    },
    h3: {
      fontSize: '2.75rem', // 2.75rem :: 42px
      ...FONT_ALIGNED_TEXT_STYLE,
    },
    h4: {
      fontSize: '2rem', // 2rem :: 32px`
      fontWeight: '400', // fontWeight 400 :: "-Regular"
      ...FONT_ALIGNED_TEXT_STYLE,
    },
    h5: {
      fontSize: '1.5rem', // 1.5rem :: 22px
      fontWeight: '400', // fontWeight 400 :: "-Regular"
      ...FONT_ALIGNED_TEXT_STYLE,
    },
    h6: {
      fontSize: '0.875rem', // 0.875rem :: 12px
      fontWeight: '400', // 400 :: "-Regular"
      letterSpacing: '0',
      ...FONT_ALIGNED_TEXT_STYLE,
    },
    caption: {
      fontSize: '0.875rem', // 0.875rem :: 12px
      fontWeight: '300', // 300 :: "-Light"
      ...FONT_ALIGNED_TEXT_STYLE,
    },
    body1: {
      fontSize: '0.75rem', // 0.75rem :: 12px
      fontWeight: '400', // fontWeight 400 :: "-Regular"
      letterSpacing: '0.75px',
      ...FONT_ALIGNED_TEXT_STYLE,
    },
    body2: {
      fontSize: '0.625rem', // 0.625rem :: 10px
      fontWeight: '500', // 500 :: "-Medium"
      ...FONT_ALIGNED_TEXT_STYLE,
    },
    button: {
      textTransform: 'none',
      fontSize: '0.875rem', // 0.875rem :: 14px
      fontWeight: '400', // fontWeight 400 :: "-Regular"
      ...FONT_ALIGNED_TEXT_STYLE,
    },
    subtitle1: {
      fontSize: '0.75rem', // 0.75rem :: 12px
      fontWeight: '400', // fontWeight 400 :: "-Regular"
      letterSpacing: '0.75px',
      ...FONT_ALIGNED_TEXT_STYLE,
    },
    subtitle2: {
      fontSize: '0.625rem', // 0.625rem :: 10px
      fontWeight: '500', // fontWeight 500 :: "-Medium"
      letterSpacing: '0.75px',
      ...FONT_ALIGNED_TEXT_STYLE,
    },
    // Also available: overline
  },

  components: {
    MuiIcon: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          // [NOTE]: Not overriding style here and doing it under `typography` objects above to fix
          // off-center issue for input fields as well, it does not work if we override style here
          // ...FONT_ALIGNED_TEXT_STYLE,
        },
      },
      defaultProps: {
        variantMapping: {
          // All `div` is to avoid the "<h2> cannot appear as a child of <h2>" (and similar) warnings
          h1: 'div',
          h2: 'div',
          h3: 'div',
          h4: 'div',
          h5: 'div',
          h6: 'div',
          subtitle1: 'div',
          subtitle2: 'div',
          body1: 'div',
          body2: 'div',
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem', // h6
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          fontSize: '0.875rem', // h6
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem', // body1
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          fontSize: '0.75rem', // body1
        },
        selectLabel: {
          fontSize: '0.75rem', // body1
        },
        displayedRows: {
          fontSize: '0.75rem', // body1
        },
      },
    },
  },
});

export default theme;
