import { FilterAlt as FilterAltIcon } from '@mui/icons-material';
import { FC } from 'react';
import TextInput, { TextInputProps } from './TextInput';
import { VALUE_TEXT_COLOR } from '../../utils/styleUtils';

export type SearchInputProps = TextInputProps & {
  searchInput: string;
  onSearchInputChange: (event: any) => void;
};

const SearchInput: FC<SearchInputProps> = ({
  searchInput,
  onSearchInputChange,
  sx,
  ...textInputProps
}) => (
  <TextInput
    InputProps={{
      startAdornment: (
        <FilterAltIcon
          sx={{
            fontSize: '1rem',
            color: VALUE_TEXT_COLOR,
            paddingLeft: 0,
          }}
        />
      ),
    }}
    formControlProps={{
      sx: {
        margin: 0,
        width: '100%',
        overflow: 'hidden',
      },
    }}
    sx={{
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0.375,
      paddingBottom: 0.375,
      margin: 0,
      background: '#fff',
      '& .MuiInputBase-root': {
        height: '1.5rem',
        background: '#fff',
      },
      ...sx,
    }}
    value={searchInput}
    onChange={({ target }) => {
      onSearchInputChange(target.value);
    }}
    {...textInputProps}
  />
);

export default SearchInput;
