import { Box, BoxProps, Icon } from '@mui/material';
import { FC, ReactElement, ReactNode } from 'react';
import { CenteredRowStyle, APP_TEXT_COLOR } from '../../utils/styleUtils';

interface BorderedButtonMaskProps extends BoxProps {
  children?: ReactElement | ReactElement[];
  prependIcon?: string | ReactNode | null;
  appendIcon?: string | ReactNode | null;
}

const BorderedButtonMask: FC<BorderedButtonMaskProps> = ({
  children,
  prependIcon = null,
  appendIcon = null,
  sx = {},
  ...buttonMaskProps
}: BorderedButtonMaskProps) => {
  return (
    <Box
      sx={{
        ...CenteredRowStyle,
        justifyContent: 'flex-start',
        alignItems: 'center',
        border: `1px solid ${APP_TEXT_COLOR}`,
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        paddingRight: '2px',
        paddingLeft: '2px',
        backgroundColor: '#FFF',
        borderRadius: '4px',
        maxWidth: 'fit-content',
        color: APP_TEXT_COLOR,
        cursor: 'pointer',
        ...(sx ?? {}),
      }}
      {...buttonMaskProps}
    >
      <>
        {prependIcon && (
          <Icon
            sx={{
              width: 'auto',
              display: 'flex',
              background: 'transparent',
              alignItems: 'center',
              svg: {
                fontSize: '0.875rem',
                height: '0.875rem',
              },
            }}
          >
            {prependIcon}
          </Icon>
        )}
        <Box
          sx={{
            borderRadius: '4px',
            width: '100%',
            background: 'transparent',
            borderWidth: '1px',
            ...CenteredRowStyle,
            alignItems: 'center',
            height: '100%',
          }}
        >
          {children}
        </Box>
        {appendIcon && (
          <Icon
            sx={{
              width: 'auto',
              display: 'flex',
              background: 'transparent',
              alignItems: 'center',
              svg: {
                fontSize: '0.875rem',
                height: '0.875rem',
              },
            }}
          >
            {appendIcon}
          </Icon>
        )}
      </>
    </Box>
  );
};

export default BorderedButtonMask;
