import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFoundPage from '../pages/NotFoundPage';
import HealthCheckPage from '../pages/health-check/HealthCheckPage';
// import InvitePage from '../pages/invite/InvitePage';
import MainPage from '../pages/main/MainPage';
// import ResetPasswordPage from '../pages/reset-password/ResetPasswordPage';
import './App.css';
import useAlerts from './alerts/useAlerts';
import { AutoLoginPage } from '../pages/auth0/Auth0AutoLogin';
import { CallbackPage } from '../pages/auth0/Auth0Callback';
// import ProtectedRoute from './protected-route/ProtectedRoute';

const App: FC = () => {
  useAlerts();

  return (
    <main className="app-wrapper">
      <Routes>
        <Route path="/" element={<MainPage />} />
        {/* <Route
          path="/protected"
          element={
            <ProtectedRoute>
              <div>Something secret</div>
            </ProtectedRoute>
          }
        /> */}
        {/* <Route path="/reset" element={<ResetPasswordPage />} />
        <Route path="/invite" element={<InvitePage />} /> */}
        <Route path="/health" element={<HealthCheckPage />} />
        <Route path="/login" element={<AutoLoginPage />} />
        <Route path="/callback" element={<CallbackPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </main>
  );
};

export default App;
