import { FC, useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Toolbar } from '@mui/material';
import AppWrapper from '../../components/AppWrapper';
import { px } from '../../utils/styleUtils';
import { TOP_BAR_HEIGHT } from '../main/TopBar/util';
import { useAppDispatch } from '../../redux/hooks';
import { userLogout } from '../../redux/slices/userSlice';
import LoadingIndicator from '../main/app-content/components/LoadingIndicator';

export const AutoLoginPage: FC = () => {
  const { logout } = useAuth0();
  const dispatch = useAppDispatch();

  const logoutAndRedirectUser = useCallback(async (): Promise<void> => {
    // eslint-disable-next-line no-console
    console.log('Logging out now.');
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    await dispatch(userLogout());
  }, [dispatch, logout]);

  useEffect(() => {
    void logoutAndRedirectUser();
  }, [logoutAndRedirectUser]);

  return (
    <AppWrapper showSkeletal>
      <main data-testid="app-content-component" style={{ height: '100%' }}>
        {/* NOTE: The Toolbar component below is a spacer element, to prevent body content from slipping under TopBar */}
        <Toolbar
          sx={{
            height: px(TOP_BAR_HEIGHT),
          }}
        />
        <Box
          sx={{
            height: `calc(100% - ${px(TOP_BAR_HEIGHT)})`,
            overflow: 'auto',
            backgroundImage: 'unset',
            backgroundColor: '#fff',
          }}
        >
          <LoadingIndicator />
        </Box>
      </main>
    </AppWrapper>
  );
};
