import { FC, MouseEvent, ReactNode, forwardRef, useCallback, useMemo, useRef } from 'react';
import {
  Box,
  IconButton,
  IconButtonProps,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { ArrowBackIosNew as ArrowBackIosNewIcon } from '@mui/icons-material';
import { isNil } from 'lodash';
import { NAV_SEPARATOR_COLOR, StyleProps } from '../../utils/styleUtils';
import { StyledBoldLabel, StyledMediumLabel, StyledThinLabel } from '../labels/StyledLabel';

export enum LabelWeight {
  THIN = 'THIN',
  NORMAL = 'NORMAL',
  MEDIUM = 'MEDIUM',
  BOLD = 'BOLD',
}

type DropdownHandleProps = IconButtonProps & {
  isHandleOn: boolean;
  menu?: ReactNode;
  iconSx?: StyleProps;
  label?: string;
  labelWeight?: LabelWeight;
  labelProps?: TypographyProps;
  onClickMenu?: (element: HTMLButtonElement | null) => void;
};
type Ref = HTMLButtonElement;

const DropdownHandle: FC<DropdownHandleProps> = forwardRef<Ref, DropdownHandleProps>(
  (
    {
      isHandleOn,
      menu = null,
      iconSx = {},
      label = '',
      labelWeight = LabelWeight.MEDIUM,
      labelProps = {},
      disabled = false,
      sx: buttonSx,
      onClickMenu,
      ...props
    }: DropdownHandleProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const theme = useTheme();
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const { sx: lblSx, onClick: onLabelClick, ...otherLabelProps } = labelProps;
    const labelSx = useMemo<TypographyProps['sx']>(
      () => ({
        ...lblSx,
        ...(disabled
          ? { color: NAV_SEPARATOR_COLOR, pointerEvents: 'none' }
          : { cursor: 'pointer' }),
      }),
      [lblSx, disabled],
    );

    const handleClick = useCallback(() => {
      if (!isNil(onClickMenu)) {
        if (!isNil(buttonRef.current)) {
          onClickMenu(buttonRef.current);
        } else {
          onClickMenu(null);
        }
      }
    }, [onClickMenu]);

    const handleLabelClick = useCallback(
      (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        handleClick();
        if (!isNil(onLabelClick)) {
          onLabelClick(e);
        }
      },
      [onLabelClick, handleClick],
    );

    const handleButtonClick = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        handleClick();
      },
      [handleClick],
    );

    const labelElement = useMemo(() => {
      if (!label) return null;

      const defLabelProps: TypographyProps = {
        sx: labelSx,
        onClick: handleLabelClick,
      };

      switch (labelWeight) {
        case LabelWeight.BOLD:
          return (
            <StyledBoldLabel {...defLabelProps} {...otherLabelProps}>
              {label}
            </StyledBoldLabel>
          );
        case LabelWeight.THIN:
          return (
            <StyledThinLabel {...defLabelProps} {...otherLabelProps}>
              {label}
            </StyledThinLabel>
          );
        case LabelWeight.NORMAL:
          return (
            <Typography {...defLabelProps} {...otherLabelProps}>
              {label}
            </Typography>
          );
        case LabelWeight.MEDIUM:
        default:
          return (
            <StyledMediumLabel {...defLabelProps} {...otherLabelProps}>
              {label}
            </StyledMediumLabel>
          );
      }
    }, [label, labelWeight, labelSx, handleLabelClick, otherLabelProps]);

    return (
      <>
        {labelElement}
        <IconButton
          ref={ref ?? buttonRef}
          disabled={disabled}
          onClick={handleButtonClick}
          sx={{
            ...buttonSx,
            '&:disabled': {
              color: NAV_SEPARATOR_COLOR,
            },
          }}
          {...props}
        >
          <Box
            sx={{
              padding: `${theme.spacing(1.25)} ${theme.spacing(1)}`,
              height: theme.spacing(1.5),
              width: theme.spacing(3.5),
              position: 'relative',
            }}
          >
            <ArrowBackIosNewIcon
              sx={{
                transform: isHandleOn ? 'rotate(90deg)' : 'rotate(-90deg)',
                height: theme.spacing(1.5),
                width: theme.spacing(1.5),
                position: 'absolute',
                top: theme.spacing(0.5),
                left: `calc(50% - ${theme.spacing(0.75)})`,
                ...iconSx,
              }}
            />
          </Box>
        </IconButton>
        {menu}
      </>
    );
  },
);

DropdownHandle.displayName = 'DropdownHandle';

export default DropdownHandle;
