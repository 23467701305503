import { isNull, orderBy } from 'lodash';
import {
  ResultSetType,
  ResultSet,
  ResultSetStatus,
  BenchmarkingLevel,
  Scenario,
} from '../../api/resultSets/types';
import { UUID } from '../../api/types';
import {
  CreateSLRInput,
  SLRColorCoding,
  SLRScenarioType,
  SLRTemplate,
  SLRTemplateJSON,
  SLRTemplateType,
} from './types';
import { EIModules } from '../../pages/query-builder/types';

const getRsScenarioFromSSP = (type: SLRScenarioType): Scenario | null => {
  switch (type) {
    case SLRScenarioType.SSP2:
      return Scenario.SSP_245;
    case SLRScenarioType.SSP5:
      return Scenario.SSP_585;
    default:
      return null;
  }
};

const getOrderedResultSetsByVersionAndType = (
  resultSets: ResultSet[],
  portfolioId: UUID,
  rsType: ResultSetType,
  dataVersion: string,
  scenario?: Scenario | null,
  eiModule?: EIModules | null,
): ResultSet[] => {
  const portfolioResultSets = resultSets.filter(
    (item) =>
      item.portfolioId === portfolioId &&
      item.type === rsType &&
      item.parameters.csgVersion === dataVersion &&
      item.status === ResultSetStatus.COMPLETED &&
      (!scenario || item.parameters.scenarios?.includes(scenario)) &&
      (!eiModule || !item.parameters.eiModules || item.parameters.eiModules.includes(eiModule)),
  );
  return portfolioResultSets.length ? orderBy(portfolioResultSets, 'createdAt', 'desc') : [];
};

const getSLRTemplateJSON = (
  template: SLRTemplateType,
  scenario: SLRScenarioType,
): SLRTemplateJSON => {
  switch (template) {
    case SLRTemplateType.CR_BASIC: {
      return {
        type: SLRTemplate.BASIC,
        scenario,
        colorCoding: SLRColorCoding.CHANGE,
      };
    }
    case SLRTemplateType.EX_BASIC: {
      return {
        type: SLRTemplate.BASIC,
        scenario,
        colorCoding: SLRColorCoding.EXPOSURE,
      };
    }
    case SLRTemplateType.CR_STANDARD: {
      return {
        type: SLRTemplate.STANDARD,
        scenario,
        colorCoding: SLRColorCoding.CHANGE,
      };
    }
    case SLRTemplateType.EX_STANDARD: {
      return {
        type: SLRTemplate.STANDARD,
        scenario,
        colorCoding: SLRColorCoding.EXPOSURE,
      };
    }
    case SLRTemplateType.CR_ADVANCED: {
      return {
        type: SLRTemplate.ADVANCED,
        scenario,
        colorCoding: SLRColorCoding.CHANGE,
      };
    }
    case SLRTemplateType.EX_ADVANCED: {
      return {
        type: SLRTemplate.ADVANCED,
        scenario,
        colorCoding: SLRColorCoding.EXPOSURE,
      };
    }
    case SLRTemplateType.FIDELITY: {
      return {
        type: SLRTemplate.FIDELITY,
        scenario,
        colorCoding: SLRColorCoding.EXPOSURE,
      };
    }
    default:
      return {
        type: SLRTemplate.BASIC,
        scenario,
        colorCoding: SLRColorCoding.CHANGE,
      };
  }
};

export const constructCreateSLRPayload = (
  template: SLRTemplateType,
  scenario: SLRScenarioType,
  state: any,
  locationId?: string,
): CreateSLRInput => {
  const { csgData } = state;
  const { resultSets, selectedResultSet } = csgData;
  const payload: CreateSLRInput = {
    templateJSON: getSLRTemplateJSON(template, scenario),
    ...(locationId ? { locationId: parseInt(locationId, 10) } : {}),
  };
  if (selectedResultSet) {
    const isBasic = [SLRTemplateType.CR_BASIC, SLRTemplateType.EX_BASIC].includes(template);
    const isStandard = [SLRTemplateType.CR_STANDARD, SLRTemplateType.EX_STANDARD].includes(
      template,
    );
    const isAdvanced = [SLRTemplateType.CR_ADVANCED, SLRTemplateType.EX_ADVANCED].includes(
      template,
    );
    const isFidelity = template === SLRTemplateType.FIDELITY;
    const {
      type: selectedRsType,
      id: selectedRsId,
      portfolioId,
      parameters: { csgVersion },
    } = selectedResultSet;
    switch (selectedRsType) {
      case ResultSetType.PERILS: {
        const scoresRS = getOrderedResultSetsByVersionAndType(
          resultSets,
          portfolioId,
          ResultSetType.SCORES,
          csgVersion,
        )[0];
        payload.perilsResultSetId = selectedRsId;
        payload.scoresResultSetId = scoresRS?.id ?? '';
        break;
      }
      case ResultSetType.ECONOMIC_IMPACTS: {
        const listScoresResultSets = getOrderedResultSetsByVersionAndType(
          resultSets,
          portfolioId,
          ResultSetType.SCORES,
          csgVersion,
        );
        const scoresRS = isFidelity
          ? listScoresResultSets.filter((itemRS) =>
              itemRS.parameters.benchmarking?.levels?.includes(BenchmarkingLevel.ADMIN2),
            )[0]
          : listScoresResultSets[0];
        payload.scoresResultSetId = scoresRS?.id ?? '';
        payload.perilsEIResultSetId = selectedRsId;
        break;
      }
      case ResultSetType.SCORES: {
        if (isAdvanced || isFidelity) {
          const perilsEIRS = getOrderedResultSetsByVersionAndType(
            resultSets,
            portfolioId,
            ResultSetType.ECONOMIC_IMPACTS,
            csgVersion,
            getRsScenarioFromSSP(scenario),
            EIModules.FINANCIAL,
          )[0];
          payload.perilsEIResultSetId = perilsEIRS?.id ?? '';
        } else {
          const perilsRS = getOrderedResultSetsByVersionAndType(
            resultSets,
            portfolioId,
            ResultSetType.PERILS,
            csgVersion,
            getRsScenarioFromSSP(scenario),
          )[0];
          const perilsEIRS = getOrderedResultSetsByVersionAndType(
            resultSets,
            portfolioId,
            ResultSetType.ECONOMIC_IMPACTS,
            csgVersion,
            getRsScenarioFromSSP(scenario),
          )[0];

          if (perilsRS && (!perilsEIRS || perilsRS.createdAt > perilsEIRS.createdAt)) {
            payload.perilsResultSetId = perilsRS?.id ?? '';
          } else {
            payload.perilsEIResultSetId = perilsEIRS?.id ?? '';
          }
        }
        payload.scoresResultSetId = selectedRsId;
        break;
      }
      default:
        break;
    }
  }
  return payload;
};
