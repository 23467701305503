import { APIResponse } from '../http';
import { UUID } from '../../api/types';
import { deleteClient, generateClient, listClients } from './client';
import { formatClientCred, formatListClientCreds } from '../../restUtils/formatUserClientResponse';
import { ClientCredsType, CredType } from '../../types/user';

export async function listClientsAdapter(userId: UUID): Promise<APIResponse<ClientCredsType>> {
  const { data, error } = await listClients(userId);
  if (error) {
    return { data: null, error };
  }
  return { data: formatListClientCreds(data), error: null };
}

export async function generateClientAdapter(userId: UUID): Promise<APIResponse<CredType>> {
  const { data, error } = await generateClient(userId);
  if (error) {
    return { data: null, error };
  }
  return { data: formatClientCred(data), error: null };
}

export async function deleteClientAdapter(userId: UUID, id: string): Promise<APIResponse<null>> {
  const { data, error } = await deleteClient(userId, id);
  if (error) {
    return { data: null, error };
  }
  return { data, error };
}
