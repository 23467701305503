import { ResultSet, ResultSetStatus, ResultSetType } from '../api/resultSets/types';
import { ContentsBreakdown, containsAll, produceContentsBreakdown } from './helpers';
// NOTE: "CRAR" stands for "Climate Risk Assessment Report"

export const SCORES_RS_READOUT_BREAKDOWN_TEXT =
  'At least one valid Scores Result Set (i.e., Has the same data version as selected D + L result set; All Scores options selected; Benchmarking Enabled; Benchmarking Levels "Country" and "Admin1" selected)'; // This is for display purposes in Dundas BI

interface ValidationReportForCRAR {
  title: string;
  valid: boolean;
  breakdown: ContentsBreakdown;
}

export interface CRARCompatibilityOutput {
  isCompatible: boolean;
  assessment: ValidationReportForCRAR[];
}

export const crarCompatibilityForDamageAndLossRs = (
  resultSet: ResultSet,
): CRARCompatibilityOutput => {
  // 1. Tests that the selected result set is D+L
  if (resultSet?.type !== ResultSetType.ECONOMIC_IMPACTS) {
    return {
      isCompatible: false,
      assessment: [
        {
          title: 'Required Result Set Type',
          valid: false,
          breakdown: produceContentsBreakdown([resultSet?.type], [ResultSetType.ECONOMIC_IMPACTS]),
        },
      ],
    };
  }

  // 2. Tests that the selected result set was successfully created
  if (resultSet?.status !== ResultSetStatus.COMPLETED) {
    return {
      isCompatible: false,
      assessment: [
        {
          title: 'Required Result Set Status',
          valid: false,
          breakdown: produceContentsBreakdown([resultSet?.status], [ResultSetStatus.COMPLETED]),
        },
      ],
    };
  }

  const {
    parameters: { years, scenarios, csgVersion, perils },
  } = resultSet;

  const REQUIRED_YEARS = [2020, 2030, 2040, 2050, 2075, 2100];
  const REQUIRED_SCENARIOS = ['ssp245', 'ssp585'];
  const REQUIRED_PERILS = [
    'cold',
    'combinedFlood',
    'drought',
    'fire',
    'hail',
    'heat',
    'precip',
    'wind',
  ];

  const reportCard: ValidationReportForCRAR[] = [
    // {
    //   title: 'Required CSG Version',
    //   valid: csgVersion === '3.0.0',
    //   breakdown: produceContentsBreakdown(['3.0.0'], [csgVersion]),
    // },
    {
      title: 'Required Years',
      valid: containsAll(REQUIRED_YEARS, years),
      breakdown: produceContentsBreakdown(REQUIRED_YEARS, years),
    },
    {
      title: 'Required Scenarios',
      valid: containsAll(REQUIRED_SCENARIOS, scenarios),
      breakdown: produceContentsBreakdown(REQUIRED_SCENARIOS, scenarios),
    },
    {
      title: 'Required Perils',
      valid: containsAll(REQUIRED_PERILS, perils),
      breakdown: produceContentsBreakdown(REQUIRED_PERILS, perils),
    },
  ];

  // 3. Tests that the requirements for the D+L result set are met
  const isCompatible = reportCard.every((item) => item.valid);

  // if (!isCompatible) {
  //   console.log(`> D+L reportCard for CRAR, FAILED: "${resultSet.name}"`);
  //   console.log(reportCard);
  // }

  return { isCompatible, assessment: reportCard };
};

export const crarCompatibilityForScoresRsWithLossRs = (
  resultSet: ResultSet, // the result set in question
  damageAndLossRs: ResultSet | null, // the selected damageAndLossResultSet for comparison
): CRARCompatibilityOutput => {
  // 1. Tests that the selected result set is of type SCORES
  if (resultSet?.type !== ResultSetType.SCORES) {
    return {
      isCompatible: false,
      assessment: [
        {
          title: 'Required Result Set Type',
          valid: false,
          breakdown: produceContentsBreakdown([resultSet?.type], [ResultSetType.SCORES]),
        },
      ],
    };
  }

  // 2. Tests that the selected result set was successfully created
  if (resultSet?.status !== ResultSetStatus.COMPLETED) {
    return {
      isCompatible: false,
      assessment: [
        {
          title: 'Required Result Set Status',
          valid: false,
          breakdown: produceContentsBreakdown([resultSet?.status], [ResultSetStatus.COMPLETED]),
        },
      ],
    };
  }

  const {
    parameters: { csgVersion = '', scores, benchmarking },
  } = resultSet;

  const REQUIRED_SCORES = [
    'allPerils',
    'cold',
    'combinedFlood',
    'drought',
    'fire',
    'hail',
    'heat',
    'precip',
    'wind',
  ];

  const REQUIRED_BENCHMARKING_LEVELS = ['COUNTRY', 'ADMIN1'];

  const reportCard: ValidationReportForCRAR[] = [
    {
      title: 'Matching CSG Version',
      // 3. Tests that the damageAndLossRs and the scores have the SAME data version
      valid: csgVersion.length > 0 && damageAndLossRs?.parameters?.csgVersion === csgVersion,
      breakdown: produceContentsBreakdown(
        [damageAndLossRs?.parameters?.csgVersion ?? 'Not Provided'],
        [csgVersion],
      ),
    },
    // {
    //   title: 'Required CSG Version',
    //   valid: csgVersion === '3.0.0',
    //   breakdown: produceContentsBreakdown(['3.0.0'], [csgVersion]),
    // },
    {
      title: 'Required Scores',
      // 4. Tests required scores values
      valid: containsAll(REQUIRED_SCORES, scores),
      breakdown: produceContentsBreakdown(REQUIRED_SCORES, scores),
    },
    {
      title: 'Benchmarking Enabled',
      // 5. Tests benchmarking enabled
      valid: benchmarking?.enabled ?? false,
      breakdown: produceContentsBreakdown(['enabled'], [benchmarking?.enabled ? 'enabled' : '']),
    },
    {
      title: 'Required Benchmarking Levels',
      // 6. Tests benchmarking levels
      valid: containsAll(REQUIRED_BENCHMARKING_LEVELS, benchmarking?.levels),
      breakdown: produceContentsBreakdown(REQUIRED_BENCHMARKING_LEVELS, benchmarking?.levels),
    },
  ];

  // 7. Tests that the requirements for the Scores result set are met
  const isCompatible = reportCard.every((item) => item.valid);

  // if (!isCompatible) {
  //   console.log(`> Scores reportCard for CRAR, FAILED: "${resultSet.name}"`);
  //   console.log(reportCard);
  // }

  return { isCompatible, assessment: reportCard };
};

/*

const CRARDetect: FC = () => {
  const selectedResultSet = useAppSelector(selectSelectedResultSet);
  const compatibleScoresRs = useAppSelector(selectCRARCompatibleScoresRsFound);

  const summaryChips: ChipGroupItem[] = useMemo(() => {
    if (selectedResultSet?.type !== ResultSetType.DAMAGE_AND_LOSS) {
      return [];
    }

    if (selectedResultSet?.status !== ResultSetStatus.COMPLETED) {
      return [];
    }

    // 1. Tests that the selected result set is D+L
    const validRs = isCRARCompatibleDamageAndLossRs(selectedResultSet);

    // 2. Tests that the portfolio has a Scores + Benchmarking RS that matches
    if (validRs && compatibleScoresRs) {
      return [
        {
          id: 'valid_crar_rs_1',
          name: 'CRAR Enabled',
          sx: { background: 'green', fontSize: 16 },
          tooltip: 'you can generate a CRAR',
        },
      ];
    }

    const reasons = [
      { show: !validRs, message: 'Damage & Loss RS is missing required parameters' },
      { show: !compatibleScoresRs, message: 'Scores RS is missing required parameters' },
    ]
      .filter((reason) => reason.show)
      .map((reason) => reason.message);

    return [
      {
        id: 'invalid_crar_rs_1',
        name: `CRAR Disabled: ${reasons.join(', ')}`,
        sx: { background: 'red', fontSize: 16 },
        tooltip: 'you cannot generate a CRAR',
      },
    ];
  }, [selectedResultSet, compatibleScoresRs]);

  return <ChipGroup items={summaryChips} />;
};

*/
