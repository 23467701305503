import { FC, useCallback, useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import { SaveAlt as SaveAltIcon } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setSLRDrawer } from '../redux/slices/uiSlice';
import { selectResultSetForSLR } from '../redux/slices/csgDataSlice';
import { selectSelectedResultSet } from '../redux/selectors/csgDataSelectors';
import BorderedButtonMask from './buttons/BorderedButtonMask';
import { StyledMediumLabel } from './labels/StyledLabel';
import {
  DISABLED_TEXT_COLOR,
  INK_BLUE_TEXT_COLOR,
  StyleProps,
  TILE_BORDER,
} from '../utils/styleUtils';
import Tooltip from './tooltip/Tooltip';

interface SLRPanelTriggerProps {
  disabled?: boolean;
  tooltip?: string;
  sx?: StyleProps;
}

const SLRPanelTrigger: FC<SLRPanelTriggerProps> = ({ disabled = false, tooltip = '', sx = {} }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const selectedResultSet = useAppSelector(selectSelectedResultSet);
  const handleSLRDrawerOpen = useCallback((): void => {
    dispatch(setSLRDrawer(true));
    if (selectedResultSet) {
      dispatch(selectResultSetForSLR(selectedResultSet));
    }
  }, [dispatch, selectedResultSet]);

  const buttonElem = useMemo(
    () => (
      <Box>
        <BorderedButtonMask
          sx={{
            height: 'auto',
            padding: `${theme.spacing(0.25)} ${theme.spacing(1)}`,
            ...(disabled
              ? {
                  pointerEvents: 'none',
                  border: TILE_BORDER,
                  color: DISABLED_TEXT_COLOR,
                }
              : {
                  borderColor: INK_BLUE_TEXT_COLOR,
                  color: INK_BLUE_TEXT_COLOR,
                }),
            ...sx,
          }}
          appendIcon={
            <SaveAltIcon
              sx={{
                height: theme.spacing(2),
                width: theme.spacing(2),
                marginLeft: theme.spacing(0.5),
              }}
            />
          }
          onClick={handleSLRDrawerOpen}
        >
          <StyledMediumLabel variant="h6">Single-Location Report</StyledMediumLabel>
        </BorderedButtonMask>
      </Box>
    ),
    [disabled, sx, theme, handleSLRDrawerOpen],
  );

  return disabled && tooltip ? (
    <Tooltip mode="dark" title={tooltip}>
      {buttonElem}
    </Tooltip>
  ) : (
    buttonElem
  );
};

export default SLRPanelTrigger;
