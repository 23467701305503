import MuiAccordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import { MuiAccordionProps } from './types';
import { SIDE_PANEL_SEPARATOR } from '../../utils/styleUtils';

const StyledAccordion = styled((props: MuiAccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  margin: 0,
  padding: 0,
  '&:not(:last-child)': {
    borderBottom: SIDE_PANEL_SEPARATOR,
  },
  '&:before': {
    display: 'none',
  },
  background: 'transparent',
}));

export default StyledAccordion;
