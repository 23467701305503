import { CustomScrollBar, SPACER, TEXT_BOX_BORDER, px } from '../../utils/styleUtils';

export const searchInputSx = {
  border: TEXT_BOX_BORDER,
  width: 'calc(100% - 4px)',
  paddingTop: 0.375,
  paddingBottom: 0.375,
  margin: 0.25,
};

export const listSx = {
  width: '100%',
  maxWidth: {
    xs: '45vw',
    sm: '45vw',
    md: '30vw',
    lg: '20vw',
  },
  bgcolor: 'background.paper',
  position: 'relative',
  overflow: 'auto',
  maxHeight: '200px',
  backgroundColor: 'inherit',
  paddingTop: px(SPACER / 4),
  paddingBottom: 0, // unnecessary bottom padding appearing for some menus
  ...CustomScrollBar,
};

export const listItemSx = {
  paddingTop: 0.5,
  paddingBottom: 0.5,
  paddingRight: 1,
  paddingLeft: 2,
  margin: 0,
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '0.875rem',
  cursor: 'pointer',
};

export const emptyMenuItemSx = {
  opacity: 0.6,
  paddingLeft: 1.5,
  paddingRight: 1.5,
};

export const dotIconSx = (selected: boolean): object => ({
  opacity: selected ? 1 : 0,
  height: '5px',
  width: '5px',
  marginRight: 1.25,
});

export const primaryButtonContainerSx = {
  marginLeft: '10px',
};

export const primaryButtonSx = {
  minWidth: 'auto',
  padding: 0,
  color: '#fff',
};

export const arrowButtonSx = { height: '20px', width: '20px' };
