import { copyTextToClipboard } from './browserUtils';
import { dateFormat } from './datetime';
import { getJwt } from './gql';

export const captureDebugLog = async (json: any, prefix: string = 'data'): Promise<void> => {
  const user = await getJwt();

  const context = {
    url: window.location.origin,
    timestamp: dateFormat(),
    datetime: dateFormat(undefined, 'MMMM D, YYYY h:mm A'),
    user,
  };

  const stringifiedContext = JSON.stringify(context, null, 2);

  const stringifiedJson = JSON.stringify(json, null, 2);

  void copyTextToClipboard(
    [
      '>>>>>>>>>>>>>>>>>>> DEBUG_LOG_START',
      'Context:',
      '-------------------',
      stringifiedContext,
      '-------------------',
      `${prefix}:`,
      '-------------------',
      stringifiedJson,
      '>>>>>>>>>>>>>>>>>>> DEBUG_LOG_END',
    ].join('\n'),
  );
};
