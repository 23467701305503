import { Box } from '@mui/material';
import { FC, ReactElement } from 'react';
import { StyleProps } from '../utils/styleUtils';

interface ConditionalRenderingProps {
  condition: boolean;
  render: ReactElement | string;
  elseRender?: ReactElement | string;
  sx?: StyleProps;
}

const ConditionalRendering: FC<ConditionalRenderingProps> = ({
  condition,
  render,
  elseRender = '',
  sx = {},
}: ConditionalRenderingProps) => {
  return <Box sx={sx}>{condition ? render : elseRender ?? ''}</Box>;
};

export default ConditionalRendering;
