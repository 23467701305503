import { Box, BoxProps, Button, ButtonProps, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, Fragment, ReactElement, ReactNode, useMemo } from 'react';
import {
  CenteredRowStyle,
  CircleStyleInterface,
  DEFAULT_CIRCLE_STYLE,
  PRIMARY_BTN_BG_COLOR,
  SWITCH_BTN_BORDER,
  StyleProps,
} from '../../utils/styleUtils';
import CircularIcon from '../circular-icon/CircularIcon';
import Tooltip from '../tooltip/Tooltip';
import { StyledMediumLabel } from '../labels/StyledLabel';

export interface IndividualButtonProps {
  id: string;
  active?: boolean; // determines the value of `activeButton`
  title: string | ReactNode | null;
  onClick: () => void;
  icon?: string | ReactNode;
  disabled?: boolean;
  tooltip?: string;
  buttonSx?: StyleProps;
  loading?: boolean;
  'data-testid'?: string;
}

interface SwitchButtonProps extends BoxProps {
  buttons: IndividualButtonProps[];
  startIcon?: ReactElement;
  activeProps?: CircleStyleInterface;
  type: 'normal' | 'notification' | 'switch';
}

interface RenderGroupButtonProps {
  button: IndividualButtonProps;
  activeButton: IndividualButtonProps | null;
  activeProps?: CircleStyleInterface;
  type: 'normal' | 'notification' | 'switch';
  buttonSx?: StyleProps;
}

export const GroupButton: FC<ButtonProps> = styled(Button)(({ theme }) => ({
  background: 'transparent !important',
  border: SWITCH_BTN_BORDER,
  borderRadius: theme.spacing(0.5),
  height: theme.spacing(4),
  color: 'inherit',
  ':hover': {
    background: 'inherit',
  },
  ':disabled': {
    color: 'inherit',
    '& .MuiTypography-button': {
      opacity: 0.6,
    },
  },
  ':not(:first-of-type)': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: 0,
  },
  ':not(:last-of-type)': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

const renderGroupButton = ({
  type,
  button,
  activeButton,
  activeProps,
  buttonSx,
}: RenderGroupButtonProps): ReactElement => {
  const {
    title,
    id,
    onClick,
    disabled = false,
    loading = false,
    'data-testid': dataTestId,
  } = button;
  const isActiveButton = activeButton?.id === id;
  return (
    <GroupButton
      data-testid={dataTestId ?? id}
      onClick={onClick}
      variant="contained"
      disabled={disabled || loading}
      sx={buttonSx}
    >
      <Box
        sx={{
          ...CenteredRowStyle,
          alignItems: 'center',
        }}
      >
        {type !== 'normal' && (
          <CircularIcon
            borderColor={
              isActiveButton ? activeProps?.borderColor : DEFAULT_CIRCLE_STYLE.borderColor
            }
            backgroundColor={
              isActiveButton ? activeProps?.backgroundColor : DEFAULT_CIRCLE_STYLE.backgroundColor
            }
          />
        )}
        <StyledMediumLabel variant="button">{title}</StyledMediumLabel>
        {loading && (
          <CircularProgress
            sx={(theme) => ({
              fontSize: 'inherit',
              marginLeft: theme.spacing(0.5),
            })}
            size="14px"
            color="inherit"
          />
        )}
      </Box>
    </GroupButton>
  );
};

const SwitchButton: FC<SwitchButtonProps> = styled(
  ({ buttons = [], activeProps, startIcon, type, ...sectionedBoxProps }: SwitchButtonProps) => {
    const activeButton: IndividualButtonProps | null = useMemo(() => {
      return buttons.find(({ active }) => active) ?? null;
    }, [buttons]);

    return (
      <Box
        sx={{
          ...CenteredRowStyle,
          alignItems: 'center',
          borderTopRightRadius: '',
        }}
        role="group"
        {...sectionedBoxProps}
      >
        {buttons.map((button) => {
          const { id, disabled = false, tooltip = '', buttonSx = {} } = button;
          const wrapTooltip = !disabled && tooltip.length > 0;

          /* NOTE: wrapTooltip includes `!disabled` requirement--Why? Because:
           * 1. A disabled element does not fire events.
           * 2. Tooltip needs to listen to the child element's events to display the title.
           */
          return wrapTooltip ? (
            <Tooltip title={tooltip} key={id}>
              {renderGroupButton({ type, button, activeButton, activeProps, buttonSx })}
            </Tooltip>
          ) : (
            <Fragment key={id}>
              {renderGroupButton({ type, button, activeButton, activeProps, buttonSx })}
            </Fragment>
          );
        })}
      </Box>
    );
  },
)(() => {
  return {
    background: PRIMARY_BTN_BG_COLOR,
    maxWidth: 'fit-content',
    button: {},
    svg: {},
  };
});

export default SwitchButton;
