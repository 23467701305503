import { Description as DescriptionIcon } from '@mui/icons-material';
import { Box, CircularProgress, Link, Theme } from '@mui/material';
import { FC, ReactElement } from 'react';
import { Title, Value } from '../../../components/InfoText';
import TextUnderlineButton from '../../../components/TextUnderlineButton';
import { StyledThinLabel } from '../../../components/labels/StyledLabel';
import { DataStatus } from '../../../redux/slices/csgDataSlice';
import { exportFileAsync } from '../../../utils/csvUtils';
import {
  CenteredColumnStyle,
  CenteredRowStyle,
  MUI_SPACER,
  MUTED_TEXT_COLOR,
  SPACER,
  px,
} from '../../../utils/styleUtils';
import {
  DocumentationDataType,
  SettingsTitleValueProps,
  SettingsUserDetailsType,
  UsageStatsSettingsType,
  UsageStatsType,
} from './types';
import { PADDING_SX } from './constants';
import Checkbox from '../../../components/checkbox/Checkbox';
import ConditionalRendering from '../../../components/ConditionalRendering';
import InfoIconTooltip from '../../../components/InfoIconTooltip';
import ClientCredsSettings from './ClientCredsSettings';

const USAGE_TRACKING_TOOLTIP_INFO =
  'Users are only charged the first time analytics are requested for a unique latitude-longitude pair. If a location has previously been uploaded by you or your colleagues, it will not appear in the report. Similarly, if no locations in a portfolio are unique, that portfolio will not appear in the report. See the Knowledge Base for details.';

const SettingsTitle: FC<SettingsTitleValueProps> = ({ value, sx = {}, ...rest }) => (
  <Title data-testid={rest['data-testid']} variant="caption" sx={sx}>
    {value}:{' '}
  </Title>
);

const SettingsValue: FC<SettingsTitleValueProps> = ({ value, sx = {}, ...rest }) => (
  <Value data-testid={rest['data-testid']} noWrap variant="h6" sx={sx}>
    {value}
  </Value>
);

export const renderAccordionDetails = ({
  id: sectionId,
  content: sectionContent,
}: {
  id: string;
  content: any[];
}): ReactElement => {
  switch (sectionId) {
    case 'profile-settings-section-drawer':
      return (
        <Box sx={{ ...CenteredRowStyle, ...PADDING_SX }}>
          {sectionContent.map(({ title, value, id }) => (
            <Box
              key={id}
              sx={{
                minWidth: '30%',
              }}
              data-testid={id}
            >
              <SettingsTitle value={title} />
              <SettingsValue
                sx={{
                  paddingLeft: px(SPACER / 2),
                  marginTop: px(SPACER / 2),
                }}
                value={value}
              />
            </Box>
          ))}
        </Box>
      );

    case 'documentation-section-drawer':
      return (
        <Box sx={{ ...CenteredRowStyle, ...PADDING_SX, flexWrap: 'wrap' }} gap={2}>
          {sectionContent.map(({ title, url, filename, id, disabled = false }) => (
            <Box
              key={id}
              sx={{
                ...CenteredRowStyle,
                alignItems: 'center',
              }}
              data-testid={id}
            >
              <DescriptionIcon
                color={disabled ? 'disabled' : 'inherit'}
                sx={{ fontSize: '1rem' }}
              />
              <TextUnderlineButton
                disabled={disabled}
                onClick={() => {
                  void exportFileAsync({ filename, url, id });
                }}
                TypographyProps={{ variant: 'h6' }}
              >
                {title}
              </TextUnderlineButton>
            </Box>
          ))}
        </Box>
      );

    case 'legal-section-drawer':
      return (
        <Box sx={{ ...CenteredRowStyle, ...PADDING_SX }} gap={1}>
          {sectionContent.map(({ title, id, url, disabled = false }) => (
            <Box
              key={id}
              sx={{
                ...CenteredRowStyle,
                alignItems: 'center',
              }}
              data-testid={id}
            >
              <Link href={url} target="_blank" rel="noopener noreferrer" sx={{ color: 'inherit' }}>
                <TextUnderlineButton disabled={disabled} TypographyProps={{ variant: 'h6' }}>
                  {title}
                </TextUnderlineButton>
              </Link>
            </Box>
          ))}
        </Box>
      );

    case 'contact-section-drawer':
      return (
        <Box sx={{ ...CenteredRowStyle, ...PADDING_SX }} gap={1} data-testid="contact-email">
          <SettingsTitle value="Email" />
          <TextUnderlineButton
            href="mailto:support@jupiterintel.com"
            TypographyProps={{ variant: 'h6' }}
          >
            support@jupiterintel.com
          </TextUnderlineButton>
        </Box>
      );

    case 'about-section-drawer':
      return (
        <Box sx={{ ...CenteredColumnStyle, ...PADDING_SX, justifyContent: 'flex-start' }} gap={0.5}>
          {sectionContent.map(({ title, id }) => (
            <Box
              key={id}
              sx={{
                ...CenteredRowStyle,
                justifyContent: 'flex-start',
                width: '100%',
              }}
              data-testid={id}
            >
              <SettingsValue sx={{ display: 'flex' }} value={title} />
            </Box>
          ))}
        </Box>
      );

    default:
      return <>Not available</>;
  }
};

export const renderUsageSummarySectionDrawerV1 = ({
  sectionContent,
  usageStats,
  theme,
}: {
  sectionContent: any[];
  usageStats: UsageStatsSettingsType | null;
  theme: Theme;
}): ReactElement => {
  if (!usageStats) {
    return <div />;
  }
  const {
    isUserStatsLoading,
    showUsersByCategory,
    userStatsData: usageStatsData,
    userDetails,
  } = usageStats;

  const usageStatsCombined: SettingsUserDetailsType = {
    user: userDetails?.user ?? '',
    tenant: userDetails?.tenant ?? '',
  };
  const usersByCategory = usageStatsData?.userPortfolioCategories ?? [];
  const userStatsMetrics = ['totalPortfolios', 'totalLocations', 'totalQueries'];
  return (
    <Box data-testid="usage-stats-container" sx={{ maxWidth: theme.spacing(85) }}>
      {/* Report */}
      <Box sx={{ ...CenteredRowStyle, ...PADDING_SX }} gap={1.5}>
        {sectionContent.map(
          ({ title, id, subTitle = '', onClick = () => {}, disabled = false }) => (
            <Box
              key={id}
              sx={{
                ...CenteredRowStyle,
                alignItems: 'center',
                width: 'calc(50% + 16px)',
              }}
              data-testid={id}
            >
              <TextUnderlineButton
                disabled={disabled}
                TypographyProps={{ variant: 'h6' }}
                sx={{ minWidth: 'auto', marginLeft: theme.spacing(0.25) }}
                onClick={onClick}
              >
                {title}
              </TextUnderlineButton>
              <InfoIconTooltip
                tooltip={USAGE_TRACKING_TOOLTIP_INFO}
                sx={{ marginLeft: '4px' }}
                placement="right"
              />
              {subTitle && (
                <StyledThinLabel
                  variant="body1"
                  sx={{ marginLeft: theme.spacing(1), color: MUTED_TEXT_COLOR }}
                >
                  {subTitle}
                </StyledThinLabel>
              )}
            </Box>
          ),
        )}
      </Box>

      <ConditionalRendering
        condition={!!isUserStatsLoading}
        render={
          <Box
            sx={{
              width: '100%',
              ...CenteredRowStyle,
              marginBottom: '0.875rem',
              justifyContent: 'center',
            }}
          >
            <CircularProgress
              size="14px"
              id="usage-stats-loading-indicator"
              color="inherit"
              sx={{
                fontSize: '0.875rem',
              }}
            />
          </Box>
        }
        elseRender={
          <>
            {/* Showing Count */}
            <Box
              sx={{ ...CenteredColumnStyle, justifyContent: 'flex-start', ...PADDING_SX }}
              gap={1.5}
            >
              {Object.entries(usageStatsCombined).map((usageStat) => {
                const [usageStatKey, usageStatValue] = usageStat;
                const usageStatsObj = usageStatsData?.[usageStatKey as keyof UsageStatsType];
                return (
                  <Box
                    key={usageStatKey}
                    sx={{
                      ...CenteredRowStyle,
                      alignItems: 'center',
                      width: '100%',
                    }}
                    data-testid="user-stats"
                  >
                    <SettingsValue
                      data-testid={`${usageStatKey}-label-usage-stats`}
                      value={usageStatValue}
                      sx={{
                        width: '25%',
                        textTransform: 'capitalize',
                        alignItems: 'center',
                        paddingTop: 0,
                      }}
                    />
                    {usageStatsData &&
                      userStatsMetrics.map((param: string) => {
                        const title: string = param.replace('total', '');
                        // eslint-disable-next-line security/detect-object-injection
                        const value: string =
                          usageStatsObj?.[param as keyof typeof usageStatsObj] ?? '';
                        return (
                          <Box
                            key={`user-usage-stats-${param}`}
                            sx={{
                              ...CenteredRowStyle,
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              width: '25%',
                              paddingTop: 0,
                            }}
                          >
                            <SettingsTitle
                              data-testid={`${usageStatKey}-${title.toLowerCase()}-label-usage-stats`}
                              value={title}
                              sx={{
                                marginRight: '0.25rem',
                                paddingTop: 0,
                                ...CenteredRowStyle,
                                justifyContent: 'start',
                              }}
                            />
                            <SettingsValue
                              value={value}
                              sx={{ paddingRight: '0.5rem', marginTop: '1px', paddingTop: 0 }}
                            />
                          </Box>
                        );
                      })}
                  </Box>
                );
              })}
            </Box>

            {/* Usage Stats by Category Checkbox */}
            <Box sx={{ ...CenteredRowStyle, ...PADDING_SX }} gap={1.5}>
              {showUsersByCategory && (
                <Box
                  sx={{
                    ...CenteredRowStyle,
                    alignItems: 'center',
                    width: '50%',
                  }}
                  data-testid="users-by-category-checkbox"
                >
                  <Checkbox
                    option={{
                      id: 'users-by-category-usage-stats-checkbox-id',
                      name: `Categories for ${usageStatsCombined.user}`,
                      selected: showUsersByCategory.checked,
                    }}
                    onOptionChange={() => {}}
                    key="users-by-category-usage-stats-checkbox"
                    sx={{
                      paddingTop: 0,
                    }}
                    onChange={() => showUsersByCategory.onClick()}
                  />
                </Box>
              )}
            </Box>

            {/* Users by Category */}
            {showUsersByCategory?.checked && (
              <Box
                sx={{ ...CenteredColumnStyle, justifyContent: 'flex-start', ...PADDING_SX }}
                gap={1.5}
              >
                {usersByCategory.map(({ name, totalPortfolios, totalLocations, totalQueries }) => {
                  const usageStatsObj = { totalPortfolios, totalLocations, totalQueries };
                  return (
                    <Box
                      key={name}
                      sx={{
                        ...CenteredRowStyle,
                        alignItems: 'center',
                        width: '100%',
                      }}
                      data-testid="user-stats"
                    >
                      <SettingsValue
                        value={name}
                        sx={{
                          width: '25%',
                          textTransform: 'capitalize',
                          alignItems: 'center',
                          paddingTop: 0,
                          marginTop: '1px',
                        }}
                      />
                      {usageStatsData &&
                        userStatsMetrics.map((param: string) => {
                          const title: string = param.replace('total', '');
                          // eslint-disable-next-line security/detect-object-injection
                          const value: number =
                            usageStatsObj?.[param as keyof typeof usageStatsObj] ?? '';
                          return (
                            <Box
                              key={`user-usage-stats-${param}`}
                              sx={{
                                ...CenteredRowStyle,
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                width: '25%',
                                paddingTop: '2px',
                              }}
                            >
                              <SettingsTitle
                                value={title}
                                sx={{
                                  marginRight: '0.25rem',
                                  paddingTop: 0,
                                  ...CenteredRowStyle,
                                  justifyContent: 'start',
                                }}
                              />
                              <SettingsValue
                                value={value}
                                sx={{ paddingRight: '0.5rem', marginTop: '1px', paddingTop: 0 }}
                              />
                            </Box>
                          );
                        })}
                    </Box>
                  );
                })}
              </Box>
            )}
          </>
        }
      />
    </Box>
  );
};

export const renderAccordionDetailsV1 = (
  {
    id: sectionId,
    content: sectionContent,
    documentationData,
    usageStats,
  }: {
    id: string;
    content: any[];
    documentationData?: DocumentationDataType;
    usageStats?: UsageStatsSettingsType;
  },
  theme: Theme,
): ReactElement => {
  switch (sectionId) {
    case 'profile-settings-section-drawer':
      return (
        <Box sx={{ ...CenteredRowStyle, ...PADDING_SX }}>
          {sectionContent.map(({ title, value, id }) => (
            <Box
              key={id}
              sx={{
                minWidth: '30%',
              }}
              data-testid={id}
            >
              <SettingsTitle value={title} />
              <SettingsValue
                sx={{
                  paddingLeft: theme.spacing(MUI_SPACER),
                }}
                value={value}
              />
            </Box>
          ))}
        </Box>
      );

    case 'documentation-section-drawer': {
      if (documentationData) {
        const { prefix, onClick, title, loading, disabled = false } = documentationData;

        return (
          <Box
            sx={{
              ...CenteredRowStyle,
              ...PADDING_SX,
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
            gap={1.5}
          >
            <Box
              sx={{
                ...CenteredRowStyle,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              data-testid="csg-app-knowledge-base"
            >
              <Title variant="caption">{prefix}&nbsp;</Title>
              <TextUnderlineButton
                onClick={onClick}
                data-testid="csg-app-knowledge-base-link"
                TypographyProps={{
                  variant: 'h6',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                disabled={disabled || loading}
              >
                {title}{' '}
                {loading && (
                  <CircularProgress
                    sx={() => ({
                      fontSize: 'inherit',
                      marginLeft: theme.spacing(0.5),
                    })}
                    size="14px"
                    color="inherit"
                  />
                )}
              </TextUnderlineButton>
            </Box>
          </Box>
        );
      }

      return (
        <Box sx={{ ...CenteredRowStyle, ...PADDING_SX, flexWrap: 'wrap' }} gap={2}>
          {sectionContent.map(({ title, url, filename, id, disabled = false }) => (
            <Box
              key={id}
              sx={{
                ...CenteredRowStyle,
                alignItems: 'center',
              }}
              data-testid={id}
            >
              <DescriptionIcon
                color={disabled ? 'disabled' : 'inherit'}
                sx={{ fontSize: '1rem' }}
              />
              <TextUnderlineButton
                disabled={disabled}
                onClick={() => {
                  void exportFileAsync({ filename, url, id });
                }}
                TypographyProps={{ variant: 'h6' }}
              >
                {title}
              </TextUnderlineButton>
            </Box>
          ))}
        </Box>
      );
    }

    case 'legal-section-drawer':
      return (
        <Box sx={{ ...CenteredColumnStyle, ...PADDING_SX, justifyContent: 'flex-start' }} gap={0.5}>
          {sectionContent.map(({ value, id }) => (
            <Box
              key={id}
              sx={{
                ...CenteredRowStyle,
                display: 'block',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
              }}
              data-testid={id}
            >
              {value}
            </Box>
          ))}
        </Box>
      );

    case 'client-credentials-section-drawer':
      return <ClientCredsSettings />;

    case 'usage-summary-section-drawer':
      return renderUsageSummarySectionDrawerV1({
        sectionContent,
        usageStats: usageStats ?? null,
        theme,
      });

    case 'contact-section-drawer':
      return (
        <Box sx={{ ...CenteredRowStyle, ...PADDING_SX }} gap={0.5} data-testid="contact-email">
          <SettingsTitle value="Email" />
          <TextUnderlineButton
            href="mailto:support@jupiterintel.com"
            TypographyProps={{ variant: 'h6' }}
          >
            support@jupiterintel.com
          </TextUnderlineButton>
        </Box>
      );

    case 'about-section-drawer':
      return (
        <Box sx={{ ...CenteredColumnStyle, ...PADDING_SX, justifyContent: 'flex-start' }} gap={0.5}>
          {sectionContent.map(({ title, value, id, sx }) => (
            <Box
              key={id}
              sx={{
                ...CenteredRowStyle,
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
              }}
              gap={0.5}
              data-testid={id}
            >
              {Boolean(title) && <SettingsTitle value={title} />}
              <SettingsValue
                sx={{ display: 'flex' }}
                value={Array.isArray(value) ? value.join(', ') : value}
              />
            </Box>
          ))}
        </Box>
      );

    default:
      return <>Not available</>;
  }
};

export const dataItemIsLoading = (dataItem: { status: DataStatus }): boolean =>
  dataItem?.status === DataStatus.LOADING;
