import { Box, FormLabel, RadioGroup as MuiRadioGroup } from '@mui/material';
import { FC } from 'react';
import Radio from './Radio';
import { RadioGroupProps } from './types';

const RadioGroup: FC<RadioGroupProps> = ({
  name = '',
  options = [],
  onRadioClick,
  isRow = false,
  sx = {},
}) => {
  return (
    <Box
      sx={(theme) => ({
        justifyContent: 'start',
        alignItems: 'start',
        flexDirection: 'column',
        paddingLeft: theme.spacing(1.5),
        marginBottom: theme.spacing(2),
        ...sx,
      })}
    >
      {name.length > 0 && <FormLabel id="radio-buttons-group">{name}</FormLabel>}
      <MuiRadioGroup
        row={isRow}
        aria-labelledby="radio-buttons-group"
        name="controlled-radio-buttons-group"
      >
        {options.map((option) => (
          <Radio key={option.id} option={option} onRadioClick={() => onRadioClick(option.id)} />
        ))}
      </MuiRadioGroup>
    </Box>
  );
};

export default RadioGroup;
