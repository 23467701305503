import { Box, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import ColorInheritedTextField from '../../../components/ColorInheritedTextField';
import TextUnderlineButton from '../../../components/TextUnderlineButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import { useAppSelector } from '../../../redux/hooks';
import { selectNumCoords } from '../../../redux/selectors/uploadSelectors';
import {
  bothCoordsHaveDecimal,
  inputIsNumberCommaNumber,
  validateCoordsInput,
} from '../../../utils/geoUtils';
import {
  APP_TEXT_COLOR,
  CenteredRowStyle,
  PRIMARY_BORDER,
  PRIMARY_TEXT_COLOR,
  TEXT_BOX_BORDER,
  StandardInputProps,
  TEXT_FIELD_BG_COLOR,
} from '../../../utils/styleUtils';
import { CoordinatesPickerProps } from './types';
import { LOCATION_LIMIT } from './util';
import { StyledThinLabel } from '../../../components/labels/StyledLabel';

const CoordinatesPicker: FC<CoordinatesPickerProps> = ({
  onSubmit,
  coordsStrValue = '',
  submitButtonText = 'Add',
  TextFieldOverrides = {},
  ButtonOverrides = {},
}) => {
  const theme = useTheme();
  const [coordsStr, updateCoordsStr] = useState<string>(coordsStrValue);

  const numCoords = useAppSelector(selectNumCoords);

  const coordsAreValid = validateCoordsInput(coordsStr); // Assumption: coordsStr=`${latitude}, ${longitude}`

  const withinCoordLimit = numCoords < LOCATION_LIMIT;

  const allowSubmit = coordsAreValid && withinCoordLimit;

  const flipOder = (): void => {
    updateCoordsStr(coordsStr.split(',').reverse().join(','));
  };

  const handleSubmit = (): void => {
    onSubmit(coordsStr);
    updateCoordsStr(''); // clear input
  };

  const getHelperText = (): string => {
    if (coordsAreValid && !bothCoordsHaveDecimal(coordsStr)) {
      return 'Coordinates should have at least one decimal.';
    }

    if (!withinCoordLimit) return 'Location limit reached.';

    return '';
  };

  return (
    <Box data-cy="coordinate-picker-component" sx={{ minWidth: theme.spacing(37.5) }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: theme.spacing(0.25),
        }}
      >
        <StyledThinLabel variant="h6">Latitude, Longitude</StyledThinLabel>
        <TextUnderlineButton
          onClick={flipOder}
          disabled={!inputIsNumberCommaNumber(coordsStr)}
          TypographyProps={{ variant: 'button' }}
        >
          Flip Order
        </TextUnderlineButton>
      </Box>
      <Box sx={CenteredRowStyle}>
        <ColorInheritedTextField
          data-testid="coordinate-picker-textbox"
          variant="standard"
          autoFocus
          label=""
          type="text"
          sx={{
            flexGrow: 1,
            justifyContent: 'center',
            '& .MuiInputBase-root': {
              color: APP_TEXT_COLOR,
              border: TEXT_BOX_BORDER,
            },
          }}
          {...TextFieldOverrides}
          onChange={({ target }) => {
            updateCoordsStr(target.value);
          }}
          value={coordsStr}
          helperText={getHelperText()}
          onKeyPress={(e) => {
            if (!allowSubmit) return;
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
          InputProps={{
            ...StandardInputProps,
            sx: {
              fontSize: theme.spacing(1.75),
              height: theme.spacing(4),
              background: TEXT_FIELD_BG_COLOR,
              paddingLeft: theme.spacing(1),
              paddingRight: theme.spacing(1),
            },
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: theme.spacing(1) }}>
        <PrimaryButton
          data-testid="add-location"
          sx={{ minWidth: 'unset', color: PRIMARY_TEXT_COLOR, border: PRIMARY_BORDER }}
          variant="contained"
          {...ButtonOverrides}
          onClick={handleSubmit}
          disabled={!allowSubmit}
        >
          {submitButtonText}
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default CoordinatesPicker;
