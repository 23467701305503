import { OptionsObject } from 'notistack';

export enum AlertSeverity {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

export interface AlertObjectInput {
  severity?: AlertSeverity;
  open: boolean;
  message?: string;
  options?: AlertObjectOptions;
}

interface AlertObjectOptions extends OptionsObject {
  // ...from notistack

  // custom
  closeable?: boolean;
  closeButtonText?: string;
  onCloseClickCallback?: () => void;
}

export interface AlertObject extends AlertObjectInput {
  id: string;
  timestamp: string;
}
