import { Box, Stack, useTheme } from '@mui/material';
import { FC } from 'react';
import PortfolioNavigation from './PortfolioNavigation';
import ResultSetsNavigation from './ResultSetsNavigation';
import VisualizationsNavigation from './VisualizationsNavigation';
// import CRARDetect from './CRARDetect';

const NavigationDropdown: FC = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginRight: theme.spacing(-1),
      }}
    >
      <Stack direction="row" spacing={0} sx={{ maxWidth: '100%' }}>
        <PortfolioNavigation />
        <ResultSetsNavigation />
        <VisualizationsNavigation />
        {/* <CRARDetect /> */}
      </Stack>
    </Box>
  );
};

export default NavigationDropdown;
