import { FC } from 'react';
import { AlertSeverity } from '../../../components/alerts/types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectCategories } from '../../../redux/selectors/csgDataSelectors';
import { selectUploadCategory } from '../../../redux/selectors/uploadSelectors';
import { refreshCategoriesList } from '../../../redux/slices/csgDataSlice';
import { addAlert } from '../../../redux/slices/uiSlice';
import { setUploadCategory } from '../../../redux/slices/uploadSlice';
import { reactKey } from '../../../utils/reactUtils';
import CategorySelector from './CategorySelector';
import { CategorySelectorContainerProps } from './types';
import { createCategoryAdapter } from '../../../rest/category/adapter';

const CategorySelectorContainer: FC<CategorySelectorContainerProps> = ({ ...boxProps }) => {
  const dispatch = useAppDispatch();
  const portfolioCategories = useAppSelector(selectCategories);
  const selectedCategory = useAppSelector(selectUploadCategory);

  const handleCreateCategory = async ({
    categoryName,
  }: {
    categoryName: string;
  }): Promise<{ id: string; name: string } | null> => {
    const mutationResponse = await createCategoryAdapter({ name: categoryName });

    const { data, error } = mutationResponse;

    if (error) {
      dispatch(
        addAlert({
          severity: AlertSeverity.Error,
          open: true,
          message: error.message,
        }),
      );
      return null;
    }

    if (data) {
      const { id, name } = data;

      if (!id) {
        dispatch(
          addAlert({
            open: true,
            severity: AlertSeverity.Error,
            message: 'Unhandled error in adding category (id not found)',
          }),
        );
        return null;
      }

      dispatch(
        addAlert({
          severity: AlertSeverity.Success,
          open: true,
          message: `Category "${categoryName}" has been created`,
        }),
      );

      // assign
      dispatch(setUploadCategory({ name, id }));

      // refresh list
      await dispatch(refreshCategoriesList());

      return { name, id };
    }

    return data;
  };

  const handleAssignCategory = async (category: any): Promise<boolean> => {
    if (category?.name) {
      await dispatch(
        setUploadCategory({
          name: category.name,
          id: category?.id ?? reactKey('cs-assign-ui-'),
        }),
      );
      return true;
    }
    console.error('Name not found for category:', category);
    return false;
  };

  const handleDisassociateCategory = async (): Promise<void> => {
    await dispatch(setUploadCategory(null));
  };

  return (
    <CategorySelector
      {...boxProps}
      categories={portfolioCategories}
      selectedCategory={selectedCategory}
      onCreateAndAssignNewCategory={handleCreateCategory}
      onAssignCategory={handleAssignCategory}
      onDisassociateCategory={handleDisassociateCategory}
    />
  );
};

export default CategorySelectorContainer;
