import { BoxProps } from '@mui/material';
import { Category } from '../../../api/portfolios/types';
import { StyleProps } from '../../../utils/styleUtils';

export enum IdleStateVariant {
  NORMAL = 'NORMAL',
  INFO_TEXT = 'INFO_TEXT',
}

export interface CategoryOption extends Category {
  isCustom?: true;
  inputValue?: string;
}

export type CategoryNullishType = Category | null;

export type CategoryOptionNullishType = CategoryOption | null;

export type CategoryValue = CategoryOption | null;

export interface CategorySelectorContainerProps extends BoxProps {}

export interface CategorySelectorProps extends CategorySelectorContainerProps {
  externalControls?: boolean; // This setting is for the PortfolioCard variant, where controls for edit mode and dissociate category are external to the CategorySelector component
  isEditingCategory?: boolean; // This setting is for the PortfolioCard variant, where the edit control is external to the CategorySelector component, and therefore edit mode can be set elsewhere and passed as prop to the child (here)
  idleStateVariant?: IdleStateVariant;
  AutocompleteProps?: {
    sx?: StyleProps;
  };
  TypographyProps?: {
    sx?: StyleProps;
  };
  onCancel?: () => void; // TEST: A callback
  categories: Category[];
  selectedCategory: Category | null;
  onCreateAndAssignNewCategory: ({
    categoryName,
  }: {
    categoryName: string;
  }) => Promise<{ id: string; name: string } | null>;
  onAssignCategory: (category: any) => Promise<boolean>;
  onDisassociateCategory: () => Promise<void>;
}
