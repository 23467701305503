import get from 'lodash/get';
import { LOG_COLOR } from '../theme';
import { getUserIdToken } from '../auth0/utils';

type GQLVariables = object | null | undefined;
type GQLQueryString = string;
type Path = string | null;

// TODO: Fix these types -- or better yet: use a library to handle GQL!!!
type GQLData = any[] | any | undefined;

interface FetchGQLResponse {
  data: GQLData | null;
  error: Error | null;
}

interface FetchJSONResponse {
  data?: any;
  errors?: Array<{ message: string }>;
}

export interface GraphqlRequestArgs {
  query: GQLQueryString;
  variables?: GQLVariables;
}

export const getJwt = async (): Promise<string> => {
  return getUserIdToken();
};

const GRAPH_QL_GET = async ({
  query,
  variables = {},
}: GraphqlRequestArgs): Promise<FetchGQLResponse> => {
  try {
    const accessToken = await getJwt();

    if (accessToken.length === 0) {
      return { data: null, error: new Error('AccessToken not found') };
    }

    const request = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        query,
        variables,
        context: {},
      }),
    };

    const res = await fetch(`https://${window._env_.REACT_APP_GQL_ENDPOINT}`, request);

    const { data, errors }: FetchJSONResponse = await res.json();

    return {
      data,
      error: errors
        ? new Error(
            errors?.map((errObj: { message: string }) => errObj.message).join('\n') ??
              'UNKNOWN_ERROR',
          )
        : null,
    };
  } catch (error) {
    console.error('Unhandled exception in GRAPH_QL_GET:', error);
    // This can happen when the user refreshes their browser in the middle of running a query (we will receive a "TypeError: NetworkError")

    return {
      data: null,
      error: null, // I think it is best in this case not to throw an error in this case, so that we don't get a ton of unnecessary / unhelpful errors all the time in the UI
    };
  }
};

const requestLogging = (
  query: GQLQueryString,
  variables: GQLVariables,
  pathToDesiredResult: Path,
  res: FetchGQLResponse,
): void => {
  const type = query.includes('query') ? 'query' : 'mutation';

  const classify = ({ error, data }: FetchGQLResponse): { color: string; status: string } => {
    if (error && data) {
      return { status: 'PARTIAL-SUCCESS', color: LOG_COLOR.warning };
    }
    if (data) {
      return { status: 'SUCCESS', color: LOG_COLOR.info };
    }
    if (error) {
      return { status: 'ERROR', color: LOG_COLOR.error };
    }
    return { status: 'EXCEPTION', color: LOG_COLOR.warning };
  };

  const { color, status } = classify(res);

  const name = (q: string): string => {
    const arr = q.split(' ');
    return arr[arr.indexOf(type) + 1];
  };

  const title = `%c GraphQL ${type}: ${name(query)} -- ${status}`;

  // eslint-disable-next-line no-console
  console.groupCollapsed(title, `color: ${color}`);
  // eslint-disable-next-line no-console
  console.log('query:\n', query);
  // eslint-disable-next-line no-console
  console.log('variables:', variables);
  // eslint-disable-next-line no-console
  console.log('res:', res);
  // eslint-disable-next-line no-console
  console.log('res.error.message:', res?.error?.message);
  // eslint-disable-next-line no-console
  console.log('pathToDesiredResult:', pathToDesiredResult);
  // eslint-disable-next-line no-console
  console.groupEnd();
};

export interface GraphqlResponse {
  data: GQLData | null;
  error: Error | null;
}

export interface GraphqlRequestInput extends GraphqlRequestArgs {
  pathToDesiredResult?: Path;
}

const graphqlRequest = async ({
  query,
  variables = {},
  pathToDesiredResult = null,
}: GraphqlRequestInput): Promise<GraphqlResponse> => {
  const res = await GRAPH_QL_GET({ query, variables });

  // QUESTION: Remove this for production?
  requestLogging(query, variables, pathToDesiredResult, res);

  if (pathToDesiredResult) {
    return {
      ...res,
      data: get(res, `data.${pathToDesiredResult}`, null),
    };
  }

  return res;
};

export default graphqlRequest;
