import { FC, ReactElement } from 'react';
import { Box, BoxProps, Grid, Typography, styled } from '@mui/material';
import { StyleProps, TILE_BORDER } from '../../utils/styleUtils';
import { StyledBoldLabel } from '../labels/StyledLabel';

export interface TileContent {
  id: string;
  title: string;
  subtitle?: string;
  actionButton?: ReactElement | null;
}

type TileCardProps = {
  sxSupplement?: StyleProps;
} & Omit<TileContent, 'id'> &
  BoxProps;

export const StyledTileBox: FC<BoxProps> = styled((props: BoxProps) => {
  return <Box {...props} />;
})(({ theme }) => ({
  padding: theme.spacing(1.5),
  border: TILE_BORDER,
  borderRadius: theme.spacing(0.5),
  textAlign: 'center',
}));

const Tile: FC<TileCardProps> = ({
  sx = {},
  sxSupplement = {},
  title,
  subtitle = '',
  actionButton = null,
}) => {
  return (
    <StyledTileBox sx={{ ...sx, ...sxSupplement }}>
      <Grid container>
        <Grid item xs>
          <StyledBoldLabel variant="h3" sx={{ fontSize: '40px' }}>
            {title}
          </StyledBoldLabel>
          {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
        </Grid>
        {actionButton && (
          <Grid item sx={{ width: '1rem', lineHeight: 1 }}>
            {actionButton}
          </Grid>
        )}
      </Grid>
    </StyledTileBox>
  );
};

export default Tile;
