import { isArray } from 'lodash';
import { SLRRestAPIError } from './types';

export const convertCreateSLRError = (error: SLRRestAPIError): string => {
  const { detail } = error;
  if (!detail) {
    return '';
  }

  const { type, details } = detail;
  let errorText = 'Something went wrong while creating single-location report.';
  switch (type) {
    case 'max_locations_exceeded': {
      const { max_locations: maxLocations } = details;
      errorText = `Locations limit exceeded. Maximum locations allowed are ${
        maxLocations as number
      }.`;
      break;
    }
    case 'portfolio_mismatch': {
      errorText = 'Selected portfolio for SLR does not match the portfolio of selected result-set.';
      break;
    }
    case 'requested_nonexistent_locations': {
      const { available_location_ids: availableLocIds } = details;
      errorText = `Requested location ID(s) does not exist in selected portfolio. Available IDs are [${
        isArray(availableLocIds) ? availableLocIds.join(',') : availableLocIds
      }].`;

      break;
    }
    case 'result_set_not_completed': {
      const { result_set_status: resultSetStatus } = details;
      errorText = `Status of selected result-set is ${resultSetStatus as string}.`;
      break;
    }
    default:
      break;
  }

  return errorText;
};
