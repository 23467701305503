/* eslint-disable no-underscore-dangle */
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import get from 'lodash/get';
import { ChangeEvent, FC, MouseEvent, useMemo, useState } from 'react';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import { MaterialTableProps, Order } from './types';
import { ROWS_PER_PAGE_OPTIONS, getComparator, stableSort } from './util';

const MaterialTable: FC<MaterialTableProps> = ({ data, columns, title = '', dense = true }) => {
  const rows = useMemo(() => {
    return data.map((r, idx) => {
      return { ...r, _rowId: idx.toString() };
    });
  }, [data]);

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>(columns[0].field);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]);

  const handleRequestSort = (event: MouseEvent<unknown>, property: string): void => {
    event.preventDefault();
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // SELECTABLE ROWS
  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._rowId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // PAGINATION

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const rowHeight: number = dense ? 27 : 47; // used also to set the empty rows buffer at the bottom

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%' }}>
        <EnhancedTableToolbar numSelected={selected.length} tableTitle={title} />
        <TableContainer>
          <Table data-testid="table" aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
            <EnhancedTableHead
              columns={columns}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              rowCount={rows.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover style={{ height: rowHeight }} tabIndex={-1} key={row._rowId}>
                      {columns.map(({ field }) => {
                        const value = get(row, field, 'N/A');

                        return (
                          <TableCell align="left" key={`${field}-${row._rowId}`}>
                            {!['string', 'number'].includes(typeof value)
                              ? value.toString()
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: rowHeight * emptyRows,
                  }}
                >
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default MaterialTable;
