import { APIClient, APIClients, APICreateClient } from '../rest/client/types';
import { ClientCredsType, CredType } from '../types/user';

function convertClientCreds(client: APIClient): CredType {
  return {
    clientId: client.client_id,
    clientSecret: client.client_secret,
  };
}

export function formatListClientCreds({ clients }: APIClients): ClientCredsType {
  return {
    creds: clients.map(convertClientCreds),
  };
}

export function formatClientCred({ client }: APICreateClient): CredType {
  return convertClientCreds(client);
}
