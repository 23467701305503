import { ReactElement } from 'react';
import { IdleTimerProvider, EventsType } from 'react-idle-timer';
import { useAuth0 } from '@auth0/auth0-react';
import { userLogout } from '../../redux/slices/userSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectIsLoggedIn } from '../../redux/selectors/userSelectors';

const maxIdleTimeInMinutes = (minutes: number): number => minutes * 60 * 1000;

const EVENTS_THAT_TRIGGER_IDLE_TIMER_RESET: EventsType[] = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  // 'visibilitychange' // <-- NOTE: This is a default in the library, but it's too permissive
];

const IdleTimerWrapper = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}): JSX.Element => {
  const { logout } = useAuth0();
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  const handleIdleTimeLogout = async (): Promise<void> => {
    if (isLoggedIn) {
      // eslint-disable-next-line no-console
      console.log('Idle timeout reached. Logging out now.');
      await logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
      await dispatch(userLogout());
    }
  };

  return (
    <IdleTimerProvider
      timeout={maxIdleTimeInMinutes(60)}
      events={EVENTS_THAT_TRIGGER_IDLE_TIMER_RESET}
      onIdle={() => {
        void handleIdleTimeLogout();
      }}
    >
      {children}
    </IdleTimerProvider>
  );
};

export default IdleTimerWrapper;
