/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface SLRError {
  slrErr: string;
  // Add more errors here...
}

export interface SLRState {
  errors: SLRError;
}

const initialState: SLRState = {
  errors: {
    slrErr: '',
  },
};

export const slrSlice = createSlice({
  name: 'queryBuilder',
  initialState,
  // the `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSLRError: (state: SLRState, action: PayloadAction<string>) => {
      state.errors.slrErr = action.payload;
    },
    resetSLRError: (state: SLRState) => {
      state.errors.slrErr = '';
    },
  },
});

export const { setSLRError, resetSLRError } = slrSlice.actions;

export default slrSlice.reducer;
