import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { selectAppContentSection } from '../../../../redux/selectors/uiSelectors';
import { getDataVersion } from '../../../../redux/slices/csgDataSlice';
import { AppSection, setAppSection } from '../../../../redux/slices/uiSlice';
import { APP_BG_COLOR, APP_TEXT_COLOR, px } from '../../../../utils/styleUtils';
import DownloadDrawer from '../../../download/DownloadDrawer';
import QueryBuilderDrawer from '../../../query-builder/QueryBuilderDrawer';
import VisualizationsStripe from '../../../visualizations/VisualizationsStripe';
import MetadataStripe from '../../MetadataStripe';
import PortfoliosStripe from '../../PortfoliosStripe';
import ResultSetsStripe from '../../ResultSetsStripe';
import SettingsDrawer from '../../SettingsDrawer';
import { TOP_BAR_HEIGHT } from '../../TopBar/util';
import UploadDrawerController from '../../UploadDrawerController';
import SLRDrawer from '../../../slr-drawer/SLRDrawer';

const UserSection: FC = () => {
  const selectedAppSection = useAppSelector(selectAppContentSection);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();

  const responsiveHeight = useMemo(() => {
    // 16px (1rem) for top border of AppContentBox - 2px for border of Toolkit stripe)
    // const verticalModeHeight = `calc(100vh - ${px(TOP_BAR_HEIGHT + T_CARD_MIN_HEIGHT)} - 18px)`;
    const verticalModeHeight = `calc(100vh - ${px(TOP_BAR_HEIGHT)})`;

    if (smallScreen) {
      // NOTE: dividing by 3 at the end because there are
      return `calc(${verticalModeHeight}/3)`;
    }

    return verticalModeHeight;
  }, [smallScreen]);

  const fetchVersion = async (): Promise<void> => {
    await dispatch(getDataVersion());
  };

  useEffect(() => {
    void fetchVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // NOTE: Restricting navigation to visualization screens
    if (selectedAppSection === AppSection.Visualizations) {
      dispatch(setAppSection(AppSection.Explorer));
    }
  }, [selectedAppSection, dispatch]);

  return (
    <>
      <UploadDrawerController />
      <QueryBuilderDrawer />
      <DownloadDrawer />
      <SettingsDrawer />
      <SLRDrawer />

      <Grid
        container
        direction="column"
        // sx={{ overflow: 'auto', background: 'rgba(239, 239, 239, 0.95)' }}
        sx={{
          overflow: 'auto',
          background: APP_BG_COLOR,
          color: APP_TEXT_COLOR,
        }}
      >
        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ToolkitStripe
            IteratorProps={{
              sx: {
                height: px(T_CARD_MIN_HEIGHT),
                background: '#fff',
                borderBottom: '1px solid #000',
                borderRadius: '2px',
              },
            }}
          />
        </Grid> */}

        {/* {selectedAppSection === AppSection.Explorer && ( */}
        <Grid
          item
          container
          direction="row"
          spacing={smallScreen ? 0 : 1.5}
          justifyContent="flex-start"
          sx={{
            paddingLeft: {
              xs: 0,
              sm: 0,
              md: theme.spacing(4.5),
            },
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={3.6} // 30%
            // md="auto" // [breakpoint]="auto" means the grid item's width matches its content
            // lg="auto" // [breakpoint]="auto" means the grid item's width matches its content
            // xl={3.6} // 30%
          >
            <PortfoliosStripe
              vertical
              IteratorProps={{
                sx: {
                  background: 'none',
                  maxWidth: {
                    xs: '100vw',
                    sm: '100vw',
                    md: '35vw',
                  },
                  height: responsiveHeight,
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3.6} // 30%
            // md="auto" // [breakpoint]="auto" means the grid item's width matches its content
            // lg="auto" // [breakpoint]="auto" means the grid item's width matches its content
            // xl={3.6} // 30%
          >
            <ResultSetsStripe
              vertical
              IteratorProps={{
                sx: {
                  background: 'none',
                  maxWidth: {
                    xs: '100vw',
                    sm: '100vw',
                    md: '35vw',
                  },
                  height: responsiveHeight,
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4.8} // 40%
            // md // [breakpoint]=true means the grid item's width grows to use the space available in the grid container
            // lg // [breakpoint]=true means the grid item's width grows to use the space available in the grid container
            // xl={4.8} // 40%
          >
            <MetadataStripe vertical IteratorProps={{ sx: { height: responsiveHeight } }} />
          </Grid>
        </Grid>
        {/* )} */}
        {/* {selectedAppSection === AppSection.Visualizations && (
          <Grid item xs={12} sm md lg xl>
            <VisualizationsStripe
              vertical
              IteratorProps={{ sx: { height: `calc(100vh - ${px(TOP_BAR_HEIGHT)})` } }}
            />
          </Grid>
        )} */}
      </Grid>
    </>
  );
};

export default UserSection;
