import { Edit as EditIcon, Close as CloseIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { ReactElement } from 'react';
import { ValueBold as InfoTextValue } from '../../../components/InfoText';
import { CategoryNullishType, CategorySelectorProps, IdleStateVariant } from './types';
import { StyleProps, spacing } from '../../../utils/styleUtils';
import { StyledMediumLabel } from '../../../components/labels/StyledLabel';

interface IdleStateComponentProps {
  idleStateVariant: IdleStateVariant;
  category: CategoryNullishType;
  TypographyProps: CategorySelectorProps['TypographyProps'];
  buttonSx?: StyleProps;
  onEditClick: () => void;
  onCancelClick?: () => void;
}

export const renderIdleStateVariant = ({
  idleStateVariant,
  category,
  TypographyProps,
  buttonSx = {},
  onEditClick,
  onCancelClick,
}: IdleStateComponentProps): ReactElement | null => {
  const categoryName = category?.name ?? 'None';

  switch (idleStateVariant) {
    case IdleStateVariant.NORMAL:
      return (
        <>
          <StyledMediumLabel
            display="inline"
            variant="h6"
            noWrap
            sx={{
              color: 'inherit',
              paddingRight: spacing(1),
              ...TypographyProps?.sx,
            }}
          >
            {categoryName}
          </StyledMediumLabel>
          <IconButton
            data-testid="category-context-menu-edit-button"
            aria-label="category-context-menu-button"
            aria-controls="category-context-menu"
            aria-haspopup="true"
            onClick={onEditClick}
            color="inherit"
            disableRipple
            sx={{
              padding: 0,
              ...buttonSx,
              ':not(:last-of-type)': {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              },
            }}
          >
            <EditIcon sx={{ height: spacing(2), width: spacing(2) }} />
          </IconButton>
          {onCancelClick && (
            <IconButton
              data-testid="category-context-menu-cancel-button"
              aria-label="category-context-menu-button"
              aria-controls="category-context-menu"
              aria-haspopup="true"
              onClick={onCancelClick}
              color="inherit"
              disableRipple
              sx={{
                padding: 0,
                ...buttonSx,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderLeft: 0,
              }}
            >
              <CloseIcon sx={{ height: spacing(2), width: spacing(2) }} />
            </IconButton>
          )}
        </>
      );
    case IdleStateVariant.INFO_TEXT:
      return (
        <InfoTextValue sx={TypographyProps?.sx} noWrap>
          {categoryName}
        </InfoTextValue>
      );

    default:
      break;
  }

  return null;
};
