import { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Toolbar } from '@mui/material';
import AppWrapper from '../../components/AppWrapper';
import { px } from '../../utils/styleUtils';
import { TOP_BAR_HEIGHT } from '../main/TopBar/util';
import Auth0ErrorContent from '../main/app-content/components/Auth0ErrorContent';
import LoadingIndicator from '../main/app-content/components/LoadingIndicator';

export const CallbackPage: FC = () => {
  const { error } = useAuth0();
  return (
    <AppWrapper showSkeletal>
      <main data-testid="app-content-component" style={{ height: '100%' }}>
        {/* NOTE: The Toolbar component below is a spacer element, to prevent body content from slipping under TopBar */}
        <Toolbar
          sx={{
            height: px(TOP_BAR_HEIGHT),
          }}
        />
        <Box
          sx={{
            height: `calc(100% - ${px(TOP_BAR_HEIGHT)})`,
            overflow: 'auto',
            backgroundImage: 'unset',
            backgroundColor: '#fff',
          }}
        >
          {error?.message ? <Auth0ErrorContent /> : <LoadingIndicator />}
        </Box>
      </main>
    </AppWrapper>
  );
};
