import get from 'lodash/get';
import { EI_MODULES } from './constants';
import { CSG_VERSION } from './types';

export const itemUnselected = (item: any): boolean => get(item, 'selected', false) === false;

export const itemSelected = (item: any): boolean => get(item, 'selected', false) === true;

export const itemNotForbidden = (item: any): boolean => get(item, 'forbidden', false) === false;

export const getId = (item: any): any => item.id;

export const atLeastOneSelected = (list: any): boolean => {
  if (!Array.isArray(list) || list.length === 0) {
    return false;
  }
  const found = list.find(itemSelected);
  return found !== undefined;
};

export const getEIModulesByCSGVersion = (
  csgVersion: string,
  isFinanceEnabled: boolean,
  isWorkerProdEnabled: boolean,
  isWildfireLossEnabled: boolean,
  isCostOfWaterEnabled: boolean,
): string[] => {
  switch (csgVersion) {
    case CSG_VERSION.v3_1_0: {
      let eiModules = EI_MODULES.def_3_0_0;
      if (isWorkerProdEnabled) {
        eiModules = [...eiModules, ...EI_MODULES.worker_3_0_0];
      }
      if (isFinanceEnabled) {
        eiModules = [...eiModules, ...EI_MODULES.fin_3_0_0];
      }
      if (isWildfireLossEnabled) {
        eiModules = [...eiModules, ...EI_MODULES.fire_3_1_0];
      }
      if (isCostOfWaterEnabled) {
        eiModules = [...eiModules, ...EI_MODULES.water_3_1_0];
      }
      return eiModules;
    }
    case CSG_VERSION.v3_0_0: {
      let eiModules = EI_MODULES.def_3_0_0;
      if (isWorkerProdEnabled) {
        eiModules = [...eiModules, ...EI_MODULES.worker_3_0_0];
      }
      if (isFinanceEnabled) {
        eiModules = [...eiModules, ...EI_MODULES.fin_3_0_0];
      }
      return eiModules;
    }
    case CSG_VERSION.v2_6_2: {
      return EI_MODULES.def_2_6_2;
    }
    default: {
      return EI_MODULES.def_2_6_2;
    }
  }
};

export const getEIVersionByCSGVersion = (version: string): string => {
  switch (version) {
    case CSG_VERSION.v2_7_0: {
      return CSG_VERSION.v2_6_2;
    }
    default: {
      return version;
    }
  }
};
