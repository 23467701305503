import { Order } from './types';

export const visuallyHidden = { display: 'none' };

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  // eslint-disable-next-line security/detect-object-injection
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  // eslint-disable-next-line security/detect-object-injection
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array
    .map((el, index) => [el, index] as [T, number])
    .sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    })
    .map((el) => el[0]);

  return stabilizedThis;
}

export const ROWS_PER_PAGE_OPTIONS: number[] = [10, 20, 50];
