import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { isNumber } from 'lodash';
import AppWrapper from '../../components/AppWrapper';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setUserAuthentication, setUserTokens } from '../../redux/slices/userSlice';
import { selectIdToken, selectIsLoggedIn } from '../../redux/selectors/userSelectors';
import {
  extractDetailsFromToken,
  getUserAccessToken,
  getUserIdToken,
  setUserAccessToken,
  setUserIdToken,
} from '../../auth0/utils';
import AcknowledgementModal from './acknowledgement/AcknowledgmentModal';
import { legalAcknowledged, setLegalAcknowledged } from './acknowledgement/util';
import AppContentBox from './app-content/components/AppContentBox';
import UserSection from './app-content/sections/UserSection';
import IdleTimerWrapper from '../../components/idle-timer/IdleTimerWrapper';

const MainPage: FC = () => {
  const dispatch = useAppDispatch();

  const [tokenTimerId, setTokenTimerId] = useState<number>();
  const [isTokenStored, setIsTokenStored] = useState<boolean>(false);

  const {
    isLoading,
    isAuthenticated,
    error,
    loginWithRedirect,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const userIdToken = useAppSelector(selectIdToken);

  const [acknowledged, setAcknowledged] = useState(legalAcknowledged());

  const fetchNewTokens = async (
    skipCache = false,
  ): Promise<{ accessToken: string; idToken: string }> => {
    return await new Promise<{ accessToken: string; idToken: string }>((resolve, reject) => {
      // avoid fetching tokens from cache to get a new set of tokens.
      // used to renew token before expiry.
      getAccessTokenSilently(skipCache ? { cacheMode: 'off' } : {})
        .then((accessToken) => {
          getIdTokenClaims()
            .then((idTokenClaims) => {
              // eslint-disable-next-line no-underscore-dangle
              const idToken = idTokenClaims?.__raw;
              if (idToken) {
                resolve({ accessToken, idToken });
              } else {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject('Error while renewing id token!');
              }
            })
            .catch((e) => {
              reject(e);
            });
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const trySilentRenewToken = (): void => {
    if (isAuthenticated) {
      fetchNewTokens(true)
        .then((res) => {
          const { accessToken, idToken } = res;
          setUserAccessToken(accessToken);
          setUserIdToken(idToken);
          dispatch(setUserTokens({ idToken, accessToken }));

          startTokenExpiryTimer();
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
  };

  const startTokenExpiryTimer = (): void => {
    const idToken = getUserIdToken();
    const accessToken = getUserAccessToken();

    // Extract id-token expiry
    const { isExpired: isIdTokenExpired, timeoutExpiry: timeoutIdTokenExpiry } =
      extractDetailsFromToken(idToken);
    // Extract access-token expiry
    const { isExpired: isAccessTokenExpired, timeoutExpiry: timeoutAccessTokenExpiry } =
      extractDetailsFromToken(accessToken);

    if (
      !isIdTokenExpired &&
      !isAccessTokenExpired &&
      isNumber(timeoutIdTokenExpiry) &&
      isNumber(timeoutAccessTokenExpiry)
    ) {
      // Have 5m buffer before start trying for silent signIn
      // If token is about to expire then start silentSignIn
      // else just set timer to try for silentSignIn before token expires
      clearTimeout(tokenTimerId);

      // Set the shortest expiry time as timeout
      const timeoutExpiry =
        timeoutAccessTokenExpiry < timeoutIdTokenExpiry
          ? timeoutAccessTokenExpiry
          : timeoutIdTokenExpiry;
      const timerId = setTimeout(() => {
        trySilentRenewToken();
      }, timeoutExpiry);
      setTokenTimerId(Number(timerId));
    }
  };

  const handleLoginWithAuth0 = useCallback(async (): Promise<void> => {
    try {
      // eslint-disable-next-line no-console
      console.log('=============== AUTH0 LOGIN START ===============');
      await loginWithRedirect({
        appState: {
          returnTo: '/',
        },
      });
      // eslint-disable-next-line no-console
      console.log('=============== AUTH0 LOGIN END ===============');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, [loginWithRedirect]);

  useEffect(() => {
    if (!legalAcknowledged()) {
      setAcknowledged(false);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn !== isAuthenticated) {
      dispatch(setUserAuthentication({ isAuthenticated }));
    }
  }, [dispatch, isAuthenticated, isLoggedIn]);

  useEffect(() => {
    void (async () => {
      if (error) {
        // eslint-disable-next-line no-console
        console.log('AUTH0 ERROR: ', error);
      }
      if (isAuthenticated) {
        try {
          const currIdToken = getUserIdToken();
          const currAccessToken = getUserAccessToken();
          const newTokens =
            (Boolean(currAccessToken) && extractDetailsFromToken(currAccessToken).isExpired) ||
            (Boolean(currIdToken) && extractDetailsFromToken(currIdToken).isExpired);
          const { accessToken, idToken } = await fetchNewTokens(newTokens);
          setUserAccessToken(accessToken);
          setUserIdToken(idToken);
          dispatch(setUserTokens({ idToken, accessToken }));
          setIsTokenStored(true);

          startTokenExpiryTimer();
        } catch (e) {
          void handleLoginWithAuth0();
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, getIdTokenClaims, isAuthenticated, error, handleLoginWithAuth0]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      void handleLoginWithAuth0();
    }
  }, [isLoading, isAuthenticated, handleLoginWithAuth0]);

  const scrimMode = useMemo(() => isLoggedIn && !acknowledged, [isLoggedIn, acknowledged]);

  const isLoadingUser = useMemo(
    () => isLoading || (!(userIdToken && isTokenStored) && isLoggedIn),
    [isLoading, isLoggedIn, userIdToken, isTokenStored],
  );

  const style = useMemo(() => {
    // NOTE: `backgroundImage: 'unset'` removes the background image
    if (isLoadingUser || !isLoggedIn) {
      return {
        backgroundImage: 'unset',
        backgroundColor: '#fff',
      };
    }
    return isLoggedIn
      ? {
          backgroundImage: 'unset',
          backgroundColor: scrimMode ? '#fff' : '#000',
        }
      : {};
  }, [isLoggedIn, scrimMode, isLoadingUser]);

  // NOTE: scrimMode is about showing a "blank" version of the app in the background with the AcknowledgementModal open in the foreground. This is a security measure that prevents users who have not acknowledged from viewing the data in UserSection.

  return (
    <IdleTimerWrapper>
      <AppWrapper scrim={scrimMode} showSkeletal={isLoadingUser || !isLoggedIn}>
        <AcknowledgementModal
          open={scrimMode}
          onConfirm={() => {
            setLegalAcknowledged(true);
            setAcknowledged(true);
          }}
        />
        <AppContentBox
          scrim={scrimMode}
          showSkeletal={isLoadingUser || !isLoggedIn}
          sx={{
            ...style,
          }}
          isLoggedIn={isLoggedIn}
        >
          <UserSection />
        </AppContentBox>
      </AppWrapper>
    </IdleTimerWrapper>
  );
};

export default MainPage;
