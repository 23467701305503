import { Box, Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';
import { StyleProps } from '../utils/styleUtils';
import { StyledBoldLabel, StyledMediumLabel, StyledThinLabel } from './labels/StyledLabel';

export const Title: FC<TypographyProps> = ({ children, noWrap, sx, ...typographyProps }) => (
  <StyledThinLabel
    variant="h6"
    noWrap={noWrap}
    sx={{ color: 'inherit', ...sx }}
    {...typographyProps}
  >
    {children}
  </StyledThinLabel>
);

export const Value: FC<TypographyProps> = ({ children, noWrap, sx, ...typographyProps }) => (
  <StyledMediumLabel
    variant="h6"
    noWrap={noWrap}
    sx={{ color: 'inherit', ...sx }}
    {...typographyProps}
  >
    {children}
  </StyledMediumLabel>
);

export const ValueBold: FC<TypographyProps> = ({ children, noWrap, sx, ...typographyProps }) => (
  <StyledBoldLabel
    variant="subtitle1"
    noWrap={noWrap}
    sx={{ color: 'inherit', ...sx }}
    {...typographyProps}
  >
    {children}
  </StyledBoldLabel>
);

interface InfoTextProps {
  title: string;
  value: string;
  sx?: StyleProps;
}

const InfoText: FC<InfoTextProps> = ({ sx = {}, title, value }) => (
  <Typography variant="subtitle1" sx={{ ...sx }}>
    <Title>{title}: </Title>
    <Value>{value}</Value>
  </Typography>
);

export interface InfoTextItem {
  title: string;
  value: string;
  id: string;
}

export enum InfoTextSection {
  TITLE = '_title_',
  VALUE = '_value_',
}

interface InfoTextBlockProps {
  justifyOn?: InfoTextSection;
  infoList: InfoTextItem[];
  textWrap?: boolean;
}

const FLEX_COLUMN_LEFT = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
};

export const InfoTextBlock: FC<InfoTextBlockProps> = ({
  justifyOn = InfoTextSection.TITLE,
  infoList,
  textWrap = false,
}) => {
  switch (justifyOn) {
    case InfoTextSection.TITLE:
      return (
        <Box sx={FLEX_COLUMN_LEFT}>
          {infoList.map((item) => (
            <InfoText key={item.id} title={item.title} value={item.value} />
          ))}
        </Box>
      );
    case InfoTextSection.VALUE:
      return (
        <Box
          sx={{
            display: 'table',
            width: '100%',
            height: '100%',
          }}
        >
          {infoList.map(({ id, title, value }) => (
            <Box key={`row-${id}`} data-testid="info-item" sx={{ display: 'table-row' }}>
              <span
                style={{
                  display: 'table-cell',
                  textAlign: 'right',
                  paddingRight: '5px',
                  width: '40%',
                  height: '100%',
                }}
              >
                <Title noWrap={!textWrap}>{title}: </Title>
              </span>
              <Value
                noWrap={!textWrap}
                sx={{
                  height: '100%',
                  verticalAlign: 'bottom',
                  display: 'table-cell',
                }}
              >
                {value}
              </Value>
            </Box>
          ))}
        </Box>
      );
    default:
      return null;
  }
};
export default InfoText;
