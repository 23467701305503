/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertSeverity } from '../../components/alerts/types';
import { DashboardObject, DUNDAS_DASHBOARDS } from '../../pages/visualizations/dundas/config';
import { addAlert, AppSection, setAppSection } from './uiSlice';

export const handleNoVizAvailableForRs = createAsyncThunk(
  'viz/none-available-for-rs',
  async (_, { dispatch }) => {
    dispatch(
      addAlert({
        severity: AlertSeverity.Info,
        open: true,
        message:
          'There are currently no visualizations available for this Result Set. You will be redirected to the Explorer view now.',
        options: {
          autoHideDuration: 10000, // 10 seconds. Normally it is 5 seconds.
          closeable: true,
          closeButtonText: 'Please click here to proceed immediately.',
          onCloseClickCallback: () => {
            // proceed immediately
            dispatch(setAppSection(AppSection.Explorer));
          },
        },
      }),
    );

    // eslint-disable-next-line no-console
    console.log('> Waiting 10s...');
    await new Promise((resolve) => {
      setTimeout(resolve, 10000);
    });
    // eslint-disable-next-line no-console
    console.log('> Done.');

    dispatch(setAppSection(AppSection.Explorer));
  },
);

export type LogonTokenRedux = string | null;
export type ErrorMsgRedux = string;

export interface VizState {
  selectedDashboard: DashboardObject | null;
  dashboards: DashboardObject[];
  token: LogonTokenRedux;
}

const initialState: VizState = {
  selectedDashboard: null,
  dashboards: Object.values(DUNDAS_DASHBOARDS),
  token: null,
};

export const visualizationsSlice = createSlice({
  name: 'viz',
  initialState,
  // the `reducers` field lets us define reducers and generate associated actions
  reducers: {
    unSetDashboard: (state: VizState) => {
      state.selectedDashboard = null;
    },
    setDashboard: (state: VizState, action: PayloadAction<DashboardObject>) => {
      state.selectedDashboard = action.payload;
    },
    setDundasLogOnToken: (state: VizState, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
  },
});

export const { setDashboard, setDundasLogOnToken, unSetDashboard } = visualizationsSlice.actions;

export default visualizationsSlice.reducer;
