import { get, isArray, isEmpty, isNil } from 'lodash';
import { reactKey } from '../../utils/reactUtils';
import { DamageAndLossField } from '../../api/resultSets/types';
import {
  BenchmarkingLabelUI,
  BenchmarkingLevelUI,
  DamageAndLossLabelUI,
  DamageAndLossPlaceholderUI,
  EIModules,
  FinancialMetricsLabelUI,
  FloodDefenseLabelUI,
  GridSizeUI,
  ParameterOptionsUI,
  PerilUI,
  PortfolioLossMetricsLabelUI,
  ResultSetConfig,
  ResultSetOptionsUI,
  ResultSetTypeUI,
  ScenarioUI,
  ScorePerilUI,
  WaterConsumptionLabelUI,
  WildfireLossLabelUI,
  WorkerProductivityLabelUI,
} from './types';
import {
  validateFloatBetweenZeroToOne,
  validateFloatFromZeroToLessThanOne,
  validateGICSEightDigitInteger,
} from './validation/validators';

export const ENABLE_QUERY_BUILDER_OPTION_TOOLTIP =
  'This section is disabled, please enable it with switching the toggle to start making selections';

export const RESULT_NAME_LABELS: {
  [section: string]: string;
} = {
  ECONOMIC_IMPACTS: 'Peril Metrics with Economic Impacts',
  FLOOD_MESH: 'Flood Mesh',
  PERILS: 'Peril Metrics Only',
  SCORES: 'Scores',
};

export const EI_MODULES = {
  def_2_6_2: [EIModules.ACUTE_COMBINED_FLOOD, EIModules.ACUTE_WIND],
  def_3_0_0: [EIModules.ACUTE_COMBINED_FLOOD, EIModules.ACUTE_WIND],
  worker_3_0_0: [EIModules.UTILITIES_COOLING, EIModules.PRODUCTIVITY_HEAT],
  fin_3_0_0: [EIModules.FINANCIAL],
  fire_3_1_0: [EIModules.ACUTE_FIRE],
  water_3_1_0: [EIModules.UTILITIES_WATER],
};

export const INPUT_CHAR_RESTRICTED: Record<string, string[]> = {
  INT: ['e', 'E', '+'],
  FLOAT: ['e', 'E', '+'],
  STRING: [], // No CHAR restrictions for STRING input
};

// Refer https://jupiterintel.atlassian.net/browse/SAAS-1509 for validation details
export const OCCUPANCY_VALIDATIONS: { [name: string]: (code: string) => boolean } = {
  ATC: (code: string) => {
    const iCode = parseInt(code, 10);
    return !Number.isNaN(iCode) && iCode >= 0 && iCode <= 39;
  },
  OED: (code: string) => {
    const iCode = parseInt(code, 10);
    return (
      !Number.isNaN(iCode) &&
      [
        1000, 1050, 1051, 1052, 1053, 1054, 1055, 1056, 1057, 1058, 1070, 1071, 1072, 1073, 1100,
        1101, 1102, 1103, 1104, 1105, 1106, 1107, 1108, 1109, 1110, 1111, 1112, 1113, 1114, 1115,
        1116, 1117, 1118, 1119, 1120, 1121, 1122, 1123, 1124, 1150, 1151, 1152, 1153, 1154, 1155,
        1156, 1157, 1158, 1159, 1200, 1201, 1210, 1211, 1212, 1213, 1214, 1215, 1220, 1230, 1231,
        1250, 1251, 1252, 1253, 1254, 1255, 1256, 1300, 1301, 1302, 1303, 1304, 1305, 1350, 1351,
        1352, 2000, 2050, 2051, 2052, 2053, 2054, 2055, 2056, 2057, 2058, 2100, 2101, 2102, 2103,
        2104, 2105, 2106, 2107, 2108, 2109, 2110, 2111, 2150, 2151, 2152, 2153, 2154, 2155, 2200,
        2201, 2202, 2203, 2204, 2205, 2206, 2207, 2208, 2250, 2251, 2252, 2253, 2300, 2301, 2302,
        2303, 2304, 2305, 2350, 2351, 2352, 2400, 2401, 2402, 2403, 2404, 2450, 2460, 2461, 2470,
        2500, 2505, 2510, 2515, 2520, 2521, 2530, 2531, 2541, 2542, 2550, 2560, 2600, 2610, 2611,
        2612, 2613, 2620, 2650, 2700, 2750, 2760, 2770, 2780,
      ].includes(iCode)
    );
  },
};

const RESULT_SET_CONFIG: ResultSetConfig = {
  // ------------------------------------------ 1. Perils Only
  [ResultSetTypeUI.PERILS]: {
    disabled: false,
    selected: false,
    name: RESULT_NAME_LABELS.PERILS,
    id: `${ResultSetTypeUI.PERILS}-panel`,
    type: ResultSetTypeUI.PERILS,
    parameters: {
      perils: [
        {
          name: PerilUI.WIND,
          selected: true,
          id: reactKey('perils.perils'),
        },
        {
          name: PerilUI.COLD,
          selected: true,
          id: reactKey('perils.perils'),
        },
        {
          name: PerilUI.PRECIP,
          selected: true,
          id: reactKey('perils.perils'),
        },
        {
          name: PerilUI.HEAT,
          selected: true,
          id: reactKey('perils.perils'),
        },
        {
          name: PerilUI.COMBINED_FLOOD,
          selected: true,
          id: reactKey('perils.perils'),
        },
        {
          name: PerilUI.HAIL,
          selected: true,
          id: reactKey('perils.perils'),
        },
        {
          name: PerilUI.DROUGHT,
          selected: true,
          id: reactKey('perils.perils'),
        },
        {
          name: PerilUI.FIRE,
          selected: true,
          id: reactKey('perils.perils'),
        },
        {
          name: PerilUI.COASTAL_FLOOD,
          selected: false,
          id: reactKey('perils.perils'),
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
        },
        {
          name: PerilUI.FLUVIAL_FLOOD,
          selected: false,
          id: reactKey('perils.perils'),
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
        },
        {
          name: PerilUI.PLUVIAL_FLOOD,
          selected: false,
          id: reactKey('perils.perils'),
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
        },
      ],
      scenarios: [
        {
          name: ScenarioUI.BASELINE,
          selected: true,
          id: reactKey('perils.scenarios'),
        },
        {
          name: ScenarioUI.SSP_126,
          selected: true,
          id: reactKey('perils.scenarios'),
        },
        {
          name: ScenarioUI.SSP_245,
          selected: true,
          id: reactKey('perils.scenarios'),
        },
        {
          name: ScenarioUI.SSP_585,
          selected: true,
          id: reactKey('perils.scenarios'),
        },
      ],
      years: [
        {
          name: '1995',
          selected: true,
          id: reactKey('perils.years'),
        },
        {
          name: '2020',
          selected: true,
          id: reactKey('perils.years'),
        },
        {
          name: '2025',
          selected: false,
          id: reactKey('perils.years'),
        },
        {
          name: '2030',
          selected: true,
          id: reactKey('perils.years'),
        },
        {
          name: '2035',
          selected: false,
          id: reactKey('perils.years'),
        },
        {
          name: '2040',
          selected: true,
          id: reactKey('perils.years'),
        },
        {
          name: '2045',
          selected: false,
          id: reactKey('perils.years'),
        },
        {
          name: '2050',
          selected: true,
          id: reactKey('perils.years'),
        },
        {
          name: '2055',
          selected: false,
          id: reactKey('perils.years'),
        },
        {
          name: '2060',
          selected: false,
          id: reactKey('perils.years'),
        },
        {
          name: '2065',
          selected: false,
          id: reactKey('perils.years'),
        },
        {
          name: '2070',
          selected: false,
          id: reactKey('perils.years'),
        },
        {
          name: '2075',
          selected: true,
          id: reactKey('perils.years'),
        },
        {
          name: '2080',
          selected: false,
          id: reactKey('perils.years'),
        },
        {
          name: '2085',
          selected: false,
          id: reactKey('perils.years'),
        },
        {
          name: '2090',
          selected: false,
          id: reactKey('perils.years'),
        },
        {
          name: '2095',
          selected: false,
          id: reactKey('perils.years'),
        },
        {
          name: '2100',
          selected: true,
          id: reactKey('perils.years'),
        },
      ],
      floodDefense: [
        {
          selected: true,
          name: FloodDefenseLabelUI.ENABLED,
          id: reactKey('perils.floodDefenseFlag'),
        },
      ],
      floodDefenseOptions: [
        {
          selected: true,
          name: FloodDefenseLabelUI.ZERO_OUT_DEFENDED_LOCATIONS,
          id: reactKey('perils.floodDefense'),
          dataVersionRestrictions: ['2.6.2'],
        },
      ],
    },
  },
  // ------------------------------------------ 2. Damage & Loss
  [ResultSetTypeUI.ECONOMIC_IMPACTS]: {
    disabled: false,
    selected: true,
    name: RESULT_NAME_LABELS.ECONOMIC_IMPACTS,
    id: `${ResultSetTypeUI.ECONOMIC_IMPACTS}-panel`,
    type: ResultSetTypeUI.ECONOMIC_IMPACTS,
    parameters: {
      perils: [
        {
          name: PerilUI.WIND,
          selected: true,
          id: reactKey('dl.perils'),
        },
        {
          name: PerilUI.COLD,
          selected: true,
          id: reactKey('dl.perils'),
        },
        {
          name: PerilUI.PRECIP,
          selected: true,
          id: reactKey('dl.perils'),
        },
        {
          name: PerilUI.HEAT,
          selected: true,
          id: reactKey('dl.perils'),
        },
        {
          name: PerilUI.COMBINED_FLOOD,
          selected: true,
          id: reactKey('dl.perils'),
        },
        {
          name: PerilUI.HAIL,
          selected: true,
          id: reactKey('dl.perils'),
        },
        {
          name: PerilUI.DROUGHT,
          selected: true,
          id: reactKey('dl.perils'),
        },
        {
          name: PerilUI.FIRE,
          selected: true,
          id: reactKey('dl.perils'),
        },
        {
          name: PerilUI.COASTAL_FLOOD,
          selected: false,
          id: reactKey('dl.perils'),
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
        },
        {
          name: PerilUI.FLUVIAL_FLOOD,
          selected: false,
          id: reactKey('dl.perils'),
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
        },
        {
          name: PerilUI.PLUVIAL_FLOOD,
          selected: false,
          id: reactKey('dl.perils'),
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
        },
      ],
      scenarios: [
        {
          name: ScenarioUI.BASELINE,
          selected: true,
          id: reactKey('dl.scenarios'),
        },
        {
          name: ScenarioUI.SSP_126,
          selected: true,
          id: reactKey('dl.scenarios'),
        },
        {
          name: ScenarioUI.SSP_245,
          selected: true,
          id: reactKey('dl.scenarios'),
        },
        {
          name: ScenarioUI.SSP_585,
          selected: true,
          id: reactKey('dl.scenarios'),
        },
      ],
      years: [
        { name: '1995', selected: true, id: reactKey('dl.years') },
        { name: '2020', selected: true, id: reactKey('dl.years') },
        { name: '2025', selected: true, id: reactKey('dl.years') },
        { name: '2030', selected: true, id: reactKey('dl.years') },
        { name: '2035', selected: true, id: reactKey('dl.years') },
        { name: '2040', selected: true, id: reactKey('dl.years') },
        { name: '2045', selected: true, id: reactKey('dl.years') },
        { name: '2050', selected: true, id: reactKey('dl.years') },
        { name: '2055', selected: true, id: reactKey('dl.years') },
        { name: '2060', selected: true, id: reactKey('dl.years') },
        { name: '2065', selected: true, id: reactKey('dl.years') },
        { name: '2070', selected: true, id: reactKey('dl.years') },
        { name: '2075', selected: true, id: reactKey('dl.years') },
        { name: '2080', selected: true, id: reactKey('dl.years') },
        { name: '2085', selected: true, id: reactKey('dl.years') },
        { name: '2090', selected: true, id: reactKey('dl.years') },
        { name: '2095', selected: true, id: reactKey('dl.years') },
        { name: '2100', selected: true, id: reactKey('dl.years') },
      ],
      floodDefense: [
        {
          selected: true,
          name: FloodDefenseLabelUI.ENABLED,
          id: reactKey('dl.floodDefenseFlag'),
        },
      ],
      floodDefenseOptions: [
        {
          selected: true,
          name: FloodDefenseLabelUI.ZERO_OUT_DEFENDED_LOCATIONS,
          id: reactKey('dl.floodDefense'),
          dataVersionRestrictions: ['2.6.2'],
        },
      ],
      damageAndLossValues: [
        {
          id: reactKey('dl.occupancyScheme'),
          name: DamageAndLossLabelUI.OCCUPANCY_SCHEME,
          placeholder: DamageAndLossPlaceholderUI.OCCUPANCY_SCHEME,
          selected: true,
          // disabled: true, // user cannot change this value
          value: 'OED', // QUESTION: How am I going to make this a dropdown with multiple options?
          selectionList: [
            {
              name: 'OED',
              value: 'OED',
              id: reactKey('dl.occupancyScheme.menuItem'),
            },
            {
              name: 'ATC',
              value: 'ATC',
              id: reactKey('dl.occupancyScheme.menuItem'),
            },
          ],
          dataTypeUI: 'STRING',
          // tooltip: '<text to be determined>',
        },
        {
          id: reactKey('dl.occupancyCode'),
          name: DamageAndLossLabelUI.OCCUPANCY_CODE,
          placeholder: DamageAndLossPlaceholderUI.OCCUPANCY_CODE,
          selected: true,
          value: '1000',
          dataTypeUI: 'INT',
          // tooltip: '<text to be determined>',
        },
        {
          id: reactKey('dl.numberOfStories'),
          name: DamageAndLossLabelUI.NUMBER_OF_STORIES,
          placeholder: DamageAndLossPlaceholderUI.NUMBER_OF_STORIES,
          selected: true,
          value: '0',
          dataTypeUI: 'INT',
          // tooltip: '<text to be determined>',
        },
        {
          id: reactKey('dl.basementCode'),
          name: DamageAndLossLabelUI.BASEMENT_CODE,
          placeholder: DamageAndLossPlaceholderUI.BASEMENT_CODE,
          selected: true,
          value: '1',
          selectionList: [
            {
              name: '0 - Unknown',
              value: '0',
              id: reactKey('dl.basementCode.menuItem'),
            },
            {
              name: '1 - Not Present',
              value: '1',
              id: reactKey('dl.basementCode.menuItem'),
            },
            {
              name: '2 - Present',
              value: '2',
              id: reactKey('dl.basementCode.menuItem'),
            },
          ],
          dataTypeUI: 'INT',
          // tooltip: '<text to be determined>',
        },
        {
          id: reactKey('dl.firstFloorElevation'),
          name: DamageAndLossLabelUI.FIRST_FLOOR_ELEVATION,
          placeholder: DamageAndLossPlaceholderUI.FIRST_FLOOR_ELEVATION,
          selected: true,
          value: '',
          dataTypeUI: 'FLOAT',
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
          tooltip: 'Meters',
        },
        {
          id: reactKey('dl.floorAreaSqm'),
          name: DamageAndLossLabelUI.FLOOR_AREA_SQM,
          placeholder: DamageAndLossPlaceholderUI.FLOOR_AREA_SQM,
          selected: true,
          value: '',
          dataTypeUI: 'FLOAT',
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
          tooltip: 'Square Meters',
        },
      ],
      lossCalculationValues: [
        {
          id: reactKey('dl.totalValue'),
          name: DamageAndLossLabelUI.TOTAL_VALUE,
          placeholder: DamageAndLossPlaceholderUI.TOTAL_VALUE,
          selected: true,
          value: '0',
          dataTypeUI: 'FLOAT',
          // tooltip: '<text to be determined>',
        },
        {
          id: reactKey('dl.buildingValue'),
          name: DamageAndLossLabelUI.BUILDING_VALUE,
          placeholder: DamageAndLossPlaceholderUI.BUILDING_VALUE,
          selected: true,
          value: '0',
          dataTypeUI: 'FLOAT',
          // tooltip: '<text to be determined>',
        },
        {
          id: reactKey('dl.contentsValue'),
          name: DamageAndLossLabelUI.CONTENTS_VALUE,
          placeholder: DamageAndLossPlaceholderUI.CONTENTS_VALUE,
          selected: true,
          value: '0',
          dataTypeUI: 'FLOAT',
          // tooltip: '<text to be determined>',
        },
        {
          id: reactKey('dl.inventoryValue'),
          name: DamageAndLossLabelUI.INVENTORY_VALUE,
          placeholder: DamageAndLossPlaceholderUI.INVENTORY_VALUE,
          selected: true,
          value: '0',
          dataTypeUI: 'FLOAT',
          // tooltip: '<text to be determined>',
        },
        {
          id: reactKey('dl.downtimeValue'),
          name: DamageAndLossLabelUI.DOWNTIME_VALUE,
          placeholder: DamageAndLossPlaceholderUI.DOWNTIME_VALUE,
          selected: true,
          value: '0',
          dataTypeUI: 'FLOAT',
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
          tooltip: 'Annual revenue associated with a given asset',
        },
      ],
      advancedParameterValues: [
        {
          id: reactKey('dl.remoteWorkRatio'),
          name: DamageAndLossLabelUI.REMOTE_WORK_RATIO,
          placeholder: DamageAndLossPlaceholderUI.REMOTE_WORK_RATIO,
          selected: true,
          value: '',
          dataTypeUI: 'FLOAT',
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
          tooltip:
            'Set to 0 if all work must be performed at the asset, 1 if all work can be performed remotely, or somewhere in between. Leave blank to have a ratio assigned',
          validate: validateFloatBetweenZeroToOne,
        },
        {
          id: reactKey('dl.financialBaseYear'),
          name: DamageAndLossLabelUI.FINANCIAL_BASE_YEAR,
          placeholder: DamageAndLossPlaceholderUI.FINANCIAL_BASE_YEAR,
          selected: true,
          value: '2020',
          selectionList: [
            {
              name: '2020',
              value: '2020',
              id: reactKey('dl.financialBaseYear.menuItem'),
            },
            {
              name: '2021',
              value: '2021',
              id: reactKey('dl.financialBaseYear.menuItem'),
            },
            {
              name: '2022',
              value: '2022',
              id: reactKey('dl.financialBaseYear.menuItem'),
            },
            {
              name: '2023',
              value: '2023',
              id: reactKey('dl.financialBaseYear.menuItem'),
            },
            {
              name: '2024',
              value: '2024',
              id: reactKey('dl.financialBaseYear.menuItem'),
            },
            {
              name: '2025',
              value: '2025',
              id: reactKey('dl.financialBaseYear.menuItem'),
            },
          ],
          dataTypeUI: 'INT',
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
          tooltip:
            'The calendar year used as the starting reference point for the financial module projections',
        },
      ],
      workerProductivity: [
        {
          selected: true,
          disabled: false,
          isFieldEnabler: true,
          name: WorkerProductivityLabelUI.ENABLED,
          id: reactKey('dl.workerProductivity'),
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
        },
      ],
      workerProductivityValues: [
        {
          id: reactKey('dl.electricityCost'),
          name: DamageAndLossLabelUI.ELECTRICITY_COST,
          placeholder: DamageAndLossPlaceholderUI.ELECTRICITY_COST,
          selected: true,
          value: '',
          dataTypeUI: 'FLOAT',
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
          tooltip: 'USD per kWh',
        },
        {
          id: reactKey('dl.coolingSystemProbability'),
          name: DamageAndLossLabelUI.COOLING_SYSTEM_PROBABILITY,
          placeholder: DamageAndLossPlaceholderUI.COOLING_SYSTEM_PROBABILITY,
          selected: true,
          value: '',
          dataTypeUI: 'FLOAT',
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
          tooltip:
            'Set to 0 if not present, 1 if present, or somewhere in between. Leave blank to have a probability assigned',
          validate: validateFloatBetweenZeroToOne,
        },
        {
          id: reactKey('dl.workIntensity'),
          name: DamageAndLossLabelUI.WORK_INTENSITY,
          placeholder: DamageAndLossPlaceholderUI.WORK_INTENSITY,
          selected: true,
          value: ' ',
          selectionList: [
            {
              name: 'High',
              value: 'H',
              id: reactKey('dl.workIntensity.menuItem'),
            },
            {
              name: 'Medium',
              value: 'M',
              id: reactKey('dl.workIntensity.menuItem'),
            },
            {
              name: 'Low',
              value: 'L',
              id: reactKey('dl.workIntensity.menuItem'),
            },
            {
              name: 'None',
              value: 'N',
              id: reactKey('dl.workIntensity.menuItem'),
            },
            {
              name: 'Unknown',
              value: ' ', // using space as '' is not selecting the item
              id: reactKey('dl.workIntensity.menuItem'),
            },
          ],
          dataTypeUI: 'STRING',
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
          // tooltip: '<text to be determined>',
        },
      ],
      wildfireLoss: [
        {
          selected: true,
          disabled: false,
          isFieldEnabler: true,
          name: WildfireLossLabelUI.ENABLED,
          id: reactKey('dl.wildfireLoss'),
          dataVersionRestrictions: ['3.1.0'],
        },
      ],
      wildfireLossValues: [
        {
          id: reactKey('dl.windowPane'),
          name: DamageAndLossLabelUI.WINDOW_PANE,
          placeholder: DamageAndLossPlaceholderUI.WINDOW_PANE,
          selected: true,
          value: 'UNKNOWN',
          selectionList: [
            {
              name: 'Single',
              value: 'SINGLE',
              id: reactKey('dl.windowPane.menuItem'),
            },
            {
              name: 'Multiple',
              value: 'MULTIPLE',
              id: reactKey('dl.windowPane.menuItem'),
            },
            {
              name: 'Unknown',
              value: 'UNKNOWN',
              id: reactKey('dl.windowPane.menuItem'),
            },
          ],
          dataTypeUI: 'STRING',
          dataVersionRestrictions: ['3.1.0'],
          tooltip: 'Type of window construction (single-pane, multi-pane, or none).',
        },
        {
          id: reactKey('dl.ventType'),
          name: DamageAndLossLabelUI.VENT_TYPE,
          placeholder: DamageAndLossPlaceholderUI.VENT_TYPE,
          selected: true,
          value: 'UNKNOWN',
          selectionList: [
            {
              name: 'Exposed',
              value: 'EXPOSED',
              id: reactKey('dl.ventType.menuItem'),
            },
            {
              name: 'Non Exposed',
              value: 'NONEXPOSED',
              id: reactKey('dl.ventType.menuItem'),
            },
            {
              name: 'Unknown',
              value: 'UNKNOWN',
              id: reactKey('dl.ventType.menuItem'),
            },
          ],
          dataTypeUI: 'STRING',
          dataVersionRestrictions: ['3.1.0'],
          tooltip:
            'The type of exterior vents (attic and basement). See Input Data Guidance for more details.',
        },
        {
          id: reactKey('dl.roofCover'),
          name: DamageAndLossLabelUI.ROOF_COVER,
          placeholder: DamageAndLossPlaceholderUI.ROOF_COVER,
          selected: true,
          value: 'UNKNOWN',
          selectionList: [
            {
              name: 'Wood',
              value: 'WOOD',
              id: reactKey('dl.roofCover.menuItem'),
            },
            {
              name: 'Other',
              value: 'OTHER',
              id: reactKey('dl.roofCover.menuItem'),
            },
            {
              name: 'Unknown',
              value: 'UNKNOWN',
              id: reactKey('dl.roofCover.menuItem'),
            },
          ],
          dataTypeUI: 'STRING',
          dataVersionRestrictions: ['3.1.0'],
          tooltip: 'The top layer of roofing material (wood or other).',
        },
      ],
      costOfWater: [
        {
          selected: true,
          disabled: false,
          isFieldEnabler: true,
          name: WaterConsumptionLabelUI.ENABLED,
          id: reactKey('dl.costOfWater'),
          dataVersionRestrictions: ['3.1.0'],
        },
      ],
      costOfWaterValues: [
        {
          id: reactKey('dl.waterConsumption'),
          name: DamageAndLossLabelUI.WATER_CONSUMPTION,
          placeholder: DamageAndLossPlaceholderUI.WATER_CONSUMPTION,
          selected: true,
          value: '',
          dataTypeUI: 'FLOAT',
          dataVersionRestrictions: ['3.1.0'],
          tooltip:
            'Annual water consumption in cubic meters. Leave blank to have a value assigned based on occupancy.',
        },
        {
          id: reactKey('dl.waterShadowPriceRatio'),
          name: DamageAndLossLabelUI.WATER_SHADOW_PRICE_RATIO,
          placeholder: DamageAndLossPlaceholderUI.WATER_SHADOW_PRICE_RATIO,
          selected: true,
          value: '0',
          dataTypeUI: 'FLOAT',
          dataVersionRestrictions: ['3.1.0'],
          tooltip:
            'The expected ratio of the shadow price of water (i.e. the total economic value of water) to the effective price of water.',
          validate: validateFloatBetweenZeroToOne,
        },
      ],
      portfolioLossMetrics: [
        {
          selected: false,
          disabled: false,
          isFieldEnabler: true,
          name: PortfolioLossMetricsLabelUI.ENABLED,
          id: reactKey('dl.portfolioLossMetrics'),
          dataVersionRestrictions: ['3.1.0'],
        },
      ],
      financialMetrics: [
        {
          selected: false,
          name: FinancialMetricsLabelUI.ENABLED,
          id: reactKey('dl.financialMetrics'),
          isFieldEnabler: true,
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
        },
      ],
      financialParametersValues: [
        {
          id: reactKey('dl.annualGrowthRate'),
          name: DamageAndLossLabelUI.ANNUAL_GROWTH_RATE,
          placeholder: DamageAndLossPlaceholderUI.ANNUAL_GROWTH_RATE,
          selected: true,
          value: '',
          dataTypeUI: 'FLOAT',
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
          tooltip: 'Assumed year-on-year percentage change, from 0-1, in total asset value',
          validate: validateFloatBetweenZeroToOne,
        },
        {
          id: reactKey('dl.annualVolatilityRate'),
          name: DamageAndLossLabelUI.ANNUAL_VOLATILITY_RATE,
          placeholder: DamageAndLossPlaceholderUI.ANNUAL_VOLATILITY_RATE,
          selected: true,
          value: '',
          dataTypeUI: 'FLOAT',
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
          tooltip: 'Assumed standard deviation of the annual growth rate, from 0-1',
          validate: validateFloatBetweenZeroToOne,
        },
        {
          id: reactKey('dl.subIndustryCode'),
          name: DamageAndLossLabelUI.SUB_INDUSTRY_CODE,
          placeholder: DamageAndLossPlaceholderUI.SUB_INDUSTRY_CODE,
          selected: true,
          value: '',
          dataTypeUI: 'INT', // Number on UI but is sent as Numeric string in payload
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
          tooltip: '8-digit sub-industry GICS code from MSCI',
          validate: validateGICSEightDigitInteger,
        },
        {
          id: reactKey('dl.salesMargin'),
          name: DamageAndLossLabelUI.SALES_MARGIN,
          placeholder: DamageAndLossPlaceholderUI.SALES_MARGIN,
          selected: true,
          value: '',
          dataTypeUI: 'FLOAT',
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
          tooltip:
            "The ratio of a company's gross profit to its total revenues, after accounting for Cost of Goods Sold (COGS)",
          validate: validateFloatFromZeroToLessThanOne,
        },
        {
          id: reactKey('dl.discountRate'),
          name: DamageAndLossLabelUI.DISCOUNT_RATE,
          placeholder: DamageAndLossPlaceholderUI.DISCOUNT_RATE,
          selected: true,
          value: '',
          dataTypeUI: 'FLOAT',
          dataVersionRestrictions: ['3.0.0', '3.1.0'],
          tooltip:
            'The discount rate applied when considering future climate risk impact on current valuations',
          validate: validateFloatBetweenZeroToOne,
        },
      ],
    },
  },
  // ------------------------------------------ 3. Flood Mesh
  [ResultSetTypeUI.FLOOD_MESH]: {
    disabled: false,
    selected: false,
    name: RESULT_NAME_LABELS.FLOOD_MESH,
    id: `${ResultSetTypeUI.FLOOD_MESH}-panel`,
    type: ResultSetTypeUI.FLOOD_MESH,
    parameters: {
      gridSizes: [
        {
          name: GridSizeUI.REGULAR_LARGE,
          selected: false,
          id: reactKey('mesh.gridSizes'),
        },
        {
          name: GridSizeUI.DYNAMIC,
          selected: true,
          id: reactKey('mesh.gridSizes'),
        },
      ],
      scenarios: [
        {
          name: ScenarioUI.BASELINE,
          selected: true,
          id: reactKey('mesh.scenarios'),
        },
        {
          name: ScenarioUI.SSP_126,
          selected: true,
          id: reactKey('mesh.scenarios'),
        },
        {
          name: ScenarioUI.SSP_245,
          selected: true,
          id: reactKey('mesh.scenarios'),
        },
        {
          name: ScenarioUI.SSP_585,
          selected: true,
          id: reactKey('mesh.scenarios'),
        },
      ],
      years: [
        { name: '1995', selected: true, id: reactKey('mesh.years') },
        { name: '2020', selected: true, id: reactKey('mesh.years') },
        { name: '2025', selected: false, id: reactKey('mesh.years') },
        { name: '2030', selected: true, id: reactKey('mesh.years') },
        { name: '2035', selected: false, id: reactKey('mesh.years') },
        { name: '2040', selected: true, id: reactKey('mesh.years') },
        { name: '2045', selected: false, id: reactKey('mesh.years') },
        { name: '2050', selected: true, id: reactKey('mesh.years') },
        { name: '2055', selected: false, id: reactKey('mesh.years') },
        { name: '2060', selected: false, id: reactKey('mesh.years') },
        { name: '2065', selected: false, id: reactKey('mesh.years') },
        { name: '2070', selected: false, id: reactKey('mesh.years') },
        { name: '2075', selected: true, id: reactKey('mesh.years') },
        { name: '2080', selected: false, id: reactKey('mesh.years') },
        { name: '2085', selected: false, id: reactKey('mesh.years') },
        { name: '2090', selected: false, id: reactKey('mesh.years') },
        { name: '2095', selected: false, id: reactKey('mesh.years') },
        { name: '2100', selected: true, id: reactKey('mesh.years') },
      ],
      floodDefense: [
        {
          selected: true,
          name: FloodDefenseLabelUI.ENABLED,
          id: reactKey('mesh.floodDefenseFlag'),
        },
      ],
      floodDefenseOptions: [
        {
          selected: true,
          name: FloodDefenseLabelUI.ZERO_OUT_DEFENDED_LOCATIONS,
          id: reactKey('mesh.floodDefense'),
        },
      ],
    },
  },
  // ------------------------------------------ 4. Scores
  [ResultSetTypeUI.SCORES]: {
    disabled: false,
    selected: false,
    name: RESULT_NAME_LABELS.SCORES,
    id: `${ResultSetTypeUI.SCORES}-panel`,
    type: ResultSetTypeUI.SCORES,
    parameters: {
      scorePerils: [
        {
          name: ScorePerilUI.ALL_PERILS,
          selected: true,
          id: reactKey('scores.scorePerils'),
        },
        {
          name: ScorePerilUI.WIND,
          selected: true,
          id: reactKey('scores.scorePerils'),
        },
        {
          name: ScorePerilUI.COLD,
          selected: true,
          id: reactKey('scores.scorePerils'),
        },
        {
          name: ScorePerilUI.PRECIP,
          selected: true,
          id: reactKey('scores.scorePerils'),
        },
        {
          name: ScorePerilUI.HEAT,
          selected: true,
          id: reactKey('scores.scorePerils'),
        },
        {
          name: ScorePerilUI.COMBINED_FLOOD,
          selected: true,
          id: reactKey('scores.scorePerils'),
        },
        {
          name: ScorePerilUI.HAIL,
          selected: true,
          id: reactKey('scores.scorePerils'),
        },
        {
          name: ScorePerilUI.DROUGHT,
          selected: true,
          id: reactKey('scores.scorePerils'),
        },
        {
          name: ScorePerilUI.FIRE,
          selected: true,
          id: reactKey('scores.scorePerils'),
        },
      ],
      benchmarkingIncluded: [
        {
          name: BenchmarkingLabelUI.ENABLED,
          selected: true,
          id: reactKey('scores.benchmarkingIncluded'),
        },
      ],
      benchmarkingOptions: [
        {
          name: BenchmarkingLevelUI.COUNTRY,
          selected: true,
          id: reactKey('scores.benchmarkingOptions'),
        },
        {
          name: BenchmarkingLevelUI.ADMIN1,
          selected: true,
          id: reactKey('scores.benchmarkingOptions'),
        },
        {
          name: BenchmarkingLevelUI.ADMIN2,
          selected: false,
          id: reactKey('scores.benchmarkingOptions'),
        },
      ],
    },
  },
};

export const DamageAndLossOptionalUI = [
  DamageAndLossLabelUI.SUB_INDUSTRY_CODE,
  DamageAndLossLabelUI.FIRST_FLOOR_ELEVATION,
  DamageAndLossLabelUI.FLOOR_AREA_SQM,
  DamageAndLossLabelUI.ELECTRICITY_COST,
  DamageAndLossLabelUI.COOLING_SYSTEM_PROBABILITY,
  DamageAndLossLabelUI.WORK_INTENSITY,
  DamageAndLossLabelUI.REMOTE_WORK_RATIO,
  DamageAndLossLabelUI.ANNUAL_GROWTH_RATE,
  DamageAndLossLabelUI.ANNUAL_VOLATILITY_RATE,
  DamageAndLossLabelUI.SALES_MARGIN,
  DamageAndLossLabelUI.DISCOUNT_RATE,
  DamageAndLossLabelUI.WINDOW_PANE,
  DamageAndLossLabelUI.VENT_TYPE,
  DamageAndLossLabelUI.ROOF_COVER,
  DamageAndLossLabelUI.WATER_CONSUMPTION,
  DamageAndLossLabelUI.WATER_SHADOW_PRICE_RATIO,
];

// TODO: Add more param keys for more dynamic behaviour based on API
const MAP_UI_API_KEYS = {
  [ResultSetTypeUI.ECONOMIC_IMPACTS]: {
    damageAndLossValues: {
      [DamageAndLossLabelUI.OCCUPANCY_SCHEME]: `damageAndLoss.defaultFields.[${DamageAndLossField.OCCUPANCY_SCHEME}]`,
      [DamageAndLossLabelUI.OCCUPANCY_CODE]: `damageAndLoss.defaultFields.[${DamageAndLossField.OCCUPANCY_CODE}]`,
    },
  },
};

const buildPanelConfig = (key: ResultSetTypeUI): ResultSetOptionsUI => ({
  // This extracts mst of the properties from RESULT_SET_CONFIG, but it explicitly omits parameters b/c resultSetOptionsUI doesn't need those--the values will come from Redux.
  name: get(RESULT_SET_CONFIG, `[${key}].name`, ''),
  selected: get(RESULT_SET_CONFIG, `[${key}].selected`, false),
  id: get(RESULT_SET_CONFIG, `[${key}].id`, ''),
  type: get(RESULT_SET_CONFIG, `[${key}].type`, key),
  disabled: get(RESULT_SET_CONFIG, `[${key}].disabled`, false),
});

export const resultSetOptionsUI: ResultSetOptionsUI[] = [
  // This being an array means we can set the order in which Accordions appear in the Drawer
  // NOTE: This is the definitive order of result set types, which informs the display of element in the query builder and elsewhere
  {
    ...buildPanelConfig(ResultSetTypeUI.PERILS),
  },
  {
    ...buildPanelConfig(ResultSetTypeUI.ECONOMIC_IMPACTS),
  },
  {
    ...buildPanelConfig(ResultSetTypeUI.FLOOD_MESH),
  },
  {
    ...buildPanelConfig(ResultSetTypeUI.SCORES),
  },
];

export const getSystemDefaultNew = ({
  resultSetType,
  parameterPath = '',
  csgDataVersion,
  rsDefaultParams = {},
}: {
  resultSetType: ResultSetTypeUI;
  parameterPath?: string;
  csgDataVersion: string;
  rsDefaultParams?: any;
}): any => {
  const defaults = get(RESULT_SET_CONFIG, `[${resultSetType}].parameters`);
  const values: ParameterOptionsUI[] = get(defaults, parameterPath, []);
  // const filteredByVersion = values.filter((item) => {
  //   const { dataVersionRestrictions = [] } = item;

  //   if (dataVersionRestrictions.length === 0) {
  //     return true;
  //   }

  //   return dataVersionRestrictions.includes(csgDataVersion);
  // });

  const filteredByVersion = values.map((item) => {
    const { dataVersionRestrictions = [], name } = item;
    let { value, selectionList, selected: itemSelected, isFieldEnabler } = item;

    const defaultParamKey = get(
      MAP_UI_API_KEYS,
      `[${resultSetType}].[${parameterPath}].[${name}]`,
      '',
    );

    const forbidden =
      dataVersionRestrictions.length !== 0 && !dataVersionRestrictions.includes(csgDataVersion);

    const selected = isFieldEnabler ? !forbidden && itemSelected : itemSelected;

    if (!isEmpty(rsDefaultParams) && Boolean(defaultParamKey)) {
      const { choices: paramChoices, defaults: paramDefaults } = get(
        rsDefaultParams,
        defaultParamKey,
        {},
      );

      // Fill choices for selection list if it's a dropdown
      if (!isNil(paramChoices) && paramChoices.length > 0 && isArray(selectionList)) {
        selectionList = paramChoices.map((choice: string) => {
          const selectionItem = selectionList?.find((selItem) => selItem.value === `${choice}`);
          return !isNil(selectionItem)
            ? selectionItem
            : { name: choice, value: choice, id: reactKey(`${parameterPath}.${name}`) };
        });
      }

      // Fill default value of the control as a string
      if (!isNil(paramDefaults) && paramDefaults.length > 0) {
        const [defValue] = paramDefaults;
        value = `${defValue}`;
      }
    }

    // return retItem;
    return { ...item, value, selectionList, forbidden, selected };
  });

  /* -- EXAMPLE where
   * -- resultSetType=ResultSetTypeUI.SCORES
   * -- && parameterPath="benchmarkingIncluded"

   * defaults === {
                  scorePerils: [
                    {
                      name: ScorePerilUI.ALL_PERILS,
                      selected: true,
                      id: reactKey('scores.scorePerils'),
                    },
                    {
                      name: ScorePerilUI.WIND,
                      selected: true,
                      id: reactKey('scores.scorePerils'),
                    },
                    {
                      name: ScorePerilUI.COLD,
                      selected: true,
                      id: reactKey('scores.scorePerils'),
                    },
                    {
                      name: ScorePerilUI.PRECIP,
                      selected: true,
                      id: reactKey('scores.scorePerils'),
                    },
                    {
                      name: ScorePerilUI.HEAT,
                      selected: true,
                      id: reactKey('scores.scorePerils'),
                    },
                    {
                      name: ScorePerilUI.COMBINED_FLOOD,
                      selected: true,
                      id: reactKey('scores.scorePerils'),
                    },
                    {
                      name: ScorePerilUI.HAIL,
                      selected: true,
                      id: reactKey('scores.scorePerils'),
                    },
                    {
                      name: ScorePerilUI.DROUGHT,
                      selected: true,
                      id: reactKey('scores.scorePerils'),
                    },
                    {
                      name: ScorePerilUI.FIRE,
                      selected: true,
                      id: reactKey('scores.scorePerils'),
                    },
                  ],
                  benchmarkingIncluded: [
                    {
                      name: BenchmarkingLabelUI.ENABLED,
                      selected: true,
                      id: reactKey('scores.benchmarkingIncluded'),
                    },
                  ],
                  benchmarkingOptions: [
                    {
                      name: BenchmarkingLevelUI.COUNTRY,
                      selected: true,
                      id: reactKey('scores.benchmarkingOptions'),
                    },
                    {
                      name: BenchmarkingLevelUI.ADMIN1,
                      selected: true,
                      id: reactKey('scores.benchmarkingOptions'),
                    },
                    {
                      name: BenchmarkingLevelUI.ADMIN2,
                      selected: false,
                      id: reactKey('scores.benchmarkingOptions'),
                    },
                  ],
                },
              },

     * values === [
              {
                name: BenchmarkingLabelUI.ENABLED,
                selected: true,
                id: reactKey('scores.benchmarkingIncluded'),
              },
            ]

  */

  return filteredByVersion;
};

// Only get fields allowed for selected data version
export const getSystemDefaultFieldNames = ({
  resultSetType,
  parameterPath = '',
  csgDataVersion = '',
}: {
  resultSetType: ResultSetTypeUI;
  parameterPath?: string;
  csgDataVersion?: string;
}): string[] => {
  if (!csgDataVersion) return [];

  const defaults = get(RESULT_SET_CONFIG, `[${resultSetType}].parameters`);
  const values: ParameterOptionsUI[] = get(defaults, parameterPath, []);
  const filteredByVersion = values.filter((item) => {
    const { dataVersionRestrictions = [] } = item;

    if (dataVersionRestrictions.length === 0) {
      return true;
    }

    return dataVersionRestrictions.includes(csgDataVersion);
  });

  return filteredByVersion.map((item) => item.name);
};
