import { FC } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { CardInfoProps } from './types';
import { Title as InfoTextTitle, ValueBold as InfoTextValue } from '../InfoText';
import {
  CARD_BODY_DELIMITER,
  CARD_BODY_DELIMITER_COLORED,
  TITLE_TEXT_COLOR,
  VALUE_TEXT_COLOR,
} from '../../utils/styleUtils';

const CardInfo: FC<CardInfoProps> = ({ infoItems = [], selected = false, sx = {} }) => {
  return (
    <Stack
      useFlexGap
      direction="row"
      flexWrap="wrap"
      spacing={2}
      divider={
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            borderRight: selected ? CARD_BODY_DELIMITER_COLORED : CARD_BODY_DELIMITER,
          }}
        />
      }
    >
      {infoItems.map((item) => (
        <Box key={`item-${item.id}`}>
          <InfoTextTitle variant="h6" sx={{ color: TITLE_TEXT_COLOR }} noWrap>
            {item.title}:{' '}
          </InfoTextTitle>
          <InfoTextValue variant="h6" sx={{ color: VALUE_TEXT_COLOR }} noWrap>
            {item.value}
          </InfoTextValue>
        </Box>
      ))}
    </Stack>
  );
};

export default CardInfo;
