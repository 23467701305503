import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { InfoTextBlock, InfoTextSection } from '../../components/InfoText';
import {
  CARD_BORDER_RADIUS,
  SIDE_PANEL_BG_COLOR,
  StyleProps,
  TILE_BORDER,
} from '../../utils/styleUtils';
import { StatusChip } from './StatusStoplight';
import { StyledMediumLabel } from '../../components/labels/StyledLabel';
import Tile, { TileContent } from '../../components/tile/Tile';
import SLRPanelTrigger from '../../components/SLRPanelTrigger';
import { useAppSelector } from '../../redux/hooks';
import { checkSLRAvailabilityAndStatus } from '../../redux/selectors/csgDataSelectors';

interface CardConfig {
  title: string;
  value: string;
  id: string;
}

interface MetadataCardProps {
  status?: string;
  sx?: StyleProps;
  tileList: TileContent[];
  dataList: Array<{
    title: string;
    cards: CardConfig[];
    id: string;
  }>;
}

const MetadataCard: FC<MetadataCardProps> = ({ sx = {}, dataList, tileList, status = '' }) => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { isSLRAvailable, isSLREnabled, strTooltip } = useAppSelector(
    checkSLRAvailabilityAndStatus,
  );
  return (
    <Box
      sx={{
        ...sx,
        // [make summary card fill the whole iterator] pt. 2
        height: '100%',
        width: '100%',
        overflow: 'auto',
        // ------------------
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <StyledMediumLabel
              data-testid="lbl-result-set-summary"
              variant="h5"
              sx={{
                padding: {
                  xs: `${theme.spacing(1.25)} 0`,
                  sm: `${theme.spacing(1.25)} 0`,
                  md: `${theme.spacing(2)} ${theme.spacing(1.25)}`,
                },
                color: SIDE_PANEL_BG_COLOR,
                ...(smScreen ? { fontSize: theme.spacing(2.5) } : {}),
              }}
            >
              Summary
            </StyledMediumLabel>
            {status.length > 0 && <StatusChip status={status} />}
          </Box>
          {isSLRAvailable && <SLRPanelTrigger disabled={!isSLREnabled} tooltip={strTooltip} />}
        </Box>

        <Grid container spacing={1.5}>
          {tileList.map(({ title, subtitle, actionButton, id }) => (
            <Grid item xs key={id}>
              <Tile
                title={title}
                subtitle={subtitle}
                actionButton={actionButton}
                sx={{ height: '100%' }}
              />
            </Grid>
          ))}
        </Grid>

        <Stack spacing={1.5} sx={{ width: '100%', marginTop: 1.5, marginBottom: 1.5 }}>
          {dataList.map(({ title, cards, id }) => {
            const cardsExist = cards.length > 0;
            return (
              <Box
                key={id}
                sx={{
                  width: '100%',
                  paddingTop: 0.75,
                  paddingBottom: 0.75,
                  paddingLeft: 1.5,
                  paddingRight: 1.5,
                  border: TILE_BORDER,
                  borderRadius: CARD_BORDER_RADIUS,
                }}
                data-testid={`${id}-card`}
              >
                <Typography
                  data-testid={id}
                  variant="h6"
                  sx={{ paddingBottom: cardsExist ? 0.25 : 0 }}
                >
                  {title}
                </Typography>
                {cardsExist && (
                  <Box
                    sx={{
                      paddingLeft: { xs: 1, sm: 1, md: 2 },
                      paddingBottom: 0.5,
                      width: '100%',
                    }}
                  >
                    <InfoTextBlock textWrap infoList={cards} justifyOn={InfoTextSection.VALUE} />
                  </Box>
                )}
              </Box>
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
};
export default MetadataCard;
