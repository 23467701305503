import { Typography } from '@mui/material';
import { isNil, lowerCase } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { ResultSet, ResultSetStatus } from '../../api/resultSets/types';
import withErrorBoundary from '../../components/ErrorBoundary/withErrorBoundary';
import { LabelWeight } from '../../components/buttons/DropdownHandle';
import TextDropdown from '../../components/dropdowns/TextDropdown';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectFilteredResultSets,
  selectNumberOfPortfolios,
  selectSelectedResultSet,
} from '../../redux/selectors/csgDataSelectors';
import { selectResultSet } from '../../redux/slices/csgDataSlice';
import { NAV_SEPARATOR_COLOR } from '../../utils/styleUtils';
import BreadcrumbDropdownMenu, {
  ButtonRefEl,
  ClickAnchorHandler,
  ClickButtonHandler,
  ElementId,
} from './BreadcrumbDropdownMenu';

// IDEA: Use Iterator as the container here, similar to PortfolioStripe

interface EnhancedRs extends ResultSet {
  selected: boolean;
  completed: boolean;
  title: string;
}
const ResultSetsNavigation: FC = () => {
  const dispatch = useAppDispatch();
  const countPortfolios = useAppSelector(selectNumberOfPortfolios);
  const resultSets = useAppSelector(selectFilteredResultSets);
  const selectedResultSet = useAppSelector(selectSelectedResultSet);
  const [searchString, setSearchString] = useState<string>('');

  const [anchorEl, setAnchorEl] = useState<ButtonRefEl>(null);

  const isMenuOpen = Boolean(anchorEl);
  const elementId: ElementId = isMenuOpen ? 'nav-resultSet-menu' : undefined;

  const onClickMenu: ClickAnchorHandler = (element) => {
    if (!isNil(element)) {
      setAnchorEl(element);
    }
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    setSearchString('');
  };

  const onCloseMenu: ClickButtonHandler = (event) => {
    event.stopPropagation();
    handleClose();
  };

  const enhancedRs: EnhancedRs[] = useMemo(() => {
    return resultSets.map((item) => {
      return {
        ...item,
        selected: !!selectedResultSet && item.id === selectedResultSet.id,
        completed: item.status === ResultSetStatus.COMPLETED,
        title: item.name,
      };
    });
  }, [resultSets, selectedResultSet]);

  const filteredResultSets: EnhancedRs[] = useMemo(() => {
    if ((searchString ?? '').trim().length > 0) {
      return enhancedRs.filter((item) => lowerCase(item.name).includes(lowerCase(searchString)));
    }
    return enhancedRs;
  }, [searchString, enhancedRs]);

  const handleSelectResultSet = (item: EnhancedRs): void => {
    if (item.completed) {
      dispatch(selectResultSet(item));
      // close the nav menu when a result set is selected--because there are no further selections beneath
      // closeNav();
      handleClose();

      return;
    }
    // eslint-disable-next-line no-console
    console.log('Incomplete result set:', item);
  };

  const breadcrumbListItems = useMemo(() => {
    return filteredResultSets.map((item) => {
      const { id } = item;

      return {
        ...item,
        onClick: () => {
          handleSelectResultSet(item);
        },
        id: `rs-nav-item-${id}`,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredResultSets]);

  return (
    <>
      <Typography variant="h6" sx={{ paddingRight: 0.5 }} color={NAV_SEPARATOR_COLOR}>
        /
      </Typography>
      <TextDropdown
        data-testid="result-set-breadcrumb-button"
        label={selectedResultSet?.name ?? 'Select Result Set'}
        isMenuOpen={isMenuOpen}
        menu={
          <BreadcrumbDropdownMenu
            elementId={elementId}
            isMenuOpen={isMenuOpen}
            anchorEl={anchorEl}
            onCloseMenu={onCloseMenu}
            menuDataTestId="nav-resultSet-menu"
            listItems={breadcrumbListItems}
            listDataTestId="filtered-resultSets-list"
            emptyMenuItemText="No result sets available"
            withSearch
            searchString={searchString}
            setSearchString={setSearchString}
            searchInputDataTestId="resultSet-nav-search"
          />
        }
        onClickMenu={onClickMenu}
        iconButtonProps={{
          disabled: countPortfolios === 0,
        }}
        labelProps={{
          labelWeight: LabelWeight.NORMAL,
        }}
      />
    </>
  );
};

export default withErrorBoundary(ResultSetsNavigation);
