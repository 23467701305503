import { AppBar } from '@mui/material';
import { FC } from 'react';
import { DARK_BOX_STYLE } from '../../../utils/styleUtils';
import { ApplicationBarProps } from './types';

const ApplicationBar: FC<ApplicationBarProps> = ({ children }) => {
  return (
    <AppBar data-testid="top-bar-component" elevation={0} position="fixed" sx={DARK_BOX_STYLE}>
      {children}
    </AppBar>
  );
};

export default ApplicationBar;
