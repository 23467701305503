import { useEffect, useRef } from 'react';
// TODO: Look into WebTransport as a replacement for Interval long-polling
// --> https://web.dev/webtransport/

type Callback = () => {} | Promise<any>;

// NOTE: Alternative for async functions: See `rsSubscribe` example in ResultSetsStripe.tsx

// Note: Hooks are only usable in functional components, not in class components
const useInterval = (callback: Callback, delay: number | null, proceed = true): void => {
  const savedCallback = useRef<Callback | null>(null);

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;

    return () => {
      savedCallback.current = null;
    };
  }, [callback]);

  // Set up the interval
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const tick = (): void => {
      if (savedCallback.current) {
        // eslint-disable-next-line no-console
        console.log('tick', { callback, delay, proceed });

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        savedCallback.current();
      }
    };

    if (delay !== null) {
      const id = setInterval(tick, delay);

      if (!proceed) {
        // eslint-disable-next-line no-console
        // console.log(`useInterval: proceed was ${JSON.stringify(proceed)}. Clearing interval...`);
        clearInterval(id);
        return;
      }

      // eslint-disable-next-line consistent-return
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay, proceed]);
};

// const delay = async (ms: number): Promise<void> => {
//   // return await for better async stack trace support in case of errors.
//   return await new Promise((resolve) => setTimeout(resolve, ms));
// };

/* IDEA:
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { clearIntervalAsync, setIntervalAsync } = require('set-interval-async');

export const useIntervalAsync = (
  callback: Callback,
  delay: number | null,
  proceed = true,
): void => {
  console.log('hello it is useIntervalAsync!');
  const savedAsyncCallback = useRef<Callback | null>(null);

  // Remember the latest callback
  useEffect(() => {
    savedAsyncCallback.current = callback;
  }, [callback]);

  // Set up the interval
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const ticker = async (): Promise<void> => {
      console.log('ticker go!');

      if (delay !== null) {
        const id = setIntervalAsync(async () => {
          if (savedAsyncCallback.current) {
            // eslint-disable-next-line no-console
            console.log('tick', { callback, delay, proceed });

            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            await savedAsyncCallback.current();
          }

          if (!proceed) {
            await (async () => {
              console.log('Stopping useIntervalAsync (1)');
              await clearIntervalAsync(id);
              console.log('Stopped (1)');
            })();
          }
        }, delay);

        if (!proceed) {
          // eslint-disable-next-line no-console
          console.log(`useInterval: proceed was ${JSON.stringify(proceed)}. Clearing interval...`);
          await clearIntervalAsync(id);
          return;
        }

        // eslint-disable-next-line consistent-return
        return await (async () => {
          console.log('Stopping useIntervalAsync (2)');
          await clearIntervalAsync(id);
          console.log('Stopped (2)');
        })();
      }

      void ticker();
    };
  }, [callback, delay, proceed]);
};

*/

export const delayInt = (seconds: number): number => seconds * 1000;

export default useInterval;
