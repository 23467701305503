export const DATA_DOG_RUM_CONFIG = {
  applicationId: 'f391e8b4-6cc0-43fd-8838-b099e5575501',
  clientToken: 'pubbdbe74bc365540755d3db0cb219677fa',
  site: 'datadoghq.com',
  service: 'passport-3-frontend',
  env: window._env_.REACT_APP_DATA_DOG_ENV,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100, // if not included, the default is 100
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  allowedTracingUrls: [
    (url: string) =>
      [
        'https://csg.jupiterintel.com/',
        'http://csg.jupiterintel.com/',
        'https://csg.dev.ioinfra.net/',
        'http://csg.dev.ioinfra.net/',
        'https://csg.stg.ioinfra.net/',
        'http://csg.stg.ioinfra.net/',
      ].some((u) => url.startsWith(u)),
  ],
  traceSampleRate: 100,
};

export default DATA_DOG_RUM_CONFIG;
