import { Alert, AlertTitle, Grid } from '@mui/material';
import { FC, useMemo } from 'react';
import MaterialTable from '../../../components/table/MaterialTable';
import { reactKey } from '../../../utils/reactUtils';
import { ValidationMessage, ValidationResults } from '../types';
import { getAlertSeverity, humanReadableNumber, toFloatOr, toIntOr } from './util';

const hasRowLevelContext = (item: ValidationMessage): boolean => {
  try {
    const hasContext = item.location.trim().length > 0;
    return hasContext;
  } catch {}

  return false;
};

interface ExceptionContextObj {
  rowNumber: number | string;
  locationId: number | string;
  latitude: number | string;
  longitude: number | string;
  code: string;
  message: string;
  id: string;
}

const extractRowNumber = ({ location }: { location: string }): string | number => {
  const FIND_ROW_IDX_REGEX = /row\[([0-9]+)\]/;
  /* NOTE: Test case:
      "row[13][buildingValue]".match(FIND_ROW_IDX_REGEX) =
      [
        'row[13]',
        '13', <------- This is what we're looking for; `rowIndex`
        index: 0,
        input: 'row[13][buildingValue]',
        groups: undefined
      ]
*/

  try {
    const m = location.match(FIND_ROW_IDX_REGEX);
    if (!m) {
      return '';
    }

    const rowIndex = m[1];

    // NOTE: `rowIndex` is a value from 0 to Infinity. Since, in the user's CSV file, the rows of their spreadsheet start at 1 and not 0, we should add 1 to the rowIndex to get the rowNumber
    const rowNumber = toIntOr(rowIndex, null);

    if (rowNumber !== null) {
      // if rowIndex is an integer, add 1 and return
      return Number(rowNumber) + 1;
    }
    return 'N/A';
  } catch {}

  return 'N/A';
};

const applyErrorContext = ({
  code,
  description,
  location,
  locationId,
  longitude,
  latitude,
}: ValidationMessage): ExceptionContextObj => {
  const rowNumber = extractRowNumber({ location });

  return {
    rowNumber,
    locationId: toIntOr(locationId, 'N/A'),
    latitude: toFloatOr(latitude, 'N/A'),
    longitude: toFloatOr(longitude, 'N/A'),
    code: code ?? '',
    message: description ?? '',
    id: reactKey('file-validation'),
  };
};

interface ErrorLogProps {
  criticalError: string | null;
  validationResults: ValidationResults | null;
  validationFailed: boolean;
}

const GROUPED_BY_EXCEPTION_TYPE_EMPTY: GroupedByExceptionType = {
  warnings: [],
  errors: [],
  info: [],
  other: [],
};

interface GroupedByExceptionType {
  warnings: ExceptionContextObj[];
  errors: ExceptionContextObj[];
  info: ExceptionContextObj[];
  other: ExceptionContextObj[];
}

const groupByExceptionType = (
  exceptionsByType: GroupedByExceptionType,
  exception: ExceptionContextObj,
): GroupedByExceptionType => {
  const { code } = exception;

  const identifyingLetter = code[0].toUpperCase();

  // NOTE: TEMP This will be improved in future versions (see: SAAS-1261)
  switch (identifyingLetter) {
    case 'W':
      return { ...exceptionsByType, warnings: [...exceptionsByType.warnings, exception] };
    case 'E':
      return { ...exceptionsByType, errors: [...exceptionsByType.errors, exception] };
    case 'I':
      return { ...exceptionsByType, info: [...exceptionsByType.info, exception] };
    default:
      return { ...exceptionsByType, other: [...exceptionsByType.other, exception] };
  }
};

const ErrorLog: FC<ErrorLogProps> = ({ criticalError, validationResults, validationFailed }) => {
  const numLocations = toIntOr(validationResults?.extra?.totalCount, 0);

  const numRejected = toIntOr(validationResults?.extra?.failedCount, 0);

  const messages: ValidationMessage[] = validationResults?.extra?.errors ?? [];

  const rowLevelExceptions: GroupedByExceptionType = messages
    .filter(hasRowLevelContext)
    .map(applyErrorContext)
    .reduce(groupByExceptionType, GROUPED_BY_EXCEPTION_TYPE_EMPTY);

  const rowLevelErrors: ExceptionContextObj[] = rowLevelExceptions.errors;

  const rowLevelWarnings: ExceptionContextObj[] = rowLevelExceptions.warnings;

  const rowLevelIssues: ExceptionContextObj[] = rowLevelErrors.concat(rowLevelWarnings);

  const otherExceptions: ExceptionContextObj[] = messages
    .filter((item) => !hasRowLevelContext(item))
    .map(applyErrorContext);

  const numAccepted = validationResults?.extra?.succeededCount ?? 0;

  const containerMaxWidth = useMemo(
    () =>
      rowLevelIssues.length > 0
        ? {
            xs: '100%',
            sm: '100%', // allow it to take more space when showing the table
            md: '80vw',
            lg: '60vw',
            xl: '60vw',
          }
        : {
            xs: '100%',
            sm: '80vw',
            md: '80vw',
            lg: '40vw',
            xl: '40vw',
          },
    [rowLevelIssues],
  );

  return (
    <Grid container direction="column" spacing={1} sx={{ maxWidth: containerMaxWidth }}>
      {validationFailed && (
        <Grid item xs={12}>
          <Alert severity="error">Validation Failed</Alert>
        </Grid>
      )}

      {!validationFailed && (
        <Grid item xs={12}>
          <Alert severity="success">
            <strong>{humanReadableNumber(numAccepted)}</strong> of{' '}
            {humanReadableNumber(numLocations)} locations were accepted
          </Alert>
        </Grid>
      )}

      {otherExceptions.length > 0 && (
        <>
          {otherExceptions.map(({ code, message, id }) => {
            const severity = getAlertSeverity(code);

            return (
              <Grid item xs={12} key={id}>
                <Alert severity={severity}>
                  {code.length > 0 && <AlertTitle>{code}</AlertTitle>}
                  {message}
                </Alert>
              </Grid>
            );
          })}
        </>
      )}

      {rowLevelIssues.length > 0 && (
        <Grid item xs={12}>
          <Alert severity="warning">{rowLevelIssues.length} Row-Level Issues Detected</Alert>
        </Grid>
      )}

      {rowLevelIssues.length > 0 && (
        <Grid item xs={12}>
          <MaterialTable
            title="Row-Level Issues"
            data={rowLevelIssues}
            columns={[
              {
                field: 'rowNumber',
                title: 'Row',
              },
              // NOTE: Hide `locationId`, `latitude`, and `longitude` for CSG Web App Release 2
              // {
              //   field: 'locationId',
              //   title: 'Location ID',
              // },
              // {
              //   field: 'latitude',
              //   title: 'Latitude',
              // },
              // {
              //   field: 'longitude',
              //   title: 'Longitude',
              // },
              {
                field: 'code',
                title: 'Issue',
              },
              {
                field: 'message',
                title: 'Message',
              },
            ]}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ErrorLog;
