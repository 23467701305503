import { FC } from 'react';
import Drawer from '../../components/Drawer';
import withErrorBoundary from '../../components/ErrorBoundary/withErrorBoundary';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectUploadDrawer,
  selectUploadMode,
  selectValidationActive,
} from '../../redux/selectors/uploadSelectors';
import { setUploadDrawer } from '../../redux/slices/uiSlice';
import { resetUploadMode } from '../../redux/slices/uploadSlice';
import CoordinateComposition from '../upload/CoordinateComposition';
import UploadFile from '../upload/UploadFile';
import { UploadModes } from '../upload/types';
import Validation from '../upload/validation/Validation';

const UploadDrawerController: FC = () => {
  const dispatch = useAppDispatch();
  const setNewDrawerState = (newState: boolean): void => {
    dispatch(setUploadDrawer(newState));
  };

  const handleDrawerClose = (): void => {
    dispatch(setUploadDrawer(false));
    dispatch(resetUploadMode());
  };
  const uploadMode = useAppSelector(selectUploadMode);
  const validationActive = useAppSelector(selectValidationActive);
  const drawerOpen = useAppSelector(selectUploadDrawer);

  return (
    <Drawer drawerOpen={drawerOpen} setDrawer={setNewDrawerState}>
      {!validationActive && uploadMode === UploadModes.CSV_FILE && (
        <UploadFile closeDrawer={handleDrawerClose} />
      )}
      {!validationActive && uploadMode === UploadModes.COORDINATE_COMPOSITION && (
        <CoordinateComposition closeDrawer={handleDrawerClose} />
      )}
      {validationActive && <Validation />}
    </Drawer>
  );
};

export default withErrorBoundary(UploadDrawerController);
