import { CategoryOptionNullishType } from '../../pages/upload/CategorySelector/types';
import {
  CSVContent,
  LocationData,
  UploadModes,
  ValidationResults,
  ValidationStatusUI,
} from '../../pages/upload/types';
import { RootState } from '../store';

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectUploadDrawer = (state: RootState): boolean =>
  state.ui?.uploadDrawerOpen ?? false;

export const selectLocationData = (state: RootState): LocationData[] =>
  state.upload?.coordinatesList ?? [];

export const selectValidationStatus = (state: RootState): ValidationStatusUI =>
  state.upload?.validation?.status ?? ValidationStatusUI.IDLE;

export const selectValidationActive = (state: RootState): boolean =>
  state.upload.validation?.active ?? false;

export const selectUploadRequestForValidation = (state: RootState): ValidationResults | null =>
  state.upload?.validation?.results ?? null;

export const selectNumCoords = (state: RootState): number =>
  state.upload?.coordinatesList?.length ?? 0;

export const selectCSVContent = (state: RootState): CSVContent | null =>
  state.upload?.csvContent ?? null;

export const selectUploadMode = (state: RootState): UploadModes | null =>
  state.upload?.uploadMode ?? null;

export const selectCoordinatesList = (state: RootState): LocationData[] =>
  state.upload.coordinatesList;

export const selectUploadCategory = (state: RootState): CategoryOptionNullishType =>
  state.upload.category;
