import { Box, Grid } from '@mui/material';
import { FC } from 'react';
import ContextMenu from '../../../components/ContextMenu';
import MockDataTag, { MOCK_DATA_TAG_WIDTH } from '../../../components/MockDataTag';
import RenameField from '../../../components/RenameField';
import { DataStatus } from '../../../redux/slices/csgDataSlice';
import { CenteredColumnStyle, ColumnStyle, px } from '../../../utils/styleUtils';
import Title from '../ResultSetCard/Title';
import {
  WRAPPER_0_SX,
  WRAPPER_0_SX_SELECTED,
  WRAPPER_1_SX,
  WRAPPER_1_SX_SELECTED,
  WRAPPER_2_SX,
  WRAPPER_3_SX,
  WRAPPER_4_SX,
  WRAPPER_5_SX,
} from '../ResultSetCard/util';
import CategorySelectorContainer from './CategorySelectorContainer';
import { PortfolioCardProps } from './types';
import MidDot from '../../../components/MidDot';
import CardInfo from '../../../components/card/CardInfo';
import { getPortfolioBgImgSx, getPortfolioImgSrc } from './util';

const PortfolioCard: FC<PortfolioCardProps> = ({
  sx = {},
  sxSupplement = {},
  title,
  selected,
  cardContent,
  handleInfoClick,
  handleSelect,
  isMockData,
  // CategorySelector
  isEditingCategory,
  onCancelCategoryEdit,
  onCategoryRemoved,
  onCategoryApplied,
  portfolio,
  // Edit props
  editNameState,
  onRenameConfirmed,
  setEditState,
  // Context menu
  contextMenuOptions,
}) => {
  return (
    <Box
      data-testid="portfolio-card"
      onClick={handleSelect}
      sx={{
        ...sx,
        ...sxSupplement,
        ...WRAPPER_0_SX,
        ...(selected ? WRAPPER_0_SX_SELECTED : {}),
        position: 'relative',
      }}
    >
      <Box
        sx={{
          ...WRAPPER_1_SX,
          ...(selected ? WRAPPER_1_SX_SELECTED : {}),
          background: '#fff',
          position: 'relative',
        }}
      >
        <img
          alt="portfolio-cover"
          style={getPortfolioBgImgSx(selected, isMockData)}
          src={getPortfolioImgSrc(selected, isMockData)}
        />
        {/** ************** TITLE - Start ****************/}
        <Box
          sx={{
            ...WRAPPER_4_SX,
            ...(editNameState.open
              ? {
                  paddingLeft: 0,
                  paddingRight: 0,
                }
              : {}),
          }}
        >
          {editNameState.open ? (
            <RenameField
              loading={editNameState.status === DataStatus.LOADING}
              error={editNameState.error}
              currentName={title}
              submitChanges={onRenameConfirmed}
              inputLabel=""
              cancelChanges={() => {
                setEditState({
                  open: false,
                  name: '',
                  status: DataStatus.IDLE,
                  error: null,
                });
              }}
            />
          ) : (
            <Grid container>
              <Grid
                item
                sx={{
                  width: 'calc(100% - 1rem)',
                  ...ColumnStyle,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Title title={title} sx={{ maxWidth: `calc(100% - ${px(MOCK_DATA_TAG_WIDTH)})` }} />
                {isMockData && <MockDataTag />}
              </Grid>
              <Grid
                item
                sx={{
                  width: '1rem',
                  ...CenteredColumnStyle,
                }}
              >
                <ContextMenu
                  menuOptions={contextMenuOptions}
                  menuId="individual-portfolio-context-menu"
                  iconSx={{ fontSize: '1.75rem' }}
                  origin="portfolio"
                />
              </Grid>
            </Grid>
          )}
        </Box>
        {/** ************** TITLE - End ****************/}
        {/** ************** BODY - Start ****************/}
        <Box sx={WRAPPER_2_SX}>
          <Box sx={WRAPPER_3_SX}>
            <Box sx={{ ...WRAPPER_5_SX, width: '100%' }}>
              <CardInfo infoItems={cardContent} selected={selected} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: selected ? '#023D5E' : '#C9C9C9',
              }}
            >
              <MidDot />
              <CategorySelectorContainer
                onCategoryRemoved={onCategoryRemoved}
                onCategoryApplied={onCategoryApplied}
                onCancel={onCancelCategoryEdit}
                isEditingCategory={isEditingCategory}
                portfolio={portfolio}
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </Box>
          </Box>

          {/* NOTE: Hiding the Info Icon for release 1 - Start */}
          {/* <Box>
              <InfoIcon
                onClick={handleInfoClick}
                sx={{ cursor: 'pointer', height: '1rem', width: '1rem' }}
                />
            </Box> */}
          {/* NOTE: Hiding the Info Icon for release 1 - End */}
        </Box>
        {/** ************** BODY - End ****************/}
      </Box>
    </Box>
  );
};
export default PortfolioCard;
