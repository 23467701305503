import { isEmpty } from 'lodash';
import { Category, Portfolio } from '../../api/portfolios/types';
import {
  BenchmarkingLevel,
  ResultSet,
  ResultSetStatus,
  ResultSetType,
  Scenario,
} from '../../api/resultSets/types';
import {
  DataStatus,
  DownloadsById,
  ResultSetCountByPortfolioId,
  UsageStats,
  VersionOption,
  buildResultSetCountByPortfolioId,
} from '../slices/csgDataSlice';
import { RootState } from '../store';
import {
  crarCompatibilityForDamageAndLossRs,
  crarCompatibilityForScoresRsWithLossRs,
} from '../../utils/crarUtils';
import { extractLocationCount } from '../slices/utils';
import { CSG_VERSION, EIModules } from '../../pages/query-builder/types';
import { SLRScenarioType, SLRTemplateType } from '../../rest/slr/types';
import { SLRTemplateProps } from '../../pages/slr-drawer/types';
import { slrScenarioOptions, slrTemplateOptions } from '../../pages/slr-drawer/constants';
import { RadioOption } from '../../components/radio/types';

export const selectDataVersions = (state: RootState): VersionOption[] =>
  state.csgData?.csgDataVersions ?? [];

export const selectDataVersion = (state: RootState): VersionOption | null => {
  const versions = state.csgData?.csgDataVersions ?? [];
  return versions.find((v) => v.selected) ?? null;
};

export const selectDataVersionTitle = (state: RootState): string | null => {
  const versions = state.csgData?.csgDataVersions ?? [];
  return versions.find((v) => v.selected)?.title ?? null;
};

export const selectAvailableDataVersions = (state: RootState): string[] => {
  const versions = state.csgData?.csgDataVersions ?? [];

  if (versions.length > 0) {
    return versions.map(({ title }) => title);
  }

  return [];
};
export const selectDataVersionStatus = (state: RootState): DataStatus =>
  state.csgData?.csgDataVersionStatus ?? DataStatus.IDLE;

export const selectEIVersions = (state: RootState): VersionOption[] =>
  state.csgData?.eiVersions ?? [];

export const selectEIVersion = (state: RootState): VersionOption | null => {
  const versions = state.csgData?.eiVersions ?? [];
  return versions.find((v) => v.selected) ?? null;
};

export const selectEIVersionTitle = (state: RootState): string | null => {
  const versions = state.csgData?.eiVersions ?? [];
  return versions.find((v) => v.selected)?.title ?? null;
};

export const selectAvailableEIVersions = (state: RootState): string[] => {
  const versions = state.csgData?.eiVersions ?? [];

  if (versions.length > 0) {
    return versions.map(({ title }) => title);
  }

  return [];
};

export const selectEIVersionStatus = (state: RootState): DataStatus =>
  state.csgData?.eiVersionStatus ?? DataStatus.IDLE;

export const selectNumberOfPortfolios = (state: RootState): number =>
  state.csgData?.portfolios?.length ?? 0;

export const selectCategories = (state: RootState): Category[] => state.csgData?.categories ?? [];

export const selectPortfolios = (state: RootState): Portfolio[] => state.csgData?.portfolios ?? [];

export const selectSelectedPortfolio = (state: RootState): Portfolio | null =>
  state.csgData?.selectedPortfolio ?? null;

export const selectResultSets = (state: RootState): ResultSet[] => state.csgData?.resultSets ?? [];

export const selectDeletedResultSetIDs = (state: RootState): string[] =>
  state.csgData?.deletedResultSetIDs ?? [];

const matchesSelectedPortfolioId = (
  item: ResultSet,
  selectedPortfolio: Portfolio | null,
): boolean => item.portfolioId === selectedPortfolio?.id;

export const selectFilteredResultSets = (state: RootState): ResultSet[] => {
  const { selectedPortfolio } = state.csgData;

  // The result sets will be filtered based on the selected portfolio
  return state.csgData.resultSets.filter((item) =>
    matchesSelectedPortfolioId(item, selectedPortfolio),
  );
  // CAUTION: Using a higher order array method like `filter` in a selector means it will return new results every time it is called. This can potentially introduce performance issues if not used sparingly.
};

export const selectScoresResultSetsInSelectedPortfolio = (state: RootState): ResultSet[] => {
  const { selectedPortfolio } = state.csgData;

  // NOTE: Borrows from `selectFilteredResultSets`

  return state.csgData.resultSets.filter(
    (item) =>
      matchesSelectedPortfolioId(item, selectedPortfolio) &&
      item.type === ResultSetType.SCORES &&
      item.status === ResultSetStatus.COMPLETED,
  );
};

const filterResultsetsByScenario = (rsList: ResultSet[], filterScenario: Scenario): ResultSet[] => {
  return rsList.filter((rs) => {
    const {
      parameters: { scenarios },
    } = rs;

    return scenarios?.length && scenarios.includes(filterScenario);
  });
};

const selectResultSetsInSelectedPortfolioByVersionAndType = (
  state: RootState,
  resultSetType: ResultSetType,
  dataVersion: string,
  eiModule?: EIModules,
): ResultSet[] => {
  const { selectedPortfolio, resultSets } = state.csgData;

  // NOTE: Borrows from `selectFilteredResultSets`

  return resultSets.filter(
    (item) =>
      matchesSelectedPortfolioId(item, selectedPortfolio) &&
      item.type === resultSetType &&
      item.status === ResultSetStatus.COMPLETED &&
      item.parameters.csgVersion === dataVersion &&
      (!eiModule || !item.parameters.eiModules || item.parameters.eiModules.includes(eiModule)),
  );
};

export const checkDataVersionForSLR = (dataVersion: string): boolean => {
  const slrAvailableVersions: string[] = [CSG_VERSION.v3_0_0, CSG_VERSION.v3_1_0];
  return slrAvailableVersions.includes(dataVersion);
};

export const checkSLRAvailabilityAndStatus = (
  state: RootState,
): { isSLRAvailable: boolean; isSLREnabled: boolean; strTooltip: string } => {
  const { selectedResultSet } = state.csgData;

  if (!selectedResultSet) {
    return { isSLRAvailable: false, isSLREnabled: false, strTooltip: '' };
  }

  const {
    type: rsType,
    status,
    parameters: { csgVersion, scenarios },
  } = selectedResultSet;

  const isSLRAvailable = [
    ResultSetType.PERILS,
    ResultSetType.ECONOMIC_IMPACTS,
    ResultSetType.SCORES,
  ].includes(rsType);

  let isSLREnabled = isSLRAvailable;
  let strTooltip = '';

  // Check enable-disable of SLR only if it's available
  if (isSLRAvailable) {
    if (!checkDataVersionForSLR(csgVersion)) {
      isSLREnabled = false;
      strTooltip =
        'Download of Single-Location Report is only available for data-version 3.0.0 and above.';
    } else if (status !== ResultSetStatus.COMPLETED) {
      isSLREnabled = false;
      strTooltip = 'Result-set query is still in-progress';
    } else if (rsType === ResultSetType.PERILS) {
      if (!scenarios?.includes(Scenario.SSP_585) && !scenarios?.includes(Scenario.SSP_245)) {
        isSLREnabled = false;
        strTooltip =
          'To enable Single-Location Report downloads, please run a "Peril Metrics Only" query with either scenario SSP2, SSP5 or both';
      } else if (
        isEmpty(
          selectResultSetsInSelectedPortfolioByVersionAndType(
            state,
            ResultSetType.SCORES,
            csgVersion,
          ),
        )
      ) {
        isSLREnabled = false;
        strTooltip = `To enable Single-Location Report downloads, please run a "Scores" query for data-version ${csgVersion}`;
      }
    } else if (rsType === ResultSetType.ECONOMIC_IMPACTS) {
      if (!scenarios?.includes(Scenario.SSP_585) && !scenarios?.includes(Scenario.SSP_245)) {
        isSLREnabled = false;
        strTooltip =
          'To enable Single-Location Report downloads, please run a "Peril Metrics with Economic Impacts" query with either scenario SSP2, SSP5 or both';
      } else if (
        isEmpty(
          selectResultSetsInSelectedPortfolioByVersionAndType(
            state,
            ResultSetType.SCORES,
            csgVersion,
          ),
        )
      ) {
        isSLREnabled = false;
        strTooltip = `To enable Single-Location Report downloads, please run a "Scores" query for data-version ${csgVersion}`;
      }
    } else if (rsType === ResultSetType.SCORES) {
      const perilsList = selectResultSetsInSelectedPortfolioByVersionAndType(
        state,
        ResultSetType.PERILS,
        csgVersion,
      );
      const perilsEIList = selectResultSetsInSelectedPortfolioByVersionAndType(
        state,
        ResultSetType.ECONOMIC_IMPACTS,
        csgVersion,
      );
      if (isEmpty(perilsList) && isEmpty(perilsEIList)) {
        isSLREnabled = false;
        strTooltip = `To enable Single-Location Report downloads, please run a perils query (either "Peril Metrics Only" or "Peril Metrics with Economic Impacts") for data-version ${csgVersion}`;
      } else {
        const hasSSP2 =
          filterResultsetsByScenario([...perilsList, ...perilsEIList], Scenario.SSP_245).length > 0;
        const hasSSP5 =
          filterResultsetsByScenario([...perilsList, ...perilsEIList], Scenario.SSP_585).length > 0;
        if (!hasSSP2 && !hasSSP5) {
          isSLREnabled = false;
          strTooltip = `To enable Single-Location Report downloads, please run a perils query (either "Peril Metrics Only" or "Peril Metrics with Economic Impacts") with either scenario SSP2, SSP5 or both; for data-version ${csgVersion}`;
        }
      }
    }
  }

  return { isSLRAvailable, isSLREnabled, strTooltip };
};

export const selectCheckAvailabilityOfFidelitySLR = (
  state: RootState,
): { isFidelityAvailable: boolean; strTooltip: string } => {
  const { selectedResultSet } = state.csgData;

  if (!selectedResultSet) {
    return { isFidelityAvailable: false, strTooltip: 'There is no selected result-set.' };
  }

  let isFidelityAvailable = false;
  let tooltipText = '';
  const {
    type: rsType,
    parameters: { csgVersion, benchmarking = {}, eiModules = [] },
  } = selectedResultSet;

  if (rsType === ResultSetType.SCORES) {
    isFidelityAvailable =
      csgVersion === CSG_VERSION.v3_1_0 &&
      Boolean(benchmarking?.levels?.includes(BenchmarkingLevel.ADMIN2)) &&
      selectResultSetsInSelectedPortfolioByVersionAndType(
        state,
        ResultSetType.ECONOMIC_IMPACTS,
        csgVersion,
        EIModules.FINANCIAL,
      ).length > 0;
    tooltipText =
      'Fidelity template is disabled. Either this "Scores" result-set does not have data version 3.1.0 with Admin 2 level selected for benchmarking or there is no corresponding "Peril Metrics with Economic Impacts" result-set with financial metrics enabled.';
  } else if (rsType === ResultSetType.ECONOMIC_IMPACTS) {
    isFidelityAvailable =
      csgVersion === CSG_VERSION.v3_1_0 &&
      eiModules.includes(EIModules.FINANCIAL) &&
      selectResultSetsInSelectedPortfolioByVersionAndType(
        state,
        ResultSetType.SCORES,
        CSG_VERSION.v3_1_0,
      ).filter((resultSet) =>
        resultSet.parameters.benchmarking?.levels?.includes(BenchmarkingLevel.ADMIN2),
      ).length > 0;
    tooltipText =
      'Fidelity template is disabled. Either this "Peril Metrics with Economic Impacts" result-set does not have data version 3.1.0 with financial mentrics enabled or there is no corresponding "Scores" result-set with Admin 2 level selected for benchmarking.';
  } else if (rsType === ResultSetType.PERILS) {
    isFidelityAvailable = false;
    tooltipText = 'This template is not available for result-set of type "Peril Metrics Only"';
  }
  return { isFidelityAvailable, strTooltip: isFidelityAvailable ? '' : tooltipText };
};

export const selectDownloadSLRAllLocations = (state: RootState): boolean => {
  const { selectedPortfolio } = state.csgData;
  return (extractLocationCount(selectedPortfolio) ?? 0) <= 25; // Enable download SLR for all only upto "25" locations
};

export const selectAvailableSLRTemplates = (state: RootState): SLRTemplateProps[] => {
  const { selectedResultSet } = state.csgData;
  if (!selectedResultSet) {
    const strTooltip = 'There is no selected result-set.';
    return slrTemplateOptions.map((template) => ({
      ...template,
      selected: false,
      disabled: true,
      tooltip: strTooltip,
    }));
  }

  const {
    type: rsType,
    parameters: { csgVersion, eiModules = [] },
  } = selectedResultSet;

  let allowBasic = true;
  let allowStandard = true;
  let allowAdvanced = true;
  const basicTooltip = '';
  const standardTooltip = '';
  let advancedTooltip = '';

  switch (rsType) {
    case ResultSetType.ECONOMIC_IMPACTS: {
      const isFinancialMetrics = eiModules.includes(EIModules.FINANCIAL);
      if (!isFinancialMetrics) {
        allowAdvanced = false;
        advancedTooltip =
          'This template is not available for result-set of type "Peril Metrics with Economic Impacts" without Financial metrics';
      }
      break;
    }
    case ResultSetType.SCORES: {
      if (
        isEmpty(
          selectResultSetsInSelectedPortfolioByVersionAndType(
            state,
            ResultSetType.ECONOMIC_IMPACTS,
            csgVersion,
            EIModules.FINANCIAL,
          ),
        )
      ) {
        allowAdvanced = false;
        advancedTooltip = `To enable this template, please run a "Peril Metrics with Economic Impacts" query with Financial metrics for data-version ${csgVersion}`;
      }
      break;
    }
    case ResultSetType.PERILS: {
      allowAdvanced = false;
      advancedTooltip =
        'This template is not available for result-set of type "Peril Metrics Only"';
      break;
    }
    default: {
      allowBasic = false;
      allowStandard = false;
      allowAdvanced = false;
      break;
    }
  }

  const { templates: slrTemplates } = slrTemplateOptions.reduce<{
    templates: SLRTemplateProps[];
    selected: boolean;
  }>(
    (prev, curr) => {
      const { templates, selected } = prev;
      let template = { ...curr };
      let isSelected = selected;
      switch (curr.value) {
        case SLRTemplateType.CR_BASIC: {
          template = {
            ...template,
            selected: !isSelected && allowBasic,
            disabled: !allowBasic,
            tooltip: basicTooltip,
          };
          isSelected = template.selected;
          break;
        }
        case SLRTemplateType.EX_BASIC: {
          template = {
            ...template,
            disabled: !allowBasic,
            tooltip: basicTooltip,
          };
          break;
        }
        case SLRTemplateType.CR_STANDARD: {
          template = {
            ...template,
            selected: !isSelected && allowStandard,
            disabled: !allowStandard,
            tooltip: standardTooltip,
          };
          isSelected = template.selected;
          break;
        }
        case SLRTemplateType.EX_STANDARD: {
          template = {
            ...template,
            disabled: !allowStandard,
            tooltip: standardTooltip,
          };
          break;
        }
        case SLRTemplateType.CR_ADVANCED: {
          template = {
            ...template,
            selected: !isSelected && allowAdvanced,
            disabled: !allowAdvanced,
            tooltip: advancedTooltip,
          };
          isSelected = template.selected;
          break;
        }
        case SLRTemplateType.EX_ADVANCED: {
          template = {
            ...template,
            disabled: !allowAdvanced,
            tooltip: advancedTooltip,
          };
          break;
        }
        default: {
          break;
        }
      }
      return { templates: [...templates, template], selected: isSelected };
    },
    { templates: [], selected: false },
  );

  return slrTemplates;
};

export const selectAvailableScenariosForSLR = (state: RootState): RadioOption[] => {
  const { selectedResultSet } = state.csgData;
  let isSSP2Available = false;
  let isSSP5Available = false;
  if (selectedResultSet) {
    const {
      type: rsType,
      parameters: { csgVersion, scenarios },
    } = selectedResultSet;
    if (rsType === ResultSetType.SCORES) {
      const perilsList = selectResultSetsInSelectedPortfolioByVersionAndType(
        state,
        ResultSetType.PERILS,
        csgVersion,
      );
      const perilsEIList = selectResultSetsInSelectedPortfolioByVersionAndType(
        state,
        ResultSetType.ECONOMIC_IMPACTS,
        csgVersion,
      );
      isSSP2Available =
        filterResultsetsByScenario([...perilsList, ...perilsEIList], Scenario.SSP_245).length > 0;
      isSSP5Available =
        filterResultsetsByScenario([...perilsList, ...perilsEIList], Scenario.SSP_585).length > 0;
    } else {
      isSSP2Available = Boolean(scenarios?.includes(Scenario.SSP_245));
      isSSP5Available = Boolean(scenarios?.includes(Scenario.SSP_585));
    }
  }
  return slrScenarioOptions.map((scenario) => {
    let disabled = true;
    let selected = false;
    let tooltip = 'This scenario is not available.';
    if (isSSP5Available && scenario.id === SLRScenarioType.SSP5) {
      disabled = false;
      tooltip = '';
      selected = true;
    }
    if (isSSP2Available && scenario.id === SLRScenarioType.SSP2) {
      disabled = false;
      tooltip = '';
      if (!isSSP5Available) {
        selected = true;
      }
    }
    return { ...scenario, selected, disabled, tooltip };
  });
};

export const isCRARCompatibleDamageAndLossRs = (resultSet: ResultSet): boolean => {
  const { isCompatible } = crarCompatibilityForDamageAndLossRs(resultSet);
  return isCompatible;
};

export const isCRARCompatibleScoresRs = (
  resultSet: ResultSet,
  damageAndLossRs: ResultSet | null, // the selected damageAndLossResultSet for comparison
): boolean => {
  const { isCompatible } = crarCompatibilityForScoresRsWithLossRs(resultSet, damageAndLossRs);
  return isCompatible;
};

export const selectCRARCompatibleScoresRsFound = (
  state: RootState,
): { scoresRs: ResultSet | null; foundAValidScoresRs: boolean } => {
  const damageAndLossRs = selectSelectedResultSet(state);
  const scoresRsList = selectScoresResultSetsInSelectedPortfolio(state);

  if (scoresRsList.length === 0) {
    return { scoresRs: null, foundAValidScoresRs: false };
  }

  // const matches = scoresRsList.filter(isCRARCompatibleScoresRs);

  // if (matches.length > 0) {
  //   console.log('Found Scores CRAR matches:', matches);
  // }

  const foundMatch =
    scoresRsList.find((rs) => isCRARCompatibleScoresRs(rs, damageAndLossRs)) ?? null;

  return { scoresRs: foundMatch, foundAValidScoresRs: (foundMatch?.id ?? '').length > 0 };
};

export const selectSelectedResultSet = (state: RootState): ResultSet | null =>
  state.csgData?.selectedResultSet ?? null;

export const selectResultSetLoading = (state: RootState): boolean =>
  state.csgData?.resultSetsStatus === DataStatus.LOADING;

export const selectPortfolioLoading = (state: RootState): boolean =>
  state.csgData?.portfoliosStatus === DataStatus.LOADING;

export const selectDownloadsById = (state: RootState): DownloadsById | null =>
  state.csgData?.downloadsById ?? null;

export const selectGeoCodeLogDownloadsById = (state: RootState): DownloadsById | null =>
  state.csgData?.downloadGeoCodingLogsById ?? null;

// IDEA: For future status or notifications tab
// export const selectListOfResultSetsLoading = (state: RootState): string[] =>
//   Object.keys(state.csgData.downloadsById);

export const selectPortfolioName = (state: RootState): string =>
  state.csgData?.selectedPortfolio?.name ?? '';

export const selectResultSetCountByPortfolioId = (
  state: RootState,
): ResultSetCountByPortfolioId => {
  return buildResultSetCountByPortfolioId(state.csgData.resultSets);
};

export const selectUsageStats = (state: RootState): UsageStats => state.csgData.usageStats;

export const selectSelectedPortfolioForSLR = (state: RootState): Portfolio | null =>
  state.csgData?.selectedPortfolioForSLR ?? null;

export const selectSelectedResultSetForSLR = (state: RootState): ResultSet | null =>
  state.csgData?.selectedResultSetForSLR ?? null;

export default selectDataVersion;
