export const TOP_BAR_HEADER_HEIGHT: number = 56;
export const TOP_BAR_NAV_HEADER_HEIGHT: number = 52;
export const TOP_BAR_HEIGHT: number = TOP_BAR_HEADER_HEIGHT + TOP_BAR_NAV_HEADER_HEIGHT;

export const CONTAINER_STYLE = {
  padding: '8px',
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const COL_STYLE = {
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};
