import { FC } from 'react';
import { Box, CircularProgress, MenuItem, useTheme } from '@mui/material';
import TextInput, { TextInputProps } from './text-input/TextInput';
import { VersionOption } from '../redux/slices/csgDataSlice';

interface CSGVersionSelectorProps extends TextInputProps {
  availableVersions: VersionOption[];
  selectedVersion: VersionOption | null;
  onSelectVersion: (input: VersionOption) => void;
  loading?: boolean;
}

const CSGVersionSelector: FC<CSGVersionSelectorProps> = ({
  availableVersions,
  selectedVersion,
  onSelectVersion,
  loading = false,
  InputProps,
  ...props
}: CSGVersionSelectorProps) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <TextInput
        select
        disabled={loading}
        onChange={(e) => {
          const versionOptionId = e.target.value;
          const versionOption = availableVersions.find((vO) => vO.id === versionOptionId) ?? null;

          if (versionOption) {
            onSelectVersion(versionOption);
          }
        }}
        value={selectedVersion?.id}
        InputProps={{
          ...InputProps,
          sx: {
            minWidth: theme.spacing(8),
            ...InputProps?.sx,
          },
        }}
        {...props}
      >
        {availableVersions.map((versionOption) => {
          const { id, title } = versionOption;

          return (
            <MenuItem key={id} value={id} data-testid={id}>
              {title}
            </MenuItem>
          );
        })}
      </TextInput>
      {loading && (
        <CircularProgress
          size={theme.spacing(1.75)}
          color="inherit"
          sx={{
            fontSize: 'inherit',
          }}
        />
      )}
    </Box>
  );
};

export default CSGVersionSelector;
