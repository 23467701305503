import { BusinessCenter as BusinessCenterIcon } from '@mui/icons-material';
import { Box, Divider, Toolbar, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { isEmpty } from 'lodash';
import MidDot from '../../../components/MidDot';
import { MenuOptionProps } from '../../../components/menu/types';
import UserProfileCard from '../../../components/user-profile/UserProfileCard';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectCustomerName,
  selectIsLoggedIn,
  selectUserDetails,
} from '../../../redux/selectors/userSelectors';
import { setSettingsDrawer } from '../../../redux/slices/uiSlice';
import { setUser, userLogout } from '../../../redux/slices/userSlice';
import { CenteredRowStyle, GREY_BOX_STYLE, px } from '../../../utils/styleUtils';
import NavHeader from '../nav-header/NavHeader';
import ApplicationBar from './ApplicationBar';
import {
  CONTAINER_STYLE,
  TOP_BAR_HEADER_HEIGHT,
  TOP_BAR_HEIGHT,
  TOP_BAR_NAV_HEADER_HEIGHT,
} from './util';
import { handleLoginInHeap } from '../../../heap';
import {
  extractCustomRoles,
  extractEmail,
  extractJupiterUserId,
  extractTenantDisplayName,
  extractTenantName,
  extractUserRole,
  extractUsername,
} from '../../../auth0/utils';

// const GlassAppBar = withGlass({
//   color: '#000',
//   noBorders: true,
//   square: true,
// })(AppBar);

const TopBar: FC = () => {
  const { isLoading, user, logout } = useAuth0();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const { username, email, tenantName } = useAppSelector(selectUserDetails);
  const companyName = useAppSelector(selectCustomerName);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleLogout = async (): Promise<void> => {
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    await dispatch(userLogout());
  };

  useEffect(() => {
    const checkForToken = async (): Promise<void> => {
      if (!isLoggedIn || isLoading) return;

      if (!isEmpty(user)) {
        dispatch(
          setUser({
            username: extractUsername(user),
            userId: extractJupiterUserId(user),
            email: extractEmail(user),
            tenantName: extractTenantName(user),
            tenantDisplayName: extractTenantDisplayName(user),
            userRole: extractUserRole(user),
            customRoles: extractCustomRoles(user),
          }),
        );
        void handleLoginInHeap({ tenantName, username: email });
        return;
      }

      // eslint-disable-next-line no-console
      console.log('Authentication failed(1). You will be logged out.');
      await handleLogout().catch((err) => {
        console.error('Error in handleLogout(1):', err);
      });
    };

    // check the token
    checkForToken()
      // make sure to catch any error
      .catch((error) => {
        if (error?.code === 'NotAuthorizedException') {
          // eslint-disable-next-line no-console
          console.log('Authentication failed(2). You will be logged out.');
          handleLogout().catch((err) => {
            console.error('Error in handleLogout(2):', err);
          });
          return;
        }

        /*  Commented below lines as they are not required for auth0
            if in any case it's necessary this was the value of variable
            export const NO_CURRENT_USER = 'No current user'; */
        // if (error?.message === NO_CURRENT_USER) {
        //   // eslint-disable-next-line no-console
        //   console.error('NO_CURRENT_USER. Not reacting to that right now.');
        //   return;
        // }
        console.error('Unhandled in checkForToken:', error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isLoading, username]);

  const onClickMenu = (element: HTMLButtonElement): void => {
    setAnchorEl(element);
  };

  const onCloseMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleOnSettingsClick = (): void => {
    dispatch(setSettingsDrawer(true));
    handleClose();
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const onLogout = (): void => {
    handleClose();
    void handleLogout();
  };

  const loggedInImageStyle = {
    height: theme.spacing(3),
    paddingRight: theme.spacing(0.625),
  };
  const MENU_OPTIONS: MenuOptionProps[] = [
    {
      title: 'Settings',
      onClick: () => {
        handleOnSettingsClick();
      },
      id: 'menu-app-bar-item-1',
    },
    {
      title: 'Logout',
      onClick: () => {
        onLogout();
      },
      id: 'menu-app-bar-item-2',
    },
  ];

  return (
    <ApplicationBar>
      <Toolbar
        disableGutters
        sx={{
          background: 'inherit',
          color: 'inherit',
          height: px(TOP_BAR_HEIGHT),
          width: '100%',
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
          }}
        >
          <Box
            sx={{
              ...CONTAINER_STYLE,
              height: isLoggedIn ? TOP_BAR_HEADER_HEIGHT : '100%',
              paddingLeft: {
                xs: theme.spacing(2.25),
                sm: theme.spacing(2.25),
                md: theme.spacing(4.5),
              },
              paddingRight: {
                xs: theme.spacing(2.25),
                sm: theme.spacing(2.25),
                md: theme.spacing(4.5),
              },
            }}
          >
            {/* COLUMN 1 */}
            <Box
              sx={{
                alignItems: 'flex-start',
                justifyContent: !isLoggedIn ? 'center' : 'space-between',
                maxWidth: {
                  xs: '26vw',
                  sm: '39vw',
                  md: '39vw',
                },
              }}
            >
              <Box
                sx={{
                  ...CenteredRowStyle,
                  alignItems: 'center',
                }}
              >
                <img
                  alt="logo"
                  style={{
                    height: theme.spacing(6),
                    ...(isLoggedIn ? loggedInImageStyle : {}),
                  }}
                  src={`${window._env_.REACT_APP_APP_ROOT}/img/logo-secondary.png`}
                />
                {isLoggedIn && (
                  <Box
                    sx={{
                      display: {
                        xs: 'none',
                        sm: 'flex',
                      },
                      alignItems: 'center',
                    }}
                  >
                    <MidDot />
                    <Typography data-testid="home-header" variant="subtitle1" noWrap>
                      ClimateScore Global
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            {/* COLUMN 2 */}
            {isLoggedIn && (
              <Box
                sx={{
                  alignItems: 'flex-end',
                  maxWidth: {
                    xs: '73vw',
                    sm: '60vw',
                    md: '60vw',
                  },
                }}
              >
                <Box sx={{ ...CenteredRowStyle, alignItems: 'center' }}>
                  <Box sx={{ ...CenteredRowStyle, alignItems: 'center' }}>
                    <BusinessCenterIcon
                      sx={{
                        height: theme.spacing(2.2),
                        width: theme.spacing(2.2),
                        marginRight: theme.spacing(0.5),
                      }}
                    />
                    <Typography data-testid="company-name-header" variant="caption">
                      {companyName}
                    </Typography>
                  </Box>
                  <Divider
                    orientation="vertical"
                    sx={{
                      borderColor: '#fff',
                      height: theme.spacing(1.5),
                      margin: {
                        xs: `0 ${theme.spacing(1)}`,
                        sm: `0 ${theme.spacing(1)}`,
                        md: `0 ${theme.spacing(2)}`,
                      },
                    }}
                  />
                  <Box>
                    <UserProfileCard
                      title={username}
                      subtitle={email}
                      avatarUrl=""
                      userGreeting="Welcome back,"
                      anchor={anchorEl}
                      isMenuDisabled={!isLoggedIn}
                      options={MENU_OPTIONS}
                      onClickCardMenu={onClickMenu}
                      onCloseCardMenu={onCloseMenu}
                    />
                  </Box>
                </Box>

                {/* Hiding the element for the release 1 */}
                {/* {isLoggedIn && (
                  <Box sx={CenteredRowStyle}>
                    <PrimaryButton
                      containerProps={{
                        sx: {
                          marginRight: '10px',
                        },
                      }}
                    >
                      Button
                    </PrimaryButton>
                    <NotificationButtons />
                  </Box>
                )} */}
              </Box>
            )}
          </Box>
          {isLoggedIn && (
            <Box
              sx={{
                ...CONTAINER_STYLE,
                ...GREY_BOX_STYLE,
                height: TOP_BAR_NAV_HEADER_HEIGHT,
                paddingLeft: {
                  xs: theme.spacing(2.25),
                  sm: theme.spacing(2.25),
                  md: theme.spacing(4.5),
                },
                paddingRight: {
                  xs: theme.spacing(2.25),
                  sm: theme.spacing(2.25),
                  md: theme.spacing(4.5),
                },
              }}
            >
              <NavHeader />
            </Box>
          )}
        </Box>
      </Toolbar>
    </ApplicationBar>
  );
};

export default TopBar;
