import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { Children, cloneElement, FC, ReactElement, ReactNode } from 'react';
import { LITE_TEXT_COLOR, MODAL_BG_COLOR } from '../utils/styleUtils';

const titleComponent = (title: any): ReactNode => {
  switch (typeof title) {
    case 'string':
      return (
        <DialogTitle
          sx={{
            marginTop: '12px',
            flexGrow: 0,
          }}
        >
          <Typography variant="h4">{title}</Typography>
        </DialogTitle>
      );
    default:
      return title;
  }
};

interface ModalProps extends DialogProps {
  children?: ReactElement | ReactElement[] | any[];
  actions?: ReactElement | ReactElement[];
  textBody?: string;
  header?: string | ReactElement;
}

const Modal: FC<ModalProps> = ({
  classes,
  children,
  header = null,
  textBody = null,
  actions,
  sx,
  ...props
}) => {
  const theme = useTheme();
  const elements = Array.isArray(children) ? children.filter(Boolean) : [children].filter(Boolean);

  return (
    <Dialog {...props} aria-labelledby="dialog-title" aria-describedby="dialog-description">
      <Box
        sx={{
          background: MODAL_BG_COLOR,
          color: LITE_TEXT_COLOR,
          boxShadow: `0 ${theme.spacing(0.25)} ${theme.spacing(0.5)} 0 rgba(0,0,0,0.50)`,
          borderRadius: theme.spacing(0.5),
          padding: theme.spacing(1.5),
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: theme.spacing(56.25), // :: '450px',
          ...(sx ?? {}),
        }}
      >
        {header && titleComponent(header)}

        {Children.map(elements, (child: ReactElement) => (
          <DialogContent sx={{ flexGrow: 1, padding: 0 }}>{cloneElement(child)}</DialogContent>
        ))}

        {actions && <DialogActions sx={{ flexGrow: 0 }}>{actions}</DialogActions>}
      </Box>
    </Dialog>
  );
};
export default Modal;
