// import { useState, useEffect } from 'react';
import { get, uniq } from 'lodash';
import Papa, { ParseResult } from 'papaparse';
import { reactKey } from './reactUtils';

interface FileExportArgs {
  filename?: string;
  id?: string;
  url: string;
}
export const exportFileAsync = async (args: FileExportArgs): Promise<void> => {
  return await new Promise((resolve, reject) => {
    try {
      const { filename = null, id = reactKey('download-tag-'), url } = args;
      const tempLink = document.createElement('a');
      tempLink.setAttribute('href', url);
      // tempLink.setAttribute('target', '_blank'); // Had to disable this because Safari browser did not close the popup, which was a bug.

      tempLink.setAttribute('id', id);

      if (filename) {
        tempLink.setAttribute('download', filename); // NOTE: This will not work in production to set the name of the file. Still, the "download" attribute is helpful. See FAQ section in README at the root of this project for more information.
      }

      tempLink.click();
      tempLink.remove();
      resolve();
    } catch (error) {
      console.error('Error in exportFileAsync:', error);
      reject(error);
    }
  });
};

export const exportAllFileAsync = async (args: FileExportArgs[]): Promise<void> => {
  return await new Promise((resolve, reject) => {
    try {
      const downloadables = args.map((arg) => {
        const { filename = null, id = reactKey('download-tag-'), url } = arg;
        const iFrame = document.createElement('iframe');
        iFrame.setAttribute('name', `download-frame-${id}`);
        iFrame.style.display = 'none';
        document.body.appendChild(iFrame);
        const tempLink = document.createElement('a');
        tempLink.setAttribute('href', url);
        if (iFrame.contentDocument) {
          iFrame.contentDocument.body.appendChild(tempLink);
        } else {
          tempLink.setAttribute('target', iFrame.name);
          tempLink.style.display = 'none';
        }
        // tempLink.setAttribute('target', '_blank'); // Had to disable this because Safari browser did not close the popup, which was a bug.

        tempLink.setAttribute('id', id);

        if (filename) {
          tempLink.setAttribute('download', filename); // NOTE: This will not work in production to set the name of the file. Still, the "download" attribute is helpful. See FAQ section in README at the root of this project for more information.
        }
        return { tempLink, iFrame };
      });

      downloadables.forEach(({ tempLink }) => tempLink.click());
      setTimeout(() => {
        // setTimeout for 1 second per link, because removing directly after link is clicked cancels the download
        // when there are more than 1 files to download over network
        downloadables.forEach(({ tempLink, iFrame }) => {
          tempLink.remove();
          iFrame.remove();
        });
      }, 1000 * downloadables.length);
      // resolve before a-tag and iframe is removed as we do not want to block user while DOM is being cleaned up.
      resolve();
    } catch (error) {
      console.error('Error in exportAllFileAsync:', error);
      reject(error);
    }
  });
};

export interface ParsedCSVFileMeta {
  aborted?: boolean;
  cursor?: number;
  delimiter?: string;
  fields?: string[];
  linebreak?: string;
  truncated?: boolean;
}

export interface PapaParseResults {
  data: any[];
  errors: any[];
  meta: ParsedCSVFileMeta;
}

// https://www.papaparse.com/docs#data
export const csvToJsonAsync = async (file: any): Promise<any> => {
  return await new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results: ParseResult<PapaParseResults>) => {
        return resolve(results);
      },
      error: (error) => {
        return reject(error);
      },
    });
  });
};

export const readCSV = async (file: any): Promise<any> => {
  return await new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: () => {
        const reader = new FileReader();

        reader.onload = async (e) => {
          const text = get(e, 'target.result', null);
          resolve(text);
        };
        reader.readAsText(file); // e.target.files[0])
      },
      error: (error) => {
        return reject(error);
      },
    });
  });
};

/*
export const useReadCSV = (csvFile: any): PapaParseResults => {
  const [values, setValues] = useState<PapaParseResults>({ data: [], errors: [], meta: {} });
  const [errors, setErrors] = useState<Error | undefined>();

  const getCsv = (file: any): void => {
    if (file === null) return;

    try {
      const results = csvToJson(csvFile);
      setValues(results);
    } catch (err) {
      const typedError = err as Error;
      setErrors(typedError);
    }
  };

  useEffect(() => {
    getCsv(csvFile);
  }, [csvFile]);

  if (errors) {
    console.error('useReadCSV had errors:', errors);
  }

  return values;
};
export default useReadCSV;

*/

interface DataObject {
  [code: string]: any;
}

export const JSON2CSV = (
  objArray: DataObject[],
  columnOrder: string[] = [],
  includeHeaders: boolean = true,
): string => {
  /* Expectations:
        objArray: [
          {latitude: "Latitude", scenario: "Scenario", longitude: "Longitude"},
          {latitude: -80, scenario: ssp338, longitude: 120},
          {latitude: -60, scenario: ssp61, longitude: 140},
          ...
        ],
        columnOrder: ["latitude", "longitude", "scenario"]
  */
  const arrayOfDictionaries = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

  const headerKeys = Object.keys(arrayOfDictionaries[0]); // ensures the columns will be in consistent order

  const order = (headers: string[]): string[] => {
    const defaultOrder = headers.sort();

    // This puts the columns in columnOrder first, in the order they are arranged, and then the rest of the columns sorted via default A-z.
    return uniq(
      // RE: Security warning -- No user input here. Disabling...
      columnOrder
        .filter((key) => arrayOfDictionaries[0][`${key}`] !== undefined)
        .concat(defaultOrder),
    );
  };

  const orderedHeaders = order(headerKeys);

  const stringifyValueRow = (array: any[]): string =>
    array.map((val: any) => JSON.stringify(val)).join(',');

  const csv = arrayOfDictionaries
    .map((dictionary: DataObject) => {
      // RE: Security warning -- No user input here. Disabling...
      return orderedHeaders.map((key) => dictionary[`${key}`]);
    })
    .map(stringifyValueRow)
    .join('\r\n');

  return includeHeaders ? `${stringifyValueRow(orderedHeaders)}\r\n`.concat(csv) : csv;
};
