import { isNumber } from 'lodash';
import { APIResponse } from '../http';
import { UUID } from '../../api/types';
import { createExport, getExport } from '../export/export';
import { APIStatus, DownloadResponse } from '../types';
import { APIFileDownloadLink } from '../file/types';
import { APIExportFileType } from '../export/types';
import { APICreateSLRInput, APISLR, CreateSLRInput, ReportType, SLRRestAPIError } from './types';
import { createFileDownloadLink } from '../file/file';
import { createSLR } from './slr';
import { convertCreateSLRError } from './utils';

export async function createSLRAdapter({
  templateJSON,
  perilsResultSetId,
  perilsEIResultSetId,
  scoresResultSetId,
  locationId,
}: CreateSLRInput): Promise<APIResponse<APISLR>> {
  const input: APICreateSLRInput = {
    type: ReportType.SLR,
    jupiter_template: {
      type: templateJSON.type,
      scenario: templateJSON.scenario,
      color_coding: templateJSON.colorCoding,
    },
    perils_result_set_id: perilsResultSetId,
    impacts_result_set_id: perilsEIResultSetId,
    scores_result_set_id: scoresResultSetId,
  };
  if (isNumber(locationId)) {
    input.location_ids = [locationId];
  }
  const { data, error } = await createSLR(input);
  if (error) {
    return {
      data: null,
      error: new Error(convertCreateSLRError(data as unknown as SLRRestAPIError)),
    };
  }
  return { data, error: null };
}

export async function getSLRDownloadUrlsAdapter(
  slrId: UUID,
): Promise<APIResponse<DownloadResponse>> {
  const { data, error } = await createExport({
    // compression: APIExportCompression.none,
    file_type: APIExportFileType.docx,
    object_id: slrId,
    options: {
      object_type: 'single_location_report',
    },
    // output_style: APIOutputStyle.standard,
  });
  if (error) {
    return { data: null, error };
  }
  let apiExport = data;
  /* eslint-disable no-await-in-loop */
  while (apiExport.status === APIStatus.pending) {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 5000));
    const apiResponse = await getExport(apiExport.id);
    if (apiResponse.error) {
      return { data: null, error: apiResponse.error };
    }
    apiExport = apiResponse.data;
  }

  if (apiExport.status === APIStatus.failed) {
    return { data: null, error: new Error('Export failed.') };
  }

  const { files } = apiExport;

  const responses = await Promise.all(
    files.map(async (file) => await createFileDownloadLink(file.id, { expires_in: 3600 })),
  );
  const errResponse = responses.find((response) => response.error);
  // If there is at least one error
  if (errResponse) {
    return { data: null, error: errResponse.error as Error };
  }
  // There are zero errors
  return {
    data: {
      download: {
        urls: responses.map((link) => (link.data as APIFileDownloadLink).url),
      },
    },
    error: null,
  };
}
