import { FC, useState } from 'react';
import { Button, CircularProgress, Divider, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectAIAssistantVisible,
  selectAppContentSection,
  selectDocument360IsLoading,
} from '../../../redux/selectors/uiSelectors';
import { NAV_SEPARATOR_COLOR, SUB_HEADER_DELIMITER } from '../../../utils/styleUtils';
import { AppSection, getDocument360Token, setAppSection } from '../../../redux/slices/uiSlice';
import CreatePortfolio from './CreatePortfolio';
import { StyledMediumLabel } from '../../../components/labels/StyledLabel';
import { unSetDashboard } from '../../../redux/slices/visualizationsSlice';
import { selectKnowledgeBaseAccess } from '../../../redux/selectors/queryBuilderSelectors';
import AiInterestFormModal from '../ai-interest-form/AiInterestFormModal';

const JUPITER_AI_ASSISTANT_LINK = 'https://jupiter-intelligence-frontend-production.up.railway.app';
const JUPITER_AI_INTEREST_FORM_LINK = 'https://www2.jupiterintel.com/l/972403/2024-05-14/5y2pr';

const PortfolioNavHeader: FC = () => {
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const selectedAppSection = useAppSelector(selectAppContentSection);
  const userCanAccessKnowledgeBase = useAppSelector(selectKnowledgeBaseAccess);

  const doc360IsLoading: boolean = useAppSelector(selectDocument360IsLoading);
  const [showAIForm, setShowAiForm] = useState(false);

  const enableViewPortfolio = selectedAppSection === AppSection.Visualizations;
  return (
    <>
      <Stack
        useFlexGap
        direction="row"
        flexWrap="wrap"
        alignItems="center"
        spacing={0}
        divider={
          <Divider
            flexItem
            orientation="vertical"
            sx={{
              borderRight: SUB_HEADER_DELIMITER,
              height: theme.spacing(3),
              marginTop: 'auto',
              marginBottom: 'auto',
              marginLeft: {
                xs: theme.spacing(1),
                sm: theme.spacing(1),
                md: theme.spacing(2),
              },
              marginRight: {
                xs: theme.spacing(1),
                sm: theme.spacing(1),
                md: theme.spacing(2),
              },
            }}
          />
        }
      >
        {(!xsScreen || !enableViewPortfolio) && <CreatePortfolio />}
        {(!xsScreen || enableViewPortfolio) && (
          <Button
            data-testid="view-portfolio"
            variant="text"
            sx={{
              padding: 0,
              color: 'inherit',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '&:disabled': {
                color: NAV_SEPARATOR_COLOR,
              },
            }}
            disabled={!enableViewPortfolio}
            onClick={() => {
              if (enableViewPortfolio) {
                dispatch(setAppSection(AppSection.Explorer));
                // deselect visualization
                dispatch(unSetDashboard());
              }
            }}
          >
            <StyledMediumLabel variant="button">View Portfolio</StyledMediumLabel>
          </Button>
        )}
        {!xsScreen && (
          <Button
            data-testid="access-knowledge-base-button"
            variant="text"
            sx={{
              padding: 0,
              color: 'inherit',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '&:focus-visible': {
                backgroundColor: 'transparent',
                outline: 0,
              },
            }}
            disableFocusRipple
            disableRipple
            onClick={() => {
              if (userCanAccessKnowledgeBase) {
                void dispatch(
                  getDocument360Token({
                    /** Empty as there is no redirectUrl needed */
                  }),
                );
              }
            }}
            disabled={!userCanAccessKnowledgeBase || doc360IsLoading}
          >
            <StyledMediumLabel
              variant="button"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Access Knowledge Base{' '}
              {doc360IsLoading && (
                <CircularProgress
                  sx={() => ({
                    fontSize: 'inherit',
                    marginLeft: theme.spacing(0.5),
                  })}
                  size="14px"
                  color="inherit"
                />
              )}
            </StyledMediumLabel>
          </Button>
        )}
        <Button
          data-testid="jupiter-ai-interest-base-button"
          variant="text"
          sx={{
            padding: 0,
            color: NAV_SEPARATOR_COLOR,
            cursor: 'default',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&:focus-visible': {
              backgroundColor: 'transparent',
              outline: 0,
            },
          }}
          disableFocusRipple
          disableRipple
          onMouseEnter={() => setShowAiForm(true)}
        >
          <StyledMediumLabel
            variant="button"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Jupiter AI
          </StyledMediumLabel>
        </Button>
      </Stack>
      <AiInterestFormModal
        open={showAIForm}
        formUrl={JUPITER_AI_INTEREST_FORM_LINK}
        onClose={() => {
          setShowAiForm(false);
        }}
      />
    </>
  );
};

export default PortfolioNavHeader;
