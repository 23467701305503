import { TextFieldProps } from '@mui/material';

export const FONT_LIGHT = 'NeueHelveticaPro45Light';
export const FONT_MEDIUM = 'NeueHelveticaPro65Medium';
export const FONT_BOLD = 'NeueHelveticaPro75Bold';

export const CARD_BORDER = '1px solid rgba(204,204,204,0.50)';
export const CARD_DELIMITER = '1px solid rgba(204,204,204,0.10)';
export const DARK_BORDER = '1px solid rgba(0,0,0,0.25)';
export const SUB_HEADER_DELIMITER = '1px solid #888888';
export const SECTION_DELIMITER = '1px solid #D9D9D9';
export const CARD_BODY_DELIMITER = '1px solid #ECECEC';
export const CARD_BODY_DELIMITER_COLORED = '1px solid #2F8DC7';
export const TILE_BORDER = '1px solid #E3E3E3';
export const TEXT_BOX_BORDER = '1px solid #E9E9E9';
export const SIDE_PANEL_SEPARATOR = '1px solid rgba(151,151,151,0.25)';
export const SIDE_PANEL_CONTAINER_BORDER = '1px solid rgba(151,151,151,0.50)';
export const PRIMARY_BORDER = '1px solid #C5F2F7';
export const WHITE_BORDER = '1px solid #FFFFFF';
export const SWITCH_BTN_BORDER = '1px solid #EEEEEE';
export const LITE_DASHED_BORDER = '1px dashed #ECECEC';

export const CARD_BORDER_RADIUS = '4px';

export const APP_TEXT_COLOR = 'rgba(0,0,0,0.90)';
export const LITE_TEXT_COLOR = 'rgba(255,255,255,0.90)';
export const LITE_TEXT_COLOR_HOVER = 'rgba(255,255,255,0.60)';
export const LITE_TEXT_COLOR_DISABLED = 'rgba(255,255,255,0.50)';
export const APP_BG_COLOR = '#F9F9F9';
export const SIDE_PANEL_BG_COLOR = '#242424';
export const MODAL_BG_COLOR = '#222222';
export const SIDE_PANEL_TITLE_COLOR = '#FFFFFF';
export const STRIPE_BG_COLOR = '#ECECEC';
export const CARD_TITLE_COLOR = '#101615';
export const NAV_SEPARATOR_COLOR = '#BBBBBB';
export const TITLE_TEXT_COLOR = '#383838';
export const VALUE_TEXT_COLOR = '#333333';
export const TEXT_FIELD_BG_COLOR = '#FFFFFF';
export const PRIMARY_BTN_BG_COLOR = '#242424';
export const PRIMARY_TEXT_COLOR = '#C5F2F7';
export const DROP_ZONE_ACTIVE_COLOR = '#CDEAFB';
export const MUTED_TEXT_COLOR = '#999999';
export const NAVBAR_BG_COLOR = '#6F6E6F';
export const LIST_SELECTED_BG_COLOR = '#66809F';
export const SECTION_LABEL_BG_COLOR = '#000000';
export const TOGGLE_THUMB_BG_COLOR = '#888888';
export const TOGGLE_TRACK_BG_COLOR = '#444444';
export const TOGGLE_TRACK_CHECKED_BG_COLOR = '#4395A5';
export const WARNING_BG_COLOR_DARK = '#b26b00';

export const DISABLED_TEXT_COLOR = '#CCCCCC';
export const INK_BLUE_TEXT_COLOR = '#023D5E';
export const SEA_BLUE_TEXT_COLOR = '#2F8DC7';

export const DISABLED_TEXT_BOX_BG_COLOR = '#CCCCCC';
export const DISABLED_TEXT_BOX_TEXT_COLOR = '#00000099';
export const DISABLED_TEXT_BOX_BORDER = '1px solid #CCCCCC';

// import { SxProps } from '@mui/material/styles';
// import { CSSProperties } from 'react';

// TODO: Figure out what this ought to be
export type StyleProps = object;
// export type StyleProps = SxProps;
// export type StyleProps = CSSProperties;

export const px = (num: number): string => `${num}px`;

export const SPACER: number = 16;
export const MUI_SPACER: number = 2;

// ToBe used in non-FC areas like utils/constants
// Can't use useTheme out of FC
export const MUI_SPACE_PX: number = 8; // ToDo: Update this when updating base for MUI spacing
// Alt of MUI theme.spacing utility
export const spacing = (num: number): string => px(num * MUI_SPACE_PX);

export const Rotate90 = {
  transform: 'rotate(-90deg)',
  /* Legacy vendor prefixes that you probably don't need... */
  /* Safari */
  WebkitTransform: 'rotate(-90deg)',
  /* Firefox */
  MozTransform: 'rotate(-90deg)',
  /* IE */
  msTransform: 'rotate(-90deg)',
  /* Opera */
  OTransform: 'rotate(-90deg)',
  /* Internet Explorer */
  filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=3)',
};

export const CustomScrollBar = {
  scrollbarWidth: 'auto',
  scrollbarColor: 'rgba(170, 170, 170, 1)',
  '&::-webkit-scrollbar': {
    width: '10px',
    scrollBehavior: 'smooth',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(170, 170, 170, 1)',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(170, 170, 170, 0.5)',
  },
};

export const ColumnStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'flex-start',
  justifyContent: 'flex-start',
};

export const CenteredColumnStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

export const CenteredRowStyle = {
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'flex-start',
};

export const CenteredBoxStyle = {
  ...CenteredColumnStyle,
  minHeight: '150px',
  minWidth: '400px',
  width: '100%', // so it will expand to the width of the container at smaller sizes
  flexGrow: 1,
};

export const DRAWER_COLUMN_CONTAINER = {
  ...ColumnStyle,
  paddingTop: px(SPACER),
  paddingLeft: px(SPACER),
  paddingRight: px(SPACER),
};

export interface CircleStyleInterface {
  borderColor: string;
  backgroundColor: string;
}

export interface ActiveCircleStyleInterface {
  NOTIFICATIONS: CircleStyleInterface;
  SWITCH: CircleStyleInterface;
}

export const DEFAULT_CIRCLE_STYLE: CircleStyleInterface = {
  borderColor: '#666666',
  backgroundColor: 'linear-gradient(180deg, #666666 50%, #222222 100%)',
};

export const ACTIVE_CIRCLE_STYLES: ActiveCircleStyleInterface = {
  NOTIFICATIONS: {
    borderColor: '#FF0000',
    backgroundColor: 'linear-gradient(180deg, #F6BBBB 50%, #FF0000 100%)',
  },
  SWITCH: {
    borderColor: '#3DDBD9',
    backgroundColor: 'linear-gradient(180deg, #FFFFFF 50%, #3DDBD9 100%)',
  },
};

export const StandardInputProps: TextFieldProps['InputProps'] = {
  disableUnderline: true,
};

export const CommonInputProps: TextFieldProps['InputProps'] = {
  ...StandardInputProps,
  sx: {
    paddingBottom: '0.5rem !important',
    borderBottom: '1.5px solid gray !important',
  },
};

export const CommonFormLabelStyles = {
  color: '#e3e3e3',
  fontWeight: 'bold',
  marginBottom: '0.25rem',
  marginTop: '0.25rem',
};

export const LINEAR_GRADIENT_1 = 'linear-gradient(180deg, #333 0%, #222 100%)';
export const LINEAR_GRADIENT_2 =
  'linear-gradient(180deg, rgba(0,0,0,0.50) 0%, rgba(0,0,0,0.25) 100%), linear-gradient(115deg, rgba(0,0,0,0.50) 5%, rgba(0,0,0,0.10) 100%)';
export const LINEAR_GRADIENT_3 =
  'linear-gradient(180deg, rgba(0,0,0,0.50) 0%, rgba(0,0,0,0.25) 100%), linear-gradient(144deg, rgba(0,0,0,0.50) 5%, rgba(0,0,0,0.10) 100%)';
export const LINEAR_GRADIENT_4 =
  'linear-gradient(180deg, rgba(0,0,0,0.50) 0%, rgba(0,0,0,0.25) 100%), linear-gradient(116deg, rgba(0,0,0,0.50) 5%, rgba(0,0,0,0.10) 100%)';
export const LINEAR_GRADIENT_5 = 'linear-gradient(180deg, #666 50%, #222 100%)';
export const LINEAR_GRADIENT_6 = 'linear-gradient(180deg, #222 0%, #333 100%)';

export const DARK_BOX_STYLE = {
  background: '#242424',
};

export const GREY_BOX_STYLE = {
  background: NAVBAR_BG_COLOR,
};

export const ACTIVE_BUTTON_STYLE = {
  borderColor: PRIMARY_TEXT_COLOR,
  color: PRIMARY_TEXT_COLOR,
  ':disabled': {
    color: PRIMARY_TEXT_COLOR,
  },
};
