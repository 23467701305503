import { FC, useCallback, useEffect } from 'react';
import { CheckboxOperation, UpdateCheckboxInstructions } from '../../../components/checkbox/types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectPerilsFloodDefense,
  selectPerilsFloodDefenseOptions,
  selectPerilsPerils,
  selectPerilsScenarios,
  selectPerilsYears,
} from '../../../redux/selectors/queryBuilderSelectors';
import {
  setPerilsFloodDefense,
  setPerilsFloodDefenseOptions,
  setPerilsPerils,
  setPerilsScenarios,
  setPerilsYears,
} from '../../../redux/slices/queryBuilderSlice';
import FloodDefense from '../sub-options/FloodDefense';
import PerilsMetricsTransferList from '../sub-options/PerilMetricsTransferList';
import Scenarios from '../sub-options/Scenarios';
import Years from '../sub-options/Years';
import { floodDefenseIsEnabled } from '../builder';

const Perils: FC = () => {
  const dispatch = useAppDispatch();
  const perils = useAppSelector(selectPerilsPerils);
  const scenarios = useAppSelector(selectPerilsScenarios);
  const years = useAppSelector(selectPerilsYears);
  const floodDefense = useAppSelector(selectPerilsFloodDefense);
  const floodDefenseOptions = useAppSelector(selectPerilsFloodDefenseOptions);
  const setPerils = (payload: UpdateCheckboxInstructions): void => {
    dispatch(setPerilsPerils(payload));
  };
  const setScenarios = (payload: UpdateCheckboxInstructions): void => {
    dispatch(setPerilsScenarios(payload));
  };
  const setYears = (payload: UpdateCheckboxInstructions): void => {
    dispatch(setPerilsYears(payload));
  };
  const setFloodDefense = (payload: UpdateCheckboxInstructions): void => {
    dispatch(setPerilsFloodDefense(payload));
  };
  const setFloodDefenseOptions = useCallback(
    (payload: UpdateCheckboxInstructions): void => {
      dispatch(setPerilsFloodDefenseOptions(payload));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!floodDefenseIsEnabled({ floodDefense })) {
      setFloodDefenseOptions({ operation: CheckboxOperation.DisableAll });
      setFloodDefenseOptions({ operation: CheckboxOperation.DeselectAll, skipForbidden: false });
    } else {
      setFloodDefenseOptions({ operation: CheckboxOperation.EnableAll });
      setFloodDefenseOptions({ operation: CheckboxOperation.SelectAll, skipForbidden: false });
    }
  }, [floodDefense, setFloodDefenseOptions]);

  return (
    <>
      <PerilsMetricsTransferList list={perils} setList={setPerils} title="Perils" />
      <Scenarios list={scenarios} setList={setScenarios} title="Scenarios" />
      <Years list={years} setList={setYears} title="Years" />
      <FloodDefense
        list={floodDefense}
        setList={setFloodDefense}
        title="Flood Defense"
        nestedList={floodDefenseOptions}
        setNestedList={setFloodDefenseOptions}
      />
    </>
  );
};

export default Perils;
