import {
  DamageAndLossDefaults,
  FloodDefenseParametersInput,
  GridSize,
  GridType,
  MeshParametersInput,
  ResultSetParametersInput,
  ResultSetType,
} from '../api/resultSets/types';
import { ResultSetInput } from '../pages/query-builder/types';
import {
  APICreateResultSetInput,
  APIDamagesResultSetOptions,
  APIFloodDefenseOptions,
  APILossAPIDefaults,
  APIMeshOptions,
  APIMeshResultSetOptions,
  APIPerilsOptions,
  APIPerilsResultSetOptions,
  APIResultSetOptions,
  APIScoresResultSetOptions,
} from '../rest/resultSet/types';

function camelToSnakeCase(str: string): string {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

function convertDefaults(defaults: DamageAndLossDefaults): APILossAPIDefaults {
  const fireEnums = new Set(['windowPane', 'ventType', 'roofCover']);
  return Object.fromEntries(
    Object.entries(defaults).map(([key, value]) => [
      camelToSnakeCase(key),
      fireEnums.has(key) && typeof value === 'string' ? value.toLowerCase() : value,
    ]),
  );
}

function convertFloodDefense(floodDefense: FloodDefenseParametersInput): APIFloodDefenseOptions {
  return {
    enabled: floodDefense.enabled,
    zero_out_defended_locations: floodDefense.zeroOutDefendedLocations,
  };
}

function convertPerilsOptions(parameters: ResultSetParametersInput): APIPerilsOptions {
  return {
    data_version: parameters.csgVersion,
    perils: parameters.perils as string[],
    scenarios: parameters.scenarios as string[],
    years: parameters.years as number[],
    flood_defense: parameters.floodDefense
      ? convertFloodDefense(parameters.floodDefense)
      : undefined,
  };
}

function convertPerilsResultSetOptions(
  parameters: ResultSetParametersInput,
): APIPerilsResultSetOptions {
  return {
    type: 'perils',
    ...convertPerilsOptions(parameters),
  };
}

function convertEIOptions(parameters: ResultSetParametersInput): APIDamagesResultSetOptions {
  const defaults = parameters.damageAndLoss?.defaultFields
    ? convertDefaults(parameters.damageAndLoss.defaultFields)
    : undefined;
  return {
    type: 'damages',
    ei_version: parameters.eiVersion,
    perils_options: convertPerilsOptions(parameters),
    ei_modules: parameters.eiModules?.map((module) => module.toLowerCase()),
    defaults,
  };
}

function convertMesh(mesh: MeshParametersInput): APIMeshOptions {
  if (mesh.gridSize === GridSize.DYNAMIC) {
    return { type: 'dynamic' };
  }
  if (mesh.gridSize === GridSize.FIFTEEN) {
    return { type: 'fixed', width: 15, height: 15 };
  }
  return { type: 'fixed', width: mesh.x, height: mesh.y };
}

function convertMeshOptions(parameters: ResultSetParametersInput): APIMeshResultSetOptions {
  return {
    type: 'mesh',
    mesh: parameters.mesh ? convertMesh(parameters.mesh) : undefined,
    ...convertPerilsOptions(parameters),
  };
}

function convertScoresOptions(parameters: ResultSetParametersInput): APIScoresResultSetOptions {
  return {
    type: 'scores',
    data_version: parameters.csgVersion,
    perils: parameters.scores as string[],
    include_benchmarks: parameters.benchmarking?.enabled,
    benchmark_levels: parameters.benchmarking?.levels?.map((level) => level.toLowerCase()),
  };
}

function convertOptions(
  parameters: ResultSetParametersInput,
  type: ResultSetType,
): APIResultSetOptions {
  switch (type) {
    case ResultSetType.ECONOMIC_IMPACTS:
      return convertEIOptions(parameters);
    case ResultSetType.FLOOD_MESH:
      return convertMeshOptions(parameters);
    case ResultSetType.PERILS:
      return convertPerilsResultSetOptions(parameters);
    case ResultSetType.SCORES:
      return convertScoresOptions(parameters);
    case ResultSetType.PORTFOLIO_FINANCIAL:
      return {
        type: 'portfolio_financial',
        ei_version: parameters.eiVersion,
        damages_result_set: parameters.damagesResultSet as string,
      };

    default:
      throw new Error('Unexpected result set type.');
  }
}

export function convertInput(input: ResultSetInput): APICreateResultSetInput {
  return {
    name: input.name,
    options: convertOptions(input.parameters, input.type),
    portfolio: input.portfolioId,
  };
}
