import { FC, useRef, useState } from 'react';
import { Box, IconButton, useTheme } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import Modal from '../../../components/Modal';
import { StyledMediumLabel } from '../../../components/labels/StyledLabel';
import { CenteredRowStyle } from '../../../utils/styleUtils';
import LoadingIndicator from '../app-content/components/LoadingIndicator';

interface AiInterestFormModalProps {
  open: boolean;
  formUrl: string;
  onClose: () => void;
}
const AiInterestFormModal: FC<AiInterestFormModalProps> = ({ open, formUrl, onClose }) => {
  const theme = useTheme();
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const [isIFrameLoaded, setIsIFrameLoaded] = useState<boolean>(false);

  const handleIframeOnLoad = (): void => {
    setIsIFrameLoaded(true);
  };

  const handleClose = (): void => {
    setIsIFrameLoaded(false);
    onClose();
  };

  return (
    <Modal
      data-testid="ai-interest-modal"
      className="ai-interest-modal"
      open={open}
      sx={{
        position: 'relative',
        maxWidth: 'none',
      }}
      onClose={handleClose}
    >
      <Box
        sx={{
          ...CenteredRowStyle,
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: theme.spacing(2),
          paddingLeft: theme.spacing(1),
          position: 'absolute',
          width: `calc(100% - ${theme.spacing(3)})`,
        }}
      >
        <StyledMediumLabel variant="h5" noWrap>
          I&apos;m interested in Jupiter AI
        </StyledMediumLabel>
        <IconButton sx={{ color: 'inherit' }} onClick={handleClose}>
          <CloseIcon sx={{ fontSize: theme.spacing(3.75) }} />
        </IconButton>
      </Box>
      <iframe
        ref={iFrameRef}
        // Below eslint-disable statement because "allowTransparency" is needed for pardot forms
        // eslint-disable-next-line react/no-unknown-property
        allowTransparency
        src={formUrl}
        title="AI Interest Form"
        width="100%"
        height={isIFrameLoaded ? theme.spacing(68.75) : 0}
        // type="text/html"
        frameBorder="0"
        style={{
          border: 0,
          background: 'transparent',
          minWidth: theme.spacing(56.25),
          paddingTop: isIFrameLoaded ? theme.spacing(4.375) : 0,
        }}
        onLoad={handleIframeOnLoad}
      />
      <Box>
        {!isIFrameLoaded ? (
          <Box
            sx={{
              height: theme.spacing(68.75),
              width: theme.spacing(56.25),
            }}
          >
            <LoadingIndicator sx={{ border: 0 }} />
          </Box>
        ) : null}
      </Box>
    </Modal>
  );
};

export default AiInterestFormModal;
