import { get } from 'lodash';
import { CheckboxOption } from '../../components/checkbox/types';
import { DataStatus, DownloadFormatLabelUI } from '../../redux/slices/csgDataSlice';
import { reactKey } from '../../utils/reactUtils';
import { DownloadFormat } from '../../api/resultSets/types';

export const OUTPUT_FORMAT_OPTIONS: CheckboxOption[] = [
  {
    id: reactKey('rs-download-format'),
    selected: true,
    name: DownloadFormatLabelUI.STANDARD,
    testId: 'rs-download-format-standard',
  },
  {
    id: reactKey('rs-download-format'),
    selected: true,
    name: DownloadFormatLabelUI.TABLEAU,
    tooltip:
      'This output includes clear and informative headers. The formatting of the scenario labels and years are designed to be compatible with a wide range of business intelligence tools.',
    testId: 'rs-download-format-enhanced',
  },
];
export const dataItemIsLoading = (dataItem: { status: DataStatus }): boolean =>
  dataItem?.status === DataStatus.LOADING;

export const formatNameMapping = {
  [DownloadFormatLabelUI.STANDARD]: DownloadFormat.STANDARD,
  [DownloadFormatLabelUI.TABLEAU]: DownloadFormat.TABLEAU,
};

export const getInternalName = (item: CheckboxOption): DownloadFormat =>
  get(formatNameMapping, item.name);
