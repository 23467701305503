import { Box, MenuItem, Typography, useTheme } from '@mui/material';
import { ChangeEvent, FC, Fragment, ReactElement, useMemo } from 'react';
import InfoIconTooltip from '../../../components/InfoIconTooltip';
import Accordion from '../../../components/accordion/Accordion';
import FormList from '../../../components/form-list/FormList';
import TextInput from '../../../components/text-input/TextInput';
import { TextInputOption } from '../../../components/text-input/types';
import { LITE_TEXT_COLOR } from '../../../utils/styleUtils';
import { DamageAndLossLabelUI, ParameterOptionsUIDataTypeUI } from '../types';
import CheckboxList from '../../../components/checkbox/CheckboxList';
import {
  CheckboxOperation,
  CheckboxOption,
  UpdateCheckboxInstructions,
} from '../../../components/checkbox/types';
import {
  financialMetricsIsEnabled,
  workerProductivityIsEnabled,
  wildfireLossIsEnabled,
  costOfWaterIsEnabled,
  portfolioLossMetricsIsEnabled,
} from '../builder';
import { INPUT_CHAR_RESTRICTED } from '../constants';

interface DamageAndLossOptionsProps {
  accordionTitle: string;
  damageAndLossFormTitle: string;
  damageAndLossValues: TextInputOption[];
  setDamageAndLossValues: (instructions: UpdateTextFormInstructions) => void;
  lossFormTitle: string;
  lossCalculationValues: TextInputOption[];
  setLossCalculationValues: (instructions: UpdateTextFormInstructions) => void;
  advParametersFormTitle: string;
  advParameterValues: TextInputOption[];
  setAdvParameterValues: (instructions: UpdateTextFormInstructions) => void;
  workerProductivityValues: TextInputOption[];
  setWorkerProductivityValues: (instructions: UpdateTextFormInstructions) => void;
  workerProductivityList: CheckboxOption[];
  setWorkerProductivityList: (instructions: UpdateCheckboxInstructions) => void;
  wildfireLossList: CheckboxOption[];
  setWildfireLossList: (instructions: UpdateCheckboxInstructions) => void;
  wildfireLossValues: TextInputOption[];
  setWildfireLossValues: (instructions: UpdateTextFormInstructions) => void;
  costOfWaterList: CheckboxOption[];
  setCostOfWaterList: (instructions: UpdateCheckboxInstructions) => void;
  costOfWaterValues: TextInputOption[];
  setCostOfWaterValues: (instructions: UpdateTextFormInstructions) => void;
  portfolioLossMetricsList: CheckboxOption[];
  setPortfolioLossMetricsList: (instructions: UpdateCheckboxInstructions) => void;
  financialFormTitle: string;
  financialParamValues: TextInputOption[];
  setFinancialParamValues: (instructions: UpdateTextFormInstructions) => void;
  financialMetricsList: CheckboxOption[];
  setFinancialMetricsList: (instructions: UpdateCheckboxInstructions) => void;
}

const renderFormLabel = ({ name, tooltip = '' }: TextInputOption): ReactElement => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Typography variant="h6">{name}</Typography>
      {tooltip.length > 0 && (
        <InfoIconTooltip tooltip={tooltip} placement="right" sx={{ marginLeft: '4px' }} />
      )}
    </Box>
  );
};

export interface UpdateTextFormInstructions {
  id: string;
  value: string;
}

const getMuiTextInputType = (dataTypeUI: ParameterOptionsUIDataTypeUI): string | undefined => {
  switch (dataTypeUI) {
    case 'STRING':
      return 'string';
    case 'INT':
      return 'number';
    case 'FLOAT':
      return 'number';
    default:
      return undefined;
  }
};
const DamageAndLossOptions: FC<DamageAndLossOptionsProps> = ({
  accordionTitle,
  damageAndLossFormTitle,
  damageAndLossValues,
  setDamageAndLossValues,
  lossFormTitle,
  lossCalculationValues,
  setLossCalculationValues,
  advParametersFormTitle,
  advParameterValues,
  setAdvParameterValues,
  workerProductivityValues,
  setWorkerProductivityValues,
  workerProductivityList,
  setWorkerProductivityList,
  wildfireLossList,
  setWildfireLossList,
  wildfireLossValues,
  setWildfireLossValues,
  costOfWaterList,
  setCostOfWaterList,
  costOfWaterValues,
  setCostOfWaterValues,
  portfolioLossMetricsList,
  setPortfolioLossMetricsList,
  financialFormTitle,
  financialParamValues,
  setFinancialParamValues,
  financialMetricsList,
  setFinancialMetricsList,
}) => {
  const theme = useTheme();
  const handleDamageFormChange = (id: string, value: string): void => {
    setDamageAndLossValues({ id, value });
  };

  const handleLossCalculationFormChange = (id: string, value: string): void => {
    setLossCalculationValues({ id, value });
  };

  const handleAdvParameterFormChange = (id: string, value: string): void => {
    setAdvParameterValues({ id, value });
  };

  const handleWorkerProductivityFormChange = (id: string, value: string): void => {
    setWorkerProductivityValues({ id, value });
  };

  const handleWildfireLossFormChange = (id: string, value: string): void => {
    setWildfireLossValues({ id, value });
  };

  const handleWaterConsumptionFormChange = (id: string, value: string): void => {
    setCostOfWaterValues({ id, value });
  };

  const handleFinancialParamsFormChange = (id: string, value: string): void => {
    setFinancialParamValues({ id, value });
  };

  const isFinancialMetricsDisabled = useMemo(
    () => !financialMetricsIsEnabled({ financialMetrics: financialMetricsList }),
    [financialMetricsList],
  );

  const isWorkerProductivityDisabled = useMemo(
    () => !workerProductivityIsEnabled({ workerProductivity: workerProductivityList }),
    [workerProductivityList],
  );

  const isWildfireLossDisabled = useMemo(
    () => !wildfireLossIsEnabled({ wildfireLoss: wildfireLossList }),
    [wildfireLossList],
  );

  const isCostOfWaterDisabled = useMemo(
    () => !costOfWaterIsEnabled({ costOfWater: costOfWaterList }),
    [costOfWaterList],
  );

  const isPortfolioLossMetricsDisabled = useMemo(
    () => !portfolioLossMetricsIsEnabled({ portfolioLossMetrics: portfolioLossMetricsList }),
    [portfolioLossMetricsList],
  );

  const filteredPortfolioLossMetricsList = useMemo(
    () =>
      portfolioLossMetricsList.map((item) => ({
        ...item,
        disabled: isFinancialMetricsDisabled || item.disabled,
      })),
    [isFinancialMetricsDisabled, portfolioLossMetricsList],
  );

  const restrictOnCharInput = (
    event: React.KeyboardEvent<HTMLDivElement>,
    dataTypeUI: string,
  ): void => {
    if (INPUT_CHAR_RESTRICTED[`${dataTypeUI}`].includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <Accordion
      expandedDefault
      accordionTitle={accordionTitle}
      accordionTitleTooltip="If you have already provided these parameters in the uploaded CSV file, you can safely skip this section."
      data-testid="ei-options-panel"
    >
      <Box sx={{ margin: `${theme.spacing(1.5)} 0`, color: LITE_TEXT_COLOR }}>
        <FormList
          disableBottomBorder
          label={damageAndLossFormTitle}
          sx={{ maxWidth: theme.spacing(131) }}
          data-testid="damage-and-loss-form"
        >
          {damageAndLossValues.map((option) => {
            const {
              disabled = false,
              forbidden = false,
              id,
              name,
              value,
              selectionList = [],
              dataTypeUI,
              placeholder,
              tooltip = '',
            } = option;
            return (
              <Fragment key={id}>
                {name === DamageAndLossLabelUI.OCCUPANCY_SCHEME && (
                  <TextInput
                    disabled={disabled || forbidden || selectionList.length === 0}
                    formLabel={renderFormLabel(option)}
                    type={getMuiTextInputType(dataTypeUI)}
                    value={value}
                    placeholder={placeholder}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                      handleDamageFormChange(id, event.target.value);
                    }}
                    formControlProps={{
                      sx: {
                        width: theme.spacing(25),
                      },
                    }}
                    select={selectionList.length > 0}
                    data-testid="occupancy-scheme"
                  >
                    {selectionList.map((item) => {
                      const { name: itemName, value: itemValue, id: itemId } = item;
                      return (
                        <MenuItem value={itemValue} key={itemId}>
                          {itemName}
                        </MenuItem>
                      );
                    })}
                  </TextInput>
                )}
                {name === DamageAndLossLabelUI.OCCUPANCY_CODE && (
                  <TextInput
                    disabled={disabled || forbidden}
                    formLabel={renderFormLabel(option)}
                    type={getMuiTextInputType(dataTypeUI)}
                    value={value}
                    placeholder={placeholder}
                    onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                      handleDamageFormChange(id, event.target.value)
                    }
                    formControlProps={{
                      sx: {
                        width: theme.spacing(25),
                      },
                    }}
                    data-testid="occupancy-code"
                  />
                )}
                {name === DamageAndLossLabelUI.NUMBER_OF_STORIES && (
                  <TextInput
                    disabled={disabled || forbidden}
                    formLabel={renderFormLabel(option)}
                    type={getMuiTextInputType(dataTypeUI)}
                    value={value}
                    placeholder={placeholder}
                    onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                      handleDamageFormChange(id, event.target.value)
                    }
                    formControlProps={{
                      sx: {
                        width: theme.spacing(25),
                      },
                    }}
                    data-testid="num-of-stories"
                  />
                )}
                {name === DamageAndLossLabelUI.BASEMENT_CODE && (
                  <TextInput
                    disabled={disabled || forbidden || selectionList.length === 0}
                    formLabel={renderFormLabel(option)}
                    type={getMuiTextInputType(dataTypeUI)}
                    value={value}
                    placeholder={placeholder}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                      handleDamageFormChange(id, event.target.value)
                    }
                    formControlProps={{
                      sx: {
                        width: theme.spacing(25),
                      },
                    }}
                    select={selectionList.length > 0}
                    data-testid="basement-code"
                  >
                    {selectionList.map((item) => {
                      const { name: itemName, value: itemValue, id: itemId } = item;
                      return (
                        <MenuItem value={itemValue} key={itemId}>
                          {itemName}
                        </MenuItem>
                      );
                    })}
                  </TextInput>
                )}
                {name === DamageAndLossLabelUI.FIRST_FLOOR_ELEVATION && (
                  <TextInput
                    disabled={disabled || forbidden}
                    formLabel={renderFormLabel(option)}
                    type={getMuiTextInputType(dataTypeUI)}
                    value={value}
                    placeholder={placeholder}
                    onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                      handleDamageFormChange(id, event.target.value)
                    }
                    formControlProps={{
                      sx: {
                        width: theme.spacing(25),
                      },
                    }}
                    data-testid="first-floor-elevation"
                  />
                )}
                {name === DamageAndLossLabelUI.FLOOR_AREA_SQM && (
                  <TextInput
                    disabled={disabled || forbidden}
                    formLabel={renderFormLabel(option)}
                    type={getMuiTextInputType(dataTypeUI)}
                    value={value}
                    placeholder={placeholder}
                    onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                      handleDamageFormChange(id, event.target.value)
                    }
                    formControlProps={{
                      sx: {
                        width: theme.spacing(25),
                      },
                    }}
                    data-testid="floor-area-sqm"
                  />
                )}
              </Fragment>
            );
          })}
        </FormList>
        <FormList
          disableTopBorderRadius
          disableBottomBorder
          label={lossFormTitle}
          sx={{ maxWidth: theme.spacing(131) }}
          data-testid="loss-form"
        >
          {lossCalculationValues.map((option) => {
            const {
              disabled = false,
              forbidden = false,
              id,
              name,
              value,
              dataTypeUI,
              placeholder,
              tooltip = '',
            } = option;

            return (
              <Fragment key={id}>
                {name === DamageAndLossLabelUI.TOTAL_VALUE && (
                  <>
                    <TextInput
                      disabled={disabled || forbidden}
                      formLabel={renderFormLabel(option)}
                      type={getMuiTextInputType(dataTypeUI)}
                      value={value}
                      placeholder={placeholder}
                      onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                        handleLossCalculationFormChange(id, event.target.value)
                      }
                      formControlProps={{
                        sx: {
                          width: theme.spacing(25),
                        },
                      }}
                      data-testid="total-value"
                    />
                    <Typography
                      variant="h6"
                      sx={{
                        display: 'flex',
                        alignItems: 'end',
                        height: 'auto',
                        width: theme.spacing(3.5),
                        marginBottom: theme.spacing(1.5),
                        padding: `0 ${theme.spacing(0.5)}`,
                      }}
                    >
                      OR
                    </Typography>
                  </>
                )}

                {name === DamageAndLossLabelUI.BUILDING_VALUE && (
                  <TextInput
                    disabled={disabled || forbidden}
                    formLabel={renderFormLabel(option)}
                    type={getMuiTextInputType(dataTypeUI)}
                    value={value}
                    placeholder={placeholder}
                    onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                      handleLossCalculationFormChange(id, event.target.value)
                    }
                    formControlProps={{
                      sx: {
                        width: theme.spacing(25),
                      },
                    }}
                    data-testid="building-value"
                  />
                )}
                {name === DamageAndLossLabelUI.CONTENTS_VALUE && (
                  <TextInput
                    disabled={disabled || forbidden}
                    formLabel={renderFormLabel(option)}
                    type={getMuiTextInputType(dataTypeUI)}
                    value={value}
                    placeholder={placeholder}
                    onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                      handleLossCalculationFormChange(id, event.target.value)
                    }
                    formControlProps={{
                      sx: {
                        width: theme.spacing(25),
                      },
                    }}
                    data-testid="contents-value"
                  />
                )}
                {name === DamageAndLossLabelUI.INVENTORY_VALUE && (
                  <TextInput
                    disabled={disabled || forbidden}
                    formLabel={renderFormLabel(option)}
                    type={getMuiTextInputType(dataTypeUI)}
                    value={value}
                    placeholder={placeholder}
                    onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                      handleLossCalculationFormChange(id, event.target.value)
                    }
                    formControlProps={{
                      sx: {
                        width: theme.spacing(25),
                      },
                    }}
                    data-testid="inventory-value"
                  />
                )}
                {name === DamageAndLossLabelUI.DOWNTIME_VALUE && (
                  <TextInput
                    disabled={disabled || forbidden}
                    formLabel={renderFormLabel(option)}
                    type={getMuiTextInputType(dataTypeUI)}
                    value={value}
                    placeholder={placeholder}
                    onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                      handleLossCalculationFormChange(id, event.target.value)
                    }
                    formControlProps={{
                      sx: {
                        width: theme.spacing(25),
                      },
                    }}
                    data-testid="downtime-value"
                  />
                )}
                {name === DamageAndLossLabelUI.REMOTE_WORK_RATIO && (
                  <TextInput
                    disabled={disabled || forbidden}
                    formLabel={renderFormLabel(option)}
                    type={getMuiTextInputType(dataTypeUI)}
                    value={value}
                    placeholder={placeholder}
                    onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                      handleLossCalculationFormChange(id, event.target.value)
                    }
                    formControlProps={{
                      sx: {
                        width: theme.spacing(25),
                        marginLeft: theme.spacing(4),
                      },
                    }}
                    data-testid="remote-work-ratio"
                  />
                )}
              </Fragment>
            );
          })}
        </FormList>
        <FormList
          disableTopBorderRadius
          isColumn
          label={advParametersFormTitle}
          sx={{ maxWidth: theme.spacing(131), alignItems: 'start' }}
          data-testid="adv-param-form"
        >
          <Box>
            {advParameterValues.map((option) => {
              const {
                disabled = false,
                forbidden = false,
                id,
                name,
                value,
                selectionList = [],
                dataTypeUI,
                placeholder,
                tooltip = '',
              } = option;

              return (
                <Fragment key={id}>
                  {name === DamageAndLossLabelUI.REMOTE_WORK_RATIO && (
                    <TextInput
                      disabled={disabled || forbidden}
                      formLabel={renderFormLabel(option)}
                      type={getMuiTextInputType(dataTypeUI)}
                      value={value}
                      placeholder={placeholder}
                      onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                        handleAdvParameterFormChange(id, event.target.value)
                      }
                      formControlProps={{
                        sx: {
                          width: theme.spacing(25),
                        },
                      }}
                      data-testid="remote-work-ratio"
                    />
                  )}
                  {name === DamageAndLossLabelUI.FINANCIAL_BASE_YEAR && (
                    <TextInput
                      disabled={disabled || forbidden || selectionList.length === 0}
                      formLabel={renderFormLabel(option)}
                      type={getMuiTextInputType(dataTypeUI)}
                      value={value}
                      placeholder={placeholder}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                        handleAdvParameterFormChange(id, event.target.value)
                      }
                      formControlProps={{
                        sx: {
                          width: theme.spacing(25),
                        },
                      }}
                      select={selectionList.length > 0}
                      data-testid="financial-base-year"
                    >
                      {selectionList.map((item) => {
                        const { name: itemName, value: itemValue, id: itemId } = item;
                        return (
                          <MenuItem value={itemValue} key={itemId}>
                            {itemName}
                          </MenuItem>
                        );
                      })}
                    </TextInput>
                  )}
                </Fragment>
              );
            })}
          </Box>
          <Box
            sx={{
              marginBottom: theme.spacing(0.5),
              marginTop: theme.spacing(1.5),
              color: LITE_TEXT_COLOR,
            }}
          >
            <CheckboxList
              options={workerProductivityList}
              onOptionChange={(id: string) => {
                setWorkerProductivityList({ operation: CheckboxOperation.Toggle, id });
              }}
              noMarginBottom // NOTE: No margin for this since `marginBottom` is applied on Box
            />
          </Box>
          <Box>
            {workerProductivityValues.map((option) => {
              const {
                disabled = false,
                forbidden = false,
                id,
                name,
                value,
                selectionList = [],
                dataTypeUI,
                placeholder,
                tooltip = '',
              } = option;

              return (
                <Fragment key={id}>
                  {name === DamageAndLossLabelUI.ELECTRICITY_COST && (
                    <TextInput
                      disabled={disabled || forbidden || isWorkerProductivityDisabled}
                      formLabel={renderFormLabel(option)}
                      type={getMuiTextInputType(dataTypeUI)}
                      value={value}
                      placeholder={placeholder}
                      onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                        handleWorkerProductivityFormChange(id, event.target.value)
                      }
                      formControlProps={{
                        sx: {
                          width: theme.spacing(25),
                        },
                      }}
                      data-testid="electricity-cost"
                    />
                  )}

                  {name === DamageAndLossLabelUI.COOLING_SYSTEM_PROBABILITY && (
                    <TextInput
                      disabled={disabled || forbidden || isWorkerProductivityDisabled}
                      formLabel={renderFormLabel(option)}
                      type={getMuiTextInputType(dataTypeUI)}
                      value={value}
                      placeholder={placeholder}
                      onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                        handleWorkerProductivityFormChange(id, event.target.value)
                      }
                      formControlProps={{
                        sx: {
                          width: theme.spacing(25),
                        },
                      }}
                      data-testid="cooling-system-probability"
                    />
                  )}
                  {name === DamageAndLossLabelUI.WORK_INTENSITY && (
                    <TextInput
                      disabled={
                        disabled ||
                        forbidden ||
                        isWorkerProductivityDisabled ||
                        selectionList.length === 0
                      }
                      formLabel={renderFormLabel(option)}
                      type={getMuiTextInputType(dataTypeUI)}
                      value={value}
                      placeholder={placeholder}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                        handleWorkerProductivityFormChange(id, event.target.value)
                      }
                      formControlProps={{
                        sx: {
                          width: theme.spacing(25),
                        },
                      }}
                      select={selectionList.length > 0}
                      data-testid="work-intensity"
                    >
                      {selectionList.map((item) => {
                        const { name: itemName, value: itemValue, id: itemId } = item;
                        return (
                          <MenuItem value={itemValue} key={itemId}>
                            {itemName}
                          </MenuItem>
                        );
                      })}
                    </TextInput>
                  )}
                </Fragment>
              );
            })}
          </Box>
          <Box
            sx={{
              marginBottom: theme.spacing(0.5),
              marginTop: theme.spacing(1.5),
              color: LITE_TEXT_COLOR,
            }}
          >
            <CheckboxList
              options={wildfireLossList}
              onOptionChange={(id: string) => {
                setWildfireLossList({ operation: CheckboxOperation.Toggle, id });
              }}
              noMarginBottom // NOTE: No margin for this since `marginBottom` is applied on Box
            />
          </Box>
          <Box>
            {wildfireLossValues.map((option) => {
              const {
                disabled = false,
                forbidden = false,
                id,
                name,
                value,
                selectionList = [],
                dataTypeUI,
                placeholder,
                tooltip = '',
              } = option;

              return (
                <Fragment key={id}>
                  {name === DamageAndLossLabelUI.WINDOW_PANE && (
                    <TextInput
                      disabled={
                        disabled ||
                        forbidden ||
                        isWildfireLossDisabled ||
                        selectionList.length === 0
                      }
                      formLabel={renderFormLabel(option)}
                      type={getMuiTextInputType(dataTypeUI)}
                      value={value}
                      placeholder={placeholder}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                        handleWildfireLossFormChange(id, event.target.value)
                      }
                      formControlProps={{
                        sx: {
                          width: theme.spacing(25),
                        },
                      }}
                      select={selectionList.length > 0}
                      data-testid="window-pane"
                    >
                      {selectionList.map((item) => {
                        const { name: itemName, value: itemValue, id: itemId } = item;
                        return (
                          <MenuItem value={itemValue} key={itemId}>
                            {itemName}
                          </MenuItem>
                        );
                      })}
                    </TextInput>
                  )}

                  {name === DamageAndLossLabelUI.VENT_TYPE && (
                    <TextInput
                      disabled={
                        disabled ||
                        forbidden ||
                        isWildfireLossDisabled ||
                        selectionList.length === 0
                      }
                      formLabel={renderFormLabel(option)}
                      type={getMuiTextInputType(dataTypeUI)}
                      value={value}
                      placeholder={placeholder}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                        handleWildfireLossFormChange(id, event.target.value)
                      }
                      formControlProps={{
                        sx: {
                          width: theme.spacing(25),
                        },
                      }}
                      select={selectionList.length > 0}
                      data-testid="vent-type"
                    >
                      {selectionList.map((item) => {
                        const { name: itemName, value: itemValue, id: itemId } = item;
                        return (
                          <MenuItem value={itemValue} key={itemId}>
                            {itemName}
                          </MenuItem>
                        );
                      })}
                    </TextInput>
                  )}

                  {name === DamageAndLossLabelUI.ROOF_COVER && (
                    <TextInput
                      disabled={
                        disabled ||
                        forbidden ||
                        isWildfireLossDisabled ||
                        selectionList.length === 0
                      }
                      formLabel={renderFormLabel(option)}
                      type={getMuiTextInputType(dataTypeUI)}
                      value={value}
                      placeholder={placeholder}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                        handleWildfireLossFormChange(id, event.target.value)
                      }
                      formControlProps={{
                        sx: {
                          width: theme.spacing(25),
                        },
                      }}
                      select={selectionList.length > 0}
                      data-testid="roof-cover"
                    >
                      {selectionList.map((item) => {
                        const { name: itemName, value: itemValue, id: itemId } = item;
                        return (
                          <MenuItem value={itemValue} key={itemId}>
                            {itemName}
                          </MenuItem>
                        );
                      })}
                    </TextInput>
                  )}
                </Fragment>
              );
            })}
          </Box>
          <Box
            sx={{
              marginBottom: theme.spacing(0.5),
              marginTop: theme.spacing(1.5),
              color: LITE_TEXT_COLOR,
            }}
          >
            <CheckboxList
              options={costOfWaterList}
              onOptionChange={(id: string) => {
                setCostOfWaterList({ operation: CheckboxOperation.Toggle, id });
              }}
              noMarginBottom // NOTE: No margin for this since `marginBottom` is applied on Box
            />
          </Box>
          <Box>
            {costOfWaterValues.map((option) => {
              const {
                disabled = false,
                forbidden = false,
                id,
                name,
                value,
                selectionList = [],
                dataTypeUI,
                placeholder,
                tooltip = '',
              } = option;

              return (
                <Fragment key={id}>
                  {name === DamageAndLossLabelUI.WATER_CONSUMPTION && (
                    <TextInput
                      disabled={disabled || forbidden || isCostOfWaterDisabled}
                      formLabel={renderFormLabel(option)}
                      type={getMuiTextInputType(dataTypeUI)}
                      value={value}
                      placeholder={placeholder}
                      onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                        handleWaterConsumptionFormChange(id, event.target.value)
                      }
                      formControlProps={{
                        sx: {
                          width: theme.spacing(25),
                        },
                      }}
                      data-testid="water-consumption"
                    />
                  )}
                  {name === DamageAndLossLabelUI.WATER_SHADOW_PRICE_RATIO && (
                    <TextInput
                      disabled={disabled || forbidden || isCostOfWaterDisabled}
                      formLabel={renderFormLabel(option)}
                      type={getMuiTextInputType(dataTypeUI)}
                      value={value}
                      placeholder={placeholder}
                      onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                        handleWaterConsumptionFormChange(id, event.target.value)
                      }
                      formControlProps={{
                        sx: {
                          width: theme.spacing(25),
                        },
                      }}
                      data-testid="water-shadow-price-ratio"
                    />
                  )}
                </Fragment>
              );
            })}
          </Box>
        </FormList>

        <Accordion
          expandedDefault
          accordionTitle="Financial Metrics"
          accordionDetailsSx={{ paddingLeft: 0 }}
          data-testid="financial-metrics-panel"
        >
          <Box
            sx={{
              marginBottom: theme.spacing(2.5),
              color: LITE_TEXT_COLOR,
            }}
          >
            <CheckboxList
              options={financialMetricsList}
              onOptionChange={(id: string) => {
                setFinancialMetricsList({ operation: CheckboxOperation.Toggle, id });
                // Deselect option to generate portfolio-level result-sets
                setPortfolioLossMetricsList({ operation: CheckboxOperation.DeselectAll });
              }}
              noMarginBottom // NOTE: No margin for this since `marginBottom` is applied on Box
            />
          </Box>
          <Box sx={{ margin: `${theme.spacing(1.5)} 0`, color: LITE_TEXT_COLOR }}>
            <FormList
              label={financialFormTitle}
              sx={{ maxWidth: theme.spacing(131) }}
              data-testid="fin-param-form"
            >
              <Box>
                {financialParamValues.map((option) => {
                  const {
                    disabled = false,
                    forbidden = false,
                    id,
                    name,
                    value,
                    selectionList = [],
                    dataTypeUI,
                    placeholder,
                    tooltip = '',
                  } = option;

                  return (
                    <Fragment key={id}>
                      {name === DamageAndLossLabelUI.ANNUAL_GROWTH_RATE && (
                        <TextInput
                          disabled={disabled || forbidden || isFinancialMetricsDisabled}
                          formLabel={renderFormLabel(option)}
                          type={getMuiTextInputType(dataTypeUI)}
                          value={value}
                          placeholder={placeholder}
                          onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            handleFinancialParamsFormChange(id, event.target.value)
                          }
                          formControlProps={{
                            sx: {
                              width: theme.spacing(25),
                            },
                          }}
                          data-testid="annual-growth-rate"
                        />
                      )}

                      {name === DamageAndLossLabelUI.ANNUAL_VOLATILITY_RATE && (
                        <TextInput
                          disabled={disabled || forbidden || isFinancialMetricsDisabled}
                          formLabel={renderFormLabel(option)}
                          type={getMuiTextInputType(dataTypeUI)}
                          value={value}
                          placeholder={placeholder}
                          onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            handleFinancialParamsFormChange(id, event.target.value)
                          }
                          formControlProps={{
                            sx: {
                              width: theme.spacing(25),
                            },
                          }}
                          data-testid="annual-volatility-rate"
                        />
                      )}
                      {name === DamageAndLossLabelUI.SUB_INDUSTRY_CODE && (
                        <TextInput
                          disabled={disabled || forbidden || isFinancialMetricsDisabled}
                          formLabel={renderFormLabel(option)}
                          type={getMuiTextInputType(dataTypeUI)}
                          value={value}
                          placeholder={placeholder}
                          onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            handleFinancialParamsFormChange(id, event.target.value)
                          }
                          formControlProps={{
                            sx: {
                              width: theme.spacing(25),
                            },
                          }}
                          data-testid="sub-industry-code"
                        />
                      )}
                      {name === DamageAndLossLabelUI.SALES_MARGIN && (
                        <TextInput
                          disabled={disabled || forbidden || isFinancialMetricsDisabled}
                          formLabel={renderFormLabel(option)}
                          type={getMuiTextInputType(dataTypeUI)}
                          value={value}
                          placeholder={placeholder}
                          onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            handleFinancialParamsFormChange(id, event.target.value)
                          }
                          formControlProps={{
                            sx: {
                              width: theme.spacing(25),
                            },
                          }}
                          data-testid="sales-margin"
                        />
                      )}
                      {name === DamageAndLossLabelUI.DISCOUNT_RATE && (
                        <TextInput
                          disabled={disabled || forbidden || isFinancialMetricsDisabled}
                          formLabel={renderFormLabel(option)}
                          type={getMuiTextInputType(dataTypeUI)}
                          value={value}
                          placeholder={placeholder}
                          onKeyDown={(event) => restrictOnCharInput(event, dataTypeUI)}
                          onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            handleFinancialParamsFormChange(id, event.target.value)
                          }
                          formControlProps={{
                            sx: {
                              width: theme.spacing(25),
                            },
                          }}
                          data-testid="discount-rate"
                        />
                      )}
                    </Fragment>
                  );
                })}
              </Box>
              <Box
                sx={{
                  marginTop: theme.spacing(1.5),
                  color: LITE_TEXT_COLOR,
                }}
              >
                <CheckboxList
                  options={filteredPortfolioLossMetricsList}
                  onOptionChange={(id: string) => {
                    setPortfolioLossMetricsList({ operation: CheckboxOperation.Toggle, id });
                  }}
                  noMarginBottom // NOTE: No margin for this since `marginBottom` is applied on Box
                />
              </Box>
            </FormList>
          </Box>
        </Accordion>
      </Box>
    </Accordion>
  );
};

export default DamageAndLossOptions;
