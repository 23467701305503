import { DATE, UUID } from '../types';

/* NOTE: The interfaces below are meant to capture the output of ./index.ts.GET_RESULT_SETS query */
export interface ResultSetMetadata {
  numberOfLocations: number;
  numberOfRows: number;
}

export enum BenchmarkingLevel {
  COUNTRY = 'COUNTRY',
  ADMIN1 = 'ADMIN1',
  ADMIN2 = 'ADMIN2',
}

export interface BenchmarkingParametersInput {
  enabled?: boolean;
  levels?: BenchmarkingLevel[] | null;
}

export enum DamageAndLossField {
  BASEMENT_CODE = 'basementCode',
  BUILDING_VALUE = 'buildingValue',
  CONTENTS_VALUE = 'contentsValue',
  INVENTORY_VALUE = 'inventoryValue',
  NUMBER_OF_STORIES = 'numberOfStories',
  OCCUPANCY_CODE = 'occupancyCode',
  OCCUPANCY_SCHEME = 'occupancyScheme',
  TOTAL_VALUE = 'totalValue',
  // 3.0.0 - 3.1.0
  SUB_INDUSTRY_CODE = 'subIndustryCode',
  FIRST_FLOOR_ELEVATION = 'firstFloorElevation',
  FLOOR_AREA_SQM = 'floorAreaSqm',
  DOWNTIME_VALUE = 'downtimeValue',
  ELECTRICITY_COST = 'electricityCost',
  ELECTRICITY_COST_USD = 'electricityCostUsd',
  COOLING_SYSTEM_PROBABILITY = 'coolingSystemProbability',
  WORK_INTENSITY = 'workIntensity',
  REMOTE_WORK_RATIO = 'remoteWorkRatio',
  ANNUAL_GROWTH_RATE = 'annualGrowth',
  ANNUAL_VOLATILITY_RATE = 'annualVolatility',
  SALES_MARGIN = 'salesMargin',
  DISCOUNT_RATE = 'discountRate',
  FINANCIAL_BASE_YEAR = 'financialBaseYear',
  // 3.1.0
  WINDOW_PANE = 'windowPane',
  VENT_TYPE = 'ventType',
  ROOF_COVER = 'roofCover',
  WATER_CONSUMPTION = 'waterConsumption',
  WATER_SHADOW_PRICE_RATIO = 'waterShadowPriceRatio',
}

export interface DamageAndLossDefaults {
  [DamageAndLossField.BASEMENT_CODE]?: number;
  [DamageAndLossField.BUILDING_VALUE]?: number;
  [DamageAndLossField.CONTENTS_VALUE]?: number;
  [DamageAndLossField.INVENTORY_VALUE]?: number;
  [DamageAndLossField.NUMBER_OF_STORIES]?: number;
  [DamageAndLossField.OCCUPANCY_CODE]?: number;
  [DamageAndLossField.OCCUPANCY_SCHEME]?: string;
  [DamageAndLossField.TOTAL_VALUE]?: number;
  // 3.0.0 - 3.1.0
  [DamageAndLossField.SUB_INDUSTRY_CODE]?: number;
  [DamageAndLossField.FIRST_FLOOR_ELEVATION]?: number;
  [DamageAndLossField.FLOOR_AREA_SQM]?: number;
  [DamageAndLossField.DOWNTIME_VALUE]?: number;
  [DamageAndLossField.ELECTRICITY_COST]?: number;
  [DamageAndLossField.ELECTRICITY_COST_USD]?: number;
  [DamageAndLossField.COOLING_SYSTEM_PROBABILITY]?: number;
  [DamageAndLossField.WORK_INTENSITY]?: string;
  [DamageAndLossField.REMOTE_WORK_RATIO]?: number;
  [DamageAndLossField.ANNUAL_GROWTH_RATE]?: number;
  [DamageAndLossField.ANNUAL_VOLATILITY_RATE]?: number;
  [DamageAndLossField.SALES_MARGIN]?: number;
  [DamageAndLossField.DISCOUNT_RATE]?: number;
  [DamageAndLossField.FINANCIAL_BASE_YEAR]?: number;
  // 3.1.0
  [DamageAndLossField.WINDOW_PANE]?: string;
  [DamageAndLossField.VENT_TYPE]?: string;
  [DamageAndLossField.ROOF_COVER]?: string;
  [DamageAndLossField.WATER_CONSUMPTION]?: number;
  [DamageAndLossField.WATER_SHADOW_PRICE_RATIO]?: number;
}

export interface DamageAndLossParametersInput {
  defaultFields?: DamageAndLossDefaults | null;
  enabled?: boolean;
}

export type Year = number;

export enum Peril {
  WIND = 'wind',
  COLD = 'cold',
  PRECIP = 'precip',
  HEAT = 'heat',
  COMBINED_FLOOD = 'combinedFlood',
  HAIL = 'hail',
  DROUGHT = 'drought',
  FIRE = 'fire',
  // 3.0.0 - 3.1.0
  COASTAL_FLOOD = 'coastalFlood',
  PLUVIAL_FLOOD = 'pluvialFlood',
  FLUVIAL_FLOOD = 'fluvialFlood',
}

export enum ScorePeril {
  ALL_PERILS = 'allPerils',
  WIND = 'wind',
  COLD = 'cold',
  PRECIP = 'precip',
  HEAT = 'heat',
  COMBINED_FLOOD = 'combinedFlood',
  HAIL = 'hail',
  DROUGHT = 'drought',
  FIRE = 'fire',
}

export enum Scenario {
  BASELINE = 'baseline',
  SSP_126 = 'ssp126',
  SSP_245 = 'ssp245',
  SSP_585 = 'ssp585',
}

export interface FloodDefenseParametersInput {
  enabled?: boolean;
  zeroOutDefendedLocations?: boolean;
}

export enum GridType {
  DYNAMIC = 'DYNAMIC',
  REGULAR = 'REGULAR',
}

export enum GridSize {
  DYNAMIC = 'DYNAMIC',
  FIFTEEN = 'FIFTEEN',
}

export interface MeshParametersInput {
  enabled?: boolean;
  gridType?: GridType;
  gridSize?: GridSize;
  x?: number | null;
  y?: number | null;
}

export type BenchmarkingInputType = BenchmarkingParametersInput | null;
export type CsgVersionInputType = string;
export type DamageAndLossInputType = DamageAndLossParametersInput | null;
export type FloodDefenseInputType = FloodDefenseParametersInput | null;
export type FloodMeshInputType = MeshParametersInput | null;
export type PerilsInputType = Peril[] | null;
export type ScenariosInputType = Scenario[] | null;
export type ScorePerilsInputType = ScorePeril[] | null;
export type YearsInputType = Year[] | null;

export interface ResultSetParametersInput {
  benchmarking?: BenchmarkingInputType;
  csgVersion: CsgVersionInputType;
  eiModules?: string[];
  damageAndLoss?: DamageAndLossInputType;
  floodDefense?: FloodDefenseInputType;
  mesh?: FloodMeshInputType;
  perils?: PerilsInputType;
  scenarios?: ScenariosInputType;
  scores?: ScorePerilsInputType;
  years?: YearsInputType;
  eiVersion?: string;
  damagesResultSet?: UUID;
  // This will ONLY be present during query submission
  portfolioLossMetrics?: boolean;
}

export enum ResultSetStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

export enum ResultSetType {
  ECONOMIC_IMPACTS = 'LOSS',
  FLOOD_MESH = 'MESH',
  PERILS = 'PERILS',
  SCORES = 'SCORES',
  PORTFOLIO_FINANCIAL = 'PORTFOLIO_FINANCIAL',
}

export interface ResultSet {
  id: UUID;
  createdAt: DATE;
  // customerId: UUID;
  metadata: ResultSetMetadata;
  // metadataId: UUID;
  name: string;
  parameters: ResultSetParametersInput;
  portfolioId: UUID;
  status: ResultSetStatus;
  type: ResultSetType;
  updatedAt: DATE;
  userId: UUID;
}

export interface ParameterOption {
  choices: string[];
  defaults: string[];
}

export interface ResultSetParameterOptions {
  benchmarking: {
    enabled: ParameterOption;
    levels: ParameterOption;
  };
  csgVersion: ParameterOption;
  damageAndLoss: {
    defaultFields: {
      basementCode: ParameterOption;
      buildingValue: ParameterOption;
      contentsValue: ParameterOption;
      inventoryValue: ParameterOption;
      numberOfStories: ParameterOption;
      occupancyCode: ParameterOption;
      occupancyScheme: ParameterOption;
      totalValue: ParameterOption;
    };
    enabled: ParameterOption;
  };
  floodDefense: {
    enabled: ParameterOption;
    zeroOutDefendedLocations: ParameterOption;
  };
  mesh: {
    enabled: ParameterOption;
    gridType: ParameterOption;
    x: ParameterOption;
    y: ParameterOption;
  };
  perils: ParameterOption;
  scenarios: ParameterOption;
  scores: ParameterOption;
  years: ParameterOption;
}

// for API
export enum DownloadFormat {
  STANDARD = 'STANDARD',
  TABLEAU = 'TABLEAU',
}
