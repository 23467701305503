import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ArrowBackIosNew as ArrowBackIosNewIcon,
  ReportProblem as ReportProblemIcon,
} from '@mui/icons-material';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectDocument360IsLoading, selectSLRDrawerOpen } from '../../redux/selectors/uiSelectors';
import { getDocument360Token, setSLRDrawer } from '../../redux/slices/uiSlice';
import {
  selectAvailableSLRTemplates,
  selectAvailableScenariosForSLR,
  selectCheckAvailabilityOfFidelitySLR,
  selectDownloadSLRAllLocations,
  selectSelectedResultSet,
} from '../../redux/selectors/csgDataSelectors';
import { downloadSLR, deselectResultSetForSLR } from '../../redux/slices/csgDataSlice';
import { selectHasFidelityRole } from '../../redux/selectors/userSelectors';
import { resetSLRError } from '../../redux/slices/slrSlice';
import { selectSLRError } from '../../redux/selectors/slrSelectors';
import { selectKnowledgeBaseAccess } from '../../redux/selectors/queryBuilderSelectors';
import { Scenario } from '../../api/resultSets/types';
import Drawer from '../../components/Drawer';
import withErrorBoundary from '../../components/ErrorBoundary/withErrorBoundary';
import {
  DRAWER_COLUMN_CONTAINER,
  LITE_TEXT_COLOR,
  SIDE_PANEL_TITLE_COLOR,
  ColumnStyle,
  CenteredRowStyle,
  SIDE_PANEL_SEPARATOR,
  MUI_SPACER,
  ACTIVE_BUTTON_STYLE,
  px,
  APP_BG_COLOR,
  APP_TEXT_COLOR,
} from '../../utils/styleUtils';
import { StyledMediumLabel } from '../../components/labels/StyledLabel';
import TileRadio from '../../components/tile/TileRadio';
import TextInput from '../../components/text-input/TextInput';
import SwitchButton, { IndividualButtonProps } from '../../components/buttons/SwitchButton';
import RadioGroup from '../../components/radio/RadioGroup';
import TextUnderlineButton from '../../components/TextUnderlineButton';
import {
  slrTemplateOptions,
  slrDownloadOptions,
  slrFidelityTemplateOptions,
  slrScenarioOptions,
} from './constants';
import { SLRTemplateProps, SLRValidatorProps } from './types';
import { RadioOption } from '../../components/radio/types';
import { TOP_BAR_HEIGHT } from '../main/TopBar/util';
import { validatePositiveIntegerString } from './util';
import { restrictOnCharInput } from '../../utils/keyboardUtils';
import { SLRScenarioType, SLRTemplateType } from '../../rest/slr/types';
import Tooltip from '../../components/tooltip/Tooltip';

const SLRDrawer: FC = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const drawerOpen = useAppSelector(selectSLRDrawerOpen);
  const allowDownloadForAllLocations = useAppSelector(selectDownloadSLRAllLocations);
  const selectedResultSet = useAppSelector(selectSelectedResultSet);
  const hasFidelityRole = useAppSelector(selectHasFidelityRole);
  const availableFidelitySLR = useAppSelector(selectCheckAvailabilityOfFidelitySLR);
  const slrError = useAppSelector(selectSLRError);
  const userCanAccessKnowledgeBase = useAppSelector(selectKnowledgeBaseAccess);
  const doc360IsLoading = useAppSelector(selectDocument360IsLoading);
  const defaultScenarios = useAppSelector(selectAvailableScenariosForSLR);
  const defaultSLRTemplates = useAppSelector(selectAvailableSLRTemplates);

  const [loadingSLR, setLoadingSLR] = useState<boolean>(true);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [selectedDownloadId, setSelectedDownloadId] = useState<string>(
    slrDownloadOptions.find((opt) => opt.selected)?.id ?? '',
  );
  const [selectedScenarioId, setSelectedScenarioId] = useState<string>(
    defaultScenarios.find((opt) => opt.selected)?.id ?? '',
  );
  const [selectedTemplate, setSelectedTemplate] = useState<SLRTemplateType | undefined>(
    defaultSLRTemplates.find((opt) => opt.selected)?.value,
  );
  const [locationId, setLocationId] = useState<string>('');

  const downloadOptions = useMemo<RadioOption[]>(
    () =>
      slrDownloadOptions.map((option) => ({
        ...option,
        ...(selectedDownloadId ? { selected: option.id === selectedDownloadId } : {}),
        ...(option.id === 'all' && !allowDownloadForAllLocations
          ? {
              disabled: true,
              tooltip: 'This option is only enabled for portfolios with 25 or fewer locations',
            }
          : {}),
      })),
    [allowDownloadForAllLocations, selectedDownloadId],
  );

  const scenarioOptions = useMemo(
    () =>
      defaultScenarios.map((option) => ({
        ...option,
        ...(selectedTemplate === SLRTemplateType.FIDELITY && option.id === SLRScenarioType.SSP2
          ? { disabled: true, tooltip: 'This scenario is not available for fidility template.' }
          : {}),
        ...(selectedScenarioId ? { selected: option.id === selectedScenarioId } : {}),
      })),
    [defaultScenarios, selectedScenarioId, selectedTemplate],
  );

  const templateOptions = useMemo(
    () =>
      [...defaultSLRTemplates, ...(hasFidelityRole ? slrFidelityTemplateOptions : [])].map(
        (option) => ({
          ...option,
          ...(selectedTemplate ? { selected: option.value === selectedTemplate } : {}),
          ...(option.value === SLRTemplateType.FIDELITY
            ? {
                disabled: !availableFidelitySLR.isFidelityAvailable,
                tooltip: availableFidelitySLR.strTooltip,
              }
            : {}),
          ...(option.value === SLRTemplateType.FIDELITY &&
          availableFidelitySLR.isFidelityAvailable &&
          selectedScenarioId === SLRScenarioType.SSP2
            ? { disabled: true, tooltip: 'This template is not available for scenario SSP2.' }
            : {}),
        }),
      ),
    [
      defaultSLRTemplates,
      hasFidelityRole,
      selectedTemplate,
      availableFidelitySLR,
      selectedScenarioId,
    ],
  );

  const validation: SLRValidatorProps = useMemo(() => {
    return {
      locationId: !isEmpty(locationId)
        ? validatePositiveIntegerString({
            value: locationId,
            fieldName: 'Location Id',
          })
        : { valid: true, message: '' },
    };
  }, [locationId]);

  const disableLocationId = useMemo(() => selectedDownloadId === 'all', [selectedDownloadId]);

  const disableDownload = useMemo(
    () =>
      !(selectedResultSet && selectedDownloadId && selectedTemplate) ||
      (!disableLocationId && (isEmpty(locationId) || !validation.locationId.valid)) ||
      loadingSLR,
    [
      selectedResultSet,
      selectedDownloadId,
      selectedTemplate,
      disableLocationId,
      locationId,
      validation.locationId.valid,
      loadingSLR,
    ],
  );

  const setNewDrawerState = (newState: boolean): void => {
    if (!loadingSLR) {
      dispatch(setSLRDrawer(newState));
      if (!newState) {
        dispatch(deselectResultSetForSLR());
      }
    } else {
      setCollapsed(true);
    }
  };

  const handleSLRDownload = async (): Promise<void> => {
    if (selectedResultSet && selectedDownloadId && selectedTemplate) {
      setLoadingSLR(true);
      await dispatch(resetSLRError());
      await dispatch(
        downloadSLR({
          template: selectedTemplate,
          scenario: selectedScenarioId as SLRScenarioType,
          locationId,
        }),
      );
      setLoadingSLR(false);
      // setNewDrawerState(false);
    }
  };

  const actionButtons: IndividualButtonProps[] = [
    {
      title: loadingSLR ? 'Downloading SLR' : 'Download SLR',
      onClick: () => {
        void handleSLRDownload();
      },
      disabled: disableDownload,
      id: 'slr-download-button',
      buttonSx: ACTIVE_BUTTON_STYLE,
      loading: loadingSLR,
    },
    {
      title: 'Close',
      onClick: () => setNewDrawerState(false),
      id: 'slr-close-button',
    },
  ];

  const selectDownloadOption = (selectedId: string): void => {
    setSelectedDownloadId(selectedId);
  };

  const selectScenarioOption = (selectedId: string): void => {
    setSelectedScenarioId(selectedId);
  };

  const selectTemplateOption = (selectedTemplateVal: SLRTemplateProps['value']): void => {
    setSelectedTemplate(selectedTemplateVal);
  };

  const handleLocationIdChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setLocationId(event.target.value);
  };

  useEffect(() => {
    if (!loadingSLR && collapsed) {
      setNewDrawerState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed, loadingSLR]);

  useEffect(() => {
    setLocationId('');
  }, [selectedDownloadId, setLocationId]);

  useEffect(() => {
    setLocationId('');
    setSelectedDownloadId(slrDownloadOptions.find((opt) => opt.selected)?.id ?? '');
    setSelectedScenarioId(defaultScenarios.find((opt) => opt.selected)?.id ?? '');
    setSelectedTemplate(defaultSLRTemplates.find((opt) => opt.selected)?.value);
    if (drawerOpen) {
      setCollapsed(false);
    }
    setLoadingSLR(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  useEffect(() => {
    dispatch(resetSLRError());
  }, [dispatch, drawerOpen, validation.locationId.valid]);

  return (
    <Drawer
      drawerOpen={drawerOpen}
      setDrawer={setNewDrawerState}
      {...(collapsed
        ? {
            hideBackdrop: true,
            sx: { width: theme.spacing(4), left: 'auto' },
            containerProps: { sx: { minWidth: 0 } },
          }
        : {})}
    >
      {!collapsed ? (
        <Box
          sx={{
            ...DRAWER_COLUMN_CONTAINER,
            justifyContent: 'space-between',
            height: '100%',
            width: theme.spacing(87.5),
            color: LITE_TEXT_COLOR,
          }}
        >
          <Box
            sx={{
              ...ColumnStyle,
              width: '100%',
            }}
          >
            <StyledMediumLabel
              sx={{
                color: SIDE_PANEL_TITLE_COLOR,
                marginBottom: theme.spacing(1),
              }}
              variant="h5"
            >
              DOWNLOAD SINGLE-LOCATION REPORT
            </StyledMediumLabel>
            <Stack
              useFlexGap
              direction="column"
              spacing={3}
              sx={{ marginTop: theme.spacing(1) }}
              divider={
                <Divider
                  flexItem
                  orientation="horizontal"
                  sx={{
                    borderBottom: SIDE_PANEL_SEPARATOR,
                  }}
                />
              }
            >
              <Box sx={{ marginLeft: theme.spacing(0), marginBottom: theme.spacing(0) }}>
                <Typography variant="h6" sx={{ fontSize: theme.spacing(2) }}>
                  Select Download Option:
                </Typography>
                <Box sx={{ marginLeft: theme.spacing(3), marginTop: theme.spacing(1) }}>
                  <RadioGroup
                    options={downloadOptions}
                    onRadioClick={selectDownloadOption}
                    sx={{ marginBottom: 0 }}
                  />
                  <TextInput
                    disabled={disableLocationId}
                    type="number"
                    placeholder="Enter Location ID..."
                    formControlProps={{
                      sx: {
                        width: theme.spacing(36.5),
                        marginLeft: theme.spacing(2.75),
                      },
                    }}
                    value={locationId}
                    onChange={handleLocationIdChange}
                    onKeyDown={(event) => restrictOnCharInput(event, 'INT')}
                  />
                </Box>
              </Box>
              <Box sx={{ marginLeft: theme.spacing(0), marginBottom: theme.spacing(0) }}>
                <Typography variant="h6" sx={{ fontSize: theme.spacing(2) }}>
                  Select Senario:
                </Typography>
                <Box sx={{ marginLeft: theme.spacing(3), marginTop: theme.spacing(1) }}>
                  <RadioGroup
                    options={scenarioOptions}
                    onRadioClick={selectScenarioOption}
                    sx={{ marginBottom: 0 }}
                  />
                </Box>
              </Box>
              <Box sx={{ marginLeft: theme.spacing(0), marginBottom: theme.spacing(0) }}>
                <Typography variant="h6" sx={{ fontSize: theme.spacing(2), paddingTop: 0 }}>
                  Select Template Format:
                </Typography>
                <Box sx={{ marginLeft: theme.spacing(3), marginTop: theme.spacing(1) }}>
                  <Grid container spacing={2}>
                    {templateOptions.map((template) => (
                      <Grid item xs={6} key={template.name}>
                        <TileRadio
                          sx={{
                            color: LITE_TEXT_COLOR,
                            ...(template.selected
                              ? { backgroundColor: APP_BG_COLOR, color: APP_TEXT_COLOR }
                              : {}),
                          }}
                          title={template.name}
                          selected={template.selected}
                          disabled={template.disabled}
                          tileTooltip={template.tooltip}
                          onClick={() => {
                            selectTemplateOption(template.value);
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Stack>
          </Box>

          <Box sx={{ marginTop: theme.spacing(2) }}>
            <Box
              sx={{
                ...CenteredRowStyle,
                alignItems: 'center',
                marginBottom: theme.spacing(MUI_SPACER),
              }}
              data-testid="csg-app-knowledge-base"
            >
              <Typography variant="caption">
                For documentation please navigate to the{' '}
                <TextUnderlineButton
                  data-testid="slr-knowledge-base-link"
                  TypographyProps={{
                    variant: 'h6',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    sx: {
                      paddingTop: 0,
                    },
                  }}
                  onClick={() => {
                    if (userCanAccessKnowledgeBase) {
                      void dispatch(
                        getDocument360Token({
                          redirectUrl: '/docs/single-location-report-documentation-10',
                        }),
                      );
                    }
                  }}
                  disabled={!userCanAccessKnowledgeBase || doc360IsLoading}
                >
                  Single-Location Report Documentation
                  {doc360IsLoading && (
                    <CircularProgress
                      sx={() => ({
                        fontSize: 'inherit',
                        marginLeft: theme.spacing(0.5),
                      })}
                      size="14px"
                      color="inherit"
                    />
                  )}
                </TextUnderlineButton>
              </Typography>
            </Box>

            <Box
              sx={{
                ...CenteredRowStyle,
                alignItems: 'center',
                width: '100%',
                marginBottom: theme.spacing(2),
              }}
            >
              <SwitchButton type="normal" buttons={actionButtons} />
              {!validation.locationId.valid ? (
                <Box sx={{ ...CenteredRowStyle, alignItems: 'center' }}>
                  <ReportProblemIcon
                    sx={{ height: theme.spacing(2), margin: `0 ${theme.spacing(0.5)}` }}
                  />
                  <Typography variant="body1">{validation.locationId.message}</Typography>
                </Box>
              ) : (
                !loadingSLR &&
                slrError && (
                  <Box sx={{ ...CenteredRowStyle, alignItems: 'center' }}>
                    <Tooltip title={slrError} placement="bottom-start" id="tt-error-message">
                      <ReportProblemIcon
                        sx={{ height: theme.spacing(2), margin: `0 ${theme.spacing(0.5)}` }}
                      />
                    </Tooltip>
                    <Typography variant="body1">Something went wrong!</Typography>
                  </Box>
                )
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            ...DRAWER_COLUMN_CONTAINER,
            justifyContent: 'space-between',
            height: '100%',
            width: theme.spacing(4),
            color: LITE_TEXT_COLOR,
            padding: theme.spacing(1),
            cursor: 'pointer',
          }}
          onClick={() => setCollapsed(false)}
        >
          <Box sx={{ ...ColumnStyle, justifyContent: 'center', height: '100%' }}>
            <Box
              sx={{
                ...ColumnStyle,
                alignItems: 'center',
                color: SIDE_PANEL_TITLE_COLOR,
                rotate: '-90deg',
                width: `calc(100vh - ${px(TOP_BAR_HEIGHT)} - ${theme.spacing(6)})`,
                alignSelf: 'center',
                textAlign: 'center',
                paddingTop: 0,
              }}
            >
              {loadingSLR ? (
                <Box sx={{ ...CenteredRowStyle, alignItems: 'center' }}>
                  <StyledMediumLabel
                    sx={{
                      color: SIDE_PANEL_TITLE_COLOR,
                    }}
                    variant="h6"
                  >
                    DOWNLOADING SLR
                  </StyledMediumLabel>
                  <CircularProgress
                    sx={() => ({
                      fontSize: 'inherit',
                      marginLeft: theme.spacing(0.5),
                      marginTop: theme.spacing(0.25),
                    })}
                    size={theme.spacing(1.75)}
                    color="inherit"
                  />
                </Box>
              ) : (
                <StyledMediumLabel
                  sx={{
                    color: SIDE_PANEL_TITLE_COLOR,
                  }}
                  variant="h6"
                >
                  DOWNLOAD SLR
                </StyledMediumLabel>
              )}
            </Box>
          </Box>
          <IconButton color="inherit">
            <ArrowBackIosNewIcon sx={{ height: theme.spacing(2), width: theme.spacing(2) }} />
          </IconButton>
        </Box>
      )}
    </Drawer>
  );
};

export default withErrorBoundary(SLRDrawer);
