import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import {
  Box,
  ButtonProps,
  Grid,
  GridProps,
  IconButton,
  Typography,
  TypographyProps,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { Children, cloneElement, FC, ReactElement } from 'react';
import { CARD_BORDER, px, Rotate90, StyleProps } from '../utils/styleUtils';
import DotMenu, { DotItem } from './DotMenu';
import { StyledMediumLabel } from './labels/StyledLabel';

interface StripePlaceholderProps {
  children?: ReactElement | ReactElement[];
  sx?: StyleProps;
}
export const StripePlaceholder: FC<StripePlaceholderProps> = ({ children, sx = {} }) => {
  return (
    <Box sx={{ borderRadius: '2px', width: '100%', border: '1px solid #fff', ...sx }}>
      {children}
    </Box>
  );
};

interface IteratorHeadProps {
  text?: string;
  sx?: StyleProps;
}

export const HEAD_WIDTH: number = 26;
export const HEAD_TITLE_WIDTH: number = 34;

export const Head: FC<IteratorHeadProps> = ({ text = '', sx = {} }) => {
  return (
    <Box
      sx={{
        borderRight: CARD_BORDER,
        height: '100%',
        width: px(HEAD_TITLE_WIDTH),
        background: '#fff',
        color: '#000',
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {text.length > 0 && (
          <Typography
            variant="caption"
            sx={{
              ...Rotate90,
              whiteSpace: 'nowrap', // this is to make all the text in the title go on the same line
            }}
          >
            {text}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

interface IteratorBodyContainerProps {
  horizontal?: boolean | undefined;
  vertical?: boolean | undefined;
  MuiGridProps?: GridProps;
  gridContainerSx?: StyleProps;
  gridItemSx?: StyleProps;
}

/*
  NOTE: [FlexibleChildrenType] The use of `any[]` here is to allow for the use of conditional render statements in generation of children, e.g.
    <Iterator>
      <Card/>                  <-- This Card always renders as a child of Iterator
      {condition && (<Card/>)} <-- This Card renders conditionally
    </Iterator>
  */

export type FlexibleChildrenType = ReactElement | ReactElement[] | any[];

interface IteratorBodyProps extends IteratorBodyContainerProps {
  children: FlexibleChildrenType;
}

const Body: FC<IteratorBodyProps> = ({
  children,
  horizontal,
  vertical,
  MuiGridProps = {},
  gridContainerSx = {},
  gridItemSx = {},
}) => {
  const elements = Array.isArray(children) ? children.filter(Boolean) : [children].filter(Boolean);
  // IDEA: [Calculate number of pages in each iterator], based on children; save to Redux and do something with that...
  // eslint-disable-next-line no-console
  // console.log('# of children:', elements.length);

  return (
    <Grid
      container
      sx={{
        overflow: 'auto',
        ...gridContainerSx,
      }}
      alignContent="flex-start"
      alignItems="center"
      direction={vertical ? 'row' : 'column'} // NOTE: This seems counter-intuitive, but it does make the overflow work correctly
      {...MuiGridProps}
      spacing={0}
    >
      {elements.length > 0 && (
        <>
          {Children.map(elements, (child: ReactElement) => {
            return (
              <Grid
                item
                // The options below were commented out in connection with [make summary card fill the whole iterator]
                xs={12}
                sm // [breakpoint]=true means the grid item's width grows to use the space available in the grid container
                // md="auto"
                sx={{
                  borderTop: 'none',
                  borderRight: CARD_BORDER,
                  borderBottom: vertical ? CARD_BORDER : 'none',
                  borderLeft: 'none',
                  ...gridItemSx,
                }}
              >
                {cloneElement(child)}
              </Grid>
            );
          })}
        </>
      )}
    </Grid>
  );
};

interface ContextMenuConfig extends ButtonProps {
  // ...
}

interface IteratorPageProp extends DotItem {}

interface IteratorTailProps {
  contextMenu?: ContextMenuConfig;
  pages?: IteratorPageProp[];
  handlePageChange?: (page: IteratorPageProp) => void;
}

const Tail: FC<IteratorTailProps> = ({
  contextMenu = {},
  pages = [],
  handlePageChange = (page) => {
    // eslint-disable-next-line no-console
    console.log('page clicked', { page });
  },
}) => {
  return (
    <Box
      sx={{
        // borderLeft: CARD_BORDER,
        height: '100%',
        width: px(HEAD_WIDTH),
        // background: '#fff',
        color: '#000',
        right: 0,
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      {!isEmpty(contextMenu) && (
        <IconButton
          size="large"
          data-testid="current-user-context-menu-button"
          aria-label="current-user-context-menu-button"
          aria-controls="menu-app-bar"
          aria-haspopup="true"
          onClick={contextMenu?.onClick}
          color="inherit"
          sx={{
            paddingTop: '7px',
          }}
          disableRipple
        >
          <MoreVertIcon />
        </IconButton>
      )}
      {pages.length > 1 && (
        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          <DotMenu dotsState={pages} onChange={handlePageChange} />
        </Box>
      )}
    </Box>
  );
};

interface IteratorProps extends IteratorBodyProps {
  HeadProps?: IteratorHeadProps;
  BodyProps?: IteratorBodyContainerProps;
  BodyContainerSx?: StyleProps;
  searchInputSx?: StyleProps;
  TitleProps?: TypographyProps;
  sx?: StyleProps;
  headless?: boolean;
  tailless?: boolean;
  TailProps?: IteratorTailProps;
  cardMinWidth?: number;
  cardMinHeight?: number;
  searchInput?: ReactElement | ReactElement[];
  title?: string;
}

const Iterator: FC<IteratorProps> = ({
  children,
  headless = false,
  tailless = false,
  horizontal,
  vertical,
  HeadProps = {},
  BodyProps = {},
  BodyContainerSx = {},
  TitleProps = {},
  TailProps = {},
  sx = {},
  searchInput = null,
  searchInputSx = {},
  title = '',
}) => {
  // IDEA: [Calculate number of pages in each iterator], based on children; save to Redux and do something with that...

  const elements = Array.isArray(children) ? children.filter(Boolean) : [children].filter(Boolean);
  const { gridContainerSx: bodyGridContainerSx, ...bodyProps } = BodyProps;
  return (
    <Box
      data-testid="iterator-component"
      sx={{
        height: '100%',
        width: '100%',
        overflow: 'hidden', // this is important to include to prevent unnecessary scroll
        display: 'flex',
        ...sx,
      }}
    >
      {!headless && <Head {...HeadProps} />}
      <Box
        sx={{
          display: 'block',
          height: '100%',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        {title && (
          <StyledMediumLabel data-testid="iterator-title" {...TitleProps}>
            {title}
          </StyledMediumLabel>
        )}
        <Box
          sx={{
            display: 'block',
            height: title ? 'calc(100% - 63px)' : '100%',
            width: '100%',
            overflow: 'hidden',
            ...BodyContainerSx,
          }}
        >
          {searchInput && <Box sx={searchInputSx}>{searchInput}</Box>}
          <Body
            {...bodyProps}
            gridContainerSx={{
              height: searchInput ? 'calc(100% - 56px)' : '100%', // `'calc(100% - 40px)'` is to ensure that the full height of the last card is visible at the bottom of the vertical list
              // borderLeft: headless ? CARD_BORDER : 'none',
              ...bodyGridContainerSx,
            }}
            horizontal={horizontal ?? vertical !== true}
            vertical={vertical}
          >
            {Children.map(elements, (child: ReactElement) => cloneElement(child))}
          </Body>
        </Box>
      </Box>
      {!tailless && <Tail {...TailProps} />}
    </Box>
  );
};

export default Iterator;
