import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  Typography,
  styled,
  CardProps,
  AvatarProps,
  TypographyProps,
  Box,
  CardContentProps,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { isNil, isUndefined } from 'lodash';
import { StyledMediumLabel } from '../labels/StyledLabel';
import { MenuOptionProps, MenuPositionX } from '../menu/types';
import Menu from '../menu/Menu';
import DropdownHandle from '../buttons/DropdownHandle';

const ProfileCard: FC<CardProps> = styled((props: CardProps) => {
  return <Card {...props} />;
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  color: 'inherit',
  padding: theme.spacing(0.5),
  paddingRight: theme.spacing(1.5),
  position: 'relative',
}));

const UserAvatar: FC<AvatarProps> = styled((props: AvatarProps) => {
  return <Avatar {...props} />;
})(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  marginRight: theme.spacing(1),
  backgroundColor: '#fff',
  color: '#000',
}));

const UserContent: FC<CardContentProps> = styled((props: CardContentProps) => {
  return <CardContent {...props} />;
})(({ theme }) => ({
  padding: '0 !important', // due to specificity, padding-bottom is not setting to 0 without !important
  marginRight: theme.spacing(1),
}));

const MutedTypography: FC<TypographyProps> = styled((props: TypographyProps) => {
  return <Typography {...props} />;
})(({ theme }) => ({
  color: '#999999',
  fontSize: theme.spacing(1.376),
  paddingTop: 0,
}));

interface UserProfileCardProps {
  title: string;
  subtitle?: string;
  avatarUrl?: string;
  userGreeting?: string;
  onClickCardMenu?: (element: HTMLButtonElement) => void;
  onCloseCardMenu?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isMenuDisabled?: boolean;
  anchor?: HTMLButtonElement | null;
  options?: MenuOptionProps[];
}

const UserProfileCard: FC<UserProfileCardProps> = ({
  title,
  subtitle = '',
  avatarUrl = '',
  userGreeting = '',
  onClickCardMenu,
  onCloseCardMenu,
  isMenuDisabled = false,
  anchor,
  options,
}: UserProfileCardProps) => {
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const smScreen = useMediaQuery(theme.breakpoints.down('md'));

  const showSubtitle = useMemo(
    () => !xsScreen && !smScreen && Boolean(subtitle),
    [xsScreen, smScreen, subtitle],
  );

  const showUserGreeting = useMemo(
    () => !xsScreen && !smScreen && Boolean(userGreeting),
    [xsScreen, smScreen, userGreeting],
  );

  const showTitle = useMemo(() => !xsScreen && Boolean(title), [xsScreen, title]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const isMenuOpen = Boolean(anchorEl);
  const elementId = isMenuOpen ? 'individual-user-menu' : undefined;

  const onClickMenu = (): void => {
    if (!isNil(buttonRef.current)) {
      if (isUndefined(anchor)) {
        setAnchorEl(buttonRef.current);
      }
      if (!isNil(onClickCardMenu)) {
        onClickCardMenu(buttonRef.current);
      }
    }
  };

  const onCloseMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    if (isUndefined(anchor)) {
      setAnchorEl(null);
    }
    if (!isNil(onCloseCardMenu)) {
      onCloseCardMenu(event);
    }
  };

  useEffect(() => {
    if (!isUndefined(anchor)) {
      setAnchorEl(anchor);
    }
  }, [anchor]);

  return (
    <ProfileCard elevation={0}>
      <UserAvatar
        alt={title}
        src={avatarUrl}
        sx={{
          width: { xs: theme.spacing(3), sm: theme.spacing(4) },
          height: { xs: theme.spacing(3), sm: theme.spacing(4) },
        }}
      />
      {showTitle && (
        <UserContent>
          <StyledMediumLabel
            data-testid="user-greeting"
            variant="body1"
            sx={{ cursor: 'pointer' }}
            onClick={onClickMenu}
          >
            {showUserGreeting ? `${userGreeting} ${title}` : title}
          </StyledMediumLabel>
          {showSubtitle && <MutedTypography variant="subtitle1">{subtitle}</MutedTypography>}
        </UserContent>
      )}
      {options && (
        <Box
          sx={{
            position: 'absolute',
            top: {
              xs: `calc(50% - ${theme.spacing(1.25)})`,
              sm: `calc(50% - ${theme.spacing(1.25)})`,
              md: theme.spacing(0.5),
            },
            right: theme.spacing(-1),
          }}
        >
          <DropdownHandle
            size="large"
            data-testid="current-user-context-menu-button"
            aria-label="current-user-context-menu-button"
            aria-controls="menu-app-bar"
            aria-haspopup="true"
            onClickMenu={onClickMenu}
            disabled={isMenuDisabled}
            color="inherit"
            disableRipple
            sx={{ minWidth: 'auto', padding: 0 }}
            isHandleOn={isMenuOpen}
            ref={buttonRef}
          />
          <Menu
            data-testid="current-user-context-menu"
            aria-labelledby="menu-app-bar"
            id={elementId}
            open={isMenuOpen}
            anchorEl={anchorEl}
            onClose={onCloseMenu}
            options={options}
            horzOrientation={MenuPositionX.LEFT}
          />
        </Box>
      )}
    </ProfileCard>
  );
};

export default UserProfileCard;
