import { get } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { Portfolio } from '../../../api/portfolios/types';
import { ContextMenuOptionProps } from '../../../components/ContextMenu/types';
import { AlertSeverity } from '../../../components/alerts/types';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectDownloadsById,
  selectGeoCodeLogDownloadsById,
} from '../../../redux/selectors/csgDataSelectors';
import { selectUsername } from '../../../redux/selectors/userSelectors';
import {
  DataStatus,
  deletePortfolio,
  downloadGeocodeLogReport,
  downloadPortfolio,
  setPortfolio,
} from '../../../redux/slices/csgDataSlice';
import { newQuery, updateQueryBuilderState } from '../../../redux/slices/queryBuilderSlice';
import { addAlert, setQueryBuilderDrawer } from '../../../redux/slices/uiSlice';
import { dateFormat } from '../../../utils/datetime';
import { captureDebugLog } from '../../../utils/debugUtils';
import { TILE_BORDER } from '../../../utils/styleUtils';
import PortfolioCard from './PortfolioCard';
import {
  EditPortfolioValueInput,
  PortfolioCardContainerProps,
  PortfolioEditState,
  RemovePortfolioCategoryInput,
} from './types';
import { updatePortfolioAdapter } from '../../../rest/portfolio/adapter';

const dataItemIsLoading = (dataItem: { status: DataStatus }): boolean =>
  dataItem?.status === DataStatus.LOADING;

const PortfolioCardContainer: FC<PortfolioCardContainerProps> = ({
  sx,
  sxSupplement,
  portfolio,
  selected,
  cardContent,
  handleInfoClick,
  handleSelect,
  isMockData,
  isDeletionDisabled = false,
}) => {
  const dispatch = useAppDispatch();
  const downloadsById = useAppSelector(selectDownloadsById);
  const downloadGeoCodeLogById = useAppSelector(selectGeoCodeLogDownloadsById);
  const username = useAppSelector(selectUsername);

  const handleRequestDownload = async ({ id, name }: Portfolio): Promise<void> => {
    await dispatch(downloadPortfolio({ portfolioId: id, portfolioName: name }));
  };

  const handleGeoCodeLogDownload = async (portfolioId: string): Promise<void> => {
    await dispatch(downloadGeocodeLogReport(portfolioId));
  };

  const isDownloading = useMemo(() => {
    const dataItem = get(downloadsById, portfolio.id, { status: DataStatus.IDLE });
    return dataItemIsLoading(dataItem);
  }, [downloadsById, portfolio.id]);

  const isDownloadingGeoCodeLogFile = useMemo(() => {
    const dataItem = get(downloadGeoCodeLogById, portfolio.id, { status: DataStatus.IDLE });
    return dataItemIsLoading(dataItem);
  }, [downloadGeoCodeLogById, portfolio.id]);

  const downloadGeoCodeLogAllowed = useMemo(() => {
    const dataItem = get(downloadGeoCodeLogById, portfolio.id, { status: DataStatus.IDLE });

    if (dataItemIsLoading(dataItem)) {
      return false;
    }

    return true;
  }, [portfolio.id, downloadGeoCodeLogById]);

  const downloadAllowed = useMemo(() => {
    const dataItem = get(downloadsById, portfolio.id, { status: DataStatus.IDLE });

    if (dataItemIsLoading(dataItem)) {
      return false;
    }

    return true;
  }, [portfolio.id, downloadsById]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // NOTE: CategorySelector -- START
  const [removeCategoryInProgress, setRemoveCategoryInProgress] = useState<boolean>(false);
  const [isEditingCategory, setIsEditingCategory] = useState<boolean>(false);
  const [isDeletePortfolioModalOpen, setDeletePortfolioModalOpen] = useState<boolean>(false);
  const [deletePortfolioInProgress, setDeletePortfolioInProgress] = useState<boolean>(false);

  const handleQueryBuilderDrawerOpen = (): void => {
    // 1. Set up query builder settings
    void dispatch(updateQueryBuilderState({ settingsOnly: true, portfolio })); // <-- Pulls / immutably modifies `RESULT_SET_CONFIG` under the hood

    // 2. Set portfolio id and report name for query
    dispatch(
      newQuery({
        portfolioId: portfolio.id,
        reportName: `${username}'s report (${dateFormat(undefined, 'MMMM D, YYYY h:mm A')})`,
      }),
    );

    // 3. Open the drawer
    dispatch(setQueryBuilderDrawer(true));
  };

  const onCategoryRemoved = (shouldCloseMenu: boolean): void => {
    // Closing the context menu and removing the progress flag.
    if (shouldCloseMenu) {
      setAnchorEl(null);
    }
    setRemoveCategoryInProgress(false);
    setIsEditingCategory(false);
  };

  const onCategoryApplied = (shouldCloseMenu: boolean = true): void => {
    // Closing the context menu and removing the progress flag.
    if (shouldCloseMenu) {
      setAnchorEl(null);
    }
    setIsEditingCategory(false);
  };

  const onCancelCategoryEdit = (): void => {
    setIsEditingCategory(false);
  };

  const removeCategory = async ({
    portfolioId,
    portfolioName,
  }: RemovePortfolioCategoryInput): Promise<void> => {
    setRemoveCategoryInProgress(true);

    const mutationResponse = await updatePortfolioAdapter(portfolioId, {
      name: portfolioName,
      categoryId: null, // This, sent to the API, will remove the category
    });

    const { data, error } = mutationResponse;

    if (data) {
      const { id } = data;

      if (id !== portfolioId) {
        dispatch(
          addAlert({
            open: true,
            message: 'Category removal could not be confirmed.',
            severity: AlertSeverity.Error,
          }),
        );

        onCategoryRemoved(false);
        return;
      }

      dispatch(
        setPortfolio({
          ...portfolio,
          category: null, // <-- This does: Locally, set portfolio category to null, which means no-category
        }),
      );

      dispatch(
        addAlert({
          open: true,
          message: 'Category removed successfully.',
          severity: AlertSeverity.Success,
        }),
      );

      onCategoryRemoved(true);
      return;
    }

    if (error) {
      dispatch(
        addAlert({
          open: true,
          message: error.message,
          severity: AlertSeverity.Error,
        }),
      );
    }

    onCategoryRemoved(true);
  };
  // NOTE: CategorySelector -- END

  const [editNameState, setEditName] = useState<PortfolioEditState>({
    name: '',
    error: null,
    open: false,
    status: DataStatus.IDLE,
  });

  const setEditState = (action: PortfolioEditState): void => {
    setEditName((prevState) => ({
      ...prevState,
      ...action,
    }));
  };

  const renamePortfolio = async ({
    portfolioId,
    portfolioName,
    categoryId,
  }: EditPortfolioValueInput): Promise<void> => {
    setEditState({
      error: null,
      status: DataStatus.LOADING,
    });

    const mutationResponse = await updatePortfolioAdapter(portfolioId, {
      name: portfolioName,
      categoryId,
    });

    const { data, error } = mutationResponse;

    if (data) {
      const { id } = data;

      if (id !== portfolioId) {
        setEditState({
          error: 'Portfolio rename could not be confirmed.',
          status: DataStatus.FAILED,
        });
        return;
      }

      dispatch(
        setPortfolio({
          ...portfolio,
          name: portfolioName,
        }),
      );

      setEditState({
        open: false,
        status: DataStatus.SUCCEEDED,
      });
      return;
    }

    if (error) {
      setEditState({
        error: error.message,
        status: DataStatus.FAILED,
      });
    }
  };

  const onRenameConfirmed = useCallback(
    (newName: string): void => {
      if (editNameState && portfolio?.id) {
        void renamePortfolio({
          portfolioId: portfolio.id,
          portfolioName: newName,
          categoryId: portfolio?.category?.id ?? null,
        });
        return;
      }

      console.error('No portfolio selected');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editNameState, portfolio],
  );

  const contextMenuOptions: ContextMenuOptionProps[] = [
    {
      title: 'Start Query',
      onClick: () => {
        handleQueryBuilderDrawerOpen();
        // close menu
        setAnchorEl(null);
      },
      id: 'portfolio-context-start-query',
      itemSx: {
        borderBottom: TILE_BORDER,
      },
    },
    {
      title: 'Rename',
      disabled: isMockData,
      onClick: () => {
        setEditState({
          status: DataStatus.IDLE,
          error: '',
          open: true,
          name: portfolio.name,
        });
        // close menu
        setAnchorEl(null);
      },
      id: 'portfolio-context-rename',
    },
    {
      title: (portfolio?.category?.id ?? '').length > 0 ? 'Recategorize' : 'Categorize',
      onClick: () => {
        // close menu
        setAnchorEl(null);
        setIsEditingCategory(true);
      },
      id:
        (portfolio?.category?.id ?? '').length > 0
          ? 'portfolio-context-recategorize'
          : 'portfolio-context-categorize',
    },
    // Removing this option as we don't allow any portfolio without a category.
    // {
    //   title: 'Remove Category',
    //   disabled: (portfolio?.category?.id ?? '').length === 0,
    //   onClick: () => {
    //     void removeCategory({
    //       portfolioId: portfolio.id,
    //       portfolioName: portfolio.name,
    //     });
    //   },
    //   id: 'ind-portfolio-menu-item-3',
    //   inProgress: removeCategoryInProgress,
    // },
    {
      title: isDownloading ? 'Downloading' : 'Download',
      onClick: async (): Promise<void> => {
        if (!isDownloading) {
          await handleRequestDownload(portfolio);
          // close menu
          setAnchorEl(null);
        }
      },
      disabled: !downloadAllowed,
      id: isDownloading ? 'portfolio-context-downloading' : 'portfolio-context-download',
    },
    {
      title: isDownloadingGeoCodeLogFile ? 'Downloading' : 'Download Geocode Log',
      onClick: async (): Promise<void> => {
        if (!isDownloadingGeoCodeLogFile) {
          await handleGeoCodeLogDownload(portfolio.id);
          // close menu
          setAnchorEl(null);
        }
      },
      disabled: !downloadGeoCodeLogAllowed,
      id: isDownloadingGeoCodeLogFile
        ? 'portfolio-context-geo-code-file-downloading'
        : 'portfolio-context-geo-code-file-download',
    },
    // {
    //   title: 'View Details',
    //   onClick: () => {},
    //   id: 'ind-portfolio-menu-item-5',
    // },
    {
      title: 'Copy Debug Log',
      onClick: () => {
        void captureDebugLog(portfolio, 'Portfolio Data');
      },
      id: 'portfolio-context-copy-debug-log',
    },
    {
      title: deletePortfolioInProgress ? 'Deleting' : 'Delete',
      disabled: isDeletionDisabled || isMockData,
      onClick: () => {
        setDeletePortfolioModalOpen(true);
        // close menu
        setAnchorEl(null);
      },
      id: deletePortfolioInProgress ? 'portfolio-context-deleting' : 'portfolio-context-delete',
      inProgress: deletePortfolioInProgress,
    },
  ];

  const onConfirmDelete = async (): Promise<void> => {
    try {
      setDeletePortfolioInProgress(true);
      await dispatch(
        deletePortfolio({
          portfolioId: portfolio.id,
        }),
      );
    } catch {}

    setDeletePortfolioInProgress(false);
  };

  return (
    <>
      <PortfolioCard
        sx={sx}
        sxSupplement={sxSupplement}
        title={portfolio.name}
        selected={selected}
        cardContent={cardContent}
        handleInfoClick={handleInfoClick}
        handleSelect={handleSelect}
        isMockData={isMockData}
        // CategorySelector
        isEditingCategory={isEditingCategory}
        onCancelCategoryEdit={onCancelCategoryEdit}
        onCategoryRemoved={onCategoryRemoved}
        onCategoryApplied={onCategoryApplied}
        portfolio={portfolio}
        // Edit props
        editNameState={editNameState}
        onRenameConfirmed={onRenameConfirmed}
        setEditState={setEditState}
        // Context menu
        contextMenuOptions={contextMenuOptions}
      />
      <ConfirmationModal
        open={isDeletePortfolioModalOpen}
        title="Delete Portfolio"
        body="You are requesting deletion of this Portfolio. Once deleted, it will no longer be available
        in your account. Are you sure you want to continue?"
        onCancel={() => setDeletePortfolioModalOpen(false)}
        onConfirm={() => {
          void onConfirmDelete();
        }}
        confirmButtonText="Yes"
        cancelButtonText="No"
        isConfirmButtonLoading={deletePortfolioInProgress}
      />
    </>
  );
};
export default PortfolioCardContainer;
