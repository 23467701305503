import { isEmpty } from 'lodash';
import {
  SCORES_RS_READOUT_BREAKDOWN_TEXT,
  crarCompatibilityForDamageAndLossRs,
} from '../../utils/crarUtils';
import { ResultSetStatus, ResultSetType } from '../../api/resultSets/types';
import config, {
  DUNDAS_DASHBOARDS,
  DashboardObject,
} from '../../pages/visualizations/dundas/config';
import { LogonTokenRedux } from '../slices/visualizationsSlice';
import { RootState } from '../store';
import {
  selectCRARCompatibleScoresRsFound,
  selectScoresResultSetsInSelectedPortfolio,
  selectSelectedResultSet,
} from './csgDataSelectors';
import { produceContentsBreakdown } from '../../utils/helpers';
import { checkCSGVersion, extractLocationCount } from '../slices/utils';

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const produceCrarReadout = (
  state: RootState,
): { allowCrar: boolean; readout: string; scoresRsId: string } | null => {
  const { selectedResultSet } = state.csgData;

  if (selectedResultSet?.type !== ResultSetType.ECONOMIC_IMPACTS) {
    return null;
  }

  // 1. Tests that the selected result set is D+L
  const validRs = crarCompatibilityForDamageAndLossRs(selectedResultSet);

  // 2. Tests that the portfolio has a Scores + Benchmarking RS that matches
  const { scoresRs, foundAValidScoresRs } = selectCRARCompatibleScoresRsFound(state);

  const scoresRsReadout = [
    {
      title: 'Other Portfolio Requirements',
      valid: foundAValidScoresRs,
      breakdown: produceContentsBreakdown(
        [SCORES_RS_READOUT_BREAKDOWN_TEXT],
        [foundAValidScoresRs ? SCORES_RS_READOUT_BREAKDOWN_TEXT : 'N/A'],
      ),
    },
  ];

  // 3. Produce a readout to display to the user
  const readoutJson = validRs.assessment.concat(scoresRsReadout);

  return {
    allowCrar: validRs.isCompatible && foundAValidScoresRs,
    readout: JSON.stringify(readoutJson),
    scoresRsId: scoresRs?.id ?? '',
  };
};

export const selectDundasIFrameUrl = (state: RootState): string => {
  const { selectedPortfolio, selectedResultSet } = state.csgData;
  const { selectedDashboard, token } = state.viz;

  let baseUrl: string = config.server;
  const portfolioId = selectedPortfolio?.id ?? null;
  const dashboardId = selectedDashboard?.id ?? null;
  const rsParamAlias = selectedDashboard?.rsParamAlias ?? null;
  const crarLinkageExpected = selectedDashboard?.crar ?? false;
  const rsId = selectedResultSet?.id ?? null;
  const params: string[] = ['e=false', 'vo=viewonly'];

  if (dashboardId) {
    baseUrl = `${baseUrl}/Dashboard/${dashboardId}`;

    if (portfolioId) {
      params.push(`$portfolio_id=${portfolioId}`);
    }

    if (rsId) {
      if (rsParamAlias) {
        params.push(`$${rsParamAlias}=${rsId}`);
      } else {
        // eslint-disable-next-line no-console
        console.warn('Result set param alias not found for dashboard,', selectedDashboard);
      }

      const crarReadout = produceCrarReadout(state);

      // console.log('- parsed:', JSON.parse(crarReadout?.readout ?? ''));

      if (crarLinkageExpected && crarReadout !== null) {
        const { allowCrar, readout, scoresRsId } = crarReadout;
        const encoded = encodeURI(readout);

        params.push(`$crar_generation_allowed=${allowCrar ? 'true' : 'false'}`);
        params.push(`$crar_generation_readout=${encoded}`);

        if (scoresRsId.length > 0) {
          params.push(`$crar_scores_result_set_id=${scoresRsId}`);
        }
      }
    }
  }

  if (token) {
    params.push(`logonTokenId=${token}`);
  }

  const queryParameters: string = params.length > 0 ? params.join('&') : '';

  return `${baseUrl}?${queryParameters}`;
};

export const selectDundasToken = (state: RootState): LogonTokenRedux => state.viz?.token ?? null;

export const selectDashboardId = (state: RootState): string =>
  state.viz?.selectedDashboard?.id ?? '';

export const selectDashboardList = (state: RootState): DashboardObject[] =>
  state.viz?.dashboards ?? [];

export const selectDashboardTitle = (state: RootState): string =>
  state.viz?.selectedDashboard?.title ?? '';

export enum VizIconKey {
  Table = 'TableViewIcon',
  Map = 'MapIcon',
  ScatterPlot = 'ScatterPlotIcon',
}

export interface VizMenuOption {
  id: string;
  iconKey: VizIconKey;
  dashboard: DashboardObject;
  disabled?: boolean;
}

const VIZ_MENU_OPTIONS: {
  [id: string]: VizMenuOption;
} = {
  perilMetricsValueTable: {
    id: 'peril-metrics-table-viz',
    iconKey: VizIconKey.Table,
    dashboard: DUNDAS_DASHBOARDS.perilMetricsValueTable,
  },
  perilMetricsMap: {
    id: 'peril-metrics-map-viz',
    iconKey: VizIconKey.Map,
    dashboard: DUNDAS_DASHBOARDS.perilMetricsMap,
  },
  perilValueChange: {
    id: 'peril-value-change-viz',
    iconKey: VizIconKey.Map,
    dashboard: DUNDAS_DASHBOARDS.perilValueChange,
  },
  perilMetricsStackedColumnChart: {
    id: 'peril-metrics-stacked-column-viz',
    iconKey: VizIconKey.Table,
    dashboard: DUNDAS_DASHBOARDS.perilMetricsStackedColumnChart,
  },
  singleLocationPerils: {
    id: 'single-location-perils-viz',
    iconKey: VizIconKey.Table,
    dashboard: DUNDAS_DASHBOARDS.singleLocationPerils,
  },
  floodMeshMap: {
    id: 'flood-mesh-map-viz',
    iconKey: VizIconKey.Map,
    dashboard: DUNDAS_DASHBOARDS.floodMeshMap,
  },
  scoresScatterPlot: {
    id: 'scores-scatter-plot-viz',
    iconKey: VizIconKey.ScatterPlot,
    dashboard: DUNDAS_DASHBOARDS.scoresScatterPlot,
  },
  singleLocationImpact: {
    id: 'single-location-impact-viz',
    iconKey: VizIconKey.Table,
    dashboard: DUNDAS_DASHBOARDS.singleLocationImpact,
  },
  singleLocationFinancial: {
    id: 'single-location-financial-viz',
    iconKey: VizIconKey.Table,
    dashboard: DUNDAS_DASHBOARDS.singleLocationFinancial,
  },
};

export const selectVizOptionsForSelectedRs = (state: RootState): VizMenuOption[] => {
  const selectedResultSet = selectSelectedResultSet(state);

  if (selectedResultSet) {
    const { type, parameters } = selectedResultSet;

    switch (type) {
      case ResultSetType.SCORES:
        return [VIZ_MENU_OPTIONS.scoresScatterPlot];
      case ResultSetType.PERILS: {
        const dataVersion3 = checkCSGVersion(selectedResultSet?.parameters?.csgVersion);

        return [
          VIZ_MENU_OPTIONS.perilMetricsStackedColumnChart,
          VIZ_MENU_OPTIONS.perilMetricsMap,
          VIZ_MENU_OPTIONS.perilMetricsValueTable,
          { ...VIZ_MENU_OPTIONS.perilValueChange, disabled: !dataVersion3 },
        ];
      }
      case ResultSetType.ECONOMIC_IMPACTS: {
        const scoresRs = selectScoresResultSetsInSelectedPortfolio(state);
        // Enable SLI/SLH Viz for Perils+EI only if Scores result-set of same version is present
        const foundScoresRsWithSameVersion = !isEmpty(
          scoresRs.find((resultSet) => resultSet.parameters.csgVersion === parameters.csgVersion),
        );
        const dataVersion3 = checkCSGVersion(selectedResultSet?.parameters?.csgVersion);

        return [
          VIZ_MENU_OPTIONS.perilMetricsStackedColumnChart,
          VIZ_MENU_OPTIONS.perilMetricsMap,
          VIZ_MENU_OPTIONS.perilMetricsValueTable,
          { ...VIZ_MENU_OPTIONS.perilValueChange, disabled: !dataVersion3 },
          { ...VIZ_MENU_OPTIONS.singleLocationPerils, disabled: !foundScoresRsWithSameVersion },
          { ...VIZ_MENU_OPTIONS.singleLocationImpact, disabled: !foundScoresRsWithSameVersion },
          { ...VIZ_MENU_OPTIONS.singleLocationFinancial, disabled: !dataVersion3 },
        ];
      }
      case ResultSetType.FLOOD_MESH:
        return [VIZ_MENU_OPTIONS.floodMeshMap];
      default:
        break;
    }
  }

  return [];
};

export const selectNumberOfAvailableVisualizations = (state: RootState): number => {
  const vizOptions = selectVizOptionsForSelectedRs(state);
  const vizAppSectionEnabled = selectVizSectionEnabled(state);
  const vizOptionsEnabled = vizOptions.filter((option) => !option?.disabled);

  return vizAppSectionEnabled && vizOptionsEnabled.length > 0 ? vizOptionsEnabled.length : 0;
};

export const selectVizSectionEnabled = (state: RootState): boolean => {
  const { selectedPortfolio, selectedResultSet } = state.csgData;
  const portfolioId = selectedPortfolio?.id ?? '';
  const rsId = selectedResultSet?.id ?? '';
  const enableVizByLocationCount = (extractLocationCount(selectedPortfolio) ?? 0) <= 10000; // Enable Viz only for less than "10k" locations
  const availableVisualizations = selectVizOptionsForSelectedRs(state);

  return (
    portfolioId.length > 0 &&
    rsId.length > 0 &&
    enableVizByLocationCount &&
    availableVisualizations.length > 0 &&
    selectedResultSet?.status === ResultSetStatus.COMPLETED
  );
};

export default selectDashboardId;
