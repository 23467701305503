import { RadioProps as MuiRadioProps } from '@mui/material';
import { ParameterOptionsUI } from '../../pages/query-builder/types';
import { StyleProps } from '../../utils/styleUtils';

export interface RadioOption extends ParameterOptionsUI {
  selected: boolean; // NOTE: within interface ParameterOptionsUI, `selected` is already a required field. But, for RadioOptions, `selected` is especially critical.
}

export interface RadioProps extends MuiRadioProps {
  option: RadioOption;
  onRadioClick: (id: string) => void;
}

export interface RadioGroupProps {
  name?: string;
  options: RadioOption[];
  onRadioClick: (id: string) => void;
  isRow?: boolean;
  sx?: StyleProps;
}

export enum RadioOperation {
  // NOTE: "RADIO_SELECT" means you turn the selected one on and all other off
  Select = 'RADIO_SELECT',
}

export interface UpdateRadioInstructions {
  id?: string;
  operation: RadioOperation;
  options?: RadioOption[];
}

export interface RadioFormProps {
  list: RadioOption[];
  setList: (instructions: UpdateRadioInstructions) => void;
  // setList: (nextList: RadioOption[]) => void;
  title: string;
}
