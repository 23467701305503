import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Box, Typography, useTheme } from '@mui/material';
import { FC, Fragment, useMemo } from 'react';
import {
  selectEIVersionStatus,
  selectSelectedPortfolio,
} from '../../redux/selectors/csgDataSelectors';
import withErrorBoundary from '../../components/ErrorBoundary/withErrorBoundary';
import SwitchButton, { IndividualButtonProps } from '../../components/buttons/SwitchButton';
import Tooltip from '../../components/tooltip/Tooltip';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectQueryBuilderStatus,
  selectQueryBuilderValidation,
} from '../../redux/selectors/queryBuilderSelectors';
import { DataStatus, resetCsgDataVersionToDefault } from '../../redux/slices/csgDataSlice';
import {
  QueryBuilderStatus,
  createResultSets,
  updateQueryBuilderState,
} from '../../redux/slices/queryBuilderSlice';
import { setQueryBuilderDrawer } from '../../redux/slices/uiSlice';
import { reactKey } from '../../utils/reactUtils';
import { ACTIVE_BUTTON_STYLE, CenteredRowStyle } from '../../utils/styleUtils';
import { ValidationSectionTypeUI } from './validation/types';
import { mapSectionTypeToLabel } from './validation/constants';

interface GroupedBySection {
  [key: string]: {
    section: ValidationSectionTypeUI;
    messages: Array<{
      text: string;
      id: string;
    }>;
  };
}

const QueryBuilderExecutor: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const qbStatus = useAppSelector(selectQueryBuilderStatus);
  const eiVersionStatus = useAppSelector(selectEIVersionStatus);
  const validation = useAppSelector(selectQueryBuilderValidation);
  const portfolio = useAppSelector(selectSelectedPortfolio);

  const handleResetAll = (): void => {
    void dispatch(updateQueryBuilderState({ settingsOnly: true, portfolio }));
    // [TODO] Uncomment if need to reset data version on ResetAll
    // dispatch(resetCsgDataVersionToDefault());
  };

  const handleCancel = (): void => {
    dispatch(setQueryBuilderDrawer(false));
    void dispatch(updateQueryBuilderState({})); // total reset
    dispatch(resetCsgDataVersionToDefault());
  };

  const startQuery = async (): Promise<void> => {
    await dispatch(createResultSets());
  };

  const actionButtons: IndividualButtonProps[] = [
    {
      title: 'Start',
      disabled: !validation.valid,
      onClick: () => {
        void startQuery();
      },
      id: reactKey(),
      'data-testid': 'qb-start-button',
      buttonSx: ACTIVE_BUTTON_STYLE,
    },
    {
      title: 'Reset All',
      onClick: handleResetAll,
      id: reactKey(),
      'data-testid': 'qb-reset-all-button',
    },
    {
      title: 'Cancel',
      onClick: handleCancel,
      id: reactKey(),
      'data-testid': 'qb-cancel-button',
    },
  ];

  const msgGroupedBySection: GroupedBySection = useMemo(() => {
    const { failed = [] } = validation;
    const groupedBySection: GroupedBySection = failed.reduce(
      (acc, curr) => {
        const { section, message } = curr;

        return {
          ...acc,
          [section]: {
            ...acc[`${section}`],
            messages: [
              ...acc[`${section}`].messages,
              {
                text: message,
                id: reactKey(),
              },
            ],
          },
        };
      },
      {
        [ValidationSectionTypeUI.GLOBAL]: {
          section: ValidationSectionTypeUI.GLOBAL,
          messages: [],
        },
        [ValidationSectionTypeUI.PERILS]: {
          section: ValidationSectionTypeUI.PERILS,
          messages: [],
        },
        [ValidationSectionTypeUI.ECONOMIC_IMPACTS]: {
          section: ValidationSectionTypeUI.ECONOMIC_IMPACTS,
          messages: [],
        },
        [ValidationSectionTypeUI.FLOOD_MESH]: {
          section: ValidationSectionTypeUI.FLOOD_MESH,
          messages: [],
        },
        [ValidationSectionTypeUI.SCORES]: {
          section: ValidationSectionTypeUI.SCORES,
          messages: [],
        },
      },
    );
    return groupedBySection;
  }, [validation]);

  const msgOrder = [
    ValidationSectionTypeUI.GLOBAL,
    ValidationSectionTypeUI.PERILS,
    ValidationSectionTypeUI.ECONOMIC_IMPACTS,
    ValidationSectionTypeUI.FLOOD_MESH,
    ValidationSectionTypeUI.SCORES,
  ];

  return (
    <>
      {(qbStatus === QueryBuilderStatus.LOADING || eiVersionStatus === DataStatus.LOADING) && (
        <Typography variant="h6" sx={{ marginBottom: theme.spacing(1) }} data-testid="qb-loading">
          Processing your request. Please wait.
        </Typography>
      )}

      {eiVersionStatus === DataStatus.FAILED && (
        <Typography variant="h6" sx={{ marginBottom: theme.spacing(1) }}>
          Something went wrong. Could not fetch EI versions.
        </Typography>
      )}

      {qbStatus === QueryBuilderStatus.FAILED && (
        <Typography variant="h6" sx={{ marginBottom: theme.spacing(1) }}>
          Something went wrong. Your request could not be fulfilled.
        </Typography>
      )}

      <Box
        sx={{
          ...CenteredRowStyle,
          alignItems: 'center',
          width: '100%',
          marginBottom: theme.spacing(2),
        }}
      >
        {qbStatus !== QueryBuilderStatus.LOADING && eiVersionStatus !== DataStatus.LOADING && (
          <SwitchButton type="normal" buttons={actionButtons} />
        )}

        {!validation.valid && (
          <Box sx={{ ...CenteredRowStyle, alignItems: 'center' }}>
            <Tooltip
              title={
                <>
                  {msgOrder
                    .filter((key) => msgGroupedBySection[`${key}`].messages.length > 0)
                    .map((key) => {
                      const { section, messages } = msgGroupedBySection[`${key}`];
                      const label = mapSectionTypeToLabel(section);

                      return (
                        <Fragment key={key}>
                          <Typography variant="body2" data-testid="validation-rs-type">
                            {label}
                          </Typography>
                          {messages.map(({ text, id }) => {
                            return (
                              <Typography variant="body2" key={id} data-testid="validation-stmt">
                                {`* ${text}`}
                              </Typography>
                            );
                          })}
                        </Fragment>
                      );
                    })}
                </>
              }
              placement="bottom-start"
              id="tt-error-message"
            >
              <ReportProblemIcon
                sx={{ height: theme.spacing(2), margin: `0 ${theme.spacing(0.5)}` }}
                data-testid="qb-report-problem-icon"
              />
            </Tooltip>
            <Typography variant="body1" data-testid="qb-error-message">
              {validation.message}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default withErrorBoundary(QueryBuilderExecutor);
