import {
  CheckBoxOutlineBlankOutlined as CheckBoxOutlineBlankOutlinedIcon,
  CheckBoxOutlined as CheckBoxOutlinedIcon,
} from '@mui/icons-material';
import {
  Box,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { getTitle, getTooltip } from '../../pages/query-builder/options/utils';
import { FONT_LIGHT, LITE_TEXT_COLOR } from '../../utils/styleUtils';
import InfoIconTooltip from '../InfoIconTooltip';
import { CheckboxProps } from './types';
import { FONT_ALIGNED_TEXT_STYLE } from '../../theme';

const CheckboxStyled: FC<MuiCheckboxProps> = styled((props: MuiCheckboxProps) => (
  <MuiCheckbox
    icon={<CheckBoxOutlineBlankOutlinedIcon />}
    checkedIcon={<CheckBoxOutlinedIcon />}
    {...props}
  />
))(({ theme }) => ({
  fontFamily: FONT_LIGHT,
  fontWeight: 200,
  padding: 0,
  marginRight: theme.spacing(0.5),
  color: 'inherit',
  '&.Mui-checked, &.MuiCheckbox-indeterminate': {
    color: 'inherit',
  },
  '&.Mui-disabled, &.Mui-disabled ~ .MuiTypography-root.Mui-disabled': {
    color: 'inherit',
    opacity: 0.4,
  },
  '& .MuiSvgIcon-root': {
    fontSize: theme.spacing(2),
    color: 'inherit',
  },
  ...FONT_ALIGNED_TEXT_STYLE,
}));

const Checkbox: FC<CheckboxProps> = ({ option, onOptionChange, ...checkboxProps }) => {
  const theme = useTheme();
  const tooltip = getTooltip(option);
  const { disabled = false, forbidden = false, id, selected } = option;

  return (
    <FormControlLabel
      sx={{
        color: LITE_TEXT_COLOR,
      }}
      disabled={disabled || forbidden}
      key={id}
      onChange={() => onOptionChange(id)}
      control={<CheckboxStyled {...checkboxProps} size="small" checked={selected} />}
      label={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Typography variant="h6">{getTitle(option)}</Typography>
          {tooltip.length > 0 && (
            <InfoIconTooltip tooltip={tooltip} sx={{ marginLeft: theme.spacing(0.5) }} />
          )}
        </Box>
      }
    />
  );
};

export default Checkbox;
