import { isNumber, toNumber } from 'lodash';

export const isValidLatitude = (lat: any): boolean => isNumber(lat) && lat > -90 && lat < 90;

export const isValidLongitude = (lng: any): boolean => isNumber(lng) && lng > -180 && lng < 180;

const isCoercedNumber = (val: any): boolean => !Number.isNaN(parseFloat(val));

export const numberHasDecimalPlace = (num: number): boolean => !Number.isInteger(num);
export const bothCoordsHaveDecimal = (coordsStr: string): boolean => {
  const arr = coordsStr.split(',');
  if (arr.length !== 2) return false; // reject when number of coords !== 2
  const coordsNumArray = arr.filter(isCoercedNumber).map((n: any) => toNumber(n));
  return coordsNumArray.every(numberHasDecimalPlace);
};

export const inputIsNumberCommaNumber = (coordsStr: string): boolean => {
  const arr = coordsStr.split(',');
  if (arr.length !== 2) return false; // reject when number of coords !== 2
  const coordsNumArray = arr.filter(isCoercedNumber).map((n: any) => toNumber(n));
  return coordsNumArray.length === 2;
};

export const validateCoordsInput = (coordsStr: string): boolean => {
  const arr = coordsStr.split(',');
  if (arr.length !== 2) return false; // reject when number of coords !== 2
  const coordsNumArray = arr.filter(isCoercedNumber).map((n: any) => toNumber(n));

  if (coordsNumArray.length === 2) {
    const [lat, lng] = coordsNumArray;
    return isValidLatitude(lat) && isValidLongitude(lng);
  }

  return false;
};

export default validateCoordsInput;
