import { Box, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import Accordion from '../../../components/accordion/Accordion';
import ChipGroup from '../../../components/chip/ChipGroup';
import RadioGroup from '../../../components/radio/RadioGroup';
import { RadioFormProps, RadioOperation } from '../../../components/radio/types';
import { CenteredRowStyle } from '../../../utils/styleUtils';
import { itemSelected } from '../utils';
import { extractChipItemProps } from '../../../components/chip/util';

const GridSize: FC<RadioFormProps> = ({ list, setList, title }) => {
  // NOTE: GridSize Chips do not have the delete option because radios are single-select

  const chips = useMemo(
    () =>
      list.filter(itemSelected).map((item) => {
        const chipBase = extractChipItemProps(item);
        return {
          ...chipBase,
        };
      }),
    [list],
  );
  return (
    <Accordion
      accordionTitle={
        <Box sx={{ ...CenteredRowStyle, alignItems: 'center' }}>
          <Typography variant="h6">{title}</Typography>
          {chips.length > 0 && <ChipGroup items={chips} />}
        </Box>
      }
    >
      <RadioGroup
        options={list}
        onRadioClick={(id: string) => {
          setList({ operation: RadioOperation.Select, id });
        }}
      />
    </Accordion>
  );
};

export default GridSize;
