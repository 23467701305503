import { FC, useEffect } from 'react';
import { AccordionProps } from './Accordion';
import AccordionDetails from './AccordionDetails';
import AccordionSummary from './AccordionSummary';
import StyledAccordion from './StyledAccordion';

interface ControlledAccordionProps extends AccordionProps {
  isExpanded: boolean;
  setExpanded: (prevState: any) => void;
  'data-testid'?: string;
}

const ControlledAccordion: FC<ControlledAccordionProps> = ({
  expandable = true,
  option,
  onAccordionChange = () => {},
  accordionDetails,
  accordionTitle,
  children,
  isExpanded,
  setExpanded,
  'data-testid': dataTestId,
  ...accordionProps
}) => {
  useEffect(() => {
    // NOTE: Only enforce this rule when `expandable` changed; therefore the dependency array must only contain [expandable]
    if (expandable && !isExpanded) {
      setExpanded((prevFlag: boolean) => !prevFlag);
    }

    if (!expandable && isExpanded) {
      setExpanded((prevFlag: boolean) => !prevFlag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandable]);

  return (
    <StyledAccordion
      data-testid={dataTestId ?? 'styled-accordion'}
      expanded={isExpanded}
      onChange={() => {
        if (expandable) {
          setExpanded((prevFlag: boolean) => !prevFlag);
          onAccordionChange(option);
        }
      }}
      {...accordionProps}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        {accordionTitle}
      </AccordionSummary>
      <AccordionDetails>
        {accordionDetails}
        {children}
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default ControlledAccordion;
