import { APIResponse } from '../http';
import { UUID } from '../../api/types';
import { getDoc360Token, getDundasToken } from './token';
import { APIToken } from './types';

export async function getDoc360TokenAdapter(userId: UUID): Promise<APIResponse<APIToken>> {
  const { data, error } = await getDoc360Token(userId);
  if (error) {
    return { data: null, error };
  }
  return { data, error: null };
}

export async function getDundasTokenAdapter(userId: UUID): Promise<APIResponse<APIToken>> {
  const { data, error } = await getDundasToken(userId);
  if (error) {
    return { data: null, error };
  }
  return { data, error: null };
}
