import { APICreateExportInput, APIExport } from './types';
import { APIResponse } from '../http';
import { create, get } from '../rest';
import { UUID } from '../../api/types';

export async function createExport(input: APICreateExportInput): Promise<APIResponse<APIExport>> {
  return await create('/exports', input);
}

export async function getExport(id: UUID): Promise<APIResponse<APIExport>> {
  return await get('/exports', id);
}
