import { camelCase, isArray, isDate, isNil } from 'lodash';

export function convertKeysToCamelCase(obj: Record<string, any>): Record<string, any> {
  const retObj: Record<string, any> = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(obj)) {
    // // eslint-disable-next-line no-console
    // console.log(key, value);
    const camelKey = camelCase(key);
    retObj[`${camelKey}`] =
      typeof value === 'object' && !(isNil(value) || isArray(value) || isDate(value))
        ? convertKeysToCamelCase(value)
        : value;
  }
  return retObj;
}
