import { Close as CloseIcon } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  CircularProgress,
  createFilterOptions,
  IconButton,
  ListItem,
  useTheme,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Category } from '../../../api/portfolios/types';
import TextInput from '../../../components/text-input/TextInput';
import { reactKey } from '../../../utils/reactUtils';
import { convertToTitleCase } from '../../../utils/stringUtils';
import { CenteredRowStyle, WHITE_BORDER } from '../../../utils/styleUtils';
import { CategorySelectorProps, IdleStateVariant } from './types';
import { renderIdleStateVariant } from './util';
import { StyledThinLabel } from '../../../components/labels/StyledLabel';

interface CategoryOption {
  inputValue?: string;
  id?: string;
  name: string;
}

const applyInputValue = (item: Category): CategoryOption => ({ ...item, inputValue: undefined });

const autocompleteFilter = createFilterOptions<CategoryOption>();

const getOptionLabel = (option: any): string => {
  // Value selected with enter, right from the input
  if (typeof option === 'string') {
    return option;
  }

  const inputValue = option?.inputValue ?? '';

  if (inputValue) {
    return inputValue;
  }

  // Regular option
  return option.name;
};
const CategorySelector: FC<CategorySelectorProps> = ({
  categories,
  selectedCategory,
  onCreateAndAssignNewCategory,
  onAssignCategory,
  onDisassociateCategory,
  isEditingCategory,
  idleStateVariant = IdleStateVariant.NORMAL,
  externalControls = false,
  AutocompleteProps = { sx: {} },
  TypographyProps = { sx: {} },
  onCancel = () => {},
  ...boxProps
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(
    isEditingCategory !== undefined ? isEditingCategory : false,
  );
  const [addingClientName, setAddingClientName] = useState<boolean>(false);
  const DEFAULT_CLIENT_NAME_ID = 'type-client-name-default-option';
  const DEFAULT_CLIENT_NAME_OPTION: CategoryOption = {
    name: '<<Type Client Name>>',
    id: DEFAULT_CLIENT_NAME_ID,
    inputValue: '',
  };

  const [localCategories, setLocalCategories] = useState<CategoryOption[]>([
    DEFAULT_CLIENT_NAME_OPTION,
    ...categories.map(applyInputValue),
  ]);

  useEffect(() => {
    if (isEditingCategory !== undefined) {
      setEditMode(isEditingCategory);
    }
  }, [isEditingCategory]);

  const handleCreateAndAssignNewCategory = async ({ name }: { name: string }): Promise<void> => {
    await setLoading(true);
    // create
    const isAlreadyPresent = [...categories.map(applyInputValue)].find(
      (category: CategoryOption) => category.name === name,
    );
    if (isAlreadyPresent) {
      void handleAssignCategory(isAlreadyPresent);
      setEditMode(false);
      if (addingClientName) {
        setAddingClientName(false);
      }
    } else {
      const newCategory = await onCreateAndAssignNewCategory({ categoryName: name });
      if (newCategory !== null) {
        // assign
        // -- This should be handled by the handler, `onCreateAndAssignNewCategory`
        // close
        setEditMode(false);
      }
    }

    await setLoading(false);
  };

  useEffect(() => {
    setLocalCategories([DEFAULT_CLIENT_NAME_OPTION, ...categories.map(applyInputValue)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const [selectedLocalCategory, setLocalCategory] = useState<Category | null>(selectedCategory);

  useEffect(() => {
    setLocalCategory(selectedCategory);
  }, [selectedCategory]);

  const handleDisassociateCategory = async (): Promise<void> => {
    await setLoading(true);
    await onDisassociateCategory();
    await setLoading(false);

    setLocalCategory(null);
  };

  const handleAssignCategory = async (category: any): Promise<void> => {
    await setLoading(true);
    const successful = await onAssignCategory(category);

    if (successful) {
      // close
      setEditMode(false);
    }
    await setLoading(false);
  };

  const handleCancel = (): void => {
    onCancel();

    // Resetting the client name placeholder
    if (addingClientName) {
      setAddingClientName(false);
    }

    // close & keep the rest of the state the same
    setEditMode(false);
  };

  const ACTION_BUTTONS = [
    {
      disabled: false,
      onClick: handleCancel,
      Icon: CloseIcon,
      id: 'action-btn-2-category-selector',
      title: 'cancel',
    },
  ];

  const [inputTextValue, setInputTextValue] = useState<string>(selectedLocalCategory?.name ?? '');

  useEffect(() => {
    setInputTextValue(selectedLocalCategory?.name ?? '');
  }, [selectedLocalCategory]);

  return (
    <Box
      {...boxProps}
      sx={{
        ...CenteredRowStyle,
        width:
          ACTION_BUTTONS.length > 0
            ? `calc(100% - ${theme.spacing(ACTION_BUTTONS.length * 4.5)})`
            : '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: `${theme.spacing(3)} !important`,
        ...(boxProps.sx ?? {}),
      }}
    >
      {idleStateVariant === IdleStateVariant.NORMAL && (
        <Box sx={{ paddingRight: theme.spacing(1) }}>
          <StyledThinLabel data-testid="lbl-category" variant="h6" sx={{ color: 'inherit' }}>
            Category:{' '}
          </StyledThinLabel>
        </Box>
      )}

      <Box
        sx={{
          ...CenteredRowStyle,
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: editMode ? 'unset' : '100%',
        }}
      >
        {!editMode ? (
          <>
            {renderIdleStateVariant({
              idleStateVariant,
              category: selectedCategory,
              TypographyProps,
              buttonSx: {
                border: WHITE_BORDER,
                borderRadius: theme.spacing(0.5),
                padding: `${theme.spacing(0.375)} ${theme.spacing(0.75)}`,
              },
              onEditClick: () => {
                setEditMode(true);
              },
              ...(!externalControls && selectedLocalCategory !== null
                ? {
                    onCancelClick: () => {
                      void handleDisassociateCategory();
                    },
                  }
                : {}),
            })}
          </>
        ) : (
          <>
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
              }}
            >
              <Autocomplete
                sx={{ width: '100%', minWidth: theme.spacing(18.75), ...AutocompleteProps.sx }}
                // clearOnBlur // NOTE: When this is true, it wipes the user's input stored by `inputTextValue` when the user clicks outside of the input box
                selectOnFocus
                openOnFocus
                handleHomeEndKeys
                freeSolo
                id="category-autocomplete-selector"
                options={localCategories}
                value={selectedLocalCategory}
                clearIcon={null}
                disabled={loading}
                inputValue={inputTextValue}
                onInputChange={(event, newValue) => setInputTextValue(newValue)}
                onChange={(event, newValue) => {
                  if (newValue === null) {
                    // console.log('---- TRIGGER 0', newValue);
                    // TRIGGER 0.: This is when:
                    // ............. a) The user hits `Enter` in the input, but there is no input
                    // ............. b) The user clears the input in the textarea by selecting and deleting, or hitting backspace all the way to empty

                    return;
                  }

                  if (typeof newValue === 'string') {
                    // console.log('---- TRIGGER 1', newValue);
                    // TRIGGER 1.: This is when the user hits `Enter` in the input
                    const updatedUserInput = convertToTitleCase(newValue);

                    if (updatedUserInput.length > 0) {
                      const filteredCategories = autocompleteFilter(localCategories, {
                        inputValue: updatedUserInput,
                        getOptionLabel,
                      });

                      // If there are filtered categories, choose whichever one would be at the top of the list rendered by `filterOptions`
                      if (filteredCategories.length > 0) {
                        void handleAssignCategory(filteredCategories[0]);
                        return;
                      }

                      // ...otherwise, create a new category
                      const newCategory = {
                        name: updatedUserInput,
                        id: reactKey('cs-create-from-enter-ui-'),
                      };

                      setLocalCategory(newCategory);
                      void handleCreateAndAssignNewCategory(newCategory);
                      return;
                    }

                    return;
                  }

                  if (newValue?.id === DEFAULT_CLIENT_NAME_ID) {
                    if (!addingClientName) {
                      setAddingClientName(true);
                    }
                    setInputTextValue('');
                    return;
                  }

                  if (addingClientName) {
                    setAddingClientName(false);
                  }

                  const inputValue = newValue?.inputValue ?? '';

                  if (inputValue.length > 0) {
                    // console.log('---- TRIGGER 2', newValue);
                    // TRIGGER 2.: This is when the user clicks on the prompt that says `Add "<category-name>"`

                    // Create a new value from the user input
                    const newCategory = {
                      name: convertToTitleCase(inputValue),
                      id: reactKey('cs-change-iv-ui-'),
                    };
                    setLocalCategory(newCategory);
                    void handleCreateAndAssignNewCategory(newCategory);

                    return;
                  }

                  // console.log('---- TRIGGER 3', newValue);
                  // TRIGGER 3. This is what happens when the user clicks on one of the options from the list
                  void handleAssignCategory(newValue);
                }}
                filterOptions={(options, params) => {
                  // NOTE: This is the function that builds the list of filtered categories for the Autocomplete menu
                  const filteredCategories = autocompleteFilter(options, params);
                  const inputValue = params?.inputValue ?? '';

                  if (inputValue.trim().length === 0) {
                    return filteredCategories;
                  }

                  const updatedUserInput = convertToTitleCase(inputValue);

                  if (updatedUserInput.length > 0) {
                    const matchesExisting =
                      options.find((option) => updatedUserInput === option.name) !== undefined;

                    // Suggest the creation of a new value
                    if (!matchesExisting) {
                      filteredCategories.push({
                        inputValue: updatedUserInput,
                        name: `Add "${updatedUserInput}"`,
                        id: reactKey('cs-filter-option-ui-'),
                      });
                    }
                  }

                  return filteredCategories;
                }}
                getOptionLabel={getOptionLabel}
                onKeyUp={(e) => {
                  switch (e.key) {
                    case 'Enter':
                      // NOTE: This will happen, ONLY if `TRIGGER 1` in onChange (see above) does not happen
                      handleCancel();
                      break;
                    case 'Escape':
                      handleCancel();
                      break;
                    default:
                      break;
                  }
                }}
                renderOption={(props, option: CategoryOption) => (
                  <ListItem {...props} key={option.id}>
                    {option.name}
                  </ListItem>
                )}
                renderInput={(params) => (
                  <TextInput
                    variant="standard"
                    {...params}
                    value={inputTextValue}
                    onChange={(event) => {
                      if (addingClientName) {
                        setAddingClientName(false);
                      }
                      const newTextValue = event.target.value;
                      setInputTextValue(newTextValue);
                    }}
                    formControlProps={{
                      fullWidth: true,
                      sx: {
                        margin: 0,
                        padding: 0,
                        height: `${theme.spacing(4)} !important`,
                        '& .MuiTextField-root': {
                          margin: 0,
                        },
                      },
                      margin: 'none',
                    }}
                    margin="none"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      sx: {
                        height: `${theme.spacing(3.75)} !important`,
                        '& .MuiInputBase-input': {
                          margin: 0,
                          padding: '0 !important',
                          fontSize: theme.spacing(1.75), // :: 14px
                        },
                        '& .MuiInputBase-root': {
                          borderRadius: '0 !important',
                          height: `${theme.spacing(3.75)} !important`,
                        },
                        width: '100%',
                      },
                    }}
                    // NOTE: InputProps from CategorySelectorPrev (taller than the PortfolioCard variant)
                    // InputProps={{
                    //   ...params.InputProps,
                    //   disableUnderline: true,
                    //   sx: {
                    //     borderRadius: '0px !important',
                    //     borderBottom: '1px solid rgba(33, 33, 33, 0.4)',
                    //     '& .MuiInputBase-input': {
                    //       margin: 0,
                    //       padding: '0px !important',
                    //       fontSize: '0.875rem',
                    //     },
                    //     '& .MuiInputBase-root': {
                    //       borderRadius: '0px !important',
                    //       height: '1.5rem !important',
                    //     },
                    //     minWidth: '200px',
                    //     // width: '200px',
                    //   },
                    // }}
                    placeholder={addingClientName ? DEFAULT_CLIENT_NAME_OPTION.name : 'autosuggest'}
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                marginLeft: theme.spacing(1),
              }}
            >
              {loading ? (
                <CircularProgress
                  size={16}
                  color="inherit"
                  sx={{ marginLeft: theme.spacing(0.75) }}
                />
              ) : (
                ACTION_BUTTONS.map(({ disabled, onClick, id, title, Icon }) => (
                  <IconButton
                    key={id}
                    data-cy={`category-action-buttons-button-${title}`}
                    aria-label={`category-action-buttons-button-${title}`}
                    aria-controls="category-action-buttons"
                    onClick={onClick}
                    color="inherit"
                    disableRipple
                    sx={{
                      border: WHITE_BORDER,
                      borderRadius: theme.spacing(0.5),
                      padding: `${theme.spacing(0.375)} ${theme.spacing(0.75)}`,
                    }}
                    disabled={disabled}
                  >
                    <Icon sx={{ height: theme.spacing(2), width: theme.spacing(2) }} />
                  </IconButton>
                ))
              )}
            </Box>
          </>
        )}
        {/* {!externalControls && selectedLocalCategory !== null && !editMode && (
          <IconButton
            data-testid="category-context-menu-cancel-button"
            aria-label="category-context-menu-button"
            aria-controls="category-context-menu"
            aria-haspopup="true"
            onClick={() => {
              void handleDisassociateCategory();
            }}
            color="inherit"
            disableRipple
          >
            <CancelIcon sx={{ height: theme.spacing(2), width: theme.spacing(2) }} />
          </IconButton>
        )} */}
      </Box>
    </Box>
  );
};
export default CategorySelector;
