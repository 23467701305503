import { UUID } from '../../api/types';
import { create, del, get, list, update } from '../rest';
import { APIResponse } from '../http';
import { APICreatePortfolioInput, APIPortfolio, APIUpdatePortfolioInput } from './types';
import { MAX_LIST_API_LIMIT } from '../constants';

export async function listPortfolios(): Promise<APIResponse<APIPortfolio[]>> {
  return await list('/portfolios', { limit: MAX_LIST_API_LIMIT });
}

export async function createPortfolio(
  input: APICreatePortfolioInput,
): Promise<APIResponse<APIPortfolio>> {
  return await create('/portfolios', input);
}

export async function getPortfolio(id: UUID): Promise<APIResponse<APIPortfolio>> {
  return await get('/portfolios', id);
}

export async function updatePortfolio(
  id: UUID,
  updates: APIUpdatePortfolioInput,
): Promise<APIResponse<APIPortfolio>> {
  return await update('/portfolios', id, updates);
}

export async function deletePortfolio(id: UUID): Promise<APIResponse<null>> {
  return await del('/portfolios', id);
}
