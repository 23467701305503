import { Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

const LoadingTypography: FC<TypographyProps> = ({ ...props }) => {
  return (
    <Typography
      variant="body1"
      {...props}
      sx={{ textAlign: 'center', color: 'rgba(136,136,136,0.9)', ...props.sx }}
    />
  );
};

export default LoadingTypography;
