import {
  FormControlProps,
  FormControl as MuiFormControl,
  FormLabel as MuiFormLabel,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  StandardTextFieldProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, ReactElement } from 'react';
import {
  DISABLED_TEXT_BOX_BG_COLOR,
  DISABLED_TEXT_BOX_TEXT_COLOR,
  DISABLED_TEXT_BOX_BORDER,
  LITE_TEXT_COLOR,
  TEXT_BOX_BORDER,
} from '../../utils/styleUtils';

export const FormLabelStyled = styled(MuiFormLabel)(({ theme }) => ({
  color: LITE_TEXT_COLOR,
  // ----- h6 - START
  fontSize: theme.spacing(1.75), // 0.875rem :: 14px
  letterSpacing: '0',
  // ----- h6 - END
}));

const TextFieldStyled = styled(({ sx, disabled, ...props }: MuiTextFieldProps) => (
  <MuiTextField
    sx={{ border: disabled ? DISABLED_TEXT_BOX_BORDER : TEXT_BOX_BORDER, ...sx }}
    disabled={disabled}
    {...props}
  />
))(({ theme }) => ({
  color: LITE_TEXT_COLOR,
  marginTop: theme.spacing(0.4),
  '& .MuiInput-underline:after': {
    border: 0,
  },
  '& .MuiInputBase-root': {
    height: theme.spacing(3.75),
    background: '#fff',
    paddingLeft: theme.spacing(0.5),
    border: 0,
    '&:hover': {
      border: 0,
    },
    '&::before': {
      borderBottom: '0 !important',
    },
    '&.Mui-disabled': {
      background: DISABLED_TEXT_BOX_BG_COLOR,
    },
    '&.Mui-disabled .MuiInputBase-input.Mui-disabled': {
      color: DISABLED_TEXT_BOX_TEXT_COLOR,
      WebkitTextFillColor: DISABLED_TEXT_BOX_TEXT_COLOR,
    },
    '& .MuiInputBase-input': {
      padding: `${theme.spacing(2)} ${theme.spacing(0.75)}`,
    },
    '& .MuiSelect-icon': {
      right: 0,
    },
    '& input:focus-visible': {
      borderWidth: 0,
      outline: 0,
    },
  },
}));

export const FormControlStyled = styled(MuiFormControl)(({ theme }) => ({
  margin: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
}));

export type TextInputProps = StandardTextFieldProps & {
  formLabel?: string | ReactElement;
  children?: ReactElement | ReactElement[] | string;
  formControlProps?: FormControlProps;
};

const TextInput: FC<TextInputProps> = ({
  formLabel = '',
  children,
  formControlProps,
  InputProps,
  ...textFieldProps
}: TextInputProps) => {
  return (
    <FormControlStyled {...formControlProps}>
      {formLabel && <FormLabelStyled>{formLabel}</FormLabelStyled>}
      <TextFieldStyled
        variant="standard"
        margin="dense"
        InputProps={{
          disableUnderline: true,
          ...InputProps,
        }}
        {...textFieldProps}
      >
        {children}
      </TextFieldStyled>
    </FormControlStyled>
  );
};

export default TextInput;
