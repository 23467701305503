import { FC, PropsWithChildren } from 'react';
import { useTheme } from '@mui/material';
import { TourProvider } from '@reactour/tour';
import { loginTourSteps, tourStyles } from '../../utils/tourUtils';
import { NextButton, PrevButton } from './Navigation';
import '@reactour/popover/dist/index.css';

// Note: Need this wrapper for passing mui theme object to TourProvider
const TourWrapper: FC<PropsWithChildren<{}>> = ({ children }) => {
  const theme = useTheme();

  return (
    <TourProvider
      position="bottom"
      prevButton={PrevButton}
      nextButton={NextButton}
      steps={loginTourSteps}
      styles={tourStyles(theme)}
      showBadge={false}
    >
      {children}
    </TourProvider>
  );
};

export default TourWrapper;
