/* eslint-disable react/display-name */
import { useMemo } from 'react';
import { useTheme, BoxProps } from '@mui/material';
import { buildGlassClasses } from '../utils/glassUtils';
import { StyleProps } from '../utils/styleUtils';

export interface GlassBoxProps extends BoxProps {
  color?: string;
  blur?: number;
  noBorders?: boolean;
  square?: boolean;
  shadow?: boolean;
}

const withGlass = (glassBoxProps: GlassBoxProps) => (WrappedComponent: any) => {
  return (props: any) => {
    const {
      color = '#fff',
      blur = 7,
      noBorders = false,
      square = false,
      shadow = true,
    } = glassBoxProps;

    const theme = useTheme();

    const sxContent: StyleProps = useMemo(() => {
      const classes = buildGlassClasses({ color, blur, theme });
      let styles = { ...classes.glass };
      if (!noBorders) {
        styles = { ...styles, ...classes.glassBorders };
      }
      if (!square) {
        styles = { ...styles, ...classes.glassRounded };
      }
      if (!shadow) {
        styles = { ...styles, boxShadow: 'none' };
      }

      return styles;
    }, [theme, blur, color, noBorders, square, shadow]);

    // Should layer glass styles on top of existing, not replacing them
    const { sx = {} } = props;

    return <WrappedComponent sx={{ ...sx, ...sxContent }} {...props} />;
  };
};

export default withGlass;
