import { FC, Component, ErrorInfo, ReactNode } from 'react';

interface FallbackUIProps {
  name?: string;
  message?: string;
  componentStack?: string;
}

const FallbackUI: FC<FallbackUIProps> = ({ name = '', message = '', componentStack = '' }) => (
  <div role="alert">
    <details style={{ whiteSpace: 'pre-wrap', color: '#9FA1A3', fontSize: '1rem' }}>
      <summary>Something went wrong:</summary>
      <p>
        <strong style={{ color: 'rgb(206, 17, 38)' }}>{name}:</strong> {message}
      </p>
      <p>
        <strong style={{ color: 'rgb(206, 17, 38)' }}>Stack trace:</strong> {componentStack}
      </p>
    </details>
  </div>
);

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  errorInfo: FallbackUIProps;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      errorInfo: {},
    };
  }

  public static getDerivedStateFromError(error: Error): State {
    const { name, message } = error;
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorInfo: { name, message, componentStack: '' } };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const { name, message } = error;
    const { componentStack } = errorInfo;
    // TODO: CREATE AND CALL A FUNCTION, logErrorToMyService(error, errorInfo); FOR LOGGING EXCEPTIONS IN PRODUCTION

    this.setState({
      hasError: !!error,
      errorInfo: { name, message, componentStack },
    });
  }

  public render(): ReactNode | ReactNode[] {
    const { hasError, errorInfo } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <FallbackUI {...errorInfo} />;
    }

    return children;
  }
}

export default ErrorBoundary;
