import { Link, Stack, tooltipClasses, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import withErrorBoundary from '../../components/ErrorBoundary/withErrorBoundary';
import DropdownHandle, { LabelWeight } from '../../components/buttons/DropdownHandle';
import Menu from '../../components/menu/Menu';
import { MenuPositionX } from '../../components/menu/types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectNumberOfPortfolios,
  selectSelectedResultSet,
} from '../../redux/selectors/csgDataSelectors';
import { selectAppContentSection, selectVizNavMenuOpen } from '../../redux/selectors/uiSelectors';
import {
  VizMenuOption,
  selectDashboardId,
  selectDashboardTitle,
  selectNumberOfAvailableVisualizations,
  selectVizOptionsForSelectedRs,
  selectVizSectionEnabled,
} from '../../redux/selectors/vizSelectors';
import { AppSection, setAppSection, setVizNavMenu } from '../../redux/slices/uiSlice';
import { handleNoVizAvailableForRs, setDashboard } from '../../redux/slices/visualizationsSlice';
import { NAV_SEPARATOR_COLOR } from '../../utils/styleUtils';
import { ButtonRefEl, ClickButtonHandler, ElementId } from './BreadcrumbDropdownMenu';
import { emptyMenuItemSx } from './styleUtils';
import { trackCustomEvent } from '../../heap';
import Tooltip from '../../components/tooltip/Tooltip';
import TextUnderlineButton from '../../components/TextUnderlineButton';

const VisualizationsNavigation: FC = () => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();
  const selectedAppSection = useAppSelector(selectAppContentSection);
  const countPortfolios = useAppSelector(selectNumberOfPortfolios);
  const selectedResultSet = useAppSelector(selectSelectedResultSet);
  const vizOptions = useAppSelector(selectVizOptionsForSelectedRs);
  const availableVizCount = useAppSelector(selectNumberOfAvailableVisualizations);
  const vizAppSectionEnabled = useAppSelector(selectVizSectionEnabled);
  const vizNavMenuOpen = useAppSelector(selectVizNavMenuOpen);
  const selectedDashboardId = useAppSelector(selectDashboardId);
  const selectedDashboardTitle = useAppSelector(selectDashboardTitle);

  const buttonRef = useRef<ButtonRefEl>(null);

  const [anchorEl, setAnchorEl] = useState<ButtonRefEl>(null);

  const isMenuOpen = Boolean(anchorEl);
  const elementId: ElementId = isMenuOpen ? 'nav-viz-menu' : undefined;

  const setNewVizMenuState = (newState: boolean): void => {
    dispatch(setVizNavMenu(newState));
  };

  const onClickMenu = (): void => {
    setAnchorEl(buttonRef.current);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    setNewVizMenuState(false);
  };

  const onCloseMenu: ClickButtonHandler = (event) => {
    event.stopPropagation();
    handleClose();
  };

  const handleSelectVisualization = (dashboard: VizMenuOption['dashboard']): void => {
    dispatch(setAppSection(AppSection.Visualizations));
    dispatch(setDashboard(dashboard));
    handleClose();
  };
  useEffect(() => {
    setAnchorEl(vizNavMenuOpen ? buttonRef.current : null);
  }, [vizNavMenuOpen, setAnchorEl]);

  useEffect(() => {
    if (selectedAppSection === AppSection.Visualizations && !vizAppSectionEnabled) {
      if (selectedResultSet) {
        void dispatch(handleNoVizAvailableForRs());
        return;
      }

      // NOTE: If there is no result set selected, just redirect them to Explorer, without sending a toast
      dispatch(setAppSection(AppSection.Explorer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppSection, vizAppSectionEnabled, selectedResultSet]);

  const menuTitle = useMemo(() => {
    if (selectedAppSection !== AppSection.Visualizations || selectedDashboardTitle.length === 0) {
      if (smScreen) {
        return 'Visualizations';
      }

      if (availableVizCount > 0) {
        return `Available Visualizations (${availableVizCount})`;
      }

      return 'Available Visualizations';
    }

    return `Available Visualizations (${availableVizCount}) : ${selectedDashboardTitle}`;
  }, [availableVizCount, selectedAppSection, smScreen, selectedDashboardTitle]);

  const breadcrumbListItems = useMemo(() => {
    return vizAppSectionEnabled
      ? vizOptions.map(({ id, dashboard, disabled = false }) => {
          return {
            title: dashboard.title,
            onClick: () => {
              trackCustomEvent('Clicks on Available Visualizations', {
                visualizationType: dashboard.title,
              });
              handleSelectVisualization(dashboard);
            },
            id: `viz-nav-item-${id}`,
            selected: dashboard.id === selectedDashboardId,
            disabled: true,
          };
        })
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vizAppSectionEnabled, vizOptions, selectedDashboardId]);

  const getMenu = (): JSX.Element => {
    return breadcrumbListItems.length > 0 ? (
      <Menu
        data-testid="nav-viz-menu"
        aria-labelledby="nav-viz-menu"
        id={elementId}
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        options={breadcrumbListItems}
        horzOrientation={MenuPositionX.LEFT}
      />
    ) : (
      <Menu
        data-testid="nav-viz-menu"
        aria-labelledby="nav-viz-menu"
        id={elementId}
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        horzOrientation={MenuPositionX.LEFT}
      >
        <Typography variant="h6" sx={emptyMenuItemSx} noWrap>
          No visualizations available
        </Typography>
      </Menu>
    );
  };

  return (
    <>
      <Typography variant="h6" sx={{ paddingRight: 0.5 }} color={NAV_SEPARATOR_COLOR}>
        /
      </Typography>
      {/* TODO: At some point this should use the TextDropdown, BreadcrumbDropdownMenu pattern that other components inside of NavigationDropdown use */}
      <Tooltip
        title={
          <Typography variant="h6">
            Please visit{' '}
            <Link
              href="https://csg.jupiterintel.com/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: 'inherit' }}
              data-testid="jupiter-csg-link"
            >
              <TextUnderlineButton TypographyProps={{ variant: 'h6', sx: { paddingTop: 0 } }}>
                csg.jupiterintel.com
              </TextUnderlineButton>
            </Link>{' '}
            for the latest visualizations
          </Typography>
        }
        sx={{
          [`& .${tooltipClasses.tooltip}`]: {
            marginTop: `${theme.spacing(1)} !important`,
          },
        }}
        placement="bottom-end"
      >
        <Stack direction="row" spacing={0}>
          <DropdownHandle
            disabled
            size="large"
            data-testid="viz-breadcrumb-button"
            aria-label="text-dropdown-menu-button"
            aria-controls="menu-app-bar"
            aria-haspopup="true"
            onClickMenu={onClickMenu}
            color="inherit"
            disableRipple
            sx={{ minWidth: 'auto', padding: 0 }}
            label={menuTitle}
            labelProps={{
              variant: 'h6',
              noWrap: true,
            }}
            labelWeight={LabelWeight.NORMAL}
            isHandleOn={isMenuOpen}
            ref={buttonRef}
          />
        </Stack>
      </Tooltip>
      {getMenu()}
    </>
  );
};

export default withErrorBoundary(VisualizationsNavigation);
