import { Typography } from '@mui/material';
import { FC } from 'react';
import { TitleProps } from './types';

const Title: FC<TitleProps> = ({ statusIcon, title, sx = {} }) => {
  return statusIcon ? (
    <>
      {statusIcon}
      <Typography
        data-testid={`title - ${title}`}
        sx={{ fontSize: '1.375rem', ...sx }}
        variant="h6"
        noWrap // NOTE: `noWrap` is what allows for `text-overflow: text-overflow: ellipsis` to work in the Flexbox context
      >
        {title}
      </Typography>
    </>
  ) : (
    <Typography
      data-testid={`title - ${title}`}
      sx={{ fontSize: '1.375rem', ...sx }}
      variant="h6"
      noWrap // NOTE: `noWrap` is what allows for `text-overflow: text-overflow: ellipsis` to work in the Flexbox context
    >
      {title}
    </Typography>
  );
};
export default Title;
