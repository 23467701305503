import { CheckboxProps as MuiCheckboxProps } from '@mui/material';
import { ParameterOptionsUI } from '../../pages/query-builder/types';

export interface CheckboxOption extends ParameterOptionsUI {
  selected: boolean; // NOTE: within interface ParameterOptionsUI, `selected` is already a required field. But, for CheckboxOptions, `selected` is especially critical.
}

export enum CheckboxOperation {
  SelectAll = 'SELECT_ALL', // turn ALL items ON
  DeselectAll = 'DESELECT_ALL', // turn ALL items OFF
  Toggle = 'TOGGLE', // switch one item's state to its inverse
  SelectOne = 'SELECT_ONE', // turn one item ON
  DeselectOne = 'DESELECT_ONE', // turn one item OFF
  // Enable/Disable Operations Enum
  DisableAll = 'DISABLE_ALL', // disable ALL items
  DisableOne = 'DISABLE_ONE', // disable one item
  EnableAll = 'ENABLE_ALL', // enable ALL items
  EnableOne = 'ENABLE_ONE', // enable one item
}

export interface UpdateCheckboxInstructions {
  id?: string;
  operation: CheckboxOperation;
  options?: CheckboxOption[];
  skipForbidden?: boolean;
}

export interface CheckboxProps extends MuiCheckboxProps {
  option: CheckboxOption;
  onOptionChange: (id: string) => void;
}

export interface CheckboxListProps {
  options: CheckboxOption[];
  onOptionChange: (id: string) => void;
  isRow?: boolean;
  noMarginBottom?: boolean;
  onSelectAll?: () => void;
  onDeselectAll?: () => void;
}

export interface CheckboxFormProps {
  list: CheckboxOption[];
  setList: (instructions: UpdateCheckboxInstructions) => void;
  //   setList: (nextList: CheckboxOption[]) => void;
  title: string;
}

export interface NestedCheckboxFormProps extends CheckboxFormProps {
  nestedList: CheckboxOption[];
  setNestedList: (instructions: UpdateCheckboxInstructions) => void;
  //   setNestedList: (nextList: CheckboxOption[]) => void;
  nestedTitle: string;
}
