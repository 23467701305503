export const LEGAL_ACKNOWLEDGE_KEY = 'legalAcknowledged';
export const LEGAL_ACKNOWLEDGE_VALUE = 'ACCEPTED'; // Change this value for Consent modal to re-appear for all users.

export const legalAcknowledged = (): boolean =>
  localStorage.getItem('legalAcknowledged') === LEGAL_ACKNOWLEDGE_VALUE;

export const setLegalAcknowledged = (bool: boolean): void => {
  if (bool) {
    localStorage.setItem('legalAcknowledged', LEGAL_ACKNOWLEDGE_VALUE);
  } else {
    localStorage.setItem('legalAcknowledged', 'DECLINED');
  }
};
