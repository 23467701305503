import { FC, ReactElement } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Typography } from '@mui/material';
import { StripePlaceholder } from '../../../../components/Iterator';
import { TOGGLE_TRACK_CHECKED_BG_COLOR, CenteredColumnStyle } from '../../../../utils/styleUtils';
import { StyledMediumLabel } from '../../../../components/labels/StyledLabel';
import { useAppDispatch } from '../../../../redux/hooks';
import { userLogout } from '../../../../redux/slices/userSlice';

interface Auth0ErrorContentProps {
  children?: ReactElement | ReactElement[];
}

const Auth0ErrorContent: FC<Auth0ErrorContentProps> = ({ children }) => {
  const { error, logout } = useAuth0();
  const dispatch = useAppDispatch();

  const logoutAndRedirectUser = async (): Promise<void> => {
    // eslint-disable-next-line no-console
    console.log('Logging out now.');
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    await dispatch(userLogout());
  };

  return (
    <StripePlaceholder sx={{ height: '100%' }}>
      <Box
        sx={{
          ...CenteredColumnStyle,
          width: '100%',
          height: '100%',
          color: TOGGLE_TRACK_CHECKED_BG_COLOR,
        }}
      >
        <StyledMediumLabel sx={{ color: TOGGLE_TRACK_CHECKED_BG_COLOR }} variant="h5">
          {error?.message ?? 'Could not login, something went wrong'}
        </StyledMediumLabel>
        <Typography sx={{ color: TOGGLE_TRACK_CHECKED_BG_COLOR }} variant="caption">
          <Button
            disableRipple
            color="inherit"
            variant="text"
            sx={{
              textDecoration: 'underline',
              padding: 0,
              '&:hover': {
                textDecoration: 'underline',
                backgroundColor: 'transparent',
              },
            }}
            onClick={() => {
              void logoutAndRedirectUser();
            }}
          >
            Click here
          </Button>{' '}
          to redirect to home.
        </Typography>
      </Box>
    </StripePlaceholder>
  );
};

export default Auth0ErrorContent;
