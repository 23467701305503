import { FC, HTMLAttributes } from 'react';
import { LINEAR_GRADIENT_5 } from '../../utils/styleUtils';

interface CircularIconProps extends HTMLAttributes<HTMLDivElement> {
  borderColor?: string;
  backgroundColor?: string;
}

const CircularIcon: FC<CircularIconProps> = ({
  borderColor,
  backgroundColor,
  ...props
}: CircularIconProps) => (
  <div
    style={{
      height: '10px',
      width: '10px',
      borderRadius: '50%',
      border: `1px solid ${borderColor ?? '#666666'}`,
      backgroundImage: backgroundColor ?? LINEAR_GRADIENT_5,
    }}
    {...props}
  />
);

export default CircularIcon;
