import {
  FiberManualRecord as FilledCircleIcon,
  FiberManualRecordOutlined as EmptyCircleIcon,
} from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { FC, Fragment, ReactElement, useEffect, useState } from 'react';
import { LINEAR_GRADIENT_4 } from '../utils/styleUtils';
import Tooltip from './tooltip/Tooltip';

export interface DotItem {
  selected: boolean;
  tooltip?: string;
  id: string;
  context?: any; // NOTE: This field is to help with actions handlePageChange
  showTooltip?: boolean;
}

interface DotMenuProps {
  dotsState: DotItem[];
  onChange: (dotClicked: DotItem) => void;
}

const renderDotIcon = ({
  dotItem,
  onClick,
}: {
  dotItem: DotItem;
  onClick: () => void;
}): ReactElement => {
  const { id, selected } = dotItem;
  return (
    <IconButton
      sx={{
        height: selected ? '1.25rem' : '1rem',
        width: selected ? '1.25rem' : '1rem',
        marginBottom: '0.5rem',
        background: 'rgba(221,221,221,0.10)',
        border: '1px solid rgba(204,204,204,0.10)',
        borderRadius: '50%',
        boxShadow: 'inset 1px 1px 2px 0 rgba(102,102,102,0.50)',
        color: 'rgba(0,0,0,0)',
      }}
      key={id}
      onClick={onClick}
    >
      {selected ? (
        <FilledCircleIcon
          sx={{
            height: '0.875rem',
            width: '0.875rem',
            background: LINEAR_GRADIENT_4,
            borderRadius: '50%',
          }}
        />
      ) : (
        <EmptyCircleIcon
          sx={{
            height: '1rem',
            width: '1rem',
            background: 'rgba(221,221,221,0.10)',
            border: '1px solid rgba(204,204,204,0.10)',
            borderRadius: '50%',
            boxShadow: 'inset 1px 1px 2px 0 rgba(102,102,102,0.50)',
            color: 'rgba(0,0,0,0)',
          }}
        />
      )}
    </IconButton>
  );
};

const DotMenu: FC<DotMenuProps> = ({ dotsState, onChange }) => {
  const [dots, setDots] = useState<DotItem[]>(dotsState);

  useEffect(() => {
    setDots(dotsState);
  }, [dotsState]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {dots.map((dotItem) => {
        const { tooltip = '', id, selected, showTooltip = undefined } = dotItem;

        const onClick = (): void => {
          onChange(dotItem);
          setDots((prevDots) =>
            prevDots.map((prevDotItem) => {
              if (prevDotItem.id === id) {
                return {
                  ...prevDotItem,
                  selected: true,
                };
              }

              return {
                ...prevDotItem,
                selected: false,
              };
            }),
          );
        };

        if (showTooltip === true) {
          return (
            <Tooltip title={tooltip} placement="left" open key={id}>
              {renderDotIcon({ dotItem, onClick })}
            </Tooltip>
          );
        }

        return tooltip.length > 0 ? (
          <Tooltip title={tooltip} placement="left" key={id}>
            {renderDotIcon({ dotItem, onClick })}
          </Tooltip>
        ) : (
          <Fragment key={id}>{renderDotIcon({ dotItem, onClick })}</Fragment>
        );
      })}
    </Box>
  );
};

export default DotMenu;
