import {
  CircularProgress,
  ListItemIcon,
  MenuItem,
  Popover,
  popoverClasses,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, useMemo } from 'react';
import { MenuOriginProps, MenuPositionX, MenuPositionY, MenuProps } from './types';
import { APP_TEXT_COLOR, StyleProps, TILE_BORDER } from '../../utils/styleUtils';
import { MENU_ARROW_SX, MENU_ORIGINS } from './utils';

const MenuStyled = styled(Popover)(({ theme }) => ({
  [`& .${popoverClasses.paper}`]: {
    overflow: 'visible',
    // boxShadow: '0 2px 4px 0 rgba(0,0,0,0.50)',
    boxShadow: `0 ${theme.spacing(0.25)} ${theme.spacing(0.5)} 0 rgba(0,0,0,0.50)`,
    backgroundColor: '#FFF',
    border: TILE_BORDER,
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    // For arrow of menu item
    '&:before': {
      // boxShadow: '1px 1px 4px -2px rgba(0,0,0,0.50)',
      boxShadow: `${theme.spacing(0.125)} ${theme.spacing(0.125)} ${theme.spacing(
        0.5,
      )} ${theme.spacing(-0.25)} rgba(0,0,0,0.50)`,
      border: TILE_BORDER,
      borderTop: 0,
      borderLeft: 0,
      content: '""',
      display: 'block',
      position: 'absolute',
      // top: '0px',
      // left: '6px',
      width: theme.spacing(1.2),
      height: theme.spacing(1.2),
      backgroundColor: '#FFF',
      // transform: 'translateY(-50%) rotate(-135deg)',
      zIndex: 0,
    },
  },
  [`& .${popoverClasses.root}`]: {
    margin: theme.spacing(1),
    padding: 0,
  },
}));

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  paddingTop: theme.spacing(0.375),
  paddingBottom: theme.spacing(0.375),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  fontSize: theme.spacing(1.75), // :: 0.875rem, 14px
  color: APP_TEXT_COLOR,
}));

const Menu: FC<MenuProps> = ({
  options = [],
  vertOrientation = MenuPositionY.BOTTOM,
  horzOrientation = MenuPositionX.RIGHT,
  MenuItemProps,
  children,
  sx,
  anchorEl,
  open,
  ...props
}: MenuProps) => {
  const theme = useTheme();

  const origins = useMemo<MenuOriginProps>(() => {
    return MENU_ORIGINS[`${vertOrientation}`][`${horzOrientation}`];
  }, [horzOrientation, vertOrientation]);

  const arrowSx = useMemo<StyleProps>(() => {
    return MENU_ARROW_SX[`${vertOrientation}`][`${horzOrientation}`];
  }, [horzOrientation, vertOrientation]);

  return (
    <MenuStyled
      anchorEl={anchorEl}
      onClose={props.onClose}
      onClick={props.onClick}
      sx={{
        ...sx,
        [`& .${popoverClasses.paper}:before`]: arrowSx,
      }}
      open={open}
      {...props}
      {...origins}
      PaperProps={{
        elevation: 0,
      }}
    >
      {options.map(
        ({ title, id, onClick, icon = null, disabled = false, inProgress = false, itemSx }) => {
          return (
            <MenuItemStyled
              {...MenuItemProps}
              key={id}
              onClick={onClick}
              disabled={disabled || inProgress}
              sx={{ ...MenuItemProps?.sx, ...itemSx }}
              disableRipple
              data-testid={id}
            >
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              {typeof title === 'string' ? (
                <Typography noWrap variant="body1" sx={{ fontSize: 'inherit' }}>
                  {title}
                </Typography>
              ) : (
                title
              )}
              {inProgress && (
                <CircularProgress
                  sx={{
                    fontSize: 'inherit',
                    marginLeft: theme.spacing(0.5),
                  }}
                  size={theme.spacing(1.75)}
                  color="inherit"
                />
              )}
            </MenuItemStyled>
          );
        },
      )}
      {children}
    </MenuStyled>
  );
};

export default Menu;
