import { filter, lowerCase } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { Portfolio } from '../../api/portfolios/types';
import withErrorBoundary from '../../components/ErrorBoundary/withErrorBoundary';
import GrowList from '../../components/GrowList';
import { InfoTextItem } from '../../components/InfoText';
import Iterator from '../../components/Iterator';
import LoadingTypography from '../../components/LoadingTypography';
import { MOCK_DATA_TITLE_REGEX } from '../../components/RenameField';
import SearchInput from '../../components/text-input/SearchInput';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectPortfolioLoading,
  selectPortfolios,
  selectResultSetCountByPortfolioId,
  selectSelectedPortfolio,
  selectSelectedPortfolioForSLR,
} from '../../redux/selectors/csgDataSelectors';
import {
  refreshCategoriesList,
  refreshPortfoliosList,
  selectPortfolio,
} from '../../redux/slices/csgDataSlice';
import { dateFormat } from '../../utils/datetime';
import {
  CARD_BORDER,
  CARD_BORDER_RADIUS,
  SECTION_DELIMITER,
  STRIPE_BG_COLOR,
  SIDE_PANEL_BG_COLOR,
} from '../../utils/styleUtils';
import PortfolioCard from './PortfolioCard';
import { StripeProps } from './MetadataStripe';

const buildCardContent = (item: Portfolio, resultSetCount: number): InfoTextItem[] => {
  try {
    const { id, createdAt, category } = item;

    return [
      {
        title: 'Created',
        value: dateFormat(createdAt, 'DD-MMM-YYYY'),
        id: `portfolio-card-content-${id}-created`,
      },
      {
        title: 'CSG Result Sets',
        value: `${resultSetCount}`,
        id: `portfolio-card-content-${id}-rs`,
      },
    ];
  } catch {}

  return [];
};

const PortfoliosStripe: FC<StripeProps> = ({ vertical = false, IteratorProps = { sx: {} } }) => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();
  const portfolios = useAppSelector(selectPortfolios);
  const selectedPortfolio = useAppSelector(selectSelectedPortfolio);
  const resultSetCountByPortfolioId = useAppSelector(selectResultSetCountByPortfolioId);
  const portfoliosLoading = useAppSelector(selectPortfolioLoading);
  const selectedPortfolioForSLR = useAppSelector(selectSelectedPortfolioForSLR);
  const [searchString, setSearchString] = useState<string>('');

  const refresh = async (): Promise<void> => {
    // eslint-disable-next-line no-console
    // console.log('Refreshing portfolios...');
    await dispatch(refreshCategoriesList());
    await dispatch(refreshPortfoliosList({}));
  };

  // This makes it so portfolios refresh whenever the user refreshes their browser...but also whenever PortfoliosStripe refreshes, which might not be ideal... WARNING:
  useEffect(() => {
    void refresh().catch((err) => {
      console.error('[PortfoliosStripe.refresh] Error (1):', err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredPortfolios = useMemo(() => {
    if ((searchString ?? '').trim().length > 0) {
      return filter(
        portfolios,
        (item) =>
          lowerCase(item.name).includes(lowerCase(searchString)) ||
          lowerCase(item?.category?.name).includes(lowerCase(searchString)),
      );
    }
    return portfolios;
  }, [searchString, portfolios]);

  return (
    <GrowList>
      <Iterator
        headless
        tailless
        vertical={vertical}
        title={smScreen ? '' : 'Portfolios'}
        sx={{
          ...IteratorProps.sx,
        }}
        // HeadProps={{ text: 'portfolio > csg results set > metadata'.toUpperCase() }}
        TailProps={{
          contextMenu: {
            /* Hiding the context menu for release 1 */
            // onClick: () => {
            //   // eslint-disable-next-line no-console
            //   console.log('This is toolkit context!');
            // },
          },
        }}
        TitleProps={{
          sx: {
            padding: {
              xs: `${theme.spacing(1)} 0`,
              sm: `${theme.spacing(1)} 0`,
              md: `${theme.spacing(2)} 0`,
            },
            color: SIDE_PANEL_BG_COLOR,
          },
          variant: 'h5',
        }}
        BodyContainerSx={{
          borderRadius: CARD_BORDER_RADIUS,
          backgroundColor: STRIPE_BG_COLOR,
        }}
        BodyProps={{
          gridContainerSx: {
            display: {
              xs: 'flex',
              sm: 'flex',
              md: 'block',
            },
            padding: 1.5,
          },
          gridItemSx: {
            border: 'none',
          },
        }}
        gridItemSx={{
          width: '100%',
          overflow: 'hidden',
        }}
        searchInputSx={{ padding: 1.5, borderBottom: SECTION_DELIMITER }}
        searchInput={
          <>
            <SearchInput
              data-testid="portfolio-search"
              placeholder={smScreen ? 'Portfolios' : ''}
              searchInput={searchString}
              onSearchInputChange={setSearchString}
            />
            {portfoliosLoading && (
              <LoadingTypography sx={{ borderBottom: CARD_BORDER }}>
                Loading portfolios...
              </LoadingTypography>
            )}
          </>
        }
      >
        <Stack spacing={1.5}>
          {filteredPortfolios.map((item) => {
            const { name, id } = item;
            const selected = Boolean(selectedPortfolio) && id === selectedPortfolio?.id;
            const resultSetCount: number = resultSetCountByPortfolioId[`${id}`] ?? 0;
            const disableDeletion: boolean =
              Boolean(selectedPortfolioForSLR) && id === selectedPortfolioForSLR?.id;

            return (
              <PortfolioCard
                key={id}
                portfolio={item}
                selected={selected}
                handleSelect={() => {
                  dispatch(selectPortfolio(item));
                }}
                handleInfoClick={() => {
                  // eslint-disable-next-line no-console
                  console.log('portfolio item:', item);
                }}
                cardContent={buildCardContent(item, resultSetCount)}
                isMockData={name.match(MOCK_DATA_TITLE_REGEX) !== null}
                isDeletionDisabled={disableDeletion}
              />
            );
          })}
        </Stack>
      </Iterator>
    </GrowList>
  );
};

export default withErrorBoundary(PortfoliosStripe);
