import { FC, ComponentType, CSSProperties, PropsWithChildren, ReactElement } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';

interface TourContentProps {
  Icon?: ComponentType<{
    style?: CSSProperties;
  }>;
  title?: ReactElement | string;
}

const TourContent: FC<PropsWithChildren<TourContentProps>> = ({ Icon, title, children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        paddingRight: 1.5,
      }}
    >
      <Grid container gap={1} wrap="nowrap">
        {Icon ? (
          <Grid item>
            {' '}
            <Icon style={{ height: theme.spacing(2), width: theme.spacing(2) }} />
          </Grid>
        ) : null}
        <Grid item>
          <Box>
            <Typography variant="body1" gutterBottom>
              {title}
            </Typography>
            <Box>{children}</Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TourContent;
