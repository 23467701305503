import { Box, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import SwitchButton, { IndividualButtonProps } from '../../../components/buttons/SwitchButton';
import useInterval, { delayInt } from '../../../hooks/useInterval';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectLocationData,
  selectUploadCategory,
  selectUploadRequestForValidation,
  selectValidationStatus,
} from '../../../redux/selectors/uploadSelectors';
import { setUploadDrawer } from '../../../redux/slices/uiSlice';
import {
  createPortfolioFromRequestId,
  resetUploadMode,
  resetUploadState,
  setValidationState,
} from '../../../redux/slices/uploadSlice';
import {
  CenteredRowStyle,
  ColumnStyle,
  DRAWER_COLUMN_CONTAINER,
  LITE_TEXT_COLOR,
  SIDE_PANEL_TITLE_COLOR,
  ACTIVE_BUTTON_STYLE,
} from '../../../utils/styleUtils';
import CategorySelector from '../CategorySelector';
import MapPreview, { LOCATION_PREVIEW_LIMIT } from '../MapPreview';
import { ValidationStatusUI } from '../types';
import ErrorLog from './ErrorLog';
import { StyledMediumLabel, StyledThinLabel } from '../../../components/labels/StyledLabel';
import { getFileValidationAdapter } from '../../../rest/portfolio/adapter';

const Validation: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const validationStatus = useAppSelector(selectValidationStatus);
  const validationResults = useAppSelector(selectUploadRequestForValidation);
  const selectedCategory = useAppSelector(selectUploadCategory);
  const locationData = useAppSelector(selectLocationData);
  const [loading, setLoading] = useState<boolean>(false);

  const [criticalError, setCriticalError] = useState<string | null>(null);
  const uploadRequestId = validationResults?.id ?? null;
  const uploadRequestName = validationResults?.name ?? '';
  const validationLoading = validationStatus === ValidationStatusUI.LOADING;

  const update = async (id: string, name: string): Promise<void> => {
    // eslint-disable-next-line no-console
    console.log('1. [Validation.poll] polling');
    const { data: nextValidation, error } = await getFileValidationAdapter(id, name);

    if (error) {
      const { message } = error;
      console.error('2. [Validation.poll] error (1)', message);
      setCriticalError(message);
      return;
    }

    // eslint-disable-next-line no-console
    console.log('2. [Validation.poll] data!', nextValidation);

    dispatch(setValidationState(nextValidation));
  };

  useEffect(() => {
    if (uploadRequestId !== null && validationLoading) {
      update(uploadRequestId, uploadRequestName).catch((err) => {
        console.error('[Validation.update] Error (1):', err);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadRequestId, uploadRequestName, validationLoading]);

  useInterval(
    async () => {
      if (uploadRequestId) {
        await update(uploadRequestId, uploadRequestName).catch((err) => {
          console.error('[Validation.update] Error (2):', err);
        });
      }
    },
    delayInt(3), // 3 seconds
    uploadRequestId !== null && validationLoading,
  );

  const onContinue = async (): Promise<void> => {
    if (validationResults) {
      setLoading(true);
      // This will create the portfolio, then refresh the portfolios list, and finally reset the drawer state and close.
      // NOTE: ^These side-effects tie this component to the Drawer

      await dispatch(
        createPortfolioFromRequestId({
          ...validationResults,
          categoryId: selectedCategory?.id,
        }),
      );
      setLoading(false);
    }
  };

  const onCancel = (): void => {
    // NOTE: These actions tie this component to the Drawer

    // reset the whole state of upload, including validation state
    dispatch(resetUploadState());

    // handleDrawerClose
    dispatch(setUploadDrawer(false));
    dispatch(resetUploadMode());
  };

  const actionButtons: IndividualButtonProps[] = [
    {
      title: loading ? 'Creating portfolio...' : 'Accept',
      onClick: () => {
        void onContinue();
      },
      disabled: validationStatus !== ValidationStatusUI.SUCCEEDED || !selectedCategory || loading,
      id: 'validation-action-button-1',
      buttonSx: ACTIVE_BUTTON_STYLE,
    },
    {
      title: 'Cancel',
      onClick: onCancel,
      id: 'validation-action-button-2',
    },
  ];

  return (
    <Box
      data-testid="validate-file-component"
      sx={{
        ...DRAWER_COLUMN_CONTAINER,
        color: LITE_TEXT_COLOR,
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          // Height excluding the category selector box
          height: `calc(100% - ${theme.spacing(12.5)})`,
        }}
      >
        <StyledMediumLabel
          variant="h5"
          sx={{ color: SIDE_PANEL_TITLE_COLOR, paddingBottom: theme.spacing(1) }}
        >
          NEW PORTFOLIO
        </StyledMediumLabel>
        <Box
          gap={0.5}
          sx={{
            ...CenteredRowStyle,
            justifyContent: 'flex-start',
            marginBottom: theme.spacing(0.625),
          }}
          data-testid="upload-validation-status"
        >
          <StyledThinLabel variant="h6">Validation State:</StyledThinLabel>
          <StyledMediumLabel variant="h6">{validationStatus}</StyledMediumLabel>
        </Box>
        {validationResults && (
          <Box
            data-testid="upload-validation-file-name"
            gap={0.5}
            sx={{
              ...CenteredRowStyle,
              justifyContent: 'flex-start',
              marginBottom: theme.spacing(0.75),
            }}
          >
            <StyledThinLabel variant="h6">File Name:</StyledThinLabel>
            <StyledMediumLabel variant="h6">
              {validationResults?.name ?? 'Not found.'}
            </StyledMediumLabel>
          </Box>
        )}

        <Box
          sx={{
            ...ColumnStyle,
            maxHeight: `calc(100vh - ${theme.spacing(20)})`,
            overflow: 'auto',
            gap: 1,
          }}
        >
          {validationStatus !== ValidationStatusUI.LOADING && locationData.length > 0 && (
            <Box sx={{ width: '100%' }}>
              <MapPreview locationData={locationData} />
              {locationData.length > LOCATION_PREVIEW_LIMIT && (
                <Typography
                  variant="body2"
                  sx={{ margin: theme.spacing(0.625), marginLeft: 0, marginRight: 0 }}
                >
                  Showing the first {LOCATION_PREVIEW_LIMIT} locations from your uploaded file.
                </Typography>
              )}
            </Box>
          )}
          <Box sx={{ width: '100%' }}>
            <ErrorLog
              validationResults={validationResults}
              validationFailed={validationStatus === ValidationStatusUI.FAILED}
              criticalError={criticalError}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          ...ColumnStyle,
          paddingBottom: theme.spacing(2),
          paddingTop: theme.spacing(1.5),
        }}
      >
        <CategorySelector sx={{ marginBottom: theme.spacing(2) }} />
        <SwitchButton type="normal" buttons={actionButtons} />
      </Box>
      <Box />
    </Box>
  );
};

export default Validation;
