import { convertUnixToUTCString } from '../utils/datetime';
import { APIPortfolio } from '../rest/portfolio/types';
import { Portfolio } from '../api/portfolios/types';
import {
  APIFileValidation,
  APIFileValidationLocn,
  APIFileValidationStatus,
} from '../rest/file/types';
import { ValidationResults, ValidationStatus } from '../pages/upload/types';

export function convertPortfolio(portfolio: APIPortfolio): Portfolio {
  return {
    category: {
      id: portfolio.category,
      name: '',
    },
    id: portfolio.id,
    name: portfolio.name,
    extra: `{\n  "locationsCount":${portfolio.location_count ?? 0} \n}`,
    createdAt: convertUnixToUTCString(portfolio.created_at),
    updatedAt: convertUnixToUTCString(portfolio.updated_at),
  };
}

function getValidationStatus(apiStatus: APIFileValidationStatus): ValidationStatus {
  switch (apiStatus) {
    case APIFileValidationStatus.succeeded: {
      return ValidationStatus.SUCCEEDED;
    }
    case APIFileValidationStatus.partially_succeeded: {
      return ValidationStatus.PARTIALLY_SUCCEEDED;
    }
    case APIFileValidationStatus.failed: {
      return ValidationStatus.FAILED;
    }
    default: {
      return ValidationStatus.UNSPECIFIED;
    }
  }
}

function getValidationErrors(
  apiErrors: APIFileValidation['errors'],
): ValidationResults['extra']['errors'] {
  if (!apiErrors) {
    return [];
  }
  const errors: ValidationResults['extra']['errors'] = apiErrors.map((error) => {
    const { code, description, row_number: rowNumber } = error;
    return {
      code,
      description,
      location: `row[${rowNumber}]`,
      locationId: '',
      longitude: '',
      latitude: '',
    };
  });
  return errors;
}

export function convertFileValidationWithLocations(
  fileValidation: APIFileValidation,
  fileValidationLocn: APIFileValidationLocn,
  name: string,
): ValidationResults {
  const {
    id,
    status,
    failed_count: failedCount,
    succeeded_count: succeededCount,
    total_count: totalCount,
    errors,
  } = fileValidation;

  const { locations } = fileValidationLocn;

  return {
    id,
    extra: {
      failedCount,
      succeededCount,
      totalCount,
      errors: getValidationErrors(errors) ?? null,
      locations: locations.map((location) => ({
        locationId: `${location.customer_location_id}`,
        latitude: `${location.geometry.latitude}`,
        longitude: `${location.geometry.longitude}`,
      })),
    },
    name: name ?? '',
    status: getValidationStatus(status),
  };
}
