import { UUID } from '../../api/types';
import { create, del, fetch, list, update } from '../rest';
import { APIResponse } from '../http';
import { APICreateResultSetInput, APIResultSet } from './types';
import { MAX_LIST_API_LIMIT } from '../constants';

export async function listResultSets(): Promise<APIResponse<APIResultSet[]>> {
  return await list('/result_sets', { limit: MAX_LIST_API_LIMIT });
}

export async function createResultSet(
  input: APICreateResultSetInput,
): Promise<APIResponse<APIResultSet>> {
  return await create('/result_sets', input);
}

export async function updateResultSet(id: UUID, name: string): Promise<APIResponse<APIResultSet>> {
  return await update('/result_sets', id, { name });
}

export async function deleteResultSet(id: UUID): Promise<APIResponse<null>> {
  return await del('/result_sets', id);
}

export async function getResultSetDefaultParams(
  csgVersion?: string,
  eiVersion?: string,
): Promise<APIResponse<any>> {
  const qParams: Record<string, string> = {};
  if (csgVersion) {
    qParams.csg_version = csgVersion;
  }
  if (eiVersion) {
    qParams.ei_version = eiVersion;
  }
  return await fetch('/app/parameters', qParams);
}
