import { Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';

interface GlassProps {
  color: string;
  blur: number;
  theme: Theme;
}

interface GlassClassesBuilder {
  glass: {
    backgroundColor: string;
    backgroundImage: string;
    backdropFilter: string;
    boxShadow: string;
  };
  glassBorders: {
    borderLeft: string;
    borderTop: string;
  };
  glassRounded: {
    borderRadius: string;
  };
}

export const buildGlassClasses = ({ color, blur, theme }: GlassProps): GlassClassesBuilder => ({
  glass: {
    backgroundColor: alpha(color, 0.4).toString(),
    backgroundImage: `linear-gradient(to bottom right, ${alpha(color, 0.2).toString()}, ${alpha(
      color,
      0,
    ).toString()})`,
    backdropFilter: `blur(${blur}px)`,
    boxShadow: '10px 10px 10px rgba(30, 30, 30, 0.1)',
  },
  glassBorders: {
    borderLeft: `solid 1px ${alpha(color, 0.3).toString()}`,
    borderTop: `solid 1px ${alpha(color, 0.8).toString()}`,
  },
  glassRounded: {
    borderRadius: theme.spacing(2),
  },
});

export default buildGlassClasses;
