export interface CheckIfStringIsSubsetProps {
  string: string;
  subString: string;
  isCaseSensitive?: false | boolean;
}

export const convertToTitleCase = (str: string): string =>
  str
    .split(' ')
    .map((indStr) => {
      return indStr.charAt(0).toUpperCase() + indStr.slice(1).toLowerCase();
    })
    .join(' ');

export const checkIfStringIsSubset = ({
  string,
  subString,
  isCaseSensitive = false,
}: CheckIfStringIsSubsetProps): boolean => {
  if (isCaseSensitive) {
    return string.includes(subString);
  }
  return string.toLowerCase().includes(subString.toLowerCase());
};
