const INPUT_CHAR_RESTRICTED: Record<string, string[]> = {
  INT: ['e', 'E', '+'],
  FLOAT: ['e', 'E', '+'],
  STRING: [], // No CHAR restrictions for STRING input
};

export const restrictOnCharInput = (
  event: React.KeyboardEvent<HTMLDivElement>,
  dataTypeUI: string,
): void => {
  if (INPUT_CHAR_RESTRICTED[`${dataTypeUI}`].includes(event.key)) {
    event.preventDefault();
  }
};
