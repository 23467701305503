import { isFunction } from 'lodash';
import { CheckboxOption } from '../../../components/checkbox/types';
import { OCCUPANCY_VALIDATIONS } from '../constants';
import { ScenarioUI } from '../types';
import { QueryBuilderValidation } from './types';

export const validSelectionOfBaselineAnd1995 = ({
  years = [],
  scenarios = [],
}: {
  years?: CheckboxOption[];
  scenarios?: CheckboxOption[];
}): Pick<QueryBuilderValidation, 'valid' | 'message'> => {
  const selectedYear1995 = Boolean(
    years.filter((year: CheckboxOption) => year.name === '1995' && year.selected).length,
  );
  const selectedScenarioBaseline = Boolean(
    scenarios.filter(
      (scenario: CheckboxOption) => scenario.name === ScenarioUI.BASELINE && scenario.selected,
    ).length,
  );

  // Either both are true or both are false
  const valid = selectedYear1995 === selectedScenarioBaseline;
  let message = '';

  if (!valid && selectedYear1995) {
    message = 'You must have Baseline Scenario selected for Year 1995';
  } else if (!valid && selectedScenarioBaseline) {
    message = 'You must have Year 1995 selected for Baseline Scenario';
  }

  return { valid, message };
};

export const validOccupancyCodeForScheme = ({
  scheme = '',
  code = '',
}: {
  scheme?: string;
  code?: string;
}): boolean => {
  const validator = OCCUPANCY_VALIDATIONS[`${scheme}`];
  if (isFunction(validator)) {
    return validator(code);
  }

  return false;
};

// IDEA: Validation chip
/*
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { ChipGroupItem } from '../../../components/chip/types';
import { reactKey } from '../../../utils/reactUtils';

export const validationChipConfig = ({ message }: { message: string }): ChipGroupItem => ({
  id: reactKey(),
  name: message,
  icon: <ReportProblemIcon/>,
});

// IMPLEMENTATION:

  const chips = useMemo(() => {
    const selected = list.filter(itemSelected);

    if (!selected.length) {
      return [validationChipConfig({ message: 'At least one year must be selected' })];
    }

    return selected.map((item) => ({
      ...item,
      onDeleteClick: () => {
        setList({ operation: CheckboxOperation.Toggle, id: item.id });
      },
    }));
  }, [list, setList]);

*/

export {};
