import { Box, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import Accordion from '../../../components/accordion/Accordion';
import CheckboxList from '../../../components/checkbox/CheckboxList';
import {
  CheckboxFormProps,
  CheckboxOperation,
  NestedCheckboxFormProps,
} from '../../../components/checkbox/types';
import ChipGroup from '../../../components/chip/ChipGroup';
import { CenteredRowStyle } from '../../../utils/styleUtils';
import { atLeastOneSelected, itemSelected } from '../utils';
import { extractChipItemProps } from '../../../components/chip/util';

const BenchmarkingOptions: FC<CheckboxFormProps> = ({ list, setList, title }) => {
  const chips = useMemo(
    () =>
      list.filter(itemSelected).map((item) => {
        const chipBase = extractChipItemProps(item);
        return {
          ...chipBase,
          onDeleteClick: () => {
            setList({ operation: CheckboxOperation.Toggle, id: item.id });
          },
        };
      }),
    [list, setList],
  );

  return (
    <Accordion
      expandedDefault
      accordionTitle={
        <Box sx={{ ...CenteredRowStyle, alignItems: 'center' }}>
          <Typography variant="h6">{title}</Typography>
          {chips.length > 0 && <ChipGroup items={chips} />}
        </Box>
      }
    >
      <CheckboxList
        options={list}
        onSelectAll={() => {
          setList({ operation: CheckboxOperation.SelectAll });
        }}
        onDeselectAll={() => {
          setList({ operation: CheckboxOperation.DeselectAll });
        }}
        onOptionChange={(id: string) => {
          setList({ operation: CheckboxOperation.Toggle, id });
        }}
      />
    </Accordion>
  );
};

const Benchmarking: FC<NestedCheckboxFormProps> = ({
  list,
  setList,
  title,
  nestedList,
  setNestedList,
  nestedTitle,
}) => {
  const chips = useMemo(
    () =>
      list
        .filter(itemSelected)
        .map((item) => ({
          ...item,
          // onInfoClick: () => {},
          // onDeleteClick: () => {},
        }))
        .map(extractChipItemProps),
    [list],
  );

  return (
    <Accordion
      accordionTitle={
        <Box sx={{ ...CenteredRowStyle, alignItems: 'center' }}>
          <Typography variant="h6">{title}</Typography>
          {chips.length > 0 && <ChipGroup items={chips} />}
        </Box>
      }
    >
      <CheckboxList
        options={list}
        onSelectAll={() => {
          setList({ operation: CheckboxOperation.SelectAll });
        }}
        onDeselectAll={() => {
          setList({ operation: CheckboxOperation.DeselectAll });
        }}
        onOptionChange={(id: string) => {
          setList({ operation: CheckboxOperation.Toggle, id });
        }}
        noMarginBottom={atLeastOneSelected(list)} // NOTE: No margin for this list since there is a nested accordion below it, `<BenchmarkingOptions />`
      />
      {/* Only show the sub-option if its parent is also selected */}
      {atLeastOneSelected(list) && (
        <BenchmarkingOptions list={nestedList} setList={setNestedList} title={nestedTitle} />
      )}
    </Accordion>
  );
};

export default Benchmarking;
