import { Auth0Provider, AppState } from '@auth0/auth0-react';
import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth0AppConfigs } from '../../auth0/utils';

export const Auth0ProviderWithNavigate: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  const { domain, clientId, redirectUri, audience } = getAuth0AppConfigs();

  const onRedirectCallback = (appState: AppState | undefined): void => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  const providerConfig = {
    domain,
    clientId,
    onRedirectCallback,
    authorizationParams: {
      redirect_uri: redirectUri,
      audience,
    },
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider useRefreshTokens cacheLocation="localstorage" {...providerConfig}>
      {children}
    </Auth0Provider>
  );
};
