import { FC } from 'react';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import {
  TOGGLE_THUMB_BG_COLOR,
  TOGGLE_TRACK_BG_COLOR,
  TOGGLE_TRACK_CHECKED_BG_COLOR,
} from '../../utils/styleUtils';

const CustomizedSwitch: FC<SwitchProps> = styled(Switch)(({ theme }) => ({
  width: theme.spacing(3.875), // '31px',
  height: theme.spacing(2), // '16px',
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: theme.spacing(2.5), // '20px',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: `translate(${theme.spacing(1.375)}, -${theme.spacing(0.1875)})`, // :: X: 11px, Y: -1.5px
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    '&.Mui-checked': {
      transform: `translate(${theme.spacing(1.6875)}, -${theme.spacing(0.1875)})`, // :: X: 13.5px, Y: -1.5px
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: TOGGLE_TRACK_CHECKED_BG_COLOR,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    // boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: TOGGLE_THUMB_BG_COLOR,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: theme.spacing(1),
    opacity: 1,
    backgroundColor: TOGGLE_TRACK_BG_COLOR,
    boxSizing: 'border-box',
  },
  '& .checkCircleIcon': {
    fontSize: theme.spacing(2.375), // :: 19px
    color: '#CCC',
  },
}));

export default CustomizedSwitch;
