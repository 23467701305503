import { CSG_VERSION, ResultSetInput } from '../../pages/query-builder/types';
import { Portfolio } from '../../api/portfolios/types';
import { CheckboxOperation, CheckboxOption } from '../../components/checkbox/types';
import { RadioOperation, RadioOption } from '../../components/radio/types';

export const logRejected = (action: any, prefix = ''): void => {
  // eslint-disable-next-line no-console
  console.groupCollapsed(`${prefix.length > 0 ? prefix : 'ERROR:'}`);

  if (action?.error) {
    const { name, message, stack } = action.error;
    // eslint-disable-next-line no-console
    console.error(`${JSON.stringify(name) ?? 'Unnamed Error:'}`, message);
    console.error('Stack:', stack);
  }
  if (action?.payload) {
    // eslint-disable-next-line no-console
    console.error('- Error from payload:', JSON.stringify(action?.payload));
  }
  // eslint-disable-next-line no-console
  console.groupEnd();
};

export const handleCheckboxOperation = ({
  prevList,
  operation,
  id = null,
  skipForbidden = true,
}: {
  prevList: CheckboxOption[];
  operation: CheckboxOperation;
  id?: string | null;
  skipForbidden?: boolean;
}): CheckboxOption[] => {
  switch (operation) {
    case CheckboxOperation.SelectAll:
      return prevList.map((option) => {
        return skipForbidden && option.forbidden ? option : { ...option, selected: true };
      });
    case CheckboxOperation.SelectOne:
      return prevList.map((option) => {
        if (option.id === id && !(skipForbidden && option.forbidden)) {
          return { ...option, selected: true };
        }
        return option;
      });
    case CheckboxOperation.DeselectAll:
      return prevList.map((option) => {
        return skipForbidden && option.forbidden ? option : { ...option, selected: false };
      });
    case CheckboxOperation.DeselectOne:
      return prevList.map((option) => {
        if (option.id === id && !(skipForbidden && option.forbidden)) {
          return { ...option, selected: false };
        }
        return option;
      });
    case CheckboxOperation.Toggle:
      return prevList.map((option) => {
        if (option.id === id && !(skipForbidden && option.forbidden)) {
          return { ...option, selected: !option.selected };
        }
        return option;
      });
    // Operations to enable/disable checkbox options
    case CheckboxOperation.DisableAll:
      return prevList.map((option) => {
        return { ...option, disabled: true };
      });
    case CheckboxOperation.DisableOne:
      return prevList.map((option) => {
        if (option.id === id) {
          return { ...option, disabled: true };
        }
        return option;
      });
    case CheckboxOperation.EnableAll:
      return prevList.map((option) => {
        return { ...option, disabled: false };
      });
    case CheckboxOperation.EnableOne:
      return prevList.map((option) => {
        if (option.id === id) {
          return { ...option, disabled: false };
        }
        return option;
      });
    default:
      // eslint-disable-next-line no-console
      console.warn('Unknown Operation!', { operation, id });
      return prevList;
  }
};

export const handleRadioOperation = ({
  prevList,
  operation,
  id = null,
}: {
  prevList: RadioOption[];
  operation: RadioOperation;
  id?: string | null;
}): RadioOption[] => {
  switch (operation) {
    case RadioOperation.Select:
      return prevList.map((option) => ({ ...option, selected: option.id === id }));
    default:
      // eslint-disable-next-line no-console
      console.warn('Unknown Operation!', { operation, id });
      return prevList;
  }
};

export const extractLocationCount = (portfolio: Portfolio | null): number | null => {
  if (!portfolio) return null;
  try {
    return JSON.parse(portfolio.extra).locationsCount;
  } catch (error) {}
  return null;
};

export const deletedResultSetIDs = (): string[] | null => {
  const listDeletedRS = localStorage.getItem('deletedResultSetIDs');
  if (!listDeletedRS) return null;
  try {
    return JSON.parse(listDeletedRS);
  } catch (error) {
    return null;
  }
};

export const setDeletedResultSetIDs = (listDeletedRS: string[]): void => {
  try {
    localStorage.setItem(
      'deletedResultSetIDs',
      JSON.stringify(listDeletedRS.length > 0 ? listDeletedRS : []),
    );
  } catch (error) {
    localStorage.setItem('deletedResultSetIDs', JSON.stringify([]));
  }
};

export const appendToDeletedResultSetIDs = (rsID: string): void => {
  const listDeletedResultSets = deletedResultSetIDs();
  setDeletedResultSetIDs(
    listDeletedResultSets?.length && listDeletedResultSets?.length > 0
      ? [...listDeletedResultSets, rsID]
      : [rsID],
  );
};

export const getResultSetAnalyticsParams = (
  resultSetPayloads: ResultSetInput[],
): {
  activeSettings: string;
  eiVersion: string;
  csgVersion: string;
  payload: string;
} => {
  const activeSettings = resultSetPayloads.map((payload) => payload.type);
  const eiVersion = resultSetPayloads[0].parameters?.eiVersion ?? '';
  const csgVersion = resultSetPayloads[0].parameters?.csgVersion;
  return {
    activeSettings: JSON.stringify(activeSettings),
    eiVersion,
    csgVersion,
    payload: JSON.stringify(resultSetPayloads),
  };
};

export const checkCSGVersion = (csgVersion = ''): boolean => {
  switch (csgVersion) {
    case CSG_VERSION.v3_0_0:
    case CSG_VERSION.v3_1_0:
      return true;
    default:
      return false;
  }
};
