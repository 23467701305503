import { DownloadFormat, ResultSet } from '../../api/resultSets/types';
import {
  createResultSet,
  deleteResultSet,
  getResultSetDefaultParams,
  listResultSets,
  updateResultSet,
} from './resultSet';
import { APIResponse } from '../http';
import {
  convertResultSet,
  convertResultSetDataAndEIVersion,
  convertResultSetDefaultParams,
  convertResultSetEIVersion,
} from '../../restUtils/restToGqlForResultSet';
import { convertInput } from '../../restUtils/gqlToRestForResultSet';
import { ResultSetInput } from '../../pages/query-builder/types';
import { UUID } from '../../api/types';
import { createExport, getExport } from '../export/export';
import { APIStatus, DownloadResponse } from '../types';
import { APIFileDownloadLink } from '../file/types';
import { APIExportCompression, APIExportFileType, APIOutputStyle } from '../export/types';
import { createFileDownloadLink } from '../file/file';

export async function listResultSetsAdapter(): Promise<APIResponse<ResultSet[]>> {
  const { data, error } = await listResultSets();
  if (error) {
    return { data: null, error };
  }
  return { data: data?.map(convertResultSet), error: null };
}

export async function createResultSetAdapter(
  input: ResultSetInput,
): Promise<APIResponse<ResultSet>> {
  const { data, error } = await createResultSet(convertInput(input));
  if (error) {
    return { data: null, error };
  }
  return { data: convertResultSet(data), error: null };
}

export async function updateResultSetAdapter(
  id: UUID,
  name: string,
): Promise<APIResponse<ResultSet>> {
  const { data, error } = await updateResultSet(id, name);
  if (error) {
    return { data: null, error };
  }
  return { data: convertResultSet(data), error: null };
}

export async function deleteResultSetAdapter(id: UUID): Promise<APIResponse<null>> {
  const { data, error } = await deleteResultSet(id);
  if (error) {
    return { data: null, error };
  }
  return { data, error };
}

export async function getResultSetDownloadUrlsAdapter(
  resultSetId: UUID,
  format: DownloadFormat,
): Promise<APIResponse<DownloadResponse>> {
  const { data, error } = await createExport({
    compression: APIExportCompression.none,
    file_type: APIExportFileType.csv,
    object_id: resultSetId,
    options: {
      object_type: 'result_set',
      include_tiers: true,
      include_bands: true,
      include_monthly: true,
      include_peak_gust: false,
    },
    output_style:
      format === DownloadFormat.TABLEAU ? APIOutputStyle.enhanced : APIOutputStyle.standard,
  });
  if (error) {
    return { data: null, error };
  }
  let apiExport = data;
  /* eslint-disable no-await-in-loop */
  while (apiExport.status === APIStatus.pending) {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 5000));
    const apiResponse = await getExport(apiExport.id);
    if (apiResponse.error) {
      return { data: null, error: apiResponse.error };
    }
    apiExport = apiResponse.data;
  }
  /* eslint-enable no-await-in-loop */
  if (apiExport.status === APIStatus.failed) {
    return { data: null, error: new Error('Export failed.') };
  }

  const { files } = apiExport;

  const responses = await Promise.all(
    files.map(async (file) => await createFileDownloadLink(file.id, { expires_in: 3600 })),
  );
  const errResponse = responses.find((response) => response.error);
  // If there is at least one error
  if (errResponse) {
    return { data: null, error: errResponse.error as Error };
  }
  // There are zero errors
  return {
    data: {
      download: {
        urls: responses.map((link) => (link.data as APIFileDownloadLink).url),
      },
    },
    error: null,
  };
}

export async function getResultSetDefaultParamsAdapter(
  csgVersion?: string,
  eiVersion?: string,
): Promise<APIResponse<Record<string, any>>> {
  const { data, error } = await getResultSetDefaultParams(csgVersion, eiVersion);
  if (error) {
    return { data: null, error };
  }
  return { data: convertResultSetDefaultParams(data), error: null };
}

export async function getResultSetEIVersionAdapter(
  csgVersion?: string,
): Promise<APIResponse<Record<string, any>>> {
  const { data, error } = await getResultSetDefaultParams(csgVersion);
  if (error) {
    return { data: null, error };
  }
  return { data: convertResultSetEIVersion(data), error: null };
}

export async function getResultSetDataVersionAdapter(): Promise<APIResponse<Record<string, any>>> {
  const { data, error } = await getResultSetDefaultParams();
  if (error) {
    return { data: null, error };
  }
  return { data: convertResultSetDataAndEIVersion(data), error: null };
}
