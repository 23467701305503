import { FC, useCallback, useEffect } from 'react';
import { CheckboxOperation, UpdateCheckboxInstructions } from '../../../components/checkbox/types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectDamageAndLossAdvParamValues,
  selectDamageAndLossDamageValues,
  selectDamageAndLossFinancialMetrics,
  selectDamageAndLossFinancialValues,
  selectDamageAndLossFloodDefense,
  selectDamageAndLossFloodDefenseOptions,
  selectDamageAndLossLossValues,
  selectDamageAndLossPerils,
  selectDamageAndLossPortfolioLossMetrics,
  selectDamageAndLossScenarios,
  selectDamageAndLossCostOfWater,
  selectDamageAndLossCostOfWaterValues,
  selectDamageAndLossWildfireLoss,
  selectDamageAndLossWildfireLossValues,
  selectDamageAndLossWorkerProductivity,
  selectDamageAndLossWorkerProductivityValues,
  selectDamageAndLossYears,
} from '../../../redux/selectors/queryBuilderSelectors';
import {
  setDamageAndLossDamageValues,
  setDamageAndLossFloodDefense,
  setDamageAndLossFloodDefenseOptions,
  setDamageAndLossLossValues,
  setDamageAndLossAdvParamValues,
  setDamageAndLossWorkerProductivity,
  setDamageAndLossWorkerProductivityValues,
  setDamageAndLossPerils,
  setDamageAndLossScenarios,
  setDamageAndLossYears,
  setDamageAndLossFinancialValues,
  setDamageAndLossFinancialMetrics,
  setDamageAndLossWildfireLoss,
  setDamageAndLossWildfireLossValues,
  setDamageAndLossCostOfWater,
  setDamageAndLossCostOfWaterValues,
  setDamageAndLossPorfolioLossMetrics,
} from '../../../redux/slices/queryBuilderSlice';
import DamageAndLossOptions, {
  UpdateTextFormInstructions,
} from '../sub-options/DamageAndLossOptions';
import FloodDefense from '../sub-options/FloodDefense';
import Scenarios from '../sub-options/Scenarios';
import Years from '../sub-options/Years';
import PerilsMetricsTransferList from '../sub-options/PerilMetricsTransferList';
import { floodDefenseIsEnabled } from '../builder';

const PerilsWithDamageLoss: FC = () => {
  const dispatch = useAppDispatch();
  const perils = useAppSelector(selectDamageAndLossPerils);
  const scenarios = useAppSelector(selectDamageAndLossScenarios);
  const years = useAppSelector(selectDamageAndLossYears);
  const floodDefense = useAppSelector(selectDamageAndLossFloodDefense);
  const floodDefenseOptions = useAppSelector(selectDamageAndLossFloodDefenseOptions);
  const damageAndLossValues = useAppSelector(selectDamageAndLossDamageValues);
  const lossCalculationValues = useAppSelector(selectDamageAndLossLossValues);
  const advParameterValues = useAppSelector(selectDamageAndLossAdvParamValues);
  const workerProductivity = useAppSelector(selectDamageAndLossWorkerProductivity);
  const workerProductivityValues = useAppSelector(selectDamageAndLossWorkerProductivityValues);
  const wildfireLoss = useAppSelector(selectDamageAndLossWildfireLoss);
  const wildfireLossValues = useAppSelector(selectDamageAndLossWildfireLossValues);
  const costOfWater = useAppSelector(selectDamageAndLossCostOfWater);
  const costOfWaterValues = useAppSelector(selectDamageAndLossCostOfWaterValues);
  const portfolioLossMetrics = useAppSelector(selectDamageAndLossPortfolioLossMetrics);
  const financialParamValues = useAppSelector(selectDamageAndLossFinancialValues);
  const financialMetrics = useAppSelector(selectDamageAndLossFinancialMetrics);

  const setPerils = (payload: UpdateCheckboxInstructions): void => {
    dispatch(setDamageAndLossPerils(payload));
  };
  const setScenarios = (payload: UpdateCheckboxInstructions): void => {
    dispatch(setDamageAndLossScenarios(payload));
  };
  const setYears = (payload: UpdateCheckboxInstructions): void => {
    dispatch(setDamageAndLossYears(payload));
  };
  const setFloodDefense = (payload: UpdateCheckboxInstructions): void => {
    dispatch(setDamageAndLossFloodDefense(payload));
  };
  const setFloodDefenseOptions = useCallback(
    (payload: UpdateCheckboxInstructions): void => {
      dispatch(setDamageAndLossFloodDefenseOptions(payload));
    },
    [dispatch],
  );
  const setDamageAndLossValues = (payload: UpdateTextFormInstructions): void => {
    dispatch(setDamageAndLossDamageValues(payload));
  };

  const setLossCalculationValues = (payload: UpdateTextFormInstructions): void => {
    dispatch(setDamageAndLossLossValues(payload));
  };

  const setAdvParameterValues = (payload: UpdateTextFormInstructions): void => {
    dispatch(setDamageAndLossAdvParamValues(payload));
  };

  const setWorkerProductivity = (payload: UpdateCheckboxInstructions): void => {
    dispatch(setDamageAndLossWorkerProductivity(payload));
  };

  const setWorkerProductivityValues = (payload: UpdateTextFormInstructions): void => {
    dispatch(setDamageAndLossWorkerProductivityValues(payload));
  };

  const setWildfireLoss = (payload: UpdateCheckboxInstructions): void => {
    dispatch(setDamageAndLossWildfireLoss(payload));
  };

  const setWildfireLossValues = (payload: UpdateTextFormInstructions): void => {
    dispatch(setDamageAndLossWildfireLossValues(payload));
  };

  const setCostOfWater = (payload: UpdateCheckboxInstructions): void => {
    dispatch(setDamageAndLossCostOfWater(payload));
  };

  const setCostOfWaterValues = (payload: UpdateTextFormInstructions): void => {
    dispatch(setDamageAndLossCostOfWaterValues(payload));
  };

  const setPorfolioLossMetrics = (payload: UpdateCheckboxInstructions): void => {
    dispatch(setDamageAndLossPorfolioLossMetrics(payload));
  };

  const setFinancialParamValues = (payload: UpdateTextFormInstructions): void => {
    dispatch(setDamageAndLossFinancialValues(payload));
  };

  const setFinancialMetrics = (payload: UpdateCheckboxInstructions): void => {
    dispatch(setDamageAndLossFinancialMetrics(payload));
  };

  useEffect(() => {
    if (!floodDefenseIsEnabled({ floodDefense })) {
      setFloodDefenseOptions({ operation: CheckboxOperation.DisableAll });
      setFloodDefenseOptions({ operation: CheckboxOperation.DeselectAll, skipForbidden: false });
    } else {
      setFloodDefenseOptions({ operation: CheckboxOperation.EnableAll });
      setFloodDefenseOptions({ operation: CheckboxOperation.SelectAll, skipForbidden: false });
    }
  }, [floodDefense, setFloodDefenseOptions]);

  return (
    <>
      <PerilsMetricsTransferList list={perils} setList={setPerils} title="Perils" />
      <Scenarios list={scenarios} setList={setScenarios} title="Scenarios" />
      <Years list={years} setList={setYears} title="Years" />
      <FloodDefense
        list={floodDefense}
        setList={setFloodDefense}
        title="Flood Defense"
        nestedList={floodDefenseOptions}
        setNestedList={setFloodDefenseOptions}
      />
      <DamageAndLossOptions
        accordionTitle="Economic Impact Options"
        damageAndLossFormTitle="Default Building Attributes"
        damageAndLossValues={damageAndLossValues}
        setDamageAndLossValues={setDamageAndLossValues}
        lossFormTitle="Default Asset Values"
        lossCalculationValues={lossCalculationValues}
        setLossCalculationValues={setLossCalculationValues}
        advParametersFormTitle="Advanced Parameters"
        advParameterValues={advParameterValues}
        setAdvParameterValues={setAdvParameterValues}
        workerProductivityList={workerProductivity}
        setWorkerProductivityList={setWorkerProductivity}
        workerProductivityValues={workerProductivityValues}
        setWorkerProductivityValues={setWorkerProductivityValues}
        wildfireLossList={wildfireLoss}
        setWildfireLossList={setWildfireLoss}
        wildfireLossValues={wildfireLossValues}
        setWildfireLossValues={setWildfireLossValues}
        costOfWaterList={costOfWater}
        setCostOfWaterList={setCostOfWater}
        costOfWaterValues={costOfWaterValues}
        setCostOfWaterValues={setCostOfWaterValues}
        portfolioLossMetricsList={portfolioLossMetrics}
        setPortfolioLossMetricsList={setPorfolioLossMetrics}
        financialFormTitle="Financial Parameters"
        financialParamValues={financialParamValues}
        setFinancialParamValues={setFinancialParamValues}
        financialMetricsList={financialMetrics}
        setFinancialMetricsList={setFinancialMetrics}
      />
    </>
  );
};

export default PerilsWithDamageLoss;
