import { Info as InfoIcon } from '@mui/icons-material';
import { FC } from 'react';
import { StyleProps } from '../utils/styleUtils';
import Tooltip from './tooltip/Tooltip';

interface InfoIconTooltipProps {
  tooltip: string;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined;
  mode?: 'light' | 'dark' | undefined;
  sx?: StyleProps;
  onClick?: () => void;
}

const InfoIconTooltip: FC<InfoIconTooltipProps> = ({
  tooltip,
  placement,
  mode = 'dark', // defaulting to `dark` mode.
  sx = {},
  onClick = () => {},
}) => {
  return (
    <Tooltip placement={placement} title={tooltip} mode="light">
      <InfoIcon
        sx={{
          fontSize: '1rem',
          ...sx,
        }}
        onClick={onClick}
      />
    </Tooltip>
  );
};
export default InfoIconTooltip;
