// NOTE: Do not import from ../constants.ts, as it will create circular dependency for file imports

import { isEmpty } from 'lodash';
import { ValidationUI, ValidatorProps } from './types';

export const validateFloatBetweenZeroToOne = ({
  sectionType,
  value = '',
  fieldName,
  isOptional = false,
}: ValidatorProps): ValidationUI => {
  const valueStr = value.trim();
  const valueFloat = parseFloat(valueStr);

  return {
    section: sectionType,
    valid: !Number.isNaN(valueFloat) && valueFloat >= 0 && valueFloat <= 1,
    message: `${fieldName ?? 'Input parameter'} must be between 0 and 1${
      isOptional ? ' or left blank' : ''
    }`,
  };
};

export const validateFloatFromZeroToLessThanOne = ({
  sectionType,
  value = '',
  fieldName,
  isOptional = false,
}: ValidatorProps): ValidationUI => {
  const valueStr = value.trim();
  const valueFloat = parseFloat(valueStr);

  return {
    section: sectionType,
    valid: !Number.isNaN(valueFloat) && valueFloat >= 0 && valueFloat < 1,
    message: `${fieldName ?? 'Input parameter'} must be between 0 and 1, but not equal to 1${
      isOptional ? ', or left blank' : ''
    }`,
  };
};

export const validateGICSEightDigitInteger = ({
  sectionType,
  value = '',
  fieldName,
}: ValidatorProps): ValidationUI => {
  const valueStr = value.trim();
  const valueInt = Number(valueStr);

  return {
    section: sectionType,
    valid:
      !Number.isNaN(valueInt) && Number.isInteger(valueInt) && valueInt > 0 && value?.length === 8,
    message: `${fieldName ?? 'Input parameter'} must be an 8-digit integer or left blank`,
  };
};

export const validatePositiveIntegers = ({
  sectionType,
  value = '',
  fieldName,
  isOptional = false,
}: ValidatorProps): ValidationUI => {
  const valueStr = value.trim();
  const valueInt = Number(valueStr);

  return {
    section: sectionType,
    valid:
      (isOptional || !isEmpty(valueStr)) &&
      !Number.isNaN(valueInt) &&
      Number.isInteger(valueInt) &&
      valueInt >= 0,
    message: `${fieldName ?? 'Input parameter'} must be an integer >= 0${
      isOptional ? ' or left blank' : ''
    }`,
  };
};

export const validatePositiveFloats = ({
  sectionType,
  value = '',
  fieldName,
  isOptional = false,
}: ValidatorProps): ValidationUI => {
  const valueStr = value.trim();
  const valueFloat = parseFloat(valueStr);

  return {
    section: sectionType,
    valid: (isOptional || !isEmpty(valueStr)) && !Number.isNaN(valueFloat) && valueFloat >= 0,
    message: `${fieldName ?? 'Input parameter'} must be a floating point number >= 0${
      isOptional ? ' or left blank' : ''
    }`,
  };
};
