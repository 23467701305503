import { Box } from '@mui/material';
import { FC } from 'react';
import Chip from './Chip';
import { ChipGroupProps } from './types';

const ChipGroup: FC<ChipGroupProps> = ({ items }) => {
  return (
    <Box
      sx={(theme) => ({
        marginLeft: theme.spacing(1),
      })}
      data-testid="chip-container"
    >
      {items.map((item) => {
        const { disabled = false, id, name, onInfoClick, onDeleteClick, ...otherChipProps } = item;
        return (
          <Chip
            sx={(theme) => ({ marginRight: theme.spacing(0.5) })}
            disabled={disabled}
            key={id}
            label={name}
            onInfoClick={onInfoClick}
            onDeleteClick={onDeleteClick}
            {...otherChipProps}
          />
        );
      })}
    </Box>
  );
};

export default ChipGroup;
