import { Box, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import InfoIconTooltip from '../../components/InfoIconTooltip';
import TextUnderlineButton from '../../components/TextUnderlineButton';
import SwitchButton, { IndividualButtonProps } from '../../components/buttons/SwitchButton';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectDownloadPortfolioId,
  selectDownloadPortfolioName,
  selectDownloadResultSetId,
  selectDownloadResultSetName,
  selectDownloadResultSetStatus,
} from '../../redux/selectors/uiSelectors';
import { downloadPortfolio, downloadResultSet } from '../../redux/slices/csgDataSlice';
import { resetDownloadDrawer } from '../../redux/slices/uiSlice';
import { OUTPUT_FORMAT_OPTIONS, getInternalName } from './utils';
import { StyledThinLabel } from '../../components/labels/StyledLabel';
import { MUTED_TEXT_COLOR } from '../../utils/styleUtils';
import { DownloadFormat, ResultSetStatus } from '../../api/resultSets/types';

const LinksPresentation: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const resultSetId = useAppSelector(selectDownloadResultSetId);
  const resultSetName = useAppSelector(selectDownloadResultSetName);
  const resultSetStatus = useAppSelector(selectDownloadResultSetStatus);
  const portfolioId = useAppSelector(selectDownloadPortfolioId);
  const portfolioName = useAppSelector(selectDownloadPortfolioName);

  const [loadingRsById, setLoadingRsById] = useState<{ [id: string]: boolean }>(
    OUTPUT_FORMAT_OPTIONS.reduce((acc, curr) => {
      return { ...acc, [curr.id]: false };
    }, {}),
  );

  const [loadingPortfolio, setLoadingPortfolio] = useState<boolean>(false);

  const handleRequestPortfolioDownload = async (): Promise<void> => {
    if (portfolioId && portfolioName) {
      setLoadingPortfolio(true);
      await dispatch(downloadPortfolio({ portfolioId, portfolioName }));
      setLoadingPortfolio(false);
    }
  };

  const handleRequestRsDownload = async ({
    downloadFormat,
    id,
  }: {
    downloadFormat: DownloadFormat;
    id: string;
  }): Promise<void> => {
    if (resultSetId) {
      setLoadingRsById((prev) => ({
        ...prev,
        [id]: true,
      }));
      await dispatch(downloadResultSet({ resultSetId, downloadFormat }));
      setLoadingRsById((prev) => ({
        ...prev,
        [id]: false,
      }));
    }
  };

  const handleCancel = (): void => {
    dispatch(resetDownloadDrawer());
  };

  const actionButtons: IndividualButtonProps[] = [
    {
      title: 'Close',
      onClick: handleCancel,
      id: 'download-action-button-2',
    },
  ];

  return (
    <>
      {/* Show portfolio link only if portfolioId is available. */}
      {/* This happens when drawer is opened from Summary and not result-set context menu. */}
      {portfolioId && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            marginBottom: theme.spacing(0.5),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <TextUnderlineButton
              onClick={() => {
                void handleRequestPortfolioDownload();
              }}
              TypographyProps={{ variant: 'button' }}
              data-testid="portfolio-download"
            >
              {portfolioName ?? 'Portfolio'}
            </TextUnderlineButton>
          </Box>
          {loadingPortfolio && (
            <StyledThinLabel
              variant="body1"
              sx={{ marginLeft: theme.spacing(1), color: MUTED_TEXT_COLOR }}
            >
              Downloading...
            </StyledThinLabel>
          )}
        </Box>
      )}
      {resultSetId &&
        OUTPUT_FORMAT_OPTIONS.map((option) => {
          const loading = loadingRsById[option.id] ?? false;
          const downloadFormat = getInternalName(option);
          const name = resultSetName ? `${resultSetName} ${option.name}` : option.name;
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                marginBottom: theme.spacing(0.5),
              }}
              key={option.id}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <TextUnderlineButton
                  onClick={() => {
                    void handleRequestRsDownload({ downloadFormat, id: option.id });
                  }}
                  TypographyProps={{ variant: 'button' }}
                  disabled={resultSetStatus !== ResultSetStatus.COMPLETED}
                  data-testid={option.testId}
                >
                  {name}
                </TextUnderlineButton>
                {(option?.tooltip ?? '').length > 0 && (
                  <InfoIconTooltip
                    tooltip={option?.tooltip ?? ''}
                    sx={{ marginLeft: theme.spacing(0.5) }}
                  />
                )}
              </Box>
              {loading && (
                <StyledThinLabel
                  variant="body1"
                  sx={{ marginLeft: theme.spacing(1), color: MUTED_TEXT_COLOR }}
                >
                  Downloading...
                </StyledThinLabel>
              )}
            </Box>
          );
        })}
      <Box sx={{ marginTop: theme.spacing(4) }}>
        <SwitchButton type="normal" buttons={actionButtons} />
      </Box>
    </>
  );
};

export default LinksPresentation;
