import { FC, useState } from 'react';
import { Box } from '@mui/material';
import { isNil } from 'lodash';
import { MenuOptionProps } from '../../../components/menu/types';
import Menu from '../../../components/menu/Menu';
import { PRIMARY_TEXT_COLOR, TILE_BORDER } from '../../../utils/styleUtils';
import TextDropdown from '../../../components/dropdowns/TextDropdown';
import { DownloadConfig } from '../../../components/DownloadFilesTrigger';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectUploadMode } from '../../../redux/selectors/uploadSelectors';
import { resetUploadState, setUploadMode } from '../../../redux/slices/uploadSlice';
import { setUploadDrawer } from '../../../redux/slices/uiSlice';
import { UploadModes } from '../../upload/types';
import { isSafari } from '../../../utils/browserUtils';
import { reactKey } from '../../../utils/reactUtils';
import { exportFileAsync } from '../../../utils/csvUtils';

export const TEMPLATE_FILE_LINKS: DownloadConfig[] = [
  {
    filename: 'Sample Input',
    url: `${window._env_.REACT_APP_APP_ROOT}/input_file_templates/Sample Input.csv`,
    id: reactKey(),
  },
  {
    filename: 'Sample Input for Loss',
    url: `${window._env_.REACT_APP_APP_ROOT}/input_file_templates/Sample Input for Loss.csv`,
    id: reactKey(),
  },
];

const CreatePortfolio: FC = () => {
  const safariBrowser = isSafari();
  const dispatch = useAppDispatch();
  const uploadMode = useAppSelector(selectUploadMode);
  const COORDINATE_COMPOSITION_UPLOAD_MODE = UploadModes.COORDINATE_COMPOSITION;
  const CSV_FILE_UPLOAD_MODE = UploadModes.CSV_FILE;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isMenuOpen = Boolean(anchorEl);

  const elementId = isMenuOpen ? 'create-portfolio-menu' : undefined;

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleUploadDrawerOpen = (): void => {
    dispatch(setUploadDrawer(true));
    handleClose();
  };

  const handleDownloadFiles = async (): Promise<void> => {
    try {
      await Promise.all(
        TEMPLATE_FILE_LINKS.map(async (config): Promise<void> => {
          await exportFileAsync(config);
        }),
      ).catch((err) => {
        console.error('[handleDownloadFiles] Promise.all ERROR -', err);
      });
    } catch (error) {
      console.error('[handleDownloadFiles] ERROR -', error);
    } finally {
      handleClose();
    }
  };

  const handleDownloadFilesForSafari = async ({
    filename,
    url,
    id,
  }: DownloadConfig): Promise<void> => {
    try {
      await exportFileAsync({ filename, url, id });
    } catch (error) {
      console.error('[handleDownloadFilesForSafari] ERROR -', error);
    } finally {
      handleClose();
    }
  };

  const MENU_OPTIONS: MenuOptionProps[] = [
    {
      title: 'Enter Lat/Lon',
      onClick: () => {
        handleUploadDrawerOpen();
        if (uploadMode !== COORDINATE_COMPOSITION_UPLOAD_MODE) {
          dispatch(resetUploadState());
        }
        dispatch(setUploadMode(COORDINATE_COMPOSITION_UPLOAD_MODE));
      },
      id: 'menu-nav-bar-item-1',
    },
    {
      title: 'Upload CSV File',
      onClick: () => {
        handleUploadDrawerOpen();
        if (uploadMode !== CSV_FILE_UPLOAD_MODE) {
          dispatch(resetUploadState());
        }
        dispatch(setUploadMode(CSV_FILE_UPLOAD_MODE));
      },
      id: 'menu-nav-bar-item-2',
    },
    ...(safariBrowser
      ? TEMPLATE_FILE_LINKS.map(({ filename, url, id }, idx) => ({
          title: `Download ${filename}`,
          onClick: () => {
            void handleDownloadFilesForSafari({ filename, url, id });
          },
          id: `menu-nav-bar-item-${3 + idx}`,
          ...(idx === 0
            ? {
                itemSx: {
                  borderTop: TILE_BORDER,
                },
              }
            : {}),
        }))
      : [
          {
            title: 'Download Input Template',
            onClick: () => {
              void handleDownloadFiles();
            },
            id: 'menu-nav-bar-item-3',
            itemSx: {
              borderTop: TILE_BORDER,
            },
          },
        ]),
  ];

  const onClickMenu = (element: HTMLButtonElement | null): void => {
    if (!isNil(element)) {
      setAnchorEl(element);
    }
  };

  const onCloseMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    handleClose();
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', color: PRIMARY_TEXT_COLOR }}>
      <TextDropdown
        label="Create Portfolio"
        data-testid="create-portfolio-menu-button"
        isMenuOpen={isMenuOpen}
        menu={
          <Menu
            data-testid="create-portfolio-menu"
            id={elementId}
            open={isMenuOpen}
            anchorEl={anchorEl}
            onClose={onCloseMenu}
            options={MENU_OPTIONS}
          />
        }
        onClickMenu={onClickMenu}
      />
    </Box>
  );
};

export default CreatePortfolio;
