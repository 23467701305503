import { Box, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import Accordion from '../../../components/accordion/Accordion';
import { CheckboxFormProps, CheckboxOperation } from '../../../components/checkbox/types';
import ChipGroup from '../../../components/chip/ChipGroup';
import TransferList from '../../../components/transfer-list/TransferList';
import { CenteredRowStyle } from '../../../utils/styleUtils';
import { itemSelected } from '../utils';
import { extractChipItemProps } from '../../../components/chip/util';

const Years: FC<CheckboxFormProps> = ({ list, setList, title }) => {
  const chips = useMemo(
    () =>
      list.filter(itemSelected).map((item) => {
        const chipBase = extractChipItemProps(item);
        return {
          ...chipBase,
          onDeleteClick: () => {
            setList({ operation: CheckboxOperation.Toggle, id: item.id });
          },
        };
      }),
    [list, setList],
  );

  return (
    <Accordion
      accordionTitle={
        <Box sx={{ ...CenteredRowStyle, alignItems: 'center' }}>
          <Typography variant="h6" data-testid="header-years">
            {title}
          </Typography>
          <ChipGroup items={chips} />
        </Box>
      }
      data-testid="years-panel"
    >
      <TransferList
        listItems={list}
        onSelectAll={() => {
          setList({ operation: CheckboxOperation.SelectAll });
        }}
        onSelectOne={(id: string) => {
          setList({ operation: CheckboxOperation.SelectOne, id });
        }}
        onDeselectAll={() => {
          setList({ operation: CheckboxOperation.DeselectAll });
        }}
        onDeselectOne={(id: string) => {
          setList({ operation: CheckboxOperation.DeselectOne, id });
        }}
      />
    </Accordion>
  );
};

export default Years;
