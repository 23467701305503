import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { IconButton, useTheme } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import Menu from '../menu/Menu';
import { MenuPositionX } from '../menu/types';
import { ContextMenuProps, MenuClick } from './types';
import { trackCustomEvent } from '../../heap';

const ContextMenu: FC<ContextMenuProps> = ({
  menuOptions,
  menuId = 'context-menu',
  iconSx = {},
  origin = 'portfolio',
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isMenuOpen = Boolean(anchorEl);
  const elementId = isMenuOpen ? menuId : undefined;

  const options = useMemo<ContextMenuProps['menuOptions']>(
    () =>
      menuOptions.map((opt) => ({
        ...opt,
        onClick: async () => {
          trackCustomEvent(`Clicked on ${opt.title.toString()}`, {
            from: origin,
          });
          await opt.onClick();
          setAnchorEl(null);
        },
      })),
    [menuOptions, origin],
  );

  const onClickMenu: MenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu: MenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        data-testid={`${menuId}-button`}
        aria-label={`${menuId}-button`}
        aria-controls={`${menuId}`}
        aria-labelledby={`${menuId}`}
        aria-haspopup="true"
        onClick={onClickMenu}
        color="inherit"
        disableRipple
        sx={{ margin: 0, padding: 0 }}
      >
        <MoreVertIcon sx={{ fontSize: theme.spacing(2), ...iconSx }} />
      </IconButton>
      <Menu
        aria-labelledby={`${menuId}`}
        id={elementId}
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        options={options}
        horzOrientation={MenuPositionX.LEFT}
      />
    </>
  );
};

export default ContextMenu;
