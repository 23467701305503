import { ResultSetParametersInput, ResultSetType } from '../../api/resultSets/types';
import { CryptoUUID } from '../../utils/reactUtils';
import { ValidationUI, ValidatorProps } from './validation/types';

export enum CSG_VERSION {
  v2_6_2 = '2.6.2',
  v2_7_0 = '2.7.0', // Keeping this enum here as there are old result-sets created using 2.7.0 that we still need to show on UI
  v3_0_0 = '3.0.0',
  v3_1_0 = '3.1.0',
}

export enum ResultSetDefaultNameUI {
  ECONOMIC_IMPACTS = 'Perils + EI',
  FLOOD_MESH = 'Flood Mesh',
  PERILS = 'Perils',
  SCORES = 'Scores',
  SCORES_WITH_BENCHMARKS = 'Scores + Benchmarks',
}

export enum FloodDefenseLabelUI {
  ENABLED = 'Enable Flood Defenses',
  ZERO_OUT_DEFENDED_LOCATIONS = 'Adjust depths for protected locations',
}

export enum DamageAndLossLabelUI {
  BASEMENT_CODE = 'Basement Code',
  BUILDING_VALUE = 'Building Value',
  CONTENTS_VALUE = 'Contents Value',
  INVENTORY_VALUE = 'Inventory Value',
  NUMBER_OF_STORIES = 'Number of Stories',
  OCCUPANCY_CODE = 'Occupancy Code',
  OCCUPANCY_SCHEME = 'Occupancy Scheme',
  TOTAL_VALUE = 'Total Value',
  // 3.0.0 - 3.1.0
  DOWNTIME_VALUE = 'Downtime Value',
  SUB_INDUSTRY_CODE = 'GICS Sub-industry Code',
  FIRST_FLOOR_ELEVATION = 'First Floor Elevation',
  FLOOR_AREA_SQM = 'Floor Area',
  ELECTRICITY_COST = 'Electricity Cost',
  COOLING_SYSTEM_PROBABILITY = 'Cooling System Probability',
  WORK_INTENSITY = 'Work Intensity',
  REMOTE_WORK_RATIO = 'Remote Work Ratio',
  ANNUAL_GROWTH_RATE = 'Annual Growth Rate',
  ANNUAL_VOLATILITY_RATE = 'Annual Volatility Rate',
  SALES_MARGIN = 'Sales Margin',
  DISCOUNT_RATE = 'Discount Rate',
  FINANCIAL_BASE_YEAR = 'Financial Base Year',
  // 3.1.0
  WINDOW_PANE = 'Window Pane',
  VENT_TYPE = 'Vent Type',
  ROOF_COVER = 'Roof Cover',
  WATER_CONSUMPTION = 'Water Consumption',
  WATER_SHADOW_PRICE_RATIO = 'Shadow Price Ratio',
}

export enum DamageAndLossPlaceholderUI {
  BASEMENT_CODE = 'Basement Code',
  BUILDING_VALUE = 'Building Value',
  CONTENTS_VALUE = 'Contents Value',
  INVENTORY_VALUE = 'Inventory Value',
  NUMBER_OF_STORIES = 'No. of Stories',
  OCCUPANCY_CODE = 'Occupancy Code',
  OCCUPANCY_SCHEME = 'Occupancy Scheme',
  TOTAL_VALUE = 'Total Value',
  // 3.0.0 - 3.1.0
  DOWNTIME_VALUE = 'Downtime Value',
  SUB_INDUSTRY_CODE = 'GICS Sub-industry Code',
  FIRST_FLOOR_ELEVATION = 'First Floor Elevation',
  FLOOR_AREA_SQM = 'Floor Area',
  ELECTRICITY_COST = 'Electricity Cost',
  COOLING_SYSTEM_PROBABILITY = 'Cooling System Probability',
  WORK_INTENSITY = 'Work Intensity',
  REMOTE_WORK_RATIO = 'Remote Work Ratio',
  ANNUAL_GROWTH_RATE = 'Annual Growth Rate',
  ANNUAL_VOLATILITY_RATE = 'Annual Volatility Rate',
  SALES_MARGIN = 'Sales Margin',
  DISCOUNT_RATE = 'Discount Rate',
  FINANCIAL_BASE_YEAR = 'Financial Base Year',
  // 3.1.0
  WINDOW_PANE = 'Window Pane',
  VENT_TYPE = 'Vent Type',
  ROOF_COVER = 'Roof Cover',
  WATER_CONSUMPTION = 'Water Consumption',
  WATER_SHADOW_PRICE_RATIO = 'Shadow Price Ratio',
}

export enum BenchmarkingLabelUI {
  ENABLED = 'Included',
}

export enum FinancialMetricsLabelUI {
  ENABLED = 'Include Financial Metrics',
}

export enum WorkerProductivityLabelUI {
  ENABLED = 'Include worker productivity and cost of cooling',
}

export enum WildfireLossLabelUI {
  ENABLED = 'Include Wildfire Loss',
}

export enum WaterConsumptionLabelUI {
  ENABLED = 'Include Utilities - Cost of Water',
}

export enum PortfolioLossMetricsLabelUI {
  ENABLED = 'Generate Portfolio-Level Results',
}

export enum YearUI {
  WIND = 'Wind',
  COLD = 'Cold',
  PRECIP = 'Precipitation',
  HEAT = 'Heat',
  COMBINED_FLOOD = 'Combined Flood',
  HAIL = 'Hail',
  DROUGHT = 'Drought',
  FIRE = 'Fire',
  ALL_PERILS = 'All Perils',
}

export enum PerilUI {
  WIND = 'Wind',
  COLD = 'Cold',
  PRECIP = 'Precipitation',
  HEAT = 'Heat',
  COMBINED_FLOOD = 'Combined Flood',
  HAIL = 'Hail',
  DROUGHT = 'Drought',
  FIRE = 'Fire',
  ALL_PERILS = 'All Perils',
  // 3.0.0 - 3.1.0
  COASTAL_FLOOD = 'Coastal Flood',
  PLUVIAL_FLOOD = 'Pluvial Flood',
  FLUVIAL_FLOOD = 'Fluvial Flood',
}

export enum ScorePerilUI {
  WIND = 'Wind',
  COLD = 'Cold',
  PRECIP = 'Precipitation',
  HEAT = 'Heat',
  COMBINED_FLOOD = 'Combined Flood',
  HAIL = 'Hail',
  DROUGHT = 'Drought',
  FIRE = 'Fire',
  ALL_PERILS = 'All Perils',
}

export enum ScenarioUI {
  BASELINE = 'Baseline',
  SSP_126 = 'SSP1-2.6',
  SSP_245 = 'SSP2-4.5',
  SSP_585 = 'SSP5-8.5',
}

export enum BenchmarkingLevelUI {
  COUNTRY = 'Country',
  ADMIN1 = 'Admin 1',
  ADMIN2 = 'Admin 2 (US Only)',
}

export enum GridSizeUI {
  REGULAR_LARGE = '15x15',
  DYNAMIC = 'Dynamic',
}

export enum ResultSetTypeUI {
  PERILS = 'PERILS',
  ECONOMIC_IMPACTS = 'ECONOMIC IMPACTS',
  FLOOD_MESH = 'FLOOD MESH',
  SCORES = 'SCORES',
}

export enum EIModules {
  ACUTE_COMBINED_FLOOD = 'ACUTE_COMBINED_FLOOD',
  ACUTE_WIND = 'ACUTE_WIND',
  UTILITIES_COOLING = 'UTILITIES_COOLING',
  PRODUCTIVITY_HEAT = 'PRODUCTIVITY_HEAT',
  FINANCIAL = 'FINANCIAL',
  ACUTE_FIRE = 'ACUTE_FIRE',
  UTILITIES_WATER = 'UTILITIES_WATER',
}

export interface ResultSetInput {
  name?: string;
  parameters: ResultSetParametersInput;
  portfolioId: string;
  type: ResultSetType;
}

export interface ResultSetOptionsUI {
  disabled: boolean;
  selected: boolean;
  name: string;
  id: string;
  type: ResultSetTypeUI;
  tooltip?: string;
}

export type ParameterOptionsUIDataTypeUI = 'STRING' | 'INT' | 'FLOAT';

interface SelectionListItem {
  name: string;
  value: string;
  id: string;
}

export interface ParameterOptionsUI {
  id: CryptoUUID;
  name: string;
  selected: boolean; // Required for Select/Checkbox/Radio/Transfer; Redundant for TextInputs, but useful all the same
  value?: string; // Only for TextInputs
  selectionList?: SelectionListItem[]; // Only for Selects(dropdowns)
  disabled?: boolean;
  tooltip?: string; // <-- IDEA
  testId?: string;
  dataVersionRestrictions?: string[];
  dataTypeUI?: ParameterOptionsUIDataTypeUI;
  placeholder?: string;
  forbidden?: boolean; // 'true' if parameter is not allowed for selected CSG version
  validate?: (props: ValidatorProps) => ValidationUI; // to define parameter specific validator
  isFieldEnabler?: boolean; // 'true' if the control is used to enable/disable a set of fields
}

interface ResultSetConfigUI extends ResultSetOptionsUI {
  parameters: {
    [key: string]: ParameterOptionsUI[];
  };
}

export interface ResultSetConfig {
  // [key: ResultSetTypeUI]: ResultSetConfigUI;
  [ResultSetTypeUI.PERILS]: ResultSetConfigUI;
  [ResultSetTypeUI.ECONOMIC_IMPACTS]: ResultSetConfigUI;
  [ResultSetTypeUI.FLOOD_MESH]: ResultSetConfigUI;
  [ResultSetTypeUI.SCORES]: ResultSetConfigUI;
}
