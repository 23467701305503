import { ChipProps } from '@mui/material';
import { FC } from 'react';
import { px } from '../utils/styleUtils';
import Pill from './pill/Pill';

export const MOCK_DATA_TAG_WIDTH: number = 100;

const MockDataTag: FC<ChipProps> = ({ sx = {} }) => {
  return (
    <Pill
      data-testid="mock-data-tag"
      label="sample data"
      sx={{
        backgroundColor: '#667F9F',
        width: px(MOCK_DATA_TAG_WIDTH),
        textAlign: 'center',
        marginLeft: '0.5rem',
        ...sx,
      }}
    />
  );
};

export default MockDataTag;
