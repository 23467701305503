import { LatLng } from './types';

export const LOCATION_LIMIT: number = 50; // user can only upload 50 locations or fewer

export const numArrayFromString = (str: string): string[] =>
  str
    ?.split(',')
    .map((n) => parseFloat(n))
    .map((n) => JSON.stringify(n));

export const deriveCoordsFromInputString = (coordsStr: string): LatLng => {
  // NOTE: Assume order is ALWAYS [Lat,Lng]
  const coordsArray = numArrayFromString(coordsStr);
  const [lat, lng] = coordsArray;
  return { lat, lng };
};

export const GUIDELINES_FOR_COORDINATE_COMPOSITION = [
  { key: 'coord-up-gd0', text: 'Guidelines:' },
  {
    key: 'coord-up-gd1',
    text: 'Coordinates should be provided in decimal degrees format, for example, "40.734,-73.994".',
  },
  {
    key: 'coord-up-gd2',
    text: 'Coordinates should include at least one non-zero decimal fraction, such as "40.1,-73.2" or "40.01,-73.02".',
  },
  {
    key: 'coord-up-gd3',
    text: 'Latitude must fall within the range of -90 to +90 degrees, while longitude must be between -180 to +180 degrees.',
  },
  { key: 'coord-up-gd4', text: 'You can enter up to 50 coordinates manually.' },
  {
    key: 'coord-up-gd5',
    text: 'If you must enter more than 50 locations, please upload a .csv file.',
  },
  {
    key: 'coord-up-gd6',
    text: 'Analysis may not be accessible for locations that are far offshore.',
  },
];
