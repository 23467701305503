import { alpha, Box, Container, Grid, Link, Slide, Typography, useTheme } from '@mui/material';
import { FC, ReactElement, ReactNode } from 'react';
import { DRAWER_BACKGROUND_STYLE, DRAWER_MIN_WIDTH } from '../../../../components/Drawer';
import GrowList from '../../../../components/GrowList';
import { CenteredRowStyle, px } from '../../../../utils/styleUtils';

const CopyrightLinks = (): ReactElement => (
  <>
    <Typography variant="body1">© Copyright Jupiter 2023</Typography>
    <Typography variant="body1">
      <Typography display="inline" variant="body1">
        {'© '}
      </Typography>
      <Link
        sx={{ color: '#fff' }}
        href="https://www.jupiterintel.com/legal/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Typography display="inline" variant="body1">
          Privacy Policy
        </Typography>
      </Link>
      <Typography display="inline" variant="body1">
        {' and '}
      </Typography>
      <Link
        sx={{ color: '#fff' }}
        href="https://www.jupiterintel.com/legal/online-services-agreement"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Typography display="inline" variant="body1">
          Terms of Service
        </Typography>
      </Link>
    </Typography>
  </>
);

export interface BannerSectionProps {
  children?: ReactNode | ReactNode[] | ReactElement | ReactElement[] | string;
}

const BannerSection: FC<BannerSectionProps> = ({ children = null }: BannerSectionProps) => {
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        width: '100vw',
        height: '100%',
      }}
    >
      <Grid
        item
        sm
        container
        sx={{
          ...CenteredRowStyle,
          display: { xs: 'none', sm: 'none', md: 'flex' }, // hide when the screen size is super small
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          width: `calc(100% - ${px(DRAWER_MIN_WIDTH)})`,
        }}
      >
        <Grid item xs={12} sm>
          <Box
            sx={{
              borderRadius: '0.5rem',
              border: `solid 1px ${alpha('#fff', 0.3).toString()}`,
              maxWidth: px(DRAWER_MIN_WIDTH),
              padding: '1.5rem',
              backgroundColor: alpha('#fff', 0.3).toString(),
              margin: 'auto',
            }}
          >
            <img
              alt="circle-icons"
              style={{
                height: '40px',
              }}
              src={`${window._env_.REACT_APP_APP_ROOT}/img/circle-icons.png`}
            />
            <Typography
              sx={{
                paddingTop: '0.75rem',
                color: '#fff',
                fontWeight: 'bold',
                paddingBottom: '1rem',
                fontSize: '1.75rem',
              }}
              variant="h5"
            >
              Predicting Risk In A Changing Climate
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: '#fff',
              }}
            >
              Jupiter is the trusted leader in climate risk analytics, turning sophisticated climate
              science into actionable data. To find out more about Jupiter, visit our website at
              www.jupiterintel.com.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          sx={{
            padding: '0 2rem',
            bottom: 0,
            width: px(DRAWER_MIN_WIDTH),
            position: 'absolute',
            display: 'flex',
            justifyContent: 'space-between',
            height: 40,
          }}
        >
          <CopyrightLinks />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md
        container
        justifyContent="flex-end"
        sx={{
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <Slide
          in
          direction="left"
          appear
          timeout={{
            // NOTE: Same as transitionDuration in <Drawer/>
            enter: theme.transitions.duration.enteringScreen,
            exit: theme.transitions.duration.leavingScreen,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              ...DRAWER_BACKGROUND_STYLE,
              height: '100%',
            }}
          >
            <GrowList>
              <Container
                sx={{
                  padding: '2rem !important',
                }}
              >
                {children}
              </Container>
              <Box
                sx={{
                  padding: '2rem',
                  bottom: 0,
                  position: 'absolute',
                  justifyContent: 'space-between',
                  height: 40,
                  width: '100%',
                  display: { xs: 'flex', sm: 'flex', md: 'none' }, // only show when the screen size is super small
                }}
              >
                <CopyrightLinks />
              </Box>
            </GrowList>
          </Grid>
        </Slide>
      </Grid>
    </Grid>
  );
};
export default BannerSection;
