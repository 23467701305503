import { StyleProps } from '../../../utils/styleUtils';

const strDonutImages = ['DonutActive_x2', 'DonutInactive_x2'];
const strDonutMockImages = ['DonutMockDataActive_x2', 'DonutMockDataInactive_x2'];

export const PORTFOLIO_BG_IMG_SX: StyleProps = {
  width: '14%',
  position: 'absolute',
  bottom: 0,
  right: 0,
};

export const PORTFOLIO_MOCKED_BG_IMG_SX: StyleProps = {
  ...PORTFOLIO_BG_IMG_SX,
  width: '30.6%',
};

export const PORTFOLIO_MOCKED_BG_IMG_SX_SELECTED: StyleProps = {
  ...PORTFOLIO_BG_IMG_SX,
  width: '28.75%',
};

export const getPortfolioBgImgSx = (isActive: boolean, isMocked = false): StyleProps => {
  const bgStyle = isMocked
    ? [PORTFOLIO_MOCKED_BG_IMG_SX_SELECTED, PORTFOLIO_MOCKED_BG_IMG_SX][isActive ? 0 : 1]
    : PORTFOLIO_BG_IMG_SX;
  return bgStyle;
};

export const getPortfolioImgSrc = (isActive: boolean, isMocked = false): string => {
  const bgImage = [...(isMocked ? strDonutMockImages : strDonutImages)][isActive ? 0 : 1];
  return `${window._env_.REACT_APP_APP_ROOT}/img/${bgImage}.png`;
};
