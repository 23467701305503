import { MenuItemProps, MenuProps as MuiMenuProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { StyleProps } from '../../utils/styleUtils';

export enum MenuPositionX {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum MenuPositionY {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export type MenuOriginProps = Pick<MuiMenuProps, 'anchorOrigin' | 'transformOrigin'>;
export type MenuOrigins = Record<MenuPositionY, Record<MenuPositionX, MenuOriginProps>>;
export type MenuArrowSx = Record<MenuPositionY, Record<MenuPositionX, StyleProps>>;

export interface MenuOptionProps {
  title: string | ReactElement | ReactElement[] | ReactNode | ReactNode[];
  id: string;
  onClick: () => void;
  icon?: null | ReactElement;
  disabled?: boolean;
  inProgress?: boolean;
  itemSx?: StyleProps;
}

export interface MenuProps extends MuiMenuProps {
  options?: MenuOptionProps[];
  horzOrientation?: MenuPositionX;
  vertOrientation?: MenuPositionY;
  MenuItemProps?: MenuItemProps;
}
