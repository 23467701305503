import { get, isEmpty, isNil } from 'lodash';
import { CheckboxOption } from '../../components/checkbox/types';
import { RadioOption } from '../../components/radio/types';
import { TextInputOption } from '../../components/text-input/types';
import {
  atLeastOneSelected,
  itemNotForbidden,
  itemSelected,
} from '../../pages/query-builder/utils';
// import { benchmarkingIsEnabled, floodDefenseIsEnabled } from '../../pages/query-builder/builder';
import {
  DamageAndLossLabelUI,
  PerilUI,
  ResultSetOptionsUI,
  ResultSetTypeUI,
} from '../../pages/query-builder/types';
import {
  QueryBuilderValidation,
  ValidationSectionTypeUI,
  ValidationUI,
} from '../../pages/query-builder/validation/types';
import {
  validOccupancyCodeForScheme,
  validSelectionOfBaselineAnd1995,
} from '../../pages/query-builder/validation/utils';
import { DamageAndLossOptionalUI } from '../../pages/query-builder/constants';
import { QueryBuilderStatus } from '../slices/queryBuilderSlice';
import { userCanAccessKnowledgeBase } from '../slices/userSlice';
import { RootState } from '../store';
import {
  validatePositiveFloats,
  validatePositiveIntegers,
} from '../../pages/query-builder/validation/validators';

export const selectQueryBuilderStatus = (state: RootState): QueryBuilderStatus => {
  return state.queryBuilder?.status ?? '';
};

export const selectQueryBuilderOptions = (state: RootState): ResultSetOptionsUI[] => {
  return state.queryBuilder?.queryBuilderOptions ?? [];
};

export const selectKnowledgeBaseAccess = (state: RootState): boolean => {
  return userCanAccessKnowledgeBase(state.user);
};

// ------------------------------------------ 1. Perils Only
export const selectPerilsPerils = (state: RootState): CheckboxOption[] =>
  state.queryBuilder?.perils?.perils ?? [];

export const selectPerilsScenarios = (state: RootState): CheckboxOption[] =>
  state.queryBuilder?.perils?.scenarios ?? [];

export const selectPerilsYears = (state: RootState): CheckboxOption[] =>
  state.queryBuilder?.perils?.years ?? [];

export const selectPerilsFloodDefense = (state: RootState): CheckboxOption[] =>
  state.queryBuilder?.perils?.floodDefense ?? [];

export const selectPerilsFloodDefenseOptions = (state: RootState): CheckboxOption[] =>
  state.queryBuilder?.perils?.floodDefenseOptions ?? [];

export const selectPerilsValidation = (state: RootState): QueryBuilderValidation[] => {
  const { perils, scenarios, years, floodDefenseOptions } = state.queryBuilder.perils;

  const validationArray = [
    {
      section: ValidationSectionTypeUI.PERILS,
      valid: atLeastOneSelected(perils),
      message: 'At least one peril must be selected',
    },
    {
      section: ValidationSectionTypeUI.PERILS,
      valid: atLeastOneSelected(scenarios),
      message: 'At least one scenario must be selected',
    },
    {
      section: ValidationSectionTypeUI.PERILS,
      valid: atLeastOneSelected(years),
      message: 'At least one year must be selected',
    },
    {
      section: ValidationSectionTypeUI.PERILS,
      ...validSelectionOfBaselineAnd1995({ years, scenarios }),
    },
  ];

  /* NOTE: Disabling the validation below because
   * 1. The UI hides `floodDefenseOptions` when floodDefenseIsEnabled == false
   * 2. The builder will set floodDefense.enabled = false when in the case of 1.

  if (floodDefenseOptions.filter(itemSelected).length > 0) {
    return validationArray.concat({
      section: ValidationSectionTypeUI.PERILS,
      valid: floodDefenseIsEnabled(state.queryBuilder.perils),
      message: 'Flood defense must be enabled',
    });
  }

  TODO: Handle nested options like this more elegantly

  */
  return validationArray;
};
// ------------------------------------------ 2. Damage & Loss

export const selectDamageAndLossPerils = (state: RootState): CheckboxOption[] =>
  state.queryBuilder?.damageAndLoss?.perils ?? [];

export const selectDamageAndLossScenarios = (state: RootState): CheckboxOption[] =>
  state.queryBuilder?.damageAndLoss?.scenarios ?? [];

export const selectDamageAndLossYears = (state: RootState): CheckboxOption[] =>
  state.queryBuilder?.damageAndLoss?.years ?? [];

export const selectDamageAndLossFloodDefense = (state: RootState): CheckboxOption[] =>
  state.queryBuilder?.damageAndLoss?.floodDefense ?? [];

export const selectDamageAndLossFloodDefenseOptions = (state: RootState): CheckboxOption[] =>
  state.queryBuilder?.damageAndLoss?.floodDefenseOptions ?? [];

export const selectDamageAndLossDamageValues = (state: RootState): TextInputOption[] =>
  state.queryBuilder?.damageAndLoss?.damageAndLossValues ?? [];

export const selectDamageAndLossLossValues = (state: RootState): TextInputOption[] =>
  state.queryBuilder?.damageAndLoss?.lossCalculationValues ?? [];

export const selectDamageAndLossAdvParamValues = (state: RootState): TextInputOption[] =>
  state.queryBuilder?.damageAndLoss?.advancedParameterValues ?? [];

export const selectDamageAndLossWorkerProductivity = (state: RootState): CheckboxOption[] =>
  state.queryBuilder?.damageAndLoss?.workerProductivity ?? [];

export const selectDamageAndLossWorkerProductivityValues = (state: RootState): TextInputOption[] =>
  state.queryBuilder?.damageAndLoss?.workerProductivityValues ?? [];

export const selectDamageAndLossWildfireLoss = (state: RootState): CheckboxOption[] =>
  state.queryBuilder?.damageAndLoss?.wildfireLoss ?? [];

export const selectDamageAndLossWildfireLossValues = (state: RootState): TextInputOption[] =>
  state.queryBuilder?.damageAndLoss?.wildfireLossValues ?? [];

export const selectDamageAndLossCostOfWater = (state: RootState): CheckboxOption[] =>
  state.queryBuilder?.damageAndLoss?.costOfWater ?? [];

export const selectDamageAndLossCostOfWaterValues = (state: RootState): TextInputOption[] =>
  state.queryBuilder?.damageAndLoss?.costOfWaterValues ?? [];

export const selectDamageAndLossPortfolioLossMetrics = (state: RootState): CheckboxOption[] =>
  state.queryBuilder?.damageAndLoss?.portfolioLossMetrics ?? [];

export const selectDamageAndLossFinancialValues = (state: RootState): TextInputOption[] =>
  state.queryBuilder?.damageAndLoss?.financialParametersValues ?? [];

export const selectDamageAndLossFinancialMetrics = (state: RootState): CheckboxOption[] =>
  state.queryBuilder?.damageAndLoss?.financialMetrics ?? [];

export const selectDamageAndLossValidation = (state: RootState): QueryBuilderValidation[] => {
  const {
    perils,
    scenarios,
    years,
    floodDefenseOptions,
    damageAndLossValues,
    lossCalculationValues,
    advancedParameterValues,
    workerProductivityValues,
    wildfireLossValues,
    costOfWaterValues,
    financialParametersValues,
  } = state.queryBuilder.damageAndLoss;

  const windAndOrCombinedFloodSelected = (): boolean => {
    return Boolean(
      perils.filter(
        (peril: CheckboxOption) =>
          [PerilUI.WIND.toString(), PerilUI.COMBINED_FLOOD.toString()].includes(peril.name) &&
          peril.selected,
      ).length,
    );
  };

  const dlNumericIntegerType = (item: TextInputOption): boolean => item.dataTypeUI === 'INT';
  const dlNumericFloatType = (item: TextInputOption): boolean => item.dataTypeUI === 'FLOAT';
  const dlWithCustomValidators = (item: TextInputOption): boolean => !isNil(item.validate);
  const dlRequiredFields = (item: TextInputOption): boolean =>
    !DamageAndLossOptionalUI.includes(item.name as DamageAndLossLabelUI);
  const dlOptionalFields = (item: TextInputOption): boolean => {
    return (
      DamageAndLossOptionalUI.includes(item.name as DamageAndLossLabelUI) && !isEmpty(item.value)
    );
  };

  const dlValues = [
    ...damageAndLossValues,
    ...lossCalculationValues,
    ...advancedParameterValues,
    ...workerProductivityValues,
    ...wildfireLossValues,
    ...costOfWaterValues,
    ...financialParametersValues,
  ].filter((item) => itemNotForbidden(item) && itemSelected(item));

  const dlRequiredValues = dlValues.filter(dlRequiredFields);
  const dlOptionalValues = dlValues.filter(dlOptionalFields);

  const dlOccupancyScheme = dlValues.find(
    (item) => item.name === DamageAndLossLabelUI.OCCUPANCY_SCHEME,
  )?.value;
  const dlOccupancyCode = dlValues.find(
    (item) => item.name === DamageAndLossLabelUI.OCCUPANCY_CODE,
  )?.value;

  const dlRequiredIntegers = dlRequiredValues.filter(dlNumericIntegerType);
  const dlOptionalIntegers = dlOptionalValues.filter(dlNumericIntegerType);

  const dlRequiredFloats = dlRequiredValues.filter(dlNumericFloatType);
  const dlOptionalFloats = dlOptionalValues.filter(dlNumericFloatType);

  const validationArrayIntegers = dlRequiredIntegers.map((item) => {
    const param = {
      sectionType: ValidationSectionTypeUI.ECONOMIC_IMPACTS,
      value: item.value,
      fieldName: item.name,
    };
    return dlWithCustomValidators(item)
      ? item.validate?.(param) ?? validatePositiveIntegers(param)
      : validatePositiveIntegers(param);
  });

  const validationArrayIntegersOptional = dlOptionalIntegers.map((item) => {
    const param = {
      sectionType: ValidationSectionTypeUI.ECONOMIC_IMPACTS,
      value: item.value,
      fieldName: item.name,
      isOptional: true,
    };
    return dlWithCustomValidators(item)
      ? item.validate?.(param) ?? validatePositiveIntegers(param)
      : validatePositiveIntegers(param);
  });

  const validationArrayFloats = dlRequiredFloats.map((item) => {
    const param = {
      sectionType: ValidationSectionTypeUI.ECONOMIC_IMPACTS,
      value: item.value,
      fieldName: item.name,
    };
    return dlWithCustomValidators(item)
      ? item.validate?.(param) ?? validatePositiveFloats(param)
      : validatePositiveFloats(param);
  });

  const validationArrayFloatsOptional = dlOptionalFloats.map((item) => {
    const param = {
      sectionType: ValidationSectionTypeUI.ECONOMIC_IMPACTS,
      value: item.value,
      fieldName: item.name,
      isOptional: true,
    };
    return dlWithCustomValidators(item)
      ? item.validate?.(param) ?? validatePositiveFloats(param)
      : validatePositiveFloats(param);
  });

  const validationArray: ValidationUI[] = [
    {
      section: ValidationSectionTypeUI.ECONOMIC_IMPACTS,
      valid: atLeastOneSelected(perils),
      message: 'At least one peril must be selected',
    },
    {
      section: ValidationSectionTypeUI.ECONOMIC_IMPACTS,
      valid: atLeastOneSelected(scenarios),
      message: 'At least one scenario must be selected',
    },
    {
      section: ValidationSectionTypeUI.ECONOMIC_IMPACTS,
      valid: atLeastOneSelected(years),
      message: 'At least one year must be selected',
    },
    {
      section: ValidationSectionTypeUI.ECONOMIC_IMPACTS,
      valid: windAndOrCombinedFloodSelected(),
      message: 'Wind and/or Combined Flood peril must be selected',
    },
    {
      section: ValidationSectionTypeUI.ECONOMIC_IMPACTS,
      ...validSelectionOfBaselineAnd1995({ years, scenarios }),
    },
    {
      section: ValidationSectionTypeUI.ECONOMIC_IMPACTS,
      valid:
        isEmpty(dlOccupancyCode) ||
        validOccupancyCodeForScheme({ scheme: dlOccupancyScheme, code: dlOccupancyCode }),
      message: 'Occupancy Code must be valid for selected Occupancy Scheme',
    },
    ...validationArrayIntegers,
    ...validationArrayIntegersOptional,
    ...validationArrayFloats,
    ...validationArrayFloatsOptional,
  ];

  /* NOTE: Disabling the validation below because
   * 1. The UI hides `floodDefenseOptions` when floodDefenseIsEnabled == false
   * 2. The builder will set floodDefense.enabled = false when in the case of 1.

  if (floodDefenseOptions.filter(itemSelected).length > 0) {
    return validationArray.concat({
      section: ValidationSectionTypeUI.ECONOMIC_IMPACTS,
      valid: floodDefenseIsEnabled(state.queryBuilder.damageAndLoss),
      message: 'Flood defense must be enabled',
    });
  }

  TODO: Handle nested options like this more elegantly

  */
  return validationArray;
};

// ------------------------------------------ 3. Flood Mesh

export const selectFloodMeshGridSizes = (state: RootState): RadioOption[] =>
  state.queryBuilder.floodMesh.gridSizes ?? [];

export const selectFloodMeshScenarios = (state: RootState): CheckboxOption[] =>
  state.queryBuilder.floodMesh.scenarios ?? [];

export const selectFloodMeshYears = (state: RootState): CheckboxOption[] =>
  state.queryBuilder.floodMesh.years ?? [];

export const selectFloodMeshFloodDefense = (state: RootState): CheckboxOption[] =>
  state.queryBuilder.floodMesh.floodDefense ?? [];

export const selectFloodMeshFloodDefenseOptions = (state: RootState): CheckboxOption[] =>
  state.queryBuilder.floodMesh.floodDefenseOptions ?? [];

export const selectFloodMeshValidation = (state: RootState): QueryBuilderValidation[] => {
  const { gridSizes, scenarios, years, floodDefenseOptions } = state.queryBuilder.floodMesh;

  const validationArray = [
    {
      section: ValidationSectionTypeUI.FLOOD_MESH,
      valid: atLeastOneSelected(gridSizes),
      message: 'Grid size must be selected',
    },
    {
      section: ValidationSectionTypeUI.FLOOD_MESH,
      valid: atLeastOneSelected(scenarios),
      message: 'At least one scenario must be selected',
    },
    {
      section: ValidationSectionTypeUI.FLOOD_MESH,
      valid: atLeastOneSelected(years),
      message: 'At least one year must be selected',
    },
    {
      section: ValidationSectionTypeUI.FLOOD_MESH,
      ...validSelectionOfBaselineAnd1995({ years, scenarios }),
    },
  ];

  /* NOTE: Disabling the validation below because
   * 1. The UI hides `floodDefenseOptions` when floodDefenseIsEnabled == false
   * 2. The builder will set floodDefense.enabled = false when in the case of 1.

      if (floodDefenseOptions.filter(itemSelected).length > 0) {
        return validationArray.concat({
          section: ValidationSectionTypeUI.FLOOD_MESH,
          valid: floodDefenseIsEnabled(state.queryBuilder.floodMesh),
          message: 'Flood defense must be enabled',
        });
      }

  TODO: Handle nested options like this more elegantly

      */

  return validationArray;
};

// ------------------------------------------ 4. Scores

export const selectScoresPerils = (state: RootState): CheckboxOption[] =>
  state.queryBuilder?.scores?.scorePerils ?? [];

export const selectScoresBenchmarkingIncluded = (state: RootState): CheckboxOption[] =>
  state.queryBuilder?.scores?.benchmarkingIncluded ?? [];

export const selectScoresBenchmarkingOptions = (state: RootState): CheckboxOption[] =>
  state.queryBuilder?.scores?.benchmarkingOptions ?? [];

export const selectScoresValidation = (state: RootState): QueryBuilderValidation[] => {
  const { scorePerils, benchmarkingIncluded, benchmarkingOptions } = state.queryBuilder.scores;

  const validationArray = [
    {
      section: ValidationSectionTypeUI.SCORES,
      valid: atLeastOneSelected(scorePerils),
      message: 'At least one score must be selected',
    },
  ];

  if (benchmarkingIncluded.filter(itemSelected).length > 0) {
    return validationArray.concat({
      section: ValidationSectionTypeUI.SCORES,
      valid: atLeastOneSelected(benchmarkingOptions),
      message: 'At least one option must be selected',
    });
  }
  /* NOTE: Disabling the validation below because
   * 1. The UI hides `benchmarkingOptions` when benchmarkingIsEnabled == false
   * 2. The builder will set benchmarking.enabled = false when in the case of 1.

  if (benchmarkingOptions.filter(itemSelected).length > 0) {
    return validationArray.concat({
      section: ValidationSectionTypeUI.SCORES,
      valid: benchmarkingIsEnabled(state.queryBuilder.scores),
      message: 'Benchmarking must be enabled',
    });
  }

  TODO: Handle nested options like this more  elegantly
    */
  return validationArray;
};

// ------------------------------------------ Validation

export const selectResultSetTypeValidation = (state: RootState): QueryBuilderValidation[] => {
  const { queryBuilderOptions } = state.queryBuilder;

  return [
    {
      section: ValidationSectionTypeUI.GLOBAL,
      valid: atLeastOneSelected(queryBuilderOptions),
      message: 'At least one result set type must be selected',
    },
  ];
};

const isInvalid = (condition: any): boolean => get(condition, 'valid', false) === false;

export const selectQueryBuilderValidation = (state: RootState): QueryBuilderValidation => {
  const { queryBuilderOptions } = state.queryBuilder;

  // validation checks all the section for result set types selected
  const validationChecks = queryBuilderOptions.filter(itemSelected).reduce((acc, curr) => {
    switch (curr?.type) {
      case ResultSetTypeUI.PERILS:
        return acc.concat(selectPerilsValidation(state));

      case ResultSetTypeUI.FLOOD_MESH:
        return acc.concat(selectFloodMeshValidation(state));

      case ResultSetTypeUI.ECONOMIC_IMPACTS:
        return acc.concat(selectDamageAndLossValidation(state));

      case ResultSetTypeUI.SCORES:
        return acc.concat(selectScoresValidation(state));

      default:
        // section === GLOBAL
        break;
    }

    return acc;
  }, selectResultSetTypeValidation(state));

  const invalid = validationChecks.filter(isInvalid);

  // all validation checks should pass
  return {
    section: ValidationSectionTypeUI.GLOBAL,
    valid: invalid.length === 0,
    message: 'Please select all required options.',
    failed: invalid,
  };
};

export default selectQueryBuilderOptions;
