import { TypographyProps as MuiTypographyProps } from '@mui/material';
import { isNil } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
  selectAvailableDataVersions,
  selectDataVersionTitle,
} from '../redux/selectors/csgDataSelectors';
import { getDataVersion } from '../redux/slices/csgDataSlice';
import { StyledMediumLabel } from './labels/StyledLabel';

interface DataVersionTypographyProps extends MuiTypographyProps {
  prefix?: string;
  notFoundText?: string;
  showAllAvailableVersions?: boolean;
}

const DataVersionTypography: FC<DataVersionTypographyProps> = ({
  prefix = null,
  notFoundText = null,
  showAllAvailableVersions = false,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const dataVersion = useAppSelector(selectDataVersionTitle);
  const availableDataVersions = useAppSelector(selectAvailableDataVersions);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchVersion = async (): Promise<void> => {
    setLoading(true);
    await dispatch(getDataVersion());
    setLoading(false);
  };

  useEffect(() => {
    // fetch versions only if they are not already available in global state
    // these is to avoid redundant API call for same data
    if (isNil(availableDataVersions) || availableDataVersions.length === 0) {
      void fetchVersion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableDataVersions]);

  const renderDataVersion = (): string => {
    if (loading) {
      return 'Loading';
    }
    if (dataVersion === null) {
      return `${notFoundText ?? 'Version Not Available'}`;
    }
    if (showAllAvailableVersions) {
      return `${availableDataVersions.join(', ')}`;
    }
    return `${prefix ? `${prefix} ` : ''}${dataVersion}`;
  };

  return (
    <StyledMediumLabel variant="h6" display="inline" {...props}>
      {renderDataVersion()}
    </StyledMediumLabel>
  );
};

export default DataVersionTypography;
