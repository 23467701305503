import { UUID } from '../../api/types';

export enum ReportType {
  SLR = 'single_location',
}

export enum SLRScenarioType {
  SSP2 = 'ssp2',
  SSP5 = 'ssp5',
}

export enum SLRTemplateType {
  CR_BASIC = 'cr_basic',
  EX_BASIC = 'ex_basic',
  CR_STANDARD = 'cr_standard',
  EX_STANDARD = 'ex_standard',
  CR_ADVANCED = 'cr_advanced',
  EX_ADVANCED = 'ex_advanced',
  FIDELITY = 'fidelity',
}

export enum SLRTemplate {
  BASIC = 'basic',
  STANDARD = 'standard',
  ADVANCED = 'advanced',
  FIDELITY = 'fidelity',
}

export enum SLRColorCoding {
  CHANGE = 'change',
  EXPOSURE = 'exposure',
}

export interface SLRTemplateJSON {
  type: SLRTemplate;
  scenario: SLRScenarioType;
  colorCoding: SLRColorCoding;
}

export interface CreateSLRInput {
  perilsResultSetId?: UUID;
  perilsEIResultSetId?: UUID;
  scoresResultSetId?: UUID;
  templateJSON: SLRTemplateJSON;
  locationId?: number;
}

export interface APICreateSLRInput {
  type: ReportType;
  jupiter_template: {
    type: SLRTemplate;
    scenario: SLRScenarioType;
    color_coding: SLRColorCoding;
  };
  perils_result_set_id?: UUID;
  scores_result_set_id?: UUID;
  impacts_result_set_id?: UUID;
  location_ids?: number[]; // Max num. of locations allowed: 25
}

export interface APISLR {
  id: UUID;
  customer_id: UUID;
  user_id: UUID;
  type: ReportType;
  jupiter_template: SLRTemplateType;
  perils_result_set_id?: UUID;
  scores_result_set_id?: UUID;
  impacts_result_set_id?: UUID;
  location_ids?: string[];
}

export interface SLRRestAPIError {
  detail?: {
    detail: string;
    details: Record<string, string | number | Array<string | number>>;
    loc: string[];
    status: number;
    title: string;
    type: string;
  };
}
