import { get, isEmpty } from 'lodash';
import {
  BenchmarkingInputType,
  BenchmarkingLevel,
  CsgVersionInputType,
  DamageAndLossField,
  DamageAndLossInputType,
  FloodDefenseInputType,
  FloodMeshInputType,
  GridSize,
  Peril,
  PerilsInputType,
  ResultSetType,
  Scenario,
  ScenariosInputType,
  ScorePeril,
  ScorePerilsInputType,
  Year,
  YearsInputType,
} from '../../api/resultSets/types';
import { CheckboxOption } from '../../components/checkbox/types';
import { RadioOption } from '../../components/radio/types';
import { TextInputOption } from '../../components/text-input/types';
import { VersionOption } from '../../redux/slices/csgDataSlice';
import {
  BenchmarkingLabelUI,
  BenchmarkingLevelUI,
  CSG_VERSION,
  DamageAndLossLabelUI,
  FinancialMetricsLabelUI,
  FloodDefenseLabelUI,
  GridSizeUI,
  PerilUI,
  PortfolioLossMetricsLabelUI,
  ResultSetDefaultNameUI,
  ResultSetInput,
  ResultSetTypeUI,
  ScenarioUI,
  ScorePerilUI,
  WaterConsumptionLabelUI,
  WildfireLossLabelUI,
  WorkerProductivityLabelUI,
} from './types';
import {
  getEIModulesByCSGVersion,
  getEIVersionByCSGVersion,
  itemNotForbidden,
  itemSelected,
} from './utils';

const toInt = (input: any): number => parseInt(input, 10);
const toFloat = (input: any): number => parseFloat(input);
export const getElecCostParamKey = (csgVersion: string): DamageAndLossField => {
  switch (csgVersion) {
    case CSG_VERSION.v3_1_0:
      return DamageAndLossField.ELECTRICITY_COST;
    case CSG_VERSION.v3_0_0:
    default:
      return DamageAndLossField.ELECTRICITY_COST_USD;
  }
};

export const financialMetricsIsEnabled = ({
  // NOTE: This function is reused in validation
  financialMetrics,
}: {
  financialMetrics: CheckboxOption[];
}): boolean => {
  const selectedOptions = financialMetrics.filter(itemSelected);
  const foundEnabledOption =
    selectedOptions.find((item) => item.name === FinancialMetricsLabelUI.ENABLED) ?? false;
  return foundEnabledOption !== false;
};

export const workerProductivityIsEnabled = ({
  // NOTE: This function is reused in validation
  workerProductivity,
}: {
  workerProductivity: CheckboxOption[];
}): boolean => {
  const selectedOptions = workerProductivity.filter(itemSelected);
  const foundEnabledOption =
    selectedOptions.find((item) => item.name === WorkerProductivityLabelUI.ENABLED) ?? false;
  return foundEnabledOption !== false;
};

export const wildfireLossIsEnabled = ({
  // NOTE: This function is reused in validation
  wildfireLoss,
}: {
  wildfireLoss: CheckboxOption[];
}): boolean => {
  const selectedOptions = wildfireLoss.filter(itemSelected);
  const foundEnabledOption =
    selectedOptions.find((item) => item.name === WildfireLossLabelUI.ENABLED) ?? false;
  return foundEnabledOption !== false;
};

export const costOfWaterIsEnabled = ({
  // NOTE: This function is reused in validation
  costOfWater,
}: {
  costOfWater: CheckboxOption[];
}): boolean => {
  const selectedOptions = costOfWater.filter(itemSelected);
  const foundEnabledOption =
    selectedOptions.find((item) => item.name === WaterConsumptionLabelUI.ENABLED) ?? false;
  return foundEnabledOption !== false;
};

export const portfolioLossMetricsIsEnabled = ({
  // NOTE: This function is reused in validation
  portfolioLossMetrics,
}: {
  portfolioLossMetrics: CheckboxOption[];
}): boolean => {
  const selectedOptions = portfolioLossMetrics.filter(itemSelected);
  const foundEnabledOption =
    selectedOptions.find((item) => item.name === PortfolioLossMetricsLabelUI.ENABLED) ?? false;
  return foundEnabledOption !== false;
};

const buildDamageAndLoss = (
  {
    enabled = true,
    damageAndLossValues,
    lossCalculationValues,
    advancedParameterValues,
    workerProductivity,
    workerProductivityValues,
    wildfireLoss,
    wildfireLossValues,
    costOfWater,
    costOfWaterValues,
    financialParametersValues,
    financialMetrics,
  }: {
    enabled?: boolean;
    damageAndLossValues: TextInputOption[];
    lossCalculationValues: TextInputOption[];
    advancedParameterValues: TextInputOption[];
    workerProductivity: CheckboxOption[];
    workerProductivityValues: TextInputOption[];
    wildfireLoss: CheckboxOption[];
    wildfireLossValues: TextInputOption[];
    costOfWater: CheckboxOption[];
    costOfWaterValues: TextInputOption[];
    financialParametersValues: TextInputOption[];
    financialMetrics: CheckboxOption[];
  },
  csgVersion = '',
): DamageAndLossInputType => {
  const DEFAULT = null;

  try {
    if (!enabled) return { enabled: false };

    const fields = [
      ...damageAndLossValues,
      ...lossCalculationValues,
      ...advancedParameterValues,
      ...(workerProductivityIsEnabled({ workerProductivity }) ? workerProductivityValues : []),
      ...(wildfireLossIsEnabled({ wildfireLoss }) ? wildfireLossValues : []),
      ...(costOfWaterIsEnabled({ costOfWater }) ? costOfWaterValues : []),
      ...(financialMetricsIsEnabled({ financialMetrics }) ? financialParametersValues : []),
    ];

    // const defaultFields = fields.filter(itemSelected).reduce((acc, curr) => {
    const defaultFields = fields
      .filter((item) => itemNotForbidden(item) && itemSelected(item))
      .reduce((acc, curr) => {
        switch (curr.name) {
          case DamageAndLossLabelUI.OCCUPANCY_SCHEME:
            return {
              ...acc,
              [DamageAndLossField.OCCUPANCY_SCHEME]: curr.value, // should be a string
            };
          case DamageAndLossLabelUI.OCCUPANCY_CODE:
            return {
              ...acc,
              [DamageAndLossField.OCCUPANCY_CODE]: toInt(curr.value),
            };
          case DamageAndLossLabelUI.NUMBER_OF_STORIES:
            return {
              ...acc,
              [DamageAndLossField.NUMBER_OF_STORIES]: toInt(curr.value),
            };
          case DamageAndLossLabelUI.BASEMENT_CODE:
            return {
              ...acc,
              [DamageAndLossField.BASEMENT_CODE]: toInt(curr.value),
            };
          case DamageAndLossLabelUI.TOTAL_VALUE:
            return {
              ...acc,
              [DamageAndLossField.TOTAL_VALUE]: toFloat(curr.value),
            };
          case DamageAndLossLabelUI.BUILDING_VALUE:
            return {
              ...acc,
              [DamageAndLossField.BUILDING_VALUE]: toFloat(curr.value),
            };
          case DamageAndLossLabelUI.CONTENTS_VALUE:
            return {
              ...acc,
              [DamageAndLossField.CONTENTS_VALUE]: toFloat(curr.value),
            };
          case DamageAndLossLabelUI.INVENTORY_VALUE:
            return {
              ...acc,
              [DamageAndLossField.INVENTORY_VALUE]: toFloat(curr.value),
            };
          // 3.0.0 - 3.1.0
          case DamageAndLossLabelUI.SUB_INDUSTRY_CODE:
            return {
              ...acc,
              [DamageAndLossField.SUB_INDUSTRY_CODE]: !isEmpty(curr.value)
                ? curr.value // should be a string
                : null,
            };
          case DamageAndLossLabelUI.FIRST_FLOOR_ELEVATION:
            return {
              ...acc,
              [DamageAndLossField.FIRST_FLOOR_ELEVATION]: !isEmpty(curr.value)
                ? toFloat(curr.value)
                : null,
            };
          case DamageAndLossLabelUI.FLOOR_AREA_SQM:
            return {
              ...acc,
              [DamageAndLossField.FLOOR_AREA_SQM]: !isEmpty(curr.value)
                ? toFloat(curr.value)
                : null,
            };
          case DamageAndLossLabelUI.DOWNTIME_VALUE:
            return {
              ...acc,
              [DamageAndLossField.DOWNTIME_VALUE]: !isEmpty(curr.value)
                ? toFloat(curr.value)
                : null,
            };
          case DamageAndLossLabelUI.REMOTE_WORK_RATIO:
            return {
              ...acc,
              [DamageAndLossField.REMOTE_WORK_RATIO]: !isEmpty(curr.value)
                ? toFloat(curr.value)
                : null,
            };
          case DamageAndLossLabelUI.ELECTRICITY_COST:
            return {
              ...acc,
              [getElecCostParamKey(csgVersion)]: !isEmpty(curr.value) ? toFloat(curr.value) : null,
            };
          case DamageAndLossLabelUI.COOLING_SYSTEM_PROBABILITY:
            return {
              ...acc,
              [DamageAndLossField.COOLING_SYSTEM_PROBABILITY]: !isEmpty(curr.value)
                ? toFloat(curr.value)
                : null,
            };
          case DamageAndLossLabelUI.WORK_INTENSITY:
            return {
              ...acc,
              [DamageAndLossField.WORK_INTENSITY]: !isEmpty(curr.value.trim())
                ? curr.value.trim()
                : null,
            };
          case DamageAndLossLabelUI.ANNUAL_GROWTH_RATE:
            return {
              ...acc,
              [DamageAndLossField.ANNUAL_GROWTH_RATE]: !isEmpty(curr.value)
                ? toFloat(curr.value)
                : null,
            };
          case DamageAndLossLabelUI.ANNUAL_VOLATILITY_RATE:
            return {
              ...acc,
              [DamageAndLossField.ANNUAL_VOLATILITY_RATE]: !isEmpty(curr.value)
                ? toFloat(curr.value)
                : null,
            };
          case DamageAndLossLabelUI.SALES_MARGIN:
            return {
              ...acc,
              [DamageAndLossField.SALES_MARGIN]: !isEmpty(curr.value) ? toFloat(curr.value) : null,
            };
          case DamageAndLossLabelUI.DISCOUNT_RATE:
            return {
              ...acc,
              [DamageAndLossField.DISCOUNT_RATE]: !isEmpty(curr.value) ? toFloat(curr.value) : null,
            };
          case DamageAndLossLabelUI.FINANCIAL_BASE_YEAR:
            return {
              ...acc,
              [DamageAndLossField.FINANCIAL_BASE_YEAR]: !isEmpty(curr.value)
                ? toInt(curr.value)
                : null,
            };
          // 3.1.0
          case DamageAndLossLabelUI.WINDOW_PANE:
            return {
              ...acc,
              [DamageAndLossField.WINDOW_PANE]: !isEmpty(curr.value.trim())
                ? curr.value.trim()
                : null,
            };
          case DamageAndLossLabelUI.VENT_TYPE:
            return {
              ...acc,
              [DamageAndLossField.VENT_TYPE]: !isEmpty(curr.value.trim())
                ? curr.value.trim()
                : null,
            };
          case DamageAndLossLabelUI.ROOF_COVER:
            return {
              ...acc,
              [DamageAndLossField.ROOF_COVER]: !isEmpty(curr.value.trim())
                ? curr.value.trim()
                : null,
            };
          case DamageAndLossLabelUI.WATER_CONSUMPTION:
            return {
              ...acc,
              [DamageAndLossField.WATER_CONSUMPTION]: !isEmpty(curr.value)
                ? toFloat(curr.value)
                : null,
            };
          case DamageAndLossLabelUI.WATER_SHADOW_PRICE_RATIO:
            return {
              ...acc,
              [DamageAndLossField.WATER_SHADOW_PRICE_RATIO]: !isEmpty(curr.value)
                ? toFloat(curr.value)
                : null,
            };

          default:
            return acc;
        }
      }, {});

    return {
      enabled,
      defaultFields,
    };
  } catch (error) {
    console.error('builder.buildDamageAndLoss ERROR:', error);
  }

  return DEFAULT;
};

const buildMesh = ({
  enabled = true,
  gridSizes,
}: {
  enabled?: boolean;
  gridSizes: RadioOption[];
}): FloodMeshInputType => {
  const DEFAULT = null;

  try {
    if (!enabled) return { enabled: false };

    const gridSizeNameMapping = {
      [GridSizeUI.REGULAR_LARGE]: GridSize.FIFTEEN,
      [GridSizeUI.DYNAMIC]: GridSize.DYNAMIC,
    };

    const getInternalName = (item: RadioOption): GridSize => {
      return get(gridSizeNameMapping, item.name);
    };

    const gridSize = get(gridSizes.filter(itemSelected).map(getInternalName), '[0]');

    return {
      enabled,
      gridSize,
      // gridType,
      // x: gridType === GridType.REGULAR ? 15 : null,
      // y: gridType === GridType.REGULAR ? 15 : null,
    };
  } catch (error) {
    console.error('builder.buildMesh ERROR:', error);
  }

  return DEFAULT;
};

export const benchmarkingIsEnabled = ({
  // NOTE: This function is reused in validation
  benchmarkingIncluded,
}: {
  benchmarkingIncluded: CheckboxOption[];
}): boolean => {
  const selectedOptions = benchmarkingIncluded.filter(itemSelected);
  const foundEnabledOption =
    selectedOptions.find((item) => item.name === BenchmarkingLabelUI.ENABLED) ?? false;
  return foundEnabledOption !== false;
};

const buildBenchmarking = ({
  benchmarkingIncluded,
  benchmarkingOptions,
}: {
  benchmarkingIncluded: CheckboxOption[];
  benchmarkingOptions: CheckboxOption[];
}): BenchmarkingInputType => {
  const DEFAULT = null;

  try {
    const enabled = benchmarkingIsEnabled({ benchmarkingIncluded });

    if (!enabled) return { enabled: false };

    const benchmarkingNameMapping = {
      [BenchmarkingLevelUI.COUNTRY]: BenchmarkingLevel.COUNTRY,
      [BenchmarkingLevelUI.ADMIN1]: BenchmarkingLevel.ADMIN1,
      [BenchmarkingLevelUI.ADMIN2]: BenchmarkingLevel.ADMIN2,
    };

    const getInternalName = (item: CheckboxOption): BenchmarkingLevel => {
      return get(benchmarkingNameMapping, item.name);
    };

    return {
      enabled,
      levels: benchmarkingOptions.filter(itemSelected).map(getInternalName),
    };
  } catch (error) {
    console.error('builder.buildBenchmarking ERROR:', error);
  }

  return DEFAULT;
};
export const floodDefenseIsEnabled = ({
  // NOTE: This function is reused in validation
  floodDefense,
}: {
  floodDefense: CheckboxOption[];
}): boolean => {
  const selectedOptions = floodDefense.filter(itemSelected);
  const foundEnabledOption =
    selectedOptions.find((item) => item.name === FloodDefenseLabelUI.ENABLED) ?? false;
  return foundEnabledOption !== false;
};

const buildFloodDefense = ({
  floodDefense,
  floodDefenseOptions,
}: {
  floodDefense: CheckboxOption[];
  floodDefenseOptions: CheckboxOption[];
}): FloodDefenseInputType => {
  const DEFAULT = null;

  try {
    const enabled = floodDefenseIsEnabled({ floodDefense });

    if (!enabled) return { enabled: false };
    return {
      enabled,
      zeroOutDefendedLocations:
        floodDefenseOptions
          .filter(itemSelected)
          .find((item) => item.name === FloodDefenseLabelUI.ZERO_OUT_DEFENDED_LOCATIONS) !==
        undefined,
    };
  } catch (error) {
    console.error('builder.buildFloodDefense ERROR:', error);
  }

  return DEFAULT;
};

const buildPerils = ({ perils }: { perils: CheckboxOption[] }): PerilsInputType => {
  const DEFAULT = null;

  try {
    const perilNameMapping = {
      [PerilUI.WIND]: Peril.WIND,
      [PerilUI.COLD]: Peril.COLD,
      [PerilUI.PRECIP]: Peril.PRECIP,
      [PerilUI.HEAT]: Peril.HEAT,
      [PerilUI.COMBINED_FLOOD]: Peril.COMBINED_FLOOD,
      [PerilUI.HAIL]: Peril.HAIL,
      [PerilUI.DROUGHT]: Peril.DROUGHT,
      [PerilUI.FIRE]: Peril.FIRE,
      // 3.0.0 - 3.1.0
      [PerilUI.COASTAL_FLOOD]: Peril.COASTAL_FLOOD,
      [PerilUI.PLUVIAL_FLOOD]: Peril.PLUVIAL_FLOOD,
      [PerilUI.FLUVIAL_FLOOD]: Peril.FLUVIAL_FLOOD,
    };

    const getInternalName = (item: CheckboxOption): Peril => {
      return get(perilNameMapping, item.name);
    };

    return perils.filter(itemSelected).map(getInternalName);
  } catch (error) {
    console.error('builder.buildPerils ERROR:', error);
  }

  return DEFAULT;
};

const buildScenarios = ({ scenarios }: { scenarios: CheckboxOption[] }): ScenariosInputType => {
  const DEFAULT = null;

  try {
    const scenarioNameMapping = {
      [ScenarioUI.BASELINE]: Scenario.BASELINE,
      [ScenarioUI.SSP_126]: Scenario.SSP_126,
      [ScenarioUI.SSP_245]: Scenario.SSP_245,
      [ScenarioUI.SSP_585]: Scenario.SSP_585,
    };

    const getInternalName = (item: CheckboxOption): Scenario => {
      return get(scenarioNameMapping, item.name);
    };

    return scenarios.filter(itemSelected).map(getInternalName);
  } catch (error) {
    console.error('builder.buildScenarios ERROR:', error);
  }

  return DEFAULT;
};

const buildYears = ({ years }: { years: CheckboxOption[] }): YearsInputType => {
  const DEFAULT = null;

  try {
    const getInternalName = (item: CheckboxOption): Year => {
      return toInt(item.name);
    };

    return years.filter(itemSelected).map(getInternalName);
  } catch (error) {
    console.error('builder.buildYears ERROR:', error);
  }

  return DEFAULT;
};

const buildScores = ({ scorePerils }: { scorePerils: CheckboxOption[] }): ScorePerilsInputType => {
  const DEFAULT = null;

  try {
    const scoreNameMapping = {
      [ScorePerilUI.WIND]: ScorePeril.WIND,
      [ScorePerilUI.COLD]: ScorePeril.COLD,
      [ScorePerilUI.PRECIP]: ScorePeril.PRECIP,
      [ScorePerilUI.HEAT]: ScorePeril.HEAT,
      [ScorePerilUI.COMBINED_FLOOD]: ScorePeril.COMBINED_FLOOD,
      [ScorePerilUI.HAIL]: ScorePeril.HAIL,
      [ScorePerilUI.DROUGHT]: ScorePeril.DROUGHT,
      [ScorePerilUI.FIRE]: ScorePeril.FIRE,
      [ScorePerilUI.ALL_PERILS]: ScorePeril.ALL_PERILS,
    };

    const getInternalName = (item: CheckboxOption): ScorePeril => {
      return get(scoreNameMapping, item.name);
    };

    return scorePerils.filter(itemSelected).map(getInternalName);
  } catch (error) {
    console.error('builder.buildScores ERROR:', error);
  }
  return DEFAULT;
};
export const constructResultSetPayloads = (state: any): ResultSetInput[] => {
  const {
    queryBuilderOptions,
    portfolioId,
    // ------------------------------------------ 1. Perils Only
    perils,
    // ------------------------------------------ 2. Damage & Loss
    damageAndLoss,
    // ------------------------------------------ 3. Flood Mesh
    floodMesh,
    // ------------------------------------------ 4. Scores
    scores,
  } = state.queryBuilder;

  const { csgDataVersions, eiVersions } = state.csgData;

  const csgVersion: CsgVersionInputType =
    csgDataVersions.find((versionOption: VersionOption) => versionOption.selected)?.name ?? '';
  const eiVersion: CsgVersionInputType =
    eiVersions.find((versionOption: VersionOption) => versionOption.selected)?.name ?? csgVersion;

  const resultSetTypeMapping = {
    [ResultSetTypeUI.PERILS]: ResultSetType.PERILS,
    [ResultSetTypeUI.ECONOMIC_IMPACTS]: ResultSetType.ECONOMIC_IMPACTS,
    [ResultSetTypeUI.FLOOD_MESH]: ResultSetType.FLOOD_MESH,
    [ResultSetTypeUI.SCORES]: ResultSetType.SCORES,
  };

  const getInternalName = (item: { type: ResultSetTypeUI }): ResultSetType => {
    return get(resultSetTypeMapping, item.type);
  };

  const selectedResultSetTypes = queryBuilderOptions
    .filter(itemSelected)
    .map(getInternalName)
    .filter(Boolean);

  return selectedResultSetTypes
    .map((type: ResultSetType) => {
      switch (type) {
        case ResultSetType.ECONOMIC_IMPACTS: {
          return {
            type,
            portfolioId,
            name: ResultSetDefaultNameUI.ECONOMIC_IMPACTS,
            parameters: {
              csgVersion,
              eiVersion,
              eiModules: getEIModulesByCSGVersion(
                csgVersion,
                financialMetricsIsEnabled({ financialMetrics: damageAndLoss.financialMetrics }),
                workerProductivityIsEnabled({
                  workerProductivity: damageAndLoss.workerProductivity,
                }),
                wildfireLossIsEnabled({
                  wildfireLoss: damageAndLoss.wildfireLoss,
                }),
                costOfWaterIsEnabled({
                  costOfWater: damageAndLoss.costOfWater,
                }),
              ),
              damageAndLoss: buildDamageAndLoss(damageAndLoss, csgVersion),
              floodDefense: buildFloodDefense(damageAndLoss),
              perils: buildPerils(damageAndLoss),
              scenarios: buildScenarios(damageAndLoss),
              years: buildYears(damageAndLoss),
              portfolioLossMetrics: portfolioLossMetricsIsEnabled(damageAndLoss),
            },
          };
        }
        case ResultSetType.FLOOD_MESH: {
          return {
            type,
            portfolioId,
            name: ResultSetDefaultNameUI.FLOOD_MESH,
            parameters: {
              csgVersion,
              eiVersion,
              floodDefense: buildFloodDefense(floodMesh),
              mesh: buildMesh(floodMesh),
              scenarios: buildScenarios(floodMesh),
              years: buildYears(floodMesh),
            },
          };
        }
        case ResultSetType.PERILS: {
          return {
            type,
            portfolioId,
            name: ResultSetDefaultNameUI.PERILS,
            parameters: {
              csgVersion,
              eiVersion,
              floodDefense: buildFloodDefense(perils),
              perils: buildPerils(perils),
              scenarios: buildScenarios(perils),
              years: buildYears(perils),
            },
          };
        }
        case ResultSetType.SCORES: {
          const benchmarking = buildBenchmarking(scores);

          return {
            type,
            portfolioId,
            name: benchmarking?.enabled
              ? ResultSetDefaultNameUI.SCORES_WITH_BENCHMARKS
              : ResultSetDefaultNameUI.SCORES,
            parameters: {
              csgVersion,
              eiVersion,
              benchmarking: buildBenchmarking(scores),
              scores: buildScores(scores),
            },
          };
        }
        default:
          // eslint-disable-next-line no-console
          console.warn('Unknown ResultSetType:', type);
          return null;
      }
    })
    .filter((item: null | ResultSetInput) => item !== null);
};

export default constructResultSetPayloads;
