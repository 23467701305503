import { UUID } from '../../api/types';
import { fetch } from '../rest';
import { APIResponse } from '../http';
import { APIToken } from './types';

export async function getDoc360Token(userId: UUID): Promise<APIResponse<APIToken>> {
  return await fetch(
    `https://${window._env_.REACT_APP_API_IO_ENDPOINT}/users/${userId}/doc360_token`,
  );
}

export async function getDundasToken(userId: UUID): Promise<APIResponse<APIToken>> {
  return await fetch(
    `https://${window._env_.REACT_APP_API_IO_ENDPOINT}/users/${userId}/dundas_token`,
  );
}
