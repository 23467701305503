import { get, lowerCase } from 'lodash';
import { ACTIVE_BUTTON_STYLE, PRIMARY_BORDER, StyleProps } from '../../utils/styleUtils';
import { CheckboxOption } from '../checkbox/types';

export const getId = (item: any): string | null => get(item, 'id', null);

export const idMatch = (item1: CheckboxOption, item2: CheckboxOption): boolean =>
  getId(item1) === getId(item2);

export const isFilteredByText = (item: any, filterString: string, filterKey: string): boolean =>
  lowerCase(item[`${filterKey}`]).includes(lowerCase(filterString));

export const getSectionedButtonsSx = (
  leftBtnDisabled = false,
  rightBtnDisabled = false,
): StyleProps[] => {
  let leftBtnSx = leftBtnDisabled ? {} : ACTIVE_BUTTON_STYLE;
  let rightBtnSx = rightBtnDisabled ? {} : ACTIVE_BUTTON_STYLE;
  if (leftBtnDisabled && !rightBtnDisabled) {
    leftBtnSx = { ...leftBtnSx, borderRight: 0 };
    rightBtnSx = { ...rightBtnSx, borderLeft: `${PRIMARY_BORDER} !important` }; // To override css with more specificity
  }

  return [leftBtnSx, rightBtnSx];
};

export const DEFAULT_LEFT_LABEL: string = 'Available Options';

export const DEFAULT_RIGHT_LABEL: string = 'Selected Options';
