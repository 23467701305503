import { ResultSetStatus } from '../../api/resultSets/types';
import { AlertObject } from '../../components/alerts/types';
import { AppSection, Document360LoadingStatus } from '../slices/uiSlice';
import { RootState } from '../store';

export const selectAppAlerts = (state: RootState): AlertObject[] => state.ui?.alerts ?? [];

export const selectAppContentSection = (state: RootState): AppSection =>
  state.ui?.appSection ?? AppSection.Explorer;

export const selectDownloadDrawerOpen = (state: RootState): boolean =>
  state.ui?.downloadDrawer?.open ?? false;

export const selectSLRDrawerOpen = (state: RootState): boolean => state.ui?.slrDrawerOpen ?? false;

export const selectDownloadResultSetId = (state: RootState): string | null =>
  state.ui?.downloadDrawer?.resultSetId ?? null;

export const selectDownloadResultSetName = (state: RootState): string | null =>
  state.ui?.downloadDrawer?.resultSetName ?? null;

export const selectDownloadResultSetStatus = (state: RootState): ResultSetStatus | null =>
  state.ui?.downloadDrawer?.resultSetStatus ?? null;

export const selectDownloadPortfolioId = (state: RootState): string | null =>
  state.ui?.downloadDrawer?.portfolioId ?? null;

export const selectDownloadPortfolioName = (state: RootState): string | null =>
  state.ui?.downloadDrawer?.portfolioName ?? null;

export const selectQueryBuilderDrawerOpen = (state: RootState): boolean =>
  state.ui?.queryBuilderDrawerOpen ?? false;

export const selectSettingsDrawerOpen = (state: RootState): boolean =>
  state.ui?.settingsDrawerOpen ?? false;

export const selectVizNavMenuOpen = (state: RootState): boolean =>
  state.ui?.vizNavMenuOpen ?? false;

export const selectNavPath = (state: RootState): string[] => {
  const { selectedPortfolio, selectedResultSet } = state.csgData;

  const path = [selectedPortfolio?.name ?? '', selectedResultSet?.name ?? ''];

  return path;
};

export const selectDocument360IsLoading = (state: RootState): boolean => {
  return state.ui.document360.status === Document360LoadingStatus.LOADING;
};

export const selectAIAssistantVisible = (state: RootState): boolean => state.ui.showAIAssistant;
