import {
  Box,
  Drawer as MuiDrawer,
  DrawerProps,
  PaperProps,
  useTheme,
  BoxProps,
} from '@mui/material';
import { FC, ReactNode } from 'react';
import { TOP_BAR_HEIGHT } from '../pages/main/TopBar/util';
import { SIDE_PANEL_BG_COLOR, px } from '../utils/styleUtils';

interface MuiDrawerProps extends DrawerProps {
  drawerOpen: boolean;
  setDrawer: Function;
  children?: ReactNode;
  PaperProps?: PaperProps;
  containerProps?: BoxProps;
}

export const DRAWER_BACKGROUND_STYLE = {
  // NOTE: background style from QueryBuilderSpec20230112:
  backgroundColor: SIDE_PANEL_BG_COLOR,
  //
  // NOTE: background style from DesignSpec20221127:
  // backgroundImage: LINEAR_GRADIENT_3
  boxShadow: '-8px 8px 16px 0 rgba(0,0,0,0.10)',
  borderRadius: '2px 0 0 2px',
};

export const DRAWER_MIN_WIDTH: number = 545;

const Drawer: FC<MuiDrawerProps> = ({
  drawerOpen,
  setDrawer,
  children = [],
  containerProps,
  ...rest
}) => {
  const theme = useTheme();
  const handleDrawerClose = (): void => {
    setDrawer(false);
  };

  return (
    <MuiDrawer
      color="inherit"
      data-testid="drawer"
      anchor="right"
      open={drawerOpen}
      onClose={handleDrawerClose}
      transitionDuration={{
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
      }}
      sx={{
        top: TOP_BAR_HEIGHT,
        '& .MuiBackdrop-root': {
          top: TOP_BAR_HEIGHT,
        },
      }}
      PaperProps={{
        sx: {
          top: TOP_BAR_HEIGHT,
          height: `calc(100% - ${px(TOP_BAR_HEIGHT)})`,
          color: '#fff',
          ...DRAWER_BACKGROUND_STYLE,
        },
      }}
      {...rest}
    >
      <Box
        {...containerProps}
        sx={{
          minWidth: {
            xs: 'none',
            sm: 'none',
            md: px(DRAWER_MIN_WIDTH),
          },
          height: '100%',
          overflowY: 'auto',
          maxWidth: '98vw',
          ...containerProps?.sx,
        }}
      >
        {children}
      </Box>
    </MuiDrawer>
  );
};

export default Drawer;
