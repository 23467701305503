import { FC, useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import FloodDefense from '../sub-options/FloodDefense';
import Years from '../sub-options/Years';
import Scenarios from '../sub-options/Scenarios';
import GridSize from '../sub-options/GridSize';
import { CheckboxOperation, UpdateCheckboxInstructions } from '../../../components/checkbox/types';
import { UpdateRadioInstructions } from '../../../components/radio/types';
import {
  selectFloodMeshGridSizes,
  selectFloodMeshScenarios,
  selectFloodMeshYears,
  selectFloodMeshFloodDefense,
  selectFloodMeshFloodDefenseOptions,
} from '../../../redux/selectors/queryBuilderSelectors';
import {
  setFloodMeshGridSizes,
  setFloodMeshScenarios,
  setFloodMeshYears,
  setFloodMeshFloodDefense,
  setFloodMeshFloodDefenseOptions,
} from '../../../redux/slices/queryBuilderSlice';
import { floodDefenseIsEnabled } from '../builder';

const FloodMesh: FC = () => {
  const dispatch = useAppDispatch();
  const gridSizes = useAppSelector(selectFloodMeshGridSizes);
  const scenarios = useAppSelector(selectFloodMeshScenarios);
  const years = useAppSelector(selectFloodMeshYears);
  const floodDefense = useAppSelector(selectFloodMeshFloodDefense);
  const floodDefenseOptions = useAppSelector(selectFloodMeshFloodDefenseOptions);

  const setGridSizes = (payload: UpdateRadioInstructions): void => {
    dispatch(setFloodMeshGridSizes(payload));
  };
  const setScenarios = (payload: UpdateCheckboxInstructions): void => {
    dispatch(setFloodMeshScenarios(payload));
  };
  const setYears = (payload: UpdateCheckboxInstructions): void => {
    dispatch(setFloodMeshYears(payload));
  };
  const setFloodDefense = (payload: UpdateCheckboxInstructions): void => {
    dispatch(setFloodMeshFloodDefense(payload));
  };
  const setFloodDefenseOptions = useCallback(
    (payload: UpdateCheckboxInstructions): void => {
      dispatch(setFloodMeshFloodDefenseOptions(payload));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!floodDefenseIsEnabled({ floodDefense })) {
      setFloodDefenseOptions({ operation: CheckboxOperation.DisableAll });
      setFloodDefenseOptions({ operation: CheckboxOperation.DeselectAll, skipForbidden: false });
    } else {
      setFloodDefenseOptions({ operation: CheckboxOperation.EnableAll });
      setFloodDefenseOptions({ operation: CheckboxOperation.SelectAll, skipForbidden: false });
    }
  }, [floodDefense, setFloodDefenseOptions]);

  return (
    <>
      <GridSize list={gridSizes} setList={setGridSizes} title="Grid size" />
      <Scenarios list={scenarios} setList={setScenarios} title="Scenarios" />
      <Years list={years} setList={setYears} title="Years" />
      <FloodDefense
        list={floodDefense}
        setList={setFloodDefense}
        title="Flood Defense"
        nestedList={floodDefenseOptions}
        setNestedList={setFloodDefenseOptions}
      />{' '}
    </>
  );
};

export default FloodMesh;
