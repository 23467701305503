import { listCategories, createCategory } from './category';
import { APIResponse } from '../http';
import { APICategory, APICreateCategoryInput } from './types';

export async function listCategoriesAdapter(): Promise<APIResponse<APICategory[]>> {
  const { data, error } = await listCategories();
  if (error) {
    return { data: null, error };
  }
  return { data, error: null };
}

export async function createCategoryAdapter(
  input: APICreateCategoryInput,
): Promise<APIResponse<APICategory>> {
  const { data, error } = await createCategory(input);
  if (error) {
    return { data: null, error };
  }
  return { data, error: null };
}
