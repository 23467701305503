import { Box, Link, Typography, useTheme } from '@mui/material';
import { get } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import DataVersionTypography from '../../../components/DataVersionTypography';
import EIVersionTypography from '../../../components/EIVersionTypography';
import Drawer from '../../../components/Drawer';
import withErrorBoundary from '../../../components/ErrorBoundary/withErrorBoundary';
import Accordion from '../../../components/accordion/Accordion';
import { StyledMediumLabel } from '../../../components/labels/StyledLabel';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectDownloadsById, selectUsageStats } from '../../../redux/selectors/csgDataSelectors';
import {
  selectDocument360IsLoading,
  selectSettingsDrawerOpen,
} from '../../../redux/selectors/uiSelectors';
import { selectUserDetails } from '../../../redux/selectors/userSelectors';
import { DataStatus, downloadUsageReport, getUsageStats } from '../../../redux/slices/csgDataSlice';
import { getDocument360Token, setSettingsDrawer } from '../../../redux/slices/uiSlice';
import { UserObject } from '../../../redux/slices/userSlice';
import {
  DRAWER_COLUMN_CONTAINER,
  LITE_TEXT_COLOR,
  SIDE_PANEL_CONTAINER_BORDER,
  SIDE_PANEL_TITLE_COLOR,
  spacing,
} from '../../../utils/styleUtils';
import { DOWNLOAD_USAGE_REPORT_ID } from './constants';
import { SettingsDrawerSection } from './types';
import { dataItemIsLoading, renderAccordionDetailsV1 } from './util';
import SwitchButton, { IndividualButtonProps } from '../../../components/buttons/SwitchButton';
import { selectKnowledgeBaseAccess } from '../../../redux/selectors/queryBuilderSelectors';
import TextUnderlineButton from '../../../components/TextUnderlineButton';
import { Title } from '../../../components/InfoText';

const APP_VERSION: string = '3.1.5';

const SettingsDrawer: FC = () => {
  const dispatch = useAppDispatch();
  const drawerOpen = useAppSelector(selectSettingsDrawerOpen);
  const userDetails: UserObject = useAppSelector(selectUserDetails);
  const doc360IsLoading = useAppSelector(selectDocument360IsLoading);
  const downloadsById = useAppSelector(selectDownloadsById);
  const userCanAccessKnowledgeBase = useAppSelector(selectKnowledgeBaseAccess);
  const usageStatsData = useAppSelector(selectUsageStats);
  const theme = useTheme();

  const [showUsersByCategory, setShowUsersByCategory] = useState<boolean>(false);

  const fetchUsageStats = async (): Promise<void> => {
    await dispatch(getUsageStats());
  };

  useEffect(() => {
    if (drawerOpen) void fetchUsageStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  const isUsageReportDownloading = useMemo(() => {
    const dataItem = get(downloadsById, DOWNLOAD_USAGE_REPORT_ID, { status: DataStatus.IDLE });
    return dataItemIsLoading(dataItem);
  }, [downloadsById]);

  const setNewDrawerState = (newState: boolean): void => {
    dispatch(setSettingsDrawer(newState));
  };

  const handleUsageReportRequestDownload = async (): Promise<void> => {
    await dispatch(downloadUsageReport());
  };

  const handleCancel = (): void => setNewDrawerState(false);

  const actionButtons: IndividualButtonProps[] = [
    {
      title: 'Cancel',
      onClick: handleCancel,
      id: 'settings-action-button-1',
    },
  ];

  const SECTIONS: SettingsDrawerSection[] = [
    {
      title: 'Profile Settings',
      id: 'profile-settings-section-drawer',
      disabled: false,
      content: [
        {
          title: 'Name',
          value: userDetails.username,
          id: 'profile-settings-name-details',
        },
        {
          title: 'E-mail',
          value: userDetails.email,
          id: 'profile-settings-email-details',
        },
      ],
    },
    {
      title: 'Documentation',
      id: 'documentation-section-drawer',
      disabled: !userCanAccessKnowledgeBase,
      content: [],
      documentationData: {
        prefix: 'All documentation and user manuals are available via',
        title: 'CSG Knowledge Base',
        link: window._env_.REACT_APP_DOCUMENT_360_ENDPOINT,
        disabled: !userCanAccessKnowledgeBase || doc360IsLoading,
        loading: doc360IsLoading,
        onClick: () => {
          if (userCanAccessKnowledgeBase) {
            void dispatch(
              getDocument360Token({
                /** Empty as there is no redirectUrl needed */
              }),
            );
          }
        },
      },
    },
    {
      title: 'Usage Stats',
      id: 'usage-summary-section-drawer',
      disabled: false,
      usageStats: {
        userStatsData: usageStatsData.stats,
        isUserStatsLoading: usageStatsData.status === DataStatus.LOADING,
        showUsersByCategory: {
          checked: showUsersByCategory,
          onClick: () => setShowUsersByCategory((prev) => !prev),
        },
        userDetails: {
          user: userDetails.username,
          tenant: userDetails.tenantName,
        },
      },
      content: [
        {
          title: 'Download Report',
          id: 'usage-report-details',
          onClick: handleUsageReportRequestDownload,
          disabled: isUsageReportDownloading,
          subTitle: isUsageReportDownloading ? 'Downloading...' : '',
        },
      ],
    },
    {
      title: 'Client Credentials',
      id: 'client-credentials-section-drawer',
      disabled: false,
      content: [],
    },
    {
      title: 'Contact Us',
      id: 'contact-section-drawer',
      disabled: false,
      content: [],
    },
    {
      title: 'Legal',
      id: 'legal-section-drawer',
      disabled: false,
      content: [
        {
          title: '',
          value: (
            <Title variant="caption">
              By accessing and using the Jupiter Platform, you acknowledge and agree that all data
              and materials provided in the Jupiter Platform is Confidential Information of Jupiter.
              Your access and use of the Jupiter Platform and such data and materials are subject to
              the agreement entered into by and between your company and Jupiter, the{' '}
              <Link
                href="https://www.jupiterintel.com/legal/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: 'inherit' }}
                data-testid="jupiter-privacy-policy"
              >
                <TextUnderlineButton TypographyProps={{ variant: 'h6', sx: { paddingTop: 0 } }}>
                  Jupiter Privacy Policy
                </TextUnderlineButton>
              </Link>
              , and the{' '}
              <Link
                href="https://www.jupiterintel.com/legal/jupiters-acceptable-use-policy-for-jupiter-software-as-a-service/"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: 'inherit' }}
                data-testid="jupiter-saas-use-policy"
              >
                <TextUnderlineButton TypographyProps={{ variant: 'h6', sx: { paddingTop: 0 } }}>
                  Jupiter Acceptable Use Policy
                </TextUnderlineButton>
              </Link>
              .
            </Title>
          ),
          id: 'legal-policy-jupiter',
        },
        {
          title: '',
          value: (
            <Typography sx={{ fontSize: theme.spacing(1.5) }}>
              © 2023 Jupiter. All Rights Reserved.
            </Typography>
          ),
          id: 'copyright-jupiter',
        },
      ],
    },
    {
      title: 'About',
      id: 'about-section-drawer',
      disabled: false,
      content: [
        {
          title: 'App Version',
          value: APP_VERSION,
          id: 'app-version-details',
        },
        {
          title: 'Data Version',
          value: (
            <DataVersionTypography
              showAllAvailableVersions
              variant="h6"
              notFoundText="Data Version Not Available"
              sx={{ paddingTop: 0 }}
            />
          ),
          id: 'data-version-details',
        },
        {
          title: 'EI Version',
          value: (
            <EIVersionTypography
              showAllAvailableVersions
              variant="h6"
              notFoundText="EI Version Not Available"
              sx={{ paddingTop: 0 }}
            />
          ),
          id: 'ei-version-details',
        },
      ],
    },
  ];

  return (
    <Drawer drawerOpen={drawerOpen} setDrawer={setNewDrawerState}>
      <Box
        data-cy="settings-drawer--component"
        sx={{
          ...DRAWER_COLUMN_CONTAINER,
          padding: theme.spacing(2),
          paddingBottom: 0,
          color: LITE_TEXT_COLOR,
          height: '100%',
          minWidth: spacing(112.5),
        }}
      >
        <Box>
          <StyledMediumLabel
            sx={{
              color: SIDE_PANEL_TITLE_COLOR,
              marginBottom: theme.spacing(1.5),
            }}
            variant="h5"
          >
            SETTINGS
          </StyledMediumLabel>
        </Box>
        <Box
          sx={{
            width: '100%',
            maxWidth: {
              sm: '90vw',
              md: '60vw',
            },
            height: '100%',
            maxHeight: `calc(100% - ${theme.spacing(5.5)} - ${theme.spacing(8)})`,
            padding: `0 ${theme.spacing(1)}`,
            border: SIDE_PANEL_CONTAINER_BORDER,
            borderRadius: theme.spacing(0.5),
            overflowY: 'auto',
          }}
        >
          {SECTIONS.map((section) => {
            const { id, disabled = false, title } = section;

            return (
              <Accordion
                option={section}
                key={id}
                expandable={!disabled}
                expandedDefault
                accordionTitle={title}
                sx={{ color: 'inherit' }}
                id={id}
              >
                {renderAccordionDetailsV1(section, theme)}
              </Accordion>
            );
          })}
        </Box>
        <Box sx={{ margin: `${theme.spacing(2)} 0` }}>
          {/* <PrimaryButton
            onClick={() => setNewDrawerState(false)}
            sx={{ height: theme.spacing(4), padding: `${theme.spacing(1)} ${theme.spacing(2)}` }}
          >
            Cancel
          </PrimaryButton> */}
          <SwitchButton type="normal" buttons={actionButtons} />
        </Box>
      </Box>
    </Drawer>
  );
};

export default withErrorBoundary(SettingsDrawer);
