import { FC } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { StripePlaceholder } from '../../../../components/Iterator';
import {
  TOGGLE_TRACK_CHECKED_BG_COLOR,
  CenteredColumnStyle,
  StyleProps,
} from '../../../../utils/styleUtils';

interface LoadingIndicatorProps {
  sx?: StyleProps;
}

const LoadingIndicator: FC<LoadingIndicatorProps> = ({ sx = {} }: LoadingIndicatorProps) => {
  return (
    <StripePlaceholder sx={{ height: '100%', ...sx }}>
      <Box
        sx={{
          ...CenteredColumnStyle,
          width: '100%',
          height: '100%',
          color: TOGGLE_TRACK_CHECKED_BG_COLOR,
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    </StripePlaceholder>
  );
};

export default LoadingIndicator;
