import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import {
  Box,
  Card,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { FC, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { getTitle } from '../../pages/query-builder/options/utils';
import { itemSelected, itemUnselected } from '../../pages/query-builder/utils';
import {
  APP_TEXT_COLOR,
  LIST_SELECTED_BG_COLOR,
  LITE_TEXT_COLOR,
  SECTION_DELIMITER,
  TEXT_FIELD_BG_COLOR,
} from '../../utils/styleUtils';
import SectionedIconButton, { IndividualButtonProps } from '../buttons/SectionedIconButton';
import { CheckboxOption } from '../checkbox/types';
import SearchInput from '../text-input/SearchInput';
import { SelectedListItemType, TransferListProps } from './types';
import {
  DEFAULT_LEFT_LABEL,
  DEFAULT_RIGHT_LABEL,
  getId,
  getSectionedButtonsSx,
  isFilteredByText,
} from './util';

const ITEM_HEIGHT: number = 22.7;

const TransferList: FC<TransferListProps> = ({
  listItems,
  leftLabel = DEFAULT_LEFT_LABEL,
  rightLabel = DEFAULT_RIGHT_LABEL,
  onSelectAll,
  onSelectOne,
  onDeselectAll,
  onDeselectOne,
}) => {
  const theme = useTheme();
  const [selectedListItem, setSelectedListItem] = useState<SelectedListItemType>(null);
  const [searchStringOnLeft, setSearchStringOnLeft] = useState<string>('');
  const [searchStringOnRight, setSearchStringOnRight] = useState<string>('');

  // IDEA: Update selectedItem when the listItems updates
  // useEffect(() => {
  //   if (selectedListItem !== null) {
  //     const intersection = listItems.find((item) => getId(item) === getId(selectedListItem));
  //     if (intersection) {
  //       setSelectedListItem(intersection);
  //     }
  //   }
  // }, [listItems, selectedListItem]);

  const itemsOnTheLeft = useMemo(() => {
    // return listItems.filter(itemUnselected);
    return listItems.filter((item) => {
      const searchStr = (searchStringOnLeft ?? '').trim();
      return (
        itemUnselected(item) && (!searchStr.length || isFilteredByText(item, searchStr, 'name'))
      );
    });
  }, [listItems, searchStringOnLeft]);

  const itemsOnTheRight = useMemo(() => {
    // return listItems.filter(itemSelected);
    return listItems.filter((item) => {
      const searchStr = (searchStringOnRight ?? '').trim();
      return itemSelected(item) && (!searchStr.length || isFilteredByText(item, searchStr, 'name'));
    });
  }, [listItems, searchStringOnRight]);

  const activeItemsOnTheLeft = useMemo(() => {
    return itemsOnTheLeft.filter((item) => !item.forbidden);
  }, [itemsOnTheLeft]);

  const activeItemsOnTheRight = useMemo(() => {
    return itemsOnTheRight.filter((item) => !item.forbidden);
  }, [itemsOnTheRight]);

  const handleToggle = (value: CheckboxOption) => () => {
    setSelectedListItem(value);
  };

  const selectOne = useCallback(
    (target: SelectedListItemType): void => {
      if (target === null) return;
      // deselect item
      setSelectedListItem(null);
      // update list
      onSelectOne(target.id);
    },
    [setSelectedListItem, onSelectOne],
  );

  const deselectOne = useCallback(
    (target: SelectedListItemType): void => {
      if (target === null) return;
      // deselect item
      setSelectedListItem(null);
      // update list
      onDeselectOne(target.id);
    },
    [setSelectedListItem, onDeselectOne],
  );

  const deselectAll = useCallback((): void => {
    // deselect item
    setSelectedListItem(null);
    // update list
    onDeselectAll();
  }, [setSelectedListItem, onDeselectAll]);

  const selectAll = useCallback((): void => {
    // deselect item
    setSelectedListItem(null);
    // update list
    onSelectAll();
  }, [setSelectedListItem, onSelectAll]);

  const transferItemButtons = useMemo<IndividualButtonProps[]>(() => {
    const leftBtnDisabled = selectedListItem === null || itemUnselected(selectedListItem);
    const rightBtnDisabled = selectedListItem === null || itemSelected(selectedListItem);

    const [leftBtnSx, rightBtnSx] = getSectionedButtonsSx(leftBtnDisabled, rightBtnDisabled);

    return [
      {
        icon: <KeyboardArrowLeft />,
        onClick: () => deselectOne(selectedListItem),
        disabled: leftBtnDisabled,
        id: 'arrow-left',
        buttonSx: leftBtnSx,
      },
      {
        icon: <KeyboardArrowRight />,
        onClick: () => selectOne(selectedListItem),
        disabled: rightBtnDisabled,
        id: 'arrow-right',
        buttonSx: rightBtnSx,
      },
    ];
  }, [selectedListItem, deselectOne, selectOne]);

  const transferAllItemButtons = useMemo<IndividualButtonProps[]>(() => {
    const leftBtnDisabled = activeItemsOnTheRight.length === 0;
    const rightBtnDisabled = activeItemsOnTheLeft.length === 0;
    const [leftBtnSx, rightBtnSx] = getSectionedButtonsSx(leftBtnDisabled, rightBtnDisabled);

    return [
      {
        icon: <KeyboardDoubleArrowLeft />,
        onClick: deselectAll,
        disabled: leftBtnDisabled,
        id: 'double-arrow-left',
        buttonSx: leftBtnSx,
      },
      {
        icon: <KeyboardDoubleArrowRight />,
        onClick: selectAll,
        disabled: rightBtnDisabled,
        id: 'double-arrow-right',
        buttonSx: rightBtnSx,
      },
    ];
  }, [activeItemsOnTheLeft, activeItemsOnTheRight, deselectAll, selectAll]);

  const renderCustomList = (
    title: ReactNode,
    options: CheckboxOption[],
    listHeight: number,
    searchString = '',
    onSearchChange?: (event: any) => void,
  ): ReactElement => (
    <>
      <Typography variant="h6" sx={{ paddingBottom: theme.spacing(0.5) }}>
        {title}
      </Typography>
      <Card
        sx={{
          borderRadius: theme.spacing(0.25),
          background: TEXT_FIELD_BG_COLOR,
          padding: 0,
        }}
      >
        {onSearchChange && (
          <SearchInput
            searchInput={searchString}
            onSearchInputChange={onSearchChange}
            sx={{
              borderBottom: SECTION_DELIMITER,
              width: '100%',
              padding: 0,
              marginBottom: theme.spacing(0.25),
            }}
          />
        )}
        <List
          sx={{
            width: 250,
            height: listHeight,
            bgcolor: 'background.paper',
            overflow: 'auto',
            padding: 0,
          }}
          dense
          component="div"
          role="list"
        >
          {options.map((item: CheckboxOption) => {
            const { disabled = false, forbidden = false } = item;
            const itemId = getId(item);
            const labelId = `transfer-list-all-item-${itemId ?? 'unknown'}-label`;
            const isItemDisabled = disabled || forbidden;

            return (
              <ListItemButton
                disableRipple
                disableGutters
                disabled={isItemDisabled}
                sx={{
                  cursor: 'pointer',
                  background:
                    getId(selectedListItem) === itemId ? LIST_SELECTED_BG_COLOR : 'transparent',
                  '&:hover': {
                    background:
                      getId(selectedListItem) === itemId ? LIST_SELECTED_BG_COLOR : 'transparent',
                  },
                  padding: 0,
                  height: ITEM_HEIGHT,
                }}
                key={itemId}
                role="button"
                onClick={handleToggle(item)}
              >
                <ListItemText
                  id={labelId}
                  primary={
                    <Typography
                      variant="h6"
                      sx={{
                        color:
                          getId(selectedListItem) === itemId ? LITE_TEXT_COLOR : APP_TEXT_COLOR,
                        marginLeft: theme.spacing(1),
                      }}
                    >
                      {getTitle(item)}
                    </Typography>
                  }
                />
              </ListItemButton>
            );
          })}
        </List>
      </Card>
    </>
  );
  const listHeight: number = useMemo(() => {
    // the lists will be a minimum of 3 items tall and a maximum of 5 items tall
    const numItems = listItems?.length ?? 0;

    if (numItems >= 5) {
      return ITEM_HEIGHT * 5;
    }

    if (numItems <= 3) {
      return ITEM_HEIGHT * 3;
    }

    return ITEM_HEIGHT * numItems;
  }, [listItems]);

  useEffect(() => {
    // In case of change in list (for instance due to data version change), remove active selection
    setSelectedListItem(null);
  }, [listItems]);

  return (
    <Grid
      sx={{
        margin: 0,
        marginLeft: theme.spacing(0.5),
        justifyContent: 'start',
        alignItems: 'center',
        width: '100%',
        marginBottom: theme.spacing(2),
        color: LITE_TEXT_COLOR,
      }}
      container
      data-testid="transfer-list-container"
    >
      <Grid item data-testid="transfer-list-left">
        {renderCustomList(
          `${leftLabel} (${activeItemsOnTheLeft.length})`,
          itemsOnTheLeft,
          listHeight,
          searchStringOnLeft,
          setSearchStringOnLeft,
        )}
      </Grid>

      <Grid item>
        <Box
          rowGap={1.5}
          sx={{
            height: '100%',
            alignItems: 'center',
            flexDirection: 'column',
            display: 'flex',
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
          }}
        >
          <SectionedIconButton buttons={transferItemButtons} />
          <SectionedIconButton buttons={transferAllItemButtons} />
        </Box>
      </Grid>
      <Grid item data-testid="transfer-list-right">
        {renderCustomList(
          `${rightLabel} (${activeItemsOnTheRight.length})`,
          itemsOnTheRight,
          listHeight,
          searchStringOnRight,
          setSearchStringOnRight,
        )}
      </Grid>
    </Grid>
  );
};

export default TransferList;
