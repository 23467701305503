import { Box, Link, useTheme } from '@mui/material';
import { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import SwitchButton, { IndividualButtonProps } from '../../../components/buttons/SwitchButton';
import Modal from '../../../components/Modal';
import { useAppDispatch } from '../../../redux/hooks';
import { userLogout } from '../../../redux/slices/userSlice';
import { setLegalAcknowledged } from './util';
import { StyledMediumLabel, StyledThinLabel } from '../../../components/labels/StyledLabel';
import { ACTIVE_BUTTON_STYLE } from '../../../utils/styleUtils';
import TextUnderlineButton from '../../../components/TextUnderlineButton';

interface AcknowledgementModalProps {
  open: boolean;
  onConfirm: () => void;
}
const AcknowledgementModal: FC<AcknowledgementModalProps> = ({ open, onConfirm }) => {
  const { logout } = useAuth0();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const handleLogout = async (): Promise<void> => {
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    await dispatch(userLogout());
  };

  const actionButtons: IndividualButtonProps[] = [
    {
      title: 'I Confirm',
      onClick: onConfirm,
      id: 'legal-acknowledge-confirm-button',
      buttonSx: ACTIVE_BUTTON_STYLE,
    },
    {
      title: 'Logout',
      onClick: () => {
        setLegalAcknowledged(false);
        void handleLogout();
      },
      id: 'legal-acknowledge-logout-button',
    },
  ];
  return (
    <Modal
      data-testid="ack-modal"
      open={open}
      onClose={() => {
        // NOTE: User can't close it manually
      }}
    >
      <Box sx={{ marginBottom: theme.spacing(2) }}>
        <StyledMediumLabel variant="h6" noWrap>
          Notice
        </StyledMediumLabel>
      </Box>
      <StyledThinLabel variant="h6">
        I confirm that I am an authorized user of the ClimateScore Global application and that I
        will use the application solely in accordance with the terms of my company&apos;s agreement
        with Jupiter, including the requirement to treat the app and the Jupiter data accessed via
        the app as confidential information of Jupiter. I also acknowledge and agree to the{' '}
        <Link
          href="https://www.jupiterintel.com/legal/jupiters-acceptable-use-policy-for-jupiter-software-as-a-service/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: 'inherit' }}
          data-testid="jupiter-saas-use-policy"
        >
          <TextUnderlineButton TypographyProps={{ variant: 'h6', sx: { paddingTop: 0 } }}>
            Jupiter Privacy Policy
          </TextUnderlineButton>
        </Link>{' '}
        which governs Jupiter&apos;s treatment of my personal information.
      </StyledThinLabel>
      <Box
        sx={{
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(1),
        }}
      >
        <SwitchButton type="normal" buttons={actionButtons} />
      </Box>
    </Modal>
  );
};

export default AcknowledgementModal;
