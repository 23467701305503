import { CssBaseline } from '@mui/material';
import { FC } from 'react';
import TopBar from '../pages/main/TopBar';
import TopBarScrim from '../pages/main/TopBar/TopBarScrim';
import { FlexibleChildrenType } from './Iterator';

const Baseline: FC = () => {
  // CssBaseline kickstart an elegant, consistent, and simple baseline to build upon.
  return <CssBaseline />;
};

interface WrapperProps {
  children: FlexibleChildrenType;
  scrim?: boolean;
  showSkeletal?: boolean;
}

const AppWrapper: FC<WrapperProps> = ({ children, scrim = false, showSkeletal = false }) => {
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Baseline />
      {scrim || showSkeletal ? <TopBarScrim /> : <TopBar />}
      {children}
    </div>
  );
};

export default AppWrapper;
