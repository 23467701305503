import { Typography } from '@mui/material';
import Button, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { LITE_TEXT_COLOR, PRIMARY_BTN_BG_COLOR, WHITE_BORDER } from '../../utils/styleUtils';

interface ButtonProps extends MuiButtonProps {
  component?: string;
}

const PrimaryButton: FC<ButtonProps> = styled(({ children, ...props }: ButtonProps) => (
  <Button {...props}>
    <Typography
      variant="h6"
      sx={{
        color: 'inherit',
      }}
    >
      {children}
    </Typography>
  </Button>
))(({ theme }) => {
  return {
    // ----- h6 - START
    fontSize: theme.spacing(1.75), // 0.875rem :: 14px
    fontWeight: '400', // fontWeight 400 :: "-Regular"
    // ----- h6 - END
    backgroundColor: `${PRIMARY_BTN_BG_COLOR} !important`,
    textTransform: 'none',
    border: WHITE_BORDER,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    height: theme.spacing(3),
    marginRight: 0,
    color: LITE_TEXT_COLOR,
    '&:disabled': {
      color: LITE_TEXT_COLOR,
      border: WHITE_BORDER,
      opacity: 0.6,
    },
  };
});

export default PrimaryButton;
