import { Box, FormControlLabel, Radio as MuiRadio, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { FONT_LIGHT, LITE_TEXT_COLOR, LITE_TEXT_COLOR_DISABLED } from '../../utils/styleUtils';
import InfoIconTooltip from '../InfoIconTooltip';
import { RadioProps } from './types';
import { FONT_ALIGNED_TEXT_STYLE } from '../../theme';

const RadioStyled = styled(MuiRadio)(({ theme }) => ({
  fontFamily: FONT_LIGHT,
  fontWeight: 200,
  padding: 0,
  marginRight: theme.spacing(0.5),
  color: 'inherit',
  '&.Mui-checked': {
    color: 'inherit',
  },
  '&.Mui-disabled': {
    color: LITE_TEXT_COLOR_DISABLED,
  },
  '& .MuiSvgIcon-root': {
    fontSize: theme.spacing(2),
    color: 'inherit',
  },
  ...FONT_ALIGNED_TEXT_STYLE,
}));

const Radio: FC<RadioProps> = ({ option, onRadioClick, ...radioProps }: RadioProps) => {
  const theme = useTheme();
  const { disabled = false, id, name, selected, tooltip = '' } = option;

  return (
    <FormControlLabel
      sx={{ color: LITE_TEXT_COLOR }}
      disabled={disabled}
      key={id}
      value={name}
      control={
        <RadioStyled
          {...radioProps}
          checked={selected}
          disabled={disabled}
          onClick={() => {
            onRadioClick(id);
          }}
        />
      }
      label={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: disabled ? LITE_TEXT_COLOR_DISABLED : 'inherit',
          }}
        >
          <Typography variant="h6">{name}</Typography>
          {tooltip.length > 0 && (
            <InfoIconTooltip tooltip={tooltip} sx={{ marginLeft: theme.spacing(0.5) }} />
          )}
        </Box>
      }
    />
  );
};
export default Radio;
