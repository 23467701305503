import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import get from 'lodash/get';
import { ChangeEvent, DragEvent, FC, useState } from 'react';
import { AlertSeverity } from '../../components/alerts/types';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import SwitchButton, { IndividualButtonProps } from '../../components/buttons/SwitchButton';
import DownloadFilesTrigger from '../../components/DownloadFilesTrigger';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectCSVContent } from '../../redux/selectors/uploadSelectors';
import { addAlert } from '../../redux/slices/uiSlice';
import {
  processLocationsCsvFile,
  resetUploadState,
  setValidationActive,
  submitUploadRequest,
} from '../../redux/slices/uploadSlice';
import { readCSV } from '../../utils/csvUtils';
import {
  APP_TEXT_COLOR,
  CenteredBoxStyle,
  CenteredColumnStyle,
  ColumnStyle,
  DRAWER_COLUMN_CONTAINER,
  DROP_ZONE_ACTIVE_COLOR,
  LITE_DASHED_BORDER,
  LITE_TEXT_COLOR,
  MUI_SPACER,
  SIDE_PANEL_TITLE_COLOR,
} from '../../utils/styleUtils';
import { TEMPLATE_FILE_LINKS } from '../main/nav-header/CreatePortfolio';
import CategorySelector from './CategorySelector';
import { CSVFile } from './types';
import { StyledMediumLabel, StyledThinLabel } from '../../components/labels/StyledLabel';

const ACCEPTED_FILE_EXTENSIONS = ['.csv']; // ['.csv', '.geojson'];

interface UploadFileProps {
  closeDrawer: () => void;
}

const GUIDELINES_FOR_FILE_UPLOAD = [
  { key: 'file-up-gd0', text: 'Guidelines:' },
  {
    key: 'file-up-gd1',
    text: 'The file can contain a maximum of 10,000 locations.',
  },
  { key: 'file-up-gd2', text: 'The file format should be .csv.' },
  {
    key: 'file-up-gd3',
    text: 'The file must have a header row and at least one valid input data row.',
  },
  {
    key: 'file-up-gd4',
    text: 'Coordinates should be in decimal degrees format, for example, "40.734,-73.994".',
  },
  {
    key: 'file-up-gd5',
    text: 'Coordinates must include at least one non-zero decimal fraction, such as "40.1,-73.2" or "40.01,-73.02".',
  },
  {
    key: 'file-up-gd6',
    text: 'Latitude must fall within the range of -90 to +90 degrees, while longitude must be between -180 to +180 degrees.',
  },
  {
    key: 'file-up-gd7',
    text: 'Analysis may not be accessible for locations that are far offshore.',
  },
];

const removeExt = (filename: string, ext: string): string => {
  const extPosition = filename.indexOf(ext);
  if (extPosition > 0) {
    // "ninja_portfolio.csv" --> "ninja_portfolio"
    return filename.slice(0, extPosition);
  }
  return filename;
};

const UploadFile: FC<UploadFileProps> = ({ closeDrawer }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const csvContent = useAppSelector(selectCSVContent);
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onCsvFile = async (fileInQuestion: CSVFile): Promise<void> => {
    setLoading(true);

    const { name } = fileInQuestion;
    const acceptedExt = ACCEPTED_FILE_EXTENSIONS.find((ext) => {
      // extension check is case-insensitive
      return name.toLowerCase().endsWith(ext);
    });

    if (acceptedExt !== undefined) {
      const csvString = await readCSV(fileInQuestion);

      // Submit upload request
      await dispatch(
        submitUploadRequest({
          csvString,
          name: removeExt(name, acceptedExt),
          csv: fileInQuestion,
        }),
      );

      // Get locations for Map preview
      await dispatch(processLocationsCsvFile(fileInQuestion));

      // Show validation
      dispatch(setValidationActive(true)); // IDEA: could be run by submitUploadRequest, if successful

      // Finished loading
      setLoading(false);
      return;
    }

    dispatch(
      addAlert({
        open: true,
        severity: AlertSeverity.Warning,
        message: 'Only .csv files are accepted.',
      }),
    );
    setLoading(false);
  };

  const onCancel = (): void => {
    dispatch(resetUploadState());
    closeDrawer();
  };

  const handleDrag = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    switch (event.type) {
      case 'dragenter':
        setDragActive(true);
        break;
      case 'dragover':
        setDragActive(true);
        break;
      case 'dragleave':
        setDragActive(false);
        break;
      case 'drop':
        setDragActive(false);
        if (event?.dataTransfer?.files) {
          const fileList = event.dataTransfer.files;
          /*
                if (fileList.length > 1) {
                    alert(
                    'Looks like you have multiple files selected...maybe try again and just choose one this time?',
                    );
                }
          */

          const file = fileList[0];
          void onCsvFile(file);
        }
        break;
      default:
        break;
    }
  };

  const handleFileSystemSelect = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    if (!get(event, 'target.validity', false)) {
      console.error('Upload not valid');
      return;
    }
    const file = get(event, 'target.files[0]', null);
    if (!file) {
      console.error('File not found');
      return;
    }
    void onCsvFile(file);
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const actionButtons: IndividualButtonProps[] = [
    // {
    //   title: loading ? 'Processing upload...' : 'Continue',
    //   onClick: handleSubmit,
    //   disabled: csvContent === null,
    //   id: 'file-upload-action-button-1',
    // },
    {
      title: 'Cancel',
      onClick: onCancel,
      id: 'file-upload-action-button-2',
    },
  ];
  return (
    <Box
      data-testid="upload-file-component"
      sx={{
        ...DRAWER_COLUMN_CONTAINER,
        color: LITE_TEXT_COLOR,
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: `calc(100% - ${theme.spacing(15.5)})`,
        }}
      >
        <StyledMediumLabel variant="h5" sx={{ color: SIDE_PANEL_TITLE_COLOR }}>
          NEW PORTFOLIO
        </StyledMediumLabel>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: `calc(100% - ${theme.spacing(4)})`,
            overflow: 'auto',
          }}
        >
          <Box sx={CenteredColumnStyle}>
            <StyledThinLabel
              variant="h6"
              sx={{
                marginTop: theme.spacing(MUI_SPACER),
                marginBottom: theme.spacing(MUI_SPACER / 2),
                width: '100%',
                textAlign: 'center',
              }}
            >
              Upload Location File (.csv)
            </StyledThinLabel>

            {loading ? (
              <Box
                sx={{
                  ...CenteredBoxStyle,
                  minHeight: theme.spacing(12),
                  border: LITE_DASHED_BORDER,
                  borderRadius: theme.spacing(0.5),
                }}
              >
                <StyledThinLabel variant="h6">Processing upload...</StyledThinLabel>
              </Box>
            ) : (
              <Box
                sx={{
                  ...CenteredBoxStyle,
                  minHeight: theme.spacing(12),
                  border: LITE_DASHED_BORDER,
                  borderRadius: theme.spacing(0.5),
                }}
                onDragEnter={handleDrag}
                data-testid="dropzone"
              >
                {dragActive && (
                  <Box
                    data-testid="dropzone-active"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrag}
                    sx={{
                      background: DROP_ZONE_ACTIVE_COLOR,
                      zIndex: 10,
                      flexGrow: 1,
                      width: '100%',
                      ...CenteredColumnStyle,
                    }}
                  >
                    <Typography variant="h6" sx={{ color: APP_TEXT_COLOR }}>
                      Drop Here!
                    </Typography>
                  </Box>
                )}

                {!dragActive && (
                  <>
                    <PrimaryButton
                      variant="contained"
                      sx={{ textTransform: 'none' }}
                      component="label"
                    >
                      Select a CSV file to Upload
                      <input
                        type="file"
                        accept={ACCEPTED_FILE_EXTENSIONS.join(',')}
                        style={{ display: 'none' }}
                        onChange={handleFileSystemSelect}
                      />
                    </PrimaryButton>
                    <Box sx={{ marginTop: theme.spacing(MUI_SPACER / 4) }}>
                      <StyledThinLabel variant="h6">or drag and drop it here</StyledThinLabel>
                    </Box>

                    {csvContent?.fileMeta?.name && (
                      <Box>
                        <Typography
                          data-cy="upload-validation-file-name-preview"
                          variant="subtitle2"
                          sx={{ fontWeight: 800 }}
                        >
                          {csvContent.fileMeta.name}
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            )}
          </Box>
          <Box
            data-testid="guidelines"
            sx={{
              width: '100%',
              ...ColumnStyle,
              marginTop: theme.spacing(MUI_SPACER),
            }}
          >
            {GUIDELINES_FOR_FILE_UPLOAD.map(({ key, text }, index) =>
              index ? (
                <Typography key={key} variant="h6" sx={{ paddingBottom: theme.spacing(1.25) }}>
                  {index}. {text}
                </Typography>
              ) : (
                <StyledThinLabel key={key} variant="h6" sx={{ paddingBottom: theme.spacing(1.25) }}>
                  {text}
                </StyledThinLabel>
              ),
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          ...ColumnStyle,
          marginBottom: theme.spacing(2),
        }}
      >
        <CategorySelector
          sx={{
            marginTop: theme.spacing(MUI_SPACER / 2),
            marginBottom: theme.spacing(MUI_SPACER / 4),
          }}
        />
        <Box sx={{ marginBottom: theme.spacing(MUI_SPACER) }}>
          <DownloadFilesTrigger
            title="Download input template files"
            downloadConfigs={TEMPLATE_FILE_LINKS}
            TypographyProps={{ variant: 'button' }}
          />
        </Box>
        <SwitchButton type="normal" buttons={actionButtons} />
      </Box>
    </Box>
  );
};

export default UploadFile;
