import { StyleProps, spacing } from '../../utils/styleUtils';
import { MenuArrowSx, MenuOriginProps, MenuOrigins, MenuPositionX, MenuPositionY } from './types';

export const MENU_BOTTOM_RIGHT: MenuOriginProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

export const MENU_BOTTOM_LEFT: MenuOriginProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

export const MENU_TOP_RIGHT: MenuOriginProps = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
};

export const MENU_TOP_LEFT: MenuOriginProps = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
};

export const MENU_ORIGINS: MenuOrigins = {
  [MenuPositionY.BOTTOM]: {
    [MenuPositionX.RIGHT]: MENU_BOTTOM_RIGHT,
    [MenuPositionX.LEFT]: MENU_BOTTOM_LEFT,
  },
  [MenuPositionY.TOP]: {
    [MenuPositionX.RIGHT]: MENU_TOP_RIGHT,
    [MenuPositionX.LEFT]: MENU_TOP_LEFT,
  },
};

export const MENU_BOTTOM_RIGHT_ARROW: StyleProps = {
  left: spacing(1),
  top: 0,
  transform: 'translateY(-50%) rotate(-135deg)',
};

export const MENU_BOTTOM_LEFT_ARROW: StyleProps = {
  right: spacing(1),
  top: 0,
  transform: 'translateY(-50%) rotate(-135deg)',
};

export const MENU_TOP_RIGHT_ARROW: StyleProps = {
  left: spacing(1),
  top: '100%',
  transform: 'translateY(-50%) rotate(45deg)',
};

export const MENU_TOP_LEFT_ARROW: StyleProps = {
  right: spacing(1),
  top: '100%',
  transform: 'translateY(-50%) rotate(45deg)',
};

export const MENU_ARROW_SX: MenuArrowSx = {
  [MenuPositionY.BOTTOM]: {
    [MenuPositionX.RIGHT]: MENU_BOTTOM_RIGHT_ARROW,
    [MenuPositionX.LEFT]: MENU_BOTTOM_LEFT_ARROW,
  },
  [MenuPositionY.TOP]: {
    [MenuPositionX.RIGHT]: MENU_TOP_RIGHT_ARROW,
    [MenuPositionX.LEFT]: MENU_TOP_LEFT_ARROW,
  },
};
