import { Box, Toolbar, Typography } from '@mui/material';
import { FC } from 'react';
import MidDot from '../../../components/MidDot';
import { CenteredRowStyle, GREY_BOX_STYLE, px } from '../../../utils/styleUtils';
import ApplicationBar from './ApplicationBar';
import {
  CONTAINER_STYLE,
  TOP_BAR_HEADER_HEIGHT,
  TOP_BAR_HEIGHT,
  TOP_BAR_NAV_HEADER_HEIGHT,
} from './util';

const TopBarScrim: FC = () => {
  const loggedInImageStyle = {
    height: '24px',
    paddingRight: '5px',
  };

  const isLoggedIn = true;

  return (
    <ApplicationBar>
      <Toolbar
        disableGutters
        sx={{
          background: 'inherit',
          color: 'inherit',
          height: px(TOP_BAR_HEIGHT),
          width: '100%',
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
          }}
        >
          <Box
            sx={{
              ...CONTAINER_STYLE,
              height: isLoggedIn ? TOP_BAR_HEADER_HEIGHT : '100%',
              paddingLeft: 4.5,
              paddingRight: 4.5,
            }}
          >
            <Box
              sx={{
                alignItems: 'flex-start',
                justifyContent: !isLoggedIn ? 'center' : 'space-between',
                maxWidth: '39vw',
              }}
            >
              <Box
                sx={{
                  ...CenteredRowStyle,
                  alignItems: 'center',
                }}
              >
                <img
                  alt="logo"
                  style={{
                    height: '48px',
                    ...(isLoggedIn ? loggedInImageStyle : {}),
                  }}
                  src={`${window._env_.REACT_APP_APP_ROOT}/img/logo-secondary.png`}
                />
                {isLoggedIn && (
                  <Box
                    sx={{
                      display: {
                        xs: 'none',
                        sm: 'flex',
                      },
                      alignItems: 'center',
                    }}
                  >
                    <MidDot />
                    <Typography data-testid="home-header" variant="subtitle1" noWrap>
                      ClimateScore Global
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          {isLoggedIn && (
            <Box
              sx={{
                ...CONTAINER_STYLE,
                ...GREY_BOX_STYLE,
                height: TOP_BAR_NAV_HEADER_HEIGHT,
              }}
            />
          )}
        </Box>
      </Toolbar>
    </ApplicationBar>
  );
};

export default TopBarScrim;
