import { datadogRum } from '@datadog/browser-rum';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { initializeHeap } from './heap';
import App from './components/App';
import TourWrapper from './components/tour/TourWrapper';
import { DATA_DOG_RUM_CONFIG } from './datadog';
import { persistor, store } from './redux/store';
import { Auth0ProviderWithNavigate } from './components/authentication/auth0-provider-with-navigate';
import theme from './theme';
import './fonts/NeueHelveticaProWebfontsKit.css';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement as HTMLElement);
// eslint-disable-next-line no-console
console.log('API Base URL === ', window._env_.REACT_APP_API_ENDPOINT);
// eslint-disable-next-line no-console
console.log('API IO User Base URL === ', window._env_.REACT_APP_API_IO_ENDPOINT);
// eslint-disable-next-line no-console
console.log('BUILD_NUMBER === ', window._env_.REACT_APP_BUILD_NUMBER);
// eslint-disable-next-line no-console
console.log('NODE ENV === ', process.env.NODE_ENV);
// eslint-disable-next-line no-console
console.log('ENVIRONMENT === ', window._env_.REACT_APP_ENVIRONMENT_TYPE);

if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line no-console
  console.log('Initializing Data Dog RUM');
  datadogRum.init(DATA_DOG_RUM_CONFIG);
  // Enable HeapAnalytics only for production and preview env
  if (
    ['production', 'preview'].includes(window._env_.REACT_APP_ENVIRONMENT_TYPE) &&
    window._env_.REACT_APP_HEAP_ANALYTICS_ID
  ) {
    initializeHeap(window._env_.REACT_APP_HEAP_ANALYTICS_ID.trim() ?? '');
  }
}

root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <BrowserRouter>
              <Auth0ProviderWithNavigate>
                <TourWrapper>
                  <App />
                </TourWrapper>
              </Auth0ProviderWithNavigate>
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </StrictMode>,
);

// expose store when run in Cypress
// need redux store to dispatch logout for clean-up
if (window.Cypress) {
  window.store = store;
}
