import 'mapbox-gl/dist/mapbox-gl.css';
import { FC, useEffect, useMemo, useState } from 'react';
import Map, { Marker } from 'react-map-gl';
import withErrorBoundary from '../../components/ErrorBoundary/withErrorBoundary';
import { CoordinateData, LocationData } from './types';

const MAPBOX_TOKEN = window._env_.REACT_APP_MAPBOX_ACCESS_TOKEN;
interface MapPreviewProps {
  // csvFile?: CSVFile | null;
  locationData: LocationData[];
}

export const LOCATION_PREVIEW_LIMIT: number = 100;

const limitedPreview = (inputData: CoordinateData[], limit: number): CoordinateData[] => {
  const numLocations = inputData.length;

  if (numLocations > limit) {
    // eslint-disable-next-line no-console
    console.log(
      `Location limit for preview is ${limit.toString()}. ${
        numLocations - limit
      } locations will not be shown.`,
    );

    return inputData.slice(0, limit - 1);
  }

  return inputData;
};

const MapPreview: FC<MapPreviewProps> = ({ locationData }) => {
  // const { data } = useReadCSV(csvFile);

  const [viewState, setViewState] = useState<{
    zoom?: number;
    latitude?: number;
    longitude?: number;
  }>({
    // starting off blank allows the map to show all points when data is loaded
    zoom: 0,
  });

  const data = useMemo(() => {
    return limitedPreview(locationData, LOCATION_PREVIEW_LIMIT);
  }, [locationData]);

  useEffect(() => {
    if (data.length === 0) return;

    const lastPointAdded = data[data.length - 1];
    const { latitude, longitude } = lastPointAdded;

    if (latitude && longitude) {
      // Center the map on the last point added
      setViewState((prevView) => ({
        ...prevView,
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
      }));
    }
  }, [data]);

  return (
    <Map
      {...viewState}
      onMove={(evt) => setViewState(evt.viewState)}
      style={{ minWidth: '300px', minHeight: '300px' }}
      mapStyle="mapbox://styles/mapbox/light-v11"
      // mapStyle="mapbox://styles/mapbox/streets-v9"
      mapboxAccessToken={MAPBOX_TOKEN}
      onRender={(MapboxEvent) => {
        const MapboxMap = MapboxEvent.target;
        MapboxMap.resize();
      }}
    >
      {data.map(({ latitude, longitude, label = '' }, idx) => {
        return (
          <Marker
            data-cy="map-preview-marker"
            // eslint-disable-next-line react/no-array-index-key
            key={`map-preview-marker-${idx}`}
            longitude={parseFloat(longitude)}
            latitude={parseFloat(latitude)}
          >
            <div
              style={{
                height: '1rem',
                width: '1rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FF0000',
                borderRadius: '100%',
              }}
            >
              {label.length > 0 && (
                <div style={{ fontSize: '0.75rem', color: '#fff' }}>{label}</div>
              )}
            </div>
          </Marker>
        );
      })}
    </Map>
  );
};

export default withErrorBoundary(MapPreview);
