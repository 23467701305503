import { CSSProperties } from 'react';
import { StepType, StylesObj } from '@reactour/tour';
import { PopoverStylesObj } from '@reactour/popover';
import { Warning as WarningIcon } from '@mui/icons-material';
import { Theme } from '@mui/material';
import TourContent from '../components/tour/TourContent';

const oppositeSide: { [key: string]: string } = {
  top: 'bottom',
  bottom: 'top',
  right: 'left',
  left: 'right',
};

export const doArrow = (
  position: string,
  verticalAlign: string,
  horizontalAlign: string,
  color = '#000',
): CSSProperties => {
  if (!position || position === 'custom') {
    return {};
  }

  const width = 16;
  const height = 12;
  const isVertical = position === 'top' || position === 'bottom';
  const spaceFromSide = 10;

  const obj = {
    // Note: String literal for "oppositeSide" object key is work-around
    // for eslint error "security/detect-object-injection"
    [`--rtp-arrow-${isVertical ? oppositeSide[`${horizontalAlign}`] : verticalAlign}`]: `${
      height + spaceFromSide
    }px`,
    [`--rtp-arrow-${oppositeSide[`${position}`]}`]: `${-height + 2}px`,
    [`--rtp-arrow-border-${isVertical ? 'left' : 'top'}`]: `${width / 2}px solid transparent`,
    [`--rtp-arrow-border-${isVertical ? 'right' : 'bottom'}`]: `${width / 2}px solid transparent`,
    [`--rtp-arrow-border-${position}`]: `${height}px solid ${color}`,
  };
  return obj;
};

export const tourStyles = (theme: Theme): StylesObj & PopoverStylesObj => ({
  popover: (base, state) => {
    return {
      ...base,
      color: '#fff',
      backgroundColor: '#000',
      padding: theme.spacing(1),
      ...doArrow(state?.position, state?.verticalAlign, state?.horizontalAlign),
    };
  },
  dot: (base, state) => ({
    ...base,
    background: '#dddddde6',
    backgroundImage: state?.current
      ? 'linear-gradient(180deg, #00000080 0%, #00000040 100%), linear-gradient(116deg, #00000080 5%, #0000001a 100%)'
      : 'none',
    border: `${theme.spacing(1 / 8)} solid ${state?.current ? '#dddddde6' : '#cccccc1a'}`,
    transform: 'none',
    width: theme.spacing(1.25),
    height: theme.spacing(1.25),
    boxShadow: state?.current
      ? `-${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(2)} 0 #0000001a`
      : `inset ${theme.spacing(1 / 8)} ${theme.spacing(1 / 8)} ${theme.spacing(1 / 4)} 0 #66666680`,
  }),
  close: (base) => ({
    ...base,
    color: 'inherit',
    top: theme.spacing(1),
    right: theme.spacing(1),
  }),
});

// Note: this steps are just for trial tour implementation
// TODO: remove when adding actual product tour steps
export const loginTourSteps: StepType[] = [
  {
    selector: '[data-tour="username"]',
    content: () => (
      <TourContent Icon={WarningIcon} title="Login Tour">
        Enter your email here.
      </TourContent>
    ),
  },
  {
    selector: '[data-tour="password"]',
    content: () => <TourContent title="Login Tour">Enter your password here.</TourContent>,
  },
  {
    selector: '[data-tour="login-button"]',
    content: () => <TourContent>Click on login button to sign in.</TourContent>,
  },
  {
    selector: '[data-tour="user-greeting"]',
    content: 'Awesome! You have logged-in successfully.',
  },
];
