import { FC, ComponentProps } from 'react';
import { components } from '@reactour/tour';
import { ArrowRightAlt as ArrowRightAltIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import PrimaryButton from '../buttons/PrimaryButton';

type NavigationProps = ComponentProps<typeof components.Navigation>;
type PrevBtnFnProps = Parameters<Exclude<NavigationProps['prevButton'], undefined>>[0];
type NextBtnFnProps = Parameters<Exclude<NavigationProps['nextButton'], undefined>>[0];

export const PrevButton: FC<PrevBtnFnProps> = ({ currentStep, setCurrentStep, steps }) => {
  const theme = useTheme();
  const isEnabled = currentStep > 0 && steps;
  const onClickPrev = (): void => {
    if (isEnabled) {
      setCurrentStep((s) => s - 1);
    }
  };

  return (
    <PrimaryButton
      size="large"
      data-testid="tour-prev-button"
      onClick={onClickPrev}
      sx={{
        minWidth: 'auto',
        padding: 0,
      }}
      disabled={!isEnabled}
    >
      <ArrowRightAltIcon
        sx={{
          height: theme.spacing(2.5),
          width: theme.spacing(2.5),
          transform: 'rotate(180deg)',
        }}
      />
    </PrimaryButton>
  );
};

export const NextButton: FC<NextBtnFnProps> = ({ currentStep, setCurrentStep, steps }) => {
  const theme = useTheme();
  const isEnabled = steps && currentStep < steps.length - 1;
  const onClickNext = (): void => {
    if (isEnabled) {
      setCurrentStep((s) => s + 1);
    }
  };

  return (
    <PrimaryButton
      size="large"
      data-testid="tour-next-button"
      onClick={onClickNext}
      sx={{
        minWidth: 'auto',
        padding: 0,
      }}
      disabled={!isEnabled}
    >
      <ArrowRightAltIcon sx={{ height: theme.spacing(2.5), width: theme.spacing(2.5) }} />
    </PrimaryButton>
  );
};
