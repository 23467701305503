/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertObjectInput, AlertSeverity } from '../../components/alerts/types';
import { LEGAL_ACKNOWLEDGE_KEY } from '../../pages/main/acknowledgement/util';
import { clearLocalStorage } from '../../utils/browserUtils';
import { logRejected } from './utils';
import { handleLogoutInHeap } from '../../heap/index';
import { UUID } from '../../api/types';
import {
  deleteClientAdapter,
  generateClientAdapter,
  listClientsAdapter,
} from '../../rest/client/adapter';
import { addAlert } from './uiSlice';
import { ClientCredsType, CredType } from '../../types/user';

export const userCanAccessLargeGrid = (user: { tenantName: string }): boolean => {
  const tenantName = user?.tenantName ?? '';

  const tenantConfig = KnownTenants.find(({ name }) => name === tenantName);

  return tenantConfig?.canAccessLargeGrid ?? false;
};

export const userCanAccessKnowledgeBase = (user: { userRole: string }): boolean => {
  const userRole = user?.userRole ?? '';

  // User has a role, and that role is not Trial User
  return userRole.length > 0 && userRole !== 'jupiter-trial-user';
};

interface TenantConfig {
  name: string;
  label: string;
  canAccessLargeGrid: boolean;
}

export const KnownTenants: TenantConfig[] = [
  {
    name: 'jupiter',
    label: 'Jupiter Intelligence',
    canAccessLargeGrid: true,
  },
  {
    name: 'msad',
    label: 'MS&AD',
    canAccessLargeGrid: true,
  },
  {
    name: 'chubb',
    label: 'Chubb',
    canAccessLargeGrid: true,
  },
  {
    name: 'pwcuk',
    label: 'PricewaterhouseCoopers',
    canAccessLargeGrid: false,
  },
];

export interface UserObject {
  username: string;
  userId: UUID;
  email: string;
  tenantName: string;
  tenantDisplayName: string;
  userRole: string;
  customRoles: string[];
}

export interface UserAuthentication {
  isAuthenticated: boolean;
}

export enum AuthStatus {
  IDLE = 'idle',
  SUCCEEDED = 'succeeded',
  LOADING = 'loading',
  FAILED = 'failed',
}

export interface UserState extends UserObject {
  loginStatus: AuthStatus;
  inviteStatus: AuthStatus;
  resetPasswordStatus: AuthStatus;
  alert: AlertObjectInput;
  isLoggedIn: boolean;
  idToken: string;
  accessToken: string;
  clientCreds: ClientCredsType & { status: AuthStatus };
}

const initialState: UserState = {
  username: '',
  userId: '',
  email: '',
  tenantName: '',
  tenantDisplayName: '',
  userRole: '',
  customRoles: [],
  loginStatus: AuthStatus.IDLE,
  inviteStatus: AuthStatus.IDLE,
  resetPasswordStatus: AuthStatus.IDLE,
  alert: {
    open: false,
  },
  isLoggedIn: false,
  idToken: '',
  accessToken: '',
  clientCreds: {
    creds: [],
    status: AuthStatus.IDLE,
  },
};

export const getClients = createAsyncThunk(
  'user/get-clients',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const state: any = getState();
    const userId = state?.user?.userId ?? '';

    if (userId) {
      const queryResponse = await listClientsAdapter(userId);

      const { data: clients, error } = queryResponse;

      if (clients) {
        return clients;
      }

      if (error) {
        dispatch(
          addAlert({
            severity: AlertSeverity.Error,
            open: true,
            message: `Fetching clients failed. ${error.message}.`,
          }),
        );

        return rejectWithValue(error.message);
      }
    } else {
      dispatch(
        addAlert({
          severity: AlertSeverity.Error,
          open: true,
          message: 'User data not available.',
        }),
      );
    }

    return rejectWithValue('Unknown error fetching clients');
  },
);

export const createClient = createAsyncThunk(
  'user/create-client',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const state: any = getState();
    const userId = state?.user?.userId ?? '';

    if (userId) {
      const queryResponse = await generateClientAdapter(userId);

      const { data: client, error } = queryResponse;

      if (client) {
        return client;
      }

      if (error) {
        dispatch(
          addAlert({
            severity: AlertSeverity.Error,
            open: true,
            message: `Creating client failed. ${error.message}.`,
          }),
        );

        return rejectWithValue(error.message);
      }
    } else {
      dispatch(
        addAlert({
          severity: AlertSeverity.Error,
          open: true,
          message: 'User data not available.',
        }),
      );
    }

    return rejectWithValue('Unknown error creating client');
  },
);

export const deleteClient = createAsyncThunk(
  'user/delete-client',
  async ({ clientId }: { clientId: string }, { rejectWithValue, dispatch, getState }) => {
    const state: any = getState();
    const userId = state?.user?.userId ?? '';

    if (userId) {
      const queryResponse = await deleteClientAdapter(userId, clientId);

      const { error } = queryResponse;

      if (error) {
        dispatch(
          addAlert({
            severity: AlertSeverity.Error,
            open: true,
            message: `Deleting client failed. ${error.message}.`,
          }),
        );

        return rejectWithValue(error.message);
      }

      dispatch(
        addAlert({
          severity: AlertSeverity.Success,
          open: true,
          message: 'Client deleted. Refreshing list.',
        }),
      );

      // 1. refresh the list of clients first,
      await dispatch(getClients());

      return clientId;
    }
    // Below is "else" for userId not available
    dispatch(
      addAlert({
        severity: AlertSeverity.Error,
        open: true,
        message: 'User data not available.',
      }),
    );

    return rejectWithValue('Unknown error deleting client');
  },
);

export const userLogout = createAsyncThunk('user/logout', async (_, { rejectWithValue }) => {
  try {
    // await cognitoSignOut(); // sign out of cognito first

    // NOTE: We don't want to lose this one key when we clear local storage
    await clearLocalStorage({ persist: [LEGAL_ACKNOWLEDGE_KEY] }); // then clear local storage
    return true;
  } catch (error) {
    await clearLocalStorage({ persist: [LEGAL_ACKNOWLEDGE_KEY] });
    return rejectWithValue(`Something went wrong: ${JSON.stringify(error)}`);
  } finally {
    handleLogoutInHeap();
  }
});

// `createSlice` creates your actions as well as your reducer for you
export const userSlice = createSlice({
  name: 'user',
  initialState,
  // the `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // `actions`:
    setUserAlert: (state: UserState, action: PayloadAction<AlertObjectInput>) => {
      const alertObject = action.payload;
      state.alert = {
        ...state.alert,
        ...alertObject,
      };
    },
    setUser: (state: UserState, action: PayloadAction<UserObject>) => {
      const { username, userId, email, tenantName, tenantDisplayName, userRole, customRoles } =
        action.payload;
      state.username = username;
      state.userId = userId;
      state.email = email;
      state.tenantName = tenantName;
      state.tenantDisplayName = tenantDisplayName;
      state.userRole = userRole;
      state.customRoles = customRoles;
    },
    setUserAuthentication: (state: UserState, action: PayloadAction<UserAuthentication>) => {
      const { isAuthenticated } = action.payload;
      state.isLoggedIn = isAuthenticated;
    },
    setUserTokens: (
      state: UserState,
      action: PayloadAction<Pick<UserState, 'idToken' | 'accessToken'>>,
    ) => {
      const { idToken, accessToken } = action.payload;
      state.idToken = idToken;
      state.accessToken = accessToken;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(userLogout.pending, () => {
        // eslint-disable-next-line no-console
        console.log('Logging out...');
      })
      .addCase(userLogout.fulfilled, () => {
        // eslint-disable-next-line no-console
        console.log('Logout succeeded (server).');

        // NOTE: Actions with regard to state and localStorage are being handled at the rootReducer, which is why none of these extra reducers are modifying state--only logging to the console (See also `src/redux/store.ts`).
      })
      .addCase(userLogout.rejected, (state, action) => {
        logRejected(action, 'Logout failed (server).');
      })
      .addCase(getClients.pending, (state: UserState) => {
        state.clientCreds.status = AuthStatus.LOADING;
      })
      .addCase(getClients.fulfilled, (state: UserState, action: PayloadAction<ClientCredsType>) => {
        const newClientCreds = action.payload;
        state.clientCreds = { ...newClientCreds, status: AuthStatus.SUCCEEDED };
      })
      .addCase(getClients.rejected, (state: UserState, action) => {
        logRejected(action, 'getClients failed.');

        state.clientCreds = {
          creds: [],
          status: AuthStatus.FAILED,
        };
      })
      .addCase(createClient.pending, (state: UserState) => {
        // createClient pending
      })
      .addCase(createClient.fulfilled, (state: UserState, action: PayloadAction<CredType>) => {
        const newClientCred = action.payload;
        const { clientCreds } = state;
        clientCreds.creds.push(newClientCred);
        state.clientCreds = clientCreds;
      })
      .addCase(createClient.rejected, (state: UserState, action) => {
        logRejected(action, 'createClient failed.');
      });
  },
});

// export the `actions` defined above
export const { setUser, setUserAlert, setUserAuthentication, setUserTokens } = userSlice.actions;

export default userSlice.reducer;
