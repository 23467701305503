import { Box, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import SwitchButton, { IndividualButtonProps } from '../buttons/SwitchButton';
import Modal from '../Modal';
import { ConfirmationModalProps } from './types';
import { ACTIVE_BUTTON_STYLE, StyleProps } from '../../utils/styleUtils';
import { StyledMediumLabel, StyledThinLabel } from '../labels/StyledLabel';

interface IndividualButtonWithStyleProps extends IndividualButtonProps {
  sx?: StyleProps;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  open,
  onConfirm,
  onCancel,
  title,
  body,
  confirmButtonText = 'Yes',
  cancelButtonText = 'No',
  isConfirmButtonLoading = false,
  onLoad = null,
}) => {
  const theme = useTheme();

  useEffect(() => {
    onLoad?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionButtons: IndividualButtonWithStyleProps[] = [
    {
      title: confirmButtonText,
      onClick: onConfirm,
      id: 'confirmation-modal-confirm-button',
      buttonSx: ACTIVE_BUTTON_STYLE,
      loading: isConfirmButtonLoading,
    },
    {
      title: cancelButtonText,
      onClick: onCancel,
      id: 'confirmation-modal-cancel-button',
      disabled: isConfirmButtonLoading,
    },
  ];

  return (
    <Modal
      data-testid="confirmation-modal"
      open={open}
      onClose={() => {
        // NOTE: User can't close it manually
      }}
    >
      <Box sx={{ marginBottom: theme.spacing(1.5) }}>
        <StyledMediumLabel variant="h6" noWrap>
          {title}
        </StyledMediumLabel>
      </Box>
      <StyledThinLabel variant="h6">{body}</StyledThinLabel>
      <Box
        sx={{
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(1),
        }}
      >
        <SwitchButton type="normal" buttons={actionButtons} />
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
