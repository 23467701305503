import { APIResponse, DELETE, GET, POST, PUT } from './http';
import { UUID } from '../api/types';
import { DEF_LIST_API_LIMIT } from './constants';

interface APIObject {
  id: UUID;
}

export async function list<T extends APIObject>(
  path: string,
  params: Record<string, any> = {},
): Promise<APIResponse<T[]>> {
  const parameters: Record<string, any> = {
    limit: DEF_LIST_API_LIMIT, // in case "params" does not override limit parameter
    ...params,
  };

  let hasMore = true;

  const items: T[] = [];

  /* eslint-disable no-await-in-loop */
  while (hasMore) {
    // The API's pagination follows Stripe's pagination model: https://stripe.com/docs/api/pagination
    // The "data" field contains the actual items, and the "has_more" field indicates whether there are more items.
    // In this case, the data variable is set to the API's response, so we need to access data.data and data.has_more.
    // While this is apparently redundant, the other API calls do not have items nested under the data field.
    const { data, error } = await GET(path, parameters);

    if (error) {
      return { data: null, error };
    }

    items.push(...data.data);
    hasMore = data.has_more;
    parameters.starting_after = items[items.length - 1].id;
  }
  /* eslint-enable no-await-in-loop */

  return {
    data: items,
    error: null,
  };
}

export async function get<T extends APIObject>(path: string, id: UUID): Promise<APIResponse<T>> {
  return await GET(`${path}/${id}`);
}

export async function fetch<T extends APIObject>(
  path: string,
  queryParams: Record<string, any> = {},
): Promise<APIResponse<T>> {
  return await GET(path, queryParams);
}

export async function create<T extends APIObject>(
  path: string,
  fields: object,
): Promise<APIResponse<T>> {
  return await POST(path, fields);
}

export async function generate<T extends APIObject>(path: string): Promise<APIResponse<T>> {
  return await POST(path);
}

export async function update<T extends APIObject>(
  path: string,
  id: UUID,
  fields: object,
): Promise<APIResponse<T>> {
  return await PUT(`${path}/${id}`, fields);
}

export async function del(path: string, id: UUID): Promise<APIResponse<null>> {
  return await DELETE(`${path}/${id}`);
}
