export type CryptoUUID = string;

export interface HashUserIdentifiersType {
  username: string;
  email: string;
}

export const reactKey = (prefix: string = ''): CryptoUUID => {
  // console.log('reactKey called!');
  return `${prefix}${crypto.randomUUID()}`;
};

/**
 * @see // https://stackoverflow.com/a/43383990
 * @return {string}
 */
export const getHash = async (identifier: string, algorithm = 'SHA-256'): Promise<string> => {
  const strBuf: Uint8Array = new TextEncoder().encode(identifier);
  return await crypto.subtle.digest(algorithm, strBuf).then((hash) => {
    let result = '';
    const view = new DataView(hash);
    for (let i = 0; i < hash.byteLength; i += 4) {
      result += `00000000${view.getUint32(i).toString(16)}`.slice(-8);
    }
    return result;
  });
};
