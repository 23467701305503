import {
  Button,
  ButtonProps,
  TypographyProps as MuiTypographyProps,
  Typography,
} from '@mui/material';
import { FC } from 'react';

export interface TextUnderlineButtonProps extends ButtonProps {
  uppercase?: boolean;
  TypographyProps?: MuiTypographyProps;
  'data-testid'?: string;
}

const TextUnderlineButton: FC<TextUnderlineButtonProps> = ({
  children,
  uppercase = false,
  TypographyProps = {},
  sx,
  ...rest
}) => {
  const elements = Array.isArray(children) ? children.filter(Boolean) : [children].filter(Boolean);

  return (
    <Button
      color="inherit"
      variant="text"
      sx={{
        padding: 0,
        '&:disabled': {
          color: 'inherit',
          opacity: 0.5,
        },
        ...sx,
      }}
      {...rest}
    >
      <Typography
        color="inherit"
        display="inline"
        variant="subtitle2"
        {...TypographyProps}
        sx={{ textDecoration: 'underline', ...(TypographyProps.sx ?? {}) }}
      >
        {/* NOTE: uppercase will only work if there is only ONE child element */}
        {elements.length === 1 && typeof elements[0] === 'string' && uppercase
          ? elements[0].toUpperCase()
          : elements}
      </Typography>
    </Button>
  );
};

export default TextUnderlineButton;
