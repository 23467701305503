import { ParsedCSVFileMeta } from '../../utils/csvUtils';

export interface CSVFile extends File {
  lastModified: number;
  lastModifiedDate?: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

export interface CoordinateData {
  latitude: string; // "33.7447"
  longitude: string; // "44.6436"
  label?: string;
}

export interface LocationData extends CoordinateData {
  locationId: string; // "1368444902"
}

interface CSVContentMeta extends CSVFile, ParsedCSVFileMeta {}

interface ValidLocation {
  locationId: string;
  longitude: string;
  latitude: string;
}

interface ValidationReadout {
  failedCount: number;
  succeededCount: number;
  totalCount: number;
  errors: ValidationMessage[] | null;
  locations: ValidLocation[];
}

export interface CSVContent {
  json: any[];
  fileMeta: CSVContentMeta;
}

export enum UploadModes {
  CSV_FILE = 'UPLOAD_CSV_FILE',
  COORDINATE_COMPOSITION = 'UPLOAD_COORDINATES',
}

export enum ValidationStatusUI {
  IDLE = 'Idle',
  // The following values are all possible statuses in the Jupiter API
  LOADING = 'Running',
  SUCCEEDED = 'Succeeded',
  FAILED = 'Failed',
  UNKNOWN = 'Unknown',
}
export interface ValidationMessage {
  /*
      code: "E303"
      description:
        "The point's longitude or latitude is out of range"
      location: "row[1]"
      locationId: ""
      longitude: ""
      latitude: ""
  */
  code: string; // "E303"
  description: string; // "The point's longitude or latitude is out of range"
  location: string; // "row[1]"
  locationId: string; // ""
  longitude: string; // ""
  latitude: string; // ""
}

export interface UploadRequest {
  /*

      created_at: "2022-12-30T20:47:21.496658+00:00"
      extra: {total: 3, failed: 0, messages: Array(0), succeeded: 3}
      id: "f4b982ee-c041-46a5-bd8b-280e328382d1"
      name: "CSV_3_cities"
      status: "Succeeded"
      updated_at: "2022-12-30T20:47:21.536093+00:00"

  */

  created_at: string;
  extra: ValidationReadout;
  id: string;
  name: string;
  status: string;
  updated_at: string;
}

export enum ValidationStatus {
  UNSPECIFIED = 'UNSPECIFIED',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  PARTIALLY_SUCCEEDED = 'PARTIALLY_SUCCEEDED',
}

export interface ValidationResults {
  extra: ValidationReadout;
  id: string;
  name: string;
  status: ValidationStatus;
  categoryId?: string | null;
}

export interface ValidationState {
  active: boolean;
  status: ValidationStatusUI;
  results: ValidationResults | null;
}
