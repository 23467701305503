import { get } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { ResultSet, ResultSetStatus } from '../../../api/resultSets/types';
import { AnchorElement, ContextMenuOptionProps } from '../../../components/ContextMenu/types';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectDownloadsById } from '../../../redux/selectors/csgDataSelectors';
import {
  DataStatus,
  queueToDeletedResultSet,
  setResultSet,
} from '../../../redux/slices/csgDataSlice';
import { setDownloadDrawer } from '../../../redux/slices/uiSlice';
import { captureDebugLog } from '../../../utils/debugUtils';
import { dataItemIsLoading } from '../../download/utils';
import ResultSetCard from './ResultSetCard';
import { EditResultSetInput, ResultSetCardContainerProps, ResultSetEditState } from './types';
import { updateResultSetAdapter } from '../../../rest/resultSet/adapter';

const ResultSetCardContainer: FC<ResultSetCardContainerProps> = ({
  sx,
  sxSupplement,
  title,
  type,
  selected,
  statusIcon,
  cardContent,
  resultSet,
  handleInfoClick,
  handleSelect,
  isMockData,
  isDeletionDisabled = false,
}) => {
  const dispatch = useAppDispatch();
  const downloadsById = useAppSelector(selectDownloadsById);

  // TODO: Finalize Card Norms; make a reusable model from this component + PortfolioCard + ToolKitCard

  // IDEA: To improve perceived speed, change state of card locally -- I.E.: OnClick >> Change Style

  const handleRequestDownload = async ({
    id: resultSetId,
    status: resultSetStatus,
  }: ResultSet): Promise<void> => {
    dispatch(
      // make sure download drawer does not persist complete/partial information from previous launch
      setDownloadDrawer({
        open: true,
        portfolioId: '',
        portfolioName: '',
        resultSetName: '',
        resultSetId,
        resultSetStatus,
      }),
    );
  };
  const isDownloading = useMemo(() => {
    const dataItem = get(downloadsById, resultSet.id, { status: DataStatus.IDLE });
    return dataItemIsLoading(dataItem);
  }, [downloadsById, resultSet.id]);

  const hasError = useMemo(() => {
    const dataItem = get(downloadsById, resultSet.id, { error: '' });
    return dataItem.error.length > 0;
  }, [downloadsById, resultSet.id]);

  const [anchorEl, setAnchorEl] = useState<AnchorElement>(null);
  const [isDeleteResultSetModalOpen, setDeleteResultSetModalOpen] = useState<boolean>(false);
  const [deleteResultSetInProgress, setDeleteResultSetInProgress] = useState<boolean>(false);

  const downloadAllowed = useMemo(() => {
    const dataItem = get(downloadsById, resultSet.id, { status: DataStatus.IDLE });
    if (dataItemIsLoading(dataItem)) {
      return false;
    }

    return resultSet.status === ResultSetStatus.COMPLETED;
  }, [resultSet.id, resultSet.status, downloadsById]);

  const [editNameState, setEditName] = useState<ResultSetEditState>({
    name: '',
    error: null,
    open: false,
    status: DataStatus.IDLE,
  });

  const setEditState = (action: ResultSetEditState): void => {
    setEditName((prevState) => ({
      ...prevState,
      ...action,
    }));
  };

  const renamePortfolio = async (variables: EditResultSetInput): Promise<void> => {
    await setEditState({
      error: null,
      status: DataStatus.LOADING,
    });

    const mutationResponse = await updateResultSetAdapter(
      variables.resultSetId,
      variables.resultSetName,
    );

    const { data, error } = mutationResponse;

    if (data) {
      await dispatch(
        setResultSet({
          ...resultSet,
          name: variables.resultSetName,
        }),
      );

      await setEditState({
        open: false,
        status: DataStatus.SUCCEEDED,
      });
      return;
    }

    if (error) {
      await setEditState({
        error: error.message,
        status: DataStatus.FAILED,
      });
    }
  };
  const onRenameConfirmed = useCallback(
    (newName: string): void => {
      if (editNameState && resultSet?.id) {
        void renamePortfolio({
          resultSetId: resultSet.id,
          resultSetName: newName,
        });
        return;
      }

      console.error('No resultSet selected');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editNameState, resultSet],
  );

  const contextMenuOptions: ContextMenuOptionProps[] = [
    {
      title: 'Rename',
      disabled: isMockData,
      onClick: () => {
        setEditState({
          name: resultSet.name,
          error: '',
          open: true,
          status: DataStatus.IDLE,
        });
        // close menu
        setAnchorEl(null);
      },
      id: 'result_set-context-rename',
    },
    {
      title: isDownloading ? 'Downloading' : 'Download',
      onClick: () => {
        if (!isDownloading) {
          void handleRequestDownload(resultSet);
          // close menu
          setAnchorEl(null);
        }
      },
      disabled: !downloadAllowed,
      id: isDownloading ? 'result_set-context-downloading' : 'result_set-context-download',
    },
    {
      title: 'Copy Debug Log',
      onClick: () => {
        void captureDebugLog(resultSet, 'Result Set Data');
      },
      id: 'result_set-context-copy-debug-log',
    },
    {
      title: deleteResultSetInProgress ? 'Deleting' : 'Delete',
      disabled: isDeletionDisabled,
      onClick: () => setDeleteResultSetModalOpen(true),
      id: deleteResultSetInProgress ? 'result_set-context-deleting' : 'result_set-context-delete',
      inProgress: deleteResultSetInProgress,
    },
  ];

  const onConfirmDelete = async (): Promise<void> => {
    try {
      setDeleteResultSetInProgress(true);
      await dispatch(
        queueToDeletedResultSet({
          resultSetId: resultSet.id,
        }),
      );
      setDeleteResultSetModalOpen(false);
    } catch {}

    setDeleteResultSetInProgress(false);
  };

  return (
    <>
      <ResultSetCard
        sx={sx}
        sxSupplement={sxSupplement}
        title={title}
        type={type}
        selected={selected}
        statusIcon={statusIcon}
        cardContent={cardContent}
        handleInfoClick={handleInfoClick}
        handleSelect={handleSelect}
        isMockData={isMockData}
        // Edit props
        editNameState={editNameState}
        onRenameConfirmed={onRenameConfirmed}
        setEditState={setEditState}
        // Context menu
        contextMenuOptions={contextMenuOptions}
      />
      <ConfirmationModal
        open={isDeleteResultSetModalOpen}
        title="Delete Result set"
        body="You are requesting deletion of this Result Set. Once deleted, it will no longer be available in your account. Are you sure you want to continue?"
        onCancel={() => setDeleteResultSetModalOpen(false)}
        onConfirm={() => {
          void onConfirmDelete();
        }}
        confirmButtonText="Yes"
        cancelButtonText="No"
        isConfirmButtonLoading={deleteResultSetInProgress}
      />
    </>
  );
};

export default ResultSetCardContainer;
