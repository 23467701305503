import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Box, IconButton, InputAdornment } from '@mui/material';
import { useFormik } from 'formik';
import { FC, KeyboardEvent } from 'react';
import * as yup from 'yup';
import ColorInheritedTextField from './ColorInheritedTextField';
import Tooltip from './tooltip/Tooltip';

interface RenameDialogProps {
  loading?: boolean;
  error?: string | null;
  currentName: string;
  inputLabel?: string;
  cancelChanges: () => void;
  submitChanges: (newName: string) => void;
}

// eslint-disable-next-line no-useless-escape
const ALLOWED_SYMBOLS_REGEX = /^[A-Za-z0-9_\-\&\+\!\(\)\*\'\. ]+$/;

// eslint-disable-next-line no-useless-escape
export const MOCK_DATA_TITLE_REGEX = /csg mock data/gi; // NOTE: `i` means case-insensitive

export const MOCK_DATA_IDENTIFIER = 'CSG MOCK DATA';

const validationSchema = yup.object({
  newName: yup
    .string()
    .trim()
    .required('Name cannot be empty')
    .test(
      'not-mock-data-title',
      'CSG Mock Data is a restricted value.  Please use another name',
      (value) => {
        if (!value) return true;
        return value.match(MOCK_DATA_TITLE_REGEX) === null;
      },
    )
    .test(
      'no-illegal-characters',
      "Name can have only letters (a-z, A-Z), numbers (0-9), and some symbols (&, +, !, (, ), *, ', .)",
      (value) => {
        if (!value) return true;
        return value.match(ALLOWED_SYMBOLS_REGEX) !== null;
      },
    ),
});

const RenameField: FC<RenameDialogProps> = ({
  loading = false,
  error = null,
  currentName,
  cancelChanges,
  submitChanges,
  inputLabel,
}) => {
  const formik = useFormik({
    initialValues: {
      newName: currentName,
    },
    validationSchema,
    onSubmit: (values) => {
      const { newName } = values;
      // Removing extra spaces from the string
      submitChanges(newName.replace(/\s+/g, ' ').trim());
    },
    // NOTE: setErrors may be helpful if validationSchema cannot accomplish test.
    // onSubmit: (values, { setErrors }) => {
    //   const { newName } = values;
    //   if (checkIfStringIsSubset({ string: newName, subString: MOCK_DATA_IDENTIFIER })) {
    //     setErrors({
    //       ...formik.errors,
    //       newName: `${newName} is a restricted value.  Please use another name.`,
    //     });
    //   } else {
    //     submitChanges(values.newName);
    //   }
    // },
  });

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{ width: '100%', height: '100%', margin: '0px', padding: '0px', background: 'inherit' }}
    >
      <ColorInheritedTextField
        hiddenLabel
        name="newName"
        fullWidth
        value={formik.values.newName}
        onChange={formik.handleChange}
        error={formik.touched.newName && Boolean(formik.errors.newName)}
        helperText={formik.touched.newName && formik.errors.newName}
        InputProps={{
          onKeyUp: (e: KeyboardEvent): void => {
            if (e.key === 'Escape') {
              cancelChanges();
            }
          },
          sx: { background: 'inherit!important' },
          endAdornment: (
            <>
              {error && (
                <InputAdornment position="end">
                  <Tooltip title={`Something went wrong: ${JSON.stringify(error)}`}>
                    <ReportProblemIcon />
                  </Tooltip>
                </InputAdornment>
              )}
              <InputAdornment position="end">
                <IconButton
                  data-testid="rename-submit"
                  aria-label="submit rename request"
                  type="submit"
                  disabled={loading}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  onMouseDown={() => {
                    //
                  }}
                  edge="end"
                >
                  {loading ? <HourglassBottomIcon /> : <CheckIcon />}
                </IconButton>
              </InputAdornment>
              <InputAdornment position="end">
                <IconButton aria-label="cancel rename request" onClick={cancelChanges} edge="end">
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
        variant="filled"
        size="small"
        errorHelperDataTestId="rename-portfolio-result-set-error"
      />
    </Box>
  );
};

export default RenameField;
