import { Typography } from '@mui/material';
import { isNil, lowerCase } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { Portfolio } from '../../api/portfolios/types';
import withErrorBoundary from '../../components/ErrorBoundary/withErrorBoundary';
import { LabelWeight } from '../../components/buttons/DropdownHandle';
import TextDropdown from '../../components/dropdowns/TextDropdown';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectNumberOfPortfolios,
  selectPortfolios,
  selectResultSetCountByPortfolioId,
  selectSelectedPortfolio,
} from '../../redux/selectors/csgDataSelectors';
import { selectPortfolio } from '../../redux/slices/csgDataSlice';
import { NAV_SEPARATOR_COLOR } from '../../utils/styleUtils';
import BreadcrumbDropdownMenu, {
  ButtonRefEl,
  ClickAnchorHandler,
  ClickButtonHandler,
  ElementId,
} from './BreadcrumbDropdownMenu';

// IDEA: Use Iterator as the container here, similar to PortfolioStripe

interface EnhancedPortfolio extends Portfolio {
  selected: boolean;
  resultSetCount: number;
  title: string;
}

const PortfolioNavigation: FC = () => {
  const dispatch = useAppDispatch();
  const portfolios = useAppSelector(selectPortfolios);
  const selectedPortfolio = useAppSelector(selectSelectedPortfolio);
  const countPortfolios = useAppSelector(selectNumberOfPortfolios);
  const resultSetCountByPortfolioId = useAppSelector(selectResultSetCountByPortfolioId);
  const [searchString, setSearchString] = useState<string>('');

  const [anchorEl, setAnchorEl] = useState<ButtonRefEl>(null);

  const isMenuOpen = Boolean(anchorEl);
  const elementId: ElementId = isMenuOpen ? 'nav-portfolio-menu' : undefined;

  const onClickMenu: ClickAnchorHandler = (element) => {
    if (!isNil(element)) {
      setAnchorEl(element);
    }
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    setSearchString('');
  };

  const onCloseMenu: ClickButtonHandler = (event) => {
    event.stopPropagation();
    handleClose();
  };

  const enhancedPortfolios: EnhancedPortfolio[] = useMemo(() => {
    return portfolios.map((item) => {
      return {
        ...item,
        selected: !!selectedPortfolio && item.id === selectedPortfolio.id,
        resultSetCount: resultSetCountByPortfolioId[`${item.id}`] ?? 0,
        title: item.name,
      };
    });
  }, [resultSetCountByPortfolioId, portfolios, selectedPortfolio]);

  const filteredPortfolios: EnhancedPortfolio[] = useMemo(() => {
    if ((searchString ?? '').trim().length > 0) {
      return enhancedPortfolios.filter((item) =>
        lowerCase(item.name).includes(lowerCase(searchString)),
      );
    }
    return enhancedPortfolios;
  }, [searchString, enhancedPortfolios]);

  const handleSelectPortfolio = (item: EnhancedPortfolio): void => {
    dispatch(selectPortfolio(item));
    handleClose();
  };

  const breadcrumbListItems = useMemo(() => {
    return filteredPortfolios.map((item) => {
      const { id } = item;

      return {
        ...item,
        onClick: () => {
          handleSelectPortfolio(item);
        },
        id: `pf-nav-item-${id}`,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredPortfolios]);

  return (
    <>
      <Typography variant="h6" sx={{ paddingRight: 0.5 }} color={NAV_SEPARATOR_COLOR}>
        /
      </Typography>
      <TextDropdown
        data-testid="portfolio-breadcrumb-button"
        label={selectedPortfolio?.name ?? 'Select Portfolio'}
        isMenuOpen={isMenuOpen}
        menu={
          <BreadcrumbDropdownMenu
            elementId={elementId}
            isMenuOpen={isMenuOpen}
            anchorEl={anchorEl}
            onCloseMenu={onCloseMenu}
            menuDataTestId="nav-portfolio-menu"
            listItems={breadcrumbListItems}
            listDataTestId="filtered-portfolios-list"
            emptyMenuItemText="No portfolios available"
            withSearch
            searchString={searchString}
            setSearchString={setSearchString}
            searchInputDataTestId="portfolio-nav-search"
          />
        }
        onClickMenu={onClickMenu}
        iconButtonProps={{
          disabled: countPortfolios === 0,
        }}
        labelProps={{
          labelWeight: LabelWeight.NORMAL,
        }}
      />
    </>
  );
};

export default withErrorBoundary(PortfolioNavigation);
