import { APIStatus } from '../rest/types';
import {
  APIDamagesResultSetOptions,
  APIFloodDefenseOptions,
  APILossAPIDefaults,
  APIMeshOptions,
  APIMeshResultSetOptions,
  APIPerilsResultSetOptions,
  APIPortfolioFinancialResultSetOptions,
  APIResultSet,
  APIResultSetOptions,
  APIResultSetStatistics,
  APIResultSetType,
  APIScoresResultSetOptions,
} from '../rest/resultSet/types';
import {
  BenchmarkingLevel,
  DamageAndLossDefaults,
  FloodDefenseParametersInput,
  GridSize,
  GridType,
  MeshParametersInput,
  Peril,
  ResultSet,
  ResultSetMetadata,
  ResultSetParametersInput,
  ResultSetStatus,
  ResultSetType,
  Scenario,
  ScorePeril,
} from '../api/resultSets/types';
import { convertUnixToUTCString } from '../utils/datetime';
import { convertKeysToCamelCase } from '../utils/objectUtils';

function convertType(resultSetType: APIResultSetType): ResultSetType {
  switch (resultSetType) {
    case 'damages':
      return ResultSetType.ECONOMIC_IMPACTS;
    case 'mesh':
      return ResultSetType.FLOOD_MESH;
    case 'perils':
      return ResultSetType.PERILS;
    case 'scores':
      return ResultSetType.SCORES;
    case 'portfolio_financial':
      return ResultSetType.PORTFOLIO_FINANCIAL;
    default:
      throw new Error('Unknown result set type');
  }
}

function convertStatistics(statistics: APIResultSetStatistics): ResultSetMetadata {
  return {
    numberOfLocations: statistics.number_of_locations,
    numberOfRows: statistics.number_of_rows,
  };
}

function convertScoresOptions(options: APIScoresResultSetOptions): ResultSetParametersInput {
  return {
    benchmarking: {
      enabled: options.include_benchmarks,
      levels: options.benchmark_levels?.map((level) => level.toUpperCase()) as BenchmarkingLevel[],
    },
    csgVersion: options.data_version,
    scores: options.perils as ScorePeril[],
  };
}

function convertFloodDefense(
  floodDefenseOptions: APIFloodDefenseOptions,
): FloodDefenseParametersInput {
  return {
    enabled: floodDefenseOptions.enabled,
    zeroOutDefendedLocations: floodDefenseOptions.zero_out_defended_locations,
  };
}

function convertPerilsOptions(options: APIPerilsResultSetOptions): ResultSetParametersInput {
  return {
    csgVersion: options.data_version as string,
    floodDefense: options.flood_defense ? convertFloodDefense(options.flood_defense) : null,
    perils: options?.perils as Peril[],
    scenarios: options?.scenarios as Scenario[],
    years: options?.years as number[],
  };
}

function snakeToCamel(s: string): string {
  return s.replace(/(_\w)/g, (m) => m[1].toUpperCase());
}

function convertDefaults(defaults: APILossAPIDefaults): DamageAndLossDefaults {
  const fireEnums = new Set(['vent_type', 'roof_cover', 'window_pane']);
  return Object.fromEntries(
    Object.entries(defaults).map(([key, value]) => [
      snakeToCamel(key),
      fireEnums.has(key) && typeof value === 'string' ? value.toUpperCase() : value,
    ]),
  );
}

function convertEIOptions(options: APIDamagesResultSetOptions): ResultSetParametersInput {
  return {
    csgVersion: options.perils_options?.data_version as string,
    damageAndLoss: {
      enabled: true,
      defaultFields: options.defaults ? convertDefaults(options.defaults) : undefined,
    },
    eiModules: options.ei_modules?.map((module) => module.toUpperCase()) as string[],
    eiVersion: options.ei_version,
    floodDefense: options.perils_options?.flood_defense
      ? convertFloodDefense(options.perils_options?.flood_defense)
      : undefined,
    perils: options.perils_options?.perils as Peril[],
    scenarios: options.perils_options?.scenarios as Scenario[],
    years: options.perils_options?.years as number[],
  };
}

function convertMesh(options: APIMeshOptions): MeshParametersInput {
  switch (options.type) {
    case 'dynamic':
      return {
        gridType: GridType.DYNAMIC,
        gridSize: GridSize.DYNAMIC,
      };
    case 'fixed':
      return {
        gridType: GridType.REGULAR,
        gridSize: GridSize.FIFTEEN,
        x: 15,
        y: 15,
      };
    default:
      throw new Error('Unknown mesh grid type.');
  }
}

function convertMeshOptions(options: APIMeshResultSetOptions): ResultSetParametersInput {
  return {
    csgVersion: options.data_version as string,
    floodDefense: options?.flood_defense ? convertFloodDefense(options?.flood_defense) : undefined,
    mesh: options.mesh ? convertMesh(options.mesh) : undefined,
    perils: options.perils as Peril[],
    scenarios: options.scenarios as Scenario[],
    years: options.years as number[],
  };
}

function convertPortfolioFinancialOptions(
  options: APIPortfolioFinancialResultSetOptions,
): ResultSetParametersInput {
  return convertEIOptions({ ...options.damages_options, type: 'damages' });
}

function convertOptions(options: APIResultSetOptions): ResultSetParametersInput {
  switch (options.type) {
    case 'damages':
      return convertEIOptions(options);
    case 'mesh':
      return convertMeshOptions(options);
    case 'perils':
      return convertPerilsOptions(options);
    case 'scores':
      return convertScoresOptions(options);
    case 'portfolio_financial':
      return convertPortfolioFinancialOptions(options);
    default:
      throw new Error('Unknown result set type.');
  }
}

function convertStatus(status: APIStatus): ResultSetStatus {
  switch (status) {
    case APIStatus.completed:
      return ResultSetStatus.COMPLETED;
    case APIStatus.failed:
      return ResultSetStatus.FAILED;
    case APIStatus.pending:
      return ResultSetStatus.PENDING;
    default:
      throw new Error('Unknown status');
  }
}

export function convertResultSet(resultSet: APIResultSet): ResultSet {
  return {
    metadata: resultSet.statistics
      ? convertStatistics(resultSet.statistics)
      : {
          numberOfLocations: 0,
          numberOfRows: 0,
        },
    name: resultSet.name,
    parameters: convertOptions(resultSet.options),
    portfolioId: resultSet.portfolio,
    status: convertStatus(resultSet.status),
    type: convertType(resultSet.options.type),
    updatedAt: convertUnixToUTCString(resultSet.updated_at),
    userId: resultSet.created_by,
    id: resultSet.id,
    createdAt: convertUnixToUTCString(resultSet.created_at),
  };
}

export function convertResultSetDefaultParams(params: Record<string, any>): Record<string, any> {
  return convertKeysToCamelCase(params);
}

export function convertResultSetEIVersion(params: Record<string, any>): Record<string, any> {
  const { ei_version: eiVersion } = params;
  return convertKeysToCamelCase({ eiVersion });
}

export function convertResultSetDataAndEIVersion(params: Record<string, any>): Record<string, any> {
  const { csg_version: csgVersion, ei_version: eiVersion } = params;
  return convertKeysToCamelCase({ csgVersion, eiVersion });
}
