import { RESULT_NAME_LABELS } from '../constants';
import { ValidationSectionTypeUI } from './types';

export const mapSectionTypeToLabel = (section: ValidationSectionTypeUI): string => {
  switch (section) {
    case ValidationSectionTypeUI.ECONOMIC_IMPACTS:
      return RESULT_NAME_LABELS.ECONOMIC_IMPACTS;
    case ValidationSectionTypeUI.FLOOD_MESH:
      return RESULT_NAME_LABELS.FLOOD_MESH;
    case ValidationSectionTypeUI.PERILS:
      return RESULT_NAME_LABELS.PERILS;
    case ValidationSectionTypeUI.SCORES:
      return RESULT_NAME_LABELS.SCORES;
    case ValidationSectionTypeUI.GLOBAL:
      return 'Global';
    default:
      break;
  }

  return 'Other';
};
