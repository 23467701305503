import { UUID } from '../../api/types';
import { CreatePortfolioInput, FileContentInput } from '../../api/upload/types';
import { Portfolio, UpdatePortfolioInput } from '../../api/portfolios/types';
import {
  convertFileValidationWithLocations,
  convertPortfolio,
} from '../../restUtils/restToGqlForPortfolio';
import {
  convertPortfolioFileInput,
  convertPortfolioInput,
  convertPortfolioUpdateInput,
} from '../../restUtils/gqlToRestForPortfolio';
import { APIResponse } from '../http';
import {
  APIFile,
  APIFileDownloadLink,
  APIFilePurpose,
  APIFileValidationLocn,
  APIFileValidationStatus,
} from '../file/types';
import {
  listPortfolios,
  createPortfolio,
  updatePortfolio,
  deletePortfolio,
  getPortfolio,
} from './portfolio';
import {
  createFile,
  createFileDownloadLink,
  createFileValidation,
  getFileValidation,
  getFileValidationLocations,
} from '../file/file';
import { APIStatus, DownloadResponse } from '../types';
import { createExport, getExport } from '../export/export';
import { APIExportFileType } from '../export/types';
import { ValidationResults } from '../../pages/upload/types';

interface FileContentCSVInput extends FileContentInput {
  csv: File;
}

export async function listPortfoliosAdapter(): Promise<APIResponse<Portfolio[]>> {
  const { data, error } = await listPortfolios();
  if (error) {
    return { data: null, error };
  }
  return { data: data?.map(convertPortfolio), error: null };
}

export async function createPortfolioAdapter(
  input: CreatePortfolioInput,
): Promise<APIResponse<Portfolio>> {
  const { data, error } = await createPortfolio(convertPortfolioInput(input));
  if (error) {
    return { data: null, error };
  }
  let apiPortfolio = data;
  /* eslint-disable no-await-in-loop */
  while (apiPortfolio.status === APIStatus.pending) {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 5000));
    const apiResponse = await getPortfolio(apiPortfolio.id);
    if (apiResponse.error) {
      return { data: null, error: apiResponse.error };
    }
    apiPortfolio = apiResponse.data;
  }

  if (apiPortfolio.status === APIStatus.failed) {
    return { data: null, error: new Error('Create portfolio failed.') };
  }
  return { data: convertPortfolio(data), error: null };
}

export async function updatePortfolioAdapter(
  id: UUID,
  updateInput: Omit<UpdatePortfolioInput, 'id'>,
): Promise<APIResponse<Portfolio>> {
  const { data, error } = await updatePortfolio(id, convertPortfolioUpdateInput(updateInput));
  if (error) {
    return { data: null, error };
  }
  return { data: convertPortfolio(data), error: null };
}

export async function deletePortfolioAdapter(id: UUID): Promise<APIResponse<null>> {
  const { data, error } = await deletePortfolio(id);
  if (error) {
    return { data: null, error };
  }
  return { data, error };
}

export async function getFileValidationLocationsAdapter(
  id: UUID,
): Promise<APIResponse<APIFileValidationLocn>> {
  const { data, error } = await getFileValidationLocations(id);
  if (error) {
    return { data: null, error };
  }
  return { data, error: null };
}

export async function createPortfolioFileAdapter({
  csv,
  ...input
}: FileContentCSVInput): Promise<APIResponse<APIFile>> {
  const { data, error } = await createFile(convertPortfolioFileInput(input, csv));
  if (error) {
    return { data: null, error };
  }
  return { data, error: null };
}

export async function createPortfolioFileValidationAdapter(
  input: FileContentCSVInput,
): Promise<APIResponse<ValidationResults>> {
  const { data: fileData, error: fileError } = await createPortfolioFileAdapter(input);
  if (fileError) {
    return { data: null, error: fileError };
  }

  const { data: dataFV, error: errorFV } = await createFileValidation({ file: fileData.id });
  if (errorFV) {
    return { data: null, error: errorFV };
  }

  let apiFileValidation = dataFV;
  /* eslint-disable no-await-in-loop */
  while (apiFileValidation.status === APIFileValidationStatus.pending) {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 5000));
    const apiResponse = await getFileValidation(apiFileValidation.id);
    if (apiResponse.error) {
      return { data: null, error: apiResponse.error };
    }
    apiFileValidation = apiResponse.data;
  }

  const { data: apiFileValidationLocn, error } = await getFileValidationLocationsAdapter(
    apiFileValidation.id,
  );
  if (error) {
    return { data: null, error };
  }

  return {
    data: convertFileValidationWithLocations(apiFileValidation, apiFileValidationLocn, input.name),
    error: null,
  };
}

export async function getFileValidationAdapter(
  id: UUID,
  name: string,
): Promise<APIResponse<ValidationResults>> {
  const { data, error: errorFV } = await getFileValidation(id);
  if (errorFV) {
    return { data: null, error: errorFV };
  }

  let apiFileValidation = data;
  /* eslint-disable no-await-in-loop */
  while (apiFileValidation.status === APIFileValidationStatus.pending) {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 5000));
    const apiResponse = await getFileValidation(apiFileValidation.id);
    if (apiResponse.error) {
      return { data: null, error: apiResponse.error };
    }
    apiFileValidation = apiResponse.data;
  }

  const { data: apiFileValidationLocn, error } = await getFileValidationLocationsAdapter(
    apiFileValidation.id,
  );
  if (error) {
    return { data: null, error };
  }

  return {
    data: convertFileValidationWithLocations(apiFileValidation, apiFileValidationLocn, name),
    error: null,
  };
}

export async function getPortfolioDownloadUrlsAdapter(
  portfolioId: UUID,
  purpose: APIFilePurpose,
): Promise<APIResponse<DownloadResponse>> {
  const { data, error } = await createExport({
    // compression: APIExportCompression.none,
    file_type: APIExportFileType.csv,
    object_id: portfolioId,
    options: {
      object_type: 'portfolio',
    },
    // output_style: APIOutputStyle.standard,
  });
  if (error) {
    return { data: null, error };
  }
  let apiExport = data;
  /* eslint-disable no-await-in-loop */
  while (apiExport.status === APIStatus.pending) {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 5000));
    const apiResponse = await getExport(apiExport.id);
    if (apiResponse.error) {
      return { data: null, error: apiResponse.error };
    }
    apiExport = apiResponse.data;
  }

  if (apiExport.status === APIStatus.failed) {
    return { data: null, error: new Error('Export failed.') };
  }

  const { files } = apiExport;
  const filteredFiles = files.filter((file) => file.purpose === purpose);

  const responses = await Promise.all(
    filteredFiles.map(async (file) => await createFileDownloadLink(file.id, { expires_in: 3600 })),
  );
  const errResponse = responses.find((response) => response.error);
  // If there is at least one error
  if (errResponse) {
    return { data: null, error: errResponse.error as Error };
  }
  // There are zero errors
  return {
    data: {
      download: {
        urls: responses.map((link) => (link.data as APIFileDownloadLink).url),
      },
    },
    error: null,
  };
}
