/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get, isNil } from 'lodash';
import { Category, Portfolio } from '../../api/portfolios/types';
import { DownloadFormat, ParameterOption, ResultSet } from '../../api/resultSets/types';
import { AlertSeverity } from '../../components/alerts/types';
import { exportAllFileAsync, exportFileAsync } from '../../utils/csvUtils';
import { timeNowUtc } from '../../utils/datetime';
import { addAlert } from './uiSlice';
import { setSLRError } from './slrSlice';
import {
  appendToDeletedResultSetIDs,
  deletedResultSetIDs,
  logRejected,
  setDeletedResultSetIDs,
} from './utils';
import { DOWNLOAD_USAGE_REPORT_ID } from '../../pages/main/SettingsDrawer/constants';
import { UsageStatsType } from '../../pages/main/SettingsDrawer/types';
import {
  deleteResultSetAdapter,
  getResultSetDataVersionAdapter,
  getResultSetDownloadUrlsAdapter,
  getResultSetEIVersionAdapter,
  listResultSetsAdapter,
} from '../../rest/resultSet/adapter';
import { createSLRAdapter, getSLRDownloadUrlsAdapter } from '../../rest/slr/adapter';
import { APISLR, CreateSLRInput, SLRScenarioType, SLRTemplateType } from '../../rest/slr/types';
import { APIResponse } from '../../rest/http';
import { constructCreateSLRPayload } from '../../rest/slr/payloadBuilder';
import { listCategoriesAdapter } from '../../rest/category/adapter';
import {
  deletePortfolioAdapter,
  getPortfolioDownloadUrlsAdapter,
  listPortfoliosAdapter,
} from '../../rest/portfolio/adapter';
import { APIFilePurpose } from '../../rest/file/types';
import {
  getUsageStatsAdapter,
  getUsageTrackingDownloadUrlsAdapter,
} from '../../rest/usage/adapter';

export enum DownloadFormatLabelUI {
  STANDARD = 'Standard CSV',
  TABLEAU = 'Enhanced CSV',
}

const truthyString = (item: any): boolean =>
  typeof item === 'string' && (item ?? '').trim().length > 0;

export const refreshCategoriesList = createAsyncThunk(
  'data/fetch-categories',
  async (_, { dispatch, rejectWithValue }) => {
    const queryResponse = await listCategoriesAdapter();

    const { data, error } = queryResponse;

    if (data) {
      const categories = data;

      return categories;
    }

    if (error) {
      dispatch(
        addAlert({
          severity: AlertSeverity.Error,
          open: true,
          message: `Failed to fetch categories: ${error.message}.`,
        }),
      );
      return rejectWithValue(error.message);
    }

    return rejectWithValue('Unknown error refreshing categories');
  },
);

interface RefreshingPortfolioListInput {
  portfolioId?: string;
}

export const refreshPortfoliosList = createAsyncThunk(
  'data/fetch-portfolios',
  async (
    { portfolioId: selectThisPortfolioIdFromList = '' }: RefreshingPortfolioListInput,
    { getState, dispatch, rejectWithValue },
  ) => {
    const queryResponse = await listPortfoliosAdapter();

    const { data: portfolios, error } = queryResponse;

    if (portfolios) {
      if (selectThisPortfolioIdFromList.length > 0) {
        // If we know the portfolio we want to select next, select that one
        const portfolioToBeSelected =
          portfolios.find(({ id }) => id === selectThisPortfolioIdFromList) ?? null;

        if (portfolioToBeSelected) {
          dispatch(selectPortfolio(portfolioToBeSelected));
        } else {
          console.error('portfolioToBeSelected was falsy');
        }
      } else {
        // If we didn't specify the portfolio to select next, see if there are no portfolios selected already
        try {
          const state: any = getState();
          const selectedPortfolio = state?.csgData?.selectedPortfolio ?? null;
          const firstPortfolio = portfolios[0] ?? null;

          // If there are no portfolios selected (and there is at least one portfolio in the list), then select the top portfolio so there will always be at least one portfolio selected!
          if (!selectedPortfolio && firstPortfolio) {
            dispatch(selectPortfolio(firstPortfolio));
          }
        } catch (err) {
          console.error('Error setting default portfolio selected:', err);
        }
      }
      return portfolios;
    }

    if (error) {
      dispatch(
        addAlert({
          severity: AlertSeverity.Error,
          open: true,
          message: `Failed to fetch portfolios. ${error.message}.`,
        }),
      );
      return rejectWithValue(error.message);
    }

    return rejectWithValue('Unknown error refreshing portfolios');
  },
);

export const refreshResultSetsList = createAsyncThunk(
  'data/fetch-result-sets',
  async (_, { getState, rejectWithValue, dispatch }) => {
    const queryResponse = await listResultSetsAdapter();

    const { data, error } = queryResponse;

    if (data) {
      dispatch(updateDeletedResultSetsQueue(data));

      try {
        const state: any = getState();
        const listDeletedResultSetIDs = state?.csgData?.deletedResultSetIDs ?? [];

        return data.filter((item: ResultSet) => !listDeletedResultSetIDs.includes(item.id));
      } catch (err) {
        console.error('Error filtering deleted result-sets:', err);
      }

      return data;
    }

    if (error) {
      dispatch(
        addAlert({
          severity: AlertSeverity.Error,
          open: true,
          message: `Failed to fetch result sets. ${error.message}.`,
        }),
      );
      return rejectWithValue(error.message);
    }

    return rejectWithValue('Unknown error refreshing portfolios');
  },
);

export const downloadUsageReport = createAsyncThunk(
  'data/download-usage-report-csv',
  async (_, { rejectWithValue, dispatch }) => {
    const mutationResponse = await getUsageTrackingDownloadUrlsAdapter();

    const { data, error } = mutationResponse;

    if (data) {
      const { download } = data;

      try {
        // filter falsy
        const downloadUrls: string[] = (download.urls ?? []).filter(truthyString);

        if (downloadUrls.length === 0) {
          dispatch(
            addAlert({
              severity: AlertSeverity.Error,
              open: true,
              message: 'Usage report download failed (file not found)',
            }),
          );

          return rejectWithValue(new Error('Empty download.urls'));
        }

        await Promise.all(
          downloadUrls.map(async (url: string, idx: number): Promise<void> => {
            await exportFileAsync({ url });
          }),
        );
      } catch (err) {
        console.error('Err trying to download usage report from urls:', { mutationResponse, err });
      }

      return download.urls;
    }

    if (error) {
      dispatch(
        addAlert({
          severity: AlertSeverity.Error,
          open: true,
          message: `Download usage report failed. ${error.message}.`,
        }),
      );

      return rejectWithValue(error.message);
    }

    return rejectWithValue('Unknown error downloading usage report');
  },
);

export const getUsageStats = createAsyncThunk(
  'data/get-usage-stats',
  async (_, { rejectWithValue, dispatch }) => {
    const queryResponse = await getUsageStatsAdapter();

    const { data: usageStatistics, error } = queryResponse;

    if (usageStatistics) {
      return usageStatistics;
    }

    if (error) {
      dispatch(
        addAlert({
          severity: AlertSeverity.Error,
          open: true,
          message: `Fetching usage stats failed. ${error.message}.`,
        }),
      );

      return rejectWithValue(error.message);
    }

    return rejectWithValue('Unknown error fetching usage stats');
  },
);

interface DownloadPortfolioProps {
  portfolioId: string;
  portfolioName: string;
}

export const downloadPortfolio = createAsyncThunk(
  'data/download-portfolio-csv',
  async ({ portfolioId, portfolioName }: DownloadPortfolioProps, { rejectWithValue, dispatch }) => {
    const mutationResponse = await getPortfolioDownloadUrlsAdapter(
      portfolioId,
      APIFilePurpose.portfolio_export,
    );

    const { data, error } = mutationResponse;

    if (data) {
      const { download } = data;

      try {
        // filter falsy
        const downloadUrls: string[] = (download.urls ?? []).filter(truthyString);

        if (downloadUrls.length === 0) {
          dispatch(
            addAlert({
              severity: AlertSeverity.Error,
              open: true,
              message: 'Download portfolio failed (file not found)',
            }),
          );

          return rejectWithValue(new Error('Empty download.urls'));
        }

        await Promise.all(
          downloadUrls.map(async (url: string, idx: number): Promise<void> => {
            await exportFileAsync({ url });
          }),
        );
      } catch (err) {
        console.error('Err trying to download all portfolio from urls:', { mutationResponse, err });
      }

      return download.urls;
    }

    if (error) {
      dispatch(
        addAlert({
          severity: AlertSeverity.Error,
          open: true,
          message: `Download portfolio failed. ${error.message}.`,
        }),
      );

      return rejectWithValue(error.message);
    }

    return rejectWithValue('Unknown error downloading portfolio');
  },
);

const createSLR = async ({
  templateJSON,
  locationId,
  perilsResultSetId,
  perilsEIResultSetId,
  scoresResultSetId,
}: CreateSLRInput): Promise<APIResponse<APISLR>> => {
  const mutationResponse = await createSLRAdapter({
    templateJSON,
    perilsResultSetId,
    perilsEIResultSetId,
    scoresResultSetId,
    locationId,
  });
  const { data, error } = mutationResponse;

  if (data) {
    return { data, error: null };
  }

  if (error) {
    return { data: null, error };
  }

  return { data: null, error: new Error('Unknown error generating single-location report') };
};

interface DownloadSLRProps {
  template: SLRTemplateType;
  scenario: SLRScenarioType;
  locationId?: string;
}

export const downloadSLR = createAsyncThunk(
  'data/download-slr-docx',
  async (
    { template, scenario, locationId }: DownloadSLRProps,
    { getState, rejectWithValue, dispatch },
  ) => {
    const state = getState();
    const slrResponse = await createSLR(
      constructCreateSLRPayload(template, scenario, state, locationId),
    );

    const { data: slrData, error: slrError } = slrResponse;
    if (slrData) {
      const { id } = slrData;
      const mutationResponse = await getSLRDownloadUrlsAdapter(id);

      const { data, error } = mutationResponse;

      if (data) {
        const { download } = data;

        try {
          // filter falsy
          const downloadUrls: string[] = (download.urls ?? []).filter(truthyString);

          if (downloadUrls.length === 0) {
            dispatch(
              addAlert({
                severity: AlertSeverity.Error,
                open: true,
                message: 'Download single-location report failed (file not found)',
              }),
            );

            return rejectWithValue(new Error('Empty download.urls'));
          }

          await exportAllFileAsync(downloadUrls.map((url) => ({ url })));
          dispatch(
            addAlert({
              severity: AlertSeverity.Success,
              open: true,
              message: 'Downloaded single-location report successfully.',
            }),
          );
        } catch (err) {
          console.error('Err trying to download single-location report from urls:', {
            mutationResponse,
            err,
          });
        }

        return download.urls;
      }

      if (error) {
        dispatch(setSLRError(error.message || 'Something went wrong while downloading report.'));

        dispatch(
          addAlert({
            severity: AlertSeverity.Error,
            open: true,
            message: 'Download single-location report failed.',
          }),
        );

        return rejectWithValue(error.message);
      }
    }

    if (slrError) {
      dispatch(setSLRError(slrError.message || 'Something went wrong while creating report.'));

      dispatch(
        addAlert({
          severity: AlertSeverity.Error,
          open: true,
          message: 'Creating single-location report failed.',
        }),
      );

      return rejectWithValue(slrError.message);
    }

    return rejectWithValue('Unknown error downloading single-location report');
  },
);

export const downloadGeocodeLogReport = createAsyncThunk(
  'data/download-geo-code-log',
  async (portfolioId: string, { rejectWithValue, dispatch }) => {
    const mutationResponse = await getPortfolioDownloadUrlsAdapter(
      portfolioId,
      APIFilePurpose.geocode_log,
    );

    const { data, error } = mutationResponse;

    if (data) {
      const { download } = data;

      try {
        // filter falsy
        const downloadUrls: string[] = (download.urls ?? []).filter(truthyString);

        if (downloadUrls.length === 0) {
          dispatch(
            addAlert({
              severity: AlertSeverity.Error,
              open: true,
              message: 'Geocoding Log file download failed (file not found)',
            }),
          );

          return rejectWithValue(new Error('Empty download.urls'));
        }

        await Promise.all(
          downloadUrls.map(async (url: string, idx: number): Promise<void> => {
            await exportFileAsync({ url });
          }),
        );
      } catch (err) {
        console.error('Err trying to download Geocoding Log file from url:', {
          mutationResponse,
          err,
        });
      }

      return download.urls;
    }

    if (error) {
      dispatch(
        addAlert({
          severity: AlertSeverity.Error,
          open: true,
          message: `Download Geocode Log failed. ${error.message}.`,
        }),
      );

      return rejectWithValue(error.message);
    }

    return rejectWithValue('Unknown error downloading Geocoding Log file');
  },
);

export interface DownloadResultSetProps {
  downloadFormat: DownloadFormat;
  resultSetId: string;
  resultSetName?: string;
}
export const downloadResultSet = createAsyncThunk(
  'data/download-result-set-csv',
  async (
    { downloadFormat, resultSetId, resultSetName = '' }: DownloadResultSetProps,
    { rejectWithValue, dispatch },
  ) => {
    const mutationResponse = await getResultSetDownloadUrlsAdapter(resultSetId, downloadFormat);

    const { data, error } = mutationResponse;

    if (data) {
      const { download } = data;

      try {
        // filter falsy
        const downloadUrls: string[] = (download.urls ?? []).filter(truthyString);

        if (downloadUrls.length === 0) {
          dispatch(
            addAlert({
              severity: AlertSeverity.Error,
              open: true,
              message: 'Download result set failed (file not found)',
            }),
          );

          return rejectWithValue(new Error('Empty download.urls'));
        }

        await Promise.all(
          downloadUrls.map(async (url: string, idx: number): Promise<void> => {
            await exportFileAsync({ url });
          }),
        );
      } catch (err) {
        console.error('Err trying to download result sets from urls:', { mutationResponse, err });
      }

      return download.urls;
    }

    if (error) {
      dispatch(
        addAlert({
          severity: AlertSeverity.Error,
          open: true,
          message: `Download result set failed. ${error.message}.`,
        }),
      );

      return rejectWithValue(error.message);
    }

    return rejectWithValue('Unknown error downloading result sets');
  },
);

export const getSelectedDataVersionName = (state: any): string => {
  try {
    return state.csgData.csgDataVersions.find((item: any) => item.selected).name;
  } catch {}

  return '';
};

export const getSelectedEIVersionName = (state: any): string => {
  try {
    return state.csgData.eiVersions.find((item: any) => item.selected).name;
  } catch {}

  return '';
};

export const getDataVersion = createAsyncThunk(
  'data/csg-version',
  async (_, { rejectWithValue, dispatch }) => {
    const queryResponse = await getResultSetDataVersionAdapter();

    const { data: getParameters, error } = queryResponse;

    if (error) {
      dispatch(
        addAlert({
          severity: AlertSeverity.Error,
          open: true,
          message: `Failed to fetch CSG Data Version. ${error.message}.`,
        }),
      );

      return rejectWithValue(error.message);
    }

    if (!isNil(getParameters)) {
      // EXAMPLE:
      // return {
      //   choices: ['2.6.2', '3.0.0'],
      //   defaults: ['2.6.2'],
      // };

      const { csgVersion, eiVersion } = getParameters;
      return { csgVersion, eiVersion };
    }

    // if `getParameters` is null/undefined, csgVersion & eiVersion are not present in response
    const errorMessage = 'Failed to fetch CSG Data Version and EI Version.';
    dispatch(
      addAlert({
        severity: AlertSeverity.Error,
        open: true,
        message: errorMessage,
      }),
    );

    return rejectWithValue('Unknown error downloading fetching CSG data version');
  },
);

export const getEIVersion = createAsyncThunk(
  'data/ei-version',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const state: any = getState();
    const csgDataVersion = getSelectedDataVersionName(state);
    const queryResponse = await getResultSetEIVersionAdapter(csgDataVersion);

    const { data: getParameters, error } = queryResponse;

    if (error) {
      dispatch(
        addAlert({
          severity: AlertSeverity.Error,
          open: true,
          message: `Failed to fetch EI Version. ${error.message}.`,
        }),
      );

      return rejectWithValue(error.message);
    }

    if (!isNil(getParameters)) {
      return getParameters;
    }

    // if `getParameters` is null/undefined, eiVersion are not present in response
    const errorMessage = 'Failed to fetch EI Version.';
    dispatch(
      addAlert({
        severity: AlertSeverity.Error,
        open: true,
        message: errorMessage,
      }),
    );

    return rejectWithValue('Unknown error downloading fetching EI version');
  },
);

export const deletePortfolio = createAsyncThunk(
  'data/delete-portfolio',
  async ({ portfolioId }: { portfolioId: string }, { rejectWithValue, dispatch }) => {
    const mutationResponse = await deletePortfolioAdapter(portfolioId);

    const { error } = mutationResponse;

    if (error) {
      dispatch(
        addAlert({
          severity: AlertSeverity.Error,
          open: true,
          message: `Failed to delete portfolio. ${error.message}.`,
        }),
      );

      return rejectWithValue(error.message);
    }

    // eslint-disable-next-line no-console
    console.log('Portfolio deleted. Refreshing list.');
    dispatch(
      addAlert({
        severity: AlertSeverity.Success,
        open: true,
        message: 'Portfolio deleted. Refreshing list.',
      }),
    );

    // success

    // 1. remove the portfolio from redux
    await dispatch(removePortfolio(portfolioId));

    // 2. refresh the list of portfolios first,
    await dispatch(refreshPortfoliosList({}));

    // 3. then refresh the list of rs
    await dispatch(refreshResultSetsList());

    return portfolioId;
  },
);

export const queueToDeletedResultSet = createAsyncThunk(
  'data/queue-delete-result-set',
  async ({ resultSetId }: { resultSetId: string }, { rejectWithValue, dispatch }) => {
    if (resultSetId) {
      dispatch(removeResultSet(resultSetId));
      dispatch(addToDeletedResultSetsQueue(resultSetId));
      void dispatch(
        deleteResultSetSilently({
          resultSetId,
        }),
      );
      // eslint-disable-next-line no-console
      console.log('Added Result set to deleted list.');
      dispatch(
        addAlert({
          severity: AlertSeverity.Success,
          open: true,
          message: 'Result set deleted.',
        }),
      );

      return resultSetId;
    }

    return rejectWithValue('Unknown error adding to deleted result set list');
  },
);

export const deleteResultSetSilently = createAsyncThunk(
  'data/delete-result-set-silently',
  async ({ resultSetId }: { resultSetId: string }, { rejectWithValue, dispatch }) => {
    const mutationResponse = await deleteResultSetAdapter(resultSetId);
    const { error } = mutationResponse;

    if (error) {
      return rejectWithValue(error.message);
    }
    // REST API delete result set does not return data, check for an error instead.
    // 1. refresh the list of rs
    await dispatch(refreshResultSetsList());

    // eslint-disable-next-line no-console
    console.log('Deleting Result Set silently.', resultSetId);

    return null;
  },
);

export const deleteResultSet = createAsyncThunk(
  'data/delete-result-set',
  async ({ resultSetId }: { resultSetId: string }, { rejectWithValue, dispatch }) => {
    const mutationResponse = await deleteResultSetAdapter(resultSetId);

    const { error } = mutationResponse;

    if (error) {
      dispatch(
        addAlert({
          severity: AlertSeverity.Error,
          open: true,
          message: `Failed to delete result set. ${error.message}.`,
        }),
      );

      return rejectWithValue(error.message);
    }

    // eslint-disable-next-line no-console
    console.log('Result set deleted. Refreshing list.');
    dispatch(
      addAlert({
        severity: AlertSeverity.Success,
        open: true,
        message: 'Result set deleted. Refreshing list.',
      }),
    );

    // success

    // 1. remove the rs from redux
    await dispatch(removeResultSet(resultSetId));

    // 2. refresh the list of rs
    await dispatch(refreshResultSetsList());

    return resultSetId;
  },
);

export const buildResultSetCountByPortfolioId = (
  resultSets: ResultSet[],
): ResultSetCountByPortfolioId => {
  const newCountDict = resultSets.reduce((acc: ResultSetCountByPortfolioId, rs: ResultSet) => {
    const { portfolioId: pfId } = rs;

    const countItem: ResultSetCountItem = acc[`${pfId}`];

    if (countItem === undefined) {
      return {
        ...acc,
        [`${pfId}`]: 1,
      };
    }

    return {
      ...acc,
      [`${pfId}`]: countItem + 1,
    };
  }, {});

  return newCountDict;
};

type ResultSetCountItem = number | undefined;

export enum DataStatus {
  IDLE = 'idle',
  SUCCEEDED = 'succeeded',
  LOADING = 'loading',
  FAILED = 'failed',
}

export enum DownloadSource {
  RESULT_SET = 'RESULT_SET',
  PORTFOLIO = 'PORTFOLIO',
  USAGE_REPORT = 'USAGE_REPORT',
}

export interface DownloadState {
  id: string;
  source: DownloadSource;
  status: DataStatus;
  error: string;
  created: string; // timestamp
  updated: string; // timestamp
}

export interface DownloadsById {
  [id: string]: DownloadState;
}

export interface ResultSetCountByPortfolioId {
  [portfolioId: string]: ResultSetCountItem;
}

export interface VersionOption {
  id: string;
  name: string;
  title: string;
  selected: boolean;
  selectedByDefault: boolean;
}

export interface UsageStats {
  stats: UsageStatsType | null;
  status: DataStatus;
}

export interface CSGDataState {
  csgDataVersions: VersionOption[];
  eiVersions: VersionOption[];
  csgDataVersionStatus: DataStatus;
  eiVersionStatus: DataStatus;
  portfolios: Portfolio[];
  portfoliosStatus: DataStatus;
  resultSets: ResultSet[];
  resultSetsStatus: DataStatus;
  deletedResultSetIDs: string[];
  selectedPortfolio: Portfolio | null;
  selectedResultSet: ResultSet | null;
  selectedPortfolioForSLR: Portfolio | null;
  selectedResultSetForSLR: ResultSet | null;
  downloadsById: DownloadsById;
  downloadGeoCodingLogsById: DownloadsById;
  categoriesStatus: DataStatus;
  categories: Category[];
  usageStats: UsageStats;
}

const initialState: CSGDataState = {
  csgDataVersions: [],
  eiVersions: [],
  csgDataVersionStatus: DataStatus.IDLE,
  eiVersionStatus: DataStatus.IDLE,
  portfolios: [],
  portfoliosStatus: DataStatus.IDLE,
  resultSets: [],
  resultSetsStatus: DataStatus.IDLE,
  deletedResultSetIDs: [],
  selectedPortfolio: null,
  selectedResultSet: null,
  selectedPortfolioForSLR: null,
  selectedResultSetForSLR: null,
  downloadsById: {},
  downloadGeoCodingLogsById: {},
  categories: [],
  categoriesStatus: DataStatus.IDLE,
  usageStats: {
    stats: null,
    status: DataStatus.IDLE,
  },
};

export const csgDataSlice = createSlice({
  name: 'csgData',
  initialState,
  // the `reducers` field lets us define reducers and generate associated actions
  reducers: {
    selectCsgDataVersion: (state: CSGDataState, action: PayloadAction<VersionOption>) => {
      const nextVersion = action.payload;

      state.csgDataVersions = state.csgDataVersions.map((v) => ({
        ...v,
        selected: v.id === nextVersion.id,
      }));
    },
    resetCsgDataVersionToDefault: (state: CSGDataState) => {
      state.csgDataVersions = state.csgDataVersions.map((v) => ({
        ...v,
        selected: v.selectedByDefault,
      }));
    },
    selectCsgEIVersion: (state: CSGDataState, action: PayloadAction<VersionOption>) => {
      const nextVersion = action.payload;

      state.eiVersions = state.eiVersions.map((v) => ({
        ...v,
        selected: v.id === nextVersion.id,
      }));
    },
    resetCsgEIVersionToDefault: (state: CSGDataState) => {
      state.eiVersions = state.eiVersions.map((v) => ({
        ...v,
        selected: v.selectedByDefault,
      }));
    },
    addResultSets: (state: CSGDataState, action: PayloadAction<ResultSet[]>) => {
      const newResultSets = action.payload;
      state.resultSets = [...newResultSets, ...state.resultSets]; // add new result sets to the front of the list
    },
    updateDeletedResultSetsQueue: (state: CSGDataState, action: PayloadAction<ResultSet[]>) => {
      const allResultSets = action.payload;
      const allResultSetIDs = allResultSets.map((resultSet) => resultSet.id);
      const oldDeletedResultSetIDs = deletedResultSetIDs();
      const newDeletedResultSetIDs =
        oldDeletedResultSetIDs && oldDeletedResultSetIDs.length > 0
          ? oldDeletedResultSetIDs.filter((resultSetID) => allResultSetIDs.includes(resultSetID))
          : [];
      setDeletedResultSetIDs(newDeletedResultSetIDs); // Update deleted result-set queue in local-storage
      state.deletedResultSetIDs = newDeletedResultSetIDs; // Update deleted result-set queue in redux
    },
    addToDeletedResultSetsQueue: (state: CSGDataState, action: PayloadAction<string>) => {
      const idOfDeletedResultSet = action.payload;

      // add to deleted result-set queue in local-storage
      appendToDeletedResultSetIDs(idOfDeletedResultSet);
      // add to deleted result-set queue in redux
      state.deletedResultSetIDs = [...state.deletedResultSetIDs, idOfDeletedResultSet];
      // filter list for newly queued result-set for deletion
      const updatedResultSets = state.resultSets.filter((item) => item.id !== idOfDeletedResultSet);
      state.resultSets = updatedResultSets;

      const selectedPortfolioId = state?.selectedPortfolio?.id ?? '';
      // select next result-set on the top of the list, for the selected portfolio
      const topRS =
        updatedResultSets.find(
          ({ portfolioId }: { portfolioId: string }) => portfolioId === selectedPortfolioId,
        ) ?? null;
      state.selectedResultSet = topRS;
    },
    removePortfolio: (state: CSGDataState, action: PayloadAction<string>) => {
      const idToRemove = action.payload;

      if (idToRemove) {
        // 1. remove selectedPortfolio if match
        if (idToRemove === state.selectedPortfolio?.id) {
          state.selectedPortfolio = null;
        }

        // 2. remove from portfolio list
        // NOTE:, RETURN TO THIS: This causes an issue interacting with `refreshPortfolioList`
        // state.portfolios = state.portfolios.filter(({ id }) => id === idToRemove);
      }
    },
    removeResultSet: (state: CSGDataState, action: PayloadAction<string>) => {
      const idToRemove = action.payload;

      if (idToRemove) {
        // 1. remove selectedResultSet if match
        if (idToRemove === state.selectedResultSet?.id) {
          state.selectedResultSet = null;
        }

        // 2. remove from rs list
        // NOTE:, RETURN TO THIS: This causes an issue interacting with `refreshResultSetsList`
        // state.resultSets = state.resultSets.filter(({ id }) => id === idToRemove);
      }
    },
    setPortfolio: (state: CSGDataState, action: PayloadAction<Portfolio>) => {
      const newPortfolio = action.payload;
      state.portfolios.forEach((current, idx) => {
        if (newPortfolio.id === current.id) {
          state.portfolios[`${idx}`] = newPortfolio;
        }
        if (state.selectedPortfolio?.id === newPortfolio.id) {
          state.selectedPortfolio = newPortfolio;
        }
      });
    },
    setResultSet: (state: CSGDataState, action: PayloadAction<ResultSet>) => {
      const newResultSet = action.payload;
      state.resultSets.forEach((current, idx) => {
        if (newResultSet.id === current.id) {
          state.resultSets[`${idx}`] = newResultSet;
        }
        if (state.selectedResultSet?.id === newResultSet.id) {
          state.selectedResultSet = newResultSet;
        }
      });
    },
    selectPortfolio: (state: CSGDataState, action: PayloadAction<Portfolio>) => {
      const nextSelectedPortfolio = action.payload;
      state.selectedPortfolio = nextSelectedPortfolio;

      const nextSelectedResultSet = state.resultSets.find(
        ({ portfolioId }) => portfolioId === nextSelectedPortfolio.id,
      );

      // nextSelectedPortfolio has at least one result set; select the first one
      if (nextSelectedResultSet) {
        state.selectedResultSet = nextSelectedResultSet;
        return;
      }

      // If nextSelectedPortfolio has no result sets, deselect result set
      state.selectedResultSet = null;
    },
    selectResultSet: (state: CSGDataState, action: PayloadAction<ResultSet>) => {
      state.selectedResultSet = action.payload;
    },
    selectResultSetForSLR: (state: CSGDataState, action: PayloadAction<ResultSet | null>) => {
      const nextSelectedResultSet = action.payload;

      if (nextSelectedResultSet) {
        const nextSelectedPortfolio = state.portfolios.find(
          ({ id }) => id === nextSelectedResultSet.portfolioId,
        );

        if (nextSelectedPortfolio) {
          state.selectedPortfolioForSLR = nextSelectedPortfolio;
          state.selectedResultSetForSLR = nextSelectedResultSet;
          return;
        }
      }
      state.selectedPortfolioForSLR = null;
      state.selectedResultSetForSLR = null;
    },
    deselectResultSetForSLR: (state: CSGDataState) => {
      state.selectedPortfolioForSLR = null;
      state.selectedResultSetForSLR = null;
    },
    setResultSetDownloads: (
      state: CSGDataState,
      action: PayloadAction<{ resultSetId: string; status?: DataStatus; error?: string }>,
    ) => {
      const { resultSetId, status, error } = action.payload;

      if (status !== undefined) {
        state.downloadsById[`${resultSetId}`].status = status;
      }
      if (error !== undefined) {
        state.downloadsById[`${resultSetId}`].error = error;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshCategoriesList.pending, (state) => {
        state.categoriesStatus = DataStatus.IDLE;
      })
      .addCase(refreshCategoriesList.fulfilled, (state: CSGDataState, action) => {
        if (action.payload !== null) {
          state.categories = action.payload;
        }

        state.categoriesStatus = DataStatus.SUCCEEDED;
      })
      .addCase(refreshCategoriesList.rejected, (state, action) => {
        logRejected(action, 'refreshCategoriesList failed');
        state.categoriesStatus = DataStatus.FAILED;
      })
      .addCase(refreshPortfoliosList.pending, (state) => {
        state.portfoliosStatus = DataStatus.LOADING;
      })
      .addCase(refreshPortfoliosList.fulfilled, (state: CSGDataState, action) => {
        if (action.payload !== null) {
          const newPortfolioList = action.payload;
          const categoryList = state.categories;
          state.portfolios = newPortfolioList.map((portfolio) => {
            const category =
              categoryList.find((item) => item.id === portfolio.category?.id) ?? null;
            return { ...portfolio, category };
          });

          const selectedPortfolioId = state.selectedPortfolio?.id ?? '';

          // update selected portfolio
          if (selectedPortfolioId.length > 0) {
            const updatedPortfolio =
              newPortfolioList.find(({ id }: { id: string }) => id === selectedPortfolioId) ?? null;
            state.selectedPortfolio = updatedPortfolio;
          }

          // If there's no selected portfolio when the portfolios load, select the first one from the list
          if (selectedPortfolioId.length === 0) {
            state.selectedPortfolio = newPortfolioList[0] ?? null;
          }
        }

        state.portfoliosStatus = DataStatus.SUCCEEDED;
      })
      .addCase(refreshPortfoliosList.rejected, (state, action) => {
        logRejected(action, 'refreshPortfoliosList failed');
        state.portfoliosStatus = DataStatus.FAILED;
      })
      .addCase(refreshResultSetsList.pending, (state, action) => {
        state.resultSetsStatus = DataStatus.LOADING;
      })
      .addCase(refreshResultSetsList.fulfilled, (state: CSGDataState, action) => {
        if (action.payload !== null) {
          const newResultSetsList = action.payload;
          state.resultSets = newResultSetsList;
          state.resultSetsStatus = DataStatus.SUCCEEDED;

          const selectedRsId = state?.selectedResultSet?.id ?? '';

          // Update selected result set (if any)
          if (selectedRsId.length > 0) {
            const selectedResultSetUpdate =
              newResultSetsList.find(({ id }: { id: string }) => id === selectedRsId) ?? null;

            state.selectedResultSet = selectedResultSetUpdate;
          }

          const selectedPortfolioId = state?.selectedPortfolio?.id ?? '';

          // If there's no selected result set when the result sets load, select the first one corresponding to the selected portfolio
          if (selectedRsId.length === 0 && selectedPortfolioId.length > 0) {
            const topRs =
              newResultSetsList.find(
                ({ portfolioId }: { portfolioId: string }) => portfolioId === selectedPortfolioId,
              ) ?? null;

            state.selectedResultSet = topRs;
          }
        }

        state.resultSetsStatus = DataStatus.IDLE;
      })
      .addCase(refreshResultSetsList.rejected, (state, action) => {
        logRejected(action, 'refreshResultSetsList failed');
        state.resultSetsStatus = DataStatus.FAILED;
      })
      .addCase(getDataVersion.pending, (state: CSGDataState) => {
        state.csgDataVersionStatus = DataStatus.LOADING;
      })
      .addCase(
        getDataVersion.fulfilled,
        (
          state: CSGDataState,
          action: PayloadAction<{
            csgVersion?: ParameterOption;
            eiVersion?: ParameterOption;
          }>,
        ) => {
          const { csgVersion, eiVersion } = action.payload;
          if (csgVersion) {
            const { choices, defaults } = csgVersion;

            state.csgDataVersions = choices.map((str) => {
              const selectedByDefault = defaults.includes(str);
              return {
                id: `csg-data-version-${str.trim()}`,
                name: str, // internal name to be used for sending value to API
                title: str,
                selected: selectedByDefault,
                selectedByDefault,
              };
            });
          }
          if (eiVersion) {
            const { choices, defaults } = eiVersion;

            state.eiVersions = choices.map((str) => {
              const selectedByDefault = defaults.includes(str);
              return {
                id: `ei-version-${str.trim()}`,
                name: str, // internal name to be used for sending value to API
                title: str,
                selected: selectedByDefault,
                selectedByDefault,
              };
            });
          }
          state.csgDataVersionStatus = DataStatus.SUCCEEDED;
        },
      )
      .addCase(getDataVersion.rejected, (state, action) => {
        state.csgDataVersionStatus = DataStatus.FAILED;
        logRejected(action, 'getDataVersion failed');
      })
      .addCase(getEIVersion.pending, (state: CSGDataState) => {
        state.eiVersionStatus = DataStatus.LOADING;
      })
      .addCase(
        getEIVersion.fulfilled,
        (
          state: CSGDataState,
          action: PayloadAction<{
            eiVersion?: ParameterOption;
          }>,
        ) => {
          const { eiVersion } = action.payload;
          if (eiVersion) {
            const { choices, defaults } = eiVersion;

            state.eiVersions = choices.map((str) => {
              const selectedByDefault = defaults.includes(str);
              return {
                id: `ei-version-${str.trim()}`,
                name: str, // internal name to be used for sending value to API
                title: str,
                selected: selectedByDefault,
                selectedByDefault,
              };
            });
          }
          state.eiVersionStatus = DataStatus.SUCCEEDED;
        },
      )
      .addCase(getEIVersion.rejected, (state, action) => {
        state.eiVersionStatus = DataStatus.FAILED;
        logRejected(action, 'getEIVersion failed');
      })
      .addCase(downloadResultSet.pending, (state: CSGDataState, action) => {
        const rsId = action?.meta?.arg?.resultSetId ?? null;
        if (rsId) {
          state.downloadsById[`${rsId}`] = {
            id: rsId,
            source: DownloadSource.RESULT_SET,
            status: DataStatus.LOADING,
            error: '',
            created: timeNowUtc(),
            updated: timeNowUtc(),
          };
        }
      })
      .addCase(downloadResultSet.fulfilled, (state: CSGDataState, action) => {
        const rsId = action?.meta?.arg?.resultSetId ?? '';
        if (rsId) {
          state.downloadsById[`${rsId}`].status = DataStatus.SUCCEEDED;
          state.downloadsById[`${rsId}`].updated = timeNowUtc();
        }
      })
      .addCase(downloadResultSet.rejected, (state: CSGDataState, action: PayloadAction<any>) => {
        logRejected(action, 'downloadResultSet failed');

        const rsId: string = get(action, 'meta.arg.resultSetId', '');
        const errorMsg: string = get(action, 'payload', '');

        if (rsId.length > 0) {
          state.downloadsById[`${rsId}`].id = rsId;
          state.downloadsById[`${rsId}`].source = DownloadSource.RESULT_SET;
          state.downloadsById[`${rsId}`].status = DataStatus.FAILED;
          state.downloadsById[`${rsId}`].error = errorMsg;
          state.downloadsById[`${rsId}`].updated = timeNowUtc();
        }
      })
      .addCase(downloadPortfolio.pending, (state: CSGDataState, action) => {
        const pId = action?.meta?.arg?.portfolioId ?? null;
        if (pId) {
          state.downloadsById[`${pId}`] = {
            id: pId,
            source: DownloadSource.PORTFOLIO,
            status: DataStatus.LOADING,
            error: '',
            created: timeNowUtc(),
            updated: timeNowUtc(),
          };
        }
      })
      .addCase(downloadPortfolio.fulfilled, (state: CSGDataState, action) => {
        const pId = action?.meta?.arg?.portfolioId ?? '';
        if (pId) {
          state.downloadsById[`${pId}`].status = DataStatus.SUCCEEDED;
          state.downloadsById[`${pId}`].updated = timeNowUtc();
        }
      })
      .addCase(downloadPortfolio.rejected, (state: CSGDataState, action: PayloadAction<any>) => {
        logRejected(action, 'downloadPortfolio failed');

        const pId: string = get(action, 'meta.arg.portfolioId', '');
        const errorMsg: string = get(action, 'payload', '');

        if (pId.length > 0) {
          state.downloadsById[`${pId}`].id = pId;
          state.downloadsById[`${pId}`].source = DownloadSource.PORTFOLIO;
          state.downloadsById[`${pId}`].status = DataStatus.FAILED;
          state.downloadsById[`${pId}`].error = errorMsg;
          state.downloadsById[`${pId}`].updated = timeNowUtc();
        }
      })
      .addCase(downloadGeocodeLogReport.pending, (state: CSGDataState, action) => {
        const pId = action?.meta?.arg ?? null;
        if (pId) {
          state.downloadGeoCodingLogsById[`${pId}`] = {
            id: pId,
            source: DownloadSource.PORTFOLIO,
            status: DataStatus.LOADING,
            error: '',
            created: timeNowUtc(),
            updated: timeNowUtc(),
          };
        }
      })
      .addCase(downloadGeocodeLogReport.fulfilled, (state: CSGDataState, action) => {
        const pId = action?.meta?.arg ?? '';
        if (pId) {
          state.downloadGeoCodingLogsById[`${pId}`].status = DataStatus.SUCCEEDED;
          state.downloadGeoCodingLogsById[`${pId}`].updated = timeNowUtc();
        }
      })
      .addCase(
        downloadGeocodeLogReport.rejected,
        (state: CSGDataState, action: PayloadAction<any>) => {
          logRejected(action, 'downloadGeoCodingLog failed');

          const pId: string = get(action, 'meta.arg', '');
          const errorMsg: string = get(action, 'payload', '');

          if (pId.length > 0) {
            state.downloadGeoCodingLogsById[`${pId}`].id = pId;
            state.downloadGeoCodingLogsById[`${pId}`].source = DownloadSource.PORTFOLIO;
            state.downloadGeoCodingLogsById[`${pId}`].status = DataStatus.FAILED;
            state.downloadGeoCodingLogsById[`${pId}`].error = errorMsg;
            state.downloadGeoCodingLogsById[`${pId}`].updated = timeNowUtc();
          }
        },
      )
      .addCase(downloadUsageReport.pending, (state: CSGDataState, action) => {
        const pId = DOWNLOAD_USAGE_REPORT_ID;
        if (pId) {
          state.downloadsById[`${pId}`] = {
            id: pId,
            source: DownloadSource.USAGE_REPORT,
            status: DataStatus.LOADING,
            error: '',
            created: timeNowUtc(),
            updated: timeNowUtc(),
          };
        }
      })
      .addCase(downloadUsageReport.fulfilled, (state: CSGDataState, action) => {
        const pId = DOWNLOAD_USAGE_REPORT_ID;
        if (pId) {
          state.downloadsById[`${pId}`].status = DataStatus.SUCCEEDED;
          state.downloadsById[`${pId}`].updated = timeNowUtc();
        }
      })
      .addCase(downloadUsageReport.rejected, (state: CSGDataState, action: PayloadAction<any>) => {
        logRejected(action, 'downloadUsageReport failed');

        const pId = DOWNLOAD_USAGE_REPORT_ID;
        const errorMsg: string = get(action, 'payload', '');

        if (pId.length > 0) {
          state.downloadsById[`${pId}`].id = pId;
          state.downloadsById[`${pId}`].source = DownloadSource.USAGE_REPORT;
          state.downloadsById[`${pId}`].status = DataStatus.FAILED;
          state.downloadsById[`${pId}`].error = errorMsg;
          state.downloadsById[`${pId}`].updated = timeNowUtc();
        }
      })
      .addCase(getUsageStats.pending, (state: CSGDataState, action) => {
        state.usageStats.status = DataStatus.LOADING;
      })
      .addCase(getUsageStats.fulfilled, (state: CSGDataState, action) => {
        state.usageStats.stats = action.payload;
        state.usageStats.status = DataStatus.SUCCEEDED;
      })
      .addCase(getUsageStats.rejected, (state: CSGDataState, action: PayloadAction<any>) => {
        logRejected(action, 'getUsageStats failed');

        state.usageStats.stats = null;
        state.usageStats.status = DataStatus.FAILED;
      });
  },
});

export const {
  addResultSets,
  updateDeletedResultSetsQueue,
  addToDeletedResultSetsQueue,
  removePortfolio,
  removeResultSet,
  setPortfolio,
  setResultSet,
  selectPortfolio,
  selectResultSet,
  selectResultSetForSLR,
  deselectResultSetForSLR,
  setResultSetDownloads,
  selectCsgDataVersion,
  resetCsgDataVersionToDefault,
  selectCsgEIVersion,
  resetCsgEIVersionToDefault,
} = csgDataSlice.actions;

export default csgDataSlice.reducer;
