import { Cancel as CancelIcon, Edit as EditIcon } from '@mui/icons-material';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { CenteredRowStyle } from '../../../utils/styleUtils';
import CoordinatesPicker from './CoordinatesPicker';
import { CoordinateItemProps } from './types';
import { deriveCoordsFromInputString } from './util';
import { StyledThinLabel } from '../../../components/labels/StyledLabel';

const CoordinateItem: FC<CoordinateItemProps> = ({
  ordinalLabel,
  coordinateData,
  onDelete,
  onEdit,
  editEnabled = true,
  deleteEnabled = true,
}) => {
  const theme = useTheme();
  const [editMode, setEditMode] = useState<boolean>(false);
  const { latitude, longitude } = coordinateData;

  const handleDelete = (): void => {
    onDelete(coordinateData);
  };

  const handleEdit = (): void => {
    setEditMode(true);
  };

  const handleSubmitEdit = (coordsStr: string): void => {
    const { lat, lng } = deriveCoordsFromInputString(coordsStr);
    onEdit({
      ...coordinateData,
      latitude: lat,
      longitude: lng,
    });
    setEditMode(false);
  };

  const handleCancelEdit = (): void => {
    setEditMode(false);
  };

  const coordsStrValue = `${latitude},${longitude}`; // no spaces

  return (
    <Box data-testid="coordinate-item-component" sx={{ width: '100%' }}>
      {editMode ? (
        <Box sx={{ ...CenteredRowStyle, width: '100%' }}>
          <StyledThinLabel variant="h6" noWrap sx={{ paddingRight: theme.spacing(0.625) }}>
            {ordinalLabel}.
          </StyledThinLabel>
          <CoordinatesPicker
            onSubmit={handleSubmitEdit}
            coordsStrValue={coordsStrValue}
            submitButtonText="Submit"
            TextFieldOverrides={{ variant: 'standard', label: 'Edit' }}
            ButtonOverrides={{ variant: 'text' }}
          />
          <Button
            variant="text"
            color="secondary"
            onClick={handleCancelEdit}
            aria-label="cancel-edit-coordinates"
          >
            Cancel
          </Button>
        </Box>
      ) : (
        <Box sx={{ ...CenteredRowStyle, alignItems: 'center', width: '100%' }}>
          <StyledThinLabel variant="h6" noWrap sx={{ paddingRight: theme.spacing(0.625) }}>
            {ordinalLabel}.
          </StyledThinLabel>
          <Typography variant="h6" noWrap>
            {coordsStrValue}
          </Typography>
          {editEnabled && (
            <IconButton
              data-cy="coordinate-item-edit-button"
              aria-label="edit-coordinates"
              onClick={handleEdit}
              color="inherit"
            >
              <EditIcon sx={{ height: theme.spacing(2), width: theme.spacing(2) }} />
            </IconButton>
          )}
          {deleteEnabled && (
            <IconButton
              data-cy="coordinate-item-delete-button"
              aria-label="delete-coordinates"
              onClick={handleDelete}
              color="inherit"
            >
              <CancelIcon sx={{ height: theme.spacing(2), width: theme.spacing(2) }} />
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  );
};
export default CoordinateItem;
