import { Box, BoxProps, useTheme } from '@mui/material';
import { FC, ReactElement } from 'react';
import {
  LITE_TEXT_COLOR,
  SECTION_LABEL_BG_COLOR,
  SIDE_PANEL_SEPARATOR,
} from '../../utils/styleUtils';
import { StyledThinLabel } from '../labels/StyledLabel';
import { FONT_ALIGNED_TEXT_STYLE } from '../../theme';

export interface FormListProps extends BoxProps {
  children: ReactElement | ReactElement[] | string;
  label: string;
  disableBottomBorder?: boolean;
  disableTopBorderRadius?: boolean;
  isColumn?: boolean;
  'data-testid'?: string;
}

const FormList: FC<FormListProps> = ({
  label,
  children,
  disableBottomBorder = false,
  disableTopBorderRadius = false,
  isColumn = false,
  sx = {},
  'data-testid': dataTestId,
  ...boxProps
}: FormListProps) => {
  const theme = useTheme();
  const disabledBottomBorderStyles = {
    borderBottom: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  };
  const disabledTopBorderRadiusStyles = {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  };
  return (
    <Box
      sx={{
        paddingLeft: theme.spacing(1.5),
        border: SIDE_PANEL_SEPARATOR,
        marginTop: 0,
        borderRadius: theme.spacing(0.5),
        ...(disableBottomBorder ? disabledBottomBorderStyles : {}),
        ...(disableTopBorderRadius ? disabledTopBorderRadiusStyles : {}),
      }}
      data-testid={dataTestId}
    >
      <Box
        sx={{
          position: 'absolute',
          margin: `0 ${theme.spacing(1)}`,
          width: 'fit-content',
          border: SIDE_PANEL_SEPARATOR,
          marginTop: `-${theme.spacing(1.75)}`,
          borderRadius: theme.spacing(0.5),
        }}
      >
        <StyledThinLabel
          sx={{
            background: SECTION_LABEL_BG_COLOR,
            color: LITE_TEXT_COLOR,
            padding: `0 ${theme.spacing(1)}`,
            borderRadius: theme.spacing(0.5),
            ...FONT_ALIGNED_TEXT_STYLE,
          }}
          variant="h6"
          data-testid="form-list-title"
        >
          {label}
        </StyledThinLabel>
      </Box>
      <Box
        {...boxProps}
        sx={{
          paddingBottom: theme.spacing(2.5),
          paddingTop: theme.spacing(3),
          display: 'flex',
          flexDirection: isColumn ? 'column' : 'row',
          flexWrap: 'wrap',
          alignItems: 'end',
          ...sx,
        }}
        data-testid="form-list-details"
      >
        {children}
      </Box>
    </Box>
  );
};

export default FormList;
