import { Box, BoxProps, Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, ReactNode } from 'react';
import {
  CenteredRowStyle,
  PRIMARY_BTN_BG_COLOR,
  SWITCH_BTN_BORDER,
  StyleProps,
} from '../../utils/styleUtils';

export interface IndividualButtonProps {
  onClick: () => void;
  icon: string | ReactNode;
  disabled?: boolean;
  id: string;
  buttonSx?: StyleProps;
}

interface SectionedIconButtonProps extends BoxProps {
  buttons: IndividualButtonProps[];
}

export const StyledButton: FC<ButtonProps> = styled(Button)(({ theme }) => ({
  background: 'transparent !important',
  border: SWITCH_BTN_BORDER,
  borderRadius: theme.spacing(0.5),
  height: theme.spacing(4),
  width: theme.spacing(4),
  minWidth: 'auto',
  color: 'inherit',
  ':hover': {
    background: 'inherit',
  },
  ':disabled': {
    color: 'inherit',
    opacity: 0.6,
  },
  ':not(:first-of-type)': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: 0,
  },
  ':not(:last-of-type)': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

const SectionedIconButton: FC<SectionedIconButtonProps> = styled(
  ({ buttons, ...sectionedBoxProps }: SectionedIconButtonProps) => {
    return (
      <Box sx={CenteredRowStyle} role="group" {...sectionedBoxProps}>
        {buttons.map((button) => {
          const { icon, id, onClick, disabled = false, buttonSx } = button;
          return (
            <StyledButton
              key={id}
              data-testid={id}
              onClick={onClick}
              disabled={disabled}
              sx={buttonSx}
            >
              {icon}
            </StyledButton>
          );
        })}
      </Box>
    );
  },
)(() => {
  return {
    background: PRIMARY_BTN_BG_COLOR,
    button: {},
    svg: {},
  };
});

export default SectionedIconButton;
