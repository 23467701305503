import { ChipGroupItem } from './types';

export const extractChipItemProps = (inputObject: any): ChipGroupItem => {
  // NOTE: The purpose of this function is to ensure that when chips are being produced from other objects, they only contain ChipGroupItem-properties
  const { name, id, disabled, tooltip, showInfo, onDeleteClick, onInfoClick } = inputObject;

  return {
    name,
    id,
    disabled,
    tooltip,
    showInfo,
    onDeleteClick,
    onInfoClick,
  };
};
