export enum ValidationSectionTypeUI {
  ECONOMIC_IMPACTS,
  FLOOD_MESH,
  PERILS,
  SCORES,
  GLOBAL,
}

export interface ValidationUI {
  valid: boolean;
  message: string;
  section: ValidationSectionTypeUI;
}

export interface QueryBuilderValidation extends ValidationUI {
  failed?: ValidationUI[];
  succeeded?: ValidationUI[];
}

export interface ValidatorProps {
  sectionType: ValidationSectionTypeUI;
  value?: string;
  fieldName?: string;
  isOptional?: boolean;
}

// export interface QueryBuilderValidationProps {}
