/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { trackCustomEvent } from '../../heap';
import { ResultSet, ResultSetType } from '../../api/resultSets/types';
import { AlertSeverity } from '../../components/alerts/types';
import { CheckboxOption, UpdateCheckboxInstructions } from '../../components/checkbox/types';
import { RadioOption, UpdateRadioInstructions } from '../../components/radio/types';
import { TextInputOption } from '../../components/text-input/types';
import { constructResultSetPayloads } from '../../pages/query-builder/builder';
import { getSystemDefaultNew, resultSetOptionsUI } from '../../pages/query-builder/constants';
import { UpdateTextFormInstructions } from '../../pages/query-builder/sub-options/DamageAndLossOptions';
import {
  GridSizeUI,
  ResultSetInput,
  ResultSetOptionsUI,
  ResultSetTypeUI,
} from '../../pages/query-builder/types';
import {
  addResultSets,
  resetCsgDataVersionToDefault,
  selectResultSet,
  VersionOption,
  getSelectedDataVersionName,
  getSelectedEIVersionName,
} from './csgDataSlice';
import { addAlert, setQueryBuilderDrawer } from './uiSlice';
import {
  extractLocationCount,
  getResultSetAnalyticsParams,
  handleCheckboxOperation,
  handleRadioOperation,
  logRejected,
} from './utils';
import { INFO_CODES_DICT } from '../../pages/upload/validation/util';
import { Portfolio } from '../../api/portfolios/types';
import { userCanAccessLargeGrid } from './userSlice';
import {
  createResultSetAdapter,
  getResultSetDefaultParamsAdapter,
} from '../../rest/resultSet/adapter';

interface CreateResultSetOutput {
  error: string | null;
  data: any;
}

interface QueryBuilderParametersInput {
  csgVersion: string;
  eiVersion: string;
}

export const updateQueryBuilderState = createAsyncThunk(
  'query/update-query-builder',
  async (
    {
      csgDataVersionOption,
      settingsOnly,
      portfolio = null,
    }: {
      csgDataVersionOption?: VersionOption | null;
      settingsOnly?: boolean;
      portfolio?: Portfolio | null;
    },
    { getState, dispatch },
  ) => {
    const state: any = getState();
    const csgDataVersion = csgDataVersionOption?.name ?? getSelectedDataVersionName(state);
    const eiVersion = getSelectedEIVersionName(state) ?? csgDataVersion;
    const canAccessLargeGrid = userCanAccessLargeGrid(state?.user);

    const { payload: rsParameters } = await dispatch(
      loadQueryBuilderParameters({ csgVersion: csgDataVersion, eiVersion }),
    );

    // 1. Set result set types
    const enableFloodMeshRs: boolean = (extractLocationCount(portfolio) ?? 0) <= 50;

    const nextQueryBuilderOptions = resultSetOptionsUI.map((option) => {
      if (option.type === ResultSetTypeUI.FLOOD_MESH) {
        return {
          ...option,
          selected: enableFloodMeshRs ? option.selected : false,
          disabled: enableFloodMeshRs ? option.disabled : true,
          tooltip: enableFloodMeshRs ? option.tooltip : INFO_CODES_DICT.I101.description,
        };
      }
      return option;
    });

    // 2. Set config by result set type
    const systemDefault =
      (resultSetType: ResultSetTypeUI) => (parameterPath: string, rsDefaultParams?: any) =>
        getSystemDefaultNew({
          resultSetType,
          parameterPath,
          csgDataVersion,
          rsDefaultParams,
        });

    const perilsDef = systemDefault(ResultSetTypeUI.PERILS);
    const dlDef = systemDefault(ResultSetTypeUI.ECONOMIC_IMPACTS);
    const floodMeshDef = systemDefault(ResultSetTypeUI.FLOOD_MESH);
    const scoresDef = systemDefault(ResultSetTypeUI.SCORES);

    return {
      settingsOnly, // pass-through to reducer
      queryBuilderOptions: nextQueryBuilderOptions,
      // ------------------------------------------ 1. Perils Only
      perils: {
        perils: perilsDef('perils'),
        scenarios: perilsDef('scenarios'),
        years: perilsDef('years'),
        floodDefense: perilsDef('floodDefense'),
        floodDefenseOptions: perilsDef('floodDefenseOptions'),
      },
      // ------------------------------------------ 2. Damage & Loss
      damageAndLoss: {
        perils: dlDef('perils'),
        scenarios: dlDef('scenarios'),
        years: dlDef('years'),
        floodDefense: dlDef('floodDefense'),
        floodDefenseOptions: dlDef('floodDefenseOptions'),
        damageAndLossValues: dlDef('damageAndLossValues', rsParameters),
        lossCalculationValues: dlDef('lossCalculationValues'),
        advancedParameterValues: dlDef('advancedParameterValues'),
        workerProductivity: dlDef('workerProductivity'),
        workerProductivityValues: dlDef('workerProductivityValues'),
        wildfireLoss: dlDef('wildfireLoss'),
        wildfireLossValues: dlDef('wildfireLossValues'),
        costOfWater: dlDef('costOfWater'),
        costOfWaterValues: dlDef('costOfWaterValues'),
        portfolioLossMetrics: dlDef('portfolioLossMetrics'),
        financialParametersValues: dlDef('financialParametersValues'),
        financialMetrics: dlDef('financialMetrics'),
      },
      // ------------------------------------------ 3. Flood Mesh
      floodMesh: {
        gridSizes: floodMeshDef('gridSizes').map((option: any) => {
          if (option.name === GridSizeUI.REGULAR_LARGE) {
            return {
              ...option,
              disabled: canAccessLargeGrid ? option.disabled : true,
              tooltip: canAccessLargeGrid ? option.tooltip : INFO_CODES_DICT.I103.description,
            };
          }

          return option;
        }),
        scenarios: floodMeshDef('scenarios'),
        years: floodMeshDef('years'),
        floodDefense: floodMeshDef('floodDefense'),
        floodDefenseOptions: floodMeshDef('floodDefenseOptions'),
      },
      // ------------------------------------------ 4. Scores
      scores: {
        scorePerils: scoresDef('scorePerils'),
        benchmarkingIncluded: scoresDef('benchmarkingIncluded'),
        benchmarkingOptions: scoresDef('benchmarkingOptions'),
      },
    };
  },
);

export const loadQueryBuilderParameters = createAsyncThunk(
  'query/load-input-parameters',
  async (payload: QueryBuilderParametersInput, { rejectWithValue, dispatch }) => {
    const { csgVersion, eiVersion } = payload;
    const mutationResponse = await getResultSetDefaultParamsAdapter(csgVersion, eiVersion);

    const { data: getParameters, error } = mutationResponse;

    if (getParameters) {
      return getParameters;
    }

    if (error) {
      dispatch(
        addAlert({
          severity: AlertSeverity.Error,
          open: true,
          message: `Failed to query builder params. ${error.message}.`,
        }),
      );
      return rejectWithValue(error.message);
    }

    return rejectWithValue('Unknown error refreshing portfolios');
  },
);

const createResultSet = async (payload: ResultSetInput): Promise<CreateResultSetOutput> => {
  const mutationResponse = await createResultSetAdapter(payload);

  const { data, error } = mutationResponse;

  if (data) {
    return { data, error: null };
  }

  if (error) {
    return { data: null, error: error.message };
  }

  return { data: null, error: 'Unknown error in result set' };
};

async function createResultSetsForInput(input: ResultSetInput): Promise<CreateResultSetOutput[]> {
  const output = await createResultSet(input);
  if (
    input.type === ResultSetType.ECONOMIC_IMPACTS &&
    input.parameters.portfolioLossMetrics &&
    !!output.data
  ) {
    const { data } = output;
    const portofolioInput: ResultSetInput = {
      name: 'Portfolio Financial Metrics',
      parameters: {
        csgVersion: input.parameters.csgVersion,
        eiVersion: input.parameters.eiVersion,
        damagesResultSet: data.id,
      },
      portfolioId: input.portfolioId,
      type: ResultSetType.PORTFOLIO_FINANCIAL,
    };
    const portfolioOutput = await createResultSet(portofolioInput);
    return [portfolioOutput, output];
  }
  return [output];
}

export const createResultSets = createAsyncThunk(
  'query/create-result-sets',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState();
    const resultSetPayloads = constructResultSetPayloads(state);

    // eslint-disable-next-line no-console
    console.log('1. Created input(s) for result set(s):', resultSetPayloads);

    const requests = resultSetPayloads.map(createResultSetsForInput);

    const responseLists = await Promise.all(requests);
    const responses: CreateResultSetOutput[] = responseLists.flat();

    // eslint-disable-next-line no-console
    console.log('2. Received response(s):', responses);

    // eslint-disable-next-line no-console
    console.log('3. figure out if any errors should be reported');
    const successes: ResultSet[] = responses.map(({ data }) => data).filter((item) => !!item);
    const numFailed = responses.filter(({ error }) => error)?.length;
    const numSuccessful = successes.length;
    const numRequests = requests.length;

    // eslint-disable-next-line no-console
    console.log(`${numSuccessful}/${numRequests} result sets returned data`);

    if (numFailed > 0) {
      if (numFailed < numRequests) {
        // eslint-disable-next-line no-console
        console.log('Partial success');

        dispatch(
          addAlert({
            severity: AlertSeverity.Warning,
            open: true,
            message: `Partial success: ${numSuccessful}/${numRequests} result sets were created.`,
          }),
        );
      }

      if (numFailed === numRequests) {
        dispatch(
          addAlert({
            severity: AlertSeverity.Error,
            open: true,
            message: 'Failed to create result sets.',
          }),
        );

        return rejectWithValue(responses);
      }
    } else {
      dispatch(
        addAlert({
          severity: AlertSeverity.Success,
          open: true,
          message: `Created ${numSuccessful} result sets.`,
        }),
      );
      const customEventParams = getResultSetAnalyticsParams(resultSetPayloads);
      trackCustomEvent('Start - Query Builder', { ...customEventParams });
    }

    // eslint-disable-next-line no-console
    console.log('4. refresh result sets');

    // 2) publish successful result sets
    // NOTE: this increases perceived performance by not having to fully refresh the result sets
    dispatch(addResultSets(successes));

    // NOTE: Select the 1st of the newly added result-set(s)
    if (numSuccessful > 0) {
      dispatch(selectResultSet(successes[0]));
    }

    // eslint-disable-next-line no-console
    console.log('Done.');

    // 3) close query builder drawer
    // eslint-disable-next-line no-console
    console.log('5. close query builder drawer');
    dispatch(setQueryBuilderDrawer(false));
    //
    await dispatch(updateQueryBuilderState({})); // this totally resets it
    dispatch(resetCsgDataVersionToDefault());

    // eslint-disable-next-line no-console
    console.log('Done.');

    return responses.map(({ data }) => data);
  },
);

export enum QueryBuilderStatus {
  IDLE = 'idle',
  SUCCEEDED = 'succeeded',
  LOADING = 'loading',
  FAILED = 'failed',
}

export interface QueryBuilderState {
  status: QueryBuilderStatus;
  reportName: string;
  portfolioId: string;
  queryBuilderOptions: ResultSetOptionsUI[];
  // ------------------------------------------ 1. Perils Only
  perils: {
    floodDefense: CheckboxOption[];
    floodDefenseOptions: CheckboxOption[];
    perils: CheckboxOption[];
    scenarios: CheckboxOption[];
    years: CheckboxOption[];
  };
  // ------------------------------------------ 2. Damage & Loss
  damageAndLoss: {
    damageAndLossValues: TextInputOption[];
    floodDefense: CheckboxOption[];
    floodDefenseOptions: CheckboxOption[];
    lossCalculationValues: TextInputOption[];
    advancedParameterValues: TextInputOption[];
    workerProductivity: CheckboxOption[];
    workerProductivityValues: TextInputOption[];
    wildfireLoss: CheckboxOption[];
    wildfireLossValues: TextInputOption[];
    costOfWater: CheckboxOption[];
    costOfWaterValues: TextInputOption[];
    portfolioLossMetrics: CheckboxOption[];
    financialParametersValues: TextInputOption[];
    financialMetrics: CheckboxOption[];
    perils: CheckboxOption[];
    scenarios: CheckboxOption[];
    years: CheckboxOption[];
  };
  // ------------------------------------------ 3. Flood Mesh
  floodMesh: {
    floodDefense: CheckboxOption[];
    floodDefenseOptions: CheckboxOption[];
    gridSizes: RadioOption[];
    scenarios: CheckboxOption[];
    years: CheckboxOption[];
  };
  // ------------------------------------------ 4. Scores
  scores: {
    benchmarkingIncluded: CheckboxOption[];
    benchmarkingOptions: CheckboxOption[];
    scorePerils: CheckboxOption[];
  };
}

const initialState: QueryBuilderState = {
  status: QueryBuilderStatus.IDLE,
  reportName: '',
  portfolioId: '',
  queryBuilderOptions: resultSetOptionsUI,
  // ------------------------------------------ 1. Perils Only
  perils: {
    perils: [],
    scenarios: [],
    years: [],
    floodDefense: [],
    floodDefenseOptions: [],
  },
  // ------------------------------------------ 2. Damage & Loss
  damageAndLoss: {
    perils: [],
    scenarios: [],
    years: [],
    floodDefense: [],
    floodDefenseOptions: [],
    damageAndLossValues: [],
    lossCalculationValues: [],
    advancedParameterValues: [],
    workerProductivity: [],
    workerProductivityValues: [],
    wildfireLoss: [],
    wildfireLossValues: [],
    costOfWater: [],
    costOfWaterValues: [],
    portfolioLossMetrics: [],
    financialParametersValues: [],
    financialMetrics: [],
  },
  // ------------------------------------------ 3. Flood Mesh
  floodMesh: {
    gridSizes: [],
    scenarios: [],
    years: [],
    floodDefense: [],
    floodDefenseOptions: [],
  },
  // ------------------------------------------ 4. Scores
  scores: {
    scorePerils: [],
    benchmarkingIncluded: [],
    benchmarkingOptions: [],
  },
};
export const queryBuilderSlice = createSlice({
  name: 'queryBuilder',
  initialState,
  // the `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // ------------------------------------------ 1. Perils Only
    setPerilsPerils: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null } = action.payload;
      state.perils.perils = handleCheckboxOperation({
        prevList: state.perils.perils,
        operation,
        id,
      });
    },
    setPerilsScenarios: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null } = action.payload;
      state.perils.scenarios = handleCheckboxOperation({
        prevList: state.perils.scenarios,
        operation,
        id,
      });
    },
    setPerilsYears: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null } = action.payload;
      state.perils.years = handleCheckboxOperation({
        prevList: state.perils.years,
        operation,
        id,
      });
    },
    setPerilsFloodDefense: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null, skipForbidden } = action.payload;
      state.perils.floodDefense = handleCheckboxOperation({
        prevList: state.perils.floodDefense,
        operation,
        id,
        skipForbidden,
      });
    },
    setPerilsFloodDefenseOptions: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null, skipForbidden } = action.payload;
      state.perils.floodDefenseOptions = handleCheckboxOperation({
        prevList: state.perils.floodDefenseOptions,
        operation,
        id,
        skipForbidden,
      });
    },
    // ------------------------------------------ 2. Damage & Loss
    setDamageAndLossPerils: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null } = action.payload;
      state.damageAndLoss.perils = handleCheckboxOperation({
        prevList: state.damageAndLoss.perils,
        operation,
        id,
      });
    },
    setDamageAndLossScenarios: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null } = action.payload;
      state.damageAndLoss.scenarios = handleCheckboxOperation({
        prevList: state.damageAndLoss.scenarios,
        operation,
        id,
      });
    },
    setDamageAndLossYears: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null } = action.payload;
      state.damageAndLoss.years = handleCheckboxOperation({
        prevList: state.damageAndLoss.years,
        operation,
        id,
      });
    },
    setDamageAndLossFloodDefense: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null, skipForbidden } = action.payload;
      state.damageAndLoss.floodDefense = handleCheckboxOperation({
        prevList: state.damageAndLoss.floodDefense,
        operation,
        id,
        skipForbidden,
      });
    },
    setDamageAndLossFloodDefenseOptions: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null, skipForbidden } = action.payload;
      state.damageAndLoss.floodDefenseOptions = handleCheckboxOperation({
        prevList: state.damageAndLoss.floodDefenseOptions,
        operation,
        id,
        skipForbidden,
      });
    },
    setDamageAndLossDamageValues: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateTextFormInstructions>,
    ) => {
      const { id, value } = action.payload;

      const nextState = state.damageAndLoss.damageAndLossValues.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            value,
          };
        }
        return item;
      });

      state.damageAndLoss.damageAndLossValues = nextState;
    },
    setDamageAndLossLossValues: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateTextFormInstructions>,
    ) => {
      const { id, value } = action.payload;

      const nextState = state.damageAndLoss.lossCalculationValues.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            value,
          };
        }
        return item;
      });

      state.damageAndLoss.lossCalculationValues = nextState;
    },
    setDamageAndLossAdvParamValues: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateTextFormInstructions>,
    ) => {
      const { id, value } = action.payload;

      const nextState = state.damageAndLoss.advancedParameterValues.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            value,
          };
        }
        return item;
      });

      state.damageAndLoss.advancedParameterValues = nextState;
    },
    setDamageAndLossWorkerProductivity: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null } = action.payload;
      state.damageAndLoss.workerProductivity = handleCheckboxOperation({
        prevList: state.damageAndLoss.workerProductivity,
        operation,
        id,
      });
    },
    setDamageAndLossWorkerProductivityValues: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateTextFormInstructions>,
    ) => {
      const { id, value } = action.payload;

      const nextState = state.damageAndLoss.workerProductivityValues.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            value,
          };
        }
        return item;
      });

      state.damageAndLoss.workerProductivityValues = nextState;
    },
    setDamageAndLossWildfireLoss: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null } = action.payload;
      state.damageAndLoss.wildfireLoss = handleCheckboxOperation({
        prevList: state.damageAndLoss.wildfireLoss,
        operation,
        id,
      });
    },
    setDamageAndLossWildfireLossValues: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateTextFormInstructions>,
    ) => {
      const { id, value } = action.payload;

      const nextState = state.damageAndLoss.wildfireLossValues.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            value,
          };
        }
        return item;
      });

      state.damageAndLoss.wildfireLossValues = nextState;
    },
    setDamageAndLossCostOfWater: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null } = action.payload;
      state.damageAndLoss.costOfWater = handleCheckboxOperation({
        prevList: state.damageAndLoss.costOfWater,
        operation,
        id,
      });
    },
    setDamageAndLossCostOfWaterValues: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateTextFormInstructions>,
    ) => {
      const { id, value } = action.payload;

      const nextState = state.damageAndLoss.costOfWaterValues.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            value,
          };
        }
        return item;
      });

      state.damageAndLoss.costOfWaterValues = nextState;
    },
    setDamageAndLossPorfolioLossMetrics: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null } = action.payload;
      state.damageAndLoss.portfolioLossMetrics = handleCheckboxOperation({
        prevList: state.damageAndLoss.portfolioLossMetrics,
        operation,
        id,
      });
    },
    setDamageAndLossFinancialMetrics: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null } = action.payload;
      state.damageAndLoss.financialMetrics = handleCheckboxOperation({
        prevList: state.damageAndLoss.financialMetrics,
        operation,
        id,
      });
    },
    setDamageAndLossFinancialValues: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateTextFormInstructions>,
    ) => {
      const { id, value } = action.payload;

      const nextState = state.damageAndLoss.financialParametersValues.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            value,
          };
        }
        return item;
      });

      state.damageAndLoss.financialParametersValues = nextState;
    },
    // ------------------------------------------ 3. Flood Mesh
    setFloodMeshGridSizes: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateRadioInstructions>,
    ) => {
      const { operation, id = null } = action.payload;
      state.floodMesh.gridSizes = handleRadioOperation({
        prevList: state.floodMesh.gridSizes,
        operation,
        id,
      });
    },
    setFloodMeshScenarios: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null } = action.payload;
      state.floodMesh.scenarios = handleCheckboxOperation({
        prevList: state.floodMesh.scenarios,
        operation,
        id,
      });
    },
    setFloodMeshYears: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null } = action.payload;
      state.floodMesh.years = handleCheckboxOperation({
        prevList: state.floodMesh.years,
        operation,
        id,
      });
    },
    setFloodMeshFloodDefense: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null, skipForbidden } = action.payload;
      state.floodMesh.floodDefense = handleCheckboxOperation({
        prevList: state.floodMesh.floodDefense,
        operation,
        id,
        skipForbidden,
      });
    },
    setFloodMeshFloodDefenseOptions: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null, skipForbidden } = action.payload;
      state.floodMesh.floodDefenseOptions = handleCheckboxOperation({
        prevList: state.floodMesh.floodDefenseOptions,
        operation,
        id,
        skipForbidden,
      });
    },
    // ------------------------------------------ 4. Scores
    updateScoresPerils: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null } = action.payload;
      state.scores.scorePerils = handleCheckboxOperation({
        prevList: state.scores.scorePerils,
        operation,
        id,
      });
    },
    updateScoresBenchmarkingIncluded: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null } = action.payload;
      state.scores.benchmarkingIncluded = handleCheckboxOperation({
        prevList: state.scores.benchmarkingIncluded,
        operation,
        id,
      });
    },
    updateScoresBenchmarkingOptions: (
      state: QueryBuilderState,
      action: PayloadAction<UpdateCheckboxInstructions>,
    ) => {
      const { operation, id = null } = action.payload;
      state.scores.benchmarkingOptions = handleCheckboxOperation({
        prevList: state.scores.benchmarkingOptions,
        operation,
        id,
      });
    },
    // ------------------------------------------ ...rest
    toggleResultSet: (state: QueryBuilderState, action: PayloadAction<ResultSetOptionsUI>) => {
      const resultSetItem = action.payload;

      const nextState = state.queryBuilderOptions.map(({ id, selected, ...option }) => ({
        ...option,
        id,
        selected: id === resultSetItem.id ? !selected : selected,
      }));

      state.queryBuilderOptions = nextState;
    },
    newQuery: (
      state: QueryBuilderState,
      action: PayloadAction<{ portfolioId: string; reportName?: string }>,
    ) => {
      const { reportName = 'My new awesome report', portfolioId } = action.payload;
      state.status = QueryBuilderStatus.IDLE;
      state.reportName = reportName;
      state.portfolioId = portfolioId;
    },
    setFloodMeshRsAvailability: (
      state: QueryBuilderState,
      action: PayloadAction<{ enabled: boolean }>,
    ) => {
      const { enabled } = action.payload;

      const defaultValues = resultSetOptionsUI.find(
        (option) => option.type === ResultSetTypeUI.FLOOD_MESH,
      ) ?? { selected: false, disabled: false, tooltip: undefined };

      state.queryBuilderOptions.forEach((option, idx) => {
        if (option.type === ResultSetTypeUI.FLOOD_MESH) {
          state.queryBuilderOptions[`${idx}`].selected = enabled ? defaultValues.selected : false;
          state.queryBuilderOptions[`${idx}`].disabled = enabled ? defaultValues.disabled : true;
          state.queryBuilderOptions[`${idx}`].tooltip = enabled
            ? defaultValues.tooltip
            : INFO_CODES_DICT.I101.description;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      // TODO: NOTE: `updateQueryBuilderState.fulfilled` is the only case here, principally, because at this time it cannot really fail for any reason... that's not ideal design though (not covering for potential faults)
      .addCase(updateQueryBuilderState.fulfilled, (state, action) => {
        const nextQueryBuilderState = action.payload;

        const { settingsOnly = false, queryBuilderOptions } = nextQueryBuilderState;

        if (!settingsOnly) {
          state.status = QueryBuilderStatus.IDLE;
          state.reportName = '';
          state.portfolioId = '';
        }

        // The code below stands in for what used to be `resetQueryBuilder({})`
        state.queryBuilderOptions = queryBuilderOptions;
        // ------------------------------------------ 1. Perils Only
        state.perils = nextQueryBuilderState.perils;
        // ------------------------------------------ 2. Damage & Loss
        state.damageAndLoss = nextQueryBuilderState.damageAndLoss;
        // ------------------------------------------ 3. Flood Mesh
        state.floodMesh = nextQueryBuilderState.floodMesh;
        // ------------------------------------------ 4. Scores
        state.scores = nextQueryBuilderState.scores;
        //
      })
      .addCase(createResultSets.pending, (state) => {
        state.status = QueryBuilderStatus.LOADING;
        // eslint-disable-next-line no-console
        // console.log('Building result sets...');
      })
      .addCase(createResultSets.fulfilled, (state, action) => {
        state.status = QueryBuilderStatus.SUCCEEDED;
        // eslint-disable-next-line no-console
        // const resultSets = action.payload;
        // eslint-disable-next-line no-console
        // console.log('Result sets created!', resultSets);
      })
      .addCase(createResultSets.rejected, (state, action) => {
        logRejected(action, 'Result set(s) failed');
        state.status = QueryBuilderStatus.FAILED;
      })
      .addCase(loadQueryBuilderParameters.pending, (state) => {
        state.status = QueryBuilderStatus.LOADING;
        // eslint-disable-next-line no-console
        // console.log('Building result sets...');
      })
      .addCase(loadQueryBuilderParameters.fulfilled, (state, action) => {
        state.status = QueryBuilderStatus.SUCCEEDED;
        // eslint-disable-next-line no-console
        // const parameters = action.payload;
        // TODO: UNPACK `parameters`...(maybe)

        // eslint-disable-next-line no-console
        // console.log('%c Parameters found', 'color: #0F9D58', parameters);
      })
      .addCase(loadQueryBuilderParameters.rejected, (state, action) => {
        logRejected(action, 'Result set(s) failed');
        state.status = QueryBuilderStatus.FAILED;
      });
  },
});

export const {
  newQuery,
  setFloodMeshRsAvailability,
  // resetQueryBuilder, // NOTE: Replaced by `updateQueryBuilderState`
  toggleResultSet,
  // ------------------------------------------ 1. Perils Only
  setPerilsPerils,
  setPerilsScenarios,
  setPerilsYears,
  setPerilsFloodDefense,
  setPerilsFloodDefenseOptions,
  // ------------------------------------------ 2. Damage & Loss
  setDamageAndLossPerils,
  setDamageAndLossScenarios,
  setDamageAndLossYears,
  setDamageAndLossFloodDefense,
  setDamageAndLossFloodDefenseOptions,
  setDamageAndLossDamageValues,
  setDamageAndLossLossValues,
  setDamageAndLossAdvParamValues,
  setDamageAndLossWorkerProductivityValues,
  setDamageAndLossWorkerProductivity,
  setDamageAndLossWildfireLoss,
  setDamageAndLossWildfireLossValues,
  setDamageAndLossCostOfWater,
  setDamageAndLossCostOfWaterValues,
  setDamageAndLossPorfolioLossMetrics,
  setDamageAndLossFinancialValues,
  setDamageAndLossFinancialMetrics,
  // ------------------------------------------ 3. Flood Mesh
  setFloodMeshGridSizes,
  setFloodMeshScenarios,
  setFloodMeshYears,
  setFloodMeshFloodDefense,
  setFloodMeshFloodDefenseOptions,
  // ------------------------------------------ 4. Scores
  updateScoresPerils,
  updateScoresBenchmarkingIncluded,
  updateScoresBenchmarkingOptions,
} = queryBuilderSlice.actions;

export default queryBuilderSlice.reducer;
