import { FormControl, FormControlProps, FormLabel, FormLabelProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export type ColorInheritedTextFieldProps = TextFieldProps & {
  formLabelProps?: FormLabelProps;
  formControlProps?: FormControlProps;
  formLabel?: string;
  errorHelperDataTestId?: string;
};

const ColorInheritedTextField = styled(
  ({
    formLabel = '',
    formControlProps = {},
    formLabelProps = {},
    FormHelperTextProps = {},
    errorHelperDataTestId = 'rename-portfolio-result-set-error',
    ...textFieldProps
  }: ColorInheritedTextFieldProps) => (
    <FormControl
      {...formControlProps}
      sx={{
        display: 'flex',
        width: '100%',
        ...formControlProps.sx,
      }}
    >
      {formLabel && <FormLabel {...formLabelProps}>{formLabel}</FormLabel>}
      <TextField
        FormHelperTextProps={{ ...FormHelperTextProps, id: errorHelperDataTestId }}
        {...textFieldProps}
      />
    </FormControl>
  ),
)(({ theme }) => ({
  marginTop: `${theme.spacing(0.625)} !important`,
  '& .MuiInputBase-root-MuiFilledInput-root:after': {
    borderColor: 'inherit',
    color: 'inherit',
  },
  '& .MuiInputBase-root.MuiFilledInput-root:after': {
    borderColor: 'inherit',
    color: 'inherit',
  },
  '& .MuiFilledInput-root': {
    borderColor: 'inherit',
    color: 'inherit',
  },
  '& label': {
    color: 'inherit',
  },
  '& label.Mui-focused': {
    color: 'inherit',
  },
  '& .MuiInputBase-root': {
    height: theme.spacing(5),
    borderColor: 'inherit',
    color: 'inherit',
    '& .MuiInputBase-input': {
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.5),
    },
  },
  '& .MuiOutlinedInput-root': {
    borderColor: 'inherit',
    color: 'inherit',
    '& fieldset': {
      //   borderColor: 'inherit',
    },
    '&:hover fieldset': {
      borderColor: 'inherit',
      borderWidth: theme.spacing(0.25),
    },
    '&.Mui-focused fieldset': {
      borderColor: 'inherit',
    },
  },
  '& .MuiFormHelperText-root': {
    color: 'inherit',
  },
}));

export default ColorInheritedTextField;
