import { FC, ReactElement, useState } from 'react';
import InfoIconTooltip from '../InfoIconTooltip';
import AccordionDetails from './AccordionDetails';
import AccordionSummary from './AccordionSummary';
import StyledAccordion from './StyledAccordion';
import { IndividualOption, MuiAccordionProps } from './types';
import { StyleProps } from '../../utils/styleUtils';

export interface AccordionProps extends MuiAccordionProps {
  expandedDefault?: boolean;
  expandable?: boolean;
  option?: IndividualOption;
  onAccordionChange?: (panel: any) => void;
  accordionDetails?: ReactElement | string;
  accordionDetailsSx?: StyleProps;
  accordionTitle: ReactElement | string;
  accordionTitleTooltip?: string;
}

const Accordion: FC<AccordionProps> = ({
  expandedDefault = false,
  expandable = true,
  option,
  onAccordionChange = () => {},
  accordionDetails,
  accordionDetailsSx = {},
  accordionTitle,
  accordionTitleTooltip = '',
  children,
  ...accordionProps
}) => {
  const [isExpanded, setExpanded] = useState<boolean>(expandedDefault);

  return (
    <StyledAccordion
      expanded={isExpanded}
      onChange={() => {
        if (expandable) {
          setExpanded((prevFlag) => !prevFlag);
          onAccordionChange(option);
        }
      }}
      {...accordionProps}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        data-testid="panel1d-header"
      >
        {accordionTitle}
        {accordionTitleTooltip.length > 0 && (
          <InfoIconTooltip
            tooltip={accordionTitleTooltip}
            sx={{ marginLeft: '4px' }}
            placement="right"
          />
        )}
      </AccordionSummary>
      <AccordionDetails sx={accordionDetailsSx}>
        {accordionDetails}
        {children}
      </AccordionDetails>
    </StyledAccordion>
  );
};
export default Accordion;
