import { ClientCredsType } from '../../types/user';
import { AlertObjectInput } from '../../components/alerts/types';
import { AuthStatus, KnownTenants, UserObject } from '../slices/userSlice';
import { RootState } from '../store';

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUsername = (state: RootState): string => state.user?.username ?? '';

export const selectUserId = (state: RootState): string => state.user?.userId ?? '';

export const selectCustomerName = (state: RootState): string => {
  const displayName = state.user?.tenantDisplayName ?? '';

  // Check in the list of KnownTenants if display name not present
  if (!displayName) {
    const tenantName = state.user?.tenantName ?? '';

    const tenantConfig = KnownTenants.find(({ name }) => name === tenantName);

    return tenantConfig?.label ?? '';
  }

  return displayName;
};

export const selectIsLoggedIn = (state: RootState): boolean => state.user?.isLoggedIn ?? false;

export const selectIdToken = (state: RootState): string => state.user?.idToken ?? '';

export const selectAccessToken = (state: RootState): string => state.user?.accessToken ?? '';

export const selectUserAlert = (state: RootState): AlertObjectInput =>
  state.user?.alert ?? {
    open: false,
  };

export const selectUserDetails = (state: RootState): UserObject => ({
  username: state.user?.username ?? '',
  userId: state.user?.userId ?? '',
  tenantName: state.user?.tenantName ?? '',
  tenantDisplayName: state.user?.tenantDisplayName ?? '',
  email: state.user?.email ?? '',
  userRole: state.user?.userRole ?? '',
  customRoles: state.user?.customRoles ?? [],
});

export const selectHasFidelityRole = (state: RootState): boolean =>
  state.user?.customRoles.includes('fidelity-slr') ?? false;

export const selectClientCreds = (state: RootState): ClientCredsType['creds'] =>
  state.user?.clientCreds?.creds ?? [];

export const selectClientCredsStatus = (state: RootState): AuthStatus =>
  state.user?.clientCreds?.status ?? AuthStatus.IDLE;

export default selectUsername;
