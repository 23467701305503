import { RadioOption } from '../../components/radio/types';
import { SLRScenarioType, SLRTemplateType } from '../../rest/slr/types';
import { SLRTemplateProps } from './types';

export const slrTemplateOptions: SLRTemplateProps[] = [
  { name: 'Change Report - Basic', value: SLRTemplateType.CR_BASIC, selected: false },
  { name: 'Exposure Report - Basic', value: SLRTemplateType.EX_BASIC, selected: false },
  { name: 'Change Report - Standard', value: SLRTemplateType.CR_STANDARD, selected: false },
  { name: 'Exposure Report - Standard', value: SLRTemplateType.EX_STANDARD, selected: false },
  { name: 'Change Report - Advanced', value: SLRTemplateType.CR_ADVANCED, selected: false },
  { name: 'Exposure Report - Advanced', value: SLRTemplateType.EX_ADVANCED, selected: false },
];

export const slrFidelityTemplateOptions: SLRTemplateProps[] = [
  { name: 'Fidelity', value: SLRTemplateType.FIDELITY, selected: false },
];

export const slrDownloadOptions: RadioOption[] = [
  {
    id: 'all',
    name: 'All Locations',
    selected: false,
  },
  {
    id: 'one',
    name: 'Selected Location',
    selected: true,
  },
];

export const slrScenarioOptions: RadioOption[] = [
  {
    id: SLRScenarioType.SSP2,
    name: 'SSP2',
    selected: false,
  },
  {
    id: SLRScenarioType.SSP5,
    name: 'SSP5',
    selected: false,
  },
];
