import { FC } from 'react';
import { IconButton, IconButtonProps } from '@mui/material';
import { NorthEast as NorthEastIcon } from '@mui/icons-material';
import { useAppDispatch } from '../../redux/hooks';
import { setVizNavMenu } from '../../redux/slices/uiSlice';

const VizNavButton: FC<IconButtonProps> = ({ sx, ...props }: IconButtonProps) => {
  const dispatch = useAppDispatch();

  const setNewVizMenuState = (newState: boolean): void => {
    dispatch(setVizNavMenu(newState));
  };

  return (
    <IconButton
      disableRipple
      sx={{ color: 'inherit', padding: 0 }}
      onClick={() => {
        setNewVizMenuState(true);
      }}
      {...props}
    >
      <NorthEastIcon sx={{ height: '1rem', width: '1rem' }} />
    </IconButton>
  );
};

export default VizNavButton;
