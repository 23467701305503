import { Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

const MidDot: FC<TypographyProps> = ({ sx, ...props }) => {
  return (
    <Typography
      variant="body1"
      sx={{
        fontWeight: 'bold',
        position: 'relative',
        fontSize: '1rem',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        paddingRight: '5px',
        ...sx,
      }}
      {...props}
    >
      &middot;
    </Typography>
  );
};

export default MidDot;
