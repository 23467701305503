import { FC, useEffect } from 'react';
import { IconButton, IconButtonProps } from '@mui/material';
import { SaveAlt as SaveAltIcon } from '@mui/icons-material';
import { Portfolio } from '../../api/portfolios/types';
import { ResultSet, ResultSetStatus } from '../../api/resultSets/types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectSelectedPortfolio,
  selectSelectedResultSet,
} from '../../redux/selectors/csgDataSelectors';
import { setDownloadDrawer } from '../../redux/slices/uiSlice';
import {
  selectDownloadDrawerOpen,
  selectDownloadResultSetStatus,
} from '../../redux/selectors/uiSelectors';

const DataDownloadButton: FC<IconButtonProps> = ({ sx, ...props }: IconButtonProps) => {
  const dispatch = useAppDispatch();
  const selectedPortfolio = useAppSelector(selectSelectedPortfolio);
  const selectedResultSet = useAppSelector(selectSelectedResultSet);
  const downloadDrawerOpen = useAppSelector(selectDownloadDrawerOpen);
  const downloadableResultSetStatus = useAppSelector(selectDownloadResultSetStatus);

  const handleRequestDownload = async (
    portfolio: Portfolio | null,
    resultSet: ResultSet | null,
  ): Promise<void> => {
    dispatch(
      setDownloadDrawer({
        open: true,
        portfolioId: portfolio?.id ?? '',
        portfolioName: portfolio?.name ?? '',
        resultSetId: resultSet?.id ?? '',
        resultSetName: resultSet?.name ?? '',
        resultSetStatus: resultSet?.status ?? ResultSetStatus.COMPLETED,
      }),
    );
    // await dispatch(downloadResultSet({ resultSetId: id, resultSetName: name }));
  };

  useEffect(() => {
    if (downloadDrawerOpen && selectedResultSet?.status !== downloadableResultSetStatus) {
      dispatch(
        setDownloadDrawer({
          resultSetStatus: selectedResultSet?.status ?? ResultSetStatus.COMPLETED,
        }),
      );
    }
  }, [downloadDrawerOpen, downloadableResultSetStatus, selectedResultSet?.status, dispatch]);

  return (
    <IconButton
      disableRipple
      sx={{ color: 'inherit', padding: 0 }}
      onClick={() => {
        if (selectedPortfolio ?? selectedResultSet) {
          void handleRequestDownload(selectedPortfolio, selectedResultSet);
        }
      }}
      {...props}
    >
      <SaveAltIcon sx={{ height: '1rem', width: '1rem' }} />
    </IconButton>
  );
};

export default DataDownloadButton;
