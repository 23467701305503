import { FC, ReactNode } from 'react';
import { IconButtonProps, TypographyProps } from '@mui/material';
import DropdownHandle, { LabelWeight } from '../buttons/DropdownHandle';

interface TextDropdownProps {
  label: string;
  menu: ReactNode;
  isMenuOpen: boolean;
  iconButtonProps?: IconButtonProps;
  labelProps?: { labelWeight?: LabelWeight } & TypographyProps;
  disabled?: boolean;
  'data-testid'?: string;
  onClickMenu: (element: HTMLButtonElement | null) => void;
}

const TextDropdown: FC<TextDropdownProps> = ({
  label,
  menu,
  isMenuOpen,
  iconButtonProps = { sx: {} },
  labelProps = {},
  disabled = false,
  'data-testid': dataTestId,
  onClickMenu,
}: TextDropdownProps) => {
  const { sx: iconButtonSx, disabled: btnDisabled, ...otherIconButtonProps } = iconButtonProps;
  const { labelWeight, ...otherLabelProps } = labelProps;
  return (
    <>
      <DropdownHandle
        size="large"
        data-testid={dataTestId ?? 'text-dropdown-menu-button'}
        onClickMenu={onClickMenu}
        color="inherit"
        disableRipple
        sx={{ minWidth: 'auto', padding: 0, ...iconButtonSx }}
        label={label}
        labelProps={{
          variant: 'h6',
          noWrap: true,
          ...otherLabelProps,
        }}
        labelWeight={labelWeight}
        isHandleOn={isMenuOpen}
        disabled={disabled || btnDisabled}
        {...otherIconButtonProps}
      />
      {menu}
    </>
  );
};

export default TextDropdown;
