import { IndeterminateCheckBoxOutlined as IndeterminateCheckBoxOutlinedIcon } from '@mui/icons-material';
import { Box, FormGroup } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { itemSelected } from '../../pages/query-builder/utils';
import Checkbox from './Checkbox';
import { CheckboxListProps } from './types';

const CheckboxList: FC<CheckboxListProps> = ({
  options,
  onOptionChange,
  isRow = false,
  noMarginBottom = false,
  onSelectAll = () => {},
  onDeselectAll = () => {},
}) => {
  const handleSingleOptionClick =
    ({ id }: { id: string }) =>
    () =>
      onOptionChange(id);

  const countSelected: number = useMemo(() => options.filter(itemSelected).length, [options]);

  const countOptions: number = useMemo(() => options.length, [options]);

  const handleBulkOptionClick = useCallback(() => {
    if (countSelected === 0) {
      onSelectAll();
      return;
    }
    onDeselectAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countSelected]);

  return (
    <Box
      sx={(theme) => ({
        justifyContent: 'start',
        alignItems: 'start',
        flexDirection: 'column',
        paddingLeft: theme.spacing(1.5),
        marginBottom: !noMarginBottom ? theme.spacing(2) : 0,
      })}
    >
      <FormGroup row={isRow}>
        {options.map((checkbox) => (
          <Checkbox
            option={checkbox}
            onOptionChange={handleSingleOptionClick(checkbox)}
            key={checkbox.id}
          />
        ))}
        {countOptions >= 3 && (
          <Checkbox
            option={{
              name: 'All',
              selected: countSelected === countOptions,
              id: 'all-checked',
            }}
            onOptionChange={handleBulkOptionClick}
            indeterminate={countSelected > 0 && countSelected !== countOptions}
            indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
          />
        )}
      </FormGroup>
    </Box>
  );
};

export default CheckboxList;
