import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(timezone);

// NOTE: Everything below does not take time zone into account in a meaningful way -- we need to write tests that do!!!!!! That said, everything should pass.

// eslint-disable-next-line no-console
console.log('Time Zone Detected:', dayjs.tz.guess());

export const dateFormat = (
  date: string | undefined = undefined, // NOTE: The expectation is this will be in UTC if not defined
  format: string | undefined = undefined,
  localize: boolean | undefined = true,
): string => {
  if (!localize) {
    return dayjs.utc(date).format(format?.trim());
  }

  // eslint-disable-next-line no-console
  // console.log('Localizing to time zone:', dayjs.tz.guess());

  return dayjs.utc(date).tz(dayjs.tz.guess()).format(format?.trim());
};

/**
 * Converts a Unix timestamp to a UTC string timestamp.
 * @param unixTimestamp The Unix timestamp to convert.
 * @returns A UTC string timestamp.
 */
export function convertUnixToUTCString(unixTimestamp: number): string {
  return dayjs.unix(unixTimestamp).utc().format();
}

export const timeNowUtc = (iso = true): string => {
  if (iso) {
    return dayjs().utc().toISOString();
  }
  return dayjs().utc().format();
};

// https://day.js.org/docs/en/parse/now
export default dayjs;
