import { TypographyProps as MuiTypographyProps } from '@mui/material';
import { isNil } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
  selectAvailableEIVersions,
  selectEIVersionTitle,
} from '../redux/selectors/csgDataSelectors';
import { getDataVersion } from '../redux/slices/csgDataSlice';
import { StyledMediumLabel } from './labels/StyledLabel';

interface EIVersionTypographyProps extends MuiTypographyProps {
  prefix?: string;
  notFoundText?: string;
  showAllAvailableVersions?: boolean;
}

const DEF_EI_VERSIONS: string[] = ['2.6.2', '3.0.0', '3.1.0'];

const EIVersionTypography: FC<EIVersionTypographyProps> = ({
  prefix = null,
  notFoundText = null,
  showAllAvailableVersions = false,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const dataVersion = useAppSelector(selectEIVersionTitle);
  const availableEIVersions = useAppSelector(selectAvailableEIVersions);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchVersion = async (): Promise<void> => {
    setLoading(true);
    await dispatch(getDataVersion());
    setLoading(false);
  };

  useEffect(() => {
    // fetch versions only if they are not already available in global state
    // these is to avoid redundant API call for same data
    if (isNil(availableEIVersions) || availableEIVersions.length === 0) {
      void fetchVersion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableEIVersions]);

  const renderDataVersion = (): string => {
    if (loading) {
      return 'Loading';
    }
    if (dataVersion === null) {
      return `${notFoundText ?? 'Version Not Available'}`;
    }
    if (showAllAvailableVersions) {
      const eiVersions = [...DEF_EI_VERSIONS, ...availableEIVersions].reduce<string[]>(
        (prev, curr) => {
          return !prev.includes(curr) ? [...prev, curr] : prev;
        },
        [],
      );
      return `${eiVersions.join(', ')}`;
    }
    return `${prefix ? `${prefix} ` : ''}${dataVersion}`;
  };

  return (
    <StyledMediumLabel variant="h6" display="inline" {...props}>
      {renderDataVersion()}
    </StyledMediumLabel>
  );
};

export default EIVersionTypography;
