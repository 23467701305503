import { Circle as CircleIcon } from '@mui/icons-material';
import { Box, Chip, useTheme } from '@mui/material';
import { FC } from 'react';
import { ResultSetStatus } from '../../api/resultSets/types';
import { WARNING_BG_COLOR_DARK } from '../../utils/styleUtils';

interface StatusIndicatorProps {
  status: string;
  sx?: object;
}

export const StatusChip: FC<StatusIndicatorProps> = ({ status, sx = {} }) => {
  const theme = useTheme();

  switch (status) {
    case ResultSetStatus.PENDING:
      return (
        <Chip
          label="IN PROGRESS"
          sx={{
            fontSize: theme.spacing(1.5),
            width: theme.spacing(15),
            height: theme.spacing(2.5),
            padding: theme.spacing(0.25),
            color: '#fff',
            background: WARNING_BG_COLOR_DARK,
            paddingTop: theme.spacing(0.625), // To fix font off-center issue
            ...sx,
          }}
        />
      );
    case ResultSetStatus.FAILED:
      return (
        <Chip
          label="FAILED"
          sx={{
            fontSize: theme.spacing(1.5),
            width: theme.spacing(9.375),
            height: theme.spacing(2.5),
            padding: theme.spacing(0.25),
            color: '#fff',
            background: theme.palette.error.light,
            paddingTop: theme.spacing(0.625), // To fix font off-center issue
            ...sx,
          }}
        />
      );
    default:
      break;
  }

  return null;
};

const StatusStoplight: FC<StatusIndicatorProps> = ({ status, sx = {} }) => {
  const theme = useTheme();
  let color = 'inherit';

  switch (status) {
    case ResultSetStatus.PENDING:
      color = WARNING_BG_COLOR_DARK;
      break;
    case ResultSetStatus.COMPLETED:
      color = theme.palette.success.light;
      return <Box sx={{ display: 'none' }} />; // Don't show the green mark (there will be A LOT of green marks)
    case ResultSetStatus.FAILED:
      color = theme.palette.error.light;
      break;
    default:
      // eslint-disable-next-line no-console
      console.warn('Unknown status:', status);
      break;
  }

  return (
    <CircleIcon sx={{ color, height: theme.spacing(1.5), width: theme.spacing(1.5), ...sx }} />
  );
};

export default StatusStoplight;
