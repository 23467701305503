import { FC } from 'react';
import { useTheme } from '@mui/material';
import TextInput, { TextInputProps } from './TextInput';

export interface ImmutableTextInputProps extends TextInputProps {
  textInput: string;
  onShowVisibility?: (event: any) => void;
  onHideVisibility?: (event: any) => void;
}

const ImmutableTextInput: FC<ImmutableTextInputProps> = ({
  textInput,
  sx,
  onShowVisibility,
  onHideVisibility,
  ...textInputProps
}: ImmutableTextInputProps) => {
  const theme = useTheme();
  return (
    <TextInput
      formControlProps={{
        sx: {
          margin: 0,
          width: '100%',
          overflow: 'hidden',
        },
      }}
      sx={{
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: theme.spacing(0.375),
        paddingBottom: theme.spacing(0.375),
        margin: 0,
        background: '#fff',
        '& .MuiInputBase-root': {
          height: 'auto',
          minHeight: theme.spacing(3),
          background: '#fff',
          cursor: 'default',
        },
        '& .MuiInputBase-root .MuiInputBase-input': {
          paddingY: theme.spacing(0.25),
          cursor: 'default',
          caret: 'unset',
          caretColor: 'transparent',
        },
        cursor: 'default',
        ...sx,
      }}
      value={textInput}
      {...textInputProps}
    />
  );
};

export default ImmutableTextInput;
