import { FC } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FONT_BOLD, FONT_LIGHT, FONT_MEDIUM } from '../../utils/styleUtils';

export const StyledThinLabel: FC<TypographyProps> = styled((props: TypographyProps) => {
  return <Typography {...props} />;
})(() => ({
  fontFamily: FONT_LIGHT,
  fontWeight: 200,
}));

export const StyledMediumLabel: FC<TypographyProps> = styled((props: TypographyProps) => {
  return <Typography {...props} />;
})(() => ({
  fontFamily: FONT_MEDIUM,
  fontWeight: 500,
}));

export const StyledBoldLabel: FC<TypographyProps> = styled((props: TypographyProps) => {
  return <Typography {...props} />;
})(() => ({
  fontFamily: FONT_BOLD,
  fontWeight: 700,
}));
