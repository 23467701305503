import { APIStatus } from '../types';
import { UUID } from '../../api/types';
import { APIFile } from '../file/types';

export interface APIResultSetExportOptions {
  object_type: 'result_set';
  include_bands?: boolean;
  include_tiers?: boolean;
  include_monthly?: boolean;
  include_peak_gust?: boolean;
  locations_per_file?: number;
}

export interface APIPortfolioExportOptions {
  object_type: 'portfolio';
}

export interface APISLRExportOptions {
  object_type: 'single_location_report';
}

export interface APIUsageTrackingExportOptions {
  object_type: 'usage_tracking';
  include_category_name?: boolean;
}

export type APIExportOptions =
  | APIResultSetExportOptions
  | APIPortfolioExportOptions
  | APISLRExportOptions
  | APIUsageTrackingExportOptions;

export enum APIOutputStyle {
  standard = 'standard',
  enhanced = 'enhanced',
}

export enum APIExportFileType {
  csv = 'csv',
  parquet = 'parquet',
  docx = 'docx',
}

export enum APIExportCompression {
  none = 'none',
  gzip = 'gzip',
  zstd = 'zstd',
  snappy = 'snappy',
}

export interface APICreateExportInput {
  options: APIExportOptions;
  output_style?: APIOutputStyle;
  file_type?: APIExportFileType;
  compression?: APIExportCompression;
  object_id?: UUID;
}

export interface APIExport {
  id: UUID;
  object_id: UUID;
  status: APIStatus;
  options: APIExportOptions;
  output_style?: APIOutputStyle;
  file_type?: APIExportFileType;
  compression?: APIExportCompression;
  created_at: number;
  updated_at: number;
  created_by: UUID;
  updated_by: UUID;
  files: APIFile[];
}
