import { Box, MenuItem, Typography, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import Drawer from '../../components/Drawer';
import withErrorBoundary from '../../components/ErrorBoundary/withErrorBoundary';
import MidDot from '../../components/MidDot';
import { StyledMediumLabel } from '../../components/labels/StyledLabel';
import CSGVersionSelector from '../../components/CSGVersionSelector';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectDataVersion,
  selectDataVersions,
  selectEIVersion,
  selectEIVersionStatus,
  selectEIVersions,
  selectPortfolioName,
  selectSelectedPortfolio,
} from '../../redux/selectors/csgDataSelectors';
import { selectQueryBuilderOptions } from '../../redux/selectors/queryBuilderSelectors';
import { selectQueryBuilderDrawerOpen } from '../../redux/selectors/uiSelectors';
import {
  DataStatus,
  getEIVersion,
  resetCsgDataVersionToDefault,
  selectCsgDataVersion,
  selectCsgEIVersion,
} from '../../redux/slices/csgDataSlice';
import {
  // loadQueryBuilderParameters,
  toggleResultSet,
  updateQueryBuilderState,
} from '../../redux/slices/queryBuilderSlice';
import { setQueryBuilderDrawer } from '../../redux/slices/uiSlice';
import {
  CenteredRowStyle,
  DRAWER_COLUMN_CONTAINER,
  LITE_TEXT_COLOR,
  MUI_SPACER,
  SIDE_PANEL_CONTAINER_BORDER,
  SIDE_PANEL_TITLE_COLOR,
} from '../../utils/styleUtils';
import QueryBuilderAccordion from './QueryBuilderAccordion';
import QueryBuilderExecutor from './QueryBuilderExecutor';

const QueryBuilderDrawer: FC = () => {
  const dispatch = useAppDispatch();

  // IDEA: Maybe should put query builder state locally in this component rather than in redux -- that way it can update using useMemo or useState -- however it will not persist in that case...

  const portfolio = useAppSelector(selectSelectedPortfolio);
  const portfolioName = useAppSelector(selectPortfolioName);
  const queryBuilderOptions = useAppSelector(selectQueryBuilderOptions);
  const csgDataVersion = useAppSelector(selectDataVersion);
  const availableCsgVersions = useAppSelector(selectDataVersions);
  const availableEIVersions = useAppSelector(selectEIVersions);
  const eiVersion = useAppSelector(selectEIVersion);
  const eiVersionStatus = useAppSelector(selectEIVersionStatus);

  useEffect(() => {
    // NOTE: This fires when you refresh
    //
    void dispatch(updateQueryBuilderState({ settingsOnly: true, portfolio })); // NOTE: Setting with `{}` wil pull the csgVersion from app state and use that. If you want it to explicitly use `null`, instead say: `updateQueryBuilderState({ csgDataVersionOption: null })`

    // void dispatch(loadQueryBuilderParameters({}));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eiVersion]);

  useEffect(() => {
    void dispatch(getEIVersion());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csgDataVersion]);

  const setNewDrawerState = (newState: boolean): void => {
    dispatch(setQueryBuilderDrawer(newState));
    if (!newState) {
      dispatch(resetCsgDataVersionToDefault());
    }
  };

  const drawerOpen = useAppSelector(selectQueryBuilderDrawerOpen);
  const theme = useTheme();

  return (
    <Drawer drawerOpen={drawerOpen} setDrawer={setNewDrawerState}>
      <Box
        data-testid="query-builder-component"
        sx={{
          ...DRAWER_COLUMN_CONTAINER,
          color: LITE_TEXT_COLOR,
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: `calc(100% - ${theme.spacing(8)})`,
            marginBottom: theme.spacing(2),
          }}
        >
          <Box>
            <StyledMediumLabel
              data-testid="header-query-builder"
              sx={{
                color: SIDE_PANEL_TITLE_COLOR,
                marginLeft: theme.spacing(1.5),
              }}
              variant="h5"
            >
              QUERY BUILDER
            </StyledMediumLabel>
            <Box
              data-testid="query-portfolio-details"
              gap={1}
              sx={{
                ...CenteredRowStyle,
                alignItems: 'center',
                marginTop: theme.spacing(MUI_SPACER / 4),
                marginBottom: theme.spacing(MUI_SPACER / 2),
                marginLeft: theme.spacing(1.5),
              }}
            >
              <Box gap={0.5} sx={{ ...CenteredRowStyle }} data-testid="portfolio-title">
                <Typography variant="h6">Portfolio:</Typography>
                <StyledMediumLabel variant="h6">{portfolioName}</StyledMediumLabel>
              </Box>
              <MidDot variant="h6" sx={{ paddingRight: 0, fontWeight: 'normal' }} />
              <Box gap={0.5} sx={{ ...CenteredRowStyle, alignItems: 'center' }}>
                <Typography variant="h6">Data Version: </Typography>
                <CSGVersionSelector
                  data-testid="qb-data-version-dropdown"
                  availableVersions={availableCsgVersions}
                  selectedVersion={csgDataVersion}
                  onSelectVersion={(versionOption) => {
                    void dispatch(selectCsgDataVersion(versionOption));
                  }}
                />
              </Box>
              <MidDot variant="h6" sx={{ paddingRight: 0, fontWeight: 'normal' }} />
              <Box gap={0.5} sx={{ ...CenteredRowStyle, alignItems: 'center' }}>
                <Typography variant="h6">EI Version: </Typography>
                <CSGVersionSelector
                  data-testid="qb-ei-version-dropdown"
                  availableVersions={availableEIVersions}
                  selectedVersion={eiVersion}
                  loading={eiVersionStatus === DataStatus.LOADING}
                  onSelectVersion={(versionOption) => {
                    void dispatch(selectCsgEIVersion(versionOption));
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              height: `calc(100% - ${theme.spacing(8.75)})`,
              minHeight: '30vh',
              padding: `0 ${theme.spacing(1.5)}`,
              border: SIDE_PANEL_CONTAINER_BORDER,
              borderRadius: theme.spacing(0.5),
              overflowY: 'auto',
            }}
          >
            <QueryBuilderAccordion
              options={queryBuilderOptions}
              onToggleSwitch={(resultSetItem) => {
                dispatch(toggleResultSet(resultSetItem));
              }}
            />
          </Box>
        </Box>
        <QueryBuilderExecutor />
      </Box>
    </Drawer>
  );
};

export default withErrorBoundary(QueryBuilderDrawer);
