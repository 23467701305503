/* eslint-disable react/display-name */
import ErrorBoundary from './ErrorBoundary';

const withErrorBoundary = (WrappedComponent: any) => {
  return (props: any) => {
    return (
      <ErrorBoundary>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };
};

export default withErrorBoundary;
