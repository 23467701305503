import { Alert, Snackbar } from '@mui/material';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectUserAlert } from '../../redux/selectors/userSelectors';
import { setUserAlert } from '../../redux/slices/userSlice';

const UserAlerts: FC = () => {
  const dispatch = useAppDispatch();
  const userAlert = useAppSelector(selectUserAlert);

  // NOTE: When open, every Snackbar will be dismissed if Escape is pressed. Unless you don't handle onClose with the "escapeKeyDown" reason. If you want to limit this behavior to only dismiss the oldest currently open Snackbar call event.preventDefault in onClose.

  return (
    <Snackbar
      open={userAlert.open}
      autoHideDuration={5000}
      onClose={() => {
        dispatch(setUserAlert({ open: false }));
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        data-cy="user-alert-snack"
        onClose={() => {
          dispatch(setUserAlert({ open: false }));
        }}
        variant="filled"
        severity={userAlert.severity}
      >
        {userAlert.message}
      </Alert>
    </Snackbar>
  );
};

export default UserAlerts;
