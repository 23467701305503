import { Cancel as CancelIcon } from '@mui/icons-material';
import { Chip as MuiChip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { LITE_TEXT_COLOR, LITE_TEXT_COLOR_HOVER } from '../../utils/styleUtils';
import { ChipProps } from './types';
import { FONT_ALIGNED_TEXT_STYLE } from '../../theme';

const Chip: FC<ChipProps> = styled(
  ({ showInfo = false, onInfoClick, onDeleteClick, disabled, label, ...chipProps }: ChipProps) => {
    return (
      <MuiChip
        // deleteIcon={
        //   <>
        //     {showInfo && onDeleteClick !== undefined && (
        //       <InfoIconTooltip tooltip="default title for the tooltip" onClick={onInfoClick} />
        //     )}
        //     {onDeleteClick !== undefined && <CancelIcon fontSize="small" onClick={onDeleteClick} />}
        //   </>
        // }
        label={label}
        onDelete={onDeleteClick}
        deleteIcon={<CancelIcon />}
        disabled={disabled ?? onDeleteClick === undefined}
        {...chipProps}
      />
    );
  },
)(({ theme }) => {
  return {
    letterSpacing: theme.spacing(0.03125), // 0.25px
    fontWeight: '400',
    borderRadius: theme.spacing(0.25), // 2px
    height: theme.spacing(2.5),
    padding: theme.spacing(0.25),
    backgroundColor: '#000',
    '& .MuiChip-label': {
      fontSize: theme.spacing(1.25),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      color: LITE_TEXT_COLOR,
      ...FONT_ALIGNED_TEXT_STYLE,
    },
    '& .MuiSvgIcon-root': {
      fontSize: theme.spacing(1.5),
      color: LITE_TEXT_COLOR,
      margin: theme.spacing(0.125),
      '&:hover': {
        color: LITE_TEXT_COLOR_HOVER,
      },
    },
  };
});

export default Chip;
