import {
  APICreateFileDownloadLinkInput,
  APIFile,
  APIFileDownloadLink,
  APIFileInput,
  APIFileValidation,
  APIFileValidationInput,
  APIFileValidationLocn,
} from './types';
import { UUID } from '../../api/types';
import { APIResponse, POST, POST_FORM } from '../http';
import { create, get } from '../rest';

export async function createFileDownloadLink(
  fileId: UUID,
  input: APICreateFileDownloadLinkInput,
): Promise<APIResponse<APIFileDownloadLink>> {
  return await POST(`/files/${fileId}/links`, input);
}

export async function createFile(input: APIFileInput): Promise<APIResponse<APIFile>> {
  return await POST_FORM('/files', input);
}

export async function createFileValidation(
  input: APIFileValidationInput,
): Promise<APIResponse<APIFileValidation>> {
  return await create('/file_validations', input);
}

export async function getFileValidation(id: UUID): Promise<APIResponse<APIFileValidation>> {
  return await get('/file_validations', id);
}

export async function getFileValidationLocations(
  id: UUID,
): Promise<APIResponse<APIFileValidationLocn>> {
  return await get('/file_validations', `${id}/locations`);
}
