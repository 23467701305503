import { useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import {
  BenchmarkingLevel,
  DamageAndLossField,
  GridSize,
  GridType,
  Peril,
  ResultSetType,
  Scenario,
  ScorePeril,
  Year,
} from '../../api/resultSets/types';
import withErrorBoundary from '../../components/ErrorBoundary/withErrorBoundary';
import GrowList from '../../components/GrowList';
import Iterator from '../../components/Iterator';
import withGlass from '../../components/withGlass';
import { useAppSelector } from '../../redux/hooks';
import {
  selectSelectedPortfolio,
  selectSelectedResultSet,
} from '../../redux/selectors/csgDataSelectors';
import { selectNumberOfAvailableVisualizations } from '../../redux/selectors/vizSelectors';
import {
  BenchmarkingLevelUI,
  DamageAndLossLabelUI,
  GridSizeUI,
  PerilUI,
  ScenarioUI,
  ScorePerilUI,
  ResultSetTypeUI,
  EIModules,
} from '../query-builder/types';
import { getSystemDefaultFieldNames } from '../query-builder/constants';
import DataDownloadButton from './DataDownloadButton';
import MetadataCard from './MetadataCard';
import VizNavButton from './VizNavButton';
import { extractLocationCount } from '../../redux/slices/utils';
import { getElecCostParamKey } from '../query-builder/builder';
import { StyleProps } from '../../utils/styleUtils';

export interface StripeProps {
  vertical?: boolean;
  IteratorProps?: {
    sx: StyleProps;
  };
}

const deriveGridSize = ({
  gridType,
  gridSize,
}: {
  gridType?: string;
  gridSize?: string;
}): string => {
  if (gridSize) {
    switch (gridSize) {
      case GridSize.DYNAMIC:
        return GridSizeUI.DYNAMIC;
      case GridSize.FIFTEEN:
        return GridSizeUI.REGULAR_LARGE;
      default:
        return `Unknown grid size (${gridSize})`;
    }
  }

  if (gridType) {
    switch (gridType) {
      case GridType.DYNAMIC:
        return GridSizeUI.DYNAMIC;
      case GridType.REGULAR:
        return GridSizeUI.REGULAR_LARGE;
      default:
        return `Unknown grid type (${gridType})`;
    }
  }

  return 'Not found';
};
const GlassMetadataCard = withGlass({ noBorders: true, square: true, shadow: false })(MetadataCard);

interface CardItemRaw {
  title: string;
  value: string;
}

const rsShowWorkerProdParams = (eiModules: string[]): boolean => {
  return (
    eiModules.includes(EIModules.PRODUCTIVITY_HEAT) &&
    eiModules.includes(EIModules.UTILITIES_COOLING)
  );
};

const rsShowWildfireParams = (eiModules: string[]): boolean => {
  return eiModules.includes(EIModules.ACUTE_FIRE);
};

const rsShowCostOfWaterParams = (eiModules: string[]): boolean => {
  return eiModules.includes(EIModules.UTILITIES_WATER);
};

const rsShowFinancialParams = (eiModules: string[]): boolean => {
  return eiModules.includes(EIModules.FINANCIAL);
};

const extractDLOptionsList = (csgDataVersion = '', eiModules?: string[]): string[] => {
  if (!csgDataVersion) return [];

  const dlDefParams = {
    resultSetType: ResultSetTypeUI.ECONOMIC_IMPACTS,
    parameterPath: '',
    csgDataVersion,
  };

  const damageAndLossValues = getSystemDefaultFieldNames({
    ...dlDefParams,
    parameterPath: 'damageAndLossValues',
  });
  const lossCalculationValues = getSystemDefaultFieldNames({
    ...dlDefParams,
    parameterPath: 'lossCalculationValues',
  });
  const advancedParameterValues = getSystemDefaultFieldNames({
    ...dlDefParams,
    parameterPath: 'advancedParameterValues',
  });
  const workerProductivityValues =
    eiModules && !rsShowWorkerProdParams(eiModules)
      ? []
      : getSystemDefaultFieldNames({
          ...dlDefParams,
          parameterPath: 'workerProductivityValues',
        });

  const wildfireParametersValues =
    eiModules && !rsShowWildfireParams(eiModules)
      ? []
      : getSystemDefaultFieldNames({
          ...dlDefParams,
          parameterPath: 'wildfireLossValues',
        });

  const costOfWaterParametersValues =
    eiModules && !rsShowCostOfWaterParams(eiModules)
      ? []
      : getSystemDefaultFieldNames({
          ...dlDefParams,
          parameterPath: 'costOfWaterValues',
        });

  const financialParametersValues =
    eiModules && !rsShowFinancialParams(eiModules)
      ? []
      : getSystemDefaultFieldNames({
          ...dlDefParams,
          parameterPath: 'financialParametersValues',
        });

  return [
    ...damageAndLossValues,
    ...lossCalculationValues,
    ...advancedParameterValues,
    ...workerProductivityValues,
    ...wildfireParametersValues,
    ...costOfWaterParametersValues,
    ...financialParametersValues,
  ];
};

const MetadataStripe: FC<StripeProps> = ({ vertical = false, IteratorProps = { sx: {} } }) => {
  const theme = useTheme();
  const selectedPortfolio = useAppSelector(selectSelectedPortfolio);
  const selectedResultSet = useAppSelector(selectSelectedResultSet);
  const availableVizCount = useAppSelector(selectNumberOfAvailableVisualizations);

  const tileList = useMemo(
    () =>
      !selectedPortfolio
        ? [] // Don't show Tiles if any portfolio is not selected
        : [
            {
              title: extractLocationCount(selectedPortfolio),
              subtitle: 'locations in selected portfolio',
              actionButton: <DataDownloadButton data-testid="summary-tile-download-icon" />,
              id: 'metadata-tile-content-icon',
            },
            {
              title: availableVizCount,
              subtitle: 'available visualizations',
              actionButton: <VizNavButton data-testid="summary-tile-viz-icon" />,
              id: 'metadata-tile-content-viz',
            },
          ],
    [selectedPortfolio, availableVizCount],
  );

  const dataList = useMemo(() => {
    if (!selectedPortfolio) {
      return [
        {
          title: 'No Portfolio Selected',
          cards: [],
          id: 'no-portfolio-selected',
        },
      ];
    }

    if (!selectedResultSet) {
      return [
        {
          title: 'No Result Set Selected',
          cards: [],
          id: 'no-result-set-selected',
        },
      ];
    }

    const list = [
      {
        title: 'Metadata:',
        id: 'metadata-metadata',
        cards: [
          {
            title: 'CSG Data Version',
            value: selectedResultSet?.parameters?.csgVersion ?? 'Not found',
          },
          ...(selectedResultSet?.type === ResultSetType.ECONOMIC_IMPACTS
            ? [
                {
                  title: 'EI Version',
                  value: selectedResultSet?.parameters?.eiVersion ?? 'Not available',
                },
              ]
            : []),
          {
            title: 'Number of Locations',
            value: selectedResultSet?.metadata?.numberOfLocations ?? 'In Progress',
          },
          // Enable this later.
          // For now, we will only show the download icon, instead of result set count.
          // Reference: https://jupiterintel.atlassian.net/browse/SAAS-570
          // {
          //   title: 'Number of Rows',
          //   value: selectedResultSet?.metadata?.numberOfRows ?? 'Not found',
          // },
        ].map((item) => ({
          ...item,
          id: `metadata-metadata-${item.title}`,
        })),
      },
    ];

    if (!selectedResultSet.parameters) {
      return [
        {
          title: 'No Parameters Found',
          cards: [],
          id: 'no-parameters',
        },
      ];
    }

    const {
      benchmarking,
      csgVersion,
      eiModules,
      damageAndLoss,
      floodDefense,
      mesh,
      perils,
      scenarios,
      scores,
      years,
    } = selectedResultSet.parameters;
    const values = {
      gridSize: mesh ? deriveGridSize(mesh) : 'Not found',
      benchmarkingEnabled: benchmarking?.enabled ? 'Yes' : 'No',
      benchmarkingLevels: (benchmarking?.levels ?? [])
        .map((value: BenchmarkingLevel) => {
          switch (value) {
            case BenchmarkingLevel.COUNTRY:
              return BenchmarkingLevelUI.COUNTRY;
            case BenchmarkingLevel.ADMIN1:
              return BenchmarkingLevelUI.ADMIN1;
            case BenchmarkingLevel.ADMIN2:
              return BenchmarkingLevelUI.ADMIN2;
            default:
              return `Unknown Benchmarking Level (${JSON.stringify(value)})`;
          }
        })
        .join(', '),
      scores: (scores ?? [])
        .map((value: ScorePeril) => {
          switch (value) {
            case ScorePeril.WIND:
              return ScorePerilUI.WIND;
            case ScorePeril.COLD:
              return ScorePerilUI.COLD;
            case ScorePeril.PRECIP:
              return ScorePerilUI.PRECIP;
            case ScorePeril.HEAT:
              return ScorePerilUI.HEAT;
            case ScorePeril.COMBINED_FLOOD:
              return ScorePerilUI.COMBINED_FLOOD;
            case ScorePeril.HAIL:
              return ScorePerilUI.HAIL;
            case ScorePeril.DROUGHT:
              return ScorePerilUI.DROUGHT;
            case ScorePeril.FIRE:
              return ScorePerilUI.FIRE;
            case ScorePeril.ALL_PERILS:
              return ScorePerilUI.ALL_PERILS;
            default:
              return `Unknown ScorePeril (${JSON.stringify(value)})`;
          }
        })
        .join(', '),
      perils: (perils ?? [])
        .map((value: Peril) => {
          switch (value) {
            case Peril.WIND:
              return PerilUI.WIND;
            case Peril.COLD:
              return PerilUI.COLD;
            case Peril.PRECIP:
              return PerilUI.PRECIP;
            case Peril.HEAT:
              return PerilUI.HEAT;
            case Peril.COMBINED_FLOOD:
              return PerilUI.COMBINED_FLOOD;
            case Peril.HAIL:
              return PerilUI.HAIL;
            case Peril.DROUGHT:
              return PerilUI.DROUGHT;
            case Peril.FIRE:
              return PerilUI.FIRE;
            case Peril.COASTAL_FLOOD:
              return PerilUI.COASTAL_FLOOD;
            case Peril.PLUVIAL_FLOOD:
              return PerilUI.PLUVIAL_FLOOD;
            case Peril.FLUVIAL_FLOOD:
              return PerilUI.FLUVIAL_FLOOD;
            default:
              return `Unknown Peril (${JSON.stringify(value)})`;
          }
        })
        .join(', '),
      scenarios: (scenarios ?? [])
        .map((value: Scenario) => {
          switch (value) {
            case Scenario.BASELINE:
              return ScenarioUI.BASELINE;
            case Scenario.SSP_126:
              return ScenarioUI.SSP_126;
            case Scenario.SSP_245:
              return ScenarioUI.SSP_245;
            case Scenario.SSP_585:
              return ScenarioUI.SSP_585;
            default:
              return `Unknown Scenario (${JSON.stringify(value)})`;
          }
        })
        .join(', '),
      years: (years ?? [])
        .map((value: Year) => {
          return value.toString();
        })
        .join(', '),
      floodDefenseEnabled: floodDefense?.enabled ? 'Yes' : 'No',
      floodDefenseZeroed: floodDefense?.zeroOutDefendedLocations ? 'Yes' : 'No',
    };

    // NOTE: only list value(s) of sub-options if the parent is enabled
    const floodDefenseCards = floodDefense?.enabled
      ? [
          { title: 'Flood Defense Enabled', value: values.floodDefenseEnabled },
          { title: 'Adjusted Depths for Protected', value: values.floodDefenseZeroed },
        ]
      : [{ title: 'Flood Defense Enabled', value: values.floodDefenseEnabled }];

    // NOTE: only list value(s) of sub-options if the parent is enabled
    const benchmarkingCards = benchmarking?.enabled
      ? [
          { title: 'Benchmarking Enabled', value: values.benchmarkingEnabled },
          { title: 'Levels', value: values.benchmarkingLevels },
        ]
      : [{ title: 'Benchmarking Enabled', value: values.benchmarkingEnabled }];

    const resultSetType = selectedResultSet?.type;
    switch (resultSetType) {
      case ResultSetType.ECONOMIC_IMPACTS: {
        const defaultFields = damageAndLoss?.defaultFields;

        const possibleCards = [
          {
            fieldKey: DamageAndLossField.OCCUPANCY_SCHEME,
            title: DamageAndLossLabelUI.OCCUPANCY_SCHEME,
          },
          {
            fieldKey: DamageAndLossField.OCCUPANCY_CODE,
            title: DamageAndLossLabelUI.OCCUPANCY_CODE,
          },
          {
            fieldKey: DamageAndLossField.NUMBER_OF_STORIES,
            title: DamageAndLossLabelUI.NUMBER_OF_STORIES,
          },
          {
            fieldKey: DamageAndLossField.BASEMENT_CODE,
            title: DamageAndLossLabelUI.BASEMENT_CODE,
          },
          {
            fieldKey: DamageAndLossField.TOTAL_VALUE,
            title: DamageAndLossLabelUI.TOTAL_VALUE,
          },
          {
            fieldKey: DamageAndLossField.BUILDING_VALUE,
            title: DamageAndLossLabelUI.BUILDING_VALUE,
          },
          {
            fieldKey: DamageAndLossField.CONTENTS_VALUE,
            title: DamageAndLossLabelUI.CONTENTS_VALUE,
          },
          {
            fieldKey: DamageAndLossField.INVENTORY_VALUE,
            title: DamageAndLossLabelUI.INVENTORY_VALUE,
          },
          // 3.0.0 - 3.1.0
          {
            fieldKey: DamageAndLossField.FIRST_FLOOR_ELEVATION,
            title: DamageAndLossLabelUI.FIRST_FLOOR_ELEVATION,
          },
          {
            fieldKey: DamageAndLossField.FLOOR_AREA_SQM,
            title: DamageAndLossLabelUI.FLOOR_AREA_SQM,
          },
          {
            fieldKey: DamageAndLossField.DOWNTIME_VALUE,
            title: DamageAndLossLabelUI.DOWNTIME_VALUE,
          },
          {
            fieldKey: DamageAndLossField.REMOTE_WORK_RATIO,
            title: DamageAndLossLabelUI.REMOTE_WORK_RATIO,
          },
          {
            fieldKey: DamageAndLossField.FINANCIAL_BASE_YEAR,
            title: DamageAndLossLabelUI.FINANCIAL_BASE_YEAR,
          },
          {
            fieldKey: getElecCostParamKey(csgVersion),
            title: DamageAndLossLabelUI.ELECTRICITY_COST,
          },
          {
            fieldKey: DamageAndLossField.COOLING_SYSTEM_PROBABILITY,
            title: DamageAndLossLabelUI.COOLING_SYSTEM_PROBABILITY,
          },
          {
            fieldKey: DamageAndLossField.WORK_INTENSITY,
            title: DamageAndLossLabelUI.WORK_INTENSITY,
          },
          {
            fieldKey: DamageAndLossField.WINDOW_PANE,
            title: DamageAndLossLabelUI.WINDOW_PANE,
          },
          {
            fieldKey: DamageAndLossField.VENT_TYPE,
            title: DamageAndLossLabelUI.VENT_TYPE,
          },
          {
            fieldKey: DamageAndLossField.ROOF_COVER,
            title: DamageAndLossLabelUI.ROOF_COVER,
          },
          {
            fieldKey: DamageAndLossField.WATER_CONSUMPTION,
            title: DamageAndLossLabelUI.WATER_CONSUMPTION,
          },
          {
            fieldKey: DamageAndLossField.WATER_SHADOW_PRICE_RATIO,
            title: DamageAndLossLabelUI.WATER_SHADOW_PRICE_RATIO,
          },
          {
            fieldKey: DamageAndLossField.ANNUAL_GROWTH_RATE,
            title: DamageAndLossLabelUI.ANNUAL_GROWTH_RATE,
          },
          {
            fieldKey: DamageAndLossField.ANNUAL_VOLATILITY_RATE,
            title: DamageAndLossLabelUI.ANNUAL_VOLATILITY_RATE,
          },
          {
            fieldKey: DamageAndLossField.SUB_INDUSTRY_CODE,
            title: DamageAndLossLabelUI.SUB_INDUSTRY_CODE,
          },
          { fieldKey: DamageAndLossField.SALES_MARGIN, title: DamageAndLossLabelUI.SALES_MARGIN },
          { fieldKey: DamageAndLossField.DISCOUNT_RATE, title: DamageAndLossLabelUI.DISCOUNT_RATE },
        ];

        const dlDefOptionsList = extractDLOptionsList(csgVersion, eiModules);

        const damageAndLossCards = defaultFields
          ? possibleCards
              .filter(({ title }) => dlDefOptionsList.includes(title))
              .map(({ fieldKey, title }) => {
                const value = defaultFields[`${fieldKey}`] ?? 'Not Provided';
                return {
                  title,
                  value: value.toString(),
                };
              })
          : [];

        return list.concat([
          {
            title: 'Peril Data Parameters:',
            id: `${resultSetType}-meta`,
            cards: [
              { title: 'Perils', value: values.perils },
              { title: 'Scenarios', value: values.scenarios },
              { title: 'Years', value: values.years },
              ...floodDefenseCards,
            ].map((item: CardItemRaw) => ({
              ...item,
              id: `${resultSetType}-meta-${item.title}`,
            })),
          },
          {
            title: 'Economic Impacts Parameters:',
            id: `${resultSetType}-meta-ei`,
            cards: damageAndLossCards.map((item: CardItemRaw) => ({
              ...item,
              id: `${resultSetType}-meta-ei-${item.title}`,
            })),
          },
        ]);
      }
      case ResultSetType.FLOOD_MESH: {
        return list.concat({
          title: 'Flood Mesh Parameters:',
          id: `${resultSetType}-meta`,
          cards: [
            { title: 'Years', value: values.years },
            { title: 'Scenarios', value: values.scenarios },
            { title: 'Grid Size', value: values.gridSize },
            ...floodDefenseCards,
          ].map((item: CardItemRaw) => ({
            ...item,
            id: `${resultSetType}-meta-${item.title}`,
          })),
        });
      }
      case ResultSetType.PERILS: {
        return list.concat({
          title: 'Peril Data Parameters:',
          id: `${resultSetType}-meta`,
          cards: [
            { title: 'Perils', value: values.perils },
            { title: 'Scenarios', value: values.scenarios },
            { title: 'Years', value: values.years },
            ...floodDefenseCards,
          ].map((item: CardItemRaw) => ({
            ...item,
            id: `${resultSetType}-meta-${item.title}`,
          })),
        });
      }

      case ResultSetType.SCORES: {
        return list.concat({
          title: 'Scores Parameters:',
          id: `${resultSetType}-meta`,
          cards: [{ title: 'Scores', value: values.scores }, ...benchmarkingCards].map(
            (item: CardItemRaw) => ({
              ...item,
              id: `${resultSetType}-meta-${item.title}`,
            }),
          ),
        });
      }
      default:
        break;
    }

    return list;
  }, [selectedResultSet, selectedPortfolio]);
  return (
    <GrowList>
      <Iterator
        vertical={vertical}
        sx={{
          background: '#fff',
          paddingLeft: {
            xs: theme.spacing(2.25),
            sm: theme.spacing(2.25),
            md: theme.spacing(1.5),
          },
          paddingRight: {
            xs: theme.spacing(2.25),
            sm: theme.spacing(2.25),
            md: theme.spacing(4.5),
          },
          boxShadow: '-4px 0 4px 1px rgba(0,0,0,0.05)',
          ...IteratorProps.sx,
        }}
        headless
        BodyProps={{
          gridItemSx: {
            // [make summary card fill the whole iterator] pt. 1
            height: '100%',
            width: '100%',
            border: 'none',
          },
        }}
        TailProps={{
          contextMenu: {
            /* Hiding the context menu for release 1 */
            // onClick: () => {
            //   // eslint-disable-next-line no-console
            //   console.log('This is toolkit context!');
            // },
          },
        }}
        tailless
      >
        <GlassMetadataCard
          sx={{ background: '#fff' }}
          dataList={dataList}
          tileList={tileList}
          status={selectedResultSet?.status ?? ''}
        />
      </Iterator>
    </GrowList>
  );
};

export default withErrorBoundary(MetadataStripe);
