export interface DashboardObject {
  title: string;
  id: string;
  rsParamAlias: string;
  crar: boolean; // when this value is TRUE it means we expect for the dashboard to be able (under certain circumstances) to link to the CRAR Report
}

export const DUNDAS_DASHBOARDS: {
  [key: string]: DashboardObject;
} = {
  singleLocationPerils: {
    // dashboardGroup: 'Damage & Loss',
    title: 'Single-Location Hazard',
    id: 'e1612d44-049e-4010-939c-7356cc934216',
    rsParamAlias: 'VP_ResultSetID',
    crar: true,
  },
  singleLocationImpact: {
    // dashboardGroup: 'Damage & Loss',
    title: 'Single-Location Impact',
    id: '18bea3e4-5aaa-4cf7-984d-56073f00a988',
    rsParamAlias: 'VP_ResultSetID',
    crar: true,
  },
  singleLocationFinancial: {
    // dashboardGroup: 'Damage & Loss',
    title: 'Single-Location Financial',
    id: '618b6418-c45b-40b2-bad3-250714300cf9',
    rsParamAlias: 'VP_ResultSetID',
    crar: false,
  },
  scoresScatterPlot: {
    // dashboardGroup: 'Scores',
    title: 'Hazard Scores',
    id: 'ebb8a2b3-82d2-4647-ad56-7b4f6daac12b',
    rsParamAlias: 'ResultSetIdFilter',
    crar: false,
  },
  perilMetricsValueTable: {
    // dashboardGroup: 'Peril Metrics',
    title: 'Tabular View',
    id: 'c1069f21-afa5-4b30-82de-93823fb9f0f6',
    rsParamAlias: 'VP_ResultID',
    crar: false,
  },
  perilMetricsMap: {
    // dashboardGroup: 'Peril Metrics',
    title: 'Geographic Overview',
    id: '5a011f80-888b-4985-aefc-fca72a4c56b6',
    rsParamAlias: 'VP_ResultSetID',
    crar: false,
  },
  perilValueChange: {
    // dashboardGroup: 'Peril Metrics',
    title: 'Peril Value Change',
    id: 'd99585e6-55fc-431d-b738-9961880fd61e',
    rsParamAlias: 'VP_ResultSetID',
    crar: false,
  },
  perilMetricsStackedColumnChart: {
    // dashboardGroup: 'Peril Metrics',
    title: 'Portfolio Overview',
    id: 'fedd7170-73a5-420e-9f05-e9dfa939d15b',
    rsParamAlias: 'ResultSetID_VP',
    crar: false,
  },
  floodMeshMap: {
    // dashboardGroup: 'Flood Mesh',
    title: 'Flood Mesh Analysis',
    id: 'c810f9a6-e6ed-4a77-b3e0-ccafce9147df',
    rsParamAlias: 'ResultSetID',
    crar: false,
  },
};

const dundasSettings = {
  server: `https://${window._env_.REACT_APP_DUNDAS_BI_ENDPOINT}`,
  applicationDivId: 'dundasApp',
  widgetTypesToExclude: ['indicator', 'richtexteditor'],
  DEFAULT_DASHBOARD_ID: DUNDAS_DASHBOARDS.perilMetricsMap.id,
};

export default dundasSettings;
