import { Box, Typography } from '@mui/material';
import { FC, ReactElement, useMemo } from 'react';
import Accordion from '../../../components/accordion/Accordion';
import CheckboxList from '../../../components/checkbox/CheckboxList';
import {
  CheckboxFormProps,
  CheckboxOperation,
  NestedCheckboxFormProps,
} from '../../../components/checkbox/types';
import ChipGroup from '../../../components/chip/ChipGroup';
import { CenteredRowStyle } from '../../../utils/styleUtils';
import { itemSelected } from '../utils';
import { extractChipItemProps } from '../../../components/chip/util';

type FloodDefenseOptionsProps = Omit<CheckboxFormProps, 'title'>;

interface FloodDefenseOptionsPanelProps extends CheckboxFormProps {
  children: ReactElement | ReactElement[];
  showChips?: boolean;
}

interface FloodDefenseProps extends Omit<NestedCheckboxFormProps, 'nestedTitle'> {
  nestedTitle?: string;
  showPanel?: boolean; // decides to show nested checklist as flat or under nested panel
  showChips?: boolean;
}

const FloodDefenseOptions: FC<FloodDefenseOptionsProps> = ({ list, setList }) => {
  return (
    <CheckboxList
      options={list}
      onSelectAll={() => {
        setList({ operation: CheckboxOperation.SelectAll });
      }}
      onDeselectAll={() => {
        setList({ operation: CheckboxOperation.DeselectAll });
      }}
      onOptionChange={(id: string) => {
        setList({ operation: CheckboxOperation.Toggle, id });
      }}
    />
  );
};

const FloodDefenseOptionsPanel: FC<FloodDefenseOptionsPanelProps> = ({
  list,
  setList,
  title,
  children,
  showChips = false,
}) => {
  const chips = useMemo(
    () =>
      list.filter(itemSelected).map((item) => {
        const chipBase = extractChipItemProps(item);
        return {
          ...chipBase,
          onDeleteClick: () => {
            setList({ operation: CheckboxOperation.Toggle, id: item.id });
          },
        };
      }),
    [list, setList],
  );

  return (
    <Accordion
      expandedDefault
      accordionTitle={
        <Box sx={{ ...CenteredRowStyle, alignItems: 'center' }}>
          <Typography variant="h6">{title}</Typography>
          {showChips && chips.length > 0 && <ChipGroup items={chips} />}
        </Box>
      }
    >
      {children}
    </Accordion>
  );
};

const FloodDefense: FC<FloodDefenseProps> = ({
  list,
  setList,
  title,
  nestedList,
  setNestedList,
  nestedTitle = 'Flood defense options', // title is as optional as panel visibility
  showPanel = false,
  showChips = false,
}) => {
  const chips = useMemo(
    () =>
      list
        .filter(itemSelected)
        .map((item) => ({
          ...item,
          // onInfoClick: () => {},
          // onDeleteClick: () => {},
        }))
        .map(extractChipItemProps),
    [list],
  );

  return (
    <Accordion
      accordionTitle={
        <Box sx={{ ...CenteredRowStyle, alignItems: 'center' }}>
          <Typography variant="h6" data-testid="header-flood-defense">
            {title}
          </Typography>
          {showChips && chips.length > 0 && <ChipGroup items={chips} />}
        </Box>
      }
      data-testid="flood-defense-panel"
    >
      <CheckboxList
        options={list}
        onSelectAll={() => {
          setList({ operation: CheckboxOperation.SelectAll });
        }}
        onDeselectAll={() => {
          setList({ operation: CheckboxOperation.DeselectAll });
        }}
        onOptionChange={(id: string) => {
          setList({ operation: CheckboxOperation.Toggle, id });
        }}
        noMarginBottom // NOTE: No margin for this list since there is a nested accordion below it, `<FloodDefenseOptions />`
      />
      {/* Only show the sub-option if its parent is also selected */}
      {/* {atLeastOneSelected(list) && */}
      {showPanel ? (
        <FloodDefenseOptionsPanel list={nestedList} setList={setNestedList} title={nestedTitle}>
          <FloodDefenseOptions list={nestedList} setList={setNestedList} />
        </FloodDefenseOptionsPanel>
      ) : (
        <FloodDefenseOptions list={nestedList} setList={setNestedList} />
      )}
    </Accordion>
  );
};

export default FloodDefense;
