import { FC } from 'react';
import { UpdateCheckboxInstructions } from '../../../components/checkbox/types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectScoresBenchmarkingIncluded,
  selectScoresBenchmarkingOptions,
  selectScoresPerils,
} from '../../../redux/selectors/queryBuilderSelectors';
import {
  updateScoresBenchmarkingIncluded,
  updateScoresBenchmarkingOptions,
  updateScoresPerils,
} from '../../../redux/slices/queryBuilderSlice';
import Benchmarking from '../sub-options/Benchmarking';
import PerilsMetricsTransferList from '../sub-options/PerilMetricsTransferList';

const Scores: FC = () => {
  const dispatch = useAppDispatch();
  const scorePerils = useAppSelector(selectScoresPerils);
  const benchmarkingIncluded = useAppSelector(selectScoresBenchmarkingIncluded);
  const benchmarkingOptions = useAppSelector(selectScoresBenchmarkingOptions);

  const setScores = (payload: UpdateCheckboxInstructions): void => {
    dispatch(updateScoresPerils(payload));
  };
  const setBenchmarkingIncluded = (payload: UpdateCheckboxInstructions): void => {
    dispatch(updateScoresBenchmarkingIncluded(payload));
  };
  const setBenchmarkingOptions = (payload: UpdateCheckboxInstructions): void => {
    dispatch(updateScoresBenchmarkingOptions(payload));
  };

  return (
    <>
      <PerilsMetricsTransferList list={scorePerils} setList={setScores} title="Scores" />
      <Benchmarking
        list={benchmarkingIncluded}
        setList={setBenchmarkingIncluded}
        title="Benchmarking"
        nestedList={benchmarkingOptions}
        setNestedList={setBenchmarkingOptions}
        nestedTitle="Options"
      />
    </>
  );
};

export default Scores;
