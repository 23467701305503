import { isEmpty } from 'lodash';
import { ValidationProps, ValidatorArgs } from './types';

export const validatePositiveIntegerString = ({
  value = '',
  fieldName,
}: ValidatorArgs): ValidationProps => {
  const valueStr = value.trim();
  const valueInt = Number(valueStr);
  return {
    valid:
      !isEmpty(valueStr) && !Number.isNaN(valueInt) && Number.isInteger(valueInt) && valueInt >= 0,
    message: `${fieldName ?? 'Input parameter'} must be a positive integer.`,
  };
};
