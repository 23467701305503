import { isArray, isString } from 'lodash';
import { RestAPIError } from './types';

export const apiErrorAccumulator = (error: RestAPIError): string => {
  let accErrorText: string[] = [];
  const { detail } = error;

  if (isArray(detail)) {
    if (detail?.length) {
      accErrorText = detail.reduce((acc, curr) => {
        return curr.msg ? [...acc, curr.msg] : acc;
      }, accErrorText);
    }
    return accErrorText.join('. ');
  }

  if (isString(detail)) {
    return detail;
  }

  return '';
};
