import { APIUsageStats } from '../rest/usage/types';
import { UsageStatsType } from '../pages/main/SettingsDrawer/types';

export function convertUsageStats(stats: APIUsageStats): UsageStatsType {
  const { user: userStats, tenant: tenantStats, categories: categoriesStats } = stats;
  const user = {
    totalPortfolios: userStats.total_portfolios,
    totalLocations: userStats.total_locations,
    totalQueries: userStats.total_queries,
  };
  const tenant = {
    totalPortfolios: tenantStats.total_portfolios,
    totalLocations: tenantStats.total_locations,
    totalQueries: tenantStats.total_queries,
  };
  const userPortfolioCategories = categoriesStats.map((categoryStat) => ({
    id: categoryStat.id,
    name: categoryStat.name,
    totalPortfolios: categoryStat.total_portfolios,
    totalLocations: categoryStat.total_locations,
    totalQueries: categoryStat.total_queries,
  }));

  return {
    user,
    tenant,
    userPortfolioCategories,
  };
}
