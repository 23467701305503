/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { encode as jsBase64Encode } from 'js-base64';
import get from 'lodash/get';
import isFinite from 'lodash/isFinite';
import { CreatePortfolioVariables, FileContentVariables } from '../../api/upload/types';
import { AlertSeverity } from '../../components/alerts/types';
import { CategoryOptionNullishType } from '../../pages/upload/CategorySelector/types';
import {
  CoordinateData,
  CSVContent,
  CSVFile,
  LocationData,
  UploadModes,
  ValidationResults,
  ValidationState,
  ValidationStatus,
  ValidationStatusUI,
} from '../../pages/upload/types';
import { csvToJsonAsync } from '../../utils/csvUtils';
import { isValidLatitude, isValidLongitude } from '../../utils/geoUtils';
import { refreshPortfoliosList } from './csgDataSlice';
import { addAlert, setUploadDrawer } from './uiSlice';
import { logRejected } from './utils';
import { trackCustomEvent } from '../../heap';
import {
  createPortfolioAdapter,
  createPortfolioFileValidationAdapter,
} from '../../rest/portfolio/adapter';

// NOTE: The purpose of `resultsWithUsableCoordinates` is to try and make sure the MapPreview component doesn't crash when it receives the data downstream
const resultsWithUsableCoordinates = (dataArray: any[]): any[] => {
  // eslint-disable-next-line no-console
  console.groupCollapsed('data cleaning');
  // eslint-disable-next-line no-console
  console.log(`found ${dataArray.length} rows of data`);

  try {
    const cleanedData = dataArray.filter((row) => {
      // NOTE: column names ARE case-sensitive
      const lat = parseFloat(get(row, 'latitude', null));
      const lng = parseFloat(get(row, 'longitude', null));
      const coordinates = [lat, lng];

      // if either latitude or longitude is not parsable as a finite number, reject the row (this is a broad check)
      if (coordinates.some((val) => !isFinite(val))) {
        return false;
      }

      // if both coordinates are valid, then allow the row (this is a more narrow check)
      if (isValidLatitude(lat) && isValidLongitude(lng)) {
        return true;
      }

      // otherwise, fail it
      return false;
    });
    // eslint-disable-next-line no-console
    console.log(`returning ${cleanedData.length} rows of cleaned data`);

    // eslint-disable-next-line no-console
    console.groupEnd();

    return cleanedData;
  } catch (error) {
    console.error('Error in resultsWithUsableCoordinates:', error);
    // eslint-disable-next-line no-console
    console.log('something went wrong; returning original data');
  }

  // eslint-disable-next-line no-console
  console.groupEnd();

  return dataArray;
};

export const processLocationsCsvFile = createAsyncThunk(
  'upload/process-locations-csv',
  async (csvFile: CSVFile, { dispatch }) => {
    const results = await csvToJsonAsync(csvFile);

    if (get(results, 'errors', []).length > 0) {
      console.error('There were errors in csvToJson:', results.errors);
    }

    const json = resultsWithUsableCoordinates(get(results, 'data', []));

    // Compose CSVContent
    const payload = {
      json,
      fileMeta: {
        ...results.meta,
        lastModified: csvFile.lastModified,
        lastModifiedDate: JSON.stringify(csvFile.lastModifiedDate),
        name: csvFile.name,
        size: csvFile.size,
        type: csvFile.type,
        webkitRelativePath: csvFile.webkitRelativePath,
      },
      locations: null,
    };

    return payload;
  },
);

export const createPortfolioFromRequestId = createAsyncThunk(
  'upload/create-portfolio',
  async ({ name, id, categoryId, extra }: ValidationResults, { dispatch, rejectWithValue }) => {
    const variables: CreatePortfolioVariables = {
      input: {
        categoryId: (categoryId ?? '').trim().length > 0 ? categoryId : null,
        description: null,
        fileUploadId: id,
        name,
      },
    };

    const mutationResponse = await createPortfolioAdapter(variables.input);

    const { data: newPortfolio, error } = mutationResponse;

    if (newPortfolio) {
      // const newPortfolio = data;

      dispatch(
        addAlert({
          open: true,
          severity: AlertSeverity.Success,
          message: 'Portfolio created!',
        }),
      );

      /*
       * 1.
       *   a) if the upload succeeds, refresh portfolios list
       *   a) if the upload succeeds:
       *      i. track custom event for analytics
       *      ii. refresh portfolios list
       *   b) select the new portfolio from the refreshed list
       */
      // Tracking Portfolio creation along with name, size, and company
      trackCustomEvent('Portfolio Created', {
        portfolioName: newPortfolio.name,
        size: extra.succeededCount,
      });
      // eslint-disable-next-line no-console
      console.log('1. refresh portfolios list, and select the new portfolio from that list');
      await dispatch(refreshPortfoliosList({ portfolioId: newPortfolio.id }));
      // eslint-disable-next-line no-console
      console.log('Done.');

      // 2. reset the whole state of upload, including validation state
      // eslint-disable-next-line no-console
      console.log('2. reset the whole state of upload, including validation state');
      dispatch(resetUploadState());

      // 3. close the drawer
      // eslint-disable-next-line no-console
      console.log('3. close the drawer');
      dispatch(setUploadDrawer(false));
      dispatch(resetUploadMode());

      return newPortfolio;
    }

    if (error) {
      dispatch(
        addAlert({
          open: true,
          severity: AlertSeverity.Error,
          message: `Unable to create portfolio. ${error.message}.`,
        }),
      );

      return rejectWithValue(error.message);
    }

    return rejectWithValue('Unknown error creating portfolio');
  },
);

interface SubmitUploadRequestProps {
  csv: File;
  csvString: string;
  name: string;
  description?: string;
}

export const submitUploadRequest = createAsyncThunk(
  'upload/validate',
  async (
    { csv, csvString, name, description }: SubmitUploadRequestProps,
    { rejectWithValue, dispatch },
  ) => {
    const base64String = jsBase64Encode(csvString);

    const variables: FileContentVariables = {
      input: {
        content: base64String,
        name,
        description: description ?? '',
      },
    };

    const mutationResponse = await createPortfolioFileValidationAdapter({
      ...variables.input,
      csv,
    });

    const { data: fileContent, error } = mutationResponse;

    if (fileContent) {
      // NOTE: There will be no success message for the user here; they will move on to validation.
      return fileContent;
    }

    if (error) {
      dispatch(
        addAlert({
          open: true,
          severity: AlertSeverity.Error,
          message: `File upload failed. ${error.message}.`,
        }),
      );

      return rejectWithValue(error.message);
    }

    return rejectWithValue('Unknown error submitting upload request');
  },
);

// https://stackoverflow.com/questions/63516716/redux-toolkit-is-it-possible-to-dispatch-other-actions-from-the-same-slice-in-o
export interface UploadState {
  coordinatesList: LocationData[];
  uploadMode: UploadModes | null;
  csvContent: CSVContent | null;
  validation: ValidationState;
  category: CategoryOptionNullishType;
}

const initialState: UploadState = {
  coordinatesList: [],
  uploadMode: null,
  csvContent: null,
  validation: {
    active: false,
    status: ValidationStatusUI.IDLE,
    results: null,
  },
  category: null,
};

const setValidationStateReducer = (
  state: UploadState,
  { id, extra, name, status }: ValidationResults,
): ValidationState => {
  switch (status) {
    case ValidationStatus.SUCCEEDED:
      state.validation.status = ValidationStatusUI.SUCCEEDED;
      break;
    case ValidationStatus.PARTIALLY_SUCCEEDED:
      state.validation.status = ValidationStatusUI.SUCCEEDED;
      break;
    case ValidationStatus.FAILED:
      state.validation.status = ValidationStatusUI.FAILED;
      break;
    default:
      // eslint-disable-next-line no-console
      console.warn('Unknown validation status:', status);
      state.validation.status = ValidationStatusUI.UNKNOWN;
      break;
  }

  const pathToValidation = (input: any): any => {
    try {
      return typeof input === 'string' ? JSON.parse(input).validation : input.validation ?? input;
    } catch (error) {}
    return input;
  };

  state.validation.results = {
    id,
    name,
    extra: pathToValidation(extra),
    status,
  };

  return state.validation;
};

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  // the `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUploadCategory: (state: UploadState, action: PayloadAction<CategoryOptionNullishType>) => {
      state.category = action.payload;
    },
    resetUploadState: (state: UploadState) => {
      state.coordinatesList = [];
      state.uploadMode = null;
      state.csvContent = null;
      state.validation = {
        active: false,
        status: ValidationStatusUI.IDLE,
        results: null,
      };
      state.category = null;
    },
    setUploadMode: (state: UploadState, action: PayloadAction<UploadModes>) => {
      state.uploadMode = action.payload;
    },
    resetUploadMode: (state: UploadState) => {
      state.uploadMode = null;
    },
    addCoordinates: (state: UploadState, action: PayloadAction<CoordinateData[]>) => {
      const prevCL = state.coordinatesList;
      const coordinatesToBeAdded = action.payload;

      // the objective of this function is to keep a an immutable series of locationIds for the purpose of rendering items efficiently
      const nextLocationId =
        prevCL.length > 0
          ? Math.max(...prevCL.map(({ locationId }) => parseFloat(locationId))) + 1
          : 1;

      const addLocationIds = (coordObj: CoordinateData, idx: number): LocationData => ({
        ...coordObj,
        locationId: `${nextLocationId + idx}`,
      });

      state.coordinatesList = prevCL.concat(coordinatesToBeAdded.map(addLocationIds));
    },
    removeCoordinates: (state: UploadState, action: PayloadAction<LocationData[]>) => {
      const prevCL = state.coordinatesList;
      const coordinatesToBeRemoved = action.payload;
      const locationIdsToBeRemoved = coordinatesToBeRemoved.map(({ locationId }) => locationId);
      state.coordinatesList = prevCL.filter(
        ({ locationId }) => !locationIdsToBeRemoved.includes(locationId),
      );
    },
    editCoordinates: (state: UploadState, action: PayloadAction<LocationData[]>) => {
      const prevCL = state.coordinatesList;
      const coordinatesToBeEdited = action.payload;

      const findEdit = ({ locationId }: LocationData): LocationData | undefined =>
        coordinatesToBeEdited.find((edit) => locationId === edit.locationId);

      state.coordinatesList = prevCL.map((item) => {
        const edit = findEdit(item);

        if (edit !== undefined) {
          return edit;
        }

        return item;
      });
    },
    setValidationActive: (state: UploadState, action: PayloadAction<boolean>) => {
      state.validation.active = action.payload;
    },
    setValidationState: (state: UploadState, action: PayloadAction<ValidationResults>) => {
      state.validation = setValidationStateReducer(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitUploadRequest.pending, (state) => {
        state.validation.status = ValidationStatusUI.LOADING;
      })
      .addCase(
        submitUploadRequest.fulfilled,
        (state: UploadState, action: PayloadAction<ValidationResults>) => {
          const newValidation = setValidationStateReducer(state, action.payload);
          state.validation = newValidation;
          state.coordinatesList = newValidation.results?.extra.locations ?? [];
        },
      )
      .addCase(submitUploadRequest.rejected, (state, action) => {
        logRejected(action, 'submitUploadRequest failed');
        state.validation.status = ValidationStatusUI.FAILED;
      })
      .addCase(createPortfolioFromRequestId.pending, (state) => {
        //
      })
      .addCase(createPortfolioFromRequestId.fulfilled, (state: UploadState, action) => {
        //
      })
      .addCase(createPortfolioFromRequestId.rejected, (state, action) => {
        logRejected(action, 'createPortfolioFromRequestId failed');
      })
      .addCase(processLocationsCsvFile.pending, (state) => {
        //
      })
      .addCase(
        processLocationsCsvFile.fulfilled,
        (state: UploadState, action: PayloadAction<CSVContent | null>) => {
          if (action.payload === null) {
            // NOTE: Do not update state if result is null; an alert will be dispatched to the user as a part of error handling
            return;
          }
          state.csvContent = action.payload;
        },
      )
      .addCase(processLocationsCsvFile.rejected, (state, action) => {
        logRejected(action, 'processLocationsCsvFile failed');
      });
  },
});

export const {
  addCoordinates,
  removeCoordinates,
  editCoordinates,
  setUploadMode,
  resetUploadMode,
  resetUploadState,
  setValidationState,
  setValidationActive,
  setUploadCategory,
} = uploadSlice.actions;

export default uploadSlice.reducer;
