import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { FC, ReactElement, SyntheticEvent, useState } from 'react';
import InfoIconTooltip from '../../components/InfoIconTooltip';
import ControlledAccordion from '../../components/accordion/ControlledAccordion';
import CustomizedSwitch from '../../components/customized-switch/CustomizedSwitch';
import Tooltip from '../../components/tooltip/Tooltip';
import { CenteredRowStyle } from '../../utils/styleUtils';
import { ENABLE_QUERY_BUILDER_OPTION_TOOLTIP } from './constants';
import FloodMesh from './options/FloodMesh';
import Perils from './options/Perils';
import PerilsWithDamageLoss from './options/PerilsWithDamageLoss';
import Scores from './options/Scores';
import { ResultSetOptionsUI, ResultSetTypeUI } from './types';

interface ResultSetSectionProps {
  type: string;
}

const ResultSetSection: FC<ResultSetSectionProps> = ({ type }) => {
  switch (type) {
    case ResultSetTypeUI.PERILS:
      return <Perils />;

    case ResultSetTypeUI.FLOOD_MESH:
      return <FloodMesh />;

    case ResultSetTypeUI.ECONOMIC_IMPACTS:
      return <PerilsWithDamageLoss />;

    case ResultSetTypeUI.SCORES:
      return <Scores />;

    default:
      return <Box>{`Unknown Result Set Type (${type})`}</Box>;
  }
};

interface ResultSetSectionTitleProps {
  item: ResultSetOptionsUI;
  onToggleSwitch: (value: ResultSetOptionsUI) => void;
}

const renderSwitch = ({ item, onToggleSwitch }: ResultSetSectionTitleProps): ReactElement => {
  return (
    <CustomizedSwitch
      checkedIcon={
        <CheckCircleIcon
          className="checkCircleIcon"
          sx={{ fontSize: '0.875rem' }}
          color="success"
        />
      }
      data-testid="custom-switch"
      disabled={item.disabled ?? false}
      checked={item.selected}
      onClick={(event: SyntheticEvent) => {
        event.stopPropagation();
        onToggleSwitch(item);
      }}
    />
  );
};

const renderSetAccordionTitle = ({
  item,
  onToggleSwitch,
}: ResultSetSectionTitleProps): ReactElement => {
  const tooltip = item?.tooltip ?? '';
  return (
    <Grid
      item
      xs={12}
      sx={{
        ...CenteredRowStyle,
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Box sx={{ ...CenteredRowStyle, alignItems: 'center' }}>
        <Typography variant="h6">{item.name}</Typography>
        {/* Hiding the testing chips for release 1 */}
        {/* <MoreVertIcon
          sx={{
            fontSize: '0.875rem',
            marginLeft: '4px',
            marginRight: '4px',
            marginTop: '2px',
          }}
        />
        <Chip label="Testing Chips" /> */}
        {tooltip.length > 0 && <InfoIconTooltip tooltip={tooltip} sx={{ marginLeft: '4px' }} />}
      </Box>
      {item.selected ? (
        renderSwitch({ item, onToggleSwitch })
      ) : (
        <Tooltip title={ENABLE_QUERY_BUILDER_OPTION_TOOLTIP}>
          {renderSwitch({ item, onToggleSwitch })}
        </Tooltip>
      )}
    </Grid>
  );
};
export interface QueryBuilderAccordionProps {
  options: ResultSetOptionsUI[];
  onToggleSwitch: (value: ResultSetOptionsUI) => void;
}

const QueryBuilderAccordion: FC<QueryBuilderAccordionProps> = ({ options, onToggleSwitch }) => {
  const [expandedControl, updatedExpanded] = useState<{
    [id: string]: boolean;
  }>(
    options.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: curr.selected,
      };
    }, {}),
  );

  return (
    <>
      {options.map((item: ResultSetOptionsUI) => {
        // Don't let the user expand the accordion if the item is not selected
        const expandable = item.selected && !item.disabled;
        const isExpanded = expandedControl[item.id];

        return (
          <ControlledAccordion
            data-testid={item.id}
            option={item}
            key={item.id}
            expandable={expandable}
            accordionTitle={renderSetAccordionTitle({ item, onToggleSwitch })}
            isExpanded={isExpanded}
            setExpanded={(toggleFn) => {
              const nextState = toggleFn(isExpanded);
              updatedExpanded((prevState) => ({
                ...prevState,
                [item.id]: nextState,
              }));
            }}
          >
            <ResultSetSection type={item.type} />
          </ControlledAccordion>
        );
      })}
    </>
  );
};

export default QueryBuilderAccordion;
